import { findServiceTypeInfo } from 'api/serviceCategoryApi';
import { sortByString } from './sortFunctions';
import { CadenceType } from 'model/serviceOffering';

export async function getServiceTypesForDropdown(setServiceTypesFunction) {


  const serviceTypeInfo = await findServiceTypeInfo();
  const serviceTypes = serviceTypeInfo.data.serviceTypes
    .filter(x => !x.deleteDate)
    .map((x, i) => ({ id: x.serviceTypeId, name: x.name }))
    .sort(sortByString('name'));
  setServiceTypesFunction(serviceTypes);
}


export function getDefaultCadenceForServiceType(serviceType) {
  const mostlySubscriptionServiceTypes = ['Home Cleaning', 'Pest Control', 'Mosquito Control', 'Mowing & Maintenance'];
  if (mostlySubscriptionServiceTypes.includes(serviceType)) {
    return CadenceType.MOSTLY_SUBSCRIPTION;
  }
  return CadenceType.ONETIME_ONLY;
}

export function getDefaultHasDisabledMaxCustomerCountForServiceType(serviceType) {
  const disabledServiceTypes = ['Painting', 'Outdoor Lighting', 'Roofing', 'Fencing', 'Window Treatments', 'Flooring', 'Brick Repair & Restoration', 'Landscape Design', 'Concrete Sealing', 'Window & Door Replacement', 'Outdoor Living'];
  if (disabledServiceTypes.includes(serviceType)) {
    return true;
  }
  return false;
}

export function getDefaultWorkFlowStepsForServiceType(serviceType) {
  // if service type is in the 1 & 4 list, return that option
  if ([
    'Irrigation Maintenance',
    'Electrical',
    'Plumbing',
    'HVAC Maintenance',
    'Stump Removal',
  ].includes(serviceType)) {
    return [
      {
        stepName: 'CONTACT_CUSTOMER',
        shouldShow: true,
      },
      {
        stepName: 'CONSULTATION_SCHEDULED',
        shouldShow: false,
      },
      {
        stepName: 'QUOTE_OR_ESTIMATE_SENT',
        shouldShow: false,
      },
      {
        stepName: 'CUSTOMER_CONFIRMED',
        shouldShow: true,
      },
    ];
  }
  // if service type is in the all list, return that option
  if ([
    'Mulch',
    'Handyman',
    'Tree Services',
    'Bush Trimming',
    'Painting',
    'Home Cleaning',
    'Roof Inspection & Maintenance',
    'Landscaping',
    'Exterior Painting',
    'Mowing & Maintenance',
    'Fencing',
    'Window Treatments',
    'Flooring',
    'Brick Repair & Restoration',
    'Landscape Design',
    'Concrete Sealing',
    'Window & Door Replacement',
  ].includes(serviceType)) {
    return [
      {
        stepName: 'CONTACT_CUSTOMER',
        shouldShow: true,
      },
      {
        stepName: 'CONSULTATION_SCHEDULED',
        shouldShow: true,
      },
      {
        stepName: 'QUOTE_OR_ESTIMATE_SENT',
        shouldShow: true,
      },
      {
        stepName: 'CUSTOMER_CONFIRMED',
        shouldShow: true,
      },
    ];
  }

  // return the default option
  return [
    {
      stepName: 'CONTACT_CUSTOMER',
      shouldShow: true,
    },
    {
      stepName: 'CONSULTATION_SCHEDULED',
      shouldShow: false,
    },
    {
      stepName: 'QUOTE_OR_ESTIMATE_SENT',
      shouldShow: true,
    },
    {
      stepName: 'CUSTOMER_CONFIRMED',
      shouldShow: true,
    },
  ];
}