import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import useIndividualsHookStore from './individualsHookStore';
import { Grid, IconButton, TextField } from '@mui/material';
import { PrimaryButton } from 'components/buttons';
import Loading from 'components/Layout/Loading';

export default function IndividualsSearch () {
  const { clearSearch, loadingKey, searchValue, onSearchInputChange, onSearchButtonClick } = useIndividualsHookStore();

  function onKeyPress(event: React.KeyboardEvent<HTMLDivElement>) {
    if (event.key === 'Enter') {
      onSearchButtonClick().catch(error => { console.error('Error on key press: ', error); });
    }
  }
  return (
    <Loading loadingKey={loadingKey}>
      <Grid container flexDirection={'row'} justifyContent={'flex-start'} alignItems={'flex-end'}>
        <Grid item xs={2}>
          <TextField
            variant="standard"
            value={searchValue}
            onKeyDown={onKeyPress}
            onChange={onSearchInputChange}
            placeholder="Email (case sensitive)"
            InputProps={{
              startAdornment: <SearchIcon fontSize="small" />,
              endAdornment: (
                <IconButton
                  title="Clear"
                  aria-label="Clear"
                  size="small"
                  style={{ visibility: searchValue ? 'visible' : 'hidden' }}
                  onClick={clearSearch}
                >
                  <ClearIcon fontSize="small" />
                </IconButton>
              ),
            }}
            sx={{ width: '100%' }}
          />
        </Grid>
        <Grid item xs={2}>
          <PrimaryButton
            onClick={onSearchButtonClick}
            disabled={!searchValue}
            sx={{ marginLeft: '25px' }}
          >
          Search
          </PrimaryButton>
        </Grid>
      </Grid>
    </Loading>
  );
}