import Axios, { AxiosResponse } from 'axios';
import { ICreateLookUpOptionRequest } from 'model/lookup/lookup';
import { INeighborhoodExperimentTag } from 'model/lookup/neighborhoodExperimentTag';

const apiUrl = window.REACT_APP_BASE_API_URI;
const baseUrl = `${apiUrl}/neighborhoodExperimentTags/v2`;

export function findAllNeighborhoodExperimentTags():Promise<AxiosResponse<INeighborhoodExperimentTag[]>> {
  return Axios.get(`${baseUrl}/findAll`);
}

export function createNeighborhoodExperimentTagOption(data: ICreateLookUpOptionRequest) {
  return Axios.post(`${baseUrl}/createNeighborhoodExperimentTag`, data);
}