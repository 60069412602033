
import { FormControl, FormHelperText } from '@mui/material';
import { HookSingleAutocomplete } from 'components/reactHookForm';
import useErrorState from 'hooks/reactHookForm/useErrorState';
import { useFormContext } from 'react-hook-form';
import useRebookServiceNotifications from '../hookStore/useRebookServiceNotifications';
import ServiceProviderAutocomplete from 'components/serviceProviderAutocomplete/ServiceProviderAutocomplete';


export interface IServiceProviderSelectProps {

}

export default function ServiceProviderSelect({ }:IServiceProviderSelectProps) {
  const { findAndSetExistingServiceOfferings } = useRebookServiceNotifications();
  const formCtx = useFormContext();
  const { isError, getErrorMessage } = useErrorState(formCtx);


  return (
    <ServiceProviderAutocomplete
      required
      fieldName='serviceProviderId'
      label='Source Service Provider'
      placeholder='Service provider whose past service will be used to find customers'
      formless={false}
      postOnChange={(formContext, value, reason, details) => {
        if (value?.optionValue) {
          void findAndSetExistingServiceOfferings(formContext, value.optionValue);
        }
      }}
    />
  );
}