import { Grid } from '@mui/material';
import Button from '@mui/material/Button';

export default function PrimarySecondaryButtonGroup (props: any) {

  return (
    <>
      <Grid container style={{
        textAlign: 'center',
        marginTop: props.marginTop,
      }}>
        <Button
          variant="contained"
          onClick={props.primaryButtonAction}
          style = {{
            background: '#299B60 no-repeat left',
            minWidth: '250px',
            padding: '10px',
            margin: '10px',
            borderRadius: '5px',
            color: 'white',
            fontWeight: 'bold',
          }}
          type="submit"
        >
          <span>{props.primaryButtonText}</span>
        </Button>

        <Button variant="text" onClick={props.secondaryButtonAction}
          style={{
            color: 'rgb(38, 153, 95)',
          }}
        >{props.secondaryButtonText}</Button>

      </Grid>
    </>
  );

}
