/* eslint-disable @typescript-eslint/no-shadow */
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import DeleteIcon from '@mui/icons-material/Delete';
import { Autocomplete, AutocompleteProps, Button, Checkbox, TextField } from '@mui/material';
import { IDropdownOption, UNSELECTED_OPTION } from 'model/dropdown';
import { Controller, useFormContext } from 'react-hook-form';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export interface IHookSingleAutocompleteProps {
  name: string;
  dropdownOptions:IDropdownOption[];
  label:string;
  placeholder:string;
  rules?:any;
  postOnChange?:any;
  postOnBlur?:Function;
  extraProps?:any;
}

export default function HookSingleAutocomplete({
  name,
  label,
  placeholder,
  rules,
  dropdownOptions,
  postOnChange,
  postOnBlur,
  extraProps,
}:IHookSingleAutocompleteProps) {
  const formContext = useFormContext();
  const { control } = formContext;
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { invalid, isTouched, isDirty, error },
        formState,
      }) => {
        return (
          <Autocomplete
            id={`${name}-singleautocomplete`}
            onChange={(e, value:any, reason, details) => {
              if (reason === 'selectOption') {
                onChange(value);
                if (postOnChange) {
                  postOnChange(formContext, value, reason, details);
                }
              } else if (reason === 'createOption') {

              } else if (reason === 'removeOption') {
                onChange(value);
                if (postOnChange) {
                  postOnChange(formContext, value, reason, details);
                }
              } else if (reason === 'blur') {

              } else if (reason === 'clear') {
                onChange(null);
                if (postOnChange) {
                  postOnChange(formContext, null, reason, details);
                }
              }

            }}
            isOptionEqualToValue={(option, value) => {
              return option.optionValue === value.optionValue;
            }}
            getOptionDisabled={(option) => {
              return option.optionValue === UNSELECTED_OPTION;
            }}
            ref={ref}
            value={value}
            options={dropdownOptions}
            filterOptions={(options, state)=> {
              const matches:any[] = [];
              for (let i = 0; i < options.length && matches.length < 20;i++) {
                if (options[i].optionText.toLowerCase().indexOf(state.inputValue) !== -1) {
                  matches.push(options[i]);
                }
              }
              return matches;
            }}
            getOptionLabel={(option) => {
              return option.optionText ?? '';
            }}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                {option.optionText}
              </li>
            )}
            fullWidth
            renderInput={(params) => (
              <TextField
                {...params}
                label={label}
                placeholder={placeholder}
                sx={{ cursor: 'pointer' }}
              />
            )}
            {...extraProps}
          />
        );
      }
      }
    />
  );
}