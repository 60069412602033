import { FormControl, InputLabel, FormHelperText } from '@mui/material';
import { HookSelect } from 'components/reactHookForm';
import useErrorState from 'hooks/reactHookForm/useErrorState';
import { CadenceType } from 'model/serviceOffering';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

export default function CadenceTypeSelect() {
  const formCtx = useFormContext();
  const [cadenceTypes] = useState<any[]>(CadenceType.list);
  const { isError, getErrorMessage } = useErrorState(formCtx);
  return (
    <FormControl variant='standard' fullWidth error={isError('cadenceType')}>
      <InputLabel htmlFor="cadence-type">Cadence Type</InputLabel>
      <HookSelect
        control={formCtx.control}
        rules={{
          required: { value: true, message: 'Required' },
        }}
        name='cadenceType'
        id='cadence-type'
        menuItems={cadenceTypes}
      />
      <FormHelperText>{getErrorMessage('cadenceType')}</FormHelperText>
    </FormControl>
  );
}