import { GenerateLinkButton } from 'components/buttons';
import useModal from 'components/modal/useModal';
import { modalStoreKey } from './WelcomePageShortLinkModal';
import styles from '../neighborhoods.module.css';

export default function OpenWelcomePageShortLinkModalButton({ neighborhoodId, neighborhoodName, disabled }) {
  const { openModal } = useModal(modalStoreKey);
  return (
    <GenerateLinkButton
      disabled={disabled}
      tooltipTitle='Generate Welcome Page Link'
      onClick={() => openModal({ neighborhoodId, neighborhoodName })}
      tooltipProps={{
        placement: 'top',
      }}
      iconButtonProps={{
        className: styles.actionButton,
      }}
    />
  );
}