import { PrimaryButton } from 'components/buttons';
import useTerritorySeedingTemplates from '../hookStore/useTerritorySeedingTemplates';
import useModal from 'components/modal/useModal';
import { modalStoreKey } from 'pages/territorySeedingTemplates/_components/CloneFromModal';

export default function CloneFromButton() {
  const { selectedTerritory, templates } = useTerritorySeedingTemplates();
  const { openModal } = useModal(modalStoreKey);
  return <PrimaryButton
    onClick={() => openModal({})}
    disabled={!selectedTerritory || templates.length > 0}
  >
       Clone From
  </PrimaryButton>;
}