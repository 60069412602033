import useConfirmationModal from 'components/modals/useConfirmationModal';
import { FieldValues, SubmitErrorHandler, SubmitHandler, useFormContext } from 'react-hook-form';
import { PrimaryButton } from 'components/buttons';
import { IGenericCTABannerDTO } from './useNeighborhoodBannerLDConfig';
import useToast from 'components/toast/useToast';


export default function SubmitButton() {
  const { handleSubmit, setError, clearErrors, formState: { isSubmitting, isValid, errors }, getValues } = useFormContext();
  const { createInfoToast } = useToast();
  const validate = (data:IGenericCTABannerDTO) => {
    return {
      isValid: true,
    };;
  };
  const onSubmit : SubmitHandler<IGenericCTABannerDTO> = data => {
    const { isValid: _isValid } = validate(data);
    console.log(JSON.stringify(data, null, '\t'));
    navigator.clipboard.writeText(JSON.stringify(data)).catch(reason => console.log(reason));
    createInfoToast('Copied to clipboard');
  };
  const onSubmitError: SubmitErrorHandler<FieldValues> = data => {
    const { isValid: _isValid } = validate(getValues() as IGenericCTABannerDTO);

  };
  return (
    <PrimaryButton
      disabled={isSubmitting || !isValid}
      type='button'
      onClick={(e) => {
        // TODO: figure out why this onSubmit gives me inconsistent ts errors

        void handleSubmit(onSubmit, onSubmitError)(e);
      }}
    >
    Submit
    </PrimaryButton>
  );
}