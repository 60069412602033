import { IGeoJSONCoordinates } from './maps';

export const ProviderCoverageAreaType = 'ProviderCoverageArea';
export const ExcludedNeighborhoodType = 'ExcludedNeighborhood';

export interface IProviderCoverageAreaEntity {
  PK:string;
  SK:string | null;
  archetype:string | null;
  id:string | null;
  serviceProviderId:string;
  entityType:string;
  GSIPK1:string;
  GSISK1:string;
}

export interface IProviderCoverageArea extends IProviderCoverageAreaEntity {
  name:string;
  wktType:string;
  coverageAreaWKT:string | null;
  coverageAreaGeoJSON:IGeoJSONCoordinates | null;
  entityType: 'ProviderCoverageArea';
}

export interface IExcludedNeighborhood extends IProviderCoverageAreaEntity{
  neighborhoodId:string;
  neighborhoodName:string;
  entityType: 'ExcludedNeighborhood';
}

export interface ICoveredNeighborhood extends IProviderCoverageAreaEntity{
  neighborhoodId:string;
  neighborhoodName:string;
  entityType: 'CoveredNeighborhood';
}

export interface IExcludedNeighborhoodDTO {
  excludedNeighborhood:IExcludedNeighborhood;
  add:boolean;
  delete:boolean;
}

export interface IProviderCoverageAreaDTO {
  delete:boolean;
  add: boolean;
  providerCoverageArea:IProviderCoverageArea;
  excludedNeighborhoods:IExcludedNeighborhoodDTO[];
}

export interface IProviderCoverageAreaFormDTO {
  serviceProviderId:string;
  providerCoverageAreas:IProviderCoverageAreaDTO[];
}

