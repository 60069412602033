import Axios, { AxiosResponse } from 'axios';
import { ICreateLookUpOptionRequest } from 'model/lookup/lookup';
import { INeighborhoodCampaignTag } from 'model/lookup/neighborhoodCampaignTag';

const apiUrl = window.REACT_APP_BASE_API_URI;
const baseUrl = `${apiUrl}/neighborhoodCampaignTags/v2`;

export function findAllNeighborhoodCampaignTags():Promise<AxiosResponse<INeighborhoodCampaignTag[]>> {
  return Axios.get(`${baseUrl}/findAll`);
}

export function createNeighborhoodCampaignTagOption(data: ICreateLookUpOptionRequest) {
  return Axios.post(`${baseUrl}/createNeighborhoodCampaignTag`, data);
}