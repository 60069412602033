import get from 'lodash/get';
import { IDiscountSchedule } from 'model/discountSchedule';
import { useState } from 'react';

function useDiscountScheduleForm(initialValues:IDiscountSchedule | null) {
  const [id, setId] = useState(initialValues?.id);
  // const [name, setName] = useState(initialValues?.name);
  const [priceLabel, setPriceLabel] = useState(initialValues?.priceLabel);
  const [priceAmount, setPriceAmount] = useState(initialValues?.priceAmount);
  const [priceSuffix, setPriceSuffix] = useState(initialValues?.priceSuffix);
  const [type, setType] = useState(initialValues?.type);
  const [discountIncrement, setDiscountIncrement] = useState(initialValues?.discountSchedule?.discountIncrement);
  const [maxDiscount, setMaxDiscount] = useState(initialValues?.discountSchedule?.maxDiscount);
  const [startingAt, setStartingAt] = useState(initialValues?.discountSchedule?.startingAt);
  const [decrement, setDecrement] = useState(initialValues?.pricingSchedule?.decrement);
  const [priceFloor, setPriceFloor] = useState(initialValues?.pricingSchedule?.priceFloor);
  const [priceStartingAt, setPriceStartingAt] = useState(initialValues?.pricingSchedule?.startingAt);
  const [providerId, setProviderId] = useState(initialValues?.providerId);
  const [serviceTypeId, setServiceTypeId] = useState(initialValues?.serviceTypeId);
  const [pricingScheduleStartingCountOffset, setPricingScheduleStartingCountOffset] = useState(initialValues?.pricingSchedule?.startingCountOffset);
  const [discountScheduleStartingCountOffset, setDiscountScheduleStartingCountOffset] = useState(initialValues?.discountSchedule?.startingCountOffset);


  const setterMap = {
    id: setId,
    // 'name':setName,
    priceLabel: setPriceLabel,
    priceAmount: setPriceAmount,
    priceSuffix: setPriceSuffix,
    type: setType,
    discountSchedule: {
      discountIncrement: setDiscountIncrement,
      maxDiscount: setMaxDiscount,
      startingAt: setStartingAt,
      startingCountOffset: setDiscountScheduleStartingCountOffset,
    },
    pricingSchedule: {
      decrement: setDecrement,
      priceFloor: setPriceFloor,
      startingAt: setPriceStartingAt,
      startingCountOffset: setPricingScheduleStartingCountOffset,
    },
    providerId: setProviderId,
    serviceTypeId: setServiceTypeId,
  };

  function initializeDynamic(propertyPath:string, val:any, setValue:Function) {
    //setValue param should be react hook form setValue
    setValue(propertyPath, val);
    if (!get(setterMap, propertyPath)) {
      console.warn(`Need to define setterMap function for propertyPath: ${propertyPath}`);
    } else {
      get(setterMap, propertyPath)(val);
    }
  }

  function setDynamic(propertyPath:string, val:any) {
    get(setterMap, propertyPath)(val);
  }

  //TODO: make recursive
  const setData = function(values: IDiscountSchedule, setValue:Function) {
    for (const p in values) {
      let propertyVal = get(values, p);
      if (typeof(propertyVal) === 'object' && Object.keys(p).length > 0) {
        for (const inner in propertyVal) {
          let innerPropertyVal = get(propertyVal, inner);
          if (innerPropertyVal === undefined) {
            throw new Error(`Could not find property ${inner} in ${p}`);
          }
          initializeDynamic(`${p}.${inner}`, innerPropertyVal, setValue);
        }
      } else {
        initializeDynamic(`${p}`, propertyVal, setValue);
      }
    }
  };

  return {
    id,
    setId,
    // name, setName,
    priceLabel,
    setPriceLabel,
    priceAmount,
    setPriceAmount,
    priceSuffix,
    setPriceSuffix,
    type,
    setType,
    discountSchedule: {
      discountIncrement,
      maxDiscount,
      startingAt,
    },
    setDiscountIncrement,
    setMaxDiscount,
    setStartingAt,
    pricingSchedule: {
      decrement,
      priceFloor,
      startingAt: priceStartingAt,
    },
    setDecrement,
    setPriceFloor,
    setPriceStartingAt,
    providerId,
    setProviderId,
    setData,
    initializeDynamic,
    setDynamic,
  };
}

export default useDiscountScheduleForm;