import createStore from 'hooks/hookStore';
import useToast from 'components/toast/useToast';
import { useLoading } from 'components/Layout/Loading';
import { useEffect, useState } from 'react';
import { ITsoiManagementBatch, ITsoiManagementBatchTerritoryServiceOffering } from 'model/tsoiManagementBatch';
import { getTsoiManagementBatchById, updateTsoiManagementBatchTerritoryServiceOfferings } from 'api/tsoiManagementApi';
import { ITerritory } from 'model/territories';
import { findTerritoryById } from 'api/territoriesApi';
import { useNavigate } from 'react-router-dom';

const loadingKey = 'TsoiManagementBatchServiceProvidersStore';
type TsoiManagementBatchServiceProvidersStore = {
  batch: ITsoiManagementBatch;
  territory: ITerritory;
  selectedServiceProviders: any[];
}
const createEmpty = () => ({
  batch: {} as ITsoiManagementBatch,
  territory: {} as ITerritory,
  selectedServiceProviders: [],
});

const { get, update, registerListener, unregisterListener } = createStore<TsoiManagementBatchServiceProvidersStore>('TsoiManagementBatchServiceProvidersStore', createEmpty());


export default function useTsoiManagementBatchServiceProviders() {
  const setState = useState(get())[1];
  const { onLoading, doneLoading } = useLoading(loadingKey);
  const { createErrorToast, createSuccessToast } = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    registerListener(setState);
    return () => {
      unregisterListener(setState);
    };
  }, []);

  async function init(batchId:string | undefined, territoryId:string | undefined) {
    if (!batchId) {
      console.log('No batch id provided');
      return;
    }

    if (!territoryId) {
      console.log('No territory id provided');
      return;
    }
    onLoading();
    try {
      const batchResp = await getTsoiManagementBatchById(batchId);
      if (!batchResp.data?.marketId) {
        createErrorToast('Error with batch: no market ID present. This is likely a defect, please consult the dev team.');
        return;
      }

      const territoryResp = await findTerritoryById(batchResp.data.marketId, territoryId);
      if (!territoryResp.data?.id) {
        createErrorToast('Error with territory: no territory found. This is likely a defect, please consult the dev team.');
        return;
      }

      const batch = batchResp.data;
      const territoryData = batch.territories[territoryId];
      const currentServiceProviders:ITsoiManagementBatchTerritoryServiceOffering[] = territoryData?.serviceOfferings || [];

      update({
        ...createEmpty(),
        selectedServiceProviders: currentServiceProviders,
        batch: batchResp.data,
        territory: territoryResp.data,
      });
    } catch (e:any) {
      createErrorToast('Error loading batch');
      console.error(e);
    }
    doneLoading(300);
  }

  function setSelectedServiceProviders(selectedServiceProviders:any[]) {
    update({
      ...get(),
      selectedServiceProviders,
    });
  }

  async function onSave() {
    try {
      const territoryId = get().territory.id;
      const batch = { ...get().batch };
      let territories = batch.territories;
      const territory = territories[territoryId];
      const newTerritory = { ...territory };
      // This is confusing, but the real entity we care about
      // for the batch is the service offerings. For now though,
      // we are just going to use the service providers as a proxy
      // because thats how the team thinks about this data
      // for TSOI management and Happening Soon invites.
      newTerritory.serviceOfferings = get().selectedServiceProviders;
      territories[territoryId] = newTerritory;
      batch.territories = territories;

      await updateTsoiManagementBatchTerritoryServiceOfferings(batch.id, territoryId, batch);
      createSuccessToast('Service providers saved');

      navigate(`/tsoiManagementBatches/${batch.id}/summary`);
    } catch (err: any) {
      const errorMessage = err.response?.data?.message || 'Please review the selections and try again.';
      createErrorToast(`Error saving service providers: ${errorMessage}`);
      console.error(err);
    }
    doneLoading();
  }

  async function onCancel() {
    navigate(`/tsoiManagementBatches/${get().batch.id}/summary`);
  }

  return {
    ...get(),
    init,
    loadingKey,
    onSave,
    onCancel,
    setSelectedServiceProviders,
  };
}