import { useFormContext, useWatch } from 'react-hook-form';
import useWelcomePageShortLink from './welcomePageShortLinkHookStore';
import { useEffect, useState } from 'react';

export default function GeneratedShortLink() {
  const { shortLinkResponse } = useWelcomePageShortLink();

  return (
    <div>
      <div>Short link (opens in new tab): </div>
      <a href={shortLinkResponse?.shortLink} target="_blank">{shortLinkResponse?.shortLink}</a>
    </div>
  );
}