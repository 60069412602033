import { Grid, Typography } from '@mui/material';
import { deleteNeighborConnectionsEmails } from 'api/neighborConnectionsApi';
import Loading, { useLoading } from 'components/Layout/Loading';
import GenericModal from 'components/modal/GenericModal';
import useModal, { IModal } from 'components/modal/useModal';
import useToast from 'components/toast/useToast';
import { INeighborConnectionBatchJobRequest } from 'model/neighborConnections';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

export const modalStoreKey = 'DeleteConnectionsEmailModal';
const loadingKey = 'DeleteConnectionsEmailModalLoader';

export interface IDeleteConnectionsEmailModal extends IModal {
  connectionIds: string[];
}

export function createInitialDeleteConnectionsEmailModal(connectionIds) :IDeleteConnectionsEmailModal {
  return {
    open: false,
    connectionIds: connectionIds,
  };
}

export function createEmptyDeleteConnectionsEmailModal() :IDeleteConnectionsEmailModal {
  return {
    open: false,
    connectionIds: [],
  };
}

export default function DeleteConnectionsEmailModal() {
  const { closeModal, getModalState } = useModal(modalStoreKey);
  const state = getModalState() as IDeleteConnectionsEmailModal;
  const { connectionIds = [] } = state;
  const { createSuccessToast, createErrorToast } = useToast();
  const { onLoading, doneLoading } = useLoading(loadingKey);
  const [requesting, setRequesting] = useState(false);

  const formContext = useForm({
    mode: 'onTouched',
    defaultValues: {
      dryRun: false,
    },
  });

  const onSubmit = async (data:any) => {
    setRequesting(true);
    onLoading();
    try {
      const formData = formContext.getValues();
      const request = {
        connectionIds: connectionIds,
        dryRun: formData.dryRun,
      } as INeighborConnectionBatchJobRequest;

      var resp = await deleteNeighborConnectionsEmails(request);
      var count = resp.data.processedCount;

      createSuccessToast('Successfully deleted ' + count + ' connection emails');
    } catch (error:any) {
      console.error(error);
      createErrorToast('Error deleting connection emails: ' + error.response?.data?.message);
    }

    doneLoading();
    setRequesting(false);
    closeModal(createEmptyDeleteConnectionsEmailModal());
  };

  return (
    <GenericModal
      modalStoreKey={modalStoreKey}
      title={'Delete Connection Emails'}
      modalContent={
        (
          <>
            <Loading loadingKey={loadingKey}>
              <Grid container justifyContent={'flex-start'}>
                <FormProvider {...formContext}>
                  <form onSubmit={formContext.handleSubmit(onSubmit)}>
                    <Grid container item justifyContent={'flex-start'} flexDirection={'column'}>
                      <Grid item xs={12}>
                        <Typography variant='body1'>{`Would you like to delete ${connectionIds.length} connection emails? This can not be undone.`}</Typography>
                      </Grid>
                    </Grid>
                  </form>
                </FormProvider>
              </Grid>
            </Loading>
          </>
        )
      }
      onConfirm={onSubmit}
      onSecondaryAction={() => closeModal(createEmptyDeleteConnectionsEmailModal())}
      primaryButtonText='Delete Emails'
      primaryDisabled={requesting}
      secondaryButtonText='Cancel'
    />
  );
}