import { Grid, Typography } from '@mui/material';
import styles from '../individuals.module.css';
import LaunchLeaderReferralLinkGenerator from './LaunchLeaderReferralLinkGenerator';
import useIndividualsHookStore from '../individualsHookStore';
import DetailItem from '../_components/DetailItem';
import RewardsAuditTable from './RewardsAuditTable';
import UpdateIndividualRewardBalanceForm from './UpdateIndividualRewardBalanceForm';
import { PrimaryButton } from 'components/buttons';
import useModal from 'components/modal/useModal';
import { modalStoreKey } from './UpdateBalanceModal';
import ServiceProviderConsumerReferralLinkGenerator from './ServiceProviderConsumerReferralLinkGenerator';
import GiftNeighborReferralLinkGenerator from './GiftNeighborReferralLinkGenerator';

export default function IndividualRewards() {
  const { individual } = useIndividualsHookStore();
  const { openModal } = useModal(modalStoreKey);
  return (
    <Grid container flexDirection='column'>
      <Grid container item xs={12} rowSpacing={5} justifyContent={'flex-start'}>
        <Grid container item xs={12} direction='row' alignItems={'center'}>
          <Grid item xs={2}>
            <DetailItem label={'Current Balance'} value={`$${individual.rewardsBalance}`} />
          </Grid>
          <Grid item xs={3}>
            <PrimaryButton
              onClick={() => openModal({})}
            >
              Update Balance
            </PrimaryButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={12}>
          <DetailItem label='Rewards History' value=''/>
        </Grid>
        <Grid item xs={12}>
          <RewardsAuditTable />
        </Grid>
      </Grid>
      <Grid container item>
        <Grid item xs={2} paddingTop={'25px'}>
          <Typography variant="h5" className={styles.header}>Referral Links</Typography>
        </Grid>
        <Grid item xs={12}>
          <LaunchLeaderReferralLinkGenerator />
        </Grid>
        <Grid item xs={12} paddingTop={5}>
          <GiftNeighborReferralLinkGenerator />
        </Grid>
        <Grid item xs={12}>
          <ServiceProviderConsumerReferralLinkGenerator />
        </Grid>
      </Grid>
    </Grid>
  );
}