
import { IServiceProvider } from 'model/serviceProvider';
import { ITerritory } from 'model/territories';
import { ITsoiManagementBatchTerritory, ITsoiManagementBatchTerritoryServiceType } from 'model/tsoiManagementBatch';

export function createInitialTsoiTerritoryFromITerritory(territory: ITerritory): ITsoiManagementBatchTerritory {

  const tsoiTerritory: ITsoiManagementBatchTerritory = {
    territoryId: territory.id,
    territoryName: territory.name,
    zipCodeTerritory: territory.zipCodeTerritory,
    status: 'DRAFT',
    serviceTypes: [],
  };

  return tsoiTerritory;
}

export function createTsoiServiceType(serviceTypeId:string, serviceTypeName:string): ITsoiManagementBatchTerritoryServiceType {
  const serviceType = {
    id: serviceTypeId,
    serviceType: serviceTypeName,
    serviceCategory: '',
  };

  return serviceType;
}

export function createTsoiServiceProviderFromServiceProviderAndServiceType(
  serviceProvider: IServiceProvider,
  serviceType: ITsoiManagementBatchTerritoryServiceType): any {
  const serviceProviderToSave = {
    serviceTypeId: serviceType.id,
    serviceType: serviceType.serviceType,
    serviceCategory: serviceType.serviceCategory,
    serviceProviderId: serviceProvider.id,
    serviceProviderName: serviceProvider.name,
    status: 'READY', // TODO: add new enum for this
  };

  return serviceProviderToSave;
}