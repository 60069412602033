import { Grid } from '@mui/material';
import { HookCheckbox } from 'components/reactHookForm';
import ExperimentTag from './formComponents/ExperimentTag';
import PageSubsectionHeader from 'components/SectionHeaders/PageSubsectionHeader';

export default function NeighborhoodExperiments() {
  return <Grid container item xs={12} spacing={1}>
    <Grid container item xs={12} justifyContent={'flex-start'}>
      <Grid item xs={12}>
        <PageSubsectionHeader text='Neighborhood Experiments' />
      </Grid>
      <Grid item xs={12} md={3} lg={3}>
        <HookCheckbox
          name='isExperimental'
          label='Is experimental?'
        />
      </Grid>
      <Grid item xs={6} md={4} lg={3}>
        <ExperimentTag />
      </Grid>
    </Grid>
  </Grid>;
}