import { Grid } from '@mui/material';
import { HookCheckbox } from 'components/reactHookForm';

function WorkflowStep({ workflowStep, index }:any) {
  return (
    <Grid container item spacing={1} alignItems='center'>
      <Grid item>
        {index + 1}. {workflowStep.stepName}
      </Grid>
      <Grid item style={{ marginLeft: '15px' }}>
        <HookCheckbox name={`workflowSteps.${index}.shouldShow`}
          label="Should show this field in provider portal?"/>
      </Grid>
    </Grid>
  );
}
export default function WorkflowSteps({ workflowSteps }:any) {

  return (
    <Grid container item spacing={1} alignItems='center'>
      {workflowSteps && workflowSteps.map((step, i) => {
        return <WorkflowStep key={i} workflowStep={step} index={i}/>;
      })}
    </Grid>
  );
}