import Userfront from '@userfront/react';
import SignInHeader from 'components/Header/signInHeader';

export default function SignIn() {

  Userfront.init(window.REACT_APP_ADMIN_USERFRONT_TENANT);

  const LoginForm = Userfront.build({
    toolId: window.REACT_APP_ADMIN_USERFRONT_LOGIN,
  });

  return (
    <>
      <SignInHeader bottomMargin='30px' />
      <LoginForm />
    </>

  );
}