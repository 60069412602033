import { FormControl, FormHelperText, InputLabel } from '@mui/material';
import { HookSelect } from 'components/reactHookForm';
import useErrorState from 'hooks/reactHookForm/useErrorState';
import { LeadSource } from 'model/serviceProvider';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { sortByString } from 'util/sortFunctions';

const sortByName = sortByString('name');

export default function LeadSourceSelect() {
  const formCtx = useFormContext();
  const [states] = useState<any[]>(LeadSource.list.sort(sortByName));
  const { isError, getErrorMessage } = useErrorState(formCtx);
  return (
    <FormControl variant='standard' fullWidth error={isError('leadSource')}>
      <InputLabel htmlFor="LeadSourceSelect">Lead Source</InputLabel>
      <HookSelect
        rules={{
          required: { value: false, message: 'Required' },
        }}
        control={formCtx.control}
        name='leadSource'
        id='LeadSourceSelect'
        menuItems={states}
      />
      <FormHelperText>{getErrorMessage('leadSource')}</FormHelperText>
    </FormControl>
  );
}