interface ICenteredProps {
  //** can be 'vertical', 'horizontal', or 'both' */
  centerType:string;
  children:any;
  className?:string;
  otherProps?: any;
}

export default function Centered({ children, centerType, className, otherProps }:ICenteredProps) {

  const style:any = { display: 'flex' };
  if (centerType === 'vertical' || centerType === 'both') {
    style.alignItems = 'center';
  }
  if (centerType === 'horizontal' || centerType === 'both') {
    style.justifyContent = 'center';
  }

  return (
    <div style={style} className={className} {...otherProps}>
      {children}
    </div>
  );
}

