import { Grid } from '@mui/material';
import { HookCheckbox, HookTextField } from 'components/reactHookForm';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { IMaskInput } from 'react-imask';

interface VenmoMaskProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const VenmoMask = React.forwardRef<HTMLElement, VenmoMaskProps>(
  function TextMaskCustom(props, ref:any) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask={/[a-zA-Z0-9-_]+$/}
        inputRef={ref}
        onAccept={(value: any, mask:any) => {
          return onChange({ target: { name: props.name, value: mask._unmaskedValue } });
        }
        }
        overwrite
      />
    );
  },
);

interface CashappMaskProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const CashappMask = React.forwardRef<HTMLElement, CashappMaskProps>(
  function TextMaskCustom(props, ref:any) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask='{$}CASH'
        blocks={{
          CASH: {
            mask: /.*$/,
          },
        }}
        validate={function(value) {
          return value.length > 0 && value.length < 20;
        }}
        inputRef={ref}
        onAccept={(value: any, mask:any) => {
          return onChange({ target: { name: props.name, value: mask._unmaskedValue } });
        }}
        overwrite
      />
    );
  },
);

export default function AcceptedPaymentTypes() {
  const { control } = useFormContext();

  const hasVenmoWatch = useWatch({ control, name: 'acceptsVenmo' });
  const hasCashAppWatch = useWatch({ control, name: 'acceptsCashApp' });
  return (
    <Grid container item spacing={1} xs={12} alignItems='center'>
      <Grid item xs={12} md='auto'>
        <HookCheckbox
          name='acceptsCreditCard'
          label='Accepts credit card'
        />
      </Grid>
      <Grid item xs='auto'>
        <HookCheckbox
          label='Has Venmo'
          name='acceptsVenmo'
        />
      </Grid>
      {hasVenmoWatch && (
        <Grid item xs={3}>

          <HookTextField
            label='Venmo Id'
            name='venmoId'
            InputProps={{
              inputComponent: VenmoMask as any,
            }}
            validationRules={{
              validate: function(value) {
                if (value.length < 5) {
                  return 'Must be 5 characters or longer';
                }
                if (value.length > 30) {
                  return 'Must be less than or equal 30 characters';
                }
                return true;
              },
            }}
          />
        </Grid>
      )}
      <Grid item xs='auto'>
        <HookCheckbox
          label='Has Cash App'
          name='acceptsCashApp'
        />
      </Grid>
      {hasCashAppWatch && (
        <Grid item xs={3}>
          <HookTextField
            label='Cash App Id'
            name='cashAppId'
            InputProps={{
              inputComponent: CashappMask as any,
            }}
            validationRules={{
              validate: function(value) {
                if (!/.*[a-zA-z].*/.test(value)) {
                  return 'must have at least one character';
                }
                return true;
              },
            }}
          />
        </Grid>
      )}
    </Grid>
  );
}