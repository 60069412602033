import Axios, { AxiosResponse } from 'axios';
import { CompensateRewardRequest, ICreateRewardCodesForm, IRewardCode } from 'model/rewards';

export function findRewardCodes():Promise<AxiosResponse<IRewardCode[]>> {
  return Axios.get(`${window.REACT_APP_BASE_API_URI}/rewards/v2/findAll`);
}

export function generateUnusedCodes(data:ICreateRewardCodesForm) {
  return Axios.post(`${window.REACT_APP_BASE_API_URI}/rewards/v2/generateUnusedCodes`, data);
}

export function compensateReward(individualId:string, data:CompensateRewardRequest) {
  return Axios.post(`${window.REACT_APP_BASE_API_URI}/rewards/v2/individuals/${individualId}/compensateReward`, data);
}

export function issueRewardCodes() {
  return Axios.post(`${window.REACT_APP_BASE_API_URI}/rewards/v2/issueRewardCodes`);
}
