import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@mui/material';
import { ICoveredNeighborhood } from 'model/providerCoverageArea';
import { useEffect, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useFormContext, useWatch } from 'react-hook-form';
import useProviderCoverageArea from '../hookStore/useProviderCoverageArea';

export interface ICoveredNeighborhoodsProps {
  index: number;
}

export default function CoveredNeighborhoods({ index }:ICoveredNeighborhoodsProps) {
  const { onFindCoveredNeighborhoods } = useProviderCoverageArea();
  const { control, getValues } = useFormContext();
  const [coveredNeighborhoods, setCoveredNeighborhoods] = useState<ICoveredNeighborhood[]>([]);
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  async function populateCoveredNeighborhoods() {

    const dto = getValues(`providerCoverageAreas.${index}`);
    await onFindCoveredNeighborhoods(dto, (data:ICoveredNeighborhood[]) => {
      setCoveredNeighborhoods(data);
    });
  }

  const watchCoordinates = useWatch({ control, name: `providerCoverageAreas.${index}.coordinates` });
  useEffect(() => {
    if (watchCoordinates && watchCoordinates.length > 0) {
      void populateCoveredNeighborhoods();
    }
  }, [watchCoordinates]);

  return (
    <Accordion expanded={expanded === 'covered-neighborhoods'} onChange={handleChange('covered-neighborhoods')}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="covered-neighborhoods-content"
        id="covered-neighborhoods-header"
      >
        Covered Neighborhoods
      </AccordionSummary>
      <AccordionDetails>
        <Grid container justifyContent={'center'} sx={{ overflowY: 'auto', maxHeight: '600px' }}>
          {coveredNeighborhoods.map((x, i) => {

            return (
              <Grid
                item
                xs={5}
                key={x.neighborhoodId}
                sx={{
                  padding: '10px',
                  margin: '4px',
                  border: '1px solid gainsboro',
                }}
              >
                <div>{i+1}. {x.neighborhoodName}</div>
              </Grid>
            );
          })}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}