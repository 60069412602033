import { Grid, FormControlLabel, Checkbox } from '@mui/material';
import SeedFactorSelect from './SeedFactorSelection';
import { HookCheckbox } from 'components/reactHookForm';
import { useFormContext } from 'react-hook-form';

export interface IServiceOfferingSeedingRowProps {
  serviceOfferingProjection: any;
  index: number;
}


export default function ServiceOfferingSeedingRow({
  index,
  serviceOfferingProjection,
}:IServiceOfferingSeedingRowProps,
) {
  const formContext = useFormContext();
  return (
    <Grid container item sx={{ paddingTop: '16px' }}>
      <Grid item xs='auto' >
        <HookCheckbox
          name={`serviceOfferingsWithSeedFactor.${index}.checked`}
          label={serviceOfferingProjection.label}
          postOnChange={(e:any) => {
            if (e.target.checked) {
              formContext.setValue(`serviceOfferingsWithSeedFactor.${index}.id`, serviceOfferingProjection.id);
            } else {
              formContext.setValue(`serviceOfferingsWithSeedFactor.${index}.id`, null);
            }
          }}
        />
      </Grid>
      <Grid item sm={1}></Grid>
      <Grid item xs={12} sm={2}>
        <SeedFactorSelect
          serviceOfferingId={serviceOfferingProjection.id}
          index={index}
        />
      </Grid>
    </Grid>
  );
}