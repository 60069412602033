export interface IGenerateWelcomePageLinkForm {
  neighborhoodId:string;
  neighborhoodName:string;
  fbp:string | null;
  mc: string | null;
}

export function createEmptyGenerateWelcomePageLinkForm():IGenerateWelcomePageLinkForm {
  return {
    neighborhoodId: '',
    neighborhoodName: '',
    fbp: '',
    mc: '',
  };
}