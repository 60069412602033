import { IMinimalCustomerDTO } from './participant';
import { format, getDate } from 'date-fns';

export interface INeighborhoodServiceOfferingInstance {
  id: string;
  neighborhoodServiceOfferingId: string;
  serviceDate: string;
  localServiceDate: string;
  endServiceDate: string | null;
  status: string;
  customerCount: number | null;
  maxCustomerCount: number | null;
  neighborhoodId: string;
  deletedDate: Date;
  isForCustomerUpload: boolean | null;
  firstCustomerId: string;
  origin: string;
  cutoffDayCount: number | null;
  discountScheduleId: string;
  discountScheduleVersion: string;
  customers: IMinimalCustomerDTO[];
  serviceProviderId: string;
  serviceTypeName: string;
  neighborhoodName: string;
  zipCodeTerritory: string;
  providerCoverageZone:string;
  serviceInstanceType:string;
}

export interface IServiceInstanceForm {
  id?:string;
  neighborhoodId:string;
  neighborhoodServiceOfferingId:string;
  localServiceDate:string;
  serviceDate:string;
  endServiceDate:string | null;
  status:string;
  cutoffDayCount: number | null;
}

export function createEmptyServiceInstanceForm():IServiceInstanceForm {
  return {
    id: '',
    neighborhoodId: '',
    neighborhoodServiceOfferingId: '',
    localServiceDate: format(new Date(), 'yyyy-MM-dd'),
    serviceDate: new Date().toISOString(),
    status: '',
    cutoffDayCount: null,
    endServiceDate: null,
  };
}

export class NeighborhoodServiceOfferingInstanceCommunicationType {
  static get PRICE_DROP_ALERT():string {
    return 'PRICE_DROP_ALERT';
  }

  static list = [
    { id: NeighborhoodServiceOfferingInstanceCommunicationType.PRICE_DROP_ALERT, name: 'Price Drop Alert' },
  ];
}

export class StatusType {
  static get ACTIVE():string {
    return 'ACTIVE';
  }
  static get PENDING():string {
    return 'PENDING';
  }
  static get COMPLETED():string {
    return 'COMPLETED';
  }
  static get CANCELLED():string {
    return 'CANCELLED';
  }

  static list = [
    { id: StatusType.PENDING, name: 'Pending' },
    { id: StatusType.ACTIVE, name: 'Active' },
    { id: StatusType.COMPLETED, name: 'Completed' },
    { id: StatusType.CANCELLED, name: 'Cancelled' },
  ];
}

export interface INotifyOfHappeningSoonForm {
  overridePhoneNumbers:string[];
  overrideEmails:string[];
}

export function createEmptyNotifyHappeningSoonForm():INotifyOfHappeningSoonForm {
  return {
    overridePhoneNumbers: [],
    overrideEmails: [],
  };
}