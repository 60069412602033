import { Grid, Typography } from '@mui/material';
import { toggleIgnoreForReconciliationNeighborhoodServiceOfferingsById } from 'api/neighborhoodServiceOfferingApi';
import GenericModal from 'components/modal/GenericModal';
import useModal, { IModal } from 'components/modal/useModal';
import { CustomerRollbackType, ICustomerUploadDeleteByIdForm, createEmptyCustomerUploadDeleteByIdForm } from 'model/customerUpload';

export const modalStoreKey = 'confirmCustomerUploadRollbackModal';
export interface IConfirmRollbackModal {
  formData: ICustomerUploadDeleteByIdForm;
  callback: (formData: ICustomerUploadDeleteByIdForm) => Promise<void>;
}
export function createEmptyConfirmRollbackModal() :IConfirmRollbackModal {
  return {
    formData: createEmptyCustomerUploadDeleteByIdForm(),
    callback: async (formData: ICustomerUploadDeleteByIdForm) => {},
  };
}
export interface IConfirmRollbackModalProps {

}

export default function ConfirmRollbackModal({ }:IConfirmRollbackModalProps) {
  const { getModalState, closeModal } = useModal(modalStoreKey);
  const _state = getModalState() as IConfirmRollbackModal & IModal;
  const { formData, callback } = _state;
  async function _onConfirm(state:IConfirmRollbackModal & IModal) {
    await callback(state.formData);
    closeModal(createEmptyConfirmRollbackModal());
  }

  return (
    <GenericModal
      modalStoreKey={modalStoreKey}
      title={'Confirm Customer Upload Rollback'}
      modalContent={
        (
          <div >
            <Typography variant='body1' sx={{ paddingBottom: '10px' }}>This will delete customers, subscribers, nsois, and individuals in dynamo</Typography>
            {formData?.customerRollbackType == CustomerRollbackType.BY_NEIGHBORHOOD && (
              <div>Deleting customer upload for neighborhood: {formData.selectedNeighborhood?.optionText}</div>
            )}
            {formData?.customerRollbackType == CustomerRollbackType.BY_SERVICE_PROVIDER && (
              <div>Deleting customer upload for neighborhood: {formData.selectedServiceProvider?.optionText}</div>
            )}
            {formData?.customerRollbackType == CustomerRollbackType.BY_REQUEST_ID && (
              <div>Deleting customer upload for request id: {formData.requestId}</div>
            )}
            {formData?.customerRollbackType == CustomerRollbackType.BY_PROCESSING_ID && (
              <div>Deleting customer upload for processing id: {formData.processingId}</div>
            )}
            {formData?.shouldRollbackStagingCustomers === false && (
              <Typography variant='body1' sx={{ color: 'red', paddingTop: '10px' }}>Staging Customers will not be reset!</Typography>
            )}
          </div>
        )
      }
      onConfirm={() => _onConfirm(_state)}
      secondaryButtonText='Cancel'
    />
  );
}