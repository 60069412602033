import styles from '../../providerOnboarding.module.css';
import { Add, Check } from '@mui/icons-material';
import { useState } from 'react';
import { Button } from '@mui/material';

export default function SuggestionPillButton({ text, onClick }) {
  const [wasClicked, setWasClicked] = useState(false);
  const startIcon = wasClicked ? <Check/> : <Add/>;
  return (
    <Button
      className={styles.suggestionPillButton}
      startIcon={startIcon}
      disabled={wasClicked}
      onClick={()=> {
        onClick();
        setWasClicked(true);
      }
      }>
      {text}
    </Button>
  );
}