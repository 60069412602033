import { Launch, Rsvp, Link as LinkIcon, Replay } from '@mui/icons-material';
import { Card, Grid, IconButton, Link, Tooltip, Typography } from '@mui/material';
import { EditButton } from 'components/buttons';
import useProviderSetupStore from '../useProviderSetupStore';
import { useNavigate, useParams } from 'react-router-dom';
import useToast from 'components/toast/useToast';


function DetailItem ({ label, value }) {
  return (
    <>
      <Grid container item xs={12} sx={{ flexDirection: 'rox', alignItems: 'center' }}>
        <Typography variant="subtitle1" sx={{ fontWeight: 'bold', marginRight: '5px' }}>{label}</Typography>
        <Typography variant="body1">{value}</Typography>
      </Grid>
    </>
  );
}

export default function ServiceOfferingSummaryCard({ serviceOffering, index, nso, discountSchedule }) {
  const { setCurrentServiceOffering } = useProviderSetupStore();
  const navigate = useNavigate();
  const { createErrorToast, createInfoToast } = useToast();
  const { providerId } = useParams();

  async function onClickEditServiceOfferingLink() {
    try {
      navigate(`/providerOnboarding/${providerId}/service-offerings`);
    } catch (error) {
      console.error('error trying to revisit service offering: ', { error, serviceOffering });
    }
  }

  return (
    <Grid container item xs={5} sx={{ flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start' }}>
      <Card sx={{ padding: '15px', width: '100%' }}>
        <Typography sx={{ textDecoration: 'underline', fontWeight: 'bold' }}>Service Offering #{index + 1}</Typography>
        <DetailItem label="Type:" value={serviceOffering?.serviceType} />
        <DetailItem label="Cadence:" value={serviceOffering?.cadenceType} />
        <DetailItem label="Discount: " value={discountSchedule?.name} />
        <Grid container item sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
          <Link href={`/service-providers/${serviceOffering?.providerId}/service-offering-details/${serviceOffering?.id}`} target="_blank">
            <EditButton tooltipTitle={'View and edit the full details on the Service Offering Details page'} onClick={() => {}} />
          </Link>
          <Link href={`/providerOnboarding/${serviceOffering?.providerId}/service-offerings/${serviceOffering?.id}`} rel='noreferrer'>
            <Tooltip title={'Revisit this service offering in step 2 of this tool'}>
              <IconButton >
                <Replay />
              </IconButton>
            </Tooltip>
          </Link>
          {
            nso?.id && (<Link href={`${window.REACT_APP_CONSUMER_BASE_URI}/neighborhood-service-offering/${nso?.id}`} target="_blank" rel='noreferrer'>
              <Tooltip title={'View the NSO on streetfair.app'}>
                <IconButton >
                  <Launch />
                </IconButton>
              </Tooltip>
            </Link>)
          }
          {
            nso?.id && (<Tooltip title={'Copy the "Claim your business" link for this NSO'}>
              <IconButton
                onClick={() => {
                  var link = `${window.REACT_APP_CONSUMER_BASE_URI}/provider-invite/neighborhood-service-offering/${nso?.id}`;
                  navigator.clipboard.writeText(link)
                    .then(() => createInfoToast(`Claim your business link copied to clipboard: ${link}`))
                    .catch(reason => createErrorToast(reason));
                }}
              >
                <Grid container>
                  <Rsvp />
                  <LinkIcon />
                </Grid>
              </IconButton>
            </Tooltip>)
          }
        </Grid>
      </Card>
    </Grid>
  );
}