import { FormControl, InputLabel, FormHelperText } from '@mui/material';
import { HookSelect } from 'components/reactHookForm';
import useErrorState from 'hooks/reactHookForm/useErrorState';
import { useFormContext } from 'react-hook-form';

interface ISeedFactorSelectProps {
  serviceOfferingId: string;
  postOnChange: Function;
}

export default function SeedFactorSelect( { serviceOfferingId, postOnChange } : ISeedFactorSelectProps) {
  const formCtx = useFormContext();
  const { isError, getErrorMessage } = useErrorState(formCtx);

  const seedFactorOptions = [1, 2, 3, 4, 5]
    .map((x, i) => ({
      key: `${x}`,
      optionText: `${x}`,
      optionValue: x,
    }));

  return (
    <FormControl variant='standard' fullWidth error={isError('seedFactor')}>
      <InputLabel htmlFor={`seed-factor-${serviceOfferingId}`}>Seed Factor</InputLabel>
      <HookSelect
        control={formCtx.control}
        postOnChange={async (event) => {
          postOnChange(event);
        }}
        name={`seedFactor-${serviceOfferingId}`}
        id={`seed-factor-${serviceOfferingId}`}
        menuItems={seedFactorOptions}
        menuItemKeys={{
          key: 'id',
          value: 'optionValue',
          displayValue: 'optionText',
        }}
      />
      <FormHelperText>{getErrorMessage(`seedFactor-${serviceOfferingId}`)}</FormHelperText>
    </FormControl>
  );
}