import { Grid } from '@mui/material';
import { PrimaryButton } from 'components/buttons';
import { ConfirmationModalContext } from 'components/modals/ConfirmationModal';
import { HookCheckbox } from 'components/reactHookForm';
import { ICustomerUploadFormV2 } from 'model/customerUpload';
import { createDefaultDropdownOption, createUninitializedDropdownOption } from 'model/dropdown';
import { useContext, useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm, useWatch } from 'react-hook-form';
import CustomerTemplateUpload from './customerTemplateUpload';
import ServiceProviderSelect from './ServiceProviderSelect';
import useCustomerUpload from './hookStore/customerUploadHookStore';
import ServiceOfferingAllocations from './ServiceOfferingAllocations';

export default function CustomerUploadForm() {
  const { openModal } = useContext(ConfirmationModalContext);

  const formContext = useForm<ICustomerUploadFormV2>({
    mode: 'onTouched',
    defaultValues: {
      customersTemplate: undefined,
      fileName: undefined,
      shouldSetProviderUploaded: true,
      selectedServiceProvider: null,
    },
  });
  const selectedServiceProvider = useWatch({ control: formContext.control, name: 'selectedServiceProvider' });

  const onSubmit : SubmitHandler<ICustomerUploadFormV2> = data => {
    const serviceProviderId = selectedServiceProvider?.optionValue;
    openModal({ serviceProviderId, data: data, fileName: data.customersTemplate.name, shouldUpload: true, formContext });
  };


  return (
    <FormProvider {...formContext}>
      <form onSubmit={formContext.handleSubmit(onSubmit)}>
        <Grid container spacing={1}>
          <Grid item xs={11}>
            <HookCheckbox
              name='shouldSetProviderUploaded'
              label='Set Provider Uploaded Flag'
            />
          </Grid>
          <Grid item xs={4}>
            <ServiceProviderSelect/>
          </Grid>
          <Grid item xs={12}></Grid>
          <Grid item xs={4}>
            <CustomerTemplateUpload/>
          </Grid>
          <Grid item xs={8}>
            <ServiceOfferingAllocations/>
          </Grid>
          <Grid item xs={11} >
            <PrimaryButton type='submit' style={{ marginTop: '10px' }}>Submit</PrimaryButton>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
}