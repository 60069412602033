import { Alert, Snackbar } from '@mui/material';
import { useEffect } from 'react';
import useToast from './useToast';

export default function Toast() {
  const {
    messageInfo,
    snackPack,
    open,
    handleClose,
    closeToast,
    addToastInternal,
    handleExited,
  } = useToast();

  useEffect(() => {
    if (snackPack.length && !messageInfo.message) {
      addToastInternal();
    } else if (snackPack.length && messageInfo && open) {
      closeToast();
    }
  }, [messageInfo, snackPack, open]);
  const color = messageInfo.severity || 'warning';
  return (
    <Snackbar
      key={messageInfo ? messageInfo.key : undefined}
      open={open}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      autoHideDuration={messageInfo?.persistent ? null : 6000}
      onClose={handleClose}
      TransitionProps={{ onExited: handleExited }}
    >
      <div>
        <Alert
          severity={color}
          variant='filled'
          onClose={(e) => handleClose(e, null)}
        >
          {messageInfo ? messageInfo.message : undefined}
        </Alert>
        {messageInfo?.component}
      </div>
    </Snackbar>
  );
}