import { Grid, Button } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import CopyToClipboardCell from 'components/DataGridCell/CopyToClipboard';
import PageHeader from 'components/SectionHeaders/PageHeader';
import QuickSearchToolbar from 'components/grid/QuickSearchToolbar';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useTableSearch from 'components/grid/useTableSearch';
import { sortByString } from 'util/sortFunctions';
import { EditButton, RemoveButton } from 'components/buttons';
import styles from './markets.module.css';
import useModal from 'components/modal/useModal';
import useMarketsTable from './hookStore/useMarketsTable';

const sortByName = sortByString('name');
const quickSearchStorageKey = 'markets';

function EditCell({ id }) {
  const navigate = useNavigate();
  function handleClick() {
    navigate(`/markets/${id}`);
  }
  return (
    <EditButton
      tooltipTitle={`Edit ${name}`}
      onClick={handleClick}
      tooltipProps={{
        placement: 'top',
      }}
      iconButtonProps={{
        className: styles.actionButton,
      }}
    />
  );
}

export default function Markets() {
  const navigate = useNavigate();
  const {
    markets,
    init,
    onConfirmDelete,
  } = useMarketsTable();
  const { rows, searchText, setRowsWithStoredFilter, requestSearch, clearStoredSearch } = useTableSearch(quickSearchStorageKey);
  const [sortModel, setSortModel] = useState([
    {
      field: 'name',
      sort: 'asc' as any,
    },
  ]);

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      width: 500,
      renderCell: (params: any) => {
        return <div>{params.row.name}</div>;
      },
    },
    {
      field: 'id',
      headerName: 'GUID',
      width: 500,
      renderCell: (params: any) => {
        return (<CopyToClipboardCell val={params.id}/>);
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 200,
      renderCell: (params:any) => {
        return (
          <Grid container item xs={12} justifyContent='center' alignItems='center'>
            <EditCell id={params.id}/>
          </Grid>
        );
      },
    },
  ];
  const columnTotalWidth = columns.map(x => x.width).reduce((a:any, b:any) => a+b, 0);

  function addNewButtonClick(e: any) {

    navigate('/markets/new');
  }

  useEffect(() => {
    void init(setRowsWithStoredFilter);
  }, []);

  return (
    <Grid container spacing={2} className='pageGridContainer'>
      <Grid item xs={12}>
        <PageHeader headerText={'Markets'} />
      </Grid>
      <>
        <Grid item xs={12}>
          <Button variant="contained" onClick={addNewButtonClick}>Add New</Button>
        </Grid>
        <Grid item xs={12}>
          <DataGrid
            rows={rows}
            components={{ Toolbar: QuickSearchToolbar }}
            componentsProps={{
              toolbar: {
                quickSearchStorageKey,
                value: searchText,
                onChange: (event: React.ChangeEvent<HTMLInputElement>) => requestSearch(markets, event.target.value),
                clearSearch: () => requestSearch(markets, ''),
              },
            }}
            columns={columns}
            autoHeight={true}
            sortModel={sortModel}
            onSortModelChange={setSortModel}
            style={{
              minWidth: '500px',
              maxWidth: `${columnTotalWidth}px`,
            }}
          />
        </Grid>
      </>

    </Grid>


  );
}