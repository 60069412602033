import { Typography } from '@mui/material';
import { useEffect } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';

export default function TagCount() {
  const { control } = useFormContext();
  const { fields: tags, append, replace, remove } = useFieldArray({
    name: 'tags',
  });
  const watchTags = useWatch({ control, name: 'tags' });
  useEffect(() => {
    if (tags.length !== watchTags.length) {
      replace(watchTags);
    }
  }, [watchTags]);
  return (
    <Typography> Tags ({tags.length})</Typography>
  );
}