import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import useTerritorySeedingTemplates from '../hookStore/useTerritorySeedingTemplates';
import get from 'lodash.get';


export default function TerritorySelect() {
  const { territories, onTerritorySelect, selectedTerritory } = useTerritorySeedingTemplates();
  return (
    <>
      <FormControl variant="outlined" fullWidth>
        <InputLabel id="territory-select-label">Territory</InputLabel>
        <Select
          onChange={async (e) => {
            await onTerritorySelect(e.target.value);
          }}
          label='Territory'
          placeholder='Select Territory'
          value={selectedTerritory}
          id={'territory-select'}
          labelId='territory-select-label'
          fullWidth>
          {territories && territories.map((x: any, i: number) => {
            return (
              <MenuItem
                key={get(x, 'id', i)}
                value={get(x, 'optionValue', x.id)}>
                <span>{get(x, 'optionText', x.name)}</span>
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </>
  );
}