import { findRewardCodes, issueRewardCodes } from 'api/rewardsApi';
import { useLoading } from 'components/Layout/Loading';
import useToast from 'components/toast/useToast';
import createStore from 'hooks/hookStore';
import { createEmptyRewardCodesForm, ICreateRewardCodesForm, IRewardCode } from 'model/rewards';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const loadingKey = 'RewardCodeExport';
type RewardCodeExportStore = {
  unusedChecked:boolean;
  rewardCodes: IRewardCode[];
  filteredRewardCodes: IRewardCode[];
}

const { get, update, registerListener, unregisterListener } = createStore<RewardCodeExportStore>('RewardCodeExport', {
  rewardCodes: [],
  filteredRewardCodes: [],
  unusedChecked: true,
});

export default function useRewardCodeExport() {
  const setState = useState(get())[1];
  const navigate = useNavigate();
  const { onLoading, doneLoading } = useLoading(loadingKey);
  const { createErrorToast, createSuccessToast, createInfoToast } = useToast();

  useEffect(() => {
    registerListener(setState);
    return () => {
      unregisterListener(setState);
    };
  }, []);

  async function init() {
    onLoading();
    try {
      const res = await findRewardCodes();
      update({
        ...get(),
        rewardCodes: res.data,
        filteredRewardCodes: res.data.filter(x => x.rewardCodeStatus === 'UNUSED'),
      });
    } catch (e:any) {
      console.error(e);
    }
    doneLoading(300);
  }

  function toggleUnusedCheckbox(nextVal:boolean) {
    if (nextVal) {
      const { rewardCodes } = get();
      update({
        ...get(),
        unusedChecked: true,
        filteredRewardCodes: rewardCodes.filter(x => x.rewardCodeStatus === 'UNUSED'),
      });
    } else {
      const { rewardCodes } = get();
      update({
        ...get(),
        unusedChecked: false,
        filteredRewardCodes: rewardCodes,
      });
    }
  }

  async function onIssueRewardCodes() {
    try {
      onLoading();
      await issueRewardCodes();
      createSuccessToast('Success. You will receive an email with the issued codes.');
    } catch (err:any) {
      console.log(err);
      createSuccessToast('Failure.');
    }
    doneLoading();
    await init();
  }

  return {
    ...get(),
    loadingKey,
    init,
    toggleUnusedCheckbox,
    onIssueRewardCodes,
  };
}