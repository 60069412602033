import { findServiceCategoryAdminDTO, saveServiceCategoryAdminDTO } from 'api/serviceCategoryApi';
import { useLoading } from 'components/Layout/Loading';
import useModal from 'components/modal/useModal';
import useToast from 'components/toast/useToast';
import createStore from 'hooks/hookStore';
import { createEmptyServiceCategoryForm, IServiceCategory, IServiceCategoryAdminDTO, IServiceCategoryAdminRequest, IServiceCategoryForm, IServiceTypeInfoCardMetadatum, IServiceTypeInfoMetadatum, ManageServiceTypeMode } from 'model/serviceCategory';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { createEmptySelectMarketModal, modalStoreKey as selectMarketModalKey } from '../components/pageConfigModal/SelectMarketModal';
import { createEmptySelectTerritoryModal, modalStoreKey as selectTerritoryModalKey } from '../components/pageConfigModal/SelectTerritoryModal';
import { findMarketById } from 'api/marketsApi';
import { IMarket } from 'model/markets';
import { ITerritory } from 'model/territories';
import { findTerritoryById } from 'api/territoriesApi';
import useMarkets from 'hooks/useMarkets';

const loadingKey = 'ServiceCategoryDetailStore';


type ServiceCategoryDetailStore = {
  formData:IServiceCategoryForm;
  originalServiceTypeInfoMetadata: IServiceTypeInfoCardMetadatum[];
  sortableServiceTypes: IServiceTypeInfoCardMetadatum[][];
  availableServiceTypes: IServiceTypeInfoCardMetadatum[][];
  rowItemCount: number;
  availableRowItemCount: number;
  availableServiceTypesExpanded:boolean;
  prevSelectedTabs: string[];
  selectedTab: string;
  selectedMarket: IMarket | null;
  selectedTerritory:ITerritory | null;
}

const { get, update, registerListener, unregisterListener } = createStore<ServiceCategoryDetailStore>('ServiceCategoryDetailStore', {
  formData: createEmptyServiceCategoryForm(),
  originalServiceTypeInfoMetadata: [],
  sortableServiceTypes: [],
  availableServiceTypes: [],
  rowItemCount: 4,
  availableRowItemCount: 5,
  availableServiceTypesExpanded: false,
  prevSelectedTabs: ['1'],
  selectedTab: '1',
  selectedMarket: null,
  selectedTerritory: null,
});

export default function useServiceCategoryDetailStore() {
  const setState = useState(get())[1];
  const navigate = useNavigate();

  const { serviceCategoryId, mode, marketId, territoryId } = useParams();
  const { onLoading, doneLoading } = useLoading(loadingKey);
  const { createErrorToast } = useToast();
  const { selectedMarket: savedMarket } = useMarkets();
  const { openModal: openSelectMarketModal } = useModal(selectMarketModalKey);
  const { openModal: openSelectTerritoryModal } = useModal(selectTerritoryModalKey);

  useEffect(() => {
    registerListener(setState);
    return () => {
      unregisterListener(setState);
    };
  }, []);

  async function init() {
    const { rowItemCount, availableRowItemCount } = get();
    onLoading();
    try {
      if (mode === ManageServiceTypeMode.CREATE) {
        const res = await findServiceCategoryAdminDTO({
          serviceCategoryId: null,
          marketId: null,
          zipCodeTerritory: null,
        });
        if (res.data) {
          let filtered = res.data.availableServiceTypeMetadata.filter(x => !x.deleteDate);
          var availableServiceTypes = createSortableServiceTypeRows(filtered, availableRowItemCount);
          update({
            ...get(),
            originalServiceTypeInfoMetadata: filtered,
            formData: createForm(res.data),
            availableServiceTypes: availableServiceTypes,
            sortableServiceTypes: [],
            selectedMarket: null,
            selectedTerritory: null,
            prevSelectedTabs: ['1'],
            selectedTab: '1',
          });
        }
        update({ ...get(), formData: createEmptyServiceCategoryForm() });
      } else if (
        mode === ManageServiceTypeMode.EDIT_DEFAULT ||
        mode === ManageServiceTypeMode.EDIT_MARKET ||
        mode === ManageServiceTypeMode.EDIT_TERRITORY
      ) {
        await refresh();
      }
    } catch (e:any) {
      console.error(e);
    }
    doneLoading(300);
  }

  async function refresh() {
    const { rowItemCount, availableRowItemCount } = get();
    if (!serviceCategoryId) {
      return;
    }
    let data:IServiceCategoryAdminRequest | null = null;
    let _selectedTab = '1';
    let selectedMarket: IMarket | null = null;
    let selectedTerritory: ITerritory | null = null;
    if (marketId && !territoryId) {
      data = {
        serviceCategoryId: serviceCategoryId,
        marketId: marketId,
        zipCodeTerritory: null,
      };
      let marketRes = await findMarketById(marketId);
      if (marketRes.data) {
        selectedMarket = marketRes.data;
      }
      _selectedTab = '2';
    } else if (marketId && territoryId) {

      let [marketRes, territoryRes] = await Promise.all([
        findMarketById(marketId),
        findTerritoryById(marketId, territoryId),
      ]);
      if (marketRes.data) {
        selectedMarket = marketRes.data;
      }
      if (territoryRes.data) {
        selectedTerritory = territoryRes.data;
      }
      if (!selectedMarket || !selectedTerritory) {
        createErrorToast('Error loading service category');
        return;
      }
      data = {
        serviceCategoryId: serviceCategoryId,
        marketId: marketId,
        zipCodeTerritory: selectedTerritory!.zipCodeTerritory,
      };
      _selectedTab = '3';
    } else {
      data = {
        serviceCategoryId: serviceCategoryId,
        marketId: null,
        zipCodeTerritory: null,
      };
      _selectedTab = '1';
    }
    if (!data) {
      createErrorToast('Error loading service category');
      return;
    }
    const res = await findServiceCategoryAdminDTO(data);
    if (res.data) {
      var filteredServiceTypeMetadata = res.data.serviceTypeMetadata.filter(x => !x.deleteDate);
      var availableData = res.data.availableServiceTypeMetadata.filter(x => !x.deleteDate);
      var sortableServiceTypes = createSortableServiceTypeRows(filteredServiceTypeMetadata, rowItemCount);
      var availableServiceTypes = createSortableServiceTypeRows(availableData, availableRowItemCount);
      update({
        ...get(),
        originalServiceTypeInfoMetadata: filteredServiceTypeMetadata,
        formData: createForm(res.data),
        sortableServiceTypes: sortableServiceTypes,
        availableServiceTypes: availableServiceTypes,
        selectedTab: _selectedTab,
        prevSelectedTabs: [_selectedTab],
        selectedMarket: selectedMarket,
        selectedTerritory: selectedTerritory,
      });
    }
  }


  function createForm(x:IServiceCategoryAdminDTO):IServiceCategoryForm {
    return {
      colorScheme: x.colorScheme,
      displayName: x.displayName,
      imageGuid: x.imageGuid,
      name: x.name,
      serviceCategoryId: x.serviceCategoryId,
      sortOrder: x.sortOrder,
      zipCodeTerritory: x.zipCodeTerritory,
      marketId: x.marketId,
      enabled: x.enabled,
    };
  }


  async function save(formData:IServiceCategoryForm) {
    const { sortableServiceTypes } = get();
    onLoading();

    const isValid = preSubmitValidation(formData);
    if (!isValid) {
      doneLoading();
      return;
    }
    var adminDto = {
      colorScheme: formData.colorScheme,
      displayName: formData.displayName ?? '',
      imageGuid: formData.imageGuid,
      name: formData.name,
      serviceCategoryId: formData.serviceCategoryId,
      sortOrder: formData.sortOrder,
      zipCodeTerritory: formData.zipCodeTerritory,
      marketId: formData.marketId,
      serviceTypeMetadata: squashAndDefineSort(sortableServiceTypes),
      enabled: formData.enabled,
    } as IServiceCategoryAdminDTO;

    const result = await saveServiceCategoryAdminDTO(adminDto);
    if (serviceCategoryId === 'create' || serviceCategoryId === 'unknown') {
      navigate('/serviceTypeInfoTabs');
      window.location.reload();
    } else {
      window.location.reload();
    }
    doneLoading(300);
  }

  function preSubmitValidation(formData:IServiceCategoryForm):boolean {
    if (!formData.imageGuid) {
      createErrorToast('Must supply a category image');
      return false;
    }

    return true;
  }

  function cancel() {
    navigate('/serviceTypeInfoTabs');
  }

  function onDragEnd(result) {
    const { sortableServiceTypes, availableServiceTypes } = get();
    const { destination, source, draggableId } = result;
    if (!destination) {
      return;
    }
    if (source.droppableId.indexOf('available') > -1 && destination.droppableId.indexOf('available') > -1) {
      //cannot reorder available service types
      return;
    }
    if (source.droppableId.indexOf('available') > -1 && destination.droppableId.indexOf('current') > -1) {
      let availableServiceTypesIndex = _getAvailableServiceTypesIndex(source.droppableId);
      let sortableServiceTypesIndex = _getSortableServiceTypesIndex(destination.droppableId);
      const start = availableServiceTypes[availableServiceTypesIndex] ?? [];
      const finish = sortableServiceTypes[sortableServiceTypesIndex] ?? [];
      _moveFromAvailableToCurrent(start, finish, source, destination);
      return;
    }
    if (source.droppableId.indexOf('current') > -1 && destination.droppableId.indexOf('available') > -1) {
      let availableServiceTypesIndex = _getAvailableServiceTypesIndex(destination.droppableId);
      let sortableServiceTypesIndex = _getSortableServiceTypesIndex(source.droppableId);
      const start = sortableServiceTypes[sortableServiceTypesIndex] ?? [];
      const finish = availableServiceTypes[availableServiceTypesIndex] ?? [];
      _moveFromCurrentToAvailable(start, finish, source, destination);
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    let sourceIndex = _getSortableServiceTypesIndex(source.droppableId);
    let destinationIndex = _getSortableServiceTypesIndex(destination.droppableId);
    const start = sortableServiceTypes[sourceIndex];
    const finish = sortableServiceTypes[destinationIndex];
    if (start === finish) {
      _moveWithinSameRow(start, finish, source, destination);
    } else {
      _moveToDifferentRow(start, finish, source, destination);
    }
  };

  function _moveFromAvailableToCurrent(availableServiceTypesRow, sortableServiceTypesRow, source, destination) {
    const { sortableServiceTypes, availableServiceTypes, rowItemCount, availableRowItemCount } = get();
    let nextAvailableServiceTypes = [
      ...availableServiceTypes,
    ];
    let nextSortableServiceTypes = [
      ...sortableServiceTypes,
    ];

    let availableServiceTypesIndex = _getAvailableServiceTypesIndex(source.droppableId);
    let sortableServiceTypesIndex = _getSortableServiceTypesIndex(destination.droppableId);
    const availableServiceTypesRowClone = Array.from(availableServiceTypesRow) as IServiceTypeInfoCardMetadatum[];
    const sortableServiceTypesRowClone = Array.from(sortableServiceTypesRow) as IServiceTypeInfoCardMetadatum[];
    var cardToMove = availableServiceTypesRowClone.splice(source.index, 1)[0];
    sortableServiceTypesRowClone.splice(destination.index, 0, cardToMove);
    nextAvailableServiceTypes[availableServiceTypesIndex] = availableServiceTypesRowClone;
    var redefinedAvailableServiceTypes = squashAndDefineSort(nextAvailableServiceTypes);
    nextSortableServiceTypes[sortableServiceTypesIndex] = sortableServiceTypesRowClone;
    var redefinedServiceTypes = squashAndDefineSort(nextSortableServiceTypes);
    update({
      ...get(),
      sortableServiceTypes: createSortableServiceTypeRows(redefinedServiceTypes, rowItemCount),
      availableServiceTypes: createSortableServiceTypeRows(redefinedAvailableServiceTypes, availableRowItemCount),
    });
  }

  function _moveFromCurrentToAvailable(sortableServiceTypesRow, availableServiceTypesRow, source, destination) {
    const { sortableServiceTypes, availableServiceTypes, rowItemCount, availableRowItemCount } = get();
    let nextAvailableServiceTypes = [
      ...availableServiceTypes,
    ];
    let nextSortableServiceTypes = [
      ...sortableServiceTypes,
    ];

    let availableServiceTypesIndex = _getAvailableServiceTypesIndex(destination.droppableId);
    let sortableServiceTypesIndex = _getSortableServiceTypesIndex(source.droppableId);
    const availableServiceTypesRowClone = Array.from(availableServiceTypesRow) as IServiceTypeInfoCardMetadatum[];
    const sortableServiceTypesRowClone = Array.from(sortableServiceTypesRow) as IServiceTypeInfoCardMetadatum[];
    var cardToMove = sortableServiceTypesRowClone.splice(source.index, 1)[0];
    availableServiceTypesRowClone.splice(destination.index, 0, cardToMove);
    nextAvailableServiceTypes[availableServiceTypesIndex] = availableServiceTypesRowClone;
    var redefinedAvailableServiceTypes = squashAndDefineSort(nextAvailableServiceTypes);
    nextSortableServiceTypes[sortableServiceTypesIndex] = sortableServiceTypesRowClone;
    var redefinedServiceTypes = squashAndDefineSort(nextSortableServiceTypes);
    update({
      ...get(),
      sortableServiceTypes: createSortableServiceTypeRows(redefinedServiceTypes, rowItemCount),
      availableServiceTypes: createSortableServiceTypeRows(redefinedAvailableServiceTypes, availableRowItemCount),
    });
  }

  //only used for moving a currently assigned service type within same row in the current assignment
  function _moveWithinSameRow(start, finish, source, destination) {
    const { sortableServiceTypes, rowItemCount } = get();
    let nextSortableServiceTypes = [
      ...sortableServiceTypes,
    ];
    let destinationDroppableIndex = _getSortableServiceTypesIndex(destination.droppableId);
    const nextItems = Array.from(start) as IServiceTypeInfoCardMetadatum[];
    var spliced = nextItems.splice(source.index, 1);
    nextItems.splice(destination.index, 0, spliced[0]);
    nextSortableServiceTypes[destinationDroppableIndex] = nextItems;
    var redefinedServiceTypes = squashAndDefineSort(nextSortableServiceTypes);
    update({
      ...get(),
      sortableServiceTypes: createSortableServiceTypeRows(redefinedServiceTypes, rowItemCount),
    });
  }

  //only used for moving a currently assigned service type to a different row in the current assignment
  function _moveToDifferentRow(start, finish, source, destination) {
    const { sortableServiceTypes, rowItemCount } = get();
    let nextSortableServiceTypes = [
      ...sortableServiceTypes,
    ];
    const startClone = Array.from(start) as IServiceTypeInfoCardMetadatum[];
    const finishClone = Array.from(finish) as IServiceTypeInfoCardMetadatum[];
    let sourceDroppableIndex = _getSortableServiceTypesIndex(source.droppableId);
    let destinationDroppableIndex = _getSortableServiceTypesIndex(destination.droppableId);
    var spliced = startClone.splice(source.index, 1);
    finishClone.splice(destination.index, 0, spliced[0]);

    nextSortableServiceTypes[sourceDroppableIndex] = startClone;
    nextSortableServiceTypes[destinationDroppableIndex] = finishClone;
    var redefinedServiceTypes = squashAndDefineSort(nextSortableServiceTypes);

    update({
      ...get(),
      sortableServiceTypes: createSortableServiceTypeRows(redefinedServiceTypes, rowItemCount),
    });
  }

  function createSortableServiceTypeRows(
    serviceTypeMetadata: IServiceTypeInfoCardMetadatum[],
    rowItemCount:number,
  ): IServiceTypeInfoCardMetadatum[][] {

    let rows: IServiceTypeInfoCardMetadatum[][] = [];

    for (let i = 0; i < serviceTypeMetadata.length; i++) {
      if (i % rowItemCount === 0) {
        rows.push([]);
      }
      let _serviceTypeMetadata = serviceTypeMetadata[i];
      if (_serviceTypeMetadata.sortOrder === null) {
        _serviceTypeMetadata.sortOrder = i;
      }

      rows[rows.length - 1].push(_serviceTypeMetadata);

    }

    return rows;
  }

  function squashAndDefineSort(multiDim:IServiceTypeInfoCardMetadatum[][]): IServiceTypeInfoCardMetadatum[] {

    let result: IServiceTypeInfoCardMetadatum[] = [];
    let sortOrder = 0;
    for (let i = 0; i < multiDim.length; i++) {
      for (let j = 0; j < multiDim[i].length; j++) {
        multiDim[i][j].sortOrder = sortOrder;
        result.push(multiDim[i][j]);
        sortOrder++;
      }
    }
    return result;
  }

  function onToggleAccordion() {
    const { availableServiceTypesExpanded } = get();
    update({
      ...get(),
      availableServiceTypesExpanded: !availableServiceTypesExpanded,
    });
  }

  function onSelectTab(event: React.SyntheticEvent, newValue: string) {
    const { prevSelectedTabs, selectedTab } = get();
    if (newValue == '1') {
      navigate(`/serviceCategoriesV2/${serviceCategoryId}/service-category-details/${ManageServiceTypeMode.EDIT_DEFAULT}`);
      window.location.reload();
    } else if (newValue == '2') {
      let state = createEmptySelectMarketModal();
      state.marketId = savedMarket?.id ?? '';
      openSelectMarketModal(state);
    } else if (newValue == '3') {
      let state = createEmptySelectTerritoryModal();
      state.marketId = savedMarket?.id ?? '';
      openSelectTerritoryModal(state);
    }
    let updatedPrevSelectedTabs = [...prevSelectedTabs, selectedTab];
    update({
      ...get(),
      prevSelectedTabs: updatedPrevSelectedTabs,
      selectedTab: newValue,
    });
  }

  function revertSelectedTab() {
    const { prevSelectedTabs } = get();
    if (prevSelectedTabs && prevSelectedTabs.length > 0) {
      let updatedPrevSelectedTabs = [...prevSelectedTabs];
      let popped = updatedPrevSelectedTabs.pop();
      if (popped) {
        update({
          ...get(),
          prevSelectedTabs: updatedPrevSelectedTabs,
          selectedTab: popped,
        });
      }
    }
  }

  function _getAvailableServiceTypesIndex(index:string) {
    return parseInt(index.replace('available-', ''));
  }

  function _getSortableServiceTypesIndex(index:string) {
    return parseInt(index.replace('current-', ''));
  }

  function swapColorScheme(colorScheme:string) {
    const { sortableServiceTypes, rowItemCount } = get();
    var redefinedServiceTypes = squashAndDefineSort([...sortableServiceTypes]).map(x => {
      return {
        ...x,
        colorScheme: colorScheme,
      };
    });

    update({
      ...get(),
      sortableServiceTypes: createSortableServiceTypeRows(redefinedServiceTypes, rowItemCount),
    });
  }
  return {
    ...get(),
    loadingKey,
    init,
    save,
    cancel,
    onDragEnd,
    onToggleAccordion,
    onSelectTab,
    revertSelectedTab,
    swapColorScheme,
  };
}