import { Grid } from '@mui/material';
import { HookCheckbox, HookTextField } from 'components/reactHookForm';

export interface IWidgetConfigDTOProps {
  fieldName:string;
}

export default function WidgetConfigDTO({
  fieldName,
}:IWidgetConfigDTOProps) {

  return (
    <Grid container spacing={1}>

      <Grid item xs={12}>
        <h1>{fieldName}</h1>
      </Grid>
      <Grid item xs={12}>
        <HookTextField name={`${fieldName}.text`} label="Displayed Text" multiline/>
      </Grid>
      <Grid item xs={4}>
        <HookTextField name={`${fieldName}.backgroundHexColor`} label="Background Hex Color" />
      </Grid>

      <Grid item xs={4}>
        <HookTextField name={`${fieldName}.fontHexColor`} label="font hex color" />
      </Grid>
      <Grid item xs={4}>
        <HookTextField name={`${fieldName}.fontSize`} label="font size" />
      </Grid>
      <Grid item xs={4}>
        <HookTextField name={`${fieldName}.fontWeight`} label="font weight" />
      </Grid>
      <Grid item xs={4}>
        <HookTextField name={`${fieldName}.buttonIcon`} label="buttonIcon" />
      </Grid>
      <Grid item xs={6}>
        <HookCheckbox name={`${fieldName}.buttonIconRightSide`} label="button icon on right side?" />
      </Grid>
    </Grid>
  );
}