
const NoPaddingNumberFormatter = new Intl.NumberFormat('en-us', {
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
});

const PennyPaddingNumberFormatter = new Intl.NumberFormat('en-us', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const OneDecimalNumberFormatter = new Intl.NumberFormat('en-us', {
  minimumFractionDigits: 1,
  maximumFractionDigits: 1,
});

/**
 * Takes a value like 13.2 and converts it to 0.132
 * @param formattedPercent
 */
export function convertFormattedPercentage(formattedPercent:string | number):number | null {
  if (formattedPercent === '') {
    return null;
  }
  if (typeof formattedPercent === 'string') {
    return parseFloat(formattedPercent) / 100.0;
  } else if (typeof formattedPercent === 'number') {
    return formattedPercent / 100.0;
  }
  throw new Error('Cannot parse: '+formattedPercent);
}

/**
 * Takes a value like 0.132 and converts it to 13.2
 * @param percent
 */
export function formatPercent(percent:number | string | null):string {
  if (percent === null) {
    return '';
  }
  if (typeof percent === 'string') {
    return (parseFloat(percent) * 100.0).toString();
  } else if (typeof percent === 'number') {
    return (percent * 100.0).toString();
  }
  throw new Error('Cannot format: '+percent);
}

/**
 * Takes a value like 0.132 and converts it to 13.2
 * @param percent
 */
export function formatPercentPennyPadding(percent:number | string | null):string {
  return _formatPercent(percent, PennyPaddingNumberFormatter);
}

export function formatPercentOneDecimal(percent:number | string | null):string {
  return _formatPercent(percent, OneDecimalNumberFormatter);
}

function _formatPercent(percent:number | string | null, formatter: any):string {
  if (percent === null) {
    return '';
  }
  if (typeof percent === 'string') {
    return formatter.format((parseFloat(percent) * 100.0));
  } else if (typeof percent === 'number') {
    return formatter.format(percent * 100.0);
  }
  throw new Error('Cannot format: '+percent);
}