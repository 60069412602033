import ServiceProviderAutocomplete from 'components/serviceProviderAutocomplete/ServiceProviderAutocomplete';
import useProviderSetupStore from '../useProviderSetupStore';
import { findServiceOfferingsByProvider } from 'api/serviceOfferingApi';
import { useFormContext } from 'react-hook-form';

export function ProviderSelect() {
  const formCtx = useFormContext();
  const { setServiceOfferingCloneOptions } = useProviderSetupStore();

  async function onServiceProviderChange(serviceProvider) {
    const serviceOfferings = await findServiceOfferingsByProvider(serviceProvider.id);
    await setServiceOfferingCloneOptions(serviceOfferings.data);
  };

  return (
    <ServiceProviderAutocomplete
      label={'Clone From Service Provider'}
      postOnChange={(_formCtx, selectedOption, reason, details) => {
        void onServiceProviderChange(selectedOption?.ancillary);
      }}
      formless={true}
      placeholder={''}
      required={false}
    />
  );
}
