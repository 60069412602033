import { useEffect, useState } from 'react';
import useMarkets from 'hooks/useMarkets';

export default function MarketNameCell({ marketId }:any) {
  const { marketMap } = useMarkets();
  const [marketName, setMarketName] = useState('');
  useEffect(() => {
    let nextVal = marketMap.get(marketId)?.name ?? '';
    setMarketName(nextVal);
  }, [marketMap]);
  return (
    <div>
      {marketName ?? 'Unknown Market'}
    </div>
  );
}