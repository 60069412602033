import useErrorState from 'hooks/reactHookForm/useErrorState';
import { FormControl, InputLabel, FormHelperText } from '@mui/material';
import { HookSelect } from 'components/reactHookForm';
import { useFormContext } from 'react-hook-form';
import useProviderSetupStore from '../useProviderSetupStore';
import { useParams } from 'react-router-dom';
import { set } from 'date-fns';

function modifyForClone(data, omitImages:boolean):any {
  data.name = `${data.serviceCategory}: ${data.serviceType}`;
  data.defaultForServiceType = false;
  delete data.itemMetadata;
  data.defaultDiscountScheduleId = '';
  data.cutoffDayCount = '';
  data.providerId = '';
  data.clonedFromId = data.id;

  if (omitImages) {
    data.imageRefs = [];
  }
  data.providerCoverageAreaId = '';
  delete data.id;
  if (!data.subscriptionOptions) {
    data.subscriptionOptions = {
      label: '',
      options: [
        {
          optionName: '',
          optionValue: '',
          isDefaultOption: false,
          isOneTime: false,
          isNew: true,
        },
      ],
      subscriptionTermInMonths: 0,
    };
  }
  // format serviceDetailsSections
  data.serviceDetailSections = data.serviceDetailSections.map((section) => {
    const updatedSectionItems = section.sectionItems.map((item) => ({ val: item }));
    const updatedSectionListItems = section.sectionListItems.map((item) => ({ val: item }));

    return {
      ...section,
      sectionItems: updatedSectionItems,
      sectionListItems: updatedSectionListItems,
    };
  });

  return data;
}

export default function ServiceOfferingCloneOptionsSelect() {
  const formCtx = useFormContext();
  const { serviceOfferingCloneOptions, setServiceType, setCadenceType } = useProviderSetupStore();
  const { getErrorMessage } = useErrorState(formCtx);
  const { providerId } = useParams();

  if (!serviceOfferingCloneOptions || serviceOfferingCloneOptions.length === 0) {
    return <></>;
  }

  return (
    <FormControl variant='standard' fullWidth>
      <InputLabel htmlFor="service-offering-id-to-clone">Service Offering to Clone</InputLabel>
      <HookSelect
        control={formCtx.control}
        rules={{
          required: { value: false, message: 'Required' },
        }}
        postOnChange={async (event, x) => {
          const serviceOffering = serviceOfferingCloneOptions.find((option) => option.optionValue === event.target.value)?.ancillary;
          const cloneData = modifyForClone(serviceOffering, false);
          cloneData.providerId = providerId;
          cloneData.serviceOfferingIdToClone = event.target.value;
          formCtx.reset(cloneData);
          // set other relevant state values
          setServiceType(cloneData.serviceType);
          setCadenceType(cloneData.cadenceType);

        }}
        name='serviceOfferingIdToClone'
        id='service-offering-id-to-clone'
        menuItems={serviceOfferingCloneOptions}
        menuItemKeys={{
          key: 'id',
          value: 'optionValue',
          displayValue: 'optionText',
        }}
      />
      <FormHelperText>{getErrorMessage('serviceTypeId')}</FormHelperText>
    </FormControl>
  );
}