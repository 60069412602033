import { createUninitializedDropdownOption, IDropdownOption } from 'model/dropdown';

export namespace AddProviderToTemplateModel {


  export interface IAddProviderToSeedingTemplatesRequestForm {
    marketId:string;
    serviceProvider:IDropdownOption | null;
    serviceOfferingsWithSeedFactor:IMinimalServiceOfferingWithSeedFactorForm[];
    zipCodeTerritories:string[];
  }

  export interface IAddProviderToSeedingTemplatesRequest {
    marketId:string;
    serviceProviderId:string;
    serviceProviderName:string;
    serviceOfferingsWithSeedFactor:IMinimalServiceOfferingWithSeedFactor[];
    zipCodeTerritories:string[];
  }

  export function createEmptyAddProviderToSeedingTemplatesRequestForm():IAddProviderToSeedingTemplatesRequestForm {
    return {
      marketId: '',
      serviceProvider: createUninitializedDropdownOption(),
      serviceOfferingsWithSeedFactor: [],
      zipCodeTerritories: [],
    };
  }

  export interface IMinimalServiceOfferingWithSeedFactor {
    id:string;
    seedFactor:number | null;
  }

  export interface IMinimalServiceOfferingWithSeedFactorForm {
    id:string;
    seedFactor:number | null;
    checked:boolean;
  }

  export function convertFormToDto(data:IAddProviderToSeedingTemplatesRequestForm):IAddProviderToSeedingTemplatesRequest {
    return {
      marketId: data.marketId,
      serviceProviderId: data?.serviceProvider?.optionValue || '',
      serviceProviderName: data?.serviceProvider?.optionText || '',
      serviceOfferingsWithSeedFactor: data.serviceOfferingsWithSeedFactor
        .filter((serviceOffering) => serviceOffering.checked)
        .filter((serviceOffering) => serviceOffering.seedFactor != null)
        .map((serviceOffering) => ({
          id: serviceOffering.id,
          seedFactor: serviceOffering.seedFactor,
        })),
      zipCodeTerritories: data.zipCodeTerritories,
    };
  }
}