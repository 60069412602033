import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import SettingsIcon from '@mui/icons-material/Settings';
import { Box, TextField, IconButton, Checkbox, FormControl, InputLabel, MenuItem, Menu } from '@mui/material';
import { useState } from 'react';
import { getStoredSaveQuickSearch, setStoredSaveQuickSearch, setStoredSearchValue } from './storage';

export function escapeRegExp(value: string): string {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

interface QuickSearchToolbarProps {
  quickSearchStorageKey?:string;
  clearSearch: () => void;
  onChange: () => void;
  value: string;
  additionalFields?: JSX.Element;
}

function QuickSearchSettings({ quickSearchStorageKey }:any) {
  let defaultSaveQuickSearchValue = getStoredSaveQuickSearch(quickSearchStorageKey);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [saveQuickSearch, setSaveQuickSearch] = useState<boolean>(defaultSaveQuickSearchValue);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSaveQuickSearchClick = () => {
    const nextSaveQuickSearch = !saveQuickSearch;
    if (!nextSaveQuickSearch) {
      setStoredSearchValue(quickSearchStorageKey, '');
    }
    setStoredSaveQuickSearch(quickSearchStorageKey, nextSaveQuickSearch);
    setSaveQuickSearch(nextSaveQuickSearch);
  };
  return (
    <>
      <IconButton
        id='quick-search-settings-button'
        aria-controls={open ? 'quick-search-settings' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}>
        <SettingsIcon/>
      </IconButton>
      <Menu
        id='quick-search-settings'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'quick-search-settings-button',
        }}>
        <MenuItem onClick={handleSaveQuickSearchClick}>
          <Checkbox checked={saveQuickSearch}></Checkbox>
          <span>Save Quick Search</span>
        </MenuItem>
      </Menu>
    </>
  );
}

export default function QuickSearchToolbar({ quickSearchStorageKey, value, onChange, clearSearch, additionalFields }: QuickSearchToolbarProps) {
  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
      }}
    >
      <TextField
        variant="standard"
        value={value}
        onChange={onChange}
        placeholder="Search…"
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: value ? 'visible' : 'hidden' }}
              onClick={clearSearch}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
        sx={{
          'width': {
            xs: 1,
            sm: 'auto',
          },
          'm': (theme) => theme.spacing(1, 0.5, 1.5),
          '& .MuiInput-underline:before': {
            borderBottom: 1,
            borderColor: 'divider',
          },
        }}
      />
      {quickSearchStorageKey && (<QuickSearchSettings quickSearchStorageKey={quickSearchStorageKey}/>)}
      {additionalFields}
    </Box>
  );
}