import { Grid } from '@mui/material';
import styles from './PageHeader.module.css';
export default function PageHeader({ headerText, children, rightChildren, suppressHeaderMargin, sx }: any) {
  return (
    <Grid container alignItems='center' className={styles.headerWrapper} sx={sx}>
      {children}
      <Grid item>
        <h3 className={styles.header} style={{ marginTop: suppressHeaderMargin ? 0 : 19 }}>
          {headerText.toLocaleUpperCase()}
        </h3>
      </Grid>
      {rightChildren}
    </Grid>
  );
}