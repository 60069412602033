import { Grid } from '@mui/material';
import StepHeader from './StepHeader';

import TextInput from './TextInput';

export default function StepOne() {
  return (
    <Grid container item xs={6} spacing={1}>
      <Grid item xs={12}>
        <StepHeader>Step 1: Enter a name</StepHeader>
      </Grid>
      <Grid item xs={6}>
        <TextInput
          required
          name='name'
          label='Name'
          maxLength='100'
        />
      </Grid>
    </Grid>
  );
}