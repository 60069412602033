import { AlertColor } from '@mui/material';
import createStore from 'hooks/hookStore';
import React, { useEffect, useState } from 'react';

export interface IToastMessageInfo {
  severity:AlertColor | undefined;
  message:string;
  key:string | number;
  component?: any;
  persistent?: boolean;
}
type Toast = {
  snackPack: any[];
  open:boolean;
  messageInfo: IToastMessageInfo;
}
export interface ICreateToastProps {
  persistent?:boolean;
}
const { get, update, registerListener, unregisterListener } = createStore<Toast>('toast', {
  open: false,
  snackPack: [],
  messageInfo: {
    message: '',
    key: '',
    severity: 'info',
    component: null,
  },
});

export default function useToast() {
  const setState = useState(get())[1];

  useEffect(() => {
    registerListener(setState);
    return () => {
      unregisterListener(setState);
    };
  }, []);


  function createSuccessToast(message:string, component?:any, createToastProps?:ICreateToastProps) {
    const messageInfo:IToastMessageInfo = { key: new Date().getTime(), message, severity: 'success', component, ...createToastProps };
    _createToast(messageInfo);
  }

  function createErrorToast(message:string, component?:any, createToastProps?:ICreateToastProps) {
    const messageInfo:IToastMessageInfo = { key: new Date().getTime(), message, severity: 'error', component, ...createToastProps };
    _createToast(messageInfo);
  }

  function createErrorHandlingToast(prepend:string, e:any, component?:any, createToastProps?:ICreateToastProps) {
    let message;
    if (e.response && e.response.data && e.response.data.message) {
      message = `${prepend ?? 'Error: '}${e.response.data.message}`;
    } else {
      message = `${prepend} ${e?.toString() ?? 'An error occurred'}`;
    }
    const messageInfo:IToastMessageInfo = { key: new Date().getTime(), message, severity: 'error', component, ...createToastProps };
    _createToast(messageInfo);
  }

  function createWarnToast(message:string, component?:any, createToastProps?:ICreateToastProps) {
    const messageInfo:IToastMessageInfo = { key: new Date().getTime(), message, severity: 'warning', component, ...createToastProps };
    _createToast(messageInfo);
  }

  function createInfoToast(message:string, component?:any, createToastProps?:ICreateToastProps) {
    const messageInfo:IToastMessageInfo = { key: new Date().getTime(), message, severity: 'info', component, ...createToastProps };
    _createToast(messageInfo);
  }

  function _createToast(messageInfo:IToastMessageInfo) {

    const { snackPack, open } = get();
    update({
      ...get(),
      snackPack: [...snackPack, messageInfo],
    });
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    update({
      ...get(),
      open: false,
    });
  };

  const handleExited = () => {
    update({
      ...get(),
      messageInfo: { message: '', key: '', severity: undefined, component: null },
    });
  };

  function closeToast() {
    update({
      ...get(),
      open: false,
    });
  }

  function addToastInternal() {
    const { snackPack } = get();
    update({
      ...get(),
      messageInfo: { ...snackPack[0] },
      snackPack: snackPack.slice(1),
      open: true,
    });
  }


  return {
    messageInfo: get().messageInfo,
    open: get().open,
    snackPack: get().snackPack,
    createSuccessToast,
    createErrorToast,
    createErrorHandlingToast,
    createWarnToast,
    createInfoToast,
    addToastInternal,
    closeToast,
    handleClose,
    handleExited,
  };
}