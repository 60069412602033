import { useEffect, useState } from 'react';
import createStore from 'hooks/hookStore';

export interface ITooltipDrawer {
  open:boolean;
}

function defautEmptyTooltipDrawer(): ITooltipDrawer {

  return {
    open: false,
  };
}
type SfTooltipDrawerStore = {
  tooltipDrawerMap:Map<string, ITooltipDrawer>;
}

const { get, update, registerListener, unregisterListener } = createStore<SfTooltipDrawerStore>('SfTooltipDrawerStore', {
  tooltipDrawerMap: new Map<string, ITooltipDrawer>(),
});

/**
 * @param tooltipDrawerStoreKey
 * @returns
 */
export default function useModal(tooltipDrawerStoreKey:string) {
  const setState = useState(get())[1];


  if (!get().tooltipDrawerMap.has(tooltipDrawerStoreKey)) {
    initializeModalState();
  }

  useEffect(() => {
    registerListener(setState);
    return () => {
      unregisterListener(setState);
    };
  }, []);

  function initializeModalState() {
    const { tooltipDrawerMap } = get();
    tooltipDrawerMap.set(tooltipDrawerStoreKey, defautEmptyTooltipDrawer());
    update({ ...get(), tooltipDrawerMap });
  }

  function getTooltipDrawerState() : ITooltipDrawer {
    const { tooltipDrawerMap } = get();
    if (tooltipDrawerMap.has(tooltipDrawerStoreKey)) {
      let result = tooltipDrawerMap.get(tooltipDrawerStoreKey);
      if (!result) {
        return defautEmptyTooltipDrawer();
      }
      return result;
    }
    return defautEmptyTooltipDrawer();
  }

  function updateTooltipDrawerState(updatedState:any) {
    const { tooltipDrawerMap } = get();
    if (tooltipDrawerMap.has(tooltipDrawerStoreKey)) {
      const currentState = tooltipDrawerMap.get(tooltipDrawerStoreKey);
      const nextState = { ...currentState, ...updatedState };
      tooltipDrawerMap.set(tooltipDrawerStoreKey, nextState);
      update({
        ...get(),
        tooltipDrawerMap,
      });
    }
  }

  function openDrawer(state:any) {
    let currentState = getTooltipDrawerState();
    currentState.open = true;
    currentState = { ...currentState, ...state };
    updateTooltipDrawerState(currentState);
  }

  function closeDrawer(state:any) {
    let currentState = getTooltipDrawerState();
    currentState.open = false;
    currentState = { ...currentState, ...state };
    updateTooltipDrawerState(currentState);
  }

  return {
    loadingKey: tooltipDrawerStoreKey,
    ...getTooltipDrawerState(),
    getTooltipDrawerState,
    updateTooltipDrawerState,
    openDrawer,
    closeDrawer,
  };
}