import Axios from 'axios';
import { IAddressContactsUploadForm, IHomeUploadForm } from 'model/launchTooling';

const apiUrl = window.REACT_APP_BASE_API_URI;

export function uploadPropStreamFile(data:IHomeUploadForm) {
  var formData = new FormData();
  formData.append('propStreamTemplate', data.propStreamTemplate);
  return Axios.post(`${apiUrl}/launchTooling/v2/propStreamHomeUpload`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

}

export function syncStreetName(neighborhoodId:string) {
  return Axios.post(`${apiUrl}/launchTooling/v2/syncStreetName/${neighborhoodId}`);
}

export function uploadAddressContactsPropStreamFile(data:IAddressContactsUploadForm) {
  var formData = new FormData();
  formData.append('propStreamTemplate', data.propStreamTemplate);
  return Axios.post(`${apiUrl}/launchTooling/v2/addressContactsUpload`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

}