import ServiceProviderServiceTypeSection from './_components/ServiceProviderServiceTypeSection';
import PageHeader from 'components/SectionHeaders/PageHeader';
import styles from './tsoiManagement.module.css';
import useTsoiManagementBatchServiceProviders from './hookStore/useTsoiManagementBatchServiceProviders';
import { Grid, Button } from '@mui/material';
import { PrimaryButton } from 'components/buttons';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ITsoiManagementBatchTerritoryServiceType } from 'model/tsoiManagementBatch';
import Loading from 'components/Layout/Loading';
import DetailItem from 'pages/individuals/_components/DetailItem';

export default function TsoiManagementBatchServiceProviders() {
  const {
    init,
    batch,
    onSave,
    onCancel,
    loadingKey,
    territory,
  } = useTsoiManagementBatchServiceProviders();

  const { batchId, territoryId } = useParams();
  const serviceTypes = batch?.territories?.[territoryId ?? '']?.serviceTypes || [];

  useEffect(() => {
    void init(batchId, territoryId);
  }, [batchId, territoryId]);

  return (
    <Loading loadingKey={loadingKey}>
      <Grid sx={{ paddingLeft: '10px', paddingRight: '10px' }}>
        <PageHeader headerText="Step 3. Select Service Providers" />
        <Grid container item paddingTop={'25px'}>
          <Grid item xs={12}>
            <DetailItem label={'Territory'} value={territory.name} />
          </Grid>
        </Grid>
        <Grid container flexDirection={'row'} paddingTop={'25px'}>
          <Grid item xs={12}>
            {
              serviceTypes.map((serviceType: ITsoiManagementBatchTerritoryServiceType) => {
                return (<ServiceProviderServiceTypeSection key={serviceType.id} serviceType={serviceType} />);
              })
            }
          </Grid>
        </Grid>
        <Grid container item justifyContent={'center'} paddingTop={'50px'} xs={6}>
          <Grid item paddingRight={'25px'}>
            <PrimaryButton onClick={onSave}>Save & Continue</PrimaryButton>
          </Grid>
          <Grid item paddingLeft={'25px'}>
            <Button variant={'outlined'} color={'error'} className={styles.cancelButton} onClick={onCancel}>Cancel</Button>
          </Grid>
        </Grid>
      </Grid>
    </Loading>);
}