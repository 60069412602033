
import { Alert, Button, Grid } from '@mui/material';
import useConfirmationModal from 'components/modals/useConfirmationModal';
import { createEmptyNeighborhoodLaunchForm, createEmptyProviderCoverageAreaLaunchToolingForm, createEmptyServiceProviderLaunchDto, INeighborhoodLaunchForm, IProviderCoverageAreaLaunchToolingForm } from 'model/neighborhoodLaunch';
import { useEffect } from 'react';
import { FieldValues, FormProvider, SubmitErrorHandler, SubmitHandler, useFieldArray, useForm, useFormContext } from 'react-hook-form';
import { ConfirmationModal } from './confirmationModal';
import ServiceProviderLaunchCards from './formComponents/ServiceProviderLaunchCards';
import ServiceProviderMultiSelect from './formComponents/ServiceProviderMultiSelect';
import ServiceProviderSingleSelect from './formComponents/ServiceProviderSingleSelect';
import useProviderCoverageAreaLaunchToolingStore from './hookStore/useProviderCoverageAreaLaunchTooling';
import ReconcileButton from './launchProvidersV2Components/ReconcileButton';
import SubmitButton from './launchProvidersV2Components/SubmitButton';
import { formatMonthDayTime } from 'util/dateUtil';
import useToast from 'components/toast/useToast';


export const launchProviderModalKey = 'launchProviderModalKey';
export const reconcileNsosByServiceProviderModalKey = 'reconcileNsosByServiceProviderModalKey';

export interface ILaunchProviderValidationResult {
  isValid:boolean;
}
export interface IProviderCoverageAreaLaunchToolingFormInternalProps {
  initialFormData:IProviderCoverageAreaLaunchToolingForm;
}

function ModalWrapper() {
  const { getValues, control } = useFormContext();
  const { save } = useProviderCoverageAreaLaunchToolingStore();
  return (
    <ConfirmationModal
      body='Confirm launch.'
      modalKey={launchProviderModalKey}
      title={'Launch Providers?'}
      onConfirm={async (data) => {
        await save(data);
      }}
      successText='Launch providers submitted.'
    />
  );
}


function ReconcileModalWrapper() {
  const { getValues, control } = useFormContext();
  const { onReconcileNSOsByServiceProvider } = useProviderCoverageAreaLaunchToolingStore();
  const dryRun = getValues().dryRun;
  return (
    <ConfirmationModal
      body={`Reconcile NSOs by provider?. ${dryRun ? '(DRY RUN)' : ''}`}
      modalKey={reconcileNsosByServiceProviderModalKey}
      title={`Reconcile NSOs? ${dryRun ? '(DRY RUN)' : ''}`}
      onConfirm={async (data) => {
        await onReconcileNSOsByServiceProvider(data);
      }}
      successText='NSO reconciliation received.'
    />
  );
}

function HelperForm() {
  const { control } = useFormContext();
  const { onSelectProvider, clearLastReconciled } = useProviderCoverageAreaLaunchToolingStore();
  const { fields, append, replace, remove } = useFieldArray({
    control,
    name: 'serviceProviderLaunchDtos',
  });

  return (
    <>
      <Grid container item xs={12} sm={12} md={4} >
        <ServiceProviderSingleSelect
          onServiceProviderAdd={(id, name) => {
            append(createEmptyServiceProviderLaunchDto(id, name));
            void onSelectProvider(id);
          }}
          onServiceProviderRemove={(index) => {
            remove(index);
            clearLastReconciled();
          }}
          variant='providerCoverageAreaLaunchTooling'
        />
      </Grid>
      <Grid container item xs={12}>
        <ServiceProviderLaunchCards
          disableServiceOfferingsWithoutProviderCoverageArea={true}
          fields={fields}
          onReplace={replace}
          variant='providerCoverageAreaLaunchTooling'
        />
      </Grid>
    </>
  );
}


function ProviderCoverageAreaLaunchToolingFormInternal({ initialFormData }:IProviderCoverageAreaLaunchToolingFormInternalProps) {
  const { lastReconciled } = useProviderCoverageAreaLaunchToolingStore();
  const formContext = useForm<IProviderCoverageAreaLaunchToolingForm>({ mode: 'onTouched', defaultValues: createEmptyProviderCoverageAreaLaunchToolingForm() });
  useEffect(() => {
    formContext.reset(initialFormData);
  }, [initialFormData]);
  const formattedLastReconciled = formatMonthDayTime(lastReconciled);
  return (
    <FormProvider {...formContext}>
      <form >
        <Grid container >
          <Grid item xs={4} sx={{ marginBottom: '10px' }}>
            <Alert severity='info'>
              You can only launch NSOs with service offerings that have a provider coverage area set. Others will be disabled.
            </Alert>
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <HelperForm/>
          <Grid container item xs={12} sm={12} md={8} >
            <Grid container item xs={12} spacing={1}>
              <Grid item xs={12}>
                Last Reconciled: {formattedLastReconciled ? formattedLastReconciled : 'N/A'}
              </Grid>
              <Grid item xs='auto' >
                <SubmitButton/>
              </Grid>
              <Grid item xs='auto'>
                <ReconcileButton dryRun={true} text='Reconcile NSOs (Dry Run)'/>
              </Grid>
              <Grid item xs='auto'>
                <ReconcileButton dryRun={false} text='Reconcile NSOs'/>
              </Grid>
            </Grid>
          </Grid>
          <ModalWrapper/>
          <ReconcileModalWrapper/>
        </Grid>
      </form>
    </FormProvider>
  );
}

export default function ProviderCoverageAreaLaunchToolingForm() {
  const { formData, init } = useProviderCoverageAreaLaunchToolingStore();
  useEffect(() => {
    void init();
  }, []);
  return (
    <ProviderCoverageAreaLaunchToolingFormInternal initialFormData={formData}/>
  );
}