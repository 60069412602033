
import { Card, CardContent, Grid, Grow } from '@mui/material';
import AddButton from 'components/buttons/AddButton';
import Centered from 'components/Layout/Centered';
import Spacer from 'components/Layout/Spacer';
import { useEffect, useLayoutEffect, useState } from 'react';
import { TransitionGroup } from 'react-transition-group';
import styles from '../providerOnboarding.module.css';

interface IServiceOfferingContainerProps {
  items:any[];
  render:any;
}
interface IServiceOfferingCardProps {
  idx:number;
  render: any;
  moved:boolean;
  setMoved: any;
}

export function StepHeader({ children }:any) {
  return (
    <h4 className={''}>{children}</h4>
  );
}

export function AddListItemButton({ label, onClick, tooltipTitle }:any) {
  return (
    <>
      <Centered centerType='vertical' className={styles.addLabel}>{label}</Centered>
      <AddButton
        tooltipTitle={tooltipTitle ||''}
        onClick={onClick}
        className={styles.addButton}
      />
    </>
  );
}

export function ServiceOfferingCard({ idx, render, moved, setMoved }:IServiceOfferingCardProps) {
  const timeout = 250;
  const [transition, setTransition] = useState(false);
  const [sortOrder, setSortOrder] = useState(idx);

  useEffect(() => {
    if (moved) {
      setSortOrder(idx);
      setMoved(false);
    }
  }, [moved]);
  useLayoutEffect(() => {
    //this layout effect is used to enable the transition when it first renders
    setTransition(true);
  }, []);

  function onDelete(cb:any) {
    //this function calls the onDelete callback (e.g. remove(index)) after
    //transitioning out. Need to use the setTimeout function to allow the transition to complete before deleting.
    setTransition(false);

    setTimeout(() => {
      cb();
      setMoved(true);
    }, timeout);
  }

  function onSortOrderChange(e:any) {
    setSortOrder(e.target.value);
  }
  function onMove() {
    setMoved(true);
  }
  return (
    <Grid container item spacing={1} xs={11} direction='column'>
      <Grow in={transition} timeout={timeout}>
        <Card>
          <CardContent>
            {render(idx, onDelete, sortOrder, onSortOrderChange, onMove)}
          </CardContent>
        </Card>
      </Grow>
    </Grid>
  );
}

export function ServiceOfferingCardContainer({ items, render }:IServiceOfferingContainerProps) {
  const [moved, setMoved] = useState(false);
  return (
    <Grid container item spacing={2}>
      {
        items.map((item, index) => {
          return (
            <ServiceOfferingCard
              key={item.id}
              idx={index}
              render={render}
              moved={moved}
              setMoved={setMoved}
            />
          );
        },
        )}
    </Grid>
  );
}