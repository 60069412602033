import Userfront from '@userfront/react';
import SignInHeader from 'components/Header/signInHeader';

export default function PasswordReset() {

  Userfront.init(window.REACT_APP_ADMIN_USERFRONT_TENANT);

  const PasswordResetForm = Userfront.build({
    toolId: window.REACT_APP_ADMIN_USERFRONT_PASSWORD_RESET,
  });

  return (
    <>
      <SignInHeader bottomMargin='30px' />
      <PasswordResetForm />
    </>

  );
}