
import { useParams } from 'react-router-dom';
import useLetterBatchNeighborhoodDetail from './hookStore/useLetterBatchNeighborhoodDetail';
import { Grid } from '@mui/material';
import LetterBatchNeighborhoodDetailForm from './LetterBatchNeighborhoodDetailForm';
import Loading from 'components/Layout/Loading';
import LetterBatchNeighborhoodDetailMetadata from './LetterBatchNeighborhoodDetailMetadata';

export interface ILetterBatchNeighborhoodDetailProps {
  isNew:boolean;
}

export default function LetterBatchNeighborhoodDetail({
  isNew,
}:ILetterBatchNeighborhoodDetailProps) {
  const { letterBatchId, letterBatchNeighborhoodId } = useParams();
  const { loadingKey } = useLetterBatchNeighborhoodDetail();

  return (
    <Loading loadingKey={loadingKey} size={100}>
      <Grid container>
        <Grid item xs={12}>
          <LetterBatchNeighborhoodDetailForm isNew={isNew}/>
        </Grid>
      </Grid>
    </Loading>
  );
}