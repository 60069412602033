
import { Status, Wrapper } from '@googlemaps/react-wrapper';
import { useMediaQuery, useTheme } from '@mui/material';
import { createFromLatLng, IGeoJSONCoordinates, ILatLng, IMapBounds } from 'model/maps';
import { useRef, useEffect, ReactElement, useState } from 'react';
import { findBounds, findCenter } from './util';

const render = (status: Status): ReactElement => {
  if (status === Status.LOADING) return <h3>{status} ..</h3>;
  if (status === Status.FAILURE) return <h3>{status} ...</h3>;
  return <div></div>;
};

export interface ICoverageAreaDrawableMapComponentProps {
  id:string;
  bounds: IMapBounds;
  center:{lat:number; lng:number};
  zoom:number;
  geoJson?:IGeoJSONCoordinates;
  postPolygonComplete: (data:ILatLng[], skipRefresh:boolean) => void;
  panToolHandler:any;
}
function CoverageAreaDrawableMapComponent({ id, bounds, center, zoom, geoJson, postPolygonComplete, panToolHandler }:ICoverageAreaDrawableMapComponentProps) {
  const theme = useTheme();
  // const [map, setMap] = useState<google.maps.Map | null>(null);
  //need to use refs because google event listeners are not aware of react state
  const mapRef = useRef<google.maps.Map | null>(null);
  const polygonRef = useRef<google.maps.Polygon | null>(null);
  const markerRef = useRef<google.maps.Marker | null>(null);
  const onPostPolygonComplete = useRef<any>(postPolygonComplete);
  //we need to give the instance of the panToolHandler a reference to the polygon when it is drawn or initialized from data
  const panToolHandlerRef = useRef<any>(panToolHandler);
  const markerListenerIdRef = useRef<google.maps.MapsEventListener>();
  const boundsChangedListenerIdRef = useRef<google.maps.MapsEventListener>();
  const overlayCompleteListenerIdRef = useRef<google.maps.MapsEventListener>();

  const width340 = useMediaQuery(theme.breakpoints.down(340));
  const width380 = useMediaQuery(theme.breakpoints.down(380));
  const smBrk = useMediaQuery(theme.breakpoints.down('sm'));
  const mdBrk = useMediaQuery(theme.breakpoints.down('md'));
  let width, height;

  if (width340) {
    width = '250px';
    height = '250px';
  } else if (width380) {
    width = '300px';
    height = '300px';
  } else if (smBrk) {
    width = '340px';
    height = '340px';
  } else if (mdBrk) {
    width = '450px';
    height = '450px';
  } else {
    width = '800px';
    height = '800px';
  }
  const ref = useRef();

  useEffect(() => {
    if (ref.current) {
      const _map = new window.google.maps.Map(ref.current, {
        disableDefaultUI: true,
        fullscreenControl: true,
        center,
        zoom,
        styles: [
          {
            featureType: 'poi',
            elementType: 'labels',
            stylers: [{ visibility: 'off' }],
          },
          {
            featureType: 'road',
            elementType: 'labels',
            stylers: [],
            // stylers: [{ visibility: 'off' }],
          },
        ],
      });

      let padding = 3;
      let _bounds = new window.google.maps.LatLngBounds(bounds.sw, bounds.ne);
      _map.fitBounds(_bounds, padding);


      const drawingManager = new window.google.maps.drawing.DrawingManager({
        drawingMode: null,
        drawingControl: true,
        polygonOptions: {
          editable: true,
          draggable: false,
          clickable: true,
        },
        drawingControlOptions: {
          position: google.maps.ControlPosition.TOP_CENTER,
          drawingModes: [
            google.maps.drawing.OverlayType.POLYGON,
          ],
        },
      });
      drawingManager.setMap(_map);
      const overlayCompleteListenerId = google.maps.event.addListener(drawingManager, 'overlaycomplete', function(event) {
        if (event.type === 'polygon') {
          const latLngs:ILatLng[] = getLatLngs(event.overlay);
          drawingManager.setDrawingMode(null); //immediately turn off drawing mode once a polygon is complete
          if (onPostPolygonComplete.current) {
            onPostPolygonComplete.current(latLngs);// need to notify form of the form state change (calls RHF setValue)
          }
          event.overlay.setMap(null); // delete the one that was just rendered as it will be redrawn by props on rerender
        }
      });
      overlayCompleteListenerIdRef.current = overlayCompleteListenerId;


      mapRef.current = _map;
    }
    return () => {
      if (overlayCompleteListenerIdRef.current) {
        google.maps.event.removeListener(overlayCompleteListenerIdRef.current);
      }
    };
  }, []);

  useEffect(() => {
    onPostPolygonComplete.current = postPolygonComplete;
  }, [postPolygonComplete]);

  useEffect(() => {
    panToolHandlerRef.current = panToolHandler;
  }, [panToolHandler]);

  useEffect(() => {
    //whenever a new geoJson is bound from props we need to reinit
    // the polygon and all listeners as google maps is not notified of react changes
    if (geoJson && geoJson?.coordinates?.length > 0) {
      const _polygon = new google.maps.Polygon({
        editable: true,
        clickable: true,
        draggable: false,
        paths: geoJson.coordinates.map(x => {
          return new google.maps.LatLng(x.latitude, x.longitude);
        }),
      });
      polygonRef.current = _polygon;
      //give the panToolHandler a reference to the polygon when it is instantiated so that the function call to mapRefFunc on the handler function
      //will refer to the correct polygon instance for the correct coverage area.
      panToolHandlerRef.current.polygonRef = _polygon;
      handleBoundsChanged(_polygon);
      _polygon.setMap(mapRef.current);
      _polygon.getPath().addListener('set_at', (e) => {
        const latLngs:ILatLng[] = getLatLngs(_polygon);
        if (onPostPolygonComplete.current) {
          onPostPolygonComplete.current(latLngs, true);// need to notify form of the form state change (calls RHF setValue)
        }
      });
      _polygon.getPath().addListener('remove_at', (e) => {
        const latLngs:ILatLng[] = getLatLngs(_polygon);
        if (onPostPolygonComplete.current) {
          onPostPolygonComplete.current(latLngs, true);// need to notify form of the form state change (calls RHF setValue)
        }
      });
      _polygon.getPath().addListener('insert_at', (e) => {
        const latLngs:ILatLng[] = getLatLngs(_polygon);
        if (onPostPolygonComplete.current) {
          onPostPolygonComplete.current(latLngs, true);// need to notify form of the form state change (calls RHF setValue)
        }
      });
      const _boundsChangedListener = _polygon.addListener('dragend', (e) => {

        handleBoundsChanged(_polygon);
      });

      boundsChangedListenerIdRef.current = _boundsChangedListener;


    }
    return () => {

      if (markerRef?.current) {
        markerRef.current.setMap(null);
      }
      if (polygonRef?.current) {
        polygonRef.current.setMap(null);
      }
      if (panToolHandlerRef?.current) {
        panToolHandlerRef.current.polygonRef = null;
      }
      if (markerListenerIdRef.current) {
        google.maps.event.removeListener(markerListenerIdRef.current);
      }
      if (boundsChangedListenerIdRef.current) {
        google.maps.event.removeListener(boundsChangedListenerIdRef.current);
      }
    };
  }, [geoJson]);

  function getLatLngs(_polygon: google.maps.Polygon):ILatLng[] {
    const latLngs:ILatLng[] = [];

    const paths:any = _polygon.getPaths().getArray();
    paths.forEach((e) => {
      if (e.getArray() && e.getArray().length > 0) {
        e.getArray().forEach((q) => {
          latLngs.push({ lat: q.lat(), lng: q.lng() });
        });
      }
    });

    return latLngs;
  }

  const handleBoundsChanged = (_polygon: google.maps.Polygon) => {
    const _latLngs = getLatLngs(_polygon);

    const geocoordinates = _latLngs.map(x => createFromLatLng(x));
    const _bounds = findBounds({ coordinates: geocoordinates, type: 'polygon' });
    const _center = findCenter(_bounds);
    const map:google.maps.Map | null = mapRef.current;
    const _marker = new google.maps.Marker({
      position: new google.maps.LatLng(_center.lat, _center.lng),
      map,
    });

    if (markerRef.current) {
      (markerRef.current as google.maps.Marker).setMap(null);
    }
    if (markerListenerIdRef.current) {
      google.maps.event.removeListener(markerListenerIdRef.current);
    }
    markerListenerIdRef.current = _marker.addListener('contextmenu', function(event) {
    });
    markerRef.current = _marker;
  };

  return <div ref={ref as any} id="coverage-area-drawable-map" style={{ width, height, margin: '0 auto' }}/>;
}

export interface ICoverageAreaDrawableMapProps {
  id:string;
  bounds: IMapBounds;
  center: {lat:number; lng: number};
  zoom: number;
  geoJson?: IGeoJSONCoordinates;
  postPolygonComplete: (data:ILatLng[], skipRefresh:boolean) => void;
  panToolHandler: any;
}

export default function CoverageAreaDrawableMap({ id, bounds, center, zoom, geoJson, postPolygonComplete, panToolHandler }:ICoverageAreaDrawableMapProps) {
  return (
    <Wrapper apiKey={window.REACT_APP_GMAPS_CLIENTSIDE_KEY} render={render} libraries={['drawing']}>
      <CoverageAreaDrawableMapComponent
        id={id}
        bounds={bounds}
        center={center}
        zoom={zoom}
        geoJson={geoJson}
        postPolygonComplete={postPolygonComplete}
        panToolHandler={panToolHandler}
      />
    </Wrapper>
  );
}