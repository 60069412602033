import { Grid } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import EditCell from './components/editCell';

export const columns: GridColDef[] = [

  {
    field: 'name',
    headerName: 'Category',
    width: 250,
    renderCell: (params: any) => {
      return <div>{params.row.name}</div>;
    },
  },
  {
    field: 'id',
    headerName: 'Id',
    width: 250,
    renderCell: (params: any) => {
      return <div>{params.row.serviceCategoryId}</div>;
    },
  },
  {
    field: 'actions',
    headerName: 'Actions',
    width: 150,
    renderCell: (params:any) => {
      return (
        <Grid container item xs={12} justifyContent='center' alignItems='center'>
          <EditCell name={params.row.name} id={params.row.serviceCategoryId}/>
        </Grid>
      );
    },
  },
];

export const columnTotalWidth = columns.map(x => x.width).reduce((a:any, b:any) => a+b, 0);