
import '@fontsource/sora';
import { StyledEngineProvider } from '@mui/material';
import { LicenseInfo } from '@mui/x-license-pro';
import Userfront from '@userfront/react';
import Axios from 'axios';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './index.css';

LicenseInfo.setLicenseKey(
  'dbab640162e5a5d17cc7fe9727ccd53cT1JERVI6NDI1MjIsRVhQSVJZPTE2ODI1NDA3MTAwMDAsS0VZVkVSU0lPTj0x',
);

let promise = new Promise<any>((resolve, reject) => {
  Userfront.addInitCallback((data) => {
    if (Userfront.user && Userfront.user.userUuid) {
      resolve({
        key: Userfront.user.userUuid,
        name: Userfront.user.name,
        email: Userfront.user.email,
      });
    } else {
      resolve({
        key: 'anonymous',
      });
    }
  });
  Userfront.init(window.REACT_APP_ADMIN_USERFRONT_TENANT);
});

Axios.defaults.withCredentials = true;

if (Userfront.tokens.accessToken) {
  Axios.defaults.headers.common = { Authorization: `Bearer ${Userfront.tokens.accessToken}` };
}

window.setInterval(async function() {
  if (Userfront.tokens.accessToken) {
    await Userfront.refresh();
    Axios.defaults.headers.common = { Authorization: `Bearer ${Userfront.tokens.accessToken}` };
  }
}, 1_800_000);


void (async () => {
  try {
    const userData = await promise;
    const LDProvider = await asyncWithLDProvider({
      clientSideID: window.REACT_APP_ADMIN_LAUNCH_DARKLY_CLIENT_ID,
      user: userData.key === 'anonymous' ? undefined : userData,
    });
    render(
      <StyledEngineProvider injectFirst>
        <BrowserRouter>
          <LDProvider>
            <App />
          </LDProvider>
        </BrowserRouter>
      </StyledEngineProvider>,
      document.getElementById('root'),
    );
  } catch (e:any) {
    console.error(e);
    render(
      <StyledEngineProvider injectFirst>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </StyledEngineProvider>,
      document.getElementById('root'),
    );
  }
})();