import { Grid, Typography } from '@mui/material';
import Loading from 'components/Layout/Loading';
import PageHeader from 'components/SectionHeaders/PageHeader';
import useTerritoryDetails from './hookStore/useTerritoryDetails';
import TerritoryForm from './territoryDetailsForm';
import TerritoryPrepConfirmationModal from './territoryPrep/TerritoryPrepConfirmationModal';
import TerritoryDataSection from './territoryDataManagement/TerritoryDataManagement';
import TerritoryNeighborhoodsSkipTraceConfirmationModal from './territoryDataManagement/TerritoryNeighborhoodsSkipTraceConfirmationModal';

export interface ITerritoryDetailsProps {
  isNew:boolean;
}

export default function TerritoryDetails({
  isNew,
}:ITerritoryDetailsProps) {
  const { loadingKey } = useTerritoryDetails();
  return (
    <Loading loadingKey={loadingKey} size={100}>
      <Grid container spacing={2} className='pageGridContainer'>
        <Grid item xs={12}>
          <PageHeader headerText={'Territory Details'} />
        </Grid>
        <Grid item xs={12}>
          <TerritoryForm isNew={isNew}/>
        </Grid>
      </Grid>
      <Grid>
        <TerritoryDataSection />
      </Grid>
      <TerritoryPrepConfirmationModal />
      <TerritoryNeighborhoodsSkipTraceConfirmationModal />
    </Loading>
  );
}