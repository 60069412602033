import createStore from 'hooks/hookStore';
import useToast from 'components/toast/useToast';
import { useLoading } from 'components/Layout/Loading';
import { useEffect, useState } from 'react';
import { sortByString } from 'util/sortFunctions';
import { ITsoiManagementBatch } from 'model/tsoiManagementBatch';
import { getTsoiManagementBatchById, updateTsoiManagementBatch } from 'api/tsoiManagementApi';
import { findServiceTypeInfo } from 'api/serviceCategoryApi';
import { ITerritory } from 'model/territories';
import { findTerritoryById } from 'api/territoriesApi';
import { createInitialTsoiTerritoryFromITerritory, createTsoiServiceType } from '../util';
import { useNavigate } from 'react-router-dom';

const loadingKey = 'TsoiManagementBatchServiceTypesStore';
type TsoiManagementBatchServiceTypesStore = {
  batch: ITsoiManagementBatch;
  territory: ITerritory;
  selectedServiceTypes: any[];
  options: any[];
}

const { get, update, registerListener, unregisterListener } = createStore<TsoiManagementBatchServiceTypesStore>('TsoiManagementBatchServiceTypesStore', {
  batch: {} as ITsoiManagementBatch,
  territory: {} as ITerritory,
  selectedServiceTypes: [],
  options: [],
});

const createEmpty = () => ({
  batch: {} as ITsoiManagementBatch,
  territory: {} as ITerritory,
  selectedServiceTypes: [],
  options: [],
});


export default function useTsoiManagementBatchServiceTypes() {
  const setState = useState(createEmpty)[1];
  const { onLoading, doneLoading } = useLoading(loadingKey);
  const { createErrorToast, createSuccessToast } = useToast();
  const navigate = useNavigate();


  useEffect(() => {
    registerListener(setState);
    return () => {
      unregisterListener(setState);
    };
  }, []);

  async function init(batchId:string | undefined, territoryId:string | undefined) {
    if (!batchId) {
      console.log('No batch id provided');
      return;
    }

    if (!territoryId) {
      console.log('No territory id provided');
      return;
    }
    onLoading();
    try {
      const batchResp = await getTsoiManagementBatchById(batchId);
      if (!batchResp.data?.marketId) {
        createErrorToast('Error with batch: no market ID present. This is likely a defect, please consult the dev team.');
        return;
      }

      const territoryResp = await findTerritoryById(batchResp.data.marketId, territoryId);
      if (!territoryResp.data?.id) {
        createErrorToast('Error with territory: no territory found. This is likely a defect, please consult the dev team.');
        return;
      }

      // get service types for the market
      const options = await getAndFormatServiceTypesForDropdown();
      // check to see if the batch has service types already selected for this territory
      // if it does, then hydrate the selected service types with those
      const batch = batchResp.data;
      const territoryData = batch.territories[territoryId];
      let startingSelectedServiceTypes = [] as any[];
      if (territoryData?.serviceTypes && territoryData.serviceTypes.length > 0) {

        // filter down the options list to only include options that are included
        // in the territory
        const selectedServiceTypeIds = territoryData.serviceTypes.map(x => x.id);
        startingSelectedServiceTypes = options.filter(x => selectedServiceTypeIds.includes(x.optionValue));
      }

      update({
        ...createEmpty(),
        batch: batch,
        options: options,
        selectedServiceTypes: startingSelectedServiceTypes,
        territory: territoryResp.data,
      });

    } catch (e:any) {
      createErrorToast('Error loading batch');
      console.error(e);
    }
    doneLoading(300);
  }

  function setSelectedServiceTypes(selectedServiceTypes:any[]) {
    update({
      ...get(),
      selectedServiceTypes,
    });
  }

  async function getAndFormatServiceTypesForDropdown() {

    const serviceTypeInfo = await findServiceTypeInfo();
    //not setting category here due to changes where category is no longer part of querying
    return serviceTypeInfo.data.serviceTypes
      .filter(x => !x.deleteDate)
      .map((x, i) => ({
        key: `${x.serviceTypeId}`,
        optionText: `${x.displayName ?? x.name}`,
        optionValue: `${x.serviceTypeId}`,
        ancillary: {
          serviceTypeId: x.serviceTypeId,
          serviceType: x.name,
          category: '',
        },
      }))
      .sort(sortByString('optionText'));

  }

  async function onSave() {
    try {
      // pull out existing territory
      const territoryId = get().territory.id;
      const batch = { ...get().batch };
      let territories = batch.territories;
      const serviceTypesToSave = get().selectedServiceTypes.map(x => createTsoiServiceType(
        x.ancillary.serviceTypeId,
        x.ancillary.serviceType,
      ));
      // if no territories are present, then create the initial one
      // TODO: should we do this initially when we create the batch?
      if (!territories) {
        let newTerritoriesMap = {};
        let newTerritoryEntry = createInitialTsoiTerritoryFromITerritory(get().territory);
        newTerritoryEntry.serviceTypes = serviceTypesToSave;
        newTerritoriesMap[territoryId] = newTerritoryEntry;
        batch.territories = newTerritoriesMap;
        const updateBatchResp = await updateTsoiManagementBatch(batch);
        // pull out existing service types
        // if territory, doesn't exist in map then add it and include service types list
        // if territory exists, then udpate the service types list accordingly
      } else {
        const territory = territories[territoryId];
        const newTerritory = { ...territory };
        newTerritory.serviceTypes = serviceTypesToSave;
        // When a user deletes a service type from the list, we need to
        // remove any service offerings in the territory whose service type is not in the final list
        const serviceTypeIds = serviceTypesToSave.map(x => x.id);
        var filteredServiceOfferings = newTerritory.serviceOfferings.filter((serviceOffering:any) => {
          return serviceTypeIds.includes(serviceOffering.serviceTypeId);
        });
        newTerritory.serviceOfferings = filteredServiceOfferings;
        territories[territoryId] = newTerritory;
        batch.territories = territories;
        const updateBatchResp = await updateTsoiManagementBatch(batch);
      }
      createSuccessToast('Service types saved');
      navigate(`/tsoiManagementBatches/${batch.id}/territory/${territoryId}/serviceProviders`);
    } catch (err) {
      createErrorToast('Error saving service types');
      console.error(err);
    }
    doneLoading();
  }

  async function onCancel() {
    navigate(`/tsoiManagementBatches/${get().batch.id}/summary`);
  }

  return {
    ...get(),
    getAndFormatServiceTypesForDropdown,
    init,
    loadingKey,
    onSave,
    onCancel,
    setSelectedServiceTypes,
  };
}