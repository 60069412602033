import { Grid } from '@mui/material';
import { HookTextField } from 'components/reactHookForm';

export default function AboutProviderInfo() {
  return (
    <Grid container item spacing={1} xs={12}>
      <Grid item xs={6}>
        <HookTextField
          label='About The Provider'
          name='aboutUs'
          multiline
          minRows={3}
          maxRows={10}
        />
      </Grid>
    </Grid>
  );
}