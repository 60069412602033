

import { emitTestPastCustomerRequestToReviewEvent, manualReceivePastCustomerRequestToReview } from 'api/batchEmailJobApi';
import { useLoading } from 'components/Layout/Loading';
import useToast from 'components/toast/useToast';
import createStore from 'hooks/hookStore';

import { useEffect, useState } from 'react';

type BatchEmailJob = {

}

const loadingKey = 'BatchEmailJob';

const { get, update, registerListener, unregisterListener } = createStore<BatchEmailJob>('BatchEmailJob', {

});

export default function useBatchEmailJob() {
  const setState = useState(get())[1];
  const { onLoading, doneLoading } = useLoading(loadingKey);

  const { createInfoToast, createSuccessToast, createErrorToast } = useToast();

  useEffect(() => {
    registerListener(setState);
    return () => {
      unregisterListener(setState);
    };
  }, []);

  async function init() {
    onLoading();
    update({
      ...get(),
    });
    doneLoading(300);
  }

  async function onEmitTestPastCustomerRequestToReviewEvent() {
    onLoading();
    try {
      await emitTestPastCustomerRequestToReviewEvent();
      createSuccessToast('Success');
    } catch (error) {
      createErrorToast('Error');
    }
    doneLoading();
  }

  async function onManualReceivePastCustomerRequestToReview() {
    onLoading();
    try {
      await manualReceivePastCustomerRequestToReview();
      createSuccessToast('Success');
    } catch (error) {
      createErrorToast('Error');
    }
    doneLoading();
  }

  return {
    loadingKey,
    ...get(),
    init,
    onManualReceivePastCustomerRequestToReview,
    onEmitTestPastCustomerRequestToReviewEvent,
  };
}