import PageHeader from 'components/SectionHeaders/PageHeader';
import BatchesDataTable from './_components/BatchesDataTable';
import { Grid } from '@mui/material';
import { PrimaryButton } from 'components/buttons';
import { useNavigate } from 'react-router-dom';

export default function TsoiManagementBatches() {
  const navigate = useNavigate();

  const onCreateNewBatchButtonClick = () => {
    navigate('/tsoiManagementBatches/new');
  };

  return (
    <Grid container sx={{ flexDirection: 'column', paddingLeft: '10px', paddingRight: '10px' }}>
      <Grid item xs={12} paddingTop={'25px'} paddingBottom={'25px'}>
        <PageHeader headerText="TSOI Happening Soon Management" />
      </Grid>
      <Grid item xs={12} paddingTop={'25px'} paddingBottom={'25px'}>
        <PrimaryButton onClick={onCreateNewBatchButtonClick}>
            Create New Batch
        </PrimaryButton>
      </Grid>
      <Grid item xs={12}>
        <BatchesDataTable />
      </Grid>
    </Grid>
  );
}