import { Grid, TextField, IconButton } from '@mui/material';
import { useState, useEffect } from 'react';
import SwapHorizOutlinedIcon from '@mui/icons-material/SwapHorizOutlined';
export interface IMoveCustomFieldProps {
  arrayLength:number;
  move:any;
  index:number;
  moved:boolean;
  setMoved:any;
}

export default function MoveCustomField({ arrayLength, move, index, moved, setMoved }:IMoveCustomFieldProps) {
  const [sortOrder, setSortOrder] = useState<number>(index);
  function onSortOrderChange(e) {
    setSortOrder(e.target.value);
  }
  useEffect(() => {
    if (moved) {
      setSortOrder(index);
      setMoved(false);
    }
  }, [moved]);
  return (
    <Grid item xs={12}>
      <TextField
        variant='standard'
        size='small'
        label='sort order'
        value={sortOrder}
        onChange={onSortOrderChange}
        sx={{
          width: '80px',
        }}
      />
      <IconButton
        type='button'
        onClick={() => {
          if (sortOrder >= 0 && sortOrder < arrayLength) {
            move(index, sortOrder);
            setMoved(true);
          }
        }}>
        <SwapHorizOutlinedIcon/>
      </IconButton>
    </Grid>
  );
}