import { Grid, Typography } from '@mui/material';
import GenericModal from 'components/modal/GenericModal';
import useModal, { IModal } from 'components/modal/useModal';
import { useNavigate } from 'react-router-dom';

export const modalStoreKey = 'CloneServiceOfferingModal';

export interface ICloneServiceOfferingModal {
  serviceProviderId: string;
  name:string;
  serviceOfferingId:string;
}
export function createEmptyCloneServiceOfferingModal() :ICloneServiceOfferingModal {
  return {
    serviceProviderId: '',
    name: '',
    serviceOfferingId: '',
  };
}
export interface ICloneServiceOfferingModalProps {
  onCloneWithImages: (serviceProviderId:string, serviceOfferingId:string) => void;
  onCloneWithoutImages: (serviceProviderId:string, serviceOfferingId:string) => void;
}

export default function CloneServiceOfferingModal({ onCloneWithImages, onCloneWithoutImages }:ICloneServiceOfferingModalProps) {
  const { getModalState, closeModal } = useModal(modalStoreKey);
  const _state = getModalState() as ICloneServiceOfferingModal & IModal;
  const { serviceProviderId, serviceOfferingId } = _state;

  async function _onClone(state:ICloneServiceOfferingModal & IModal, cloneWithImages:boolean) {
    if (cloneWithImages) {
      onCloneWithImages(serviceProviderId, serviceOfferingId);
    } else {
      onCloneWithoutImages(serviceProviderId, serviceOfferingId);
    }
    closeModal(createEmptyCloneServiceOfferingModal());
  }

  return (
    <GenericModal
      modalStoreKey={modalStoreKey}
      title={'Clone options'}
      modalContent={
        (
          <div >
            <Typography variant='body1'>Do you want to clone {_state.name} with images?</Typography>
          </div>
        )
      }
      onConfirm={() => _onClone(_state, true)}
      onSecondaryAction={() => _onClone(_state, false)}
      primaryButtonText='Continue with images'
      secondaryButtonText='Continue without images'
    />
  );
}