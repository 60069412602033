import { generateUnusedCodes } from 'api/rewardsApi';
import { useLoading } from 'components/Layout/Loading';
import useToast from 'components/toast/useToast';
import createStore from 'hooks/hookStore';
import { createEmptyRewardCodesForm, ICreateRewardCodesForm } from 'model/rewards';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const loadingKey = 'RewardCodeManagement';
type RewardCodeManagementStore = {
  formData: ICreateRewardCodesForm;
}

const { get, update, registerListener, unregisterListener } = createStore<RewardCodeManagementStore>('RewardCodeManagement', {
  formData: createEmptyRewardCodesForm(),
});

export default function useRewardCodeManagement() {
  const setState = useState(get())[1];
  const navigate = useNavigate();
  const { onLoading, doneLoading } = useLoading(loadingKey);
  const { createErrorToast, createInfoToast } = useToast();

  useEffect(() => {
    registerListener(setState);
    return () => {
      unregisterListener(setState);
    };
  }, []);

  async function init() {
    onLoading();
    try {

      update({
        ...get(),
      });
    } catch (e:any) {
      console.error(e);
    }
    doneLoading(300);
  }

  async function createRewardCodes(data:ICreateRewardCodesForm) {
    const dataToSave = { ...data };
    onLoading();
    try {
      const res = await generateUnusedCodes(dataToSave);
      update({
        ...get(),
        formData: createEmptyRewardCodesForm(),
      });
    } catch (err:any) {
      console.error(err);
    }
    doneLoading(300);
  }

  return {
    ...get(),
    loadingKey,
    init,
    createRewardCodes,
  };
}