import { FormControl, InputLabel, FormHelperText } from '@mui/material';
import { HookSelect } from 'components/reactHookForm';
import useErrorState from 'hooks/reactHookForm/useErrorState';
import { useFormContext, useWatch } from 'react-hook-form';
import useServiceOfferingDetails from './hookStore/useServiceOfferingDetails';

export default function DiscountScheduleSelect() {
  const { discountScheduleOptions } = useServiceOfferingDetails();
  const formCtx = useFormContext();

  const { isError, getErrorMessage } = useErrorState(formCtx);
  useWatch({ control: formCtx.control, name: 'defaultDiscountScheduleId' });
  return (
    <FormControl variant='standard' fullWidth error={isError('defaultDiscountScheduleId')}>
      <InputLabel htmlFor="default-discount-schedule">Default Discount Schedule</InputLabel>
      <HookSelect
        rules={{
          required: { value: false, message: 'Required' },
        }}
        control={formCtx.control}
        name='defaultDiscountScheduleId'
        id='default-discount-schedule'
        menuItems={discountScheduleOptions}
      />
      <FormHelperText>{getErrorMessage('defaultDiscountScheduleId')}</FormHelperText>
    </FormControl>
  );
}