import { Button } from '@mui/material';
import useConfirmationModal from 'components/modals/useConfirmationModal';
import { IProviderCoverageAreaLaunchToolingForm, INeighborhoodLaunchForm } from 'model/neighborhoodLaunch';
import { FieldValues, SubmitErrorHandler, SubmitHandler, useFormContext } from 'react-hook-form';
import { ILaunchProviderValidationResult } from '../NeighborhoodLaunchToolingForm';
import { launchProviderModalKey } from '../ProviderCoverageAreaLaunchToolingForm';

export default function SubmitButton() {
  const { handleSubmit, setError, clearErrors, formState: { isSubmitting, isValid }, getValues } = useFormContext();
  const { openModal } = useConfirmationModal(launchProviderModalKey);

  function validate(data:IProviderCoverageAreaLaunchToolingForm):ILaunchProviderValidationResult {
    const result = { isValid: true };
    const { serviceProviderLaunchDtos } = data;
    for (let i = 0; i < serviceProviderLaunchDtos.length;i++) {
      let dto = serviceProviderLaunchDtos[i];
      if (dto.serviceOfferingOptions.filter(x => x.checked).length === 0) {
        setError(`serviceProviderLaunchDtos.${i}`, {
          type: 'manual',
          message: 'at least one service offering must be checked',
        });
        result.isValid = false;
      } else {
        clearErrors(`serviceProviderLaunchDtos.${i}`);
      }
    }
    return result;
  }

  const onSubmit : SubmitHandler<IProviderCoverageAreaLaunchToolingForm> = data => {
    const { isValid: _isValid } = validate(data);
    if (_isValid) {
      openModal(data);
    }
  };
  const onSubmitError: SubmitErrorHandler<FieldValues> = data => {
    const { isValid: _isValid } = validate(getValues() as IProviderCoverageAreaLaunchToolingForm);
    if (_isValid) {
      openModal(getValues());
    }
  };

  return (
    <Button
      disabled={isSubmitting || !isValid}
      variant='contained'
      type='button'
      onClick={(e) => {
        // TODO: figure out why this onSubmit gives me inconsistent ts errors
        // @ts-expect-error
        void handleSubmit(onSubmit, onSubmitError)(e);
      }}
    >
              Save
    </Button>
  );
}