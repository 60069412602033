import { FormControl, FormHelperText, InputLabel } from '@mui/material';
import { HookSelect } from 'components/reactHookForm';
import { MetadataConstants } from 'constants/MetadataConstants';
import useErrorState from 'hooks/reactHookForm/useErrorState';
import { StateAbbreviations } from 'model/markets';
import { useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { sortByString } from 'util/sortFunctions';

const sortByName = sortByString('name');

export default function StateAbbrSelect() {
  const formCtx = useFormContext();
  const [states] = useState<any[]>(StateAbbreviations.list.sort(sortByName));
  const { isError, getErrorMessage } = useErrorState(formCtx);
  const selectedMarketId = useWatch({ name: 'id' });

  return (
    <FormControl variant='standard' fullWidth error={isError('stateAbbr')}>
      <InputLabel htmlFor="StateAbbrSelect">State Abbreviation</InputLabel>
      <HookSelect
        rules={{
          required: { value: true, message: 'Required' },
        }}
        disabled={MetadataConstants.UNASSIGNED_MARKET_ID === selectedMarketId}
        control={formCtx.control}
        name='stateAbbr'
        id='StateAbbrSelect'
        menuItems={states}
      />
      <FormHelperText>{getErrorMessage('stateAbbr')}</FormHelperText>
    </FormControl>
  );
}