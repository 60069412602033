import { IDropdownOption } from './dropdown';

export interface IProviderRecommendationsForm {
  territoryId:string | null;
  searchTerm?:string;
}
export interface INextdoorSearchPostRequest {
  territoryId:string;
  searchTerm:string;
}
export interface INextDoorSearchPostsResult {
  id:string;
  title:string;
  body:string;
  category:string;
  author:INextDoorPostAuthor;
}

export interface INextDoorPostAuthor {
  name:string;
  neighborhood_name:string;
}

export function createEmptyProviderRecommendationsForm():IProviderRecommendationsForm {
  return {
    territoryId: null,
    searchTerm: '',
  };
}