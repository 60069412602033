import { Grid } from '@mui/material';
import HookConfirmationModal from 'components/modals/HookConfirmationModal';
import { createEmptyProviderCoverageAreaForm, IProviderCoverageAreaForm } from 'model/maps';
import { useEffect } from 'react';
import { useForm, FormProvider, useFormContext, useWatch } from 'react-hook-form';
import CoverageAreaList from './formComponents/CoverageAreaList';

import ServiceProviderSelect from './formComponents/ServiceProviderSelect';
import SubmitButton from './formComponents/SubmitButton';
import useProviderCoverageArea from './hookStore/useProviderCoverageArea';


export interface IProviderCoverageAreaFormInternalProps {
  initialFormData:IProviderCoverageAreaForm;
}

export const providerCoverageAreaModalKey = 'providerCoverageAreaModalKey';

function ModalWrapper() {
  const { getValues, control } = useFormContext();
  const { save } = useProviderCoverageArea();
  return (
    <HookConfirmationModal
      title={() => 'Confirm Provider Coverage Area'}
      body='This will update the coverage areas associated to this provider, but you will need to manually launch/re-launch via the launch tooling for any NSO updates.'
      modalKey={providerCoverageAreaModalKey}
      message={(confirmArgs, cancelModal) => (
        <Grid container spacing={1} alignItems='center' justifyContent='center' >
          <Grid item xs={12}>

          </Grid>
        </Grid>
      )}
      onConfirm={async (data) => {
        await save(data);
      }}

    />
  );
}


function ProviderCoverageAreaFormInternal({ initialFormData }:IProviderCoverageAreaFormInternalProps) {
  const formContext = useForm<IProviderCoverageAreaForm>({ mode: 'onChange', defaultValues: createEmptyProviderCoverageAreaForm() });
  useEffect(() => {
    formContext.reset(initialFormData);
  }, [initialFormData]);
  const watchedServiceProviderId = useWatch({ control: formContext.control, name: 'serviceProviderId' });

  return (
    <FormProvider {...formContext}>
      <form >
        <Grid container alignItems='start'>
          <Grid item xs={12} sm={12} md={3} >
            <ServiceProviderSelect />
          </Grid>
          <CoverageAreaList/>
          <Grid container item xs={12} sx={{ marginTop: '10px' }}>
            <SubmitButton/>
          </Grid>
          <ModalWrapper/>
        </Grid>
      </form>
    </FormProvider>
  );
}

export default function ProviderCoverageAreaForm() {
  const { formData, init } = useProviderCoverageArea();
  useEffect(() => {
    void init();
  }, []);
  return (
    <ProviderCoverageAreaFormInternal initialFormData={formData}/>
  );
}