
import { DiscountScheduleStandardOptions, DiscountScheduleTypes } from 'model/discountSchedule';
import { CadenceType, IFormFriendlyServiceOffering } from 'model/serviceOffering';
import isEqual from 'lodash/isEqual';

export function createDefaultServiceOfferingForProviderOnboarding({ providerId = '' }):IFormFriendlyServiceOffering {
  return {
    cadenceType: '',
    customFields: [],
    frequency: '',
    hasDisabledMaxCustomerCount: false,
    id: '',
    invitationDescription: '',
    name: '',
    providerId: providerId,
    serviceDetailSections: [
      {
        sectionIcon: 'listIcon',
        sectionHeaderName: 'Service Details',
        sectionItems: ['Our Services Include:'],
        sectionListItems: [
          { val: 'Lorem ipsum dolor sit amet' },
          { val: 'Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit' },
          { val: 'Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur' },
        ],
      },
    ],
    workflowSteps: [
      {
        stepName: 'CONTACT_CUSTOMER',
        shouldShow: true,
      },
      {
        stepName: 'CONSULTATION_SCHEDULED',
        shouldShow: false,
      },
      {
        stepName: 'QUOTE_OR_ESTIMATE_SENT',
        shouldShow: true,
      },
      {
        stepName: 'CUSTOMER_CONFIRMED',
        shouldShow: true,
      },
    ],
    serviceTypeId: '',
    serviceType: '',
    serviceCategory: '',
    subscriptionOptions: {
      label: '',
      options: [
        {
          optionName: '',
          optionValue: '',
          isDefaultOption: false,
          isOneTime: false,
          isNew: true,
        },
      ],
      subscriptionTermInMonths: 0,
    },
    cutoffDayCount: 3,
    defaultDiscountScheduleId: '',
    defaultForServiceType: true,
    territoryServiceOfferingEnabled: true,
    providerCoverageAreaId: '',
  };
}

export function createInitialServiceOfferingFormDataFromExisting(existingServiceOffering):IFormFriendlyServiceOffering {
  const final = { ...existingServiceOffering };
  // adding back subscrition options if they don't exist
  // they will be removed prior to submission for one time cadence type
  if (!final.subscriptionOptions) {
    final.subscriptionOptions = {
      label: '',
      options: [
        {
          optionName: '',
          optionValue: '',
          isDefaultOption: false,
          isOneTime: false,
          isNew: true,
        },
      ],
      subscriptionTermInMonths: 0,
    };
  }
  // format serviceDetailsSections
  final.serviceDetailSections = final.serviceDetailSections.map((section) => {
    const updatedSectionItems = section.sectionItems.map((item) => ({ val: item }));
    const updatedSectionListItems = section.sectionListItems.map((item) => ({ val: item }));

    return {
      ...section,
      sectionItems: updatedSectionItems,
      sectionListItems: updatedSectionListItems,
    };
  });
  return final;
}

export function shouldServiceTypeHavePrice(serviceType: string): Boolean {
  // should probably configure defaults in DB
  const hidePriceServiceTypes = ['Window Cleaning', 'Handyman',
    'Tree Services', 'Painting', 'Home Cleaning', 'Outdoor Living', 'Roof Inspection & Maintenance', 'Landscaping', 'Exterior Painting', 'Leaf Clean Up',
    'Fencing', 'Garage Door Repair', 'Flooring', 'TV Mounting', 'Brick Repair & Restoration', 'Home Media & Technology', 'Landscape Design', 'Stump Removal', 'Concrete Sealing', 'Window & Door Replacement'];

  if (hidePriceServiceTypes.includes(serviceType)) {
    return false;
  }
  return true;
}

export function createDefaultDiscSchedByServiceAndCadence(serviceType: string, cadenceType: CadenceType) {
  let defaultObj;
  if (shouldServiceTypeHavePrice(serviceType)) {
    defaultObj = {
      hasPrice: true,
      name: `Standard Discount (${serviceType})`,
      priceLabel: 'Starting at',
      priceAmount: 125,
      priceSuffix: '',
      priceSubText: '',
    };
  } else {
    defaultObj = {
      hasPrice: false,
      name: `Standard Discount (${serviceType})`,
      noPriceTextField: 'Free Estimate',
    };
  }

  if (cadenceType === CadenceType.ONETIME_ONLY || cadenceType === CadenceType.MOSTLY_ONETIME ) {
    defaultObj.annualBillingCycleCount = 1;
  }

  defaultObj.type = DiscountScheduleTypes.PercentSavePerCustomer;
  defaultObj.discountSchedule = {
    startingAt: '0',
    discountIncrement: '5',
    maxDiscount: '10',
    startingCountOffset: '0',
  };

  return defaultObj;
};

export function getDiscountScheduleStandardOptionFromSchedule(discountSchedule) {
  const match = DiscountScheduleStandardOptions.list.find(
    (option) => (option.discountType === discountSchedule.type && isEqual(option.options, discountSchedule.discountSchedule)),
  );

  return match?.id;
}
