import { useFlags } from 'launchdarkly-react-client-sdk';
import GenericCTABannerDTOForm from './GenericCTABannerDTOForm';
import { Accordion, AccordionDetails, AccordionSummary, Grid } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function NeighborhoodBannerLDConfig() {

  const {
    launchedNeighborhoodBannerOffer,
  } = useFlags();
  return (
    <Grid container spacing={2} className='pageGridContainer' >
      <Grid item xs='auto'>
        <Accordion sx={{ width: '100%' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <h1>NeighborhoodBannerLDConfig</h1>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <h3>Current Feature Flag for Admin tenant</h3>
              <pre style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word', overflow: 'auto' }}>{JSON.stringify(launchedNeighborhoodBannerOffer, null, '\t')}</pre>

            </div>
          </AccordionDetails>
        </Accordion>


      </Grid>
      <Grid item xs={12}>
        <GenericCTABannerDTOForm/>
      </Grid>

    </Grid>
  );
}