import { Button, CircularProgress, Grid } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { deleteNeighborhood, findAllNeighborhoods, searchNeighborhoods } from 'api/neighborhoodApi';
import { EditButton, RemoveButton } from 'components/buttons';
import CopyToClipboardCell from 'components/DataGridCell/CopyToClipboard';
import QuickSearchToolbar from 'components/grid/QuickSearchToolbar';
import useTableSearch from 'components/grid/useTableSearch';
import ConfirmationModal, { ConfirmationModalContext, ConfirmationModalContextProvider } from 'components/modals/ConfirmationModal';
import PageHeader from 'components/SectionHeaders/PageHeader';
import useToast from 'components/toast/useToast';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './neighborhoods.module.css';
import debounce from 'lodash.debounce';
import { INeighborhoodSearchRequest, ISearchPage, IStreetFairSearchRequest } from 'model/search';
import { INeighborhood } from 'model/neighborhood';
import { AxiosResponse } from 'axios';
import Loading from 'components/Layout/Loading';
import useMarkets from 'hooks/useMarkets';
import OpenWelcomePageShortLinkModalButton from './welcomePageShortLink/OpenWelcomePageShortLinkModalButton';
import { WelcomePageShortLinkModal } from './welcomePageShortLink';

type neighborhoodSearchResultType = Promise<AxiosResponse<ISearchPage<INeighborhood>>>;
const debouncedSearchNeighborhoods = debounce(async (dto:IStreetFairSearchRequest, callback:(neighborhoodSearchResultType) => any) => {
  const res = await searchNeighborhoods(dto);
  callback(res.data);
}, 400);

function DeleteCell({ id, name, deleteDate }) {
  const confirmationModalCtx = useContext(ConfirmationModalContext);
  return (
    <RemoveButton
      disabled={deleteDate != null}
      tooltipTitle='Remove neighborhood'
      onClick={() => confirmationModalCtx.openModal({ id, name })}
      tooltipProps={{
        placement: 'top',
      }}
      iconButtonProps={{
        className: styles.actionButton,
      }}
    />
  );
}

function EditCell({ id, name }) {
  const navigate = useNavigate();
  function handleClick() {
    navigate(`/neighborhoods/${id}/neighborhood-details`);
  }
  return (
    <EditButton
      tooltipTitle={`Edit ${name}`}
      onClick={handleClick}
      tooltipProps={{
        placement: 'top',
      }}
      iconButtonProps={{
        className: styles.actionButton,
      }}
    />
  );
}

const columns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Name',
    width: 350,
    renderCell: (params: any) => {
      return (
        <>
          <a target="_blank" rel='noreferrer' href={`${window.REACT_APP_CONSUMER_BASE_URI}/home/${params.row.id ?? params.row.objectID}?preventRedirect=true`} >{params.value}</a>
          <a target="_blank" rel='noreferrer' href={`${window.REACT_APP_CONSUMER_BASE_URI}/welcome/${params.row.id ?? params.row.objectID}?preventRedirect=true`} style={{ fontSize: '10px', paddingLeft: '12px' }}>welcome page</a>
          {
            (params.row.status === 'BETA')
            && <a target="_blank" rel='noreferrer' href={`${window.REACT_APP_CONSUMER_BASE_URI}/neighborhood-launch-details?neighborhoodId=${params.row.id ?? params.row.objectID}`} style={{ fontSize: '10px', paddingLeft: '12px' }}>
               neighborhood unlock page
            </a>
          }
        </>
      );
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 250,
    renderCell: (params:any) => {
      return params.row.deleteDate ? `${params.row.status} (Deleted)` : params.row.status;
    },
  },
  {
    field: 'id',
    headerName: 'GUID',
    width: 500,
    renderCell: (params:any) => {
      return (<CopyToClipboardCell val={params.id ?? params.objectID}/>);
    },
  },
  {
    field: 'zipCodes',
    headerName: 'Zip Codes',
    width: 300,
    renderCell: (params:any) => {
      let zipCodes = params.row.zipCodes;
      if (!zipCodes) {
        return null;
      }
      return zipCodes.join(',');
    },
  },
  {
    field: 'actions',
    headerName: 'Actions',
    width: 150,
    renderCell: (params:any) => {
      return (
        <Grid container item xs={12} justifyContent='center' alignItems='center'>
          <OpenWelcomePageShortLinkModalButton
            neighborhoodId={params.id}
            neighborhoodName={params.row.name}
            disabled={params.row.deleteDate != null}
          />
          <EditCell id={params.id} name={params.row.name}/>
          <DeleteCell id={params.id} name={params.row.name} deleteDate={params.row.deleteDate}/>
        </Grid>
      );
    },
  },
];

const columnTotalWidth = columns.map(x => x.width).reduce((a:any, b:any) => a+b, 0);
const quickSearchStorageKey = 'neighborhoods';
function Neighborhoods () {
  const navigate = useNavigate();
  const { createErrorToast } = useToast();
  const {
    searchResultsLoading,
    rows,
    rowCountState,
    searchText,
    paginationModel,
    clearStoredSearch,
    setSearchResultsLoading,
    setRowsWithStoredFilter,
    setRowCountState,
    requestAsyncSearch,
    onPageChange,
    onPageSizeChange,
    setPaginationModel,
  } = useTableSearch(quickSearchStorageKey, [], 10);
  const { selectedMarket } = useMarkets();
  const [sortModel, setSortModel] = useState([
    {
      field: 'name',
      sort: 'asc' as any,
    },
  ]);

  function addNewButtonClick(e: any) {
    navigate('/neighborhoods/create/neighborhood-details');
  }

  const _searchNeighborhoods = useCallback(async () => {
    setSearchResultsLoading(true);
    var searchRequest:INeighborhoodSearchRequest = {
      offset: 0,
      pageNumber: paginationModel.page,
      pageSize: paginationModel.pageSize,
      query: searchText,
      marketId: selectedMarket?.id,
      statuses: [],
      zipCodeTerritories: [],
      ids: [],

    };
    debouncedSearchNeighborhoods(searchRequest, (data:ISearchPage<INeighborhood>) => {
      setRowCountState(data.availablePagesCount * data.pageSize);
      setRowsWithStoredFilter(data.items);
      setSearchResultsLoading(false);
    });

  }, [paginationModel, selectedMarket]);

  const _deleteNeighborhood = useCallback(async ({ id }) => {
    try {
      if (id) {
        await deleteNeighborhood(id);
        await _searchNeighborhoods();
      }
    } catch (e:any) {
      console.error(`Unable to delete neighborhood. Message=${e.response?.data?.message}`);
      createErrorToast(`Unable to delete neighborhood. Message=${e.response?.data?.message}`);
    }
  }, [_searchNeighborhoods]);

  useEffect(() => {
    if (selectedMarket) {
      void _searchNeighborhoods();
    }

  }, [_searchNeighborhoods, selectedMarket]);

  return (
    <ConfirmationModalContextProvider onConfirm={_deleteNeighborhood}>
      <Grid container spacing={2} className='pageGridContainer'>
        <Grid item xs={12}>
          <PageHeader headerText={'NEIGHBORHOODS'} />
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" onClick={addNewButtonClick}>Add New</Button>
        </Grid>
        <Grid item xs={12}>

        </Grid>
        <Grid item xs={12}>
          <DataGrid
            loading={searchResultsLoading}
            pagination={true}
            rowsPerPageOptions={[10, 20, 50]}
            page={paginationModel.page}
            pageSize={paginationModel.pageSize}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            getRowId={(row) => row.objectID ?? row.id}
            paginationMode='server'
            rowCount={rowCountState}
            rows={rows}
            components={{ Toolbar: QuickSearchToolbar }}
            componentsProps={{
              toolbar: {
                quickSearchStorageKey,
                value: searchText,
                onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                  void requestAsyncSearch(
                    debouncedSearchNeighborhoods,
                    {
                      offset: 0,
                      pageNumber: paginationModel.page,
                      pageSize: paginationModel.pageSize,
                      query: event.target.value,
                      marketId: selectedMarket?.id,
                      statuses: [],
                      zipCodeTerritories: [],
                      ids: [],
                    });
                },
                clearSearch: () => {
                  setPaginationModel({
                    page: 0,
                    pageSize: paginationModel.pageSize,
                  });
                  clearStoredSearch();
                  void requestAsyncSearch(debouncedSearchNeighborhoods,
                    {
                      offset: 0,
                      pageNumber: 0,
                      pageSize: paginationModel.pageSize,
                      query: '',
                      marketId: selectedMarket?.id,
                      statuses: [],
                      zipCodeTerritories: [],
                      ids: [],
                    });
                },
              },
            }}

            columns={columns}
            autoHeight={true}
            sortModel={sortModel}
            onSortModelChange={setSortModel}
            style={{
              minWidth: '500px',
              maxWidth: `${columnTotalWidth}px`,
            }}
          />
        </Grid>
      </Grid>
      <ConfirmationModal
        title={(onConfirmArgs) => (<div className={styles.confirmDeleteModalTitle}>Delete neighborhood</div>)}
        message={(onConfirmArgs:any) => (
          <Grid container spacing={1} alignItems='center' justifyContent='center' className={styles.confirmDeleteModal}>
            <Grid item xs>
              You are about to delete {onConfirmArgs.name}.
            </Grid>
          </Grid>
        )}
      />
      <WelcomePageShortLinkModal/>
    </ConfirmationModalContextProvider>
  );
}

export default Neighborhoods;
