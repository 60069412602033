import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { getIndividualRewardsAuditHistory } from 'api/individualApi';
import useIndividualsHookStore from '../individualsHookStore';
import { IIndividualRewardAuditHistory } from 'model/individual';
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import { Typography } from '@mui/material';

export default function RewardsAuditTable() {
  const [rows, setRows] = React.useState([] as IIndividualRewardAuditHistory[]);
  const { individual } = useIndividualsHookStore();
  React.useEffect(() => {
    getIndividualRewardsAuditHistory(individual.id.toString())
      .then((response) => {
        setRows(response.data.reverse()); // reverse to show most recent first
      }).catch((error) => {
        console.error(error);
      });
  }, [individual]);

  return (
    rows.length === 0 ? <Typography>No rewards history found</Typography> :
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell><Typography fontWeight={'bold'}>Date</Typography></TableCell>
              <TableCell align="right"><Typography fontWeight={'bold'}>Action</Typography></TableCell>
              <TableCell align="right"><Typography fontWeight={'bold'}>Ending Balance</Typography></TableCell>
              <TableCell align="right"><Typography fontWeight={'bold'}>Reward Type</Typography></TableCell>
              <TableCell align="right"><Typography fontWeight={'bold'}>Note</Typography></TableCell>
              <TableCell align="right"><Typography fontWeight={'bold'}>Initiating User</Typography></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.createDate}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell >{format(parseISO(row.createDate), 'Pppp')}</TableCell>
                <TableCell component="th" scope="row">
                  <Typography align="right" fontWeight={'bold'} color={row.actionType === 'ADD' ? 'green' : 'red'}>
                    {row.actionType === 'ADD' ? `+ $${row.amount}` : `- $${row.amount}`}
                  </Typography>
                </TableCell>
                <TableCell align="right">{`$${row.endingBalance}`}</TableCell>
                <TableCell align="right">{row.rewardType}</TableCell>
                <TableCell align="right" sx={{ maxWidth: '200px', wordWrap: 'break-word' }}>{row.context}</TableCell>
                <TableCell align="right">{row.creatorId}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
  );
}