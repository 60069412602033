import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import TextField from '@mui/material/TextField';
import useErrorState from 'hooks/reactHookForm/useErrorState';
import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

export interface IHookDatePickerProps {
  name:string;
  label:string;
  disabled?:boolean;
  required?:boolean;
  validationRules?: any;
  datePickerProps?:any;
  textFieldProps?:any;
  dateFormats?:any;
}

export default function HookDatePicker({ name, label, disabled, required, validationRules, datePickerProps, textFieldProps, dateFormats }:IHookDatePickerProps) {
  const methods = useFormContext();
  const [open, setOpen] = React.useState(false);
  const { control } = methods;
  const { isError, getErrorMessage } = useErrorState(methods);
  const rules:any = {
    required: {
      value: required || false, message: 'Required',
    },
    ...validationRules,
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=''
      rules={rules}
      render={({
        field: { onChange, onBlur, value, ref },
        fieldState: { invalid, isTouched, isDirty, error },
        ...parmas
      }) => {
        return (
          <LocalizationProvider dateAdapter={AdapterDateFns} dateFormats={dateFormats}>
            <DatePicker
              open={open}
              disabled={disabled}
              onOpen={() => setOpen(true)}
              onClose={() => setOpen(false)}
              label={label}
              value={value || null}

              onChange={(e:any) => {
                onChange(e);
                void methods.trigger(name);
              }}
              onBlur={onBlur}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    {...textFieldProps}
                    id={`${name}-datepicker-input`}
                    error={isError(name)}
                  />
                );
              }}
              {...datePickerProps}
            />
          </LocalizationProvider>
        );
      }
      }
    />
  );
}