import { Grid, Typography } from '@mui/material';
import { sendInvitesToNeighborConnections } from 'api/neighborConnectionsApi';
import { approveProviderForSap } from 'api/serviceProviderApi';
import Loading, { useLoading } from 'components/Layout/Loading';
import GenericModal from 'components/modal/GenericModal';
import useModal, { IModal } from 'components/modal/useModal';
import NeighborhoodAutocomplete from 'components/neighborhoodAutocomplete/NeighborhoodAutocomplete';
import { HookCheckbox, HookTextField } from 'components/reactHookForm';
import useToast from 'components/toast/useToast';
import { set } from 'date-fns';
import { IBatchSendNeighborConnectionInvitesRequest, INeighborConnectionBatchJobRequest } from 'model/neighborConnections';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

export const modalStoreKey = 'SendInvitesModal';
const loadingKey = 'SendInvitesModalLoader';

export interface ISendInvitesModal extends IModal {
  connectionIds: string[];
}

export function createInitialSendInvitesModal(connectionIds) :ISendInvitesModal {
  return {
    open: false,
    connectionIds: connectionIds,
  };
}

export function createEmptySendInvitesModal() :ISendInvitesModal {
  return {
    open: false,
    connectionIds: [],
  };
}

export default function SendInvitesModal() {
  const { closeModal, getModalState } = useModal(modalStoreKey);
  const state = getModalState() as ISendInvitesModal;
  const { connectionIds = [] } = state;
  const { createSuccessToast, createErrorToast } = useToast();
  const { onLoading, doneLoading } = useLoading(loadingKey);
  const [requesting, setRequesting] = useState(false);

  const formContext = useForm({
    mode: 'onTouched',
    defaultValues: {
      testEmail: '',
      dryRun: false,
    },
  });

  function resetForm() {
    formContext.reset({
      testEmail: '',
      dryRun: false,
    });
  }

  const onSubmit = async (data:any) => {
    setRequesting(true);
    onLoading();
    try {
      const formData = formContext.getValues();
      const request = {
        connectionIds: connectionIds,
        dryRun: formData.dryRun,
      } as IBatchSendNeighborConnectionInvitesRequest;

      if (!!formData.testEmail) {
        request.testEmail = formData.testEmail;
      }

      var resp = await sendInvitesToNeighborConnections(request);
      var count = resp.data.processedCount;

      createSuccessToast('Successfully sent ' + count + ' invites');
    } catch (error:any) {
      console.error(error);
      createErrorToast('Error sending invites: ' + error.response?.data?.message);
    }

    doneLoading();
    setRequesting(false);
    resetForm();
    closeModal(createEmptySendInvitesModal());
  };

  return (
    <GenericModal
      modalStoreKey={modalStoreKey}
      title={'Send Email Invites to Users'}
      modalContent={
        (
          <>
            <Loading loadingKey={loadingKey}>
              <Grid container justifyContent={'flex-start'}>
                <FormProvider {...formContext}>
                  <form onSubmit={formContext.handleSubmit(onSubmit)}>
                    <Grid container item justifyContent={'flex-start'} flexDirection={'column'}>
                      <Grid item xs={12}>
                        <Typography variant='body1'>{`Would you like to send email invites to ${connectionIds.length} people?`}</Typography>
                      </Grid>
                      <Grid item paddingTop={3}>
                        <HookTextField
                          name='testEmail'
                          label='Test Email Override'
                          required={false}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <HookCheckbox
                          name='dryRun'
                          label="Test run? (Will send one email to yourself)"
                          required={false}
                        />
                      </Grid>
                    </Grid>
                  </form>
                </FormProvider>
              </Grid>
            </Loading>
          </>
        )
      }
      onConfirm={onSubmit}
      onSecondaryAction={() => {
        resetForm();
        closeModal(createEmptySendInvitesModal());
      }}
      primaryButtonText='Send'
      primaryDisabled={requesting}
      secondaryButtonText='Cancel'
    />
  );
}