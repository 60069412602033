import Axios, { AxiosResponse } from 'axios';
import { IServiceOffering, IServiceOfferingFormDTO, IServiceOfferingStatusCheckDTO, IServiceOfferingWithProviderCoverageArea } from 'model/serviceOffering';

const apiUrl = window.REACT_APP_BASE_API_URI;
export function findServiceOfferingsByProvider(providerId:string):Promise<AxiosResponse<IServiceOffering[]>> {
  return Axios.get(`${apiUrl}/serviceOffering/v2/public/findByProvider/${providerId}`);
}

export function findServiceOfferingsByProviderCoverageArea(providerCoverageAreaId:string):Promise<AxiosResponse<IServiceOfferingWithProviderCoverageArea[]>> {
  return Axios.get(`${apiUrl}/serviceOffering/v2/findByProviderCoverageArea/${providerCoverageAreaId}`);
}

export function findServiceOfferingById(id:string) {
  return Axios.get(`${apiUrl}/serviceOffering/v2/public/find/${id}`);
}

export function deleteServiceOffering(id:string) {
  return Axios.delete(`${apiUrl}/serviceOffering/v2/delete/${id}`);
}

export function getServiceOfferingStatusCheck(serviceProviderId:string):Promise<AxiosResponse<IServiceOfferingStatusCheckDTO>> {
  return Axios.get(`${apiUrl}/serviceOffering/v2/serviceProviders/${serviceProviderId}/serviceOfferingStatusCheck`);
}

export function saveServiceOffering(data:FormData, id:string | null) {
  if (id) {
    return Axios.post(`${apiUrl}/serviceOffering/v2/update/${id}`, data, {
      headers: {
        'Content-Type': 'undefined',
      },
    });
  }
  return Axios.post(`${apiUrl}/serviceOffering/v2/create`, data, {
    headers: {
      'Content-Type': 'undefined',
    },
  });
}