import { Grid } from '@mui/material';
import { PrimaryButton } from 'components/buttons';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import { IGenericCTABannerDTO } from './useNeighborhoodBannerLDConfig';
import WidgetConfigDTO from './WidgetConfigDTO';
import StickyFormButtons from './StickyFormButtons';
import { HookTextField } from 'components/reactHookForm';
import CompoundTitle from './CompoundTitle';

export default function GenericCTABannerDTOForm() {

  const formContext = useForm<IGenericCTABannerDTO>({
    mode: 'onTouched',
    defaultValues: {
      compoundTitle: [],
    },
  });


  return (
    <FormProvider {...formContext}>
      <form>
        <Grid container spacing={1} sx={{
          '&&&': {
            position: 'relative',
          },
        }}>

          <StickyFormButtons initialFormData={{}}/>

          <Grid item xs={4}>
            <WidgetConfigDTO fieldName="topAlert" />
          </Grid>
          <Grid item xs={4}>
            <WidgetConfigDTO fieldName="title" />
          </Grid>

          <Grid item xs={4}>
            <WidgetConfigDTO fieldName="text" />
          </Grid>
          <Grid item xs={4}>
            <WidgetConfigDTO fieldName="ctaButton" />
          </Grid>

          <Grid item xs={4}>
            <HookTextField name="assetImagePath" label="Asset Image Path" />
            <HookTextField name="backgroundHexColor" label="Background hex color" />
          </Grid>
          <Grid item xs={12}>
            <CompoundTitle/>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
}