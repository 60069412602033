
import get from 'lodash.get';

export function removeDupes<T>(array:T[], propertyPath:string):T[] {
  let map = new Map<string, T>();
  for (let i = 0; i < array.length; i++) {
    let current = array[i];
    let currentProperty = get(current, propertyPath, null);
    if (current !== null && !map.has(currentProperty)) {
      map.set(currentProperty, current);
    }
  }
  return Array.from(map.values());
}

export function findFirstMatch<Type>(items:Type[], propertyPath:string, id:string | null):Type | null {
  let matchingItems = items.filter(x => get(x, propertyPath, '') === id);
  if (matchingItems.length > 0) {
    return matchingItems[0];
  }
  return null;
}