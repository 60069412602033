import { Button, Grid } from '@mui/material';
import Spacer from 'components/Layout/Spacer';
import { ConfirmationModalContext } from 'components/modals/ConfirmationModal';
import PageHeader from 'components/SectionHeaders/PageHeader';
import { INeighborhood } from 'model/neighborhood';
import { useContext } from 'react';
import styles from './neighborhoodDetail.module.css';

export interface IReconcileNsosByNeighborhoodFormProps {
  neighborhood: INeighborhood | null;
}

export default function ReconcileNsosByNeighborhoodForm({ neighborhood }:IReconcileNsosByNeighborhoodFormProps) {
  const confirmationModalCtx = useContext(ConfirmationModalContext);

  return (
    <Grid container item xs={12} spacing={1} className='pageGridContainer'>
      <Grid container item xs={12}>
        <PageHeader headerText={'NSO Reconciliation'}/>
        <h4>This will use current provider coverage areas and the current geometry (from the DB) to create/soft delete NSOs.</h4>
      </Grid>
      <Spacer/>
      <Grid item xs={'auto'}>

        <Button
          variant="contained"
          className={styles.saveButton}
          type="button"
          disabled={neighborhood == null}
          onClick={() => {
            confirmationModalCtx.openModal({ dryRun: true });
          }}
        >
          <span>Reconcile NSOs (Dry Run)</span>
        </Button>

      </Grid>
      <Grid item xs='auto'>
        <Button
          variant="contained"
          className={styles.saveButton}
          type="button"
          disabled={neighborhood == null}
          onClick={() => {
            confirmationModalCtx.openModal({ dryRun: false });
          }}
        >
          <span>Reconcile NSOs</span>
        </Button>
      </Grid>
      <Spacer/>
    </Grid>
  );
}