import { Autocomplete, TextField } from '@mui/material';
import useMarkets from 'hooks/useMarkets';
import useTerritories from 'hooks/useTerritories';
import { IDropdownOption } from 'model/dropdown';
import { IMarket } from 'model/markets';
import { ITerritory } from 'model/territories';


export interface ITerritoryMultiAutocompleteProps {
  marketId:string;
  selectedOptions:IDropdownOption[];
  onChange: (selectedOptions:IDropdownOption[]) => void;
}

function convertToDropdownOption(selectedTerritory:ITerritory):IDropdownOption {
  return {
    key: selectedTerritory.id,
    optionText: selectedTerritory.name,
    optionValue: selectedTerritory.id,
    ancillary: selectedTerritory,
  };
}

export default function TerritoryMultiAutocomplete({
  marketId,
  selectedOptions,
  onChange,
}:ITerritoryMultiAutocompleteProps) {

  const {
    territoryDropdownOptions,
    preOnChangeOverride,
  } = useTerritories(marketId);

  return (
    <Autocomplete
      multiple
      size='small'

      sx={{
        '&': {
          minWidth: '300px',
          maxWidth: '400px',
          padding: '1px',
        },
      }}
      id={'territory-multi-autocomplete'}
      onChange={(e, value:any[], reason, details) => {
        if (reason === 'selectOption') {
          onChange(value);
        } else if (reason === 'createOption') {

        } else if (reason === 'removeOption') {
          onChange(value);

        } else if (reason === 'blur') {

        } else if (reason === 'clear') {
          onChange([]);
        }
      }}
      isOptionEqualToValue={(option, value: IDropdownOption) => {
        return option.optionValue === value.optionValue;
      }}
      value={selectedOptions}
      options={territoryDropdownOptions}
      getOptionLabel={(option) => {
        return option?.optionText ?? '';
      }}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          {option.optionText}
        </li>
      )}

      fullWidth
      renderInput={(params) => (
        <TextField
          {...params}
          label={'Territory'}
          placeholder={'Select a territory'}
          sx={{
            backgroundColor: 'white',
          }}
        />
      )}
    />
  );
}