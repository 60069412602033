import { useLoading } from 'components/Layout/Loading';
import createStore from 'hooks/hookStore';
import { useEffect, useState } from 'react';
import { IMarket } from 'model/markets';
import { IDropdownOption } from 'model/dropdown';
import useToast from 'components/toast/useToast';
import { findServiceOfferingsByProvider } from 'api/serviceOfferingApi';
import { IServiceOffering } from 'model/serviceOffering';
import { AddProviderToTemplateModel } from 'model/seeding/addProviderToTemplates';
import { addProviderToSeedingTemplates } from 'api/territorySeedingTemplatesApi';

export const loadingKey = 'AddProviderToTemplatesStore';
type AddProviderToTemplatesStore = {
  formData:AddProviderToTemplateModel.IAddProviderToSeedingTemplatesRequestForm;
  availableServiceOfferings: any[];
  selectedTerritories: IDropdownOption[];
}

const { get, update, registerListener, unregisterListener } = createStore<AddProviderToTemplatesStore>('AddProviderToTemplatesStore', {
  formData: AddProviderToTemplateModel.createEmptyAddProviderToSeedingTemplatesRequestForm(),
  availableServiceOfferings: [],
  selectedTerritories: [],
});

export default function useAddProviderToTemplates() {
  const setState = useState(get())[1];
  const { onLoading, doneLoading } = useLoading(loadingKey);
  const { createErrorToast, createSuccessToast } = useToast();

  useEffect(() => {
    registerListener(setState);
    return () => {
      update({
        ...get(),
        formData: AddProviderToTemplateModel.createEmptyAddProviderToSeedingTemplatesRequestForm(),
        availableServiceOfferings: [],
        selectedTerritories: [],
      });
      unregisterListener(setState);
    };
  }, []);


  async function init(market:IMarket | null) {
    if (market == null) {
      console.log('waiting to load market');
      return;
    }
    onLoading();
    try {
      update({
        ...get(),

      });
    } catch (e:any) {
      console.error(e);
    }
    doneLoading(300);
  }

  async function onProviderSelect(providerSelection: IDropdownOption) {
    const { optionValue: id } = providerSelection;
    onLoading();
    const serviceOfferingMap = {};
    (await findServiceOfferingsByProvider(id)).data.forEach((m: IServiceOffering) => {
      const mapValue = {
        key: m.id,
        id: m.id,
        label: m.name,
        checked: false,
        seedFactor: null,
        ancillary: m,
      };
      if (m.id) {
        serviceOfferingMap[m.id] = mapValue;
      }
    });

    const serviceOfferings = Object.values(serviceOfferingMap);
    update({
      ...get(),
      availableServiceOfferings: serviceOfferings,
    });
    doneLoading(300);
  }

  function onSelectTerritory(territorySelection: IDropdownOption[]) {
    update({
      ...get(),
      selectedTerritories: [...territorySelection],
    });
  }

  async function onSave(formData: AddProviderToTemplateModel.IAddProviderToSeedingTemplatesRequestForm) {
    onLoading();
    try {
      const { selectedTerritories } = get();
      var dto = AddProviderToTemplateModel.convertFormToDto(formData);
      dto.zipCodeTerritories = selectedTerritories.map((territory) => territory.ancillary.zipCodeTerritory);
      var hasSelectedServiceOfferings = dto.serviceOfferingsWithSeedFactor.length > 0;
      if (dto.marketId == null) {
        createErrorToast('Please select a market');
        return;
      }
      if (dto.serviceProviderId == '' || dto.serviceProviderId == null) {
        createErrorToast('Please select a provider');
        return;
      }
      if (!hasSelectedServiceOfferings) {
        createErrorToast('Please select at least one service offering');
        return;
      }
      if (dto.zipCodeTerritories.length === 0) {
        createErrorToast('Please select at least one territory');
        return;
      }
      await addProviderToSeedingTemplates(dto);
      createSuccessToast('Successfully added provider to seeding templates');
    } catch (e) {
      console.error(e);
      createErrorToast('Failed to add provider to seeding templates');
    } finally {
      doneLoading();
    }

  }


  return {
    ...get(),
    loadingKey,
    init,
    onProviderSelect,
    onSelectTerritory,
    onSave,
  };
}