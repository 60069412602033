import { Grid, Typography, TextField, Button } from '@mui/material';
import { INeighborhoodFromAddressResponse, INeighborhoodFromPlaceResponse } from 'model/ancillary';
import useHome from './hookStore/useHome';

function foundNeighborhood(data:INeighborhoodFromAddressResponse | null) {
  if (data === null) {
    return false;
  }
  return data.neighborhoodFromPlaceResponse?.neighborhood && !data.message;
}

function foundPredictions(data:INeighborhoodFromAddressResponse | null) {
  if (data === null) {
    return false;
  }
  return data.message && data.predictions?.length > 0;
}

function foundMultipleNeighborhoods(data:INeighborhoodFromAddressResponse | null) {
  if (data === null) {
    return false;
  }
  return data.message && data.neighborhoodFromPlaceResponse?.possibleMatchingNeighborhoods.length > 0;
}

export default function DemoNeighborhoodFromAddress() {
  const {
    loadingKey,
    address,
    city,
    state,
    zipCode,
    neighborhoodFromAddressResponse,
    onNeighborhoodFromAddressChange,
    onFindBestMatchNeighborhoodByAddress,
  } = useHome();
  const _foundNeighborhood = foundNeighborhood(neighborhoodFromAddressResponse);
  const _foundPredictions = foundPredictions(neighborhoodFromAddressResponse);
  const _foundMultipleNeighborhoods = foundMultipleNeighborhoods(neighborhoodFromAddressResponse);


  return (
    <Grid container item spacing={1} xs={6} sx={{ margin: 1, border: '1px solid gainsboro' }} >
      <Grid item xs={12} >
        <Typography variant='h6'>Neighborhood lookup via address</Typography>
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          variant='standard'
          name='address'
          label='address'
          value={address}
          onChange={(e) => onNeighborhoodFromAddressChange('address', e.target.value)}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          fullWidth
          variant='standard'
          name='city'
          label='city'
          value={city}
          onChange={(e) => onNeighborhoodFromAddressChange('city', e.target.value)}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          fullWidth
          variant='standard'
          name='state'
          label='state'
          value={state}
          onChange={(e) => onNeighborhoodFromAddressChange('state', e.target.value)}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          fullWidth
          variant='standard'
          name='zipCode'
          label='zipCode'
          value={zipCode}
          onChange={(e) => onNeighborhoodFromAddressChange('zipCode', e.target.value)}
        />
      </Grid>
      <Grid item xs={3}></Grid>
      <Grid item xs='auto'>
        <Button
          variant='contained'
          type='button'
          onClick={() => onFindBestMatchNeighborhoodByAddress({ address, state, city, zipCode })}
        >
          <>Submit</>
        </Button>
      </Grid>
      <Grid item xs={12}>
        {!_foundNeighborhood && !_foundPredictions && !_foundMultipleNeighborhoods && (
          <Typography variant='subtitle1'>Result: {'No Neighborhood found'}</Typography>
        )}
        {_foundNeighborhood && (
          <Typography variant='subtitle1'>Result: {neighborhoodFromAddressResponse?.neighborhoodFromPlaceResponse?.neighborhood?.name ?? 'No Result'}</Typography>
        )}
        {_foundPredictions && (
          <>
            <Typography variant='h6'>Could not find neighborhood due to multiple address possibilities.</Typography>
            <Typography variant='subtitle1'>Possible addresses: </Typography>
            <ul>
              {neighborhoodFromAddressResponse?.predictions.map((x, i) => (
                <li key={i}>{x.description}</li>
              ))}
            </ul>
          </>
        )}
        {_foundMultipleNeighborhoods && (
          <>
            <Typography variant='h6'>Could not find neighborhood due to multiple address possibilities.</Typography>
            <Typography variant='subtitle1'>Possible addresses: </Typography>
            <ul>
              {neighborhoodFromAddressResponse?.neighborhoodFromPlaceResponse.possibleMatchingNeighborhoods.map((x, i) => (
                <li key={i}>{x.name}</li>
              ))}
            </ul>
          </>
        )}
      </Grid>
    </Grid>
  );
}