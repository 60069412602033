import Axios from 'axios';
import { IAuditCustomerUploadRequestDto, ICustomerUploadDeleteByIdForm, ICustomerUploadDeleteFormV2, ICustomerUploadForm, ICustomerUploadFormV2, ICustomerUploadRequestDto, IReconcileCustomerUploadRequestDto, IRollbackUploadedCustomersRequestDto } from 'model/customerUpload';

const apiUrl = window.REACT_APP_BASE_API_URI;

export function uploadCustomers(data:ICustomerUploadForm) {
  var formData = new FormData();
  formData.append('customersTemplate', data.customersTemplate);
  formData.set('shouldSetProviderUploaded', Boolean(data.shouldSetProviderUploaded).toString());
  return Axios.post(`${apiUrl}/customer/v2/uploadCustomers`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function stageCustomersV2(serviceProviderId:string, data:ICustomerUploadFormV2) {
  var formData = new FormData();
  formData.append('customersTemplate', data.customersTemplate);
  const formDto = {
    shouldSetProviderUploaded: data.shouldSetProviderUploaded,
    serviceOfferingAllocations: data.serviceOfferingAllocations,
  };
  formData.append('dto', new Blob([JSON.stringify(formDto)], {
    type: 'application/json',
  }));

  return Axios.post(`${apiUrl}/customer/v2/stageCustomersV2/${serviceProviderId}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function processStagedCustomers(dto:ICustomerUploadRequestDto ) {
  return Axios.post(`${apiUrl}/customer/v2/processStagedCustomers`, dto);
}

export function deleteCustomerUpload(requestId:string) {
  //this endpoint will delete the step 2 nsois, customers, subscribers, and individuals.
  //will not revert step 1 for reprocessing

  //this functionality is the same as the deleteCustomerUploadV2 if you pass a requestId
  return Axios.post(`${apiUrl}/customer/v2/deleteUploadCustomers`, { requestId });
}

export function deleteCustomerUploadV2(data:ICustomerUploadDeleteFormV2) {
  //if you pass a requestId
  //this endpoint will delete the step 2 nsois, customers, subscribers, and individuals.
  //will not revert step 1 for reprocessing

  //if you pass a processingId
  //uses dynamo to query for individuals by processingId. For each individual it will find related step 2 nsois, customers, and subscribers and delete them.
  //it will then delete the individual for the loop.
  //This will not revert step 1 for reprocessing
  return Axios.post(`${apiUrl}/customer/v2/deleteUploadCustomersV2`, data);
}

export function rollbackUploadedCustomers(data:IRollbackUploadedCustomersRequestDto) {
  //uses sql to query the public.staging_customer table for processed customers with matching neighborhood in the dto
  // for each customer it will find related step 2 entities using the individualId of the customer. This will find nsois, subscribers, and related individual and delete them.
  //it will then reset the step 1 customers that are in the PROCESSED status, in the given neighborhood, and are not already deleted via delete_date
  return Axios.post(`${apiUrl}/customer/v2/rollbackUploadedCustomers`, data);
}

export function auditCustomerUpload(data:IAuditCustomerUploadRequestDto) {
  return Axios.post(`${apiUrl}/customer/v2/auditCustomerUpload`, data);
}

export function reconcileCustomerUpload(data:IReconcileCustomerUploadRequestDto) {
  return Axios.post(`${apiUrl}/customer/v2/reconcileCustomerUpload`, data);
}

export function migrateCustomerPrice() {
  return Axios.get(`${apiUrl}/customer/v2/migrateCustomerPrice`);
}

export function migrateSubscriberPrices() {
  return Axios.get(`${apiUrl}/subscriber/v2/migrateSubscriberPrices`);
}

export function migratePlaceholderCustomers() {
  return Axios.get(`${apiUrl}/customer/v2/migratePlaceholderCustomers`);
}

export function migrateCustomerStatus() {
  return Axios.get(`${apiUrl}/customer/v2/migrateCustomerStatus`);
}

export function migrateProviderUploadedField() {
  return Axios.get(`${apiUrl}/customer/v2/migrateProviderUploadedField`);
}
