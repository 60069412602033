import Axios from 'axios';

const apiUrl = window.REACT_APP_BASE_API_URI;

export function findServiceProviderAccounts() {
  return Axios.get(`${apiUrl}/accounts/v2/serviceProviderAccounts`);
}

export function findIndividualAccounts() {
  return Axios.get(`${apiUrl}/accounts/v2/individualAccounts`);
}