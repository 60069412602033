import { Grid, Button } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import CopyToClipboardCell from 'components/DataGridCell/CopyToClipboard';
import PageHeader from 'components/SectionHeaders/PageHeader';
import QuickSearchToolbar from 'components/grid/QuickSearchToolbar';
import useTerritories from './hookStore/useTerritories';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useTableSearch from 'components/grid/useTableSearch';
import { sortByString } from 'util/sortFunctions';
import useMarkets from 'hooks/useMarkets';
import { EditButton, RemoveButton } from 'components/buttons';
import styles from './territories.module.css';
import useModal from 'components/modal/useModal';
import DeleteTerritoryModal, { IDeleteTerritoryModal, modalStoreKey } from './confirmDelete/DeleteTerritoryModal';

const sortByName = sortByString('name');
const quickSearchStorageKey = 'territories';

function DeleteCell({ id: territoryId, name: territoryName }) {
  const { openModal } = useModal(modalStoreKey);
  const state:IDeleteTerritoryModal = {
    id: territoryId,
    name: territoryName,
  };
  return (
    <RemoveButton
      disabled
      tooltipTitle='Remove territory'
      onClick={() => openModal({ ...state })}
      tooltipProps={{
        placement: 'top',
      }}
      iconButtonProps={{
        className: styles.actionButton,
      }}
    />
  );
}

function EditCell({ id }) {
  const navigate = useNavigate();
  function handleClick() {
    navigate(`/territories/${id}`);
  }
  return (
    <EditButton
      tooltipTitle={`Edit ${name}`}
      onClick={handleClick}
      tooltipProps={{
        placement: 'top',
      }}
      iconButtonProps={{
        className: styles.actionButton,
      }}
    />
  );
}

export default function Territories() {
  const navigate = useNavigate();
  const { marketId } = useParams();
  const {
    territories,
    init,
    onConfirmDelete,
  } = useTerritories();
  const { market } = useMarkets();
  const { rows, searchText, setRowsWithStoredFilter, requestSearch, clearStoredSearch } = useTableSearch(quickSearchStorageKey);
  const [sortModel, setSortModel] = useState([
    {
      field: 'name',
      sort: 'asc' as any,
    },
  ]);

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      width: 300,
      renderCell: (params: any) => {
        return <div>{params.row.name}</div>;
      },
    },
    {
      field: 'newsletterCadence',
      headerName: 'Newsletter Cadence',
      width: 150,
      renderCell: (params: any) => {
        return <div>{params.row.newsletterCadence}</div>;
      },
    },
    {
      field: 'zipCodeTerritory',
      headerName: 'Zip Code Territory',
      width: 200,
      renderCell: (params: any) => {
        return <div>{params.row.zipCodeTerritory}</div>;
      },
    },
    {
      field: 'id',
      headerName: 'GUID',
      width: 400,
      renderCell: (params: any) => {
        return (<CopyToClipboardCell val={params.id}/>);
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 200,
      renderCell: (params:any) => {
        return (
          <Grid container item xs={12} justifyContent='center' alignItems='center'>
            <EditCell id={params.id}/>
            <DeleteCell id={params.id} name={params.row.name}/>
          </Grid>
        );
      },
    },
  ];
  const columnTotalWidth = columns.map(x => x.width).reduce((a:any, b:any) => a+b, 0);

  function addNewButtonClick(e: any) {

    navigate('/territories/new');
  }

  useEffect(() => {
    console.log('detected market change to: ', market?.name);
    void init(market, setRowsWithStoredFilter);
  }, [market]);

  return (
    <Grid container spacing={2} className='pageGridContainer'>
      <Grid item xs={12}>
        <PageHeader headerText={'Territories'} />
      </Grid>
      <>
        <Grid item xs={12}>
          <Button variant="contained" onClick={addNewButtonClick}>Add New</Button>
        </Grid>
        <Grid item xs={12}>
          <DataGrid
            rows={rows}
            components={{ Toolbar: QuickSearchToolbar }}
            componentsProps={{
              toolbar: {
                quickSearchStorageKey,
                value: searchText,
                onChange: (event: React.ChangeEvent<HTMLInputElement>) => requestSearch(territories, event.target.value),
                clearSearch: () => requestSearch(territories, ''),
              },
            }}
            columns={columns}
            autoHeight={true}
            sortModel={sortModel}
            onSortModelChange={setSortModel}
            style={{
              minWidth: '500px',
              maxWidth: `${columnTotalWidth}px`,
            }}
          />
        </Grid>
      </>
      <DeleteTerritoryModal onConfirm={onConfirmDelete}/>
    </Grid>


  );
}