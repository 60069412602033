import { Grid, Typography } from '@mui/material';
import { toggleNeighborhoodServiceOfferingsByServiceOffering, toggleNeighborhoodServiceOfferingsByServiceProvider } from 'api/neighborhoodServiceOfferingApi';
import GenericModal from 'components/modal/GenericModal';
import useModal, { IModal } from 'components/modal/useModal';

export const modalStoreKey = 'disableNsoByServiceOfferingModal';
export interface IDisableNsoByServiceOfferingModal {
  name: string;
  id:string;
  wasEnabled:boolean;
}
export function createEmptyDisableNsoByServiceOfferingModal() :IDisableNsoByServiceOfferingModal {
  return {
    name: '',
    id: '',
    wasEnabled: false,
  };
}
export interface IDisableNsoByServiceOfferingModalProps {
  postOnConfirm: (serviceOfferingId:string, wasEnabled:boolean) => void;
}

export default function DisableNsoByServiceOfferingModal({ postOnConfirm }:IDisableNsoByServiceOfferingModalProps) {
  const { getModalState, closeModal } = useModal(modalStoreKey);
  const _state = getModalState() as IDisableNsoByServiceOfferingModal & IModal;
  const { name, wasEnabled } = _state;
  async function _onDisableNsosByServiceOffering(state:IDisableNsoByServiceOfferingModal & IModal) {
    if (state.wasEnabled) {
      await toggleNeighborhoodServiceOfferingsByServiceOffering(state.id, { shouldEnable: false });
    } else {
      await toggleNeighborhoodServiceOfferingsByServiceOffering(state.id, { shouldEnable: true });
    }
    postOnConfirm(state.id, !state.wasEnabled);
    closeModal(createEmptyDisableNsoByServiceOfferingModal());
  }

  return (
    <GenericModal
      modalStoreKey={modalStoreKey}
      title={wasEnabled ? 'Disable all NSOs for service offering?' : 'Enable all NSOs for service offering?'}
      modalContent={
        (
          <div >
            <Typography variant='body1' >Do you want to {wasEnabled ? 'disable' : 'enable'} all NSOs for {name}?</Typography>
          </div>
        )
      }
      onConfirm={() => _onDisableNsosByServiceOffering(_state)}
      secondaryButtonText='Cancel'
    />
  );
}