/* eslint-disable @typescript-eslint/no-shadow */
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import DeleteIcon from '@mui/icons-material/Delete';
import { Autocomplete, AutocompleteProps, Button, Checkbox, TextField } from '@mui/material';
import { IDropdownOption } from 'model/dropdown';
import { Controller, useFormContext } from 'react-hook-form';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export interface IHookAutocompleteProps {
  name: string;
  dropdownOptions:IDropdownOption[];
  label:string;
  placeholder:string;
  rules?:any;
  postOnChange?:Function;
  postOnBlur?:Function;
  extraProps?:any;
}

export default function HookAutocomplete({
  name,
  label,
  placeholder,
  rules,
  dropdownOptions,
  postOnChange,
  postOnBlur,
  extraProps,
}:IHookAutocompleteProps) {
  const formContext = useFormContext();
  const { control } = formContext;
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { invalid, isTouched, isDirty, error },
        formState,
      }) => {
        return (
          <Autocomplete
            multiple
            id={`${name}-multiautocomplete`}
            onChange={(e, value:any, reason, details) => {
              if (reason === 'selectOption') {
                let nextValues = value.map(x => x.optionValue ?? x);
                onChange(nextValues);
                if (postOnChange) {
                  postOnChange(formContext, nextValues, reason, details);
                }
              } else if (reason === 'createOption') {

              } else if (reason === 'removeOption') {
                let nextValues = value.filter(x => x !== x.optionValue).map(x => x.optionValue ?? x);
                onChange(nextValues);
                if (postOnChange) {
                  postOnChange(formContext, nextValues, reason, details);
                }
              } else if (reason === 'blur') {

              } else if (reason === 'clear') {
                onChange([]);
                if (postOnChange) {
                  postOnChange(formContext, [], reason, details);
                }
              }

            }}
            isOptionEqualToValue={(option, value) => {
              return option.optionValue === value;
            }}
            ref={ref}
            value={value}
            options={dropdownOptions}
            disableCloseOnSelect
            getOptionLabel={(option) => {
              return option?.optionText ?? '';
            }}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.optionText}
              </li>
            )}
            renderTags={(value, getTagProps) => {
              return dropdownOptions.filter(x => {
                return value.indexOf(x.optionValue) > -1;
              }).map(x => (
                <Button
                  key={x.key}
                  variant='outlined'
                  startIcon={<DeleteIcon/>}
                  type='button'
                  size='small'
                  onClick={(e) => {
                    let nextValues = value.filter(y => y !== x.optionValue).map(z => z.optionValue ?? z);
                    onChange(nextValues);
                    if (postOnChange) {
                      postOnChange(formContext, nextValues, 'removeOption', x);
                    }
                  }}
                >
                  <span>{x.optionText}</span>
                </Button>
              ));
            }}
            fullWidth
            renderInput={(params) => (
              <TextField {...params} label={label} placeholder={placeholder} />
            )}
            {...extraProps}
          />
        );
      }
      }
    />
  );
}