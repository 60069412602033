import { Grid } from '@mui/material';
import { useEffect } from 'react';
import useMarkets from 'hooks/useMarkets';
import PageHeader from 'components/SectionHeaders/PageHeader';
import useAddProviderToTemplates, { loadingKey } from './hookStore/useAddProviderToTemplates';
import AddProviderButton from 'pages/territorySeedingTemplates/_components/AddProviderButton';
import AddProviderToTemplatesForm from './AddProviderToTemplatesForm';
import Loading from 'components/Layout/Loading';


export default function AddProviderToTemplates() {
  const { market } = useMarkets();
  const { init } = useAddProviderToTemplates();

  useEffect(() => {
    void init(market);
  }, [market]);
  return (
    <Loading loadingKey={loadingKey} size={100}>
      <Grid container sx={{ flexDirection: 'column', paddingLeft: '10px', paddingRight: '10px' }}>
        <Grid item xs={12}>
          <PageHeader headerText={'Add Provider to Templates'} />
        </Grid>
        <Grid
          container item
          xs={10}
          md={6}
          flexDirection='row'
          justifyContent='space-between'
          alignItems={'center'}
          sx={{ paddingLeft: '10px', paddingRight: '10px', paddingTop: '20px' }}>
        </Grid>
        <Grid item xs>
          <AddProviderToTemplatesForm/>
        </Grid>
      </Grid>
    </Loading>);
}

