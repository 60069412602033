import NeighborhoodAutocomplete from 'components/neighborhoodAutocomplete/NeighborhoodAutocomplete';
import useSeedFromTemplate from '../_hookStore/useSeedFromTemplate';
import { Grid } from '@mui/material';
import { PrimaryButton } from 'components/buttons';
import SeedNeighborhoodConfirmationModal, { SeedNeighborhoodconfirmationModalKey } from './SeedNeighborhoodConfirmationModal';
import useConfirmationModal from 'components/modals/useConfirmationModal';
import { getNeighborhoodZipCodeStatusString } from 'util/neighborhoodUtil';

export default function SeedNeighborhoodFromTemplate() {
  const { onNeighborhoodSelect } = useSeedFromTemplate();
  const { openModal } = useConfirmationModal(SeedNeighborhoodconfirmationModalKey);
  return (
    <>
      <Grid container flexDirection={'row'} alignItems={'flex-start'} columnSpacing={3}>
        <Grid item xs={4}>
          <NeighborhoodAutocomplete
            label={'Neighborhood'}
            formless={true}
            postOnChange={(_formCtx, value) => {
              void onNeighborhoodSelect(value);
            }}
            fieldName='neighborhood'
            placeholder={''}
            required={true}
            optionDescriptionFunc={getNeighborhoodZipCodeStatusString}
          />
        </Grid>
        <Grid item xs={2}>
          <PrimaryButton
            sx={{ height: '55px' }}
            onClick={() => {
              openModal({});
            }}
          >
            Seed
          </PrimaryButton>
        </Grid>
      </Grid>
      <SeedNeighborhoodConfirmationModal />
    </>
  );
}