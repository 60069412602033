import { Checkbox, MenuItem, Select } from '@mui/material';
import get from 'lodash/get';
import { Control, Controller, RegisterOptions } from 'react-hook-form';

interface IMenuItemKeys {
  key: string;
  value: string;
  displayValue:string;
}

interface IHookMultiselectProps {
  control:Control<any>;
  rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' >;
  id:string;
  name:string;
  menuItems:any[];
  menuItemKeys?:IMenuItemKeys | null;
}

export default function HookMultiselect({ control, id, name, menuItems, menuItemKeys, rules }:IHookMultiselectProps) {

  const menuItemKey = menuItemKeys?.key || '';
  const menuItemValue = menuItemKeys?.value || '';
  const menuItemDisplayValue = menuItemKeys?.displayValue || '';
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={[]}
      rules={rules}
      render={({ field: { onBlur, onChange, ref, value } })=>(
        <Select
          inputProps={{
            inputRef: ref,
            onBlur,
          }}
          onChange={onChange}
          value={[...value]}
          id={id}
          fullWidth
          multiple
          renderValue={(selected) => {
            const selectedItems = menuItems.map(x => {
              if (selected.indexOf(get(x, menuItemValue, x.id)) > -1) {
                return get(x, menuItemDisplayValue, x.name);
              }
            });
            return selectedItems.filter(x=> x !== undefined && x !== null).join(', ');
          }}
        >
          {
            menuItems && menuItems.map((x: any, i:number) => {
              return (
                <MenuItem
                  key={get(x, menuItemKey, i)}
                  value={get(x, menuItemValue, x.id)}>
                  <Checkbox checked={value.indexOf(get(x, menuItemValue, x.id)) > -1}/>
                  <span>{get(x, menuItemDisplayValue, x.name)}</span>
                </MenuItem>
              );
            })
          }
        </Select>
      )}/>
  );
}