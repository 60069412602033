import createStore from 'hooks/hookStore';
import useToast from 'components/toast/useToast';
import { IDropdownOption } from 'model/dropdown';
import { IInternalNeighborhood } from 'model/neighborhood';
import { getNeighborhoodById } from 'api/neighborhoodApi';
import { findAllPopularityByNeighborhood } from 'api/popularityApi';
import { useLoading } from 'components/Layout/Loading';
import { useState, useEffect } from 'react';
import { IPopularity } from 'model/popularity';

const loadingKey = 'NeighborhoodSeedingToolingStore';
type NeighborhoodSeedingToolingStore = {
  neighborhoodOptions: IDropdownOption[];
  selectedNeighborhood: IInternalNeighborhood;
  onNeighborhoodSelect: Function;
  tableRows: any[];
}

const { get, update, registerListener, unregisterListener } = createStore<NeighborhoodSeedingToolingStore>('NeighborhoodSeedingToolingStore', {
  neighborhoodOptions: [],
  selectedNeighborhood: {} as IInternalNeighborhood,
  onNeighborhoodSelect: () => {},
  tableRows: [],
});


function convertPopularityDataForGrid(data:IPopularity[]) {
  return data.map((item) => ({
    ...item,
    // row items need to have a unique ID attribute
    id: item.providerId + item.serviceType + item.category,
  }));
}

export default function useNeighborhoodSeedingToolingStore() {
  const setState = useState(get())[1];
  const { onLoading, doneLoading } = useLoading(loadingKey);
  const { createErrorToast } = useToast();

  useEffect(() => {
    registerListener(setState);
    return () => {
      unregisterListener(setState);
    };
  }, []);

  async function init() {
    onLoading();
    try {
    } catch (e:any) {
      console.error('error initializing NeighborhoodSeedingToolingStore', e);
    }
    doneLoading(300);
  }


  async function onNeighborhoodSelect(neighborhoodId:string) {
    onLoading();
    try {
      const neighborhoodResp = await getNeighborhoodById(neighborhoodId);
      const popularityScores = await findAllPopularityByNeighborhood(neighborhoodId)
        .then((resp) => convertPopularityDataForGrid(resp.data));

      update({
        ...get(),
        selectedNeighborhood: neighborhoodResp.data,
        tableRows: popularityScores,
      });
    } catch (error) {
      console.error('error retrieving neighborhood data', error);
      createErrorToast('Error retrieving neighborhood data');
    }
    doneLoading(300);
  }

  function refreshTable() {
    return update({
      ...get(),
    });
  };

  return {
    ...get(),
    loadingKey,
    init,
    onNeighborhoodSelect,
    refreshTable,
  };
}
