import Loading from 'components/Layout/Loading';
import useTsoiManagementBatches from '../hookStore/useTsoiManagementBatches';
import { useEffect, useState } from 'react';
import useMarkets from 'hooks/useMarkets';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import useTableSearch from 'components/grid/useTableSearch';
import QuickSearchToolbar from 'components/grid/QuickSearchToolbar';
import { Grid, IconButton } from '@mui/material';
import { Edit } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import Delete from '@mui/icons-material/Delete';

const getColumns = ({ deleteById, market }):GridColDef[] => ([
  {
    field: 'startDate',
    headerName: 'Week Start',
    width: 200,
    renderCell: (params: any) => {
      return <div>{params.value}</div>;
    },
  },
  {
    field: 'endDate',
    headerName: 'Week End',
    width: 200,
    renderCell: (params: any) => {
      return <div>{params.value}</div>;
    },
  },
  {
    field: 'status',
    headerName: 'Batch Status',
    width: 100,
    renderCell: (params: any) => {
      return <div>{params.value}</div>;
    },
  },
  {
    field: 'actions',
    headerName: 'Actions',
    width: 200,
    renderCell: (params:any) => {
      const navigate = useNavigate();
      return (
        <Grid container justifyContent='center' alignItems='center' flexDirection={'row'}>
          <Grid item>
            <IconButton onClick={() => navigate(`/tsoiManagementBatches/${params.row.id}/summary`)}>
              <Edit />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton onClick={() => deleteById(params.row.id, market)}>
              <Delete />
            </IconButton>
          </Grid>
        </Grid>
      );
    },
  },
]);

const quickSearchStorageKey = 'territorySeedingTemplatesTable';

export default function BatchesDataTable() {
  const {
    batches,
    deleteBatch,
    init,
    loadingKey,
    tableRows,
  } = useTsoiManagementBatches();
  const { market } = useMarkets();
  const { rows, searchText, setRowsWithStoredFilter, requestSearch } = useTableSearch(quickSearchStorageKey);
  const [sortModel, setSortModel] = useState([{
    field: 'startDate',
    sort: 'desc' as any,
  }]);
  const columns = getColumns({ deleteById: deleteBatch, market });
  const columnTotalWidth = columns.map(x => x.width).reduce((a:any, b:any) => a+b, 0);

  useEffect(() => {
    void init(market);
  }, [market]);

  useEffect(() => {
    setRowsWithStoredFilter(batches);
  }, [batches]);

  return (
    <Loading loadingKey={loadingKey}>
      <Grid container>
        <DataGrid
          rows={rows}
          components={{ Toolbar: QuickSearchToolbar }}
          componentsProps={{
            toolbar: {
              quickSearchStorageKey,
              value: searchText,
              onChange: (event: React.ChangeEvent<HTMLInputElement>) => requestSearch(tableRows, event.target.value),
              clearSearch: () => requestSearch(tableRows, ''),
            },
          }}
          columns={columns}
          autoHeight={true}
          sortModel={sortModel}
          onSortModelChange={setSortModel}
          style={{
            minWidth: '500px',
            maxWidth: `${columnTotalWidth}px`,
          }}
        />
      </Grid>
    </Loading>
  );
}
