import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

export interface INavigationButtonProps {
  route:string;
  text:string | JSX.Element;
}

export default function NavigationButton ({ route, text }:INavigationButtonProps) {

  return (
    <Link to={route} rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
      <Button
        data-route={route}
        size='small'
        style={{
          textAlign: 'left',
          color: 'white',
        }}
        sx={{
          'padding': '4px 8px',
          'margin': '2px 0',
          '&:hover': {
            transition: '0.5s',
            background: '#224E4E',
          },
        }}>{text}</Button>
    </Link>

  );
}