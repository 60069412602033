import { Close } from '@mui/icons-material';
import { Drawer, Grid, IconButton } from '@mui/material';
import useTooltipDrawer from './useTooltipDrawer';

export interface ITooltipDrawerProps {
  storeKey: string;
  children: React.ReactNode;
}

export default function TooltipDrawer({ storeKey, children }:ITooltipDrawerProps) {
  const { closeDrawer, open } = useTooltipDrawer(storeKey);
  return (
    <Drawer anchor='right' open={open}>
      <Grid container item >
        <Grid container item direction='row' justifyContent='flex-end'>
          <IconButton onClick={closeDrawer}>
            <Close />
          </IconButton>
        </Grid>
        <Grid item xs='auto'>
          {children}
        </Grid>
      </Grid>
    </Drawer>
  );
}