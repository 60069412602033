import get from 'lodash/get';

export function nullableIntSort(a:number | null, b:number | null) {
  if (a === null && b === null) {
    return 0;
  }
  if (a === null) {
    return 1;
  }
  if (b === null) {
    return -1;
  }
  return a - b;
}

export function sortByString(propertyPath:string, isDescending?:boolean) {
  if (isDescending) {
    return function(a:any, b:any) {
      var nameA = get(a, propertyPath, '').toUpperCase(); // ignore upper and lowercase
      var nameB = get(b, propertyPath, '').toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return 1;
      }
      if (nameA > nameB) {
        return -1;
      }

      // names must be equal
      return 0;
    };
  }
  return function(a:any, b:any) {
    var nameA = get(a, propertyPath, '').toUpperCase(); // ignore upper and lowercase
    var nameB = get(b, propertyPath, '').toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  };
}