import { IFormFriendlyServiceOffering } from 'model/serviceOffering';

export function createDefaultValues():IFormFriendlyServiceOffering {
  return {
    cadenceType: '',
    customFields: [],
    frequency: '',
    id: '',
    invitationDescription: '',
    name: '',
    providerId: '',
    serviceDetailSections: [],
    workflowSteps: [],
    serviceTypeId: '',
    serviceType: '',
    serviceCategory: '',
    subscriptionOptions: {
      label: '',
      options: [
        {
          optionName: '',
          optionValue: '',
          isDefaultOption: false,
          isOneTime: false,
          isNew: true,
        },
      ],
      subscriptionTermInMonths: 0,
    },
    cutoffDayCount: 3,
    defaultDiscountScheduleId: '',
    defaultForServiceType: false,
    territoryServiceOfferingEnabled: false,
    providerCoverageAreaId: '',
  };
}