import { Grid, Typography } from '@mui/material';
import { toggleNeighborhoodServiceOfferingsById } from 'api/neighborhoodServiceOfferingApi';
import GenericModal from 'components/modal/GenericModal';
import useModal, { IModal } from 'components/modal/useModal';

export const modalStoreKey = 'disableNsoByIdModal';
export interface IDisableNsoByIdModal {
  name: string;
  id:string;
  wasEnabled:boolean;
}
export function createEmptyDisableNsoByIdModal() :IDisableNsoByIdModal {
  return {
    name: '',
    id: '',
    wasEnabled: false,
  };
}
export interface IDisableNsoByIdModalProps {
  postOnConfirm: (neighborhoodServiceOfferingId:string, wasEnabled:boolean) => void;
}

export default function DisableNsoByIdModal({ postOnConfirm }:IDisableNsoByIdModalProps) {
  const { getModalState, closeModal } = useModal(modalStoreKey);
  const _state = getModalState() as IDisableNsoByIdModal & IModal;
  const { name, wasEnabled } = _state;
  async function _onDisableNsoById(state:IDisableNsoByIdModal & IModal) {
    if (state.wasEnabled) {
      await toggleNeighborhoodServiceOfferingsById(state.id, { shouldEnable: false });
    } else {
      await toggleNeighborhoodServiceOfferingsById(state.id, { shouldEnable: true });
    }
    postOnConfirm(state.id, !state.wasEnabled);
    closeModal(createEmptyDisableNsoByIdModal());
  }

  return (
    <GenericModal
      modalStoreKey={modalStoreKey}
      title={wasEnabled ? 'Disable NSO?' : 'Enable NSO?'}
      modalContent={
        (
          <div >
            <Typography variant='body1' >Do you want to {wasEnabled ? 'disable' : 'enable'} {name}?</Typography>
          </div>
        )
      }
      onConfirm={() => _onDisableNsoById(_state)}
      secondaryButtonText='Cancel'
    />
  );
}