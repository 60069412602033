import { IDropdownOption } from 'model/dropdown';
import { INeighborhood } from 'model/neighborhood';

export interface IOneTimeNewsletterRequest {
  territoryId:string | null;
  marketId:string;
  neighborhoodIds:string[];
  sendTest:boolean;
  testToEmail?:string;
  fromEmail?:string;
}

export interface INeighborhoodSelection {
  neighborhoodId:string;
  selected:boolean;
}

export interface IOneTimeNewsletterForm {
  territoryId:IDropdownOption | null;
  neighborhoods:INeighborhoodSelection[];
  overrideEmails?:string[];
  testToEmail?:string;
  fromEmail?:string;
  sendTest:boolean;
}

export function createEmptyOneTimeNewsletterFormDto():IOneTimeNewsletterForm {
  return {
    territoryId: null,
    neighborhoods: [],
    overrideEmails: [],
    sendTest: false,
    testToEmail: '',
    fromEmail: '',
  };
}