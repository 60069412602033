import { Box, Grid, Typography } from '@mui/material';
import { useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import useServiceCategoryDetailStore from '../hookStore/useServiceCategoryDetail';
import { PrimaryButton } from 'components/buttons';
import styles from './serviceCategoryTypeDetails.module.css';
import ServiceTypeImage from './ServiceTypeImage';
import { IServiceTypeInfoCardMetadatum } from 'model/serviceCategory';


export interface IDraggableServiceType {
  snapshot: any;
  serviceTypeInfoMetadatum: IServiceTypeInfoCardMetadatum;
  useSimple:boolean;
}

function DraggableServiceType(props:IDraggableServiceType) {
  const {
    snapshot,
    serviceTypeInfoMetadatum,
    useSimple,
  } = props;

  if (useSimple) {
    <Box
      className={`${styles.serviceTypeCard} ${snapshot.isDragging ? styles.serviceTypeCardDragging : styles.serviceTypeCard}`}
    >
      <div>{serviceTypeInfoMetadatum.sortOrder ?? 0}.</div>
      <div>{serviceTypeInfoMetadatum.serviceTypeName}</div>
    </Box>;
  }
  return (
    <ServiceTypeImage
      serviceTypeName={serviceTypeInfoMetadatum.serviceTypeName}
      imageGuid={serviceTypeInfoMetadatum.imageGuid}
      colorScheme={serviceTypeInfoMetadatum.colorScheme}
    />

  );
}

function DraggableItem({
  id,
  index,
  serviceTypeInfoMetadatum,
  useSimple,
}) {
  return (
    <Draggable draggableId={id} index={index} >
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <DraggableServiceType
            snapshot={snapshot}
            serviceTypeInfoMetadatum={serviceTypeInfoMetadatum}
            useSimple={useSimple}
          />
        </div>
      )}
    </Draggable>
  );
}

function DroppableRow({
  id,
  serviceTypeInfoMetadata,
  useSimple,
}) {
  return (
    <Droppable droppableId={`current-${id}`} direction='horizontal' >
      {(provided) => (
        <div ref={provided.innerRef} {...provided.droppableProps} style={{ display: 'flex' }}>
          {serviceTypeInfoMetadata.map((serviceTypeInfoMetadatum, index) => (
            <DraggableItem
              key={serviceTypeInfoMetadatum.serviceTypeId}
              id={serviceTypeInfoMetadatum.serviceTypeId}
              index={index}
              serviceTypeInfoMetadatum={serviceTypeInfoMetadatum}
              useSimple={useSimple}
            />
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
}

export default function SortableServiceTypes() {
  const {
    sortableServiceTypes,
    onDragEnd,
  } = useServiceCategoryDetailStore();


  return (
    <Grid container sx={{ marginTop: '20px', marginBottom: '20px' }}>

      <Typography variant='body1'>Current Service Types</Typography>

      <Grid item xs={12} sx={{ background: '#eeeeee', overflowX: 'auto' }}>
        <>
          {Object.entries(sortableServiceTypes).map(([id, items]) => {
            return (
              <DroppableRow
                key={id}
                id={id}
                serviceTypeInfoMetadata={items}
                useSimple={false}
              />
            );
          })}
          {sortableServiceTypes.length === 0 && (
            <Droppable droppableId={`current-${0}`} direction='horizontal' >
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps} style={{ display: 'flex', height: '100px', width: '100%' }}>
                </div>
              )}
            </Droppable>
          )}
        </>
      </Grid>
    </Grid>
  );
}

