import { HookSwitch } from 'components/reactHookForm';
import { useFormContext, useWatch } from 'react-hook-form';

export function NsoToggle() {
  const formCtx = useFormContext();
  const watchIsEnabled = useWatch({ control: formCtx.control, name: 'isEnabled' });
  return (
    <HookSwitch
      name='isEnabled'
      label={watchIsEnabled ? 'NSO Enabled' : 'NSO Disabled'}
      switchProps={{
        sx: {
          '& .MuiSwitch-thumb': {
            color: '#DE3051',
          },
          '& .Mui-checked .MuiSwitch-thumb': {
            color: '#26995F',
          },
          '&&& .Mui-checked + .MuiSwitch-track': {
            backgroundColor: '#26995F',
          },
          '&&& .MuiSwitch-track': {
            backgroundColor: '#DE3051',
          },
        },
      }} />
  );
}
