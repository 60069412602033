import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { makeStyles, styled } from '@mui/material/styles';

const CustomCancelIcon = styled(CancelOutlinedIcon)`
color: grey;
opacity:0.7;
transition:color 0.3s, opacity 0.3s;

:hover {
    cursor:pointer;
    color:#299b60;
    opacity:1;
}
`;
export default CustomCancelIcon;