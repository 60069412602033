import { Grid } from '@mui/material';
import { HookTextField } from 'components/reactHookForm';
import get from 'lodash.get';
import { DiscountScheduleTypes } from 'model/discountSchedule';
import { useFormContext, useWatch } from 'react-hook-form';
import TextInput from './TextInput';
import validateNumber from './validators';

function isTruthyNumber(value) {
  return value !== undefined && value !== null && value !== '';
}
export default function DollarPerCustomerFields({ required, neighborhoodServiceOfferings }:any) {
  const { control, trigger, getValues } = useFormContext();
  const watchedDiscountScheduleType = useWatch({ control, name: 'type' });
  const nsosWithNeighborhoodLevelPrices = neighborhoodServiceOfferings.filter( x => x.price);
  return (
    <Grid container item xs={12} spacing={1}>
      <Grid item xs={12}>
        <h4>Step 4: Pricing Schedule Details</h4>
      </Grid>
      <Grid item xs={2}>
        <HookTextField
          required
          name='pricingSchedule.startingAt'
          label='Starting At'
          maxLength='100'
          validationRules={{
            validate: function(value) {
              let _validatedNumber = validateNumber(value);
              if (_validatedNumber !== true) {
                return _validatedNumber;
              }
              if (isTruthyNumber(value)) {
                let parsedValue = parseFloat(value);
                if (parsedValue === 0 && watchedDiscountScheduleType === DiscountScheduleTypes.DollarPerCustomer) {
                  return 'The Starting At value must be greater than 0';
                }
                let priceDecrement = get(getValues(), 'pricingSchedule.decrement');
                if (isTruthyNumber(priceDecrement) && parsedValue <= parseFloat(priceDecrement)) {
                  return 'The Starting At value must be greater than the decrement.';
                }
                let priceFloor = get(getValues(), 'pricingSchedule.priceFloor');
                if (isTruthyNumber(priceFloor) && parsedValue <= parseFloat(priceFloor)) {
                  return 'The Starting At value must be greater than the price floor.';
                }
              }

            },
          }}
        />
      </Grid>
      <Grid item xs={2}>
        <HookTextField
          required
          name='pricingSchedule.decrement'
          label='Decrement'
          maxLength='100'
          postOnChange={() => {
            void trigger('pricingSchedule.startingAt');
          }}
          validationRules={{
            validate: validateNumber,
          }}
        />
      </Grid>
      <Grid item xs={2}>
        <HookTextField
          required
          name='pricingSchedule.priceFloor'
          label='Price Floor'
          maxLength='100'
          postOnChange={() => {
            void trigger('pricingSchedule.startingAt');
          }}
          validationRules={{
            validate: function(value) {
              let _validatedNumber = validateNumber(value);
              if (_validatedNumber !== true) {
                return _validatedNumber;
              }
              let parsedValue = parseFloat(value);
              for (let i = 0; i < nsosWithNeighborhoodLevelPrices.length;i++) {
                let nsoWithPrice = nsosWithNeighborhoodLevelPrices[i];
                if (nsoWithPrice && nsoWithPrice.price && nsoWithPrice.price <= parsedValue) {
                  return `${nsoWithPrice.name} has a price ${nsoWithPrice.price} which is under the price floor: ${parsedValue}.`;
                }
              }
            },
          }}
        />
      </Grid>
      <Grid item xs={2}>
        <TextInput
          required
          name='pricingSchedule.startingCountOffset'
          label='Starting Count Offset'
          maxLength='100'
          validationRules={{
            validate: validateNumber,
          }}
        />
      </Grid>
    </Grid>
  );
}