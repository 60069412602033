import { Alert, Button, Chip, Grid, IconButton, TextField, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import ClearIcon from '@mui/icons-material/Clear';
import { deleteUser, findAllUsers, findUserByEmail } from 'api/userApi';
import { RemoveButton, EditButton } from 'components/buttons';
import CopyToClipboardCell from 'components/DataGridCell/CopyToClipboard';
import QuickSearchToolbar from 'components/grid/QuickSearchToolbar';
import useTableSearch from 'components/grid/useTableSearch';
import Loading, { useLoading } from 'components/Layout/Loading';
import ConfirmationModal, { ConfirmationModalContextProvider, ConfirmationModalContext } from 'components/modals/ConfirmationModal';
import PageHeader from 'components/SectionHeaders/PageHeader';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './users.module.css';

function DeleteCell({ id: id, name: name }) {
  const confirmationModalCtx = useContext(ConfirmationModalContext);
  return (
    <RemoveButton
      tooltipTitle='Remove user'
      onClick={() => confirmationModalCtx.openModal({ id, name })}
      tooltipProps={{
        placement: 'top',
      }}
      iconButtonProps={{
        className: styles.actionButton,
      }}
    />
  );
}

function EditCell({ id, name }) {
  const navigate = useNavigate();
  function handleClick() {
    navigate(`/users/${id}/user-details`);
  }
  return (
    <EditButton
      tooltipTitle={`Edit ${name}`}
      onClick={handleClick}
      tooltipProps={{
        placement: 'top',
      }}
      iconButtonProps={{
        className: styles.actionButton,
      }}
    />
  );
}
const quickSearchStorageKey = 'userManagement';
const loadingKey = 'usersTable';
export default function Users() {
  const [users, setUsers] = useState<any[]>([]);
  const navigate = useNavigate();
  const [isLoadAllUsersDisabled, setIsLoadAllUsersDisabled] = useState<boolean>(true);

  const ref = useRef(null);
  const { onLoading, doneLoading } = useLoading(loadingKey);
  const { rows, searchText, setRowsWithStoredFilter, requestSearch, clearStoredSearch } = useTableSearch(quickSearchStorageKey);

  const [sortModel, setSortModel] = useState([
    {
      field: 'name',
      sort: 'asc' as any,
    },
  ]);

  async function onFindUserByEmail() {
    if (ref.current) {
      onLoading();
      const _users = await findUserByEmail((ref?.current as any).value);
      setUsers(_users.data);
      setRowsWithStoredFilter(_users.data);
      doneLoading();
    }
  }

  function onEmailKeyDown(e) {
    if (e.keyCode === 13) {
      void onFindUserByEmail();
    }
  }
  function handleClearClick() {
    (ref?.current as any).value = '';
  }

  async function getUsers() {
    setIsLoadAllUsersDisabled(true);
    onLoading();
    const _users = await findAllUsers();
    setUsers(_users.data);
    setRowsWithStoredFilter(_users.data);
    doneLoading();
    setTimeout(function() {
      setIsLoadAllUsersDisabled(false);
    }, 10000);
  }

  useEffect(() => {
    if (window.REACT_APP_NODE_ENV !== 'local') {
      setTimeout(() => {
        setIsLoadAllUsersDisabled(false);
      }, 5000);
    } else {
      setTimeout(() => {
        setIsLoadAllUsersDisabled(false);
      }, 0);
    }
  }, []);

  async function _deleteUser({ id }) {
    if (id) {
      await deleteUser(id);
      clearStoredSearch();
      await getUsers();
    }
  }

  const columns: GridColDef[] = [
    {
      field: 'email',
      headerName: 'email',
      flex: 1,
      renderCell: (params: any) => {
        if (!params.row.email) {
          return <Chip label="Unverified" variant='filled'></Chip>;
        }
        return <div>{params.row.email}</div>;
      },
    },
    {
      field: 'userType',
      headerName: 'User Type',
      flex: 1,
      renderCell: (params: any) => {
        const userType = params.row.userType;
        if (userType === 'ROLE_ADMIN') {
          return <div>Admin Site User</div>;
        }
        if (userType === 'ROLE_CONSUMER') {
          return <div>Consumer Site User</div>;
        }
        if (userType === 'ROLE_PROVIDER') {
          return <div>Provider Site User</div>;
        }
        return <div>Not associate to site</div>;
      },
    },
    {
      field: 'id',
      headerName: 'GUID',
      flex: 1,
      renderCell: (params: any) => {
        return (<CopyToClipboardCell val={params.id}/>);
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      renderCell: (params:any) => {
        return (
          <Grid container item xs={12} justifyContent='flex-end' alignItems='center' sx={{ paddingRight: '20px' }}>
            <EditCell id={params.id} name={params.row.name} />
            <DeleteCell id={params.id} name={params.row.name}/>
          </Grid>
        );
      },
    },
  ];
  const columnTotalWidth = columns.map(x => x.width).reduce((a:any, b:any) => a+b, 0);
  //admin+consumer@example.com
  return (
    <Loading loadingKey={loadingKey}>
      <ConfirmationModalContextProvider onConfirm={_deleteUser}>
        <Grid container spacing={2} className='pageGridContainer'>
          <Grid item xs={12}>
            <PageHeader headerText={'Users'} />
          </Grid>
          <Grid container item spacing={1} alignItems='center'>
            <Grid item xs='auto'>
              <Button
                variant='outlined'
                onClick={() => navigate('/users/create/provider-user-details')}>Create Provider User</Button>
            </Grid>
            <Grid item xs={2}>
              <TextField
                variant='outlined'
                label='email'
                size='small'
                inputRef={ref}
                onKeyDown={onEmailKeyDown}
                fullWidth
                InputProps={{
                  autoComplete: 'email',
                  endAdornment: (
                    <IconButton
                      size='small'
                      onClick={handleClearClick}
                    >
                      <ClearIcon />
                    </IconButton>
                  ),
                }}
              />
            </Grid>
            <Grid item xs='auto'>
              <Button
                variant='outlined'
                onClick={onFindUserByEmail}>
                <>Find User</>
              </Button>
            </Grid>
            <div style={{ flexGrow: '1' }}></div>
            <Grid item xs='auto'>
              <Alert severity='warning'>Load all users is an expensive query</Alert>
            </Grid>
            <Grid item xs='auto'>
              <Button
                disabled={isLoadAllUsersDisabled}
                variant='outlined'
                onClick={() => getUsers()}
              >Load All Users</Button>
            </Grid>
          </Grid>
          <Grid item xs={12}>

          </Grid>
          <Grid item xs={12}>
            <DataGrid
              rows={rows}
              components={{ Toolbar: QuickSearchToolbar }}
              componentsProps={{
                toolbar: {
                  quickSearchStorageKey,
                  value: searchText,
                  onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
                    requestSearch(users, event.target.value),
                  clearSearch: () => requestSearch(users, ''),
                },
              }}
              columns={columns}
              autoHeight={true}
              sortModel={sortModel}
              onSortModelChange={setSortModel}
              style={{
                minWidth: '500px',
              }}
            />
          </Grid>
        </Grid>
        <ConfirmationModal
          title={(onConfirmArgs) => (<div className={styles.confirmDeleteModalTitle}>Delete user</div>)}
          message={(onConfirmArgs:any) => (
            <Grid container spacing={1} alignItems='center' justifyContent='center' className={styles.confirmDeleteModal}>
              <Grid item xs>
                        You are about to delete {onConfirmArgs.id}. This will not delete the user in Userfront.
              </Grid>
            </Grid>
          )}
        />
      </ConfirmationModalContextProvider>
    </Loading>
  );
}