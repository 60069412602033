import { EditButton } from 'components/buttons';
import { useNavigate } from 'react-router-dom';
import styles from './tableCell.module.css';

export interface IEditCellProps {
  editName:string;
  toRoute: string;
}

export default function EditCell({ toRoute, editName }: IEditCellProps) {
  const navigate = useNavigate();
  function handleClick() {
    navigate(toRoute);
  }
  return (
    <EditButton
      tooltipTitle={`Edit ${editName ?? ''}`}
      onClick={handleClick}
      tooltipProps={{
        placement: 'top',
      }}
      iconButtonProps={{
        className: styles.actionButton,
      }}
    />
  );
}