import * as React from 'react';
import { Button, Menu } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import NavigationDropdownMenuItem from './NavigationDropdownMenuItem';

export default function NavigationDropdownMenu({ buttonText, menuItemsContent }) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const endIcon = open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />;
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        endIcon={endIcon}
        onClick={handleClick}
        size='small'
        style={{
          textAlign: 'left',
          color: 'white',
        }}
        sx={{
          'padding': '4px 8px',
          'margin': '2px 0',
          '&:hover': {
            transition: '0.2s',
            background: '#224E4E',
          },
        }}>{buttonText}</Button>
      <Menu
        transitionDuration={{ appear: 100, enter: 100, exit: 0 }}
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {menuItemsContent.map((item, idx) => (
          <NavigationDropdownMenuItem
            buttonText={item.buttonText}
            route={item.route}
            onClick={handleClose}
            key={`menu-item-${item.buttonText}-${idx}`}
          />
        ))}
      </Menu>
    </>
  );
}