import { Grid, Button } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import QuickSearchToolbar from 'components/grid/QuickSearchToolbar';
import useTableSearch from 'components/grid/useTableSearch';
import { useEffect, useState } from 'react';
import useServiceTypeInfo from '../hookStore/useServiceTypeInfo';
import { columnTotalWidth, columns } from './colDef';

const quickSearchStorageKey = 'serviceCategories';

function ServiceCategories () {
  const { serviceCategories, init, addNewServiceCategory } = useServiceTypeInfo();
  const { rows, searchText, setRowsWithStoredFilter, requestSearch } = useTableSearch(quickSearchStorageKey);
  const [sortModel, setSortModel] = useState([
    {
      field: 'name',
      sort: 'asc' as any,
    },
  ]);

  useEffect(() => {
    void init();
  }, []);

  useEffect(() => {
    setRowsWithStoredFilter(serviceCategories);
  }, [serviceCategories]);

  return (
    <Grid container>
      <Grid item xs={12} sx={{ marginBottom: '20px' }}>
        <Button variant="contained" onClick={addNewServiceCategory}>Add New</Button>
      </Grid>
      <Grid item xs={12}>
        <DataGridPro
          rows={rows}
          components={{ Toolbar: QuickSearchToolbar }}
          getRowId={(row) => row.serviceCategoryId}
          componentsProps={{
            toolbar: {
              quickSearchStorageKey,
              value: searchText,
              onChange: (event: React.ChangeEvent<HTMLInputElement>) => requestSearch(serviceCategories, event.target.value),
              clearSearch: () => requestSearch(serviceCategories, ''),
            },
          }}
          columns={columns}
          pagination
          pageSize={10}
          autoHeight={true}
          sortModel={sortModel}
          onSortModelChange={setSortModel}
          style={{
            minWidth: '500px',
            maxWidth: `${columnTotalWidth}px`,
          }}
        />
      </Grid>
    </Grid>
  );
}

export default ServiceCategories;
