
import { getNeighborhoodById } from 'api/neighborhoodApi';
import { findNeighborhoodServiceOfferingById } from 'api/neighborhoodServiceOfferingApi';
import { findServiceInstanceById } from 'api/serviceInstanceApi';
import { findServiceOfferingById } from 'api/serviceOfferingApi';
import { useLoading } from 'components/Layout/Loading';
import useToast from 'components/toast/useToast';
import createStore from 'hooks/hookStore';
import { createEmptyNotifyHappeningSoonForm, createEmptyServiceInstanceForm, INotifyOfHappeningSoonForm } from 'model/serviceInstance';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { createDateIgnoreTimezone } from 'util/dateUtil';

function compare(a: any, b: any) {
  return a.name > b.name ? 1 : b.name > a.name ? -1 : 0;
}

const loadingKey = 'neighborhoodServiceOfferingInstanceDetail';

type NeighborhoodServiceOfferingInstanceDetail = {
  neighborhoodId:string;
  formData:any;
  notifyOfHappeningSoonFormData:INotifyOfHappeningSoonForm;
}

const { get, update, registerListener, unregisterListener } = createStore<NeighborhoodServiceOfferingInstanceDetail>('neighborhoodServiceOfferingInstanceDetail', {
  neighborhoodId: '',
  formData: createEmptyServiceInstanceForm(),
  notifyOfHappeningSoonFormData: createEmptyNotifyHappeningSoonForm(),
});

export default function useNeighborhoodServiceOfferingInstanceDetail() {
  const setState = useState(get())[1];
  const navigate = useNavigate();
  const { createErrorToast, createSuccessToast } = useToast();
  const { onLoading, doneLoading } = useLoading(loadingKey);

  const { serviceInstanceId, neighborhoodServiceOfferingId } = useParams();

  useEffect(() => {
    registerListener(setState);
    return () => {
      unregisterListener(setState);
    };
  }, []);

  async function init() {
    onLoading();
    try {

      if (neighborhoodServiceOfferingId) {

        const nsoRes = await findNeighborhoodServiceOfferingById(neighborhoodServiceOfferingId);
        const [neighborhoodRes, serviceOfferingRes] = await Promise.all([
          getNeighborhoodById(nsoRes.data.neighborhoodId),
          findServiceOfferingById(nsoRes.data.serviceOfferingId),
        ]);
        let neighborhoodId = neighborhoodRes.data.id;
        let formData = createEmptyServiceInstanceForm();
        if (serviceInstanceId) {
          const response = await findServiceInstanceById(serviceInstanceId);
          formData = response.data;
          formData.cutoffDayCount = formData.cutoffDayCount;
        } else {
          formData.cutoffDayCount = serviceOfferingRes.data.cutoffDayCount;
        }
        formData.serviceDate = createDateIgnoreTimezone(formData.serviceDate).toISOString();
        if (formData.endServiceDate) {
          formData.endServiceDate = createDateIgnoreTimezone(formData.endServiceDate).toISOString();
        }
        update({
          ...get(),
          neighborhoodId,
          formData,
        });
      }

      doneLoading(300);
    } catch (e:any) {
      console.error(e);
      createErrorToast('Could not retrieve nso');
      doneLoading(300);
    }
  }


  return {
    loadingKey,
    ...get(),
    init,
  };
}