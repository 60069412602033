import Axios, { AxiosResponse } from 'axios';
import { IFinalizedInvoiceDTO, IGenerateInvoiceRequest, IGenerateInvoiceResponse, IInvoiceCustomerRowDTO } from 'model/invoicing';

const apiUrl = window.REACT_APP_BASE_API_URI;

export function findInvoicedCustomersByServiceProvider(providerId: string, startDate: Date, endDate: Date) :Promise<AxiosResponse<IInvoiceCustomerRowDTO[]>> {
  return Axios.post(`${apiUrl}/invoicing/serviceProviders/${providerId}/findInvoiced`, { startDate, endDate });
}

export function findCompletedCustomersByServiceProviderWithoutInvoice(providerId: string, startDate: Date | null, endDate: Date | null) :Promise<AxiosResponse<IInvoiceCustomerRowDTO[]>> {
  return Axios.post(`${apiUrl}/invoicing/serviceProviders/${providerId}/noInvoice`, { startDate, endDate });
}

export function removeCustomerInvoiceInfo(providerId: string, customerIds: any[]) {
  return Axios.post(`${apiUrl}/invoicing/serviceProviders/${providerId}/removeCustomerInvoiceInfo`, customerIds);
}

export function markCustomersAsInvoiced(providerId: string, customerIds: any[], invoiceNumber: string ) {
  return Axios.post(`${apiUrl}/invoicing/serviceProviders/${providerId}/markCustomersAsInvoiced/${invoiceNumber}`, customerIds);
}

export function draftServiceProviderInvoice(providerId: string, dto: IGenerateInvoiceRequest):Promise<AxiosResponse<IGenerateInvoiceResponse>> {
  return Axios.post(`${apiUrl}/invoicing/serviceProviders/${providerId}/draftServiceProviderInvoice`, dto);
}

export function getCustomerInvoice(providerId: string, customerId:string):Promise<AxiosResponse<IFinalizedInvoiceDTO>> {
  return Axios.get(`${apiUrl}/invoicing/serviceProviders/${providerId}/customers/${customerId}/getCustomerInvoice`);
}