import { createValidateNumberNotRequired } from 'util/rhfValidateFunctions';
import { Grid } from '@mui/material';
import { HookTextField } from 'components/reactHookForm';
import HookRating from 'components/reactHookForm/HookRating';
import GoogleIcon from 'images/logos/google_logo.png';
import styles from '../serviceProviderDetails.module.css';

const validateNumber = createValidateNumberNotRequired();

export default function RatingInfo({ textInputGridXs = 1 }) {

  return (
    <Grid container item spacing={1} alignItems='top' xs={12}>
      <Grid container item xs='auto' alignItems='center'>
        <img src={GoogleIcon} width={24} height={24}/>
      </Grid>
      <Grid container item xs='auto' alignItems='center'>
        <HookRating
          name="rating.rating"
          label='Rating'
          precision={0.1}
          size='large'
          className={styles.rating}
        />
      </Grid>
      <Grid item xs={textInputGridXs}>
        <HookTextField
          label='Rating number entry'
          name='rating.rating'
          validationRules={{
            min: { value: 0, message: 'rating must be above 0' },
            max: { value: 5, message: 'rating must be less than 5' },
            validate: validateNumber,
          }}
          inputProps={{ inputMode: 'numeric' }}
        />
      </Grid>
      <Grid item xs={textInputGridXs}>
        <HookTextField
          label='Number of ratings'
          name='rating.numberOfRatings'
          validationRules={{
            min: { value: 0, message: 'rating must be above 0' },
            validate: validateNumber,
          }}
          inputProps={{ inputMode: 'numeric' }}
        />
      </Grid>
    </Grid>
  );
}