import { Autocomplete, TextField } from '@mui/material';
import useMarkets from 'hooks/useMarkets';
import useTerritories from 'hooks/useTerritories';
import { IDropdownOption } from 'model/dropdown';
import { IMarket } from 'model/markets';
import { ITerritory } from 'model/territories';


export interface ITerritoryAutocompleteProps {
  marketId:string;
  value?:any;
  onChangeOverride?:any;
}

function convertToDropdownOption(selectedTerritory:ITerritory):IDropdownOption {
  return {
    key: selectedTerritory.id,
    optionText: selectedTerritory.name,
    optionValue: selectedTerritory.id,
    ancillary: selectedTerritory,
  };
}

export default function TerritoryAutocomplete({
  marketId,
  onChangeOverride,
}:ITerritoryAutocompleteProps) {

  const {
    territoryDropdownOptions,
    selectedTerritory,
    territory,
    onChange,
    preOnChangeOverride,
  } = useTerritories(marketId);
  let usedOption:IDropdownOption | null = null;
  if (onChangeOverride) {
    usedOption = territory != null ? convertToDropdownOption(territory) : null;
  } else {
    usedOption = selectedTerritory != null ? convertToDropdownOption(selectedTerritory) : null;
  }
  return (
    <Autocomplete
      size='small'

      sx={{
        '&': {
          minWidth: '300px',
          maxWidth: '300px',
          padding: '1px',
        },
      }}
      id={'territory-autocomplete'}
      onChange={(e, value:any, reason, details) => {
        if (reason === 'selectOption') {
          if (onChangeOverride) {
            preOnChangeOverride(value);
            onChangeOverride(value);
            return;
          }
          onChange(value);

        } else if (reason === 'createOption') {

        } else if (reason === 'removeOption') {
          if (onChangeOverride) {
            preOnChangeOverride(value);
            onChangeOverride(value);
            return;
          }
          onChange(value);

        } else if (reason === 'blur') {

        } else if (reason === 'clear') {
          if (onChangeOverride) {
            preOnChangeOverride(value);
            onChangeOverride(value);
            return;
          }
          onChange(null);
        }
      }}
      isOptionEqualToValue={(option, value: IDropdownOption) => {
        return option.optionValue === value.optionValue;
      }}
      value={usedOption}
      options={territoryDropdownOptions}
      getOptionLabel={(option) => {
        return option?.optionText ?? '';
      }}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          {option.optionText}
        </li>
      )}

      fullWidth
      renderInput={(params) => (
        <TextField
          {...params}
          label={'Territory'}
          placeholder={'Select a territory'}
          sx={{
            backgroundColor: 'white',
          }}
        />
      )}
    />
  );
}