import { Button, Checkbox, FormControl, FormControlLabel, Grid } from '@mui/material';
import { DataGrid, GridColDef, GridCsvExportOptions, GridCsvGetRowsToExportParams, gridSortedRowIdsSelector, GridToolbarContainer, GridToolbarExport, GridToolbarQuickFilter, useGridApiContext } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { usdFormatter } from 'util/currencyUtil';
import { formatMonthDayYear } from 'util/dateUtil';
import useRewardCodeExport from './hookStore/useRewardCodeExport';
import styles from './rewards.module.css';

function CustomToolbar() {
  const apiRef = useGridApiContext();

  const { toggleUnusedCheckbox, unusedChecked, onIssueRewardCodes } = useRewardCodeExport();
  return (
    <GridToolbarContainer>
      <Grid container justifyContent='space-between' className={styles.toolbar}>
        <Grid container item xs={12} sm={6}>
          <Grid item>
            <Button
              type='button'
              variant='contained'
              startIcon={<FileDownloadIcon />}
              onClick={async() => {
                const data = await onIssueRewardCodes();
              }}
            >
        Export Unused
            </Button>
          </Grid>
          <Grid item>
            <div className={styles.unusedCheckboxWrapper}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={unusedChecked}
                    name='showOnlyUnused'
                    onChange={(e) => toggleUnusedCheckbox(e.target.checked)}
                  ></Checkbox>
                }
                label={'Filter Unused'}
              />
            </div>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={6} lg={3}>
          <GridToolbarQuickFilter sx={{
            width: '100%',
            marginTop: {
              xs: '20px',
              sm: '0',
            },
          }}
          />
        </Grid>
      </Grid>
    </GridToolbarContainer>
  );
}

const columns: GridColDef[] = [
  {
    field: 'code',
    headerName: 'Code',
    flex: 1,
    minWidth: 150,
    renderCell: (params) => {
      return <div>{params.row.formattedCode}</div>;
    },
  },
  {
    field: 'rewardCodeStatus',
    headerName: 'Status',
    flex: 1,
    minWidth: 150,
    renderCell: (params) => {
      let { rewardCodeStatus, individualId, redeemedDate } = params.row;
      if (rewardCodeStatus === 'UNUSED') {
        return <div>Unused</div>;
      } else if (rewardCodeStatus === 'REDEEMED') {
        return <div>Redeemed by: {individualId} on {formatMonthDayYear(redeemedDate)}</div>;
      }
      return <div>{params.row.rewardCodeStatus}</div>;
    },
  },
  {
    field: 'amount',
    headerName: 'amount',
    flex: 1,
    minWidth: 50,
    renderCell: (params) => {
      return <div>{usdFormatter.format(params.row.amount)}</div>;
    },
  },
  {
    field: 'notes',
    headerName: 'Notes',
    flex: 1,
    minWidth: 100,
    renderCell: (params) => {
      return <div>{params.row.notes}</div>;
    },
  },
];

const columnTotalWidth = columns.map(x => x.width).reduce((a:any, b:any) => a+b, 0);
export default function RewardCodeExport() {
  const { filteredRewardCodes, init } = useRewardCodeExport();
  const [sortModel, setSortModel] = useState([
    {
      field: 'name',
      sort: 'asc' as any,
    },
  ]);

  useEffect(() => {
    void init();
  }, []);

  return (
    <DataGrid
      pagination
      rows={filteredRewardCodes}
      getRowId={(row) => row.pk}
      components={{ Toolbar: CustomToolbar }}
      componentsProps={{
        toolbar: {
          showQuickFilter: true,
          quickFilterProps: { debounceMs: 200 },
        },
      }}
      columns={columns}
      autoHeight={true}
      sortModel={sortModel}
      onSortModelChange={setSortModel}
      style={{
        minWidth: '500px',
        maxWidth: `${columnTotalWidth}px`,
      }}
    />
  );
}