import { reconcileUsers } from 'api/userApi';
import { useLoading } from 'components/Layout/Loading';
import useToast from 'components/toast/useToast';
import createStore from 'hooks/hookStore';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

type UserReconciliation = {
  onUpload:Function;
  onDropFile:Function;
  acceptedFiles:any;
}
const loadingKey = 'userReconciliation';

const { get, update, registerListener, unregisterListener } = createStore<UserReconciliation>('userReconciliation', {
  onUpload: () => {},
  onDropFile: () => {},
  acceptedFiles: [],
});

export default function useUserReconciliation() {
  const setState = useState(get())[1];
  const { onLoading, doneLoading } = useLoading(loadingKey);
  const { neighborhoodId } = useParams();
  const { createInfoToast, createSuccessToast, createErrorToast } = useToast();

  useEffect(() => {
    registerListener(setState);
    return () => {
      unregisterListener(setState);
    };
  }, []);

  async function onUpload(onConfirmArgs) {
    const { data } = onConfirmArgs;
    await reconcileUsers(data);
    createSuccessToast('Upload received. You will receive an email once the process is complete.');
    update({
      ...get(),
      acceptedFiles: [],
    });
  }

  async function onDropFile(acceptedFiles, ctx) {
    try {
      ctx.setValue('users', acceptedFiles[0]);
      ctx.setValue('fileName', acceptedFiles[0].name);
      update({
        ...get(),
        acceptedFiles,
      });
    } catch (e:any) {
      // setErrorMessage(e.response.data);
    }
  }


  return {
    loadingKey,
    ...get(),
    onDropFile,
    onUpload,
  };
}