import Axios from 'axios';

const apiUrl = window.REACT_APP_BASE_API_URI;

export function emitTestPastCustomerRequestToReviewEvent() {
  return Axios.get(`${apiUrl}/batchEmailJob/emitTestPastCustomerRequestToReviewEvent`);
}

export function manualReceivePastCustomerRequestToReview() {
  return Axios.get(`${apiUrl}/batchEmailJob/manual/scheduling/pastCustomerRequestToReview`);
}