import Axios, { AxiosResponse } from 'axios';
import { INeighborhoodServiceOffering, IToggleIgnoreNsoForReconciliationDTO, IToggleNsoDTO } from 'model/neighborhoodServiceOffering';

const apiUrl = window.REACT_APP_BASE_API_URI;

export function findAllNeighborHoodServiceOfferings() {
  return Axios.get(`${apiUrl}/neighborhoodServiceOffering/v2/findAll`);
}

export function findNeighborhoodServiceOfferingById(id:string) {
  return Axios.get(`${apiUrl}/neighborhoodServiceOffering/v2/find/${id}`);
}

export function deleteNeighborhoodServiceOfferingById(id:string) {
  return Axios.delete(`${apiUrl}/neighborhoodServiceOffering/v2/delete/${id}`);
}

export function findNeighborhoodServiceOfferingsByFilter(tableFilter) {
  let params:any = {};
  if (tableFilter.selectedServiceProviderId) {
    params.providerId = tableFilter.selectedServiceProviderId;
  }
  if (tableFilter.selectedNeighborhoodId) {
    params.neighborhoodId = tableFilter.selectedNeighborhoodId;
  }
  return Axios.get(`${apiUrl}/neighborhoodServiceOffering/v2/findByFilter`, {
    params,
  });
}

export function saveNeighborhoodServiceOffering(values:any) {
  return Axios.post(`${apiUrl}/neighborhoodServiceOffering/v2/create`, values);
}

export function updateNeighborhoodServiceOffering(values:any) {
  return Axios.put(`${apiUrl}/neighborhoodServiceOffering/v2/update/${values.id}`, values);
}

export function toggleNeighborhoodServiceOfferingsByServiceProvider(serviceProviderId:string, data:IToggleNsoDTO) {
  return Axios.post(`${apiUrl}/neighborhoodServiceOffering/v2/serviceProviders/${serviceProviderId}/toggleNeighborhoodServiceOfferings`, data);
}

export function toggleNeighborhoodServiceOfferingsByServiceOffering(serviceOfferingId:string, data:IToggleNsoDTO) {
  return Axios.post(`${apiUrl}/neighborhoodServiceOffering/v2/serviceOfferings/${serviceOfferingId}/toggleNeighborhoodServiceOfferings`, data);
}

export function toggleNeighborhoodServiceOfferingsById(neighborhoodServiceOfferingId:string, data:IToggleNsoDTO) {
  return Axios.post(`${apiUrl}/neighborhoodServiceOffering/v2/neighborhoodServiceOfferings/${neighborhoodServiceOfferingId}/toggleByNeighborhoodServiceOfferingId`, data);
}

export function toggleIgnoreForReconciliationNeighborhoodServiceOfferingsById(neighborhoodServiceOfferingId:string, data:IToggleIgnoreNsoForReconciliationDTO) {
  return Axios.post(`${apiUrl}/neighborhoodServiceOffering/v2/neighborhoodServiceOfferings/${neighborhoodServiceOfferingId}/toggleIgnoreForReconciliationNeighborhoodServiceOfferingsById`, data);
}

export function findNSOsByDiscountSchedule(discountScheduleId:string):Promise<AxiosResponse<INeighborhoodServiceOffering[]>> {
  return Axios.get(`${apiUrl}/neighborhoodServiceOffering/v2/discountSchedules/${discountScheduleId}/findByDiscountSchedule`);
}