import { FormControl, InputLabel, FormHelperText } from '@mui/material';
import { HookSelect } from 'components/reactHookForm';
import HookMultiselect from 'components/reactHookForm/HookMultiselect';
import useErrorState from 'hooks/reactHookForm/useErrorState';
import { useFormContext, useWatch } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import useNeighborhoodServiceOfferingDetail from '../hookStore/useNeighborhoodServiceOfferingDetail';
import NeighborhoodAutocomplete from 'components/neighborhoodAutocomplete/NeighborhoodAutocomplete';
import NeighborhoodMultiAutocomplete from 'components/neighborhoodAutocomplete/NeighborhoodMultiAutocomplete';
import { NeighborhoodStatusType } from 'model/neighborhood';
import { useState } from 'react';

export function NeighborhoodSelect() {
  const formCtx = useFormContext();
  const { neighborhoodServiceOfferingId } = useParams();
  const { onNeighborhoodsSearched } = useNeighborhoodServiceOfferingDetail();
  const { isError, getErrorMessage } = useErrorState(formCtx);
  const [inputValue, setInputValue] = useState<string>('');
  let watchedNeighborhoodId = '';
  if (neighborhoodServiceOfferingId) {
    watchedNeighborhoodId = useWatch({ name: 'neighborhoodId' });
  }
  return (
    <FormControl variant='standard' fullWidth error={isError('neighborhoodIds')}>
      {neighborhoodServiceOfferingId ?
        (
          <NeighborhoodAutocomplete
            label='Neighborhoods'
            fieldName='neighborhoodId'
            placeholder='Search and check a neighborhood'
            formless={false}
            required
            controlledValue={watchedNeighborhoodId}
            optionDescriptionFunc= {(x) => {
              if (!x.zipCodes || x.zipCodes.length === 0) {
                return `${x.name}`;
              }
              return `${x.name} [zip(s): ${x.zipCodes.join(',')}]`;
            }}
            onOptionsCreated={async (options) => {

              onNeighborhoodsSearched(options);
            }}
          />
        ) : (
          <NeighborhoodMultiAutocomplete
            label='Neighborhoods'
            fieldName='neighborhoodIds'
            statuses={[NeighborhoodStatusType.BETA, NeighborhoodStatusType.LAUNCHED, NeighborhoodStatusType.ACTIVE]}
            formless={false}
            placeholder={''}
            required={true}
            optionDescriptionFunc= {(x) => {
              if (!x.zipCodes || x.zipCodes.length === 0) {
                return `${x.name}`;
              }
              return `${x.name} [zip(s): ${x.zipCodes.join(',')}]`;
            }}
            extraProps={{
              clearOnBlur: false,
              onInputChange: (e, newInputValue, reason) => {
                if (reason === 'reset') {

                } else {
                  setInputValue(newInputValue);
                }
              },
              inputValue: inputValue,
            }}
            postOnChange={(formContext, value, reason, details, rawOptions) => {
              onNeighborhoodsSearched([...rawOptions]);

            }}
          />
        )}

    </FormControl>
  );
}
