import { Typography } from '@mui/material';
import GenericModal from 'components/modal/GenericModal';
import useModal, { IModal } from 'components/modal/useModal';
import { IEditServiceAllocationsForm, createEmptyEditServiceAllocationsForm } from 'model/customerUpload';

export const modalStoreKey = 'confirmEditAllocationsModal';
export interface IConfirmEditAllocationsModal {
  formData: IEditServiceAllocationsForm;
  callback: (formData: IEditServiceAllocationsForm) => Promise<void>;
}
export function createEmptyConfirmEditAllocationsModal() :IConfirmEditAllocationsModal {
  return {
    formData: createEmptyEditServiceAllocationsForm(),
    callback: async (formData: IEditServiceAllocationsForm) => {},
  };
}
export interface IConfirmEditAllocationsModalProps {

}

export default function ConfirmEditAllocationsModal({ }:IConfirmEditAllocationsModalProps) {
  const { getModalState, closeModal } = useModal(modalStoreKey);
  const _state = getModalState() as IConfirmEditAllocationsModal & IModal;
  const { formData, callback } = _state;
  async function _onConfirm(state:IConfirmEditAllocationsModal & IModal) {
    await callback(state.formData);
    closeModal(createEmptyConfirmEditAllocationsModal());
  }

  return (
    <GenericModal
      modalStoreKey={modalStoreKey}
      title={'Confirm Customer Upload Rollback'}
      modalContent={
        (
          <div >
            <Typography variant='body1' sx={{ paddingBottom: '10px' }}>
              This will edit the service allocations for request id
            </Typography>
          </div>
        )
      }
      onConfirm={() => _onConfirm(_state)}
      secondaryButtonText='Cancel'
    />
  );
}