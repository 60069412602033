import { FormControl, FormHelperText, InputLabel } from '@mui/material';
import { HookSelect } from 'components/reactHookForm';
import useErrorState from 'hooks/reactHookForm/useErrorState';
import { ServiceTypePriority } from 'model/serviceType';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

export interface IServiceTypePrioritySelecttProps {
  disabled?: boolean;
}
export function ServiceTypePrioritySelect({ disabled }:IServiceTypePrioritySelecttProps) {
  const formCtx = useFormContext();
  const [statusTypes] = useState<any[]>(ServiceTypePriority.list);
  const { isError, getErrorMessage } = useErrorState(formCtx);
  return (
    <FormControl variant='standard' fullWidth error={isError('serviceTypePriority')}>
      <InputLabel htmlFor={'service-type-priority'}>Service Type Priority</InputLabel>
      <HookSelect
        disabled={disabled}
        control={formCtx.control}
        name={'serviceTypePriority'}
        id={'service-type-priority'}
        menuItems={statusTypes} />
      <FormHelperText>{getErrorMessage('serviceTypePriority')}</FormHelperText>
    </FormControl>
  );
}
