
import DeleteIcon from '@mui/icons-material/Delete';
import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import PageHeader from 'components/SectionHeaders/PageHeader';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Card, IconButton, ListItemIcon } from '@mui/material';
import { DragHandle } from '@mui/icons-material';
import { IDropdownOption } from 'model/dropdown';

interface Item {
  id: string;
  content: string;
  data: {};
}

const reorder = (list: IDropdownOption[], startIndex: number, endIndex: number): IDropdownOption[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

interface DraggableListProps {
  items: IDropdownOption[];
  title: string;
  stateSetter: Function;
}

const DraggableList: React.FC<DraggableListProps> = ({ items, title, stateSetter }) => {
  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }

    const newItems = reorder(
      items,
      result.source.index,
      result.destination.index,
    );

    stateSetter(newItems);
  };

  const onDelete = (item: IDropdownOption) => {
    const newItems = items.filter(x => x.optionValue !== item.optionValue);
    stateSetter(newItems);
  };

  return (
    <>
      <Card sx={{ paddingLeft: '15px', paddingRight: '15px' }}>
        <PageHeader headerText={title} />
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <List {...provided.droppableProps} ref={provided.innerRef}>
                {items.map((item, index) => (
                  <Draggable key={item.optionValue} draggableId={item.optionValue} index={index}>
                    {(child) => (
                      <Paper
                        ref={child.innerRef}
                        {...child.draggableProps}
                        {...child.dragHandleProps}
                        style={child.draggableProps.style}
                        elevation={0}
                      >
                        <ListItem>
                          <ListItemIcon><DragHandle /></ListItemIcon>
                          <ListItemText primary={item.optionText} />
                          <IconButton onClick={() => onDelete(item)}>
                            <DeleteIcon />
                          </IconButton>
                        </ListItem>
                      </Paper>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </List>
            )}
          </Droppable>
        </DragDropContext>
      </Card>
    </>
  );
};

export default DraggableList;
