
export interface IDropdownOption {
  key:string;
  optionValue:string;
  optionSk?:string;
  optionText:string;
  ancillary?: any;
}

export interface IDropDownOptionCreatable extends IDropdownOption {
  inputValue?: string;
}

export function createDefaultDropdownOption() {
  return { key: 'UNSELECTED', optionValue: 'UNSELECTED', optionText: 'All' };
}

export function createUninitializedDropdownOption(optionText?:string) {
  return { key: 'UNSELECTED', optionValue: 'UNSELECTED', optionText: optionText ?? 'Not Initialized' };
}

export const UNSELECTED_OPTION = 'UNSELECTED';