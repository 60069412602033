
import { Grid } from '@mui/material';
import { PrimaryButton } from 'components/buttons';
import useToast from 'components/toast/useToast';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { HookTextField } from 'components/reactHookForm';
import { IReferrerRewardTestingForm } from 'model/referrer_reward_testing/referrer_reward';
import useReferrerRewardTesting from './useReferrerRewardTesting';

export default function ReferrerRewardTestingForm() {

  const { onReferrerRewardTesting } = useReferrerRewardTesting();
  const { createErrorToast } = useToast();
  const formContext = useForm<IReferrerRewardTestingForm>({
    mode: 'onTouched',
    defaultValues: {
      individualId: '',
      referralCode: '',
      referralOfferId: '',
    },
  });
  const onSubmit : SubmitHandler<IReferrerRewardTestingForm> = data => {
    onReferrerRewardTesting(data).catch((err:any) => {
      createErrorToast(err.response.data.message);
    });
  };


  return (
    <>
      <FormProvider {...formContext}>
        <form onSubmit={formContext.handleSubmit(onSubmit)}>
          <Grid container spacing={1} >
            <Grid container item xs={12}>
              <Grid item xs={12} md={3}>
                <HookTextField
                  required
                  name='individualId'
                  label='Referee Individual id'
                />
                <HookTextField
                  required
                  name='referralCode'
                  label='Referral Id'
                />
                <HookTextField
                  name='referralOfferId'
                  label='Referral Offer Id'
                />
              </Grid>
            </Grid>
            <Grid item xs={11} sx={{ marginTop: '20px' }}>
              <PrimaryButton type='submit'>Submit</PrimaryButton>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </>
  );
}