import { findMarkets } from 'api/marketsApi';
import { createModuleStore } from 'hooks/hookStore';
import { IDropdownOption } from 'model/dropdown';
import { IMarket, MarketUtils } from 'model/markets';


import { useEffect, useState } from 'react';

type MarketStore = {
  market: IMarket | null;
}

type ModuleSelf = {
  loaded:boolean;
  markets: IMarket[];
  selectedMarket: IMarket | null;
  marketDropdownOptions: IDropdownOption[];
  marketMap: Map<string, IMarket>;
}

const { get, getModuleScoped, update, registerListener, unregisterListener } = createModuleStore<MarketStore, ModuleSelf>('MarketStore', {
  market: null,
},
{
  loaded: false,
  markets: [],
  selectedMarket: null,
  marketDropdownOptions: [],
  marketMap: new Map<string, IMarket>(),
});

const loadingKey = 'MarketStore';

export default function useMarkets() {
  const setState = useState(get())[1];

  useEffect(() => {
    registerListener(setState);
    return () => {
      unregisterListener(setState);
    };
  }, []);

  useEffect(() => {
    void onInit();
  }, []);

  async function onInit() {
    try {
      const moduleScope = getModuleScoped();
      if (!moduleScope.loaded) {
        moduleScope.loaded = true;
        const res = await findMarkets();
        if (res.data) {
          moduleScope.markets = res.data;
          moduleScope.marketMap = MarketUtils.convertToMap(res.data);

          moduleScope.marketDropdownOptions = res.data.map(m => {
            return {
              key: m.id,
              optionValue: m.id,
              optionText: m.name,
              ancillary: m,
            };
          });
          const storedMarketId = getStoredMarketId();
          if (storedMarketId === null) {
            moduleScope.selectedMarket = res.data[0];
            if (moduleScope.selectedMarket) {
              setStoredMarketId(moduleScope.selectedMarket.id);
            }

          } else {
            let index = res.data.findIndex((m:IMarket) => m.id === storedMarketId);
            moduleScope.selectedMarket = res.data[index];
          }
          update({
            ...get(),
            market: moduleScope.selectedMarket,
          });
        }
      }
    } catch (err:any) {
      console.log(err);
    }
  }

  function getStoredMarketId():string | null {
    return localStorage.getItem('selectedMarketId');
  }

  function setStoredMarketId(marketId:string | null) {
    if (marketId === null) {
      localStorage.removeItem('selectedMarketId');
    } else {
      localStorage.setItem('selectedMarketId', marketId);
    }
  }

  function onChange(marketOption:IDropdownOption | null) {
    const moduleScope = getModuleScoped();
    if (!marketOption) {
      moduleScope.selectedMarket = null;
      setStoredMarketId(null);
      return;
    }
    moduleScope.selectedMarket = marketOption.ancillary as IMarket;
    setStoredMarketId(moduleScope.selectedMarket.id);
    update({
      ...get(),
      market: moduleScope.selectedMarket,
    });
  }

  function preOnChangeOverride(marketOption: IDropdownOption | null) {
    if (marketOption === null) {
      update({
        ...get(),
        market: null,
      });
      return;
    }
    update({
      ...get(),
      market: marketOption?.ancillary as IMarket,
    });
  }

  return {
    loadingKey: loadingKey,
    ...get(),
    ...getModuleScoped(),
    onChange,
    preOnChangeOverride,
  };
}