export class DiscountScheduleTypes {
  /** No discount. Should hide/delete the pricingSchedule and discountSchedule fields */
  static NoDiscount='NoDiscount';
  /** Discount fields in pricingSchedule. Should hide/delete the discountSchedule fields.*/
  static DollarPerCustomer='DollarPerCustomer';
  /** Discount fields in discountSchedule. Should hide/delete the pricingSchedule fields*/
  static DollarSavePerCustomer='DollarSavePerCustomer';
  static PercentPerCustomer='PercentPerCustomer';
  static PercentSavePerCustomer='PercentSavePerCustomer';
  static PercentFlatRate='PercentFlatRate';
  static DollarFlatRate='DollarFlatRate';
  static list = [
    { id: DiscountScheduleTypes.NoDiscount, name: 'No Discount' },
    { id: DiscountScheduleTypes.DollarPerCustomer, name: 'Dollar Per Customer' },
    { id: DiscountScheduleTypes.DollarSavePerCustomer, name: 'Dollar Saved Per Customer' },
    // {id:DiscountScheduleTypes.PercentPerCustomer, name: 'Percent Per Customer'},
    { id: DiscountScheduleTypes.PercentSavePerCustomer, name: 'Percent Saved Per Customer' },
    { id: DiscountScheduleTypes.PercentFlatRate, name: 'Percent Flat Rate' },
    { id: DiscountScheduleTypes.DollarFlatRate, name: 'Dollar Flat Rate' },
  ];
}

export function displayValueForDiscountScheduleType(type:string) {
  if (type === DiscountScheduleTypes.DollarPerCustomer) {
    return 'Dollar Per Customer';
  } else if (type === DiscountScheduleTypes.DollarSavePerCustomer) {
    return 'Dollar Save Per Customer';
  } else if (type === DiscountScheduleTypes.PercentSavePerCustomer) {
    return 'Percent Save Per Customer';
  } else if (type === DiscountScheduleTypes.NoDiscount) {
    return 'No Discount';
  } else if (type === DiscountScheduleTypes.PercentFlatRate) {
    return 'Percent Flat Rate';
  } else if (type === DiscountScheduleTypes.DollarFlatRate) {
    return 'Dollar Flat Rate';
  }
  return 'Undefined';
}

export class DiscountScheduleStandardOptions {
  // standard schedules
  static PercentSavePerCustomer1='PercentSavePerCustomer1';
  static PercentSavePerCustomer2='PercentSavePerCustomer2';
  static DollarSavePerCustomer1='DollarSavePerCustomer1';
  static DollarSavePerCustomer2='DollarSavePerCustomer2';
  static NoDiscount='NoDiscount';

  static standardOptionsMap={
    [DiscountScheduleStandardOptions.NoDiscount]: {
      id: DiscountScheduleStandardOptions.NoDiscount,
      name: 'No Discount',
      discountType: DiscountScheduleTypes.NoDiscount,
      options: {},
    },
    [DiscountScheduleStandardOptions.PercentSavePerCustomer1]: {
      id: DiscountScheduleStandardOptions.PercentSavePerCustomer1,
      name: 'Percent Saved Per Customer - 0% / 5% / 10%',
      discountType: DiscountScheduleTypes.PercentSavePerCustomer,
      options: {
        startingAt: 0,
        discountIncrement: 5,
        maxDiscount: 10,
        startingCountOffset: 0,
      },
    },
    [DiscountScheduleStandardOptions.PercentSavePerCustomer2]: {
      id: DiscountScheduleStandardOptions.PercentSavePerCustomer2,
      name: 'Percent Saved Per Customer - 0% / 10% / 20%',
      discountType: DiscountScheduleTypes.PercentSavePerCustomer,
      options: {
        startingAt: 0,
        discountIncrement: 10,
        maxDiscount: 20,
        startingCountOffset: 0,
      },
    },
    [DiscountScheduleStandardOptions.DollarSavePerCustomer1]: {
      id: DiscountScheduleStandardOptions.DollarSavePerCustomer1,
      name: 'Dollar Saved Per Customer - $0 / $10 / $20',
      discountType: DiscountScheduleTypes.DollarSavePerCustomer,
      options: {
        startingAt: 0,
        discountIncrement: 10,
        maxDiscount: 20,
        startingCountOffset: 0,
      },
    },
    [DiscountScheduleStandardOptions.DollarSavePerCustomer2]: {
      id: DiscountScheduleStandardOptions.DollarSavePerCustomer2,
      name: 'Dollar Saved Per Customer - $0 / $20 / $40',
      discountType: DiscountScheduleTypes.DollarSavePerCustomer,
      options: {
        startingAt: 0,
        discountIncrement: 20,
        maxDiscount: 40,
        startingCountOffset: 0,
      },
    },
  };

  static list = Object.values(DiscountScheduleStandardOptions.standardOptionsMap);
}

export interface IDiscountScheduleDetails{
  discountIncrement: number;
  maxDiscount: number;
  startingAt: number;
  startingCountOffset: number;
}
export interface IPricingSchedule {
  decrement: number;
  priceFloor: number;
  startingAt: number;
  startingCountOffset: number;
}
export interface IFlatRateSchedule {
  dollarsOff: number;
  percentOff: number;
}
export interface IDiscountSchedule {
  id: string;
  name: string;
  priceAmount?: number;
  unitBasedPricingFlag?: boolean;
  hasPrice?: boolean;
  noPriceTextField?: string;
  priceLabel?: string;
  priceSubText?:string;
  priceSuffix?: string;
  discountSchedule?: IDiscountScheduleDetails;
  pricingSchedule?: IPricingSchedule;
  flatRateSchedule?: IFlatRateSchedule;
  type?:string;
  providerId:string;
  serviceTypeId:string;
}
export interface IDiscountScheduleWithStandardOption extends IDiscountSchedule {
  standardOption?: string;
}