import { findServiceTypeInfo } from 'api/serviceCategoryApi';
import { useLoading } from 'components/Layout/Loading';
import { createModuleStore } from 'hooks/hookStore';
import { IServiceCategory, IServiceTypeInfo, ManageServiceTypeMode } from 'model/serviceCategory';
import { IServiceType } from 'model/serviceType';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const loadingKey = 'ServiceTypeInfoStore';
type ServiceTypeInfoStore = {
  serviceCategories:IServiceCategory[];
  serviceTypes: IServiceType[];
  selectedTab: number;
}

type ServiceTypeInfoModuleSelf = {
  loaded:boolean;
  serviceTypeInfo: IServiceTypeInfo | null;
}

const {
  get,
  getModuleScoped,
  update,
  registerListener,
  unregisterListener,
} = createModuleStore<ServiceTypeInfoStore, ServiceTypeInfoModuleSelf>('ServiceTypeInfoStore',
  {
    selectedTab: 0,
    serviceCategories: [],
    serviceTypes: [],

  },
  {
    loaded: false,
    serviceTypeInfo: null,
  });

export default function useServiceTypeInfo() {
  const setState = useState(get())[1];
  const navigate = useNavigate();
  const { onLoading, doneLoading } = useLoading(loadingKey);

  useEffect(() => {
    registerListener(setState);
    return () => {
      unregisterListener(setState);
    };
  }, []);

  async function initTable() {
    var initialSelectedTab = localStorage.getItem('service_type_info_selected_tab');
    update({
      ...get(),
      selectedTab: initialSelectedTab ? parseInt(initialSelectedTab) : 0,
    });
  }

  //init details
  async function init() {
    onLoading();
    const moduleScope = getModuleScoped();
    if (!moduleScope.loaded) {
      await retrieveServiceTypeInfo();
    }
    if (moduleScope.serviceTypeInfo !== null) {
      const serviceCategories = moduleScope.serviceTypeInfo.serviceCategories;
      const serviceTypes = moduleScope.serviceTypeInfo.serviceTypes.filter(x => !x.deleteDate);
      update({
        ...get(),
        serviceCategories: serviceCategories,
        serviceTypes: serviceTypes,
      });
    }

    doneLoading(300);
  }

  async function retrieveServiceTypeInfo() {
    const moduleScope = getModuleScoped();
    const serviceTypeInfoRes = await findServiceTypeInfo();
    if (serviceTypeInfoRes.data) {
      moduleScope.loaded = true;
      moduleScope.serviceTypeInfo = serviceTypeInfoRes.data;
    }
  }

  async function onParentDispose() {
    const moduleScope = getModuleScoped();
    moduleScope.loaded = false;
  }

  function addNewServiceCategory(e: any) {

    navigate('/serviceCategoriesV2/unknown/service-category-details/create');
  }

  function addNewServiceType(e: any) {
    navigate('/serviceTypes/unknown/service-type-details/create');
  }

  function selectTab(index: number) {
    localStorage.setItem('service_type_info_selected_tab', index.toString());
    update({
      ...get(),
      selectedTab: index,
    });
  }

  return {
    ...get(),
    init,
    retrieveServiceTypeInfo,
    onParentDispose,
    addNewServiceCategory,
    addNewServiceType,
    initTable,
    selectTab,
  };
}