import ProviderSummary from 'pages/providerSetup/summary/ProviderSummary';
import ServiceOfferingsSummary from './ServiceOfferingsSummary';
import styles from '../providerOnboarding.module.css';
import { Card, Divider, Grid, Typography } from '@mui/material';
import { PrimaryButton } from 'components/buttons';
import useProviderSetupStore from '../useProviderSetupStore';
import { IServiceOffering } from 'model/serviceOffering';
import { IDiscountSchedule } from 'model/discountSchedule';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IInternalServiceProvider, IServiceProvider, StatusType } from 'model/serviceProvider';
import { saveServiceProvider } from 'api/serviceProviderApi';
import useToast from 'components/toast/useToast';
import useModal from 'components/modal/useModal';
import ApproveForSapModal, { createEmptyApproveForSapModal, createInitialApproveForSapModal, modalStoreKey } from './ApproveForSapModal';
import useSelf from 'hooks/useSelf';
import { ROLE_ADMIN_DEFAULT, ROLE_ADMIN_USER, ROLE_ADMIN_SUPPLY_MANAGER } from 'model/roles';
import useMarkets from 'hooks/useMarkets';

export default function OnboardingSummary() {
  const {
    init,
    provider,
    setCurrentDiscountSchedule,
    setCurrentServiceOffering,
    setServiceType,
    setProvider,
    setServiceOfferingCloneOptions,
  } = useProviderSetupStore();
  const { market } = useMarkets();

  const { providerId } = useParams();
  const navigate = useNavigate();
  const { createErrorToast, createSuccessToast } = useToast();
  const { openModal } = useModal(modalStoreKey);
  const { roles } = useSelf();
  const hasDefaultAdminRole = roles.indexOf(ROLE_ADMIN_DEFAULT) > -1;

  useEffect(() => {
    void init(market);
  }, [providerId, market]);

  const onCreateAnotherServiceOfferingClick = async () => {
    try {
      await setCurrentServiceOffering({} as IServiceOffering);
      await setCurrentDiscountSchedule({} as IDiscountSchedule);
      await setServiceType('');
      await setServiceOfferingCloneOptions([]);
      return navigate(`/providerOnboarding/${providerId}/service-offerings`);
    } catch (error) {
      console.error('error attempting to create a new service offering: ', { error });
    }
  };

  const onCreateAnotherProviderClick = async () => {
    try {
      await setCurrentServiceOffering({} as IServiceOffering);
      await setCurrentDiscountSchedule({} as IDiscountSchedule);
      await setProvider({} as IServiceProvider);
      await setServiceType('');
      return navigate('/providerOnboarding');
    } catch (error) {
      console.error('error attempting to create a new service offering: ', { error });
    }
  };

  const onSubmitForApprovalClick = async () => {
    var newProvider = { ...provider, status: StatusType.READY_FOR_APPROVAL } as IInternalServiceProvider;
    try {
      await saveServiceProvider(newProvider);
      createSuccessToast('Successfully submitted for approval!');
    } catch (error) {
      console.error('error attempting to submit for approval: ', { error });
      createErrorToast('Error submitting for approval');
    }
  };

  const onApproveForSapClick = () => {
    return openModal(createInitialApproveForSapModal(provider));
  };

  return (
    <Grid sx={{
      width: '80%',
    }}>
      <Card sx={{ marginTop: '50px' }}>
        <Grid container justifyContent={'center'}>
          <Typography variant="h4" sx={{ marginTop: '20px', alignSelf: 'center' }} className={styles.summarySectionTitle}>Summary</Typography>
        </Grid>
        <ProviderSummary />
        <ServiceOfferingsSummary />
        <Grid container item spacing={1} sx={{ justifyContent: 'center', alignItems: 'center', marginTop: '30px', marginBottom: '50px' }}>
          <Grid item>
            <PrimaryButton onClick={onCreateAnotherServiceOfferingClick}>Create another Service Offering</PrimaryButton>
          </Grid>
          <Grid item>
            <PrimaryButton onClick={onCreateAnotherProviderClick}>Create another Provider</PrimaryButton>
          </Grid>
        </Grid>
        <Grid container item sx={{ justifyContent: 'center', alignItems: 'center', marginBottom: '50px' }}>
          { provider.status === StatusType.DRAFT &&
              <PrimaryButton onClick={onSubmitForApprovalClick} className={styles.submitForApprovalButton}>
                Submit For Approval
              </PrimaryButton>
          }
          { provider.status === StatusType.READY_FOR_APPROVAL && hasDefaultAdminRole &&
              <><PrimaryButton onClick={onApproveForSapClick} className={styles.submitForApprovalButton}>
              Approve for SAP
              </PrimaryButton><ApproveForSapModal serviceProvider={provider} /></>
          }
        </Grid>
      </Card>
    </Grid>
  );
}