import { Button } from '@mui/material';
import useConfirmationModal from 'components/modals/useConfirmationModal';
import { IProviderCoverageAreaLaunchToolingForm, INeighborhoodLaunchForm } from 'model/neighborhoodLaunch';
import { FieldValues, SubmitErrorHandler, SubmitHandler, useFormContext } from 'react-hook-form';
import { ILaunchProviderValidationResult } from '../NeighborhoodLaunchToolingForm';
import { reconcileNsosByServiceProviderModalKey } from '../ProviderCoverageAreaLaunchToolingForm';

export interface IReconcileButtonProps {
  dryRun: boolean;
  text:string;
}

export default function ReconcileButton({ dryRun, text }:IReconcileButtonProps) {
  const { handleSubmit, setError, clearErrors, formState: { isSubmitting, isValid }, getValues, setValue } = useFormContext();
  const { openModal } = useConfirmationModal(reconcileNsosByServiceProviderModalKey);

  function validate(data:IProviderCoverageAreaLaunchToolingForm):ILaunchProviderValidationResult {
    const result = { isValid: true };
    const { serviceProviderId } = data;
    if (serviceProviderId === null) {
      setError('serviceProviderId', {
        type: 'manual',
        message: 'Must select a service provider',
      });
      result.isValid = false;
    } else {
      clearErrors('serviceProviderId');
    }
    return result;
  }

  const onSubmit : SubmitHandler<IProviderCoverageAreaLaunchToolingForm> = data => {
    const { isValid: _isValid } = validate(data);
    if (_isValid) {
      setValue('dryRun', dryRun);
      openModal({ ...data, dryRun: dryRun });
    }
  };
  const onSubmitError: SubmitErrorHandler<FieldValues> = data => {
    const { isValid: _isValid } = validate(getValues() as IProviderCoverageAreaLaunchToolingForm);
    if (_isValid) {
      setValue('dryRun', dryRun);
      openModal({ ...getValues(), dryRun: dryRun });
    }
  };

  return (
    <Button
      disabled={isSubmitting || !isValid}
      variant='contained'
      type='button'
      onClick={(e) => {
        // TODO: figure out why this onSubmit gives me inconsistent ts errors
        // @ts-expect-error
        void handleSubmit(onSubmit, onSubmitError)(e);
      }}
    >
      {text}
    </Button>
  );
}