export interface ISendNeighborConnectionInviteForConsumerRequest {
  isTest: boolean;
  connectionId: string;
  recepientEmail: string;
}

export interface ISendNeighborConnectionInviteForConsumerResponse {
  wasSuccess: boolean;
  inviteType?: string | null;
}

export interface IRetrieveNeighborConnectionsRequest {
  startDate: string | null | undefined;
  endDate: string | null | undefined;
  status: string | null | undefined;
}

export interface IRetrieveNeighborConnectionsResponse {
  neighborConnections: INeighborConnectionAdminDTO[];
}

export interface INeighborConnectionAdminDTO {
  connectionId: string;
  inviterFirstName?: string;
  inviterLastName?: string;
  inviterEmail?: string;
  connectionDate?: string;
  inviteeFirstName?: string;
  inviteeLastName?: string;
  address?: string;
  city?: string;
  state?: string;
  zip?: string;
  connectionStatus?: string;
  contactEmail?: string;
  noEmailAvailable?: boolean;
  dataQualityTag?: string;
}

export interface INeighborConnectionBatchJobRequest {
  connectionIds: string[];
  dryRun: boolean;
}

export interface IBatchSendNeighborConnectionInvitesRequest extends INeighborConnectionBatchJobRequest {
  testEmail?: string | null;
}

export interface INeighborConnectionBatchJobResponse {
  processedCount: number;
}

export interface INeighborConnectionSetQualityTagRequest {
  connectionIds: string[];
  dryRun: boolean;
  qualityTag: string;
}

export interface IPatchNeighborConnectionRequest {
  connectionFirstName?: string | null;
  connectionLastName?: string | null;
}

export interface IPatchNeighborConnectionResponse {
  connectionFirstName?: string | null;
  connectionLastName?: string | null;
  updated: boolean;
}

export class NeighborConnectionStatus {
  static get PENDING_INVITE():string {
    return 'PENDING_INVITE';
  }
  static get SENT():string {
    return 'SENT';
  }
  static get RECEIVED():string {
    return 'RECEIVED';
  }
  static get ACCEPTED():string {
    return 'ACCEPTED';
  }
  static get REJECTED():string {
    return 'REJECTED';
  }
  static get SKIPPED():string {
    return 'SKIPPED';
  }

  static list = [
    { id: NeighborConnectionStatus.PENDING_INVITE, name: 'Pending Invite' },
    { id: NeighborConnectionStatus.SENT, name: 'Sent' },
    { id: NeighborConnectionStatus.RECEIVED, name: 'Received' },
    { id: NeighborConnectionStatus.ACCEPTED, name: 'Accepted' },
    { id: NeighborConnectionStatus.REJECTED, name: 'Rejected' },
    { id: NeighborConnectionStatus.SKIPPED, name: 'Skipped' },

  ];
}

export class NeighborConnectionQualityTag {
  static get SPOUSE():string {
    return 'SPOUSE';
  }
  static get LIKELY_MATCH():string {
    return 'LIKELY_MATCH';
  }
  static get UNLIKELY_MATCH():string {
    return 'UNLIKELY_MATCH';
  }
  static get UNKNOWN():string {
    return 'UNKNOWN';
  }

  static list = [
    { id: NeighborConnectionQualityTag.SPOUSE, name: 'Spouse' },
    { id: NeighborConnectionQualityTag.LIKELY_MATCH, name: 'Likely Match' },
    { id: NeighborConnectionQualityTag.UNLIKELY_MATCH, name: 'Unlikely Match' },
    { id: NeighborConnectionQualityTag.UNKNOWN, name: 'Unknown' },
  ];
}