import EventIcon from '@mui/icons-material/Event';
import { IconButton, IconButtonProps, Tooltip, TooltipProps } from '@mui/material';

interface IEventButtonProps {
  tooltipTitle:string;
  onClick:any;
  className?:string;
  disabled?:boolean;
  iconButtonProps?:any;
  tooltipProps?:any;
}
export default function EventButton({ tooltipTitle, onClick, className, disabled, tooltipProps, iconButtonProps }:IEventButtonProps) {
  return (
    <Tooltip title={tooltipTitle} className={className} {...tooltipProps}>
      <IconButton onClick={onClick} disabled={disabled} {...iconButtonProps}>
        <EventIcon/>
      </IconButton>
    </Tooltip>
  );
}