import { Grid, Button } from '@mui/material';
import useConfirmationModal from 'components/modals/useConfirmationModal';
import { HookPriceField, HookTextField } from 'components/reactHookForm';
import { CompensateRewardRequest } from 'model/rewards';
import useIndividualsHookStore from 'pages/individuals/individualsHookStore';
import { useEffect } from 'react';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import useWelcomePageShortLink from './welcomePageShortLinkHookStore';
import { IGenerateWelcomePageLinkForm } from 'model/neighborhoods/generateWelcomePageLinkForm';
import PreviewFullLink from './PreviewFullLink';
import GeneratedShortLink from './GeneratedShortLink';
import useToast from 'components/toast/useToast';


export interface IGenerateWelcomePageLinkFormProps {
  initialFormData: IGenerateWelcomePageLinkForm;
  onConfirm: SubmitHandler<IGenerateWelcomePageLinkForm>;
  onCancel:any;
}

export interface IGenerateWelcomePageLinkFormInternalProps {
  initialFormData: IGenerateWelcomePageLinkForm;
  onConfirm: SubmitHandler<IGenerateWelcomePageLinkForm>;
  onCancel:any;
}

function GenerateWelcomePageLinkFormInternal({ initialFormData, onConfirm, onCancel }:IGenerateWelcomePageLinkFormInternalProps) {
  const formContext = useForm<IGenerateWelcomePageLinkForm>({ mode: 'onTouched', defaultValues: initialFormData });
  const { isSubmitting, isValid } = formContext.formState;
  const { formData, shortLinkResponse } = useWelcomePageShortLink();
  const { createInfoToast } = useToast();

  useEffect(() => {
    formContext.reset(initialFormData);
  }, [initialFormData]);

  return (
    <FormProvider {...formContext}>
      <form onSubmit={formContext.handleSubmit(onConfirm)} style={{ width: '100%' }}>
        <Grid container spacing={1}>
          <Grid container item xs={12} columnGap={2} alignItems='center' sx={{ overflow: 'auto' }}>
            <Grid item xs={12} >
              <div style={{ fontSize: '12px' }}> {`Neighborhood ID: ${formData?.neighborhoodId}`} </div>
              <div style={{ fontSize: '12px' }}> {`Neighborhood Name: ${formData?.neighborhoodName}`} </div>
            </Grid>
            {/* <Grid item xs={4} >
              <HookTextField
                name='fbp'
                label='Facebook Post ID'
              />
            </Grid> */}
            <Grid item xs={4}>
              <HookTextField
                name='mc'
                label='Marketing Code'
              />
            </Grid>
          </Grid>

          <Grid container item xs={12}>
            <PreviewFullLink/>
          </Grid>

          <Grid container item xs={12} sx={{ marginTop: '20px;', marginBottom: '20px' }}>
            <GeneratedShortLink/>
          </Grid>

          <Grid container item xs={12} justifyContent={'space-between'} >
            <Grid item xs='auto'>
              {shortLinkResponse?.shortLink != null && (
                <Button
                  variant='contained'
                  onClick={() => {
                    navigator.clipboard.writeText(shortLinkResponse?.shortLink!).catch(reason => console.log(reason));
                    createInfoToast('Short link copied to clipboard');
                    onCancel();
                  }}
                >
                  Copy &amp; close
                </Button>
              )}
            </Grid>
            <Grid container item xs='auto'>
              <Button
                variant='text'
                disabled={false}
                onClick={() => {
                  formContext.reset(initialFormData);
                  onCancel();
                }}
                sx={{ paddingRight: '10px' }}
              >
                Cancel
              </Button>
              <Button
                disabled={isSubmitting || (formData?.neighborhoodId == null || formData?.neighborhoodId == '') || !isValid}
                variant='contained'
                type='submit'
              >
                Generate
              </Button>

            </Grid>


          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
}

export default function GenerateWelcomePageLinkForm({
  initialFormData,
  onConfirm,
  onCancel,
}: IGenerateWelcomePageLinkFormProps) {
  return (
    <GenerateWelcomePageLinkFormInternal
      initialFormData={initialFormData}
      onConfirm={onConfirm}
      onCancel={onCancel}
    />
  );
}