import { Grid, Alert } from '@mui/material';
import { saveServiceCategoryTypeImage } from 'api/serviceCategoryApi';
import { useLoading } from 'components/Layout/Loading';
import { IServiceTypeDetailForm } from 'model/serviceType';
import { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useFormContext, useWatch } from 'react-hook-form';

import styles from '../serviceCategoryTypeDetails.module.css';
import useServiceTypeDetail from '../../hookStore/useServiceTypeDetail';

export function ServiceTypeImageDropzone() {
  const [errorMessage, setErrorMessage] = useState('');
  const { loadingKey } = useServiceTypeDetail();
  const { onLoading, doneLoading } = useLoading(loadingKey);
  const ctx = useFormContext<IServiceTypeDetailForm>();

  const watched_serviceType = useWatch({ control: ctx.control, name: 'name' });
  const watched_serviceTypeColorScheme = useWatch({ control: ctx.control, name: 'colorScheme' });
  const onDrop = useCallback(async acceptedFiles => {
    var formData = new FormData();
    if (acceptedFiles.filter(x => x.type === 'image/webp').length > 0) {
      setErrorMessage('WebP format is not supported. Please upload a PNG, JPEG, or SVG file.');
      return;
    } else {
      setErrorMessage('');
    }
    formData.append('image', acceptedFiles[0]);
    let existingImageUrlGuid = '';
    try {
      existingImageUrlGuid = ctx.getValues('imageGuid');
    } catch (e:any) {
      console.error(e);
    }

    try {
      try {
        onLoading();
        const response = await saveServiceCategoryTypeImage(formData);
        const imageUrlGuid = response.data;
        ctx.setValue('imageGuid', imageUrlGuid);
      } catch (e:any) {
        console.error(e);
      } finally {
        doneLoading();
      }
    } catch (e:any) {

      setErrorMessage(e.response.data);
    }

  }, [

    watched_serviceType,
    watched_serviceTypeColorScheme,
  ]);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <Grid container justifyContent='center' className={styles.serviceTypeDropzoneWrapper}>
      <Grid item xs>
        <div {...getRootProps()} className={styles.dropzoneWrapper}>
          <input {...getInputProps()} accept='.png,.jpeg,.svg'/>
          {
            isDragActive ?
              <p>Drop the files here ...</p> :
              <p>Drag and drop a file or click here to select a file</p>
          }

        </div>
      </Grid>
      <Grid item xs={12}>
        {errorMessage && (
          <Alert
            severity='error'
            className={styles.uploadErrorMessage}
            variant='outlined'
          >
            {errorMessage}
          </Alert>
        )}
      </Grid>
    </Grid>
  );
}