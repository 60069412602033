import { Grid, Typography } from '@mui/material';
import { useFormContext, useWatch } from 'react-hook-form';
import styles from './serviceTypeCard.module.css';

export default function ServiceTypeImage() {
  const formCtx = useFormContext();
  const watchedServiceTypeName = useWatch({ control: formCtx.control, name: 'name' });
  const watchedImage = useWatch({ control: formCtx.control, name: 'imageGuid' });
  const watchedColorScheme = useWatch({ control: formCtx.control, name: 'colorScheme' });

  function getBackground(colorScheme) {
    switch (colorScheme) {
      case 'bluegrey':
        return 'linear-gradient(180deg, #486C9C 0%, #598AAB 100%)';
      case 'red':
        return 'linear-gradient(180deg, #861920 0%, #9B1B23 100%)';
      case 'brown':
        return 'linear-gradient(180deg, #713A1A 0%, #86441E 100%)';
      case 'purple':
        return 'linear-gradient(180deg, #4657BB 0%, #4B5DCA 100%)';
      case 'orange':
        return 'linear-gradient(180deg, #CE6300 0%, #C96101 100%)';
      case 'blue':
        return 'linear-gradient(180deg, #4372CB 0%, #2A5BB9 100%)';
      default:
        return '#3a403d';
    }
  }

  return (
    <Grid
      container
      className={styles.cardContainerDesktop}
    >
      <Grid item xs={12} className={styles.cardTopContainer} style={{ background: getBackground(watchedColorScheme) }}>
        {watchedImage && <img src={`${window.REACT_APP_SERVICE_CATEGORY_TYPE_IMAGE_URI}${watchedImage}`} alt='No image found' className={styles.cardTopImage}/>}
        {!watchedImage && <div className={`${styles.cardTopImage} ${styles.noImage}`}>No image set</div>}
      </Grid>
      <Grid container item xs={12} className={styles.cardBottomContainer} style={{ background: getBackground(watchedColorScheme) }} justifyContent="flex-start">
        <Grid item className={styles.cardTitle}>
          <Typography variant="h5" color='#fff'>{watchedServiceTypeName}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}