
import { Button, Checkbox, FormControl, FormLabel, Grid, Typography } from '@mui/material';
import { IServiceOfferingImageRef } from 'model/serviceOffering';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

export interface IExistingImage {
  unmarkForDelete: (x:IServiceOfferingImageRef, formCtx:any) => void;
  markForDelete: (x:IServiceOfferingImageRef, formCtx:any) => void;
  imageWrapper: IServiceOfferingImageRef;
}

export default function ExistingImage({ unmarkForDelete, markForDelete, imageWrapper }:IExistingImage) {
  const [markedForDelete, setMarkedForDelete] = useState<boolean>(false);
  const [hardDelete, setHardDelete] = useState<boolean>(false);
  const formCtx = useFormContext();
  function onClick() {
    if (markedForDelete) {
      setMarkedForDelete(false);
      unmarkForDelete(imageWrapper, formCtx);
    } else {
      setMarkedForDelete(true);
      markForDelete(imageWrapper, formCtx);
    }
  }
  return (
    <Grid container item xs='auto' alignItems='center' sx={{ border: '1px solid gainsboro', padding: '4px', margin: '2px' }}>
      <img
        src={`${window.REACT_APP_SERVICE_OFFERING_IMAGE_URI}${imageWrapper.thumbnailImageFileName}`}
        width={200}
      />
      <Grid container item xs='auto' justifyContent={'center'} sx={{ paddingLeft: '8px', paddingRight: '8px' }}>
        <Button type='button' variant='outlined' onClick={onClick}>{markedForDelete ? 'Undelete' : 'Delete'}</Button>
      </Grid>
    </Grid>
  );
}