import { TextField, TextFieldProps } from '@mui/material';
import useErrorState from 'hooks/reactHookForm/useErrorState';
import { Controller, useFormContext } from 'react-hook-form';

export interface IHookTextFieldProps {
  name:string;
  label:string;
  required?:boolean | any;
  maxLength?: string | number;
  validationRules?: any;
  textFieldProps?:any;
  postOnChange?: Function;
}

export default function HookTextField({ name, label, required, maxLength, validationRules, postOnChange, defaultValue = '', ...textFieldProps }:IHookTextFieldProps | TextFieldProps |any) {
  const methods = useFormContext();
  const { control } = methods;
  const { isError, getErrorMessage } = useErrorState(methods);
  const rules:any = {
    required: {
      value: required || false, message: 'Required',
    },
    maxLength: {
      value: maxLength || 0, message: `Must be ${maxLength} characters or less`,
    },
    ...validationRules,
  };
  if (!maxLength) {
    delete rules.maxLength;
  }
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({
        field: { onChange, onBlur, value, ref },
        fieldState: { invalid, isTouched, isDirty, error },
      }) => {
        let _value = '';
        if (value !== null && value !== undefined) {
          _value = value;
        }
        return (
          <TextField
            fullWidth
            id={`${name}-input`}
            variant='standard'
            label={label}
            inputRef={ref}
            error={isError(name)}
            helperText={(isError(name) && getErrorMessage(name)) || ' '}
            value={_value || ''}
            onChange={(e) => {
              onChange(e);
              if (postOnChange) {
                // note that if you are using a postOnChange to call the RHF trigger function for cross field validation
                // that useWatch() is useless and you need to use getValues() within the validate method(s) that is/are triggered
                postOnChange(e);
              }
            }}
            onBlur={onBlur}
            {...textFieldProps}
          />
        );
      }
      }
    />
  );
}