
import { EnvelopeType } from 'model/letter_batch/ancillary';
import EnumSelect from './EnumSelect';
import { Grid } from '@mui/material';

export default function LaunchTypeSelect() {

  return (
    <Grid item xs={12}>
      <EnumSelect
        label='Envelope Type'
        enabled={false}
        fieldName='envelopeType'
        list={EnvelopeType.list}
      />
    </Grid>
  );

}