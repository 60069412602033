import { Grid, Tooltip, Typography } from '@mui/material';
import { HookCheckbox, HookSwitch } from 'components/reactHookForm';
import HookDateRangePicker from 'components/reactHookForm/HookDateRangePicker';
import styles from './neighborhoodDetail.module.css';
import InfoIcon from '@mui/icons-material/Info';
import PageSubsectionHeader from 'components/SectionHeaders/PageSubsectionHeader';
import TooltipDrawerButton from 'components/drawer/TooltipDrawerButton';
import MiningDrawer, { neighborhoodDetailsMiningDrawerTooltipKey } from './tooltip_drawers/mining/MiningDrawer';


export default function MiningInputs() {
  return <>
    <Grid container direction='row' justifyContent={'flex-start'}>
      <Grid container item xs={12} alignItems='center' >
        <Grid item xs='auto'>
          <PageSubsectionHeader text={'Mining'}/>
        </Grid>
        <Grid item xs='auto'>
          <TooltipDrawerButton storeKey={neighborhoodDetailsMiningDrawerTooltipKey} />
        </Grid>
        <MiningDrawer/>
      </Grid>
      <Grid container item xs={12} md={4} lg={2} alignItems='center'>
        <Grid item paddingLeft='15px'>
          <HookSwitch
            name='hideLaunchLeaderOffer'
            label='Hide LL Offer'
          />
        </Grid>
        <Grid item >
          <Tooltip title='If toggled on (i.e. toggled to the right), the Launch Leader step of the consumer onboarding flow will NOT be shown to users'>
            <InfoIcon className={styles.infoIcon}/>
          </Tooltip>
        </Grid>
      </Grid>
      <Grid container item xs={12} md={8} lg={3} alignItems='center'>
        <Grid item paddingLeft='15px'>
          <HookSwitch
            name='hideFbRequestOffer'
            label='Hide Facebook RTP Offer'
          />
        </Grid>
        <Grid item xs='auto'>
          <Tooltip title='If toggled on (i.e. toggled to the right), the "Request to Post" step of the consumer onboarding flow will NOT be shown to users'>
            <InfoIcon className={styles.infoIcon}/>
          </Tooltip>
        </Grid>
      </Grid>
      <Grid container item xs={12} md={4} lg={2} alignItems='center'>
        <Grid item paddingLeft='15px'>
          <HookSwitch
            name='recruitingLaunchLeader'
            label='Actively Mining'
          />
        </Grid>
        <Grid item >
          <Tooltip title='If toggled on (i.e. toggled to the right), this indicates that we are actively "mining" this neighborhood for a Neighborhood Lead.'>
            <InfoIcon className={styles.infoIcon}/>
          </Tooltip>
        </Grid>
      </Grid>
      <Grid item xs='auto' sx={{ paddingLeft: '20px' }}>
        <HookDateRangePicker
          labelStart='Mining Date Range'
          labelEnd={''}
          name='miningDateRange'
          textFieldProps={{
            fullWidth: true,
            variant: 'standard',
          }}
        />
      </Grid>
    </Grid>
    <Grid container direction='row' justifyContent={'flex-start'}>
      <Grid container item xs={12} justifyContent={'flex-start'} alignItems={'center'}>
        <Grid item xs='auto'>
          <HookCheckbox
            name='unlockRequired'
            label='Neighborhood Unlock Required'
          />
        </Grid>
        <Grid item>
          <Tooltip title='When checked, all users viewing the "Welcome" page in the consumer app will be presented the "Unlock Neighborhood" flow'>
            <InfoIcon className={styles.infoIcon}/>
          </Tooltip>
        </Grid>
      </Grid>
    </Grid>
  </>;
}