import { Grid } from '@mui/material';
import { INeighborhoodServiceOffering } from 'model/neighborhoodServiceOffering';
import { useFormContext, useWatch } from 'react-hook-form';
import StepHeader from './StepHeader';
import { HookPriceField } from 'components/reactHookForm';
import HookPercentField from 'components/reactHookForm/HookPercentField';


export interface IPercentFlatRateFieldsProps {
  neighborhoodServiceOfferings:INeighborhoodServiceOffering[];
}

export default function PercentFlatRateFields() {
  return (
    <Grid container item xs={12} spacing={1}>
      <Grid item xs={12}>
        <StepHeader>Step 4: Flat Rate Schedule Details</StepHeader>
      </Grid>
      <Grid item xs={2}>
        <HookPercentField
          name='flatRateSchedule.percentOff'
          label='Percent Off'
          allowDecimalPoints={0}
          required
          validationRules={{
            validate: function(value) {
              if (value == null || value == undefined || value == '') {
                return 'Percent Off is required';
              }
              if (value === '0') {
                return 'Percent Off must be greater than 0.';
              }
              if (value > 100) {
                return 'Percent Off must be less than 100.';
              }

              return null;
            },

          }}
        />
      </Grid>
    </Grid>
  );
}