import { Alert, Button, Grid, Typography } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import QuickSearchToolbar from 'components/grid/QuickSearchToolbar';
import useTableSearch from 'components/grid/useTableSearch';
import PageHeader from 'components/SectionHeaders/PageHeader';
import { useEffect, useState } from 'react';
import WarningIcon from '@mui/icons-material/Warning';
import GenerateInvoiceModal from './GenerateInvoiceModal';
import { columnsCreator } from './columnDefinitions';
import SelectedRowsTotals from './SelectedRowsTotals';
import useInvoicing from './hookStore/useInvoicing';
import InvoiceTableFilters from './InvoiceTableFilters';
import MarkInvoiceConfirmationModal from './MarkInvoiceConfirmationModal';
import Loading from 'components/Layout/Loading';


const quickSearchStorageKey = 'serviceProviderInvoices';
export default function ServiceProviderInvoicingDetails() {

  const {
    loadingKey,
    customers,
    selectedRows,
    invoicingTableFilter,
    shouldShowInvoicedCustomers,
    nonStandardPricing,
    init,
    refreshTableData,
    handleSelectionModelChange,
    onButtonClick,
    onGetCustomerInvoice,
    onGenerateInvoice,
  } = useInvoicing();

  const { rows, searchText, setRowsWithStoredFilter, requestSearch, clearStoredSearch } = useTableSearch(quickSearchStorageKey);
  const [isInitializing, setIsInitializing] = useState<boolean>(true);


  const [sortModel, setSortModel] = useState([
    {
      field: 'name',
      sort: 'asc' as any,
    },
  ]);


  useEffect(() => {

    void init();
  }, []);

  useEffect(() => {
    setRowsWithStoredFilter(customers);
    return () => {
      setRowsWithStoredFilter([]);
    };
  }, [customers]);

  useEffect(() => {
    if (isInitializing) {
      setIsInitializing(false);
      return;
    }
    void refreshTableData();
  }, [invoicingTableFilter]);

  const columns = columnsCreator(onGetCustomerInvoice);
  const columnTotalWidth = columns.map(x => x.width).reduce((a:any, b:any) => a+b, 0);

  return (
    <Loading loadingKey={loadingKey} size={100}>
      <Grid container spacing={1} className='pageGridContainer'>
        <Grid item xs={12}>
          <PageHeader headerText={'INVOICING'} />
        </Grid>
        <Grid container item xs={12} justifyItems={'space-between'} alignItems={'center'}>
          <Grid item xs='auto'>
            <Alert severity='info' >
              <Typography variant='caption'>Query for customers without an invoice by <b>service date</b> and with an invoice by <b>invoice date</b>.</Typography>
            </Alert>
          </Grid>
          <Grid item xs></Grid>

        </Grid>
        <InvoiceTableFilters/>
        <Grid item xs='auto'>
          {shouldShowInvoicedCustomers &&
          <Button
            variant="contained"
            disabled={selectedRows?.length === 0}
            onClick={onButtonClick}>Mark as NOT Invoiced</Button>
          }
          {!shouldShowInvoicedCustomers &&
          <Button
            variant="contained"
            disabled={selectedRows?.length === 0}
            onClick={onButtonClick}>Mark as Invoiced</Button>
          }
        </Grid>
        {nonStandardPricing &&
          <Grid container item xs='auto'>
            <Grid item xs='auto'>
              <WarningIcon fontSize='large' style={{ color: 'red' }}/>
            </Grid>
            <Grid item xs='auto'>
              <Typography style={{ color: 'red', marginTop: '5px', marginLeft: '5px' }} component='div'>Non standard pricing!!</Typography>
            </Grid>
          </Grid>
        }
        <Grid item xs={12}>
          <DataGridPro
            checkboxSelection
            selectionModel={selectedRows.map(x => x.id)}
            onSelectionModelChange={(selectionModel) => handleSelectionModelChange(rows, selectionModel)}
            rows={rows}
            components={{ Toolbar: QuickSearchToolbar }}
            componentsProps={{
              toolbar: {
                quickSearchStorageKey,
                value: searchText,
                onChange: (event: React.ChangeEvent<HTMLInputElement>) => requestSearch(customers, event.target.value),
                clearSearch: () => requestSearch(customers, ''),
              },
            }}
            columns={columns}
            sortModel={sortModel}
            onSortModelChange={setSortModel}
            autoHeight={true}
            style={{
              minWidth: '500px',
              maxWidth: `${columnTotalWidth}px`,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <SelectedRowsTotals selectedRows={selectedRows}/>
        </Grid>
        <MarkInvoiceConfirmationModal/>
        <GenerateInvoiceModal/>
      </Grid>
    </Loading>
  );
}