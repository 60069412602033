import { HookCheckbox } from 'components/reactHookForm';
import useOneTimeNewsletter from '../oneTimeNewsletterHookStore';
import { Grid, Typography } from '@mui/material';
import { useFormContext, useWatch } from 'react-hook-form';

export default function AvailableNeighborhoods() {
  const formCtx = useFormContext();
  const neighborhoods = useWatch({ control: formCtx.control, name: 'neighborhoods' });

  if (neighborhoods.length === 0) {
    return <Typography>No neighborhoods available</Typography>;
  }
  return (
    <Grid container>
      {
        neighborhoods.map((neighborhood, index) => (

          <Grid item xs={'auto'} key={neighborhood.neighborhoodId}>
            <HookCheckbox
              name={`neighborhoods.${index}.selected`}
              label={(
                <Typography
                  variant='body1'
                  sx={{ fontSize: '13px' }}
                >
                  <>{neighborhood.name} ({neighborhood.status})</>
                </Typography>
              )}
            />
          </Grid>
        ))
      }
    </Grid>
  );
}