import { Box, Grid, IconButton, List, Typography } from '@mui/material';
import Loading from 'components/Layout/Loading';
import PageHeader from 'components/SectionHeaders/PageHeader';
import { useEffect } from 'react';
import TooltipDrawerButton from 'components/drawer/TooltipDrawerButton';
import useSelf from 'hooks/useSelf';
import { ROLE_ADMIN_DEFAULT } from 'model/roles';
import useOneTimeNewsletter from './oneTimeNewsletterHookStore';
import OneTimeNewsletterForm from './OneTimeNewsletterForm';
import useMarkets from 'hooks/useMarkets';

export default function OneTimeNewsletter() {
  const { loadingKey, init } = useOneTimeNewsletter();
  const { selectedMarket } = useMarkets();


  useEffect(() => {
    void init(selectedMarket);
  }, []);

  return (

    <Loading loadingKey={loadingKey} size={100}>
      <Grid container spacing={1} className='pageGridContainer' justifyContent='start' alignItems={'center'}>
        <Grid item xs={12}>
          <PageHeader
            headerText={'One Time Newsletter'}
          />
        </Grid>
        <Grid item xs={12} sx={{ marginTop: 1 }}>
          <OneTimeNewsletterForm/>
        </Grid>
      </Grid>
    </Loading>
  );
}