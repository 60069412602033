import { Grid, TextField, Typography } from '@mui/material';
import GenericModal from 'components/modal/GenericModal';
import useModal, { IModal } from 'components/modal/useModal';
import { useNavigate } from 'react-router-dom';

export const modalStoreKey = 'DeleteTerritoryModal';

export interface IDeleteTerritoryModal {
  id: string;
  name: string;
}
export function createEmptyDeleteTerritoryModal() :IDeleteTerritoryModal {
  return {
    id: '',
    name: '',
  };
}
export interface IDeleteTerritoryModalProps {
  onConfirm: ( territoryId:string) => void;
}

export default function DeleteTerritoryModal({ onConfirm }:IDeleteTerritoryModalProps) {
  const { getModalState, updateModalState, closeModal } = useModal(modalStoreKey);
  const _state = getModalState() as IDeleteTerritoryModal & IModal;

  async function _onConfirm(state:IDeleteTerritoryModal & IModal) {
    await onConfirm(_state.id);
    closeModal(createEmptyDeleteTerritoryModal());
  }

  return (
    <GenericModal
      modalStoreKey={modalStoreKey}
      title={'Delete Territory?'}
      modalContent={
        (
          <div >
            <Typography variant='body1' >Do you want to delete territory: "{_state.name}"?</Typography>
            <Typography variant='body1'>This will soft delete the territory in the system. It will still show up in the list.</Typography>

            <Grid container>
              <Grid item xs={12}>

              </Grid>
            </Grid>
          </div>
        )
      }
      onConfirm={() => _onConfirm(_state)}
      primaryButtonText='Confirm'
    />
  );
}