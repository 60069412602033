import { Grid } from '@mui/material';
import { sendClaimYourBusinessMessage } from 'api/serviceProviderApi';
import GenericModal from 'components/modal/GenericModal';
import useModal from 'components/modal/useModal';
import NeighborhoodAutocomplete from 'components/neighborhoodAutocomplete/NeighborhoodAutocomplete';
import { HookTextField } from 'components/reactHookForm';
import useToast from 'components/toast/useToast';
import { IDropdownOption } from 'model/dropdown';
import { FormProvider, useForm } from 'react-hook-form';

export const modalStoreKey = 'SendClaimYourBusinessModal';

export interface ISendClaimYourBusinessModal {
  referringNeighborhood: IDropdownOption;
  nsoId: string;
  reference?: string;
}
export function createEmptySendClaimYourBusinessModal() :ISendClaimYourBusinessModal {
  return {
    referringNeighborhood: {} as IDropdownOption,
    nsoId: '',
    reference: '',
  };
}

export default function SendClaimYourBusinessModal({ serviceProviderId, nsoId }) {
  const { closeModal } = useModal(modalStoreKey);
  const { createSuccessToast, createErrorToast } = useToast();
  const formContext = useForm({
    mode: 'onTouched',
    defaultValues: {
      referringNeighborhood: {} as IDropdownOption,
      nsoId: nsoId,
      reference: '',
    },
  });

  const onSubmit = async (data:any) => {
    try {
      const formData = formContext.getValues();
      if (!formData.referringNeighborhood.optionValue) {
        createErrorToast('Failed to Send message');
        return;
      }
      const requestBody = {
        referringNeighborhoodId: formData.referringNeighborhood.optionValue,
        nsoId: formData.nsoId,
        reference: formData.reference,
      };
      await sendClaimYourBusinessMessage(serviceProviderId, requestBody);
      createSuccessToast('Message sent successfully!');
    } catch (error) {
      console.error(error);
      createErrorToast('Error sending message...');
    }
    closeModal(createEmptySendClaimYourBusinessModal());
  };
  return (
    <GenericModal
      modalStoreKey={modalStoreKey}
      title={'Message Options'}
      modalContent={
        (
          <div >
            <FormProvider {...formContext}>
              <form onSubmit={formContext.handleSubmit(onSubmit)}>
                <Grid container spacing={1} justifyContent='center'>
                  <Grid item xs={11}>
                    <NeighborhoodAutocomplete
                      label='Neighborhood*'
                      fieldName='referringNeighborhood'
                      placeholder='Search and check a neighborhood'
                      formless={false}
                      required={true}
                    />
                  </Grid>
                  <Grid item xs={11}>
                    <HookTextField
                      name='reference'
                      label='Reference (optional)'
                      required={false}
                    />
                  </Grid>
                  <Grid item xs={11}>
                  </Grid>
                </Grid>
              </form>
            </FormProvider>
          </div>
        )
      }
      onConfirm={onSubmit}
      onSecondaryAction={() => closeModal(createEmptySendClaimYourBusinessModal())}
      primaryButtonText='Send'
      secondaryButtonText='Cancel'
    />
  );
}