
import useConfirmationModal from 'components/modals/useConfirmationModal';
import { FieldValues, SubmitErrorHandler, SubmitHandler, useFormContext } from 'react-hook-form';
import { PrimaryButton } from 'components/buttons';
import { letterBatchNeighborhoodDetailModalKey } from '../LetterBatchNeighborhoodDetailForm';
import { ILetterBatchNeighborhoodFormDto } from 'model/letter_batch/letterBatchNeighborhood';


export default function SubmitButton() {
  const { handleSubmit, setError, clearErrors, formState: { isSubmitting, isValid, errors }, trigger, getValues } = useFormContext();
  const { openModal } = useConfirmationModal(letterBatchNeighborhoodDetailModalKey);
  const validate = (data:ILetterBatchNeighborhoodFormDto) => {
    return {
      isValid: true,
    };;
  };
  const onSubmit : SubmitHandler<ILetterBatchNeighborhoodFormDto> = data => {
    const { isValid: _isValid } = validate(data);

    if (_isValid) {
      openModal(data);
    }
  };
  const onSubmitError: SubmitErrorHandler<FieldValues> = data => {
    const { isValid: _isValid } = validate(getValues() as ILetterBatchNeighborhoodFormDto);
    if (_isValid) {
      openModal(getValues());
    }
  };
  return (
    <PrimaryButton
      disabled={isSubmitting || !isValid}
      type='button'
      onClick={async (e) => {
        const valid = await trigger();
        if (!valid) {
          return;
        }
        // TODO: figure out why this onSubmit gives me inconsistent ts errors
        // @ts-expect-error
        void handleSubmit(onSubmit, onSubmitError)(e);
      }}
    >
            Save
    </PrimaryButton>
  );
}