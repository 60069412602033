import { Grid, Button } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import QuickSearchToolbar from 'components/grid/QuickSearchToolbar';
import useTableSearch from 'components/grid/useTableSearch';
import { useEffect, useState } from 'react';
import useServiceTypeInfo from '../hookStore/useServiceTypeInfo';
import { columnTotalWidth, columns } from './colDef';


const quickSearchStorageKey = 'serviceTypes';
export default function ServiceTypes () {
  const { serviceTypes, init, addNewServiceType } = useServiceTypeInfo();
  const { rows, searchText, setRowsWithStoredFilter, requestSearch } = useTableSearch(quickSearchStorageKey);
  const [sortModel, setSortModel] = useState([
    {
      field: 'name',
      sort: 'asc' as any,
    },
  ]);

  useEffect(() => {
    void init();
  }, []);

  useEffect(() => {
    setRowsWithStoredFilter(serviceTypes);
  }, [serviceTypes]);

  return (
    <Grid container>
      <Grid item xs={12} sx={{ marginBottom: '20px' }}>
        <Button variant="contained" onClick={addNewServiceType}>Add New</Button>
      </Grid>
      <Grid item xs={12}>
        <DataGridPro
          rows={rows}
          components={{ Toolbar: QuickSearchToolbar }}
          getRowId={(row) => row.serviceTypeId}
          componentsProps={{
            toolbar: {
              quickSearchStorageKey,
              value: searchText,
              onChange: (event: React.ChangeEvent<HTMLInputElement>) => requestSearch(serviceTypes, event.target.value),
              clearSearch: () => requestSearch(serviceTypes, ''),
            },
          }}
          columns={columns}
          autoHeight={true}
          sortModel={sortModel}
          pagination
          pageSize={10}
          onSortModelChange={setSortModel}
          style={{
            minWidth: '500px',
            maxWidth: `${columnTotalWidth}px`,
          }}
        />
      </Grid>
    </Grid>
  );
}
