import { Grid, Typography } from '@mui/material';
import { toggleIgnoreForReconciliationNeighborhoodServiceOfferingsById } from 'api/neighborhoodServiceOfferingApi';
import GenericModal from 'components/modal/GenericModal';
import useModal, { IModal } from 'components/modal/useModal';

export const modalStoreKey = 'lockNsoByIdModal';
export interface ILockNsoByIdModal {
  name: string;
  id:string;
  wasEnabled:boolean;
}
export function createEmptyLockNsoByIdModal() :ILockNsoByIdModal {
  return {
    name: '',
    id: '',
    wasEnabled: false,
  };
}
export interface ILockNsoByIdModalProps {
  postOnConfirm: (neighborhoodServiceOfferingId:string, wasEnabled:boolean) => void;
}

export default function LockNsoByIdModal({ postOnConfirm }:ILockNsoByIdModalProps) {
  const { getModalState, closeModal } = useModal(modalStoreKey);
  const _state = getModalState() as ILockNsoByIdModal & IModal;
  const { name, wasEnabled } = _state;
  async function _onToggle(state:ILockNsoByIdModal & IModal) {
    if (state.wasEnabled) {
      await toggleIgnoreForReconciliationNeighborhoodServiceOfferingsById(state.id, { shouldIgnore: false });
    } else {
      await toggleIgnoreForReconciliationNeighborhoodServiceOfferingsById(state.id, { shouldIgnore: true });
    }
    postOnConfirm(state.id, !state.wasEnabled);
    closeModal(createEmptyLockNsoByIdModal());
  }

  return (
    <GenericModal
      modalStoreKey={modalStoreKey}
      title={wasEnabled ? 'Enable NSO for reconciliation ?' : 'Ignore NSO for reconciliation?'}
      modalContent={
        (
          <div >
            <Typography variant='body1' >Do you want to {wasEnabled ? 'enable for reconciliation' : 'ignore for reconciliation'} {name}?</Typography>
            {!wasEnabled && <Typography variant='body1'>If ignored then the nso reconciliation on neighborhood details/launch tooling will have no effect.</Typography>}
          </div>
        )
      }
      onConfirm={() => _onToggle(_state)}
      secondaryButtonText='Cancel'
    />
  );
}