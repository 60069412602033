import { useEffect, useState } from 'react';
import { Type } from 'typescript';

let moduleStateMap: Map<string, any> = new Map();
let stateMap: Map<string, any> = new Map();
let listenerMap:Map<string, any[]> = new Map();

export function getStore<Type>(key:string) {
  return stateMap.get(key);
}


/**
 * Used when you need mutable singleton module state which cannot be subscribed to. Useful for state that does not need to trigger rerenders or only needs to be initialized once.
 * For example, the module state can be used to prevent duplicate initialization of data by checking a boolean variable without needing to worry about asynchrony via setState
 * @param key
 * @param initialState
 * @param moduleState
 * @param disableCheckForUniqueStore
 * @returns
 */
export function createModuleStore<Type, ModuleScopeType>(key:string, initialState: Type, moduleState:ModuleScopeType, disableCheckForUniqueStore?:boolean) {
  const store = createStore(key, initialState, disableCheckForUniqueStore);
  moduleStateMap.set(key, moduleState);

  /**
   *
   * @returns the module state for the store
   */
  function getModuleScoped():ModuleScopeType {
    return moduleStateMap.get(key);
  }

  return {
    ...store,
    getModuleScoped,
  };
}

export default function createStore<Type>(key:string, initialState: Type, disableCheckForUniqueStore?:boolean) {

  if (stateMap.has(key) && !disableCheckForUniqueStore) {
    throw Error(`hookStore already has state for ${key}`);
  } else if (!disableCheckForUniqueStore) {
    stateMap.set(key, initialState);
  } else {
    if (!stateMap.has(key)) {
      stateMap.set(key, initialState);
    }
  }

  function get():Type {
    return stateMap.get(key) as Type;
  }

  function update(state:Type) {
    if (!key) {
      console.error('Must supply a loading state.');
    }
    stateMap.set(key, state);
    notifyListeners();
  }

  function notifyListeners() {
    const listeners = listenerMap.get(key);
    if (listeners) {
      for (const li of listeners) {
        li(get());
      }
    }
  }

  function registerListener(setState) {
    if (listenerMap.has(key)) {
      listenerMap.get(key)?.push(setState);
    } else {
      listenerMap.set(key, [setState]);
    }
    return () => {

    };
  }

  function unregisterListener(setState) {
    const nextListeners = listenerMap.get(key)?.filter(li => li !== setState);
    if (nextListeners) {
      listenerMap.set(key, nextListeners);
    }
  }

  return { get, update, registerListener, unregisterListener };
}
