import { extractNeighborhoodsFromMap } from 'util/neighborhoodUtil';
import { createUninitializedDropdownOption, IDropdownOption, UNSELECTED_OPTION } from './dropdown';
import { INeighborhood, NeighborhoodStatusType } from './neighborhood';
import { INeighborhoodSearchRequest } from './search';
import { searchNeighborhoods } from 'api/neighborhoodApi';

export interface IRebookServicesNSOIFilter {
  serviceProviderId:string;
  serviceOfferingId:string;
  zipCodeTerritory:string;
  hoursOffset?:number;
}

export interface IRebookServicesDto {
  serviceProviderId:string;
  serviceOfferingId:string;
  priorCustomerStartDate:string | null;
  priorCustomerEndDate:string | null;
  neighborhoodIds:string[];
  neighborhoodServiceOfferingInstanceId:string;
  overrideEmails:string[];
  sendToMeAsTest:boolean;
  targetServiceProviderId:string;
  targetServiceOfferingId:string;
}


export interface IRebookServicesForm {
  serviceProviderId:IDropdownOption | null;
  serviceOfferingId:IDropdownOption | null;
  priorCustomerStartDate:string | null;
  priorCustomerEndDate:string | null;
  territoryId:IDropdownOption | null;
  neighborhoodServiceOfferingInstanceId:IDropdownOption | null;
  overrideEmails?:string;
  sendToMeAsTest: boolean;
  targetServiceProviderId:IDropdownOption | null;
  targetServiceOfferingId:IDropdownOption | null;
}

export async function convert(marketId: string, form:IRebookServicesForm):Promise<IRebookServicesDto> {

  let zipCodeTerritories = form.territoryId?.optionValue ? [form.territoryId.optionValue] : [];
  var searchRequest:INeighborhoodSearchRequest = {
    offset: 0,
    pageNumber: 0,
    pageSize: 200,
    query: '',
    marketId: marketId,
    statuses: [NeighborhoodStatusType.ACTIVE, NeighborhoodStatusType.LAUNCHED],
    zipCodeTerritories: zipCodeTerritories,
    ids: [],

  };
  var neighborhoodsRes = await searchNeighborhoods(searchRequest);
  var neighborhoodIds = neighborhoodsRes.data.items.map(x => x.id ?? '');
  return {
    ...form,
    serviceProviderId: form?.serviceProviderId?.optionValue ?? '',
    serviceOfferingId: form?.serviceOfferingId?.optionValue ?? '',
    neighborhoodIds: neighborhoodIds,
    neighborhoodServiceOfferingInstanceId: form?.neighborhoodServiceOfferingInstanceId?.optionValue ?? '',
    overrideEmails: form.overrideEmails ? [form.overrideEmails] : [],
    sendToMeAsTest: form.sendToMeAsTest,
    targetServiceProviderId: form?.targetServiceProviderId?.optionValue ?? '',
    targetServiceOfferingId: form?.targetServiceOfferingId?.optionValue ?? '',
  };
}

function extractNeighborhoods(territoryToNeighborhoodsMap:Map<string, INeighborhood[]>, territoryIds:string[], individualNeighborhoodIds) {
  const neighborhoodIds:string[] = [];
  const uniqueNeighborhoodIds:string[] = [];
  const neighborhoodsFromMap = extractNeighborhoodsFromMap(territoryToNeighborhoodsMap, territoryIds);
  const neighborhoodIdsFromMap:string[] = neighborhoodsFromMap.filter(x => x.id !== undefined).map(x => x.id as string);
  neighborhoodIds.push(...neighborhoodIdsFromMap);
  neighborhoodIds.push(...individualNeighborhoodIds);
  const neighborhoodIdSet = new Set(neighborhoodIds);
  neighborhoodIdSet.forEach((id)=>{
    uniqueNeighborhoodIds.push(id);
  });
  return uniqueNeighborhoodIds;
}

export function createEmptyRebookServicesForm():IRebookServicesForm {
  return {
    serviceProviderId: null,
    serviceOfferingId: null,
    priorCustomerStartDate: null,
    priorCustomerEndDate: null,
    territoryId: null,
    neighborhoodServiceOfferingInstanceId: null,
    overrideEmails: '',
    sendToMeAsTest: true,
    targetServiceProviderId: null,
    targetServiceOfferingId: null,
  };
}