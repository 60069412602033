import { Grid } from '@mui/material';
import { approveProviderForSap } from 'api/serviceProviderApi';
import GenericModal from 'components/modal/GenericModal';
import useModal from 'components/modal/useModal';
import NeighborhoodAutocomplete from 'components/neighborhoodAutocomplete/NeighborhoodAutocomplete';
import { HookCheckbox, HookTextField } from 'components/reactHookForm';
import useToast from 'components/toast/useToast';
import { IDropdownOption } from 'model/dropdown';
import { IApproveForSapDTO, IInternalServiceProvider, StatusType } from 'model/serviceProvider';
import { FormProvider, useForm } from 'react-hook-form';

export const modalStoreKey = 'ApproveForSapModal';

export interface IApproveForSapModal {
  referringNeighborhoodId: string;
  referringPersonName?: string;
  companyHubspotId?: string;
}
export function createInitialApproveForSapModal(provider) :IApproveForSapModal {
  return {
    referringNeighborhoodId: provider.referringNeighborhoodId ?? '',
    referringPersonName: provider.referringPersonName ?? '',
    companyHubspotId: provider.companyHubspotId ?? '',
  };
}
export function createEmptyApproveForSapModal() :IApproveForSapModal {
  return {
    referringNeighborhoodId: '',
    referringPersonName: '',
    companyHubspotId: '',
  };
}

export default function ApproveForSapModal({ serviceProvider }) {
  const { closeModal } = useModal(modalStoreKey);
  const { createSuccessToast, createErrorToast } = useToast();
  const { referringNeighborhoodId, referringPersonName, companyHubspotId } = serviceProvider as IInternalServiceProvider;

  const formContext = useForm({
    mode: 'onTouched',
    defaultValues: {
      referringNeighborhoodId: referringNeighborhoodId ?? '',
      selectedNeighborhood: {} as IDropdownOption,
      referringPersonName: referringPersonName ?? '',
      companyHubspotId: companyHubspotId ?? '',
      skipFullSap: false,
    },
  });

  const neighborhoodRequired = !formContext.getValues().skipFullSap;
  const onSubmit = async (data:any) => {
    try {
      const formData = formContext.getValues();
      if (!formData.selectedNeighborhood?.optionValue && neighborhoodRequired) {
        createErrorToast('Failed to Send message: Referring Neighborhood is required');
        return;
      }
      const request = {
        referringNeighborhoodId: formData.selectedNeighborhood.optionValue,
        referringPersonName: formData.referringPersonName,
        companyHubspotId: formData.companyHubspotId,
        skipFullSap: formData.skipFullSap,
      } as IApproveForSapDTO;

      await approveProviderForSap(serviceProvider.id, request);
      createSuccessToast('Approved for SAP!');
    } catch (error) {
      console.error(error);
      createErrorToast('Error Approving...');
    }
    closeModal(createEmptyApproveForSapModal());
  };
  return (
    <GenericModal
      modalStoreKey={modalStoreKey}
      title={'Approve for SAP'}
      modalContent={
        (
          <div >
            <FormProvider {...formContext}>
              <form onSubmit={formContext.handleSubmit(onSubmit)}>
                <Grid container spacing={1} justifyContent='center'>
                  <Grid item xs={8}>
                    <NeighborhoodAutocomplete
                      label='Referring Neighborhood'
                      fieldName='selectedNeighborhood'
                      placeholder='Referring Neighborhood'
                      controlledValue={formContext.getValues().referringNeighborhoodId ?? undefined}
                      formless={false}
                      required={neighborhoodRequired} // Dont require if skipping full SAP
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <HookTextField
                      name='referringPersonName'
                      label='Reference (optional)'
                      required={false}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <HookTextField
                      name='companyHubspotId'
                      label='Hubspot ID (optional)'
                      required={false}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <HookCheckbox
                      name='skipFullSap'
                      label='Skip Full SAP'
                      required={false}
                    />
                  </Grid>
                </Grid>
              </form>
            </FormProvider>
          </div>
        )
      }
      onConfirm={onSubmit}
      onSecondaryAction={() => closeModal(createEmptyApproveForSapModal())}
      primaryButtonText='Approve'
      secondaryButtonText='Cancel'
    />
  );
}