import { InputLabel, FormControl, FormHelperText } from '@mui/material';
import { findServiceProviderAccounts } from 'api/accountApi';
import HookMultiselect from 'components/reactHookForm/HookMultiselect';
import useErrorState from 'hooks/reactHookForm/useErrorState';
import { useState, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { sortByName } from '../userDetails';

export function AccountsSelect() {
  const formCtx = useFormContext();
  const watched = useWatch({ name: 'accounts' });
  const { userId } = useParams();
  const [accounts, setAccounts] = useState<any[]>([]);
  useEffect(() => {
    async function getAccounts() {
      if (userId) {
        const serviceProviderAccountsRes = await findServiceProviderAccounts();
        // const individualAccountsRes = await findIndividualAccounts();
        // const individualAccounts = individualAccountsRes.data
        //   .map(x => {return { ...x, commonId: x.stripeCustomerId, displayName: `Individual: ${x.name}` };});
        const serviceProAccounts = serviceProviderAccountsRes.data
          .map(x => { return { ...x, commonId: x.stripeAccountId, displayName: `ServiceProvider: ${x.name}` }; });
        // const sortedAccounts = individualAccounts.concat(serviceProAccounts).sort(sortByName);
        const sortedAccounts = serviceProAccounts.sort(sortByName);
        setAccounts(sortedAccounts);
      }
    }
    void getAccounts();
  }, []);
  const { isError, getErrorMessage } = useErrorState(formCtx);
  return (
    <FormControl variant='standard' fullWidth error={isError('Accounts')}>
      <InputLabel htmlFor="accounts">Accounts</InputLabel>
      <HookMultiselect
        control={formCtx.control}
        name='accounts'
        id='accounts'
        menuItems={accounts}
        menuItemKeys={{
          key: 'commonId',
          value: 'commonId',
          displayValue: 'displayName',
        }} />
      <FormHelperText>{getErrorMessage('Accounts')}</FormHelperText>
    </FormControl>
  );
}
