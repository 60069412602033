
import { FormControl, FormHelperText } from '@mui/material';
import { HookSingleAutocomplete } from 'components/reactHookForm';
import useErrorState from 'hooks/reactHookForm/useErrorState';
import { useFormContext } from 'react-hook-form';
import useRebookServiceNotifications from '../hookStore/useRebookServiceNotifications';
import ServiceProviderAutocomplete from 'components/serviceProviderAutocomplete/ServiceProviderAutocomplete';


export interface IServiceProviderSelectProps {

}

export default function TargetServiceProviderSelect({ }:IServiceProviderSelectProps) {
  const { findAndSetTargetExistingServiceOfferings } = useRebookServiceNotifications();
  const formCtx = useFormContext();
  const { isError, getErrorMessage } = useErrorState(formCtx);


  return (
    <ServiceProviderAutocomplete
      required
      fieldName='targetServiceProviderId'
      label='Target Service Provider'
      placeholder='Service provider whose service will be advertised'
      formless={false}
      postOnChange={(formContext, value, reason, details) => {
        console.log(value);
        if (value?.optionValue) {
          void findAndSetTargetExistingServiceOfferings(formContext, value.optionValue);
        }
      }}
    />
  );
}