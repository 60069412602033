import { sendOneTimeNewsletter } from 'api/communicationsApi';
import { syncStreetName, uploadPropStreamFile } from 'api/launchToolingApi';
import { searchNeighborhoods, uploadHomeSales, uploadNeighborhoodWKT } from 'api/neighborhoodApi';
import { useLoading } from 'components/Layout/Loading';
import useToast from 'components/toast/useToast';
import createStore from 'hooks/hookStore';
import { IDropdownOption } from 'model/dropdown';
import { INeighborhoodSelection, IOneTimeNewsletterForm, IOneTimeNewsletterRequest } from 'model/marketing/oneTimeNewsletter';
import { IMarket, MarketUtils } from 'model/markets';
import { INeighborhood, NeighborhoodStatusType } from 'model/neighborhood';
import { INeighborhoodSearchRequest, IStreetFairSearchRequest } from 'model/search';
import { ITerritory } from 'model/territories';
import { useEffect, useState } from 'react';
import { sortByString } from 'util/sortFunctions';

type OneTimeNewsletter = {

  formData: IOneTimeNewsletterForm;
}

const loadingKey = 'OneTimeNewsletter';

const { get, update, registerListener, unregisterListener } = createStore<OneTimeNewsletter>('OneTimeNewsletter', {

  formData: {
    territoryId: null,
    neighborhoods: [],
    sendTest: false,
  },
});

export default function useOneTimeNewsletter() {
  const setState = useState(get())[1];
  const { onLoading, doneLoading } = useLoading(loadingKey);
  const { createInfoToast, createSuccessToast, createErrorToast } = useToast();

  useEffect(() => {
    registerListener(setState);
    return () => {
      update({
        ...get(),
        formData: {
          territoryId: null,
          neighborhoods: [],
          sendTest: false,
          testToEmail: '',
          fromEmail: '',
        },
      });
      unregisterListener(setState);
    };
  }, []);

  async function init(market:IMarket | null) {
    if (!market) {
      return;
    }
    onLoading();

    update({
      ...get(),

    });
    doneLoading(300);
  }

  async function onTerritoryChange(selectedMarket: IMarket, ancillary: ITerritory) {
    try {
      let zipCodeTerritories = [ancillary.zipCodeTerritory];
      const request: INeighborhoodSearchRequest = {
        query: '',
        pageNumber: 0,
        pageSize: 200,
        offset: 0,
        zipCodeTerritories,
        ids: [],
        statuses: [NeighborhoodStatusType.ACTIVE, NeighborhoodStatusType.LAUNCHED],
        marketId: selectedMarket.id,
      };
      const res = await searchNeighborhoods(request);
      if (res.data) {
        update({
          ...get(),
          formData: {
            ...get().formData,
            neighborhoods: res.data.items.map(n => ({
              neighborhoodId: n.id,
              name: n.name,
              status: n.status,
              selected: false,
            } as INeighborhoodSelection))
              .sort(sortByString('name', false)),
          },

        });
      }
    } catch (e:any) {
      console.error('Error searching neighborhoods: ', e.response?.data?.message);
    }
  }

  async function onSubmit(data:IOneTimeNewsletterForm, marketId: string) {

    onLoading();
    try {

      let request: IOneTimeNewsletterRequest = {
        territoryId: data.territoryId?.optionValue ?? null,
        marketId: marketId,
        neighborhoodIds: data.neighborhoods.filter(n => n.selected).map(n => n.neighborhoodId),
        sendTest: data.sendTest,
        testToEmail: data.testToEmail,
        fromEmail: data.fromEmail,
      };
      let res = await sendOneTimeNewsletter(request);
      if (!res.data.valid && res.data.message) {
        createErrorToast('Could not save batch: '+res.data.message);
      }
      doneLoading(150);
    } catch (e:any) {
      createErrorToast('An error occurred during save: '+e.response?.data?.message);
      doneLoading(150);
    }
  }

  return {
    loadingKey,
    ...get(),
    init,
    onTerritoryChange,
    onSubmit,
  };
}