import { Checkbox, Grid, TextField, Typography } from '@mui/material';
import GenericModal from 'components/modal/GenericModal';
import useModal, { IModal } from 'components/modal/useModal';
import { IInvoiceCustomerRowDTO } from 'model/invoicing';
import { useNavigate } from 'react-router-dom';
import useInvoicing from './hookStore/useInvoicing';

export const modalStoreKey = 'GenerateInvoiceModal';

export interface IGenerateInvoiceModal {
  selectedRows:IInvoiceCustomerRowDTO[];
  name:string;
  memo: string;
  autoFinalizeIfPossible: boolean;
}
export function createEmptyGenerateInvoiceModal() :IGenerateInvoiceModal {
  return {
    selectedRows: [],
    name: '',
    memo: '',
    autoFinalizeIfPossible: false,
  };
}
export interface IGenerateInvoiceModalProps {

}

export default function GenerateInvoiceModal({ }:IGenerateInvoiceModalProps) {
  const {
    nonStandardPricing,
    selectedRows,
    onGenerateInvoice,
  } = useInvoicing();
  const { getModalState, updateModalState, closeModal } = useModal(modalStoreKey);
  const _state = getModalState() as IGenerateInvoiceModal & IModal;

  const hasSubs = selectedRows.filter(x => x.recurring).length > 0;
  const hasZeroPriceCustomer = selectedRows.filter(x => x.totalPrice === 0).length > 0;
  const allowedToAutoFinalize = !hasSubs && !nonStandardPricing && !hasZeroPriceCustomer;
  async function _onConfirm(state:IGenerateInvoiceModal & IModal) {
    await onGenerateInvoice(_state.memo, allowedToAutoFinalize && _state.autoFinalizeIfPossible);
    closeModal(createEmptyGenerateInvoiceModal());
  }

  return (
    <GenericModal
      modalStoreKey={modalStoreKey}
      title={'Generate Invoice?'}
      modalContent={
        (
          <div >
            <Typography variant='body1' >Do you want to generate an invoice for {_state.name}?</Typography>
            <Typography variant='body1'>This will overwrite the associated invoice for each customer/subscriber checked. </Typography>

            <Grid container sx={{ maxWidth: '500px' }}>
              <Grid item xs={12}>
                <TextField
                  label="memo"
                  placeholder='Optional memo'
                  multiline
                  fullWidth
                  value={_state.memo}
                  onChange={(e) => {
                    updateModalState({ memo: e.target.value });
                  }}
                  sx={{
                    marginTop: '20px',
                  }}
                >
                </TextField>
              </Grid>
              <Grid container item xs={12} alignItems={'center'} sx={{ marginTop: '10px' }}>
                <Checkbox
                  disabled={!allowedToAutoFinalize}
                  checked={allowedToAutoFinalize && _state.autoFinalizeIfPossible}
                  onChange={(e) => {
                    updateModalState({ autoFinalizeIfPossible: e.target.checked });
                  }}
                />
                <Typography
                  component='span'
                  variant='body1'
                  sx={{
                    '&&&': {
                      color: !allowedToAutoFinalize ? '#bcbcbc' : 'black',
                    },
                  }}>
                  <>Automatically finalize &amp; send invoice</>
                </Typography>

              </Grid>
              {!allowedToAutoFinalize && (<Grid container item xs={12} justifyItems={'start'}>
                <Typography variant='body1'>Automated invoicing is unavailable for this provider because they have one of the following:</Typography>

                <ul>
                  <li>non-standard pricing: {nonStandardPricing ? 'Yes' : 'No'}</li>
                  <li>subscriptions: {hasSubs ? 'Yes' : 'No'}</li>
                  <li>$0 customer: {hasZeroPriceCustomer ? 'Yes' : 'No'}</li>
                </ul>
              </Grid>
              )}
            </Grid>
          </div>
        )
      }
      onConfirm={() => _onConfirm(_state)}
      primaryButtonText='Confirm'
    />
  );
}