import { Grid, Typography } from '@mui/material';
import NewsletterCadenceSelect from './NewsletterCadenceSelect';
import NonUserNewsletterCadenceSelect from './NonUserNewsletterCadenceSelect';
import PageSubsectionHeader from 'components/SectionHeaders/PageSubsectionHeader';

export default function TerritoryCommunicationsInputs() {
  return <>
    <Grid container direction='row' justifyContent={'flex-start'} spacing={1} paddingLeft={1}>
      <Grid item xs={12}>
        <PageSubsectionHeader text={'Territory Communications'} />
      </Grid>
      <Grid item xs={12} sm={6} md={2} >
        <NewsletterCadenceSelect/>
      </Grid>
      <Grid item xs={12} sm={6} md={3} >
        <NonUserNewsletterCadenceSelect/>
      </Grid>
    </Grid>
  </>;
}