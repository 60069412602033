import { useLoading } from 'components/Layout/Loading';
import useIndividualsHookStore from '../individualsHookStore';
import { Alert, Grid, MenuItem, Select } from '@mui/material';
import { PrimaryButton } from 'components/buttons';
import { LaunchLeaderStatusTypes } from 'model/individual';
import { useState } from 'react';
import { updateLaunchLeaderStatus } from 'api/individualApi';
import useToast from 'components/toast/useToast';

export default function LaunchLeaderStatusUpdate() {
  const { individual, loadingKey, updateIndividual } = useIndividualsHookStore();
  const [value, setValue] = useState(individual.launchLeaderStatus);
  const [showInfoPanel, setShowInfoPanel] = useState(false);
  const { onLoading, doneLoading, getLoadingState } = useLoading(loadingKey);
  const { isLoading } = getLoadingState();
  const { createErrorToast, createSuccessToast } = useToast();


  async function onUpdateButtonClick() {
    onLoading();
    try {
      const resp = await updateLaunchLeaderStatus(individual.id.toString(), value!);
      await updateIndividual(resp.data);
      createSuccessToast('Successfully updated launch leader status');
    } catch (error:any) {
      console.error('Error updating launch leader status', { error, individual, newStatus: value });
      createErrorToast(error.response?.data?.message || 'Error updating launch leader status');
    }
    doneLoading(300);
  }

  return (
    <Grid container flexDirection="column" rowSpacing={2}>
      <Grid container item xs={6}>
        <Select
          variant='standard'
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
            if (e.target.value === LaunchLeaderStatusTypes.DONE) {
              setShowInfoPanel(true);
            }
          }}
          sx={{ width: '40%' }}
        >
          {LaunchLeaderStatusTypes.list.map((status, i) => {
            return (
              <MenuItem key={`${status}-${i}`} value={status.id}> {status.name} </MenuItem>
            );
          })}
        </Select>
        <PrimaryButton
          disabled={(value === individual.launchLeaderStatus) || isLoading}
          onClick={onUpdateButtonClick}
          sx={{ marginLeft: '25px' }}
        >
        Update
        </PrimaryButton>
      </Grid>
      { showInfoPanel && (
        <Grid item xs={6}>
          <Alert severity="info">Marking as DONE will automatically add this individual's Launch leader application reward amount to their account balance. If the user was referred by another individual, then the referring individual will also be funded their promised award.</Alert>
        </Grid>
      ) }
    </Grid>
  );
}