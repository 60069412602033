import { Alert, Grid } from '@mui/material';
import { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useFormContext } from 'react-hook-form';
import styles from './addressContactsUpload.module.css';
import useAddressContactsUpload from './addressContactsUploadHookStore';
import { IAddressContactsUploadForm } from 'model/launchTooling';

export default function AddressContactsPropstreamTemplateUpload() {

  const [errorMessage, setErrorMessage] = useState('');
  const ctx = useFormContext<IAddressContactsUploadForm>();
  const { onDropFile, loadingKey, acceptedFiles } = useAddressContactsUpload();
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (_acceptedFiles) => onDropFile(_acceptedFiles, ctx),
  });

  return (
    <Grid container item justifyContent='center'>
      <Grid item xs>
        <div {...getRootProps()} className={styles.dropzoneWrapper}>
          <input {...getInputProps()} accept='.csv'/>
          {
            isDragActive ?
              <p>Drop the files here ...</p> :
              <p>Drag and drop a PropStream file or click here to select from file system</p>
          }
        </div>
      </Grid>
      <Grid item xs={12}>
        {acceptedFiles.map((x, i) => {
          return (
            <Alert
              key={i}
              severity='info'
              className={styles.uploadInfoMessage}
            >
              Selected file: {x.name}
            </Alert>

          );
        })}
      </Grid>
      <Grid item xs={12}>
        {errorMessage && (
          <Alert
            severity='error'
            className={styles.uploadErrorMessage}
            variant='outlined'
          >
            {errorMessage}
          </Alert>
        )}
      </Grid>
    </Grid>
  );
}