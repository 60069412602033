import { Grid, Typography } from '@mui/material';
import { AddButton, RemoveButton } from 'components/buttons';
import Spacer from 'components/Layout/Spacer';
import { HookTextField } from 'components/reactHookForm';
import { ManageServiceTypeMode } from 'model/serviceCategory';
import { useEffect } from 'react';
import { useFormContext, useFieldArray, useWatch } from 'react-hook-form';
import { useParams } from 'react-router-dom';

function createTag() {
  return {
    value: '',
  };
}

export default function Tags() {
  const { control } = useFormContext();
  const { mode } = useParams();

  const shouldDisableDefaultFields = mode === ManageServiceTypeMode.EDIT_MARKET || mode === ManageServiceTypeMode.EDIT_TERRITORY;
  const { fields: tags, append, replace, remove } = useFieldArray({
    name: 'tags',
  });
  const watchTags = useWatch({ control, name: 'tags' });
  useEffect(() => {
    if (tags.length !== watchTags.length) {
      replace(watchTags);
    }
  }, [watchTags]);

  return (
    <Grid container item spacing={1} alignItems='center'>
      <Grid container alignItems='center' item xs={12}>
        <Typography>Add a tag</Typography>
        <Grid item xs='auto'>
          <AddButton
            tooltipTitle="Add a tag"
            disabled={shouldDisableDefaultFields}
            onClick={ () => append(createTag())}
          />
        </Grid>
        <Spacer/>
      </Grid>
      {tags.map((item, index) => {

        return (
          <Grid container item xs={4} lg={3} spacing={1} alignItems='center' key={item.id}>
            <Grid container item xs={9} alignItems='center'>
              <HookTextField
                name={`tags.${index}.value`}
                label={`Tag ${index+1}`}
                disabled={shouldDisableDefaultFields}
                required
              />
            </Grid>

            <Grid container item xs='auto' alignSelf='center' >
              <RemoveButton
                tooltipTitle="Delete this tag"
                disabled={shouldDisableDefaultFields}
                onClick={() => remove(index)}
              />
            </Grid>
          </Grid>
        );
      })}

    </Grid>
  );
}