import { Grid, Paper, Typography } from '@mui/material';
import { AddButton, RemoveButton } from 'components/buttons';
import Spacer from 'components/Layout/Spacer';
import { HookTextField } from 'components/reactHookForm';
import { useEffect } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';

function createEmptyZipCode() {
  return {
    value: '',
  };
}

export default function ZipCodes({ init }:any) {
  const { control } = useFormContext();


  const { fields: zipCodes, append, replace, remove } = useFieldArray({
    name: 'zipCodes',
  });
  const watchZipCodes = useWatch({ control, name: 'zipCodes' });
  useEffect(() => {
    if (zipCodes.length !== watchZipCodes.length) {
      replace(watchZipCodes);
    }
  }, [watchZipCodes]);

  return (
    <Grid container item spacing={1} alignItems='center'>
      <Grid container alignItems='center' item xs={12}>
        <Typography>Add Zip Code</Typography>
        <Grid item xs='auto'>
          <AddButton
            tooltipTitle="Add a zip code"
            onClick={() => append(createEmptyZipCode())}
          />
        </Grid>
        <Spacer/>
      </Grid>
      {zipCodes.map((item, index) => {
        return (
          <Grid container item spacing={1} alignItems='center' key={item.id}>
            <Grid container item lg={1} md={2} xs={3} alignItems='center'>
              <HookTextField
                name={`zipCodes.${index}.value`}
                label={`Zip Code ${index+1}`}
                required
              />
            </Grid>

            <Grid container item xs='auto' alignSelf='center' >
              <RemoveButton
                tooltipTitle="Delete this zip code"
                onClick={() => remove(index)}
              />
            </Grid>
          </Grid>
        );
      })}

    </Grid>
  );
}
