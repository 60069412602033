import { RemoveButton } from 'components/buttons';

import styles from './tableCell.module.css';
import useModal from 'components/modal/useModal';
import useConfirmationModal from 'components/modals/useConfirmationModal';

export interface IDeleteCellProps {
  modalStoreKey: string;
  id: string;
  desc: string;
  disabled?:boolean;
}

export default function DeleteCell({ id, desc, modalStoreKey, disabled }:IDeleteCellProps) {
  const { openModal } = useConfirmationModal(modalStoreKey);

  return (
    <RemoveButton
      disabled={disabled}
      tooltipTitle='Remove'
      onClick={() => {

        openModal({ id, desc });
      }}
      tooltipProps={{
        placement: 'top',
      }}
      iconButtonProps={{
        className: styles.actionButton,
      }}
    />
  );
}