import { Grid } from '@mui/material';
import Loading from 'components/Layout/Loading';
import PageHeader from 'components/SectionHeaders/PageHeader';
import { useEffect } from 'react';
import TooltipDrawerButton from 'components/drawer/TooltipDrawerButton';
import ShortLinkForm from './ShortLinkForm';
import useShortLink from './shortLinkHookStore';
import ShortLinkResult from './ShortLinkResult';


export default function ShortLink() {
  const { init, loadingKey } = useShortLink();

  useEffect(() => {
    void init();
  }, []);

  return (
    <>
      <Loading loadingKey={loadingKey} size={100}>
        <Grid container spacing={1} className='pageGridContainer' justifyContent='start' alignItems={'center'}>

          <Grid item xs={12}>
            <PageHeader
              headerText={'Short Link'}
            />
          </Grid>
          <Grid item xs={12} sx={{ marginTop: 1 }}>
            <ShortLinkForm/>
          </Grid>
          <Grid item xs={12}>
            <ShortLinkResult/>
          </Grid>
        </Grid>
      </Loading>
    </>
  );
}