import PageHeader from 'components/SectionHeaders/PageHeader';
import SeedFromTemplateTabs from './_components/SeedFromTemplateTabs';
import { Grid } from '@mui/material';
import Loading from 'components/Layout/Loading';
const loadingKey = 'SeedFromTemplate';
export default function SeedFromTemplate() {
  return (
    <Loading loadingKey={loadingKey}>
      <Grid padding={'25px'}>
        <PageHeader headerText={'Seed From Template'} />
        <SeedFromTemplateTabs />
      </Grid>
    </Loading>
  );
}