import { Grid, IconButton } from '@mui/material';
import { SecondaryButton } from 'components/buttons';
import { BigTooltip } from 'components/tooltip/BigTooltip';
import HelpIcon from '@mui/icons-material/Help';
import { useFormContext, useWatch } from 'react-hook-form';
import { LetterBatchStatus } from 'model/letter_batch/letterBatch';

export default function MarkSent() {
  const formContext = useFormContext();
  const watchedExportDate = useWatch({ name: 'exportDate', control: formContext.control });
  const watchBatchStatus = useWatch({ name: 'batchStatus', control: formContext.control });
  const fieldState = formContext.getFieldState('batchStatus');
  const { isDirty } = fieldState;
  return (
    <>
      <SecondaryButton
        disabled={!watchedExportDate || watchBatchStatus == LetterBatchStatus.SENT}
        onClick={() => {
          formContext.setValue('batchStatus', LetterBatchStatus.SENT, { shouldDirty: true });
        }}
      >
        <>{watchBatchStatus == LetterBatchStatus.SENT ? `Sent ${isDirty ? '(Need Save)' : ''}` : 'Mark Sent'}</>
      </SecondaryButton>
      <BigTooltip title={
        'This button is enabled when the batch has been exported successfully at least once. You must still save the batch after marking it as sent.'
      }>
        <IconButton>
          <HelpIcon />
        </IconButton>
      </BigTooltip>
    </>
  );
}