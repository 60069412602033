import { draftServiceProviderInvoice, findCompletedCustomersByServiceProviderWithoutInvoice, findInvoicedCustomersByServiceProvider, getCustomerInvoice, markCustomersAsInvoiced, removeCustomerInvoiceInfo } from 'api/invoicingApi';
import { findInternalServiceProviderById } from 'api/serviceProviderApi';
import { useLoading } from 'components/Layout/Loading';
import createStore from 'hooks/hookStore';
import { IInvoiceCustomerRowDTO, IInvoicingTableFilter, createGenerateInvoiceRequest } from 'model/invoicing';
import { IServiceProvider } from 'model/serviceProvider';
import { useEffect, useState } from 'react';
import { parse } from 'date-fns';
import { sortByString } from 'util/sortFunctions';
import useToast from 'components/toast/useToast';
import { findServiceTypeInfo } from 'api/serviceCategoryApi';
import { ServiceTypeInfoUtil } from 'util/serviceType/serviceTypeInfoUtil';
import { IServiceType } from 'model/serviceType';

const loadingKey = 'InvoicingStore';
export const ALL_MONTHS_NO_INVOICED = 13;
const sortByName = sortByString('name');

type InvoicingStore = {
  customers: IInvoiceCustomerRowDTO[];
  selectedRows: IInvoiceCustomerRowDTO[];
  invoicingTableFilter: IInvoicingTableFilter;
  shouldShowInvoicedCustomers: boolean;
  nonStandardPricing: boolean;
  isModalOpen: boolean;
  invoiceNumber: string;
  serviceTypeMap:Map<String, IServiceType>;
}

const { get, update, registerListener, unregisterListener } = createStore<InvoicingStore>('InvoicingStore', {
  customers: [],
  selectedRows: [],
  invoicingTableFilter: {
    selectedServiceProviderId: '',
    selectedNeighborhoodId: '',
    selectedMonth: '',
    selectedYear: '',
  },
  shouldShowInvoicedCustomers: false,
  nonStandardPricing: false,
  isModalOpen: false,
  invoiceNumber: '',
  serviceTypeMap: new Map<String, IServiceType>(),
});


export default function useInvoicing() {
  const setState = useState(get())[1];
  const { onLoading, doneLoading } = useLoading(loadingKey);
  const { createErrorToast, createSuccessToast } = useToast();

  useEffect(() => {
    registerListener(setState);
    return () => {
      unregisterListener(setState);
    };
  }, []);

  async function init() {

    let serviceTypeMap = new Map<String, IServiceType>();
    onLoading();
    try {

      let serviceTypeInfoRes = await findServiceTypeInfo();
      if (serviceTypeInfoRes.data) {
        serviceTypeMap = ServiceTypeInfoUtil.getServiceTypeMap(serviceTypeInfoRes.data);
      }

      const storedSelectedServiceProvider = localStorage.getItem('invoices.selectedServiceProviderId') || '';
      const storedSelectedMonth = localStorage.getItem('invoices.selectedMonth') || '';
      const storedSelectedYear = localStorage.getItem('invoices.selectedYear') || '';
      const initialFilter:IInvoicingTableFilter = {
        selectedServiceProviderId: '',
        selectedMonth: '',
        selectedYear: '',
        selectedNeighborhoodId: '',
      };
      if (storedSelectedServiceProvider) {
        initialFilter.selectedServiceProviderId = storedSelectedServiceProvider;
      }
      if (storedSelectedMonth) {
        initialFilter.selectedMonth = storedSelectedMonth;
      }
      if (storedSelectedYear) {
        initialFilter.selectedYear = storedSelectedYear;
      }
      if (initialFilter.selectedServiceProviderId) {
        const res = await findInternalServiceProviderById(initialFilter.selectedServiceProviderId);
        localStorage.setItem('invoices.selectedServiceProviderName', res.data.name);
        update({
          ...get(),
          customers: [],
          selectedRows: [],
          invoiceNumber: '',
          nonStandardPricing: res.data.nonStandardPricing,
          invoicingTableFilter: {
            ...initialFilter,
            selectedServiceProviderId: initialFilter.selectedServiceProviderId,
          },
          serviceTypeMap,
        });
      } else {
        update({
          ...get(),
          invoiceNumber: '',
          customers: [],
          selectedRows: [],
          invoicingTableFilter: initialFilter,
          serviceTypeMap,
        });
      }

    } catch (e:any) {
      console.error(e);
    }
    doneLoading(300);
  }

  async function refreshTableData() {
    const { shouldShowInvoicedCustomers } = get();
    await _findCustomersByProvider(shouldShowInvoicedCustomers);
  }

  async function onShouldShowInvoicedCustomers(nextVal) {

    update({
      ...get(),
      shouldShowInvoicedCustomers: nextVal,
    });
    await _findCustomersByProvider( nextVal);
  }


  async function onServiceProviderChanged(serviceProvider:IServiceProvider) {
    const { invoicingTableFilter } = get();
    localStorage.setItem('invoices.selectedServiceProviderId', serviceProvider.id);
    try {
      const res = await findInternalServiceProviderById(serviceProvider.id);
      localStorage.setItem('invoices.selectedServiceProviderName', res.data.name);
      update({
        ...get(),
        nonStandardPricing: res.data.nonStandardPricing,
        invoicingTableFilter: {
          ...invoicingTableFilter,
          selectedServiceProviderId: serviceProvider.id,
        },
      });
    } catch (err:any) {
      console.error(err);
    }
  }

  async function onMonthChange(e:any) {
    const { invoicingTableFilter } = get();
    update({
      ...get(),
      invoicingTableFilter: {
        ...invoicingTableFilter,
        selectedMonth: e.target.value,
      },
    });
    if (e.target.value && parseInt(e.target.value) == ALL_MONTHS_NO_INVOICED) {
      void onShouldShowInvoicedCustomers(false);
    }
    localStorage.setItem('invoices.selectedMonth', e.target.value);
  }

  async function onYearChange(e:any) {
    const { invoicingTableFilter } = get();
    update({
      ...get(),
      invoicingTableFilter: {
        ...invoicingTableFilter,
        selectedYear: e.target.value,
      },
    });
    localStorage.setItem('invoices.selectedYear', e.target.value);
  }

  async function onClearMonth() {
    const { invoicingTableFilter } = get();
    update({
      ...get(),
      invoicingTableFilter: {
        ...invoicingTableFilter,
        selectedMonth: '',
      },
    });
    localStorage.setItem('invoices.selectedMonth', '');
  }

  async function onClearYear() {
    const { invoicingTableFilter } = get();
    update({
      ...get(),
      invoicingTableFilter: {
        ...invoicingTableFilter,
        selectedYear: '',
      },
    });
    localStorage.setItem('invoices.selectedYear', '');
  }


  const _findCustomersByProvider = async ( _shouldShowInvoicedCustomers:boolean) => {
    const { invoicingTableFilter } = get();
    const {
      selectedMonth,
      selectedYear,
      selectedServiceProviderId,
    } = invoicingTableFilter;

    var selectedMonthNumber = parseInt(selectedMonth);
    if (selectedServiceProviderId && selectedMonthNumber > 0) {
      if (_shouldShowInvoicedCustomers && selectedMonthNumber !== ALL_MONTHS_NO_INVOICED) {
        const startDate = parse(`${selectedYear}-${selectedMonth}`, 'yyyy-MM', new Date());
        let targetYear = selectedYear;
        let endDateMonth:number;
        if (selectedMonthNumber === 12) {
          endDateMonth = 0;
          targetYear = (parseInt(selectedYear) + 1).toString();
        } else {
          endDateMonth = selectedMonthNumber;
        }
        const endDate = parse(`${targetYear}-${endDateMonth+1}`, 'yyyy-MM', new Date());
        if (startDate && endDate) {
          const res = await findInvoicedCustomersByServiceProvider(selectedServiceProviderId, startDate, endDate);
          update({
            ...get(),
            customers: updateCustomersWithServiceTypeName(res.data),
            selectedRows: [],
          });
        } else {
          createErrorToast('Please select year and month values from the dropdown');
        }
      } else {
        let startDate:Date | null = null;
        let endDate:Date | null = null;
        if (selectedMonthNumber !== ALL_MONTHS_NO_INVOICED) {
          startDate = parse(`${selectedYear}-${selectedMonth}`, 'yyyy-MM', new Date());
          let targetYear = selectedYear;
          let endDateMonth:number;
          if (selectedMonthNumber === 12) {
            endDateMonth = 0;
            targetYear = (parseInt(selectedYear) + 1).toString();
          } else {
            endDateMonth = selectedMonthNumber;
          }
          endDate = parse(`${targetYear}-${endDateMonth+1}`, 'yyyy-MM', new Date());
        }

        const res = await findCompletedCustomersByServiceProviderWithoutInvoice(selectedServiceProviderId, startDate, endDate);

        update({
          ...get(),
          customers: updateCustomersWithServiceTypeName(res.data),
          selectedRows: [],
        });
      }
    } else {
      update({
        ...get(),
        customers: [],
        selectedRows: [],
      });
    }
  };

  function updateCustomersWithServiceTypeName(customers: IInvoiceCustomerRowDTO[]): IInvoiceCustomerRowDTO[] {
    const { serviceTypeMap } = get();

    return customers.map((customer) => {
      if (!customer.serviceTypeId) {
        return customer;
      }
      const serviceType = serviceTypeMap[customer.serviceTypeId];
      return {
        ...customer,
        serviceType: serviceType?.displayName ?? serviceType?.name ?? '',
      };
    });

    return customers;
  }
  async function onModalClose() {
    update({
      ...get(),
      isModalOpen: false,
    });
  }

  async function onModalSubmit() {
    update({
      ...get(),
      isModalOpen: false,
    });
    onLoading();
    const { invoicingTableFilter, selectedRows, shouldShowInvoicedCustomers, invoiceNumber } = get();
    console.log('invoice number....' + invoiceNumber);
    if (shouldShowInvoicedCustomers) {
      console.log('Marking customers as NOT invoiced');
      console.log(selectedRows);
    } else {
      console.log('Marking customers as NOT invoiced');
      console.log(selectedRows);
    }
    try {
      await _updateCustomerInvoicingStatus(invoicingTableFilter.selectedServiceProviderId, selectedRows, shouldShowInvoicedCustomers);
      await _findCustomersByProvider(shouldShowInvoicedCustomers);
      update({
        ...get(),
        selectedRows: [],
      });
      createSuccessToast('Invoice status updated');
    } catch (err:any) {
      console.error(err);
    } finally {
      doneLoading(300);
    }
  }

  const _updateCustomerInvoicingStatus = async (_selectedServiceProviderId, _selectedCustomerSet, _shouldShowInvoicedCustomers) => {
    const { invoiceNumber } = get();
    if (_shouldShowInvoicedCustomers) {
      return removeCustomerInvoiceInfo(_selectedServiceProviderId, _selectedCustomerSet);
    } else {
      return markCustomersAsInvoiced(_selectedServiceProviderId, _selectedCustomerSet, invoiceNumber);
    }
  };

  function onButtonClick() {
    update({
      ...get(),
      isModalOpen: true,
      invoiceNumber: '',
    });
  }


  const onGenerateInvoice = async (memo:string, autoFinalizeIfPossible:boolean) => {
    const { invoicingTableFilter, selectedRows } = get();
    const dto = createGenerateInvoiceRequest(selectedRows, memo, { daysUntilDue: 7 }, autoFinalizeIfPossible );

    try {
      const res = await draftServiceProviderInvoice(invoicingTableFilter.selectedServiceProviderId, dto);
      if (res.data?.success && res.data.requestReceivedMessage) {
        createSuccessToast(res.data.requestReceivedMessage);
      } else if (res.data && !res.data.success) {
        createErrorToast('Failed to create invoice: '+res.data.errorMessage);
      }
      update({
        ...get(),
        selectedRows: [],
      });

    } catch (err:any) {
      console.error('Could not generate draft invoice');
      console.error(err);
      createErrorToast('Unable to generate draft invoice');
    }

  };

  const onGetCustomerInvoice = async (customerId:string) => {
    try {
      const { invoicingTableFilter } = get();
      const res = await getCustomerInvoice(invoicingTableFilter.selectedServiceProviderId, customerId);
      if (res.data) {
        if (res.data.success) {
          createSuccessToast(`Copied: ${res.data.finalizedInvoiceNumber} to clipboard`);
          await navigator.clipboard.writeText(res.data.finalizedInvoiceNumber);
        } else {
          createErrorToast(res.data.message);
        }
      }
    } catch (err:any) {
      console.error(err);
      createErrorToast('Could not retrieve invoice');
    }
  };

  const handleSelectionModelChange = (rows, newSelection) => {
    console.log('newSelection', newSelection);
    const newSelectedRows = rows.filter(x => newSelection.indexOf(x.id) > -1);
    update({
      ...get(),
      selectedRows: [...newSelectedRows],
    });
  };

  async function postOnChange(_formCtx: any, selectedOption: any, reason: any, details: any) {
    const { invoicingTableFilter } = get();
    if (reason === 'removeOption') {
      update({
        ...get(),
        invoicingTableFilter: {
          ...invoicingTableFilter,
          selectedServiceProviderId: '',
        },
      });
      localStorage.setItem('invoices.selectedServiceProviderId', '');
      localStorage.setItem('invoices.selectedServiceProviderName', '');
    } else if (reason === 'clear') {
      update({
        ...get(),
        invoicingTableFilter: {
          ...invoicingTableFilter,
          selectedServiceProviderId: '',
        },
      });
      localStorage.setItem('invoices.selectedServiceProviderId', '');
      localStorage.setItem('invoices.selectedServiceProviderName', '');
    } else if (reason === 'selectOption') {
      if (selectedOption?.optionValue) {
        await onServiceProviderChanged(selectedOption?.ancillary);
      }
    }

  }

  function onSetInvoiceNumber(val:string) {
    update({
      ...get(),
      invoiceNumber: val,
    });
  }

  return {
    ...get(),
    loadingKey,
    init,
    refreshTableData,
    onButtonClick,
    handleSelectionModelChange,
    onClearMonth,
    onClearYear,
    onGenerateInvoice,
    onGetCustomerInvoice,
    onModalClose,
    onModalSubmit,
    onMonthChange,
    onSetInvoiceNumber,
    onShouldShowInvoicedCustomers,
    onYearChange,
    postOnChange,
  };
}