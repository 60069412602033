import { useLoading } from 'components/Layout/Loading';
import useToast from 'components/toast/useToast';
import createStore from 'hooks/hookStore';
import { IDropdownOption } from 'model/dropdown';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { charlotteMarketCoverageArea, createEmptyProviderCoverageAreaForm, dallasMarketCoverageArea, ICoverageArea, IGeoJSONCoordinates, IMarketCoverageAreaMap, IProviderCoverageAreaForm } from 'model/maps';
import { findCoverageAreaDTOsByServiceProvider, findCoveredNeighborhoods, saveProviderCoverageAreas } from 'api/providerCoverageAreaApi';
import { ExcludedNeighborhoodType, IExcludedNeighborhoodDTO, IProviderCoverageArea, IProviderCoverageAreaDTO, IProviderCoverageAreaFormDTO, ProviderCoverageAreaType } from 'model/providerCoverageArea';
import { findServiceOfferingsByProviderCoverageArea } from 'api/serviceOfferingApi';
import { IServiceOfferingWithProviderCoverageArea } from 'model/serviceOffering';
import debounce from 'lodash.debounce';
import { findServiceProviderById } from 'api/serviceProviderApi';


const loadingKey = 'ProviderCoverageAreaStore';
function createPanToolHandler() {
  return {
    polygonRef: null, // this field is set at runtime after a coverage area is initialized in the map component and a polygon is created. Also when a polygon is drawn.
    draggableEnabled: false, // controls the color of the pan tool handler icon
    mapRefFunc: function () {
      //this is admittedly pretty complicated. We pass a reference to this object to the map component which then uses a useRef() hook to set the polygonRef property
      //at runtime. This allows us to call the setDraggable method on the polygonRef which is a google.maps.Polygon object. In addition, we also want updates to the draggableEnabled
      //property to reflected in the standard react ui components so we call onPanToolHandlerUpdate which uses standard hookStore get and update functions to update the state.
      if (this.polygonRef) {
        (this.polygonRef as any).setDraggable(!this.draggableEnabled);
        this.draggableEnabled = !this.draggableEnabled;
      }
    },
  };
}

type ProviderCoverageAreaStore = {
  formData:IProviderCoverageAreaForm;
  marketCoverageAreaMap: IMarketCoverageAreaMap;
  neighborhoodOptions: IDropdownOption[];
  originalProviderCoverageAreas:Map<string, IProviderCoverageAreaDTO>;
  providerCoverageAreaIdToServiceOffering:Map<string, IServiceOfferingWithProviderCoverageArea[]>;
  panToolHandlers:any[];
}


const { get, update, registerListener, unregisterListener } = createStore<ProviderCoverageAreaStore>('ProviderCoverageAreaStore', {
  formData: createEmptyProviderCoverageAreaForm(),
  marketCoverageAreaMap: { ...charlotteMarketCoverageArea },
  neighborhoodOptions: [],
  originalProviderCoverageAreas: new Map<string, IProviderCoverageAreaDTO>(),
  providerCoverageAreaIdToServiceOffering: new Map<string, IServiceOfferingWithProviderCoverageArea[]>(),
  panToolHandlers: [],
});

const debouncedFindCoveredNeighborhoods = debounce(async (dto, callback) => {
  const res = await findCoveredNeighborhoods(dto);
  callback(res.data);
}, 400);

export default function useProviderCoverageArea() {
  const setState = useState(get())[1];
  const navigate = useNavigate();
  const { onLoading, doneLoading } = useLoading(loadingKey);
  const { createErrorToast, createInfoToast } = useToast();

  useEffect(() => {
    registerListener(setState);
    return () => {
      unregisterListener(setState);
    };
  }, []);

  async function init() {
    onLoading();
    try {
      update({
        ...get(),
        originalProviderCoverageAreas: new Map<string, IProviderCoverageAreaDTO>(),
        formData: createEmptyProviderCoverageAreaForm(),
        panToolHandlers: [],
      });
    } catch (e:any) {
      console.error(e);
    }
    doneLoading(300);
  }

  async function save(data:IProviderCoverageAreaForm) {
    const dataToSave = { ...data };
    onLoading();
    try {
      if (dataToSave.serviceProviderId) {
        const formDto:IProviderCoverageAreaFormDTO = {
          serviceProviderId: dataToSave.serviceProviderId?.optionValue,
          providerCoverageAreas: [],
        };
        for (let i = 0; i < dataToSave.providerCoverageAreas.length; i++) {
          const {
            id,
            name,
            excludedNeighborhoodIds,
            excludedNeighborhoodOptions,
            type,
            coordinates,
            new: isNew,
          } = dataToSave.providerCoverageAreas[i];
          const geoJson = {
            type,
            coordinates,
          };
          formDto.providerCoverageAreas.push({
            delete: false,
            add: isNew,
            excludedNeighborhoods: _createExcludedNeighborhoods(excludedNeighborhoodIds, excludedNeighborhoodOptions, formDto.serviceProviderId, id, isNew ),
            providerCoverageArea: _createProviderCoverageArea(id, formDto.serviceProviderId, name, geoJson, isNew),
          });
        }
        formDto.providerCoverageAreas.push(...createProviderCoverageAreaDTOToDelete(dataToSave));
        await saveProviderCoverageAreas(formDto);
        await refreshCoverageAreasForProvider(formDto.serviceProviderId);
        createInfoToast('Provider coverage area updated.');
      }
      doneLoading(200);
    } catch (err:any) {
      console.error(err);
      doneLoading(200);
    }
  }

  function _createProviderCoverageArea(id:string, serviceProviderId:string, name:string, geoJson:IGeoJSONCoordinates, isNew:boolean):IProviderCoverageArea {
    const _data:IProviderCoverageArea = {
      PK: '',
      SK: '',
      id: isNew ? null : id,
      archetype: '',
      coverageAreaGeoJSON: geoJson,
      coverageAreaWKT: null,
      entityType: ProviderCoverageAreaType,
      name: name,
      serviceProviderId,
      wktType: 'POLYGON',
      GSIPK1: '',
      GSISK1: '',
    };
    return _data;
  }

  function _createExcludedNeighborhoods(
    excludedNeighborhoodIds:string[],
    excludedNeighborhoodOptions:IDropdownOption[],
    serviceProviderId:string,
    providerCoverageAreaId:string,
    isNew:boolean,
  ):IExcludedNeighborhoodDTO[] {
    let excludedNeighborhoodDtos:IExcludedNeighborhoodDTO[] = [];
    const { originalProviderCoverageAreas } = get();
    const excludedNeighborhoodIdSet = new Set(excludedNeighborhoodIds);
    if (!isNew) {
      const originalProviderCoverageArea = originalProviderCoverageAreas.get(providerCoverageAreaId);
      if (originalProviderCoverageArea) {
        const originalExcludedNeighborhoodIdSet = new Set(originalProviderCoverageArea.excludedNeighborhoods.map(x => x.excludedNeighborhood.neighborhoodId));
        originalProviderCoverageArea.excludedNeighborhoods.forEach(x => {
          if (!excludedNeighborhoodIdSet.has(x.excludedNeighborhood.neighborhoodId)) {
            //we are removing the neighborhood from exclusion list
            excludedNeighborhoodDtos.push({
              delete: true,
              add: false,
              excludedNeighborhood: {
                entityType: ExcludedNeighborhoodType,
                id: providerCoverageAreaId,
                neighborhoodId: x.excludedNeighborhood.neighborhoodId,
                neighborhoodName: x.excludedNeighborhood.neighborhoodName,
                serviceProviderId,
                PK: '', //set on server,
                SK: '', // set on server
                GSIPK1: '', //set on server,
                GSISK1: '', // set on server
                archetype: '', //not used for ExcludedNeighborhood type
              },
            });
          } else {
            // it already existed in the list
            excludedNeighborhoodDtos.push({
              delete: false,
              add: false,
              excludedNeighborhood: {
                entityType: ExcludedNeighborhoodType,
                id: providerCoverageAreaId,
                neighborhoodId: x.excludedNeighborhood.neighborhoodId,
                neighborhoodName: x.excludedNeighborhood.neighborhoodName,
                serviceProviderId,
                PK: '', //set on server,
                SK: '', // set on server
                GSIPK1: '', //set on server,
                GSISK1: '', // set on server
                archetype: '', //not used for ExcludedNeighborhood type
              },
            });
          }
        });
        excludedNeighborhoodIds.forEach(x => {
          if (!originalExcludedNeighborhoodIdSet.has(x)) {
            // was newly added.
            let filteredOptions = excludedNeighborhoodOptions.filter(y => y.optionValue === x);

            if (filteredOptions.length > 0) {
              const _neighborhood = filteredOptions[0];
              excludedNeighborhoodDtos.push({
                delete: false,
                add: true,
                excludedNeighborhood: {
                  entityType: ExcludedNeighborhoodType,
                  id: providerCoverageAreaId,
                  neighborhoodId: _neighborhood.optionValue ?? '',
                  neighborhoodName: _neighborhood.optionText,
                  serviceProviderId,
                  PK: '', //set on server,
                  SK: '', // set on server
                  GSIPK1: '', //set on server,
                  GSISK1: '', // set on server
                  archetype: '', //not used for ExcludedNeighborhood type
                },
              });
            }
          }
        });
      }
    } else {
      //brand new so we are only adding
      excludedNeighborhoodIds.forEach(x => {
        let filteredOptions = excludedNeighborhoodOptions.filter(y => y.optionValue === x);
        if (filteredOptions.length > 0) {
          const _neighborhood = filteredOptions[0];
          excludedNeighborhoodDtos.push({
            delete: false,
            add: true,
            excludedNeighborhood: {
              entityType: ExcludedNeighborhoodType,
              id: providerCoverageAreaId,
              neighborhoodId: _neighborhood.optionValue ?? '',
              neighborhoodName: _neighborhood.optionText,
              serviceProviderId,
              PK: '', //set on server,
              SK: '', // set on server
              GSIPK1: '', //set on server,
              GSISK1: '', // set on server
              archetype: '', //not used for ExcludedNeighborhood type
            },
          });
        }
      });
    }
    return excludedNeighborhoodDtos;
  }

  async function getServiceProviderCoverageAreas(formContext:any, serviceProviderIdOption: IDropdownOption, serviceProviderMarketId: string) {
    onLoading();
    const providerCoverageAreaDtos = await findCoverageAreaDTOsByServiceProvider(serviceProviderIdOption.optionValue);
    const providerCoverageAreaIdToServiceOffering = await populateProviderCoverageAreaToServiceOfferingMap(providerCoverageAreaDtos.data);
    const marketCoverageAreaMap = await getMarketCoverageAreaMap(serviceProviderMarketId);
    let panToolHandlers:any[] = [];
    for (let i = 0; i < providerCoverageAreaDtos.data.length; i++) {
      panToolHandlers.push(createPanToolHandler());
    }
    update({
      ...get(),
      marketCoverageAreaMap,
      panToolHandlers,
      formData: {
        serviceProviderId: serviceProviderIdOption,
        providerCoverageAreas: convertForForm(providerCoverageAreaDtos.data, marketCoverageAreaMap),
      },
      originalProviderCoverageAreas: createProviderCoverageAreaMap(providerCoverageAreaDtos.data),
      providerCoverageAreaIdToServiceOffering: providerCoverageAreaIdToServiceOffering,
    });
    doneLoading(300);
  }

  async function getMarketCoverageAreaMap(marketId:string) {
    //TODO: at some point we will want to store this in the backend
    const dallasMarketId = 'f525b7f1-0e33-434a-b081-b40f2c1c478a'; //dallas in prod
    const charlotteMarketId = '05995c6a-a164-4a7a-99e9-a9e8efc84250'; //charlotte in prod and in dev environments

    var map:IMarketCoverageAreaMap;
    switch (marketId) {
      case dallasMarketId:
        map = dallasMarketCoverageArea;
        break;
      case charlotteMarketId:
        map = charlotteMarketCoverageArea;
        break;
      default:
        map = charlotteMarketCoverageArea;
        break;
    }
    return map;
  }

  async function onFindCoveredNeighborhoods(coverageArea:ICoverageArea, callback:Function) {
    const { id, name, excludedNeighborhoodIds, type, coordinates, new: isNew } = coverageArea;
    const geoJson = {
      type,
      coordinates,
    };
    const dto = {
      delete: false,
      add: false,
      providerCoverageArea: _createProviderCoverageArea(id, '', name, geoJson, isNew ),
      excludedNeighborhoods: [],
    };
    await debouncedFindCoveredNeighborhoods(dto, callback);
  }

  async function refreshCoverageAreasForProvider(serviceProviderId:string) {
    const { formData: currentFormData } = get();
    const providerCoverageAreaDtos = await findCoverageAreaDTOsByServiceProvider(serviceProviderId);
    const serviceProviderRes = await findServiceProviderById(serviceProviderId);
    const marketCoverageAreaMap = await getMarketCoverageAreaMap(serviceProviderRes.data.marketId);
    let panToolHandlers:any[] = [];
    for (let i = 0; i < providerCoverageAreaDtos.data.length; i++) {
      panToolHandlers.push(createPanToolHandler());
    }
    update({
      ...get(),
      marketCoverageAreaMap,
      formData: {
        ...currentFormData,
        providerCoverageAreas: convertForForm(providerCoverageAreaDtos.data, marketCoverageAreaMap),
      },
      originalProviderCoverageAreas: createProviderCoverageAreaMap(providerCoverageAreaDtos.data),
    });
  }

  function convertForForm(data:IProviderCoverageAreaDTO[], marketCoverageAreaMap:IMarketCoverageAreaMap):ICoverageArea[] {
    return data.map(x => {
      const { providerCoverageArea, excludedNeighborhoods } = x;
      return {
        bounds: marketCoverageAreaMap.bounds,
        center: marketCoverageAreaMap.center,
        excludedNeighborhoodIds: excludedNeighborhoods.map(y => y.excludedNeighborhood.neighborhoodId),
        excludedNeighborhoodOptions: excludedNeighborhoods.map(z => ({
          key: z.excludedNeighborhood.neighborhoodId,
          optionText: z.excludedNeighborhood.neighborhoodName,
          optionValue: z.excludedNeighborhood.neighborhoodId,
        })),
        id: providerCoverageArea.id ?? '',
        name: providerCoverageArea.name,
        new: false,
        type: providerCoverageArea.coverageAreaGeoJSON?.type ?? '',
        coordinates: providerCoverageArea.coverageAreaGeoJSON?.coordinates ?? [],
      };
    });
  }

  async function populateProviderCoverageAreaToServiceOfferingMap(providerCoverageAreaDtos:IProviderCoverageAreaDTO[]):Promise<Map<string, IServiceOfferingWithProviderCoverageArea[]>> {
    var map = new Map<string, IServiceOfferingWithProviderCoverageArea[]>();
    if (providerCoverageAreaDtos) {
      for (let i = 0; i < providerCoverageAreaDtos.length; i++) {
        const dto = providerCoverageAreaDtos[i];
        if (dto.providerCoverageArea.id) {
          const res = await findServiceOfferingsByProviderCoverageArea(dto.providerCoverageArea.id);
          if (res.data) {
            map.set(dto.providerCoverageArea.id, res.data);
          }
        }
      }
    }
    return map;
  }

  function createProviderCoverageAreaMap(data:IProviderCoverageAreaDTO[]):Map<string, IProviderCoverageAreaDTO> {
    var map = new Map<string, IProviderCoverageAreaDTO>();
    data.forEach(x => {
      if (x.providerCoverageArea.id) {
        map.set(x.providerCoverageArea.id, { ...x });
      }
    });
    return map;
  }

  function createProviderCoverageAreaDTOToDelete(data:IProviderCoverageAreaForm):IProviderCoverageAreaDTO[] {
    const toDelete:IProviderCoverageAreaDTO[] = [];
    const { originalProviderCoverageAreas } = get();
    var nextProviderCoverageAreaIds = data.providerCoverageAreas.filter(x => !x.new).map(x => x.id);
    originalProviderCoverageAreas.forEach((val, key, map) => {
      if (val.providerCoverageArea.id && nextProviderCoverageAreaIds.indexOf(val.providerCoverageArea.id) === -1) {
        //the old provider coverage area id was not in the new list, therefore set for delete
        toDelete.push({
          ...val,
          delete: true,
        });
      }
    });
    return toDelete;
  }

  function clearForm(formContext:any) {

    update({
      ...get(),
      formData: createEmptyProviderCoverageAreaForm(),
    });
  }

  function onPanToolHandlerUpdate(index:number) {
    const { panToolHandlers } = get();
    const nextPanToolHandlers = [...panToolHandlers];
    panToolHandlers[index].mapRefFunc();
    nextPanToolHandlers.splice(index, 1, panToolHandlers[index]);
    update({
      ...get(),
      panToolHandlers: nextPanToolHandlers,
    });
  }

  function onAddCoverageArea() {
    const { panToolHandlers } = get();
    const nextPanToolHandlers = [...panToolHandlers];
    nextPanToolHandlers.push(createPanToolHandler());
    update({
      ...get(),
      panToolHandlers: nextPanToolHandlers,
    });
  }
  function onRemoveCoverageArea(index:number) {
    const { panToolHandlers, formData } = get();
    const nextPanToolHandlers = [...panToolHandlers];
    nextPanToolHandlers.splice(index, 1);
    update({
      ...get(),
      panToolHandlers: nextPanToolHandlers,
    });
  }

  return {
    ...get(),
    loadingKey,
    init,
    save,
    getServiceProviderCoverageAreas,
    onFindCoveredNeighborhoods,
    clearForm,
    onPanToolHandlerUpdate,
    onAddCoverageArea,
    onRemoveCoverageArea,
  };
}