import { Accordion, AccordionDetails, AccordionSummary, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import CoverageAreaDrawableMap from 'components/gmaps/CoverageAreaDrawableMap';
import { IGeoJSONCoordinates, IMapBounds, ILatLng, createFromLatLng } from 'model/maps';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useFormContext, useWatch } from 'react-hook-form';
import { useEffect } from 'react';
import { RemoveButton } from 'components/buttons';
import RefreshButton from 'components/buttons/RefreshButton';
import { HookTextField } from 'components/reactHookForm';
import NeighborhoodMultiSelect from './NeighborhoodMultiSelect';
import useProviderCoverageArea from '../hookStore/useProviderCoverageArea';
import ServiceOfferingsWithProviderCoverage from './ServiceOfferingsWithProviderCoverageArea';
import CoveredNeighborhoods from './CoveredNeighborhoods';
import PanToolIcon from '@mui/icons-material/PanTool';

export interface ICoverageAreaProps {
  index: number;
  remove: any;
}

export default function CoverageArea({ index, remove }:ICoverageAreaProps) {
  const { setValue, getValues, control } = useFormContext();
  const { providerCoverageAreaIdToServiceOffering, marketCoverageAreaMap, panToolHandlers, onPanToolHandlerUpdate } = useProviderCoverageArea();

  const id = getValues(`providerCoverageAreas.${index}.id`);


  const serviceOfferingsWithProviderCoverage = providerCoverageAreaIdToServiceOffering.get(id);
  return (
    <Grid container item spacing={1} xs={12} sx={{ padding: '20px 0' }} justifyContent='center'>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Grid container alignItems='center'>
            <Grid item xs={6}>
              <HookTextField
                name={`providerCoverageAreas.${index}.name`}
                label='Name'
                required
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              />
            </Grid>
            <RemoveButton
              disabled={serviceOfferingsWithProviderCoverage && serviceOfferingsWithProviderCoverage?.length > 0}
              onClick={() => {
                remove(index);
              }}
              tooltipTitle='Delete coverage area'
            />
            <RefreshButton
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                const current = getValues(`providerCoverageAreas.${index}`);
                setValue(`providerCoverageAreas.${index}`, {
                  ...current,
                  coordinates: null,
                  type: 'POLYGON',
                });
              }}
              tooltipTitle='Reset map'
            />
            <Tooltip title={'enable polygon dragging. Red indicates it is not active.'} >
              <IconButton onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                onPanToolHandlerUpdate(index);

              }} >
                {panToolHandlers[index]?.draggableEnabled ? <PanToolIcon style={{ fill: 'green' }}/> : <PanToolIcon style={{ fill: 'red' }}/>}
              </IconButton>
            </Tooltip>
            <ServiceOfferingsWithProviderCoverage serviceOfferings={serviceOfferingsWithProviderCoverage} />
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <CoverageAreaDrawableMap
            id={getValues(`providerCoverageAreas.${index}.id`)}
            bounds={getValues(`providerCoverageAreas.${index}.bounds`) || marketCoverageAreaMap.bounds}
            center={getValues(`providerCoverageAreas.${index}.center`) || marketCoverageAreaMap.center}
            zoom={marketCoverageAreaMap.zoom}
            geoJson={getValues(`providerCoverageAreas.${index}`)}
            postPolygonComplete={(data:ILatLng[], skipRefresh:boolean) => {
              if (!skipRefresh) {
                const geocoordinates = data.map(x => createFromLatLng(x));
                const current = getValues(`providerCoverageAreas.${index}`);
                setValue(`providerCoverageAreas.${index}`, {
                  ...current,
                  coordinates: geocoordinates,
                  type: 'POLYGON',
                });
              } else {
                const geocoordinates = data.map(x => createFromLatLng(x));
                setValue(`providerCoverageAreas.${index}.coordinates`, geocoordinates );
              }
            }}
            panToolHandler={panToolHandlers[index]}
          />
        </AccordionDetails>
      </Accordion>
      <Grid item xs={4}>
        <NeighborhoodMultiSelect index={index}/>
        <CoveredNeighborhoods index={index}/>
      </Grid>

    </Grid>
  );
}