import { Grid } from '@mui/material';
import { HookCheckbox, HookTextField } from 'components/reactHookForm';
import { useEffect } from 'react';
import { useForm, FormProvider, useFormContext, useWatch } from 'react-hook-form';
import SubmitButton from './formComponents/SubmitButton';
import useMarkets from 'hooks/useMarkets';
import useProviderRecommendations from './hookStore/useProviderRecommendations';
import { IProviderRecommendationsForm, createEmptyProviderRecommendationsForm } from 'model/providerRecommendations';
import TerritorySelect from './formComponents/TerritorySelect';

export interface IProviderRecommendationsFormInternalProps {
  initialFormData:IProviderRecommendationsForm;
}


function ProviderRecommendationsFormInternal({ initialFormData }:IProviderRecommendationsFormInternalProps) {
  const formContext = useForm<IProviderRecommendationsForm>({ mode: 'onChange', defaultValues: createEmptyProviderRecommendationsForm() });
  const { } = useProviderRecommendations();

  useEffect(() => {
    formContext.reset(initialFormData);
  }, [initialFormData]);

  return (
    <FormProvider {...formContext}>
      <form >
        <Grid container spacing={2} sx={{ marginLeft: '0px' }}>
          <Grid item xs={12} sm={12} md={3} >
            <TerritorySelect/>
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={12} sm={12} md={2} >
              <HookTextField name="searchTerm" label="Search Term"/>
            </Grid>

          </Grid>
          <Grid container item xs={12}>
            <SubmitButton/>
          </Grid>

        </Grid>
      </form>
    </FormProvider>
  );
}

export default function ProviderRecommendationsForm() {
  const { formData, init } = useProviderRecommendations();
  const { market } = useMarkets();
  useEffect(() => {
    if (!market) {
      return;
    }
    void init(market);
  }, [market]);
  return (
    <ProviderRecommendationsFormInternal initialFormData={formData}/>
  );
}