import { useWatch } from 'react-hook-form';

export function VerificationMessage() {
  const isVerified = useWatch({ name: 'verified' });
  if (isVerified) {
    return null;
  }
  return (
    <p>Unverified users have not been initialized in the Streetfair system. Save with appropriate fields to verify.</p>
  );
}
