
import HelpIcon from '@mui/icons-material/Help';
import { IconButton } from '@mui/material';
import useTooltipDrawer from './useTooltipDrawer';

export interface ITooltipDrawerButtonProps {
  storeKey: string;
  iconButtonProps?:any;
}

export default function TooltipDrawerButton({
  storeKey,
  iconButtonProps,
}:ITooltipDrawerButtonProps,
) {
  const { openDrawer } = useTooltipDrawer(storeKey);
  return (
    <IconButton

      onClick={() => {
        openDrawer({});
      }}
      {...iconButtonProps}
    >
      <HelpIcon />
    </IconButton>
  );
}