import CustomFields from './CustomFields';
import ServiceDetailSections from './ServiceDetailSections';
import SubscriptionOptions from 'pages/serviceOffering/details/SubscriptionOptions';
import WorkflowSteps from 'pages/serviceOffering/details/WorkflowSteps';
import get from 'lodash/get';
import styles from '../providerOnboarding.module.css';
import useServiceOfferingForm from 'hooks/serviceOffering/useServiceOfferingForm';
import { CadenceType } from 'model/serviceOffering';
import { Grid, Tabs, Tab, Tooltip } from '@mui/material';
import { ServiceOfferingImageDropzone } from './imageUpload/ServiceOfferingImageDropzone';
import { useFormContext } from 'react-hook-form';
import { memo } from 'react';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = memo(function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`service-offering-details-tabpanel-${index}`}
      aria-labelledby={`service-offering-details-tab-${index}`}
      className={styles.tabPanel}
      {...other}
    >
      {children}
    </div>
  );
});

export default function ServiceOfferingFormTabsSection() {
  const {
    tabValue, setTabValue,
  } = useServiceOfferingForm(null);
  const formContext = useFormContext();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  function getSubscriptionTabTooltip(selectedCadenceType:string) {
    return selectedCadenceType===CadenceType.ONETIME_ONLY ?
      'One time only does not have subscriptions' : '';
  }

  const selectedCadenceType = formContext.watch('cadenceType');
  return (
    <><Grid item xs={12}>
      <Tabs
        value={tabValue}
        onChange={handleChange}
        aria-label="basic tabs example"
        variant="scrollable"
        scrollButtons="auto"
      >
        <Tab
          label={(
            <span className={get(formContext.formState.errors, 'serviceDetailSections', false) ? styles.errorTab : ''}>
              Service detail sections
            </span>)}
          {...a11yProps(0)} />
        <Tab
          style={{ pointerEvents: 'auto' }}
          label={<Tooltip
            title={getSubscriptionTabTooltip(selectedCadenceType)}>
            <span className={get(formContext.formState.errors, 'subscriptionOptions', false) ? styles.errorTab : ''}>
              Subscription options
            </span>
          </Tooltip>}
          disabled={selectedCadenceType === CadenceType.ONETIME_ONLY}
          {...a11yProps(1)} />
        <Tab
          label={(
            <span className={get(formContext.formState.errors, 'customFields', false) ? styles.errorTab : ''}>
              Custom fields
            </span>)}
          {...a11yProps(2)} />
        <Tab
          label={(
            <span className={get(formContext.formState.errors, 'workflowSteps', false) ? styles.errorTab : ''}>
              Workflow Steps
            </span>)}
          {...a11yProps(3)} />
        <Tab
          label={(
            <span>Images</span>
          )}
          {...a11yProps(3)} />
      </Tabs>
    </Grid>
    <Grid item xs={12} className={styles.tabBackground}>
      <TabPanel value={tabValue} index={0}>
        <ServiceDetailSections />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <SubscriptionOptions />
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <CustomFields />
      </TabPanel>
      <TabPanel value={tabValue} index={3}>
        <WorkflowSteps workflowSteps={formContext.getValues().workflowSteps} />
      </TabPanel>
      <TabPanel value={tabValue} index={4}>
        <ServiceOfferingImageDropzone />
      </TabPanel>
    </Grid>
    </>
  );
}

function a11yProps(index: number) {
  return {
    'id': `service-offering-details-tab-${index}`,
    'aria-controls': `service-offering-details-tabpanel-${index}`,
  };
}
