import { FormControl, FormHelperText, InputLabel } from '@mui/material';
import { HookSelect } from 'components/reactHookForm';
import useErrorState from 'hooks/reactHookForm/useErrorState';
import { NeighborConnectionStatus } from 'model/neighborConnections';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

export default function NeighborConnectionStatusSelect() {
  const formCtx = useFormContext();
  const [neighborConnectionStatuses] = useState<any[]>(NeighborConnectionStatus.list);
  const { isError, getErrorMessage } = useErrorState(formCtx);
  return (
    <FormControl variant='standard' fullWidth error={isError('neighborConnectionStatus')}>
      <InputLabel htmlFor="neighborConnectionStatus-select">Status</InputLabel>
      <HookSelect
        control={formCtx.control}
        name='status'
        id='neighborConnectionStatus-select'
        menuItems={neighborConnectionStatuses}
      />
      <FormHelperText>{getErrorMessage('neighborConnectionStatus')}</FormHelperText>
    </FormControl>
  );
}