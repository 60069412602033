import { Grid } from '@mui/material';
import { PrimaryButton } from 'components/buttons';
import { ConfirmationModalContext } from 'components/modals/ConfirmationModal';
import { IHomeSaleUploadForm } from 'model/neighborhoodUpload';
import { useContext } from 'react';
import { FormProvider, SubmitHandler, useForm, useWatch } from 'react-hook-form';
import HomeSaleTemplateUpload from './homeSaleTemplateUpload';

export default function HomeUploadForm() {
  const { openModal } = useContext(ConfirmationModalContext);

  const formContext = useForm<IHomeSaleUploadForm>({
    mode: 'onTouched',
    defaultValues: {
      homeSaleTemplate: undefined,
      fileName: undefined,
    },
  });

  const onSubmit : SubmitHandler<IHomeSaleUploadForm> = data => {
    openModal({
      data: data,
      fileName: data.homeSaleTemplate.name,
      formContext,
      shouldUpload: true,
    });
  };

  return (
    <FormProvider {...formContext}>
      <form onSubmit={formContext.handleSubmit(onSubmit)}>
        <Grid container spacing={1}>
          <Grid item xs={12}></Grid>
          <Grid item xs={4}>
            <HomeSaleTemplateUpload/>
          </Grid>
          <Grid item xs={11} >
            <PrimaryButton type='submit' style={{ marginTop: '10px' }}>Submit</PrimaryButton>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
}