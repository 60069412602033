import { useFormContext, useWatch } from 'react-hook-form';
import useWelcomePageShortLink from './welcomePageShortLinkHookStore';
import { useEffect, useState } from 'react';

export default function PreviewFullLink() {
  const { createWelcomePageUrl } = useWelcomePageShortLink();

  const formContext = useFormContext();
  const [fullUrl, setFullUrl] = useState('');
  const watchedNeighborhoodId = useWatch({ name: 'neighborhoodId', control: formContext.control });
  const watchedMarketingCode = useWatch({ name: 'mc', control: formContext.control });

  useEffect(() => {
    var nextUrl = createWelcomePageUrl({
      neighborhoodId: watchedNeighborhoodId,
      mc: watchedMarketingCode,
    });
    setFullUrl(nextUrl);
  }, [watchedNeighborhoodId, watchedMarketingCode]);

  return (
    <div>
      <div>Preview full URL (opens in new tab): </div>
      <a href={fullUrl} target="_blank">{fullUrl}</a>
    </div>
  );
}