import { Grid, Button } from '@mui/material';
import useConfirmationModal from 'components/modals/useConfirmationModal';
import { HookPriceField, HookTextField } from 'components/reactHookForm';
import { CompensateRewardRequest } from 'model/rewards';
import { useEffect } from 'react';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import useIndividualsHookStore from '../individualsHookStore';

export interface IUpdateIndividualRewardBalanceFormProps {
  onConfirm: SubmitHandler<CompensateRewardRequest>;
  onCancel:any;
}

export interface IUpdateIndividualRewardBalanceFormInternalProps {
  initialFormData: CompensateRewardRequest;
  onConfirm: SubmitHandler<CompensateRewardRequest>;
  onCancel:any;
}

function UpdateIndividualRewardBalanceFormInternal({ initialFormData, onConfirm, onCancel }:IUpdateIndividualRewardBalanceFormInternalProps) {
  const formContext = useForm<CompensateRewardRequest>({ mode: 'onTouched', defaultValues: initialFormData });
  const { isSubmitting, isValid } = formContext.formState;
  const { individual } = useIndividualsHookStore();

  useEffect(() => {
    formContext.reset(initialFormData);
  }, [initialFormData]);

  return (
    <FormProvider {...formContext}>
      <form onSubmit={formContext.handleSubmit(onConfirm)} style={{ width: '100%' }}>
        <Grid container spacing={1}>
          <Grid container item xs={12} columnGap={2} alignItems='center' sx={{ overflow: 'auto' }}>
            <Grid item xs={12} md={7}>
              <HookTextField
                required
                multiline
                maxRows={6}
                disabled={!individual || !individual.id}
                name='note'
                label='Note'
              />
            </Grid>
            <Grid item xs={5}>
              <HookPriceField
                required
                disabled={!individual || !individual.id}
                name='rewardsBalance'
                label='New Balance'
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} justifyContent={'flex-end'}>
            <Button
              variant='text'
              onClick={() => {
                formContext.reset(initialFormData);
                onCancel();
              }}
            >
              Cancel
            </Button>
            <Button
              disabled={isSubmitting || !isValid}
              variant='contained'
              type='submit'
            >
                  Save
            </Button>

          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
}

export default function UpdateIndividualRewardBalanceForm({
  onConfirm,
  onCancel,
}: IUpdateIndividualRewardBalanceFormProps) {
  const { formData, init } = useIndividualsHookStore();
  useEffect(() => {
    void init();
  }, []);
  return (
    <UpdateIndividualRewardBalanceFormInternal
      initialFormData={formData}
      onConfirm={onConfirm}
      onCancel={onCancel}
    />
  );
}