import { Button, Grid } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { getServiceOfferingStatusCheck } from 'api/serviceOfferingApi';
import { deleteServiceProvider, getServiceProviderKey, searchServiceProviders } from 'api/serviceProviderApi';
import { EditButton, KeyButton, RemoveButton, ToggleButton } from 'components/buttons';
import CopyToClipboardCell from 'components/DataGridCell/CopyToClipboard';
import QuickSearchToolbar from 'components/grid/QuickSearchToolbar';
import useTableSearch from 'components/grid/useTableSearch';
import useModal from 'components/modal/useModal';
import ConfirmationModal, { ConfirmationModalContext, ConfirmationModalContextProvider } from 'components/modals/ConfirmationModal';
import PageHeader from 'components/SectionHeaders/PageHeader';
import useToast from 'components/toast/useToast';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { sortByString } from 'util/sortFunctions';
import DisableNsoModal, { createEmptyDisableNsoModal, IDisableNsoModal } from './DisableNsoModal';
import styles from './serviceProviders.module.css';
import { IServiceProvider } from 'model/serviceProvider';
import { ISearchPage, IServiceProviderSearchRequest, IStreetFairSearchRequest } from 'model/search';
import debounce from 'lodash.debounce';
import CreateApiKeyModal, { ICreateApiKeyModal } from './CreateApiKeyModal';
import useMarkets from 'hooks/useMarkets';


const debouncedSearchServiceProviders = debounce(async (dto:IStreetFairSearchRequest, callback:(serviceProvderSearchResultType) => any) => {
  const res = await searchServiceProviders(dto);
  callback(res.data);
}, 400);

function DeleteCell({ id, name }) {
  const confirmationModalCtx = useContext(ConfirmationModalContext);
  return (
    <RemoveButton
      tooltipTitle='Remove service provider'
      onClick={() => confirmationModalCtx.openModal({ id, name })}
      tooltipProps={{
        placement: 'top',
      }}
      iconButtonProps={{
        className: styles.actionButton,
      }}
    />
  );
}

function EditCell({ id, name }) {
  const navigate = useNavigate();
  function handleClick() {
    navigate(`/service-providers/${id}/service-provider-details`);
  }
  return (
    <EditButton
      tooltipTitle={`Edit ${name}`}
      onClick={handleClick}
      tooltipProps={{
        placement: 'top',
      }}
      iconButtonProps={{
        className: styles.actionButton,
      }}
    />
  );
}

function ApiKeyGenCell({ id, name }:any) {
  const { openModal } = useModal('createServiceProviderApiKeyModal');

  return (
    <KeyButton
      tooltipTitle={`Create API key for ${name}`}
      tooltipProps={{
        placement: 'top',
      }}
      iconButtonProps={{
        className: styles.actionButton,
      }}
      onClick={async () => {
        const apiKeyRes = await getServiceProviderKey(id);
        const hasApiKey = apiKeyRes.data != '' && apiKeyRes.data != null;
        const state:ICreateApiKeyModal = {
          id,
          name,
          hasApiKey,
          createdApiKey: apiKeyRes.data,
        };
        openModal(state);
      }}
    />
  );
}

function ToggleCell({ id, name, wasEnabled }:any) {
  const { openModal } = useModal('disableNsoModal');

  return (
    <ToggleButton
      toggled={wasEnabled}
      tooltipTitle={`Toggle ${name}`}
      onClick={async () => {
        const serviceOfferingStatusCheckRes = await getServiceOfferingStatusCheck(id);
        const state:IDisableNsoModal = {
          id,
          name,
          wasEnabled,
          serviceOfferingStatusCheckDTO: serviceOfferingStatusCheckRes.data,
        };
        openModal(state);
      }}
      tooltipProps={{
        placement: 'top',
      }}
      iconButtonProps={{
        className: styles.actionButton,
      }}
    />
  );
}

const columns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Name',
    width: 250,
    renderCell: (params) => {
      return <a target="_blank" href={`${window.REACT_APP_PROVIDER_BASE_URI}/v1/${params.row.id ?? params.row.objectID}`} >{params.value}</a>;
    },
  },
  {
    field: 'contactPersonPhone',
    headerName: 'Phone',
    width: 125,
    renderCell: (params:any) => {
      return <span>{params.row.contactPersonPhone || 'No phone set'}</span>;
    },
  },
  {
    field: 'contactPersonEmail',
    headerName: 'Email',
    width: 250,
    renderCell: (params:any) => {
      return <span>{params.row.contactPersonEmail || 'No email set'}</span>;
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 125,
    renderCell: (params:any) => {
      return <span>{params.row.status}</span>;
    },
  },
  {
    field: 'id',
    headerName: 'GUID',
    width: 350,
    renderCell: (params:any) => {
      return (<CopyToClipboardCell val={params.id ?? params.row.objectID}/>);
    },
  },
  {
    field: 'actions',
    headerName: 'Actions',
    width: 200,
    renderCell: (params:any) => {


      return (
        <Grid container item xs={12} justifyContent='center' alignItems='center'>
          <ApiKeyGenCell id={params.id ?? params.row.objectID} name={params.row.name}/>
          <ToggleCell
            id={params.id ?? params.row.objectID}
            name={params.row.name}
            wasEnabled={!params.row.disabledNeighborhoodServiceOfferingsDate}
          />
          <EditCell id={params.id ?? params.row.objectID} name={params.row.name}/>
          <DeleteCell id={params.id ?? params.row.objectID} name={params.row.name}/>
        </Grid>
      );
    },
  },
];
const columnTotalWidth = columns.map(x => x.width).reduce((a:any, b:any) => a+b, 0);
const quickSearchStorageKey = 'serviceProviders';
export default function ServiceProviders() {
  const navigate = useNavigate();
  const { selectedMarket } = useMarkets();
  const [serviceProviders, setServiceProviders] = useState<any[]>([]);
  const { createErrorToast, createSuccessToast } = useToast();
  const {
    searchResultsLoading,
    rows,
    rowCountState,
    searchText,
    paginationModel,
    setRowsWithStoredFilter,
    setSearchResultsLoading,
    // requestSearch,
    clearStoredSearch,
    setRowCountState,
    requestAsyncSearch,
    onPageChange,
    onPageSizeChange,
    setPaginationModel,
  } = useTableSearch(quickSearchStorageKey, [], 10);

  const [sortModel, setSortModel] = useState([
    {
      field: 'name',
      sort: 'asc' as any,
    },
  ]);

  async function _findAllServiceProviders() {
    setSearchResultsLoading(true);
    var searchRequest:IServiceProviderSearchRequest = {
      offset: 0,
      pageNumber: paginationModel.page,
      pageSize: paginationModel.pageSize,
      query: searchText,
      marketId: selectedMarket?.id,
    };

    debouncedSearchServiceProviders(searchRequest, (data:ISearchPage<IServiceProvider>) => {
      setRowCountState(data.availablePagesCount * data.pageSize);
      setRowsWithStoredFilter(data.items);
      setSearchResultsLoading(false);
    });
  }

  useEffect(() => {

    if (selectedMarket) {
      void _findAllServiceProviders();
    }

  }, [paginationModel, selectedMarket]);

  function addNewButtonClick(e: any) {
    navigate('/service-providers/create/service-provider-details');
  }

  async function _deleteServiceProvider({ id }) {
    try {
      await deleteServiceProvider(id);
      clearStoredSearch();
      location.reload();
    } catch (e:any) {
      console.error(`Unable to delete service provider. Message=${e.response?.data?.message}`);
      createErrorToast(`Unable to delete service provider. Message=${e.response?.data?.message}`);
    }
  }

  function postToggle(serviceProviderId:string, wasEnableRequest:boolean) {
    const pseudoUpdatedServiceProviders = serviceProviders.map(x => {
      if (x.id === serviceProviderId && wasEnableRequest) {
        x.disabledNeighborhoodServiceOfferingsDate = null;
      } else if (x.id === serviceProviderId && !wasEnableRequest) {
        x.disabledNeighborhoodServiceOfferingsDate = new Date();
      }
      return x;
    });
    setServiceProviders(pseudoUpdatedServiceProviders);
    setRowsWithStoredFilter(pseudoUpdatedServiceProviders);
    createSuccessToast( 'Success!' );
  }

  async function postCreateApiKey(serviceProviderId:string, apiKey:string) {
    void navigator.clipboard.writeText(apiKey);
    createSuccessToast('api key copied to clipboard');

  }

  return (
    <ConfirmationModalContextProvider onConfirm={_deleteServiceProvider}>
      <Grid container spacing={1} className='pageGridContainer'>
        <Grid item xs={12}>
          <PageHeader headerText={'SERVICE PROVIDERS'} />
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" onClick={addNewButtonClick}>Add New</Button>
        </Grid>
        <Grid item xs={12}>
          <DataGrid
            loading={searchResultsLoading}
            pagination={true}
            rowsPerPageOptions={[10, 20, 50]}
            page={paginationModel.page}
            pageSize={paginationModel.pageSize}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            getRowId={(row) => row.objectID ?? row.id}
            paginationMode='server'
            rowCount={rowCountState}
            rows={rows}
            components={{ Toolbar: QuickSearchToolbar }}
            componentsProps={{
              toolbar: {
                quickSearchStorageKey,
                value: searchText,
                onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                  void requestAsyncSearch(debouncedSearchServiceProviders,
                    {
                      offset: 0,
                      pageNumber: paginationModel.page,
                      pageSize: paginationModel.pageSize,
                      query: event.target.value,
                      marketId: selectedMarket?.id,
                    },
                  );
                },
                clearSearch: () => {
                  setPaginationModel({
                    page: 0,
                    pageSize: paginationModel.pageSize,
                  });
                  clearStoredSearch();
                  void requestAsyncSearch(debouncedSearchServiceProviders,
                    {
                      offset: 0,
                      pageNumber: 0,
                      pageSize: paginationModel.pageSize,
                      query: '',
                      marketId: selectedMarket?.id,
                    });
                },
              },
            }}
            columns={columns}
            autoHeight={true}
            sortModel={sortModel}
            onSortModelChange={setSortModel}
            style={{
              minWidth: '500px',
              maxWidth: `${columnTotalWidth}px`,
            }}
          />
        </Grid>
      </Grid>
      <ConfirmationModal
        title={(onConfirmArgs) => (<div className={styles.confirmDeleteModalTitle}>Delete service provider</div>)}
        message={(onConfirmArgs:any) => (
          <Grid container spacing={1} alignItems='center' justifyContent='center' className={styles.confirmDeleteModal}>
            <Grid item xs>
                  You are about to delete {onConfirmArgs.name}.
            </Grid>
          </Grid>
        )}
      />
      <DisableNsoModal
        postOnConfirm={postToggle}
      />
      <CreateApiKeyModal
        postOnConfirm={postCreateApiKey}
      />
    </ConfirmationModalContextProvider>
  );
}