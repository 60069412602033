import { Grid, FormControlLabel, Checkbox } from '@mui/material';
import { HookCheckbox } from 'components/reactHookForm';
import { useFormContext } from 'react-hook-form';

export interface IServiceOfferingSeedingRowProps {
  serviceOfferingProjection: any;
  index: number;
}


export default function ServiceOfferingSeedingRow({
  index,
  serviceOfferingProjection,
}:IServiceOfferingSeedingRowProps,
) {
  const formContext = useFormContext();
  return (
    <Grid container item sx={{ paddingTop: '16px' }}>
      <Grid item xs='auto' >
        <HookCheckbox
          name={`serviceOfferingDtos.${index}.checked`}
          label={serviceOfferingProjection.label}
          postOnChange={(e:any) => {
            if (e.target.checked) {
              formContext.setValue(`serviceOfferingDtos.${index}.id`, serviceOfferingProjection.id);
            } else {
              formContext.setValue(`serviceOfferingDtos.${index}.id`, null);
            }
          }}
        />
      </Grid>
    </Grid>
  );
}