import { useLoading } from 'components/Layout/Loading';
import createStore from 'hooks/hookStore';
import { useEffect, useState } from 'react';
import { IMarket } from 'model/markets';
import { IDropdownOption } from 'model/dropdown';
import useToast from 'components/toast/useToast';
import { findServiceOfferingsByProvider } from 'api/serviceOfferingApi';
import { IServiceOffering } from 'model/serviceOffering';
import { RemoveProviderFromTemplateModel } from 'model/seeding/removeProviderFromTemplates';
import { removeProviderFromSeedingTemplates } from 'api/territorySeedingTemplatesApi';

export const loadingKey = 'RemoveProviderFromTemplatesStore';
type RemoveProviderFromTemplatesStore = {
  formData:RemoveProviderFromTemplateModel.IRemoveRequestForm;
  availableServiceOfferings: any[];
  market: IMarket | null;
}

const { get, update, registerListener, unregisterListener } = createStore<RemoveProviderFromTemplatesStore>('RemoveProviderFromTemplatesStore', {
  formData: RemoveProviderFromTemplateModel.createEmptyForm(),
  availableServiceOfferings: [],
  market: null,
});

export default function useRemoveProviderFromTemplates() {
  const setState = useState(get())[1];
  const { onLoading, doneLoading } = useLoading(loadingKey);
  const { createErrorToast, createSuccessToast } = useToast();

  useEffect(() => {
    registerListener(setState);
    return () => {
      update({
        ...get(),
        formData: RemoveProviderFromTemplateModel.createEmptyForm(),
        availableServiceOfferings: [],
        market: null,
      });
      unregisterListener(setState);
    };
  }, []);


  async function init(market:IMarket | null) {
    if (market == null) {
      console.log('waiting to load market');
      return;
    }
    onLoading();
    try {
      update({
        ...get(),
        market,
      });
    } catch (e:any) {
      console.error(e);
    }
    doneLoading(300);
  }

  async function onProviderSelect(providerSelection: IDropdownOption) {
    const { optionValue: id } = providerSelection;
    onLoading();
    const serviceOfferingMap = {};
    (await findServiceOfferingsByProvider(id)).data.forEach((m: IServiceOffering) => {
      const mapValue = {
        key: m.id,
        id: m.id,
        label: m.name,
        checked: false,
        seedFactor: null,
        ancillary: m,
      };
      if (m.id) {
        serviceOfferingMap[m.id] = mapValue;
      }
    });

    const serviceOfferings = Object.values(serviceOfferingMap);
    update({
      ...get(),
      availableServiceOfferings: serviceOfferings,
    });
    doneLoading(300);
  }


  async function onSave(formData: RemoveProviderFromTemplateModel.IRemoveRequestForm) {
    onLoading();
    try {
      var dto = RemoveProviderFromTemplateModel.convertFormToDto(formData, get().market!);
      var hasSelectedServiceOfferings = dto.serviceOfferingIds.length > 0;

      if (dto.serviceProviderId == '' || dto.serviceProviderId == null) {
        createErrorToast('Please select a provider');
        return;
      }
      if (!hasSelectedServiceOfferings) {
        createErrorToast('Please select at least one service offering');
        return;
      }
      await removeProviderFromSeedingTemplates(dto);
      createSuccessToast('Successfully removed provider from templates');
    } catch (e) {
      console.error(e);
      createErrorToast('Failed to remove provider from templates');
    } finally {
      doneLoading();
    }

  }


  return {
    ...get(),
    loadingKey,
    init,
    onProviderSelect,
    onSave,
  };
}