import createStore from 'hooks/hookStore';
import useToast from 'components/toast/useToast';
import { useLoading } from 'components/Layout/Loading';
import { useEffect, useState } from 'react';
import { IMarket } from 'model/markets';
import { deleteTsoiManagementBatch, findTsoiManagementBatchesByMarket } from 'api/tsoiManagementApi';

const loadingKey = 'TsoiManagementBatchesStore';
type TsoiManagementBatchesStore = {
  batches: any[];
  tableRows: any[];
}

const { get, update, registerListener, unregisterListener } = createStore<TsoiManagementBatchesStore>('TsoiManagementBatchesStore', {
  batches: [],
  tableRows: [],
});

export default function useTsoiManagementBatches() {
  const setState = useState(get())[1];
  const { onLoading, doneLoading } = useLoading(loadingKey);
  const { createErrorToast } = useToast();

  useEffect(() => {
    registerListener(setState);
    return () => {
      unregisterListener(setState);
    };
  }, []);

  async function init(market:IMarket | null) {
    if (market == null) {
      console.log('waiting to load market');
      return;
    }
    onLoading();
    try {
      var resp = await findTsoiManagementBatchesByMarket(market.id);
      update({
        ...get(),
        batches: resp.data,
      });

    } catch (e:any) {
      createErrorToast('Error loading batches');
      console.error(e);
    }
    doneLoading(300);
  }

  // batchId to delete the batch, market to retrieve the updated list of batches
  async function deleteBatch(batchId: string, market:IMarket | null) {
    onLoading();
    try {

      await deleteTsoiManagementBatch(batchId);
      const updatedBatches = await findTsoiManagementBatchesByMarket(market?.id);
      update({
        ...get(),
        batches: updatedBatches.data,
      });

    } catch (e:any) {
      const errorMessage = e.response?.data?.message || 'unknown error';
      createErrorToast(`Error deleting batch: ${errorMessage}`);
      console.error(e);
    }
    doneLoading(300);
  }

  return {
    ...get(),
    deleteBatch,
    loadingKey,
    init,
  };
}