import { FormControl, FormHelperText, InputLabel } from '@mui/material';
import { HookSelect } from 'components/reactHookForm';
import useErrorState from 'hooks/reactHookForm/useErrorState';
import { LaunchLeaderType } from 'model/neighborhood';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

export default function LaunchLeaderTypeSelect() {
  const formCtx = useFormContext();
  const [launchLeaderTypes] = useState<any[]>(LaunchLeaderType.list);
  const { isError, getErrorMessage } = useErrorState(formCtx);
  return (
    <FormControl variant='standard' fullWidth error={isError('launchLeaderType')}>
      <InputLabel htmlFor="launchLeaderType-select">Launch Leader Type</InputLabel>
      <HookSelect
        control={formCtx.control}
        name='launchLeaderType'
        id='launchLeaderType-select'
        menuItems={launchLeaderTypes}
      />
      <FormHelperText>{getErrorMessage('launchLeaderType')}</FormHelperText>
    </FormControl>
  );
}