import { useLoading } from 'components/Layout/Loading';
import createStore from 'hooks/hookStore';
import { useEffect, useState } from 'react';
import { IMarket } from 'model/markets';
import { IDropdownOption } from 'model/dropdown';
import { findTerritoriesByMarket } from 'api/territoriesApi';
import { useNavigate, useParams } from 'react-router-dom';
import { ITerritory, createUnassignedTerritoryOption } from 'model/territories';
import useToast from 'components/toast/useToast';
import { findServiceOfferingsByProvider } from 'api/serviceOfferingApi';
import { IServiceOffering } from 'model/serviceOffering';
import { ICreateTerritorySeedingTemplateRequest, IServiceOfferingWithSeedFactor } from 'model/territorySeedingTemplates';
import useTerritorySeedingTemplates from '../hookStore/useTerritorySeedingTemplates';
import { saveTerritorySeedingTemplates } from 'api/territorySeedingTemplatesApi';
import { create } from 'domain';

const loadingKey = 'AddProviderTemplateDrawer';
type AddProviderTemplateDrawer = {
  selectedProviderId:string;
  selectedProviderName: string;
  availableServiceOfferingsMap: object;
  serviceOfferingsToAdd: object[];
}

function getEmptyDrawerState() {
  return {
    selectedProviderId: '',
    selectedProviderName: '',
    availableServiceOfferingsMap: {},
    serviceOfferingsToAdd: [],
  };
}

const { get, update, registerListener, unregisterListener } = createStore<AddProviderTemplateDrawer>('AddProviderTemplateDrawer', getEmptyDrawerState());


export default function useAddProviderTemplateDrawer() {
  const setState = useState(get())[1];
  const { createErrorToast, createSuccessToast, createInfoToast } = useToast();
  const { onLoading, doneLoading } = useLoading(loadingKey);

  useEffect(() => {
    registerListener(setState);
    return () => {
      unregisterListener(setState);
    };
  }, []);

  async function init() {

  }

  async function onProviderSelect(providerSelection: IDropdownOption) {
    const { optionValue: id, optionText: name } = providerSelection;
    onLoading();
    const serviceOfferingmap = {};
    (await findServiceOfferingsByProvider(id)).data.forEach((m: IServiceOffering) => {
      const mapValue = {
        key: m.id,
        id: m.id,
        label: m.name,
        checked: false,
        seedFactor: null,
        ancillary: m,
      };
      if (m.id) {
        serviceOfferingmap[m.id] = mapValue;
      }
    });

    update({
      ...get(),
      selectedProviderId: id,
      selectedProviderName: name,
      availableServiceOfferingsMap: serviceOfferingmap,
    });
    doneLoading(300);
  }

  function toggleServiceOfferingCheckbox(serviceOfferingId: string) {
    const serviceOfferingMap = get().availableServiceOfferingsMap;
    const serviceOffering = serviceOfferingMap[serviceOfferingId];
    serviceOffering.checked = !serviceOffering.checked;
    update({
      ...get(),
      availableServiceOfferingsMap: serviceOfferingMap,
    });
  }

  function onServiceOfferingSeedFactorChange(serviceOfferingId: string, seedFactor: number) {
    const serviceOfferingMap = get().availableServiceOfferingsMap;
    const serviceOffering = serviceOfferingMap[serviceOfferingId];
    serviceOffering.seedFactor = seedFactor;
    update({
      ...get(),
      availableServiceOfferingsMap: serviceOfferingMap,
    });
  }

  async function onSave(selectedTerritory, formData) {
    onLoading();
    try {
      var serviceOfferingsMap = get().availableServiceOfferingsMap;
      var selectedserviceOfferingsToAdd = Object.values(serviceOfferingsMap)
        .reduce((acc: IServiceOfferingWithSeedFactor[], serviceOffering: any) => {
          if (serviceOffering.checked) {
            acc.push({ id: serviceOffering.id, seedFactor: serviceOffering.seedFactor });
          }
          return acc;
        }, []);

      if (selectedserviceOfferingsToAdd.length === 0) {
        createErrorToast('Please select at least one service offering');
        doneLoading();
        return;
      }

      const request: ICreateTerritorySeedingTemplateRequest = {
        providerId: get().selectedProviderId,
        providerName: get().selectedProviderName,
        serviceOfferingsWithSeedFactor: selectedserviceOfferingsToAdd,
        zipcodeTerritory: selectedTerritory,
      };

      await saveTerritorySeedingTemplates(request);
      createSuccessToast('Successfully saved seeding template');
      update(getEmptyDrawerState());

    } catch (e) {
      console.error(e);
      createErrorToast('Failed to save seeding template');
    }
    doneLoading();
  }

  function clearData() {
    update(getEmptyDrawerState());
  }

  return {
    ...get(),
    clearData,
    init,
    loadingKey,
    onProviderSelect,
    onSave,
    onServiceOfferingSeedFactorChange,
    toggleServiceOfferingCheckbox,
  };
}