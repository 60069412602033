import { SecondaryButton } from 'components/buttons';
import { useNavigate } from 'react-router-dom';

export default function BackToTableButton() {
  const navigate = useNavigate();
  return (
    <SecondaryButton
      sx={{
        '&&': {
          marginTop: '10px',
          marginLeft: '20px',
          minWidth: '60px',
          width: '60px',
          fontSize: '10px',
        },

      }}
      onClick={() => {
        navigate('/serviceTypeInfoTabs');
      }}
    >
      <>Back</>
    </SecondaryButton>
  );
}