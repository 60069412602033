import { AlertColor } from '@mui/material';
import createStore from 'hooks/hookStore';
import { useEffect, useState } from 'react';


type ConfirmationModal = {
  open:boolean;
  onConfirmArgs:any;
}


export default function useConfirmationModal(key:string) {
  const { get, update, registerListener, unregisterListener } = createStore<ConfirmationModal>(key, {
    open: false,
    onConfirmArgs: {},
  }, true);
  const setState = useState(get())[1];

  useEffect(() => {
    registerListener(setState);
    return () => {
      unregisterListener(setState);
    };
  }, []);

  function openModal(onConfirmArgs) {
    update({
      open: true,
      onConfirmArgs,
    });
  }

  function confirmModal(onConfirm) {
    const { onConfirmArgs } = get();
    onConfirm(onConfirmArgs);
    update({
      open: false,
      onConfirmArgs: {},
    });
  }

  function cancelModal() {
    update({
      open: false,
      onConfirmArgs: {},
    });
  }


  return {
    ...get(),
    openModal,
    confirmModal,
    cancelModal,
  };
}