import CadenceTypeSelect from '../formComponents/CadenceTypeSelect';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Loading, { useLoading } from 'components/Layout/Loading';
import ServiceOfferingFormTabsSection from './ServiceOfferingFormTabsSection';
import ServiceTypeSelect from '../formComponents/ServiceTypeSelect';
import styles from '../providerOnboarding.module.css';
import useProviderSetupStore from '../useProviderSetupStore';
import useToast from 'components/toast/useToast';
import {
  CadenceType, IFormFriendlyServiceDetailSection, IServiceDetailSection, IServiceOffering, IServiceOfferingFormDTO,
} from 'model/serviceOffering';
import { Accordion, AccordionSummary, Card, Grid, Typography } from '@mui/material';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { HookTextField, HookCheckbox, HookIntegerField } from 'components/reactHookForm';
import { PrimaryButton } from 'components/buttons';
import { createDefaultDiscSchedByServiceAndCadence, createDefaultServiceOfferingForProviderOnboarding, createInitialServiceOfferingFormDataFromExisting } from '../helper';
import { findServiceTypeInfo } from 'api/serviceCategoryApi';
import { saveServiceOffering } from 'api/serviceOfferingApi';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { saveNeighborhoodServiceOffering } from 'api/neighborhoodServiceOfferingApi';
import { INeighborhoodServiceOffering } from 'model/neighborhoodServiceOffering';
import { DiscountScheduleTypes } from 'model/discountSchedule';
import { saveDiscountSchedule } from 'api/discountScheduleApi';
import { ProviderSelect } from './ProviderSelect';
import ServiceOfferingCloneOptionsSelect from './ServiceOfferingCloneOptionsSelect';
import useMarkets from 'hooks/useMarkets';
import { ServiceTypeInfoUtil } from 'util/serviceType/serviceTypeInfoUtil';
import { seedCustomerFromReferral } from 'api/popularityApi';
import { maybeSendReferringIndividualProviderLaunchedEmail } from 'api/serviceProviderApi';
import CloneWithImagesCheckbox from './CloneWithImagesCheckbox';

const loadingKey = 'ProviderOnboardingServiceOfferingForm';

function convertSectionDetailSectionOriginal(serviceDetailSection:IFormFriendlyServiceDetailSection):IServiceDetailSection {
  return {
    sectionHeaderName: serviceDetailSection.sectionHeaderName,
    sectionIcon: serviceDetailSection.sectionIcon,
    sectionItems: serviceDetailSection.sectionItems ? serviceDetailSection.sectionItems.map(x => (x.val)) : [],
    sectionListItems: serviceDetailSection.sectionListItems ? serviceDetailSection.sectionListItems.map(x => (x.val)) : [],
  };
}

export default function ServiceOfferingForm() {
  const {
    cadenceType,
    currentServiceOffering,
    files,
    init,
    serviceType,
    setCadenceType,
    setCurrentServiceOffering,
    provider,
  } = useProviderSetupStore();


  const { market } = useMarkets();
  const { providerId, serviceOfferingId } = useParams();
  const defaultValues = currentServiceOffering?.id ?
    createInitialServiceOfferingFormDataFromExisting(currentServiceOffering) :
    createDefaultServiceOfferingForProviderOnboarding({ providerId });
  const formContext = useForm<IServiceOffering>({
    mode: 'onChange',
    defaultValues: defaultValues,
  });
  const { onLoading, doneLoading } = useLoading(loadingKey);
  const { createErrorToast } = useToast();
  const { serviceTypeId } = formContext.getValues();
  const navigate = useNavigate();

  useEffect(() => {
    void init(market);
  }, [market]);

  useEffect(() => {
    if (currentServiceOffering?.id) {
      formContext.reset(createInitialServiceOfferingFormDataFromExisting(currentServiceOffering));
    }
  }, [currentServiceOffering]);


  async function convertDataToSave(data:IServiceOffering): Promise<IServiceOfferingFormDTO> {
    const dataToSave = { ...data };
    dataToSave.providerId = providerId!;
    if (dataToSave.cadenceType === 'ONETIME_ONLY') {
      delete dataToSave.subscriptionOptions;
    }
    if (!serviceOfferingId) {
      //if we are creating/cloning a service offering, we don't want to carry over the providerCoverageAreaId
      dataToSave.providerCoverageAreaId = null;
    }

    //convert serviceDetailSections.sectionItems and serviceDetailSections.sectionListItems back to
    //original form
    dataToSave.serviceDetailSections = data.serviceDetailSections
      .map(x => convertSectionDetailSectionOriginal(x));
    if (dataToSave.customFields) {
      dataToSave.customFields.forEach((customField) => {
        if (customField.customFieldGroupType === '') {
          //empty string won't deserialize into an enum
          customField.customFieldGroupType = null;
        }
        customField.customFieldOptions.forEach((customFieldOption) => {
          customFieldOption.optionValue = customFieldOption.optionText;
        });
      });
    }


    const serviceTypeInfoRes = await findServiceTypeInfo();
    const serviceTypeInfo = serviceTypeInfoRes.data;
    let sampleNames = ServiceTypeInfoUtil.findMatchingServiceCategoryAndServiceTypeNames(dataToSave.serviceTypeId, serviceTypeInfo);
    dataToSave.serviceType = sampleNames?.serviceTypeName ?? '';
    dataToSave.serviceCategory = sampleNames?.serviceCategoryName ?? '';


    let formDto:IServiceOfferingFormDTO = {
      serviceOffering: dataToSave,
      existingDefaultServiceOffering: null,
      hardDeletedImageRefs: [],
    };
    return formDto;
  }

  async function createDefaultDiscountSchedule() {
    let data = createDefaultDiscSchedByServiceAndCadence(serviceType, cadenceType);

    if (data.type === DiscountScheduleTypes.NoDiscount) {
      delete data.discountSchedule;
      delete data.pricingSchedule;
      delete data.flatRateSchedule;
    } else if (data.type === DiscountScheduleTypes.DollarPerCustomer) {
      delete data.discountSchedule;
      delete data.flatRateSchedule;
    } else if (data.type === DiscountScheduleTypes.DollarSavePerCustomer) {
      delete data.pricingSchedule;
      delete data.flatRateSchedule;
    } else if (data.type === DiscountScheduleTypes.DollarFlatRate) {
      delete data.pricingSchedule;
      delete data.discountSchedule;
    } else if (data.type === DiscountScheduleTypes.PercentFlatRate) {
      delete data.pricingSchedule;
      delete data.discountSchedule;
    }
    if (providerId) {
      data.providerId = providerId;
    }
    if (currentServiceOffering?.serviceTypeId) {
      data.serviceTypeId = currentServiceOffering.serviceTypeId;
    }
    return saveDiscountSchedule(data);
  }

  async function generateNso(provId, soId, discSchedId, neighborhoodId = window.REACT_APP_NEIGHBORVILLE_UUID) {
    if (!provId || !soId || !discSchedId) {
      console.log('missing required data to generate NSO');
      return;
    }
    const nsoName = window.REACT_APP_NODE_ENV === 'production' ? 'Neighborville' : 'Northstone';

    let nso:INeighborhoodServiceOffering = {
      name: `${serviceType} in ${nsoName}`,
      altName: '',
      serviceOfferingId: soId,
      discountScheduleId: discSchedId,
      neighborhoodId: neighborhoodId,
      providerId: provId,
      disabledDate: null,
    };

    const request = {
      neighborhoodServiceOfferings: [nso],
    };

    try {
      return await saveNeighborhoodServiceOffering(request);
    } catch (error) {
      console.error('error creating NSO: ', { error, request });
    }
  }

  const onSubmit : SubmitHandler<IServiceOffering> = async data => {
    try {
      onLoading();
      if (data.subscriptionOptions?.options) {
        var options = data.subscriptionOptions?.options;
        if (options.filter(x => x.isOneTime).length > 0 && data.cadenceType === CadenceType.SUBSCRIPTION_ONLY) {
          throw new Error('Cannot save a subscription only service offering with a one-time option.');
        }
      }
      if (!data.defaultDiscountScheduleId) {
        const defaultDiscountSchedule = await createDefaultDiscountSchedule();
        data.defaultDiscountScheduleId = defaultDiscountSchedule?.data.id;
      }
      const formDto = await convertDataToSave(data);

      var formData = new FormData();
      formData.append('dto', new Blob([JSON.stringify(formDto)], {
        type: 'application/json',
      }));
      for (let i = 0; i < files.length; i++) {
        formData.append('images', files[i]);
      }

      await setCadenceType(data.cadenceType);
      const serviceOfferingResp = await saveServiceOffering(formData, currentServiceOffering?.id);
      await setCurrentServiceOffering(serviceOfferingResp?.data);

      // if serviceOfferingId is present in the path,
      // we are updating an existing service offering
      // and we don't need to generate an NSO
      if (!serviceOfferingId) {
        // generate NSO in neighborville (or northstone in local & staging envs)
        await generateNso(providerId, serviceOfferingResp?.data.id, serviceOfferingResp?.data.defaultDiscountScheduleId);

        // if the service provider was a referral, and we have the referring neighborhood ID, then create an NSO in that neighborhood as well
        // and seed a customer
        if (provider?.referringNeighborhoodId) {
          const response = await generateNso(providerId, serviceOfferingResp?.data.id, serviceOfferingResp?.data.defaultDiscountScheduleId, provider?.referringNeighborhoodId);
          if (response?.data?.length > 0) {
            const nso = response?.data[0];
            await seedCustomerFromReferral(providerId!, nso.id);
            try {
              await maybeSendReferringIndividualProviderLaunchedEmail(providerId!);
            } catch (e) {
              console.error('error sending referring individual provider launched email: ', { error: e, provider: providerId });
            }
          }
        }
      }

      doneLoading(300);
      navigate(`/providerOnboarding/${providerId}/service-offerings/${serviceOfferingResp?.data.id}/discount-schedules/${serviceOfferingResp?.data.defaultDiscountScheduleId}`);
    } catch (e:any) {
      doneLoading();
      createErrorToast('Error creating Service offering: '+ e.response.data.message);
      console.error('error creating Service offering:', { error: e, data });
    }
  };

  return (
    <FormProvider {...formContext}>
      <form onSubmit={formContext.handleSubmit(onSubmit)} style={{ width: '100%' }}>
        <Grid container item spacing={1} sx={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <Card sx={{ paddingLeft: '50px', paddingBottom: '20px', marginTop: '50px', width: '70%', maxWidth: '1300px' }}>
            <Typography variant='h6' className={styles.cardTitle} sx={{ marginTop: '10px' }}>Service Offering</Typography>
            <Grid container item spacing={2} xs={12} sx={{ flexDirection: 'column' }}>
              <Grid container item justifyContent='space-between'>
                <Grid container item direction='column' xs={5} spacing={2}>
                  <Grid item>
                    <ServiceTypeSelect/>
                  </Grid>
                  {/* <Grid container item spacing={1} xs={4} direction='column'> */}
                  {serviceTypeId && (
                    <><Grid item>
                      <HookTextField
                        name='name'
                        label='Name'
                        required
                        maxLength={100}
                      />
                    </Grid>
                    <Grid item>
                      <CadenceTypeSelect />
                    </Grid>
                    </>
                  )}
                  {/* </Grid> */}
                </Grid>
                <Grid container item xs={5} direction='column' marginRight='20px' spacing={2}>
                  <Grid item>
                    <ProviderSelect/>
                  </Grid>
                  <Grid item>
                    <ServiceOfferingCloneOptionsSelect />
                  </Grid>
                  <Grid item>
                    <CloneWithImagesCheckbox/>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Card>
          {serviceTypeId && (
            <Accordion sx={{ width: '70%', paddingLeft: '50px', marginTop: '50px', borderRadius: '4px', maxWidth: '1300px' }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant='h6' className={styles.accordionTitle}>Additional Details</Typography>
              </AccordionSummary>
              <Grid item xs={3}>
                <HookIntegerField
                  name='cutoffDayCount'
                  label='Default Cutoff Day Count'
                />
              </Grid>
              <Grid item container sx={{ flexDirection: 'row', justifyContent: 'flex-start' }}>
                <Grid item xs={2}>
                  <HookCheckbox
                    name='territoryServiceOfferingEnabled'
                    label='TSOI Enabled?'
                  />
                </Grid>
                <Grid item xs={4}>
                  <HookCheckbox
                    name='hasDisabledMaxCustomerCount'
                    label='Has Disabled Max Customer Count?'
                  />
                </Grid>
                <Grid item xs={4}>
                  <HookCheckbox
                    name='defaultForServiceType'
                    label='Is Default for Service Type'
                  />
                </Grid>
              </Grid>
            </Accordion>
          )}
          {serviceTypeId && (
            <Grid container item spacing={1} xs={12} sx={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
              <Card sx={{ marginTop: '50px', paddingLeft: '50px', paddingBottom: '50px', width: '70%', maxWidth: '1300px' }}>
                <ServiceOfferingFormTabsSection />
              </Card>
            </Grid>
          )}
          <Grid item xs={4}>
            <PrimaryButton type='submit' sx={{ marginTop: '40px' }}> Save & Continue </PrimaryButton>
          </Grid>
        </Grid>
      </form>
      <Loading loadingKey={loadingKey} />
    </FormProvider>
  );
}