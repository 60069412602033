import { Box, Typography } from '@mui/material';
import TooltipDrawer from 'components/drawer/TooltipDrawer';

export const neighborhoodDetailsMiningDrawerTooltipKey = 'neighborhoodDetailsMiningDrawerTooltipKey';
export default function MiningDrawer() {
  return (
    <TooltipDrawer
      storeKey={neighborhoodDetailsMiningDrawerTooltipKey}
    >
      <Box sx={{ padding: '20px', maxWidth: '500px' }}>
        <Typography variant='h5'>Mining (aka Launch Leader Acquisition)</Typography>
        <Typography variant='subtitle1'sx={{ paddingTop: '20px', fontWeight: 'bold' }}>
            When does the letter offer display to the user in the onboarding flow modal?
        </Typography>
        <Typography variant='body1' sx={{ paddingTop: '20px' }}>
            The letter offer will display as the last slide in the onboarding modal when:
        </Typography>
        <ul>
          <li>'Hide LL Offer' is toggled off and </li>
          <li>'Hide Facebook RTP Offer' is toggled on or there are no 'Neighborhood Facebook Groups'</li>
          <li>
            The neighborhood status is either not 'Launched' or it was 'Launched' in the past 30 days. If there is no launch date at all then we assume it was launched further back than 30 days.
          </li>
          <li>
            The launch darkly flag 'new-user-onboarding-referral-content' has the 'launch_leader_offer_enabled' field set to 'true'.
          </li>
        </ul>

      </Box>
    </TooltipDrawer>
  );
}