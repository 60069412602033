import useAddProviderToTemplates from '../hookStore/useAddProviderToTemplates';
import ServiceOfferingSeedingRow from './ServiceOfferingSeedingRow';

export default function ServiceOfferingSelections() {
  const { availableServiceOfferings } = useAddProviderToTemplates();
  return (
    <>
      {availableServiceOfferings.map((x:any, i:number) => {
        return (
          <ServiceOfferingSeedingRow
            key={x.id}
            serviceOfferingProjection={x}
            index={i}
          />
        );
      })}
    </>
  );
}