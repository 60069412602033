import { AutocompleteChangeReason, Button, Checkbox, FormControlLabel, Grid } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { deleteNeighborhoodServiceOfferingById, findNeighborhoodServiceOfferingsByFilter } from 'api/neighborhoodServiceOfferingApi';
import { createNeighborhoodMap, getNeighborhoodZipCodeStatusString } from 'util/neighborhoodUtil';
import QuickSearchToolbar from 'components/grid/QuickSearchToolbar';
import useTableSearch from 'components/grid/useTableSearch';
import ConfirmationModal, { ConfirmationModalContextProvider } from 'components/modals/ConfirmationModal';
import PageHeader from 'components/SectionHeaders/PageHeader';
import useToast from 'components/toast/useToast';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { sortByString } from 'util/sortFunctions';
import { columns, columnTotalWidth } from './nsoTableConfig';
import styles from './neighborhoodServiceOfferings.module.css';
import DisableNsoByIdModal from './DisableNsoByIdModal';
import { INeighborhood } from 'model/neighborhood';
import LockNsoByIdModal from './LockNsoByIdModal';
import ServiceProviderAutocomplete from 'components/serviceProviderAutocomplete/ServiceProviderAutocomplete';
import { IDropdownOption } from 'model/dropdown';
import NeighborhoodAutocomplete from 'components/neighborhoodAutocomplete/NeighborhoodAutocomplete';

const sortByName = sortByString('name');

const quickSearchStorageKey = 'neighborhoodServiceOfferings';
const searchablePropertyKeys = ['altName', 'zips', 'id', 'name'];
export default function NeighborhoodServiceOfferings() {
  const { createErrorToast, createSuccessToast } = useToast();
  const [neighborhoodServiceOfferings, setNeighborhoodServiceOfferings] = useState<any[]>([]);
  const [baseNsos, setBaseNsos] = useState<any[]>([]);
  const [tableFilter, setTableFilter] = useState<any>({ selectedServiceProviderId: '', selectedNeighborhoodId: '' });

  const { rows, searchText, setRowsWithStoredFilter, requestSearch, clearStoredSearch } = useTableSearch(quickSearchStorageKey, searchablePropertyKeys );
  const [isInitializing, setIsInitializing] = useState<boolean>(true);
  const [shouldShowDisabled, setShouldShowDisabled] = useState(false);
  const [neighborhoodMap, setNeighborhoodMap]= useState<Map<string, INeighborhood[]>>(new Map<string, INeighborhood[]>());
  const [requireNeighborhood, setRequireNeighborhood] = useState<boolean>(true);

  async function onShouldShowDisabled(nextVal) {
    setShouldShowDisabled(nextVal);
    await _findNeighborHoodServiceOfferings(tableFilter, nextVal);
  }


  const [sortModel, setSortModel] = useState([
    {
      field: 'name',
      sort: 'asc' as any,
    },
  ]);
  const navigate = useNavigate();

  async function onServiceProviderChanged(formCtx: any, selectedOption: IDropdownOption | null, reason: AutocompleteChangeReason, details: any) {
    if (reason === 'clear') {
      setTableFilter({ ...tableFilter, selectedServiceProviderId: '' });
      localStorage.removeItem('nso.selectedServiceProviderId');
    } else if (selectedOption !== null) {
      setTableFilter({ ...tableFilter, selectedServiceProviderId: selectedOption?.optionValue });
      localStorage.setItem('nso.selectedServiceProviderId', selectedOption?.optionValue);
    }
  }
  function onNeighborhoodChange(formCtx: any, selectedOption: IDropdownOption | null, reason: AutocompleteChangeReason, details: any) {
    if (reason === 'clear') {
      setTableFilter({ ...tableFilter, selectedNeighborhoodId: '' });
      localStorage.removeItem('nso.selectedNeighborhoodId');
    } else if (selectedOption !== null) {
      setTableFilter({ ...tableFilter, selectedNeighborhoodId: selectedOption?.optionValue });
      localStorage.setItem('nso.selectedNeighborhoodId', selectedOption?.optionValue);
    }
  }

  const _findNeighborHoodServiceOfferings = async (_tableFilter, _shouldShowDisabled:boolean) => {
    const res = await findNeighborhoodServiceOfferingsByFilter(_tableFilter);
    setBaseNsos(res.data);
  };

  function _mapRowWithZips(_neighborhoodMap) {
    return (x) => {
      {
        const _mappedNeighborhoods = _neighborhoodMap.get(x.neighborhoodId);
        let zips:string | undefined = '';
        if (_mappedNeighborhoods && _mappedNeighborhoods?.length > 0) {
          zips = _mappedNeighborhoods[0]?.zipCodes?.join(',');
        }
        return {
          ...x,
          zips,
        };
      }
    };
  }


  const _deleteNeighborhoodServiceOffering = async ({ id }) => {
    try {
      if (id) {
        await deleteNeighborhoodServiceOfferingById(id);
        await _findNeighborHoodServiceOfferings(tableFilter, shouldShowDisabled);
        createSuccessToast(`NSO ${id} deleted. Table refreshed.`);
      }
    } catch (e:any) {
      console.error(`Unable to delete neighborhood service offering. Message=${e.response?.data?.message}`);
      createErrorToast(`Unable to delete neighborhood service offering. Message=${e.response?.data?.message}`);
    }
  };

  useEffect(() => {
    async function init() {
      const storedSelectedServiceProvider = localStorage.getItem('nso.selectedServiceProviderId') || '';
      const initialFilter = { selectedServiceProviderId: '', selectedNeighborhoodId: '' };
      if (storedSelectedServiceProvider) {
        initialFilter.selectedServiceProviderId = storedSelectedServiceProvider;
      }
      const storedSelectedNeighborhood = localStorage.getItem('nso.selectedNeighborhoodId') || '';
      if (storedSelectedNeighborhood) {
        initialFilter.selectedNeighborhoodId = storedSelectedNeighborhood;
      }
      setTableFilter(initialFilter);
    }
    void init();
  }, []);

  async function refreshTableData() {
    const serviceProviderId= tableFilter.selectedServiceProviderId;
    const neighborhoodId = tableFilter.selectedNeighborhoodId;
    if ((requireNeighborhood && neighborhoodId && serviceProviderId) || (!requireNeighborhood && serviceProviderId)) {
      await _findNeighborHoodServiceOfferings(tableFilter, shouldShowDisabled);
    }
  }

  useEffect(() => {
    if (isInitializing) {
      setIsInitializing(false);
      return;
    }
    void refreshTableData();
  }, [tableFilter, requireNeighborhood]);

  useEffect(() => {
    const _nsos = baseNsos.map(_mapRowWithZips(neighborhoodMap));
    setNeighborhoodServiceOfferings(_nsos);
    if (shouldShowDisabled) {
      setRowsWithStoredFilter(_nsos);
    } else {
      setRowsWithStoredFilter(_nsos.filter(x => !x.disabledDate));
    }
  }, [baseNsos, neighborhoodMap]);


  function postToggle(neighborhoodServiceOfferingId:string, wasEnableRequest:boolean) {
    void refreshTableData();
    createSuccessToast( 'Success!' );
  }

  const addNewButtonClick = () => {
    navigate('/neighborhood-service-offering-detail');
  };

  return (
    <ConfirmationModalContextProvider onConfirm={_deleteNeighborhoodServiceOffering}>
      <Grid container spacing={2} className='pageGridContainer'>
        <Grid item xs={12}>
          <PageHeader headerText={'NEIGHBORHOOD SERVICE OFFERINGS'} />
        </Grid>
        <Grid container spacing={1} item xs={12} >
          <Grid container alignItems='center' item xs={9} md={2}>
            <ServiceProviderAutocomplete
              required
              label='Service Provider'
              placeholder='Search a service provider'
              formless
              postOnChange={onServiceProviderChanged}
              controlledValue={tableFilter.selectedServiceProviderId}
            />
          </Grid>
          <Grid container item xs={9} md={3} alignItems='center'>
            <NeighborhoodAutocomplete
              required={false}
              label='Neighborhood'
              placeholder='Search a neighborhood'
              formless
              postOnChange={onNeighborhoodChange}
              optionDescriptionFunc={getNeighborhoodZipCodeStatusString}
              controlledValue={tableFilter.selectedNeighborhoodId}
              onNeighborhoodsRetrieved={async (options: INeighborhood[]) => {
                const _neighborhoodMap = createNeighborhoodMap(options, x => true, 'id');
                setNeighborhoodMap(_neighborhoodMap);
              }}
            />
          </Grid>
          <Grid item xs='auto'>
            <FormControlLabel
              label="Show disabled nsos"
              control={(
                <Checkbox checked={shouldShowDisabled} onChange={(e) => onShouldShowDisabled(e.target.checked)}/>
              )}
            />
          </Grid>
          <Grid item xs='auto'>
            <FormControlLabel
              label="Require neighborhood"
              control={(
                <Checkbox checked={requireNeighborhood} onChange={(e) => {
                  setRequireNeighborhood(e.target.checked);
                }}/>
              )}
            />
          </Grid>
        </Grid>
        <Grid item xs={6} md={1}>
          <Button variant="contained" onClick={addNewButtonClick}>Add New</Button>
        </Grid>
        <Grid item xs={12}>
          <DataGrid
            rows={rows}
            components={{ Toolbar: QuickSearchToolbar }}
            componentsProps={{

              toolbar: {
                quickSearchStorageKey,
                value: searchText,
                onChange: (event: React.ChangeEvent<HTMLInputElement>) => requestSearch(neighborhoodServiceOfferings, event.target.value),
                clearSearch: () => requestSearch(neighborhoodServiceOfferings, ''),
              },
            }}
            columns={columns}
            sortModel={sortModel}
            onSortModelChange={setSortModel}
            autoHeight={true}
            style={{
              minWidth: '500px',
              maxWidth: `${columnTotalWidth}px`,
            }}
          />
        </Grid>
      </Grid>
      <ConfirmationModal
        title={(onConfirmArgs) => (<div className={styles.confirmDeleteModalTitle}>Delete neighborhood service offering</div>)}
        message={(onConfirmArgs:any) => (
          <Grid container spacing={1} alignItems='center' justifyContent='center' className={styles.confirmDeleteModal}>
            <Grid item xs>
              You are about to delete {onConfirmArgs.name}.
            </Grid>
          </Grid>
        )}
      />
      <DisableNsoByIdModal
        postOnConfirm={postToggle}
      />
      <LockNsoByIdModal
        postOnConfirm={postToggle}
      />
    </ConfirmationModalContextProvider>
  );
}