import { IDropdownOption } from './dropdown';
import { IServiceType, IServiceTypeCategoryMetadata } from './serviceType';

export const ManageServiceTypeMode = {
  CREATE: 'create',
  EDIT_DEFAULT: 'edit_default',
  EDIT_MARKET: 'edit_market',
  EDIT_TERRITORY: 'edit_territory',
};

export class SearchResultCardStyle {
  static list = [
    { id: 'NONE', name: 'No Style Applied' },
    { id: 'STYLE_1', name: 'Price is hidden' },
  ];
}

export class ServiceTypeColor {

  static list = [
    { id: 'bluegrey', name: 'bluegrey' },
    { id: 'red', name: 'red' },
    { id: 'brown', name: 'brown' },
    { id: 'purple', name: 'purple' },
    { id: 'orange', name: 'orange' },
    { id: 'blue', name: 'blue' },
  ];
}


export interface IServiceTypeInfo {
  serviceCategories:IServiceCategory[];
  serviceTypes:IServiceType[];
  serviceCategoryToServiceTypes:IServiceCategoryToServiceTypeMap;
}

export interface IServiceCategoryToServiceTypeMap {
  [key: string]: IServiceTypeInfoMetadatum[];
}

export interface IServiceTypeInfoMetadatum {
  serviceTypeId:string;
  serviceTypeName:string;
  sortOrder: number;
}

export interface IServiceTypeInfoCardMetadatum extends IServiceTypeInfoMetadatum{
  colorScheme:string;
  imageGuid:string;
  enabled:boolean;
  deleteDate:string | null;
}

export interface IServiceTypeTree {
  sortOrder:number;
  serviceCategory:IServiceCategory;
  serviceTypes:IServiceType[];
}

export interface ISampleServiceCategoryAndServiceTypeName {
  serviceCategoryName:string;
  serviceTypeName:string;
}

export interface ICategorizedServiceType {
  serviceCategory:IServiceCategory;
  serviceTypes:IServiceType[];
}

export interface IServiceCategory {
  pk:string;
  sk:string;
  serviceCategoryId:string;
  marketId:string | null;
  name:string;
  displayName:string | null;
  sortOrder:number | null;
  colorScheme:string;
  imageGuid:string;
  zipCodeTerritory:string | null;
  enabled:boolean;
}


export interface IServiceCategoryAdminRequest {
  serviceCategoryId:string | null;
  marketId:string | null;
  zipCodeTerritory:string | null;
}


export interface IServiceCategoryAdminDTO {
  serviceCategoryId:string | null;
  marketId:string | null;
  zipCodeTerritory:string | null;
  name:string;
  displayName:string | null;
  sortOrder:number | null;
  colorScheme:string;
  imageGuid:string;
  serviceTypeMetadata:IServiceTypeInfoCardMetadatum[];
  availableServiceTypeMetadata: IServiceTypeInfoCardMetadatum[];
  enabled:boolean;

}


export interface IServiceCategoryForm {

  serviceCategoryId:string | null;
  marketId:string | null;
  name:string;
  displayName:string | null;
  sortOrder:number | null;
  colorScheme:string;
  imageGuid:string;
  zipCodeTerritory:string | null;
  enabled:boolean;
}

export function createEmptyServiceCategoryForm():IServiceCategoryForm {
  return {
    serviceCategoryId: '',
    marketId: '',
    name: '',
    displayName: '',
    sortOrder: null,
    colorScheme: '',
    imageGuid: '',
    zipCodeTerritory: '',
    enabled: true,
  };
}


