import { createCustomShortLink } from 'api/shortLinkApi';
import { useLoading } from 'components/Layout/Loading';
import useToast from 'components/toast/useToast';
import createStore from 'hooks/hookStore';
import { createEmptyGenerateWelcomePageLinkForm, IGenerateWelcomePageLinkForm } from 'model/neighborhoods/generateWelcomePageLinkForm';
import { IShortLinkCreateRequest, IShortLinkCreateResponse } from 'model/short_link/shortlink';
import { useEffect, useState } from 'react';


type WelcomePageShortLink = {

  formData: IGenerateWelcomePageLinkForm;
  shortLinkResponse:IShortLinkCreateResponse | null;
}
const loadingKey = 'WelcomePageShortLink';

const { get, update, registerListener, unregisterListener } = createStore<WelcomePageShortLink>('WelcomePageShortLink', {
  formData: createEmptyGenerateWelcomePageLinkForm(),
  shortLinkResponse: null,
});

export default function useWelcomePageShortLink() {
  const setState = useState(get())[1];
  const { onLoading, doneLoading } = useLoading(loadingKey);
  const { createInfoToast, createSuccessToast, createErrorToast } = useToast();

  useEffect(() => {
    registerListener(setState);
    return () => {
      update({
        ...get(),
        shortLinkResponse: null,
        formData: createEmptyGenerateWelcomePageLinkForm(),
      });
      unregisterListener(setState);
    };
  }, []);

  async function init() {
    onLoading();
    update({
      ...get(),
      formData: createEmptyGenerateWelcomePageLinkForm(),
    });
    doneLoading(300);
  }

  async function onSubmit(data:IGenerateWelcomePageLinkForm) {
    onLoading();
    const { } = get();
    try {
      let url = createWelcomePageUrl(data);
      let shortLinkRequest:IShortLinkCreateRequest = {
        url: url,
      };
      let res = await onCreateShortLink(shortLinkRequest);
      createInfoToast('Short link created');
    } catch (err:any) {
      createErrorToast('Error creating short link');
      console.error(err);
    }
    doneLoading();
  }

  async function onCreateShortLink(data:IShortLinkCreateRequest) {
    try {
      let response = await createCustomShortLink(data);
      if (response.data.shortLink && response.data.converted) {
        navigator.clipboard.writeText(response.data.shortLink).catch(reason => console.log(reason));
        createInfoToast('Copied to clipboard');
        update({
          ...get(),
          shortLinkResponse: response.data,
        });
      } else if (response.data.shortLink && !response.data.converted) {
        createErrorToast('Could not create short link');
      }
    } catch (err:any) {
      createErrorToast(err.response.data.message);
    }
  }

  function createWelcomePageUrl({ neighborhoodId, mc } ) {
    let params: URLSearchParams | null = null;
    if (mc != null && mc.length > 0) {
      params = new URLSearchParams({
        mc: mc,
      });
    }

    if (params == null) {
      return `${window.REACT_APP_CONSUMER_BASE_URI}/welcome/${neighborhoodId}`;
    }
    return `${window.REACT_APP_CONSUMER_BASE_URI}/welcome/${neighborhoodId}?${params.toString()}`;
  }

  function onChangeNeighborhood(neighborhoodId:string, neighborhoodName:string) {
    let nextFormData = createEmptyGenerateWelcomePageLinkForm();
    nextFormData.neighborhoodId = neighborhoodId;
    nextFormData.neighborhoodName = neighborhoodName;
    update({
      ...get(),
      formData: nextFormData,
    });
  }

  return {
    loadingKey,
    ...get(),
    init,
    onSubmit,
    onChangeNeighborhood,
    createWelcomePageUrl,
  };
}