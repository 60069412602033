import { InputLabel, FormControl, FormHelperText } from '@mui/material';
import { findAvailableRoles } from 'api/userApi';
import HookMultiselect from 'components/reactHookForm/HookMultiselect';
import useErrorState from 'hooks/reactHookForm/useErrorState';
import { useState, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { sortByName } from '../userDetails';

export function RolesSelect() {
  const formCtx = useFormContext();
  const watched = useWatch({ name: 'roles' });
  const { userId } = useParams();
  const [roles, setRoles] = useState<any[]>([]);
  useEffect(() => {
    async function getAvailableRoles() {
      if (userId) {
        const _roles = await findAvailableRoles(userId);
        const sortedRoles = _roles.data.sort(sortByName);
        setRoles(sortedRoles);
      }
    }
    void getAvailableRoles();
  }, []);
  const { isError, getErrorMessage } = useErrorState(formCtx);
  return (
    <FormControl variant='standard' fullWidth error={isError('roles')}>
      <InputLabel htmlFor="roles">Roles</InputLabel>
      <HookMultiselect
        control={formCtx.control}
        rules={{
          required: { value: true, message: 'Required' },
        }}
        name='roles'
        id='roles'
        menuItems={roles} />
      <FormHelperText>{getErrorMessage('roles')}</FormHelperText>
    </FormControl>
  );
}
