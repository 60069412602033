import { Grid, Typography } from '@mui/material';
import HookConfirmationModal from 'components/modals/HookConfirmationModal';
import useSeedFromTemplate from '../_hookStore/useSeedFromTemplate';

export const SeedProviderConfirmationModalKey = 'SeedProviderConfirmationModalKey';

export default function SeedNeighborhoodConfirmationModal() {
  const { selectedServiceProvider, onConfirmProviderSeeding } = useSeedFromTemplate();
  return (
    <HookConfirmationModal
      title={() => 'Confirm Service Provider Seeding'}
      body='Seed Service Provider?'
      modalKey={SeedProviderConfirmationModalKey}
      message={(confirmArgs, cancelModal) => (
        <Grid container spacing={1} alignItems='center' justifyContent='center' >
          <Grid item xs={12} paddingTop={'30px'} paddingBottom={'25px'}>
            <Typography variant='body1'>Seed {selectedServiceProvider?.optionText} from template?</Typography>
          </Grid>
        </Grid>
      )}
      onConfirm={async (data) => {
        await onConfirmProviderSeeding();
      }}
    />
  );
}