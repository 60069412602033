import { Card, Divider, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import ServiceOfferingSummaryCard from './ServiceOfferingSummaryCard';
import styles from '../providerOnboarding.module.css';
import { findServiceOfferingsByProvider } from 'api/serviceOfferingApi';
import { findNeighborhoodServiceOfferingsByFilter } from 'api/neighborhoodServiceOfferingApi';
import { INeighborhoodServiceOffering } from 'model/neighborhoodServiceOffering';
import { useParams } from 'react-router-dom';
import { findDiscountSchedulesByProvider } from 'api/discountScheduleApi';
import { IServiceOffering } from 'model/serviceOffering';

export default function ServiceOfferingsSummary() {
  const { providerId } = useParams();
  const [nsos, setNsos] = useState([]);
  const [discountSchedules, setDiscountSchedules] = useState([]);
  const [serviceOfferings, setServiceOfferings] = useState([] as IServiceOffering[]);

  useEffect(() => {
    findNeighborhoodServiceOfferingsByFilter({ selectedServiceProviderId: providerId, selectedNeighborhoodId: window.REACT_APP_NEIGHBORVILLE_UUID })
      .then(function (response) {
        setNsos(response.data);
      })
      .catch(function (error) {
        console.error('error finding NSOs by providerId: ', { error, providerId });
      });
  }, [providerId]);

  useEffect(() => {
    findServiceOfferingsByProvider(providerId!)
      .then(resp => {
        setServiceOfferings(resp.data);
      })
      .catch(err => {
        console.error('error retrieving service offerings: ', { err });
      });
  }, [providerId]);

  useEffect(() => {
    findDiscountSchedulesByProvider(providerId!)
      .then(resp => {
        setDiscountSchedules(resp.data);
      })
      .catch(err => {
        console.error('error retrieving discount schedules: ', { err });
      });
  }, [providerId]);

  return (
    <Grid container flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
      <Typography variant="h6" className={styles.summarySectionTitle} marginTop={'50px'}>Service Offerings</Typography>
      <Grid container spacing={7} sx={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
        {serviceOfferings.length === 0 && (<Typography variant="body1" sx={{ marginTop: '50px', marginBottom: '20px' }}>No service offerings have been created yet.</Typography>)}
        {serviceOfferings.map((serviceOffering, index) => {
          const nso = nsos.find((el:INeighborhoodServiceOffering) => el.serviceOfferingId === serviceOffering.id);
          const ds = discountSchedules.find((el:any) => el.id === serviceOffering.defaultDiscountScheduleId);
          return <ServiceOfferingSummaryCard serviceOffering={serviceOffering} index={index} key={index} nso={nso} discountSchedule={ds} />;
        })}
      </Grid>
    </Grid>
  );
}