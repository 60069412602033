import { Grid } from '@mui/material';
import EditInviteeNameModal from './EditInviteeNameModal';
import MarkAsFollowingModal from './MarkAsFollowingModal';
import MarkNoEmailAvailableModal from './MarkNoEmailAvailableModal';
import SetQualityTagModal from './SetQualityTagModal';
import SendInvitesModal from './SendInvitesModal';
import DeleteConnectionsEmailModal from './DeleteConnectionEmailsModal';

export default function InviteNeighobrsModals() {
  return (
    <>
      <Grid>
        <SendInvitesModal />
      </Grid>
      <Grid>
        <MarkNoEmailAvailableModal />
      </Grid>
      <Grid>
        <SetQualityTagModal />
      </Grid>
      <Grid>
        <EditInviteeNameModal />
      </Grid>
      <Grid>
        <MarkAsFollowingModal />
      </Grid>
      <Grid>
        <DeleteConnectionsEmailModal />
      </Grid>
    </>
  );
}