import { Grid, Typography } from '@mui/material';
import HookConfirmationModal from 'components/modals/HookConfirmationModal';
import useTerritoryDetails from '../hookStore/useTerritoryDetails';
import useMarkets from 'hooks/useMarkets';

export const TerritoryNeighborhoodsSkipTraceConfirmationModalKey = 'TerritoryNeighborhoodsSkipTraceConfirmationModalKey';

export default function TerritoryNeighborhoodsSkipTraceConfirmationModal() {
  const { formData, onConfirmSkiptraceData, init } = useTerritoryDetails();
  var { market } = useMarkets();
  return (
    <HookConfirmationModal
      title={() => 'Confirm Territory Prep'}
      body='Prep Territory?'
      modalKey={TerritoryNeighborhoodsSkipTraceConfirmationModalKey}
      message={(confirmArgs, cancelModal) => (
        <Grid container spacing={1} alignItems='center' justifyContent='center' sx={{ maxWidth: '600px' }}>
          <Grid item xs={12} paddingTop={'30px'} paddingBottom={'25px'}>
            <Typography variant='body1'>
                Initiate neighborhood skiptracing process for territory - {formData.name}?
            </Typography>
            <Typography variant='body1' paddingTop={'15px'}>
                This will skiptrace contact info by address for all ACTIVE & LAUNCHED neighborhoods in the territory.
            </Typography>
          </Grid>
        </Grid>
      )}
      onConfirm={async () => {
        await onConfirmSkiptraceData();
        if (market) {
          void init(market.id, false);
        }
      }}
    />
  );
}