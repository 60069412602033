import { Typography, Grid } from '@mui/material';
import { HookTextField } from 'components/reactHookForm';
import { createValidateEmail, createValidatePhone } from 'util/rhfValidateFunctions';

function validateEmail(arg) {
  if (!arg) {
    return true;
  }
  return createValidateEmail()(arg);
}
function validatePhone(arg) {
  if (!arg) {
    return true;
  }
  return createValidatePhone('Should be of form xxx-xxx-xxxx or xxxyyyzzzz')(arg);
}

export default function ContactInformation() {
  return <><Typography variant='h6'>Contact Information</Typography><Grid container item spacing={1}>
    <Grid container item spacing={1} sx={{ flexDirection: 'row' }}>
      <Grid item xs={3}>
        <HookTextField
          label='Contact First Name'
          name='contactPersonFirstName'
          validationRules={{
            required: false,
          }} />
      </Grid>
      <Grid item xs={3}>
        <HookTextField
          label='Contact Last Name'
          name='contactPersonLastName'
          validationRules={{
            required: false,
          }} />
      </Grid>
      <Grid item xs={4}>
        <HookTextField
          label='Contact Full Name'
          name='contactPersonName' />
      </Grid>
    </Grid>
    <Grid item xs={4}>
      <HookTextField
        label='Contact Phone'
        name='contactPersonPhone'
        validationRules={{
          required: false,
          validate: validatePhone,
        }} />
    </Grid>
    <Grid item xs={4}>
      <HookTextField
        label='Contact Email'
        name='contactPersonEmail'
        validationRules={{
          required: false,
          validate: validateEmail,
        }} />
    </Grid>
    <Grid container item spacing={1} sx={{ flexDirection: 'row' }}>
      <Grid item xs={4}>
        <HookTextField
          label='Support Contact Phone'
          name='businessContactPhone'
          validationRules={{
            required: false,
            validate: validatePhone,
          }} />
      </Grid>
      <Grid item xs={4}>
        <HookTextField
          label='Support Contact Email'
          name='businessContactEmail'
          validationRules={{
            required: false,
            validate: validateEmail,
          }} />
      </Grid>
    </Grid>
  </Grid></>;
}