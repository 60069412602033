
export const usdFormatter = new Intl.NumberFormat('en-us', { style: 'currency', currency: 'USD' });


const USDNoDecimalFormatter = new Intl.NumberFormat('en-us', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});


const USDFormatter = new Intl.NumberFormat('en-us', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
});

const USDFormatterTwoDecimal = new Intl.NumberFormat('en-us', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export function formatUSDNoDecimal(num:number, placeholder?:any) {
  if (num === undefined || num === null) {
    return placeholder || 'N/A';
  }
  return USDNoDecimalFormatter.format(num);
}

export function formatUSD(num:number | null | undefined, placeholder?:any) {
  if (num === undefined || num === null) {
    return placeholder || 'N/A';
  }
  return USDFormatter.format(num);
}

export function formatUSDTwoDecimal(num:number | null | undefined, placeholder?:any) {
  if (num === undefined || num === null) {
    return placeholder || 'N/A';
  }
  return USDFormatterTwoDecimal.format(num);
}