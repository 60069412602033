import { Card, Grid, IconButton, Link, Tooltip, Typography } from '@mui/material';
import { EditButton } from 'components/buttons';
import useProviderSetupStore from '../useProviderSetupStore';
import { Replay } from '@mui/icons-material';


export default function ProviderSummary() {
  const { provider } = useProviderSetupStore();

  return (
    <>
      <Grid container item xs={12} spacing={2} sx={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'end' }}>
        <Grid item>
          <Typography variant="h6" sx={{ marginTop: '20px', alignSelf: 'center', fontWeight: 'bold' }}>Provider: </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h6" sx={{ marginTop: '20px', alignSelf: 'center' }}>{provider?.name}</Typography>
        </Grid>
        <Grid item>
          <Link href={`/service-providers/${provider?.id}/service-provider-details`} target="_blank">
            <EditButton tooltipTitle={'View and edit the full provider details on the Service Provider Details page'} onClick={() => {}} />
          </Link>
          <Link href={`/providerOnboarding/${provider?.id}`} rel='noreferrer'>
            <Tooltip title={'Revisit this provider in step 1 of this tool'}>
              <IconButton >
                <Replay />
              </IconButton>
            </Tooltip>
          </Link>
        </Grid>
      </Grid>
    </>
  );
}