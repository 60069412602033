import { FormControl, FormHelperText, InputLabel } from '@mui/material';
import { HookSelect } from 'components/reactHookForm';
import useErrorState from 'hooks/reactHookForm/useErrorState';
import { StateAbbreviations } from 'model/markets';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { sortByString } from 'util/sortFunctions';

const sortByName = sortByString('name');

export default function StateAbbrSelect({
  isRequired = false,
  label = 'State Abbreviation',
  hookSelectName,
  hookSelectId,
},
) {
  const formCtx = useFormContext();
  const [states] = useState<any[]>(StateAbbreviations.list.sort(sortByName));
  const { isError, getErrorMessage } = useErrorState(formCtx);
  return (
    <FormControl variant='standard' fullWidth error={isError('stateAbbr')}>
      <InputLabel htmlFor={hookSelectId}>{label}</InputLabel>
      <HookSelect
        rules={{
          required: { value: isRequired, message: 'Required' },
        }}
        control={formCtx.control}
        name={hookSelectName}
        id={hookSelectId}
        menuItems={states}
      />
      <FormHelperText>{getErrorMessage(hookSelectName)}</FormHelperText>
    </FormControl>
  );
}