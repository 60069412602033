import { PrimaryButton, SecondaryButton } from 'components/buttons';
import { useNavigate } from 'react-router-dom';

export function FormButtons() {
  const navigate = useNavigate();
  return (
    <>
      <PrimaryButton
        type='submit'
      >
        Save
      </PrimaryButton>
      <SecondaryButton
        onClick={() => navigate('/users')}
      >
        Cancel
      </SecondaryButton>
    </>
  );
}
