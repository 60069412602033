import { CampaignType, EnvelopeType, NeighborhoodLaunchType, NeighborhoodLetterStatus, ToAddressOption } from './ancillary';

export interface IQRGenerationRequest {
  width:number;
  height: number;
  neighborhoodId:string;
  marketingCode:string;
}

export interface ILetterBatchNeighborhood {
  id:string;
  launchType:string;
  campaignType:string;
  campaignTag: string;
  variablePrintTemplateId:string | null;
  variablePrintTemplateIdAlt:string | null;
  letterTemplateFileKey:string | null;
  letterTemplateFileName:string | null;
  letterTemplateFileKeyAlt:string | null;
  letterTemplateFileNameAlt:string | null;
  targetAddressCount:number | null;
  toAddressOption:string;
  returnAddressFirstName:string | null;
  returnAddressLastName:string | null;
  returnAddressStreetAddress:string | null;
  returnAddressCity:string | null;
  returnAddressState:string | null;
  returnAddressZip:string | null;
  envelopeType:string;
  neighborhoodLetterStatus:string;
  marketingCode:string | null;
  marketingCodeAlt:string | null;
  letterBatchId:string;
  territoryId:string;
  neighborhoodId:string;
  excludeExistingUsers: boolean | null;
  createDate:Date | null;
  updateDate:Date | null;
  deleteDate:Date | null;
  variablePrintOverrides: { [key: string]: string } | null;
}

export interface ILetterBatchNeighborhoodFormDto {
  id:string;
  launchType:string;
  campaignType:string;
  campaignTag: string;
  variablePrintTemplateId:string | null;
  variablePrintTemplateIdAlt:string | null;
  letterTemplateFileKey:string | null;
  letterTemplateFileName:string | null;
  letterTemplateFileKeyAlt:string | null;
  letterTemplateFileNameAlt:string | null;
  targetAddressCount:number | null;
  toAddressOption:string;
  returnAddressFirstName:string | null;
  returnAddressLastName:string | null;
  returnAddressStreetAddress:string | null;
  returnAddressCity:string | null;
  returnAddressState:string | null;
  returnAddressZip:string | null;
  envelopeType:string;
  neighborhoodLetterStatus:string;
  marketingCode:string | null;
  marketingCodeAlt:string | null;
  letterBatchId:string;
  territoryId:string;
  territoryName:string;
  neighborhoodId:string;
  neighborhoodName:string;
  excludeExistingUsers: boolean | null;
  createDate:Date | null;
  updateDate:Date | null;
  deleteDate:Date | null;
  householdCount: number | null;
  lastPreparationDate: Date | null;
  variablePrintOverrides: { [key: string]: string } | null;
  additionalNeighborhoodIds: string[];
}

export function createEmptyLetterBatchNeighborhood():ILetterBatchNeighborhood {
  return {
    id: '',
    launchType: NeighborhoodLaunchType.RESIDENT,
    campaignType: CampaignType.LAUNCH_LEADER_ACQUISITION,
    campaignTag: '',
    variablePrintTemplateId: null,
    variablePrintTemplateIdAlt: null,
    letterTemplateFileKey: null,
    letterTemplateFileName: null,
    letterTemplateFileKeyAlt: null,
    letterTemplateFileNameAlt: null,
    targetAddressCount: null,
    toAddressOption: ToAddressOption.TO_MY_STREET,
    returnAddressFirstName: null,
    returnAddressLastName: null,
    returnAddressStreetAddress: null,
    returnAddressCity: null,
    returnAddressState: null,
    returnAddressZip: null,
    envelopeType: EnvelopeType.ROBOT_WRITTEN,
    neighborhoodLetterStatus: NeighborhoodLetterStatus.DRAFT,
    marketingCode: null,
    marketingCodeAlt: null,
    letterBatchId: '',
    territoryId: '',
    neighborhoodId: '',
    excludeExistingUsers: false,
    createDate: null,
    updateDate: null,
    deleteDate: null,
    variablePrintOverrides: null,
  };
}

export function createEmptyLetterBatchNeighborhoodFormDto():ILetterBatchNeighborhoodFormDto {
  return {
    id: '',
    launchType: NeighborhoodLaunchType.RESIDENT,
    campaignType: CampaignType.LAUNCH_LEADER_ACQUISITION,
    campaignTag: '',
    variablePrintTemplateId: null,
    variablePrintTemplateIdAlt: null,
    letterTemplateFileKey: null,
    letterTemplateFileName: null,
    letterTemplateFileKeyAlt: null,
    letterTemplateFileNameAlt: null,
    targetAddressCount: null,
    toAddressOption: ToAddressOption.TO_MY_STREET,
    returnAddressFirstName: null,
    returnAddressLastName: null,
    returnAddressStreetAddress: null,
    returnAddressCity: null,
    returnAddressState: null,
    returnAddressZip: null,
    envelopeType: EnvelopeType.ROBOT_WRITTEN,
    neighborhoodLetterStatus: NeighborhoodLetterStatus.DRAFT,
    marketingCode: null,
    marketingCodeAlt: null,
    letterBatchId: '',
    territoryId: '',
    territoryName: '',
    neighborhoodId: '',
    neighborhoodName: '',
    householdCount: null,
    excludeExistingUsers: true,
    createDate: null,
    updateDate: null,
    deleteDate: null,
    lastPreparationDate: null,
    variablePrintOverrides: null,
    additionalNeighborhoodIds: [],
  };
}