import QuickSearchToolbar from 'components/grid/QuickSearchToolbar';
import useTableSearch from 'components/grid/useTableSearch';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Grid, IconButton } from '@mui/material';
import { useEffect, useState } from 'react';
import Loading, { useLoading } from 'components/Layout/Loading';
import useTerritorySeedingTemplates from '../hookStore/useTerritorySeedingTemplates';
import { ITerritorySeedingTemplate } from 'model/territorySeedingTemplates';
import { deleteProviderServiceTypeTemplateForTerritory } from 'api/territorySeedingTemplatesApi';
import useToast from 'components/toast/useToast';
import { Delete } from '@mui/icons-material';

function DeleteTemplate({ params }) {
  const { loadingKey, tableRows, refreshTemplatesForCurrentTerritory } = useTerritorySeedingTemplates();
  const { createErrorToast } = useToast();
  const { onLoading, doneLoading } = useLoading(loadingKey);

  async function handleClick() {
    try {
      onLoading();
      await deleteProviderServiceTypeTemplateForTerritory(params.pk, params.sk);

      await refreshTemplatesForCurrentTerritory();
      doneLoading();
    } catch (error) {
      doneLoading();
      console.error('error deleting template', { error, params });
      createErrorToast(`Error deleting template ${error}`);
    }
  }

  return (
    <IconButton
      onClick={handleClick}
    >
      <Delete />
    </IconButton>
  );
}
const columns: GridColDef[] = [
  {
    field: 'serviceType',
    headerName: 'Service Type',
    width: 250,
    renderCell: (params: any) => {
      return <div>{params.value}</div>;
    },
  },
  {
    field: 'providerName',
    headerName: 'Provider Name',
    width: 250,
    renderCell: (params: any) => {
      return <div>{params.value}</div>;
    },
  },
  {
    field: 'seedFactor',
    headerName: 'Seed Factor',
    width: 100,
    renderCell: (params: any) => {
      return <div>{params.value}</div>;
    },
  },
  {
    field: 'actions',
    headerName: 'Actions',
    width: 100,
    renderCell: (params:any) => {
      return (
        <Grid container justifyContent='center' alignItems='center'>
          <Grid>
            <DeleteTemplate params={params.row} />
          </Grid>
        </Grid>
      );
    },
  },
];

const columnTotalWidth = columns.map(x => x.width).reduce((a:any, b:any) => a+b, 0);
const quickSearchStorageKey = 'territorySeedingTemplatesTable';

export default function TerritorySeedingTemplatesTable () {
  const { tableRows, loadingKey } = useTerritorySeedingTemplates();
  const { rows, searchText, setRowsWithStoredFilter, requestSearch } = useTableSearch(quickSearchStorageKey);
  const [sortModel, setSortModel] = useState([]);

  useEffect(() => {
    setRowsWithStoredFilter(tableRows);
  }, [tableRows]);

  return (
    <Loading loadingKey={loadingKey}>
      <Grid container spacing={2} className='pageGridContainer'>
        <Grid item xs={12}>
          <DataGrid
            rows={rows}
            components={{ Toolbar: QuickSearchToolbar }}
            componentsProps={{
              toolbar: {
                quickSearchStorageKey,
                value: searchText,
                onChange: (event: React.ChangeEvent<HTMLInputElement>) => requestSearch(tableRows, event.target.value),
                clearSearch: () => requestSearch(tableRows, ''),
              },
            }}
            columns={columns}
            autoHeight={true}
            sortModel={sortModel}
            style={{
              minWidth: '500px',
              maxWidth: `${columnTotalWidth}px`,
            }}
          />
        </Grid>
      </Grid>
    </Loading>
  );
};
