
import GenericModal from 'components/modal/GenericModal';
import useModal, { IModal } from 'components/modal/useModal';

import { useEffect } from 'react';
import GenerateWelcomePageLinkForm from './GenerateWelcomePageLinkForm';
import { createEmptyGenerateWelcomePageLinkForm, IGenerateWelcomePageLinkForm } from 'model/neighborhoods/generateWelcomePageLinkForm';
import useWelcomePageShortLink from './welcomePageShortLinkHookStore';

export const modalStoreKey = 'welcomePageShortLinkModal';

export interface IGenerateWelcomePageLinkModalState {
  neighborhoodId: string;
  neighborhoodName: string;
  callback: (formData: IGenerateWelcomePageLinkForm) => Promise<void>;
}
export function createEmptyGenerateWelcomePageLink() :IGenerateWelcomePageLinkModalState {
  return {
    neighborhoodId: '',
    neighborhoodName: '',
    callback: async (formData: IGenerateWelcomePageLinkForm) => {},
  };
}
export interface IGenerateWelcomePageLinkInteralProps {

}

export function GenerateWelcomePageLinkInternal({ }:IGenerateWelcomePageLinkInteralProps) {
  const { getModalState, closeModal } = useModal(modalStoreKey);
  const _state = getModalState() as IGenerateWelcomePageLinkModalState & IModal;
  const { neighborhoodId, neighborhoodName, callback } = _state;

  const { onSubmit, onChangeNeighborhood, formData } = useWelcomePageShortLink();

  useEffect(() => {
    if (neighborhoodId && neighborhoodName) {
      onChangeNeighborhood(neighborhoodId, neighborhoodName);
    }
  }, [neighborhoodId, neighborhoodName]);

  async function _onConfirm(data) {
    void onSubmit(data);
  }


  return (
    <GenericModal
      modalStoreKey={modalStoreKey}
      title={'Generate Welcome Page Link'}
      modalContent={(
        <GenerateWelcomePageLinkForm
          initialFormData={formData}
          onConfirm={_onConfirm}
          onCancel={() => closeModal(createEmptyGenerateWelcomePageLink())}
        />
      )}
      customConfirmationHandling
      secondaryButtonText='Cancel'
      overrideSx={{
        '& .MuiPaper-root': {
          width: '500px',
        },
      }}
    />
  );
}


export default function GenerateWelcomePageLinkModal() {

  return (
    <GenerateWelcomePageLinkInternal/>
  );
}