import { Grid, Typography } from '@mui/material';

export default function DetailItem ({ label, value }: { label: string; value: string | undefined }) {
  return (
    <Grid container item xs={6}>
      <Grid item>
        <Typography variant={'body1'} fontWeight={'bold'}>{label}:</Typography>
      </Grid>
      <Grid item paddingLeft={'10px'}>
        <Typography variant={'body1'}>{value}</Typography>
      </Grid>
    </Grid>
  );
};