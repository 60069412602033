import useIndividualsHookStore from '../individualsHookStore';
import useToast from 'components/toast/useToast';
import CopyButton from 'components/buttons/CopyButton';
import { InfoOutlined } from '@mui/icons-material';
import { Grid, Typography, Tooltip, TextField, InputAdornment } from '@mui/material';
import { generateLaunchLeaderReferralLink, generateServiceProviderNeighborReferralLink } from 'api/individualApi';
import { PrimaryButton } from 'components/buttons';
import { useEffect, useState } from 'react';
import { createValidateNumber } from 'util/rhfValidateFunctions';
import { useLoading } from 'components/Layout/Loading';
import { IndividualRewardTypes } from 'model/individual';
import get from 'lodash.get';
import DetailItem from '../_components/DetailItem';
import { useParams } from 'react-router-dom';

const validateNumber = createValidateNumber('Please enter a valid number.');

export default function ServiceProviderConsumerReferralLinkGenerator() {
  const { individual, setNeighorReferralLink, neighborReferralLink, loadingKey } = useIndividualsHookStore();
  const rewardKey = IndividualRewardTypes.NEIGHBOR_REFERRAL;
  const offer = get(individual.rewards, rewardKey, {});
  const [isError, setIsError] = useState(false);
  const [helperText, setHelperText] = useState('');
  const [amount, setAmount] = useState(0);
  const { onLoading, doneLoading } = useLoading(loadingKey);
  const { createErrorToast } = useToast();
  const { individualId } = useParams();
  let defaultAmountValue = offer.amount || 0;

  async function onGenerateButtonClick() {
    try {
      onLoading();
      const resp = await generateServiceProviderNeighborReferralLink(individualId!, amount);
      if (resp?.data?.referralLink) {
        setNeighorReferralLink(resp.data.referralLink);
      }
    } catch (error:any) {
      console.error('Error generating referral link: ', { error, individualId: individual.id, amount });
      createErrorToast('Error:', error.response.data.message);
    }
    doneLoading(300);
  }

  // only show this for Provider users
  if (!individual.provider) {
    return <></>;
  }

  return (
    <Grid container spacing={3} paddingTop={'40px'}>
      <Grid container item flexDirection={'row'} justifyContent={'flex-start'} alignItems={'center'}>
        <Typography variant="h6">Provider Neighbor Referral Link Generator</Typography>
        <Tooltip title="This tool will generate a referral link for a service provider to use to refer a new individual to StreetFair. The Service Provider will be able to send this link to their existing customers and earn $$ for each one that joins. Additionally, we will be able to connect a consumer to a referring service provider.">
          <InfoOutlined sx={{ fontSize: '20px', marginBottom: '10px', marginLeft: '5px' }}/>
        </Tooltip>
      </Grid>
      <Grid container item spacing={2}>
        <Grid container item flexDirection={'row'}>
          <TextField
            label="Reward Amount"
            variant="standard"
            helperText={helperText}
            defaultValue={defaultAmountValue}
            onChange={(event) => {
              const validation = validateNumber(event.target.value);
              if (validation !== true) {
                setIsError(true);
                setHelperText(validation);
                return;
              }
              setAmount(parseFloat(event.target.value));
              setIsError(false);
              setHelperText('');
            }}
            required
            error={isError}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
          />
          <PrimaryButton
            disabled={!individual.id || isError || !amount}
            onClick={()=> onGenerateButtonClick()}
            sx={{ marginLeft: '25px' }}
          >
            Generate
          </PrimaryButton>
        </Grid>
        { neighborReferralLink && (
          <Grid item container flexDirection='row' justifyContent='flex-start'>
            <Grid item>
              <DetailItem label="Link" value={neighborReferralLink} />
            </Grid>
            <Grid item>
              <CopyButton message={neighborReferralLink} />
            </Grid>
          </Grid>
        )
        }
      </Grid>
    </Grid>
  );
}