
import { FormControl, FormHelperText } from '@mui/material';
import { HookSingleAutocomplete } from 'components/reactHookForm';
import useErrorState from 'hooks/reactHookForm/useErrorState';
import get from 'lodash.get';
import { IDropdownOption } from 'model/dropdown';
import { useFormContext } from 'react-hook-form';
import useProviderCoverageAreaLaunchToolingStore from '../hookStore/useProviderCoverageAreaLaunchTooling';
import ServiceProviderAutocomplete from 'components/serviceProviderAutocomplete/ServiceProviderAutocomplete';


export interface IServiceProviderSingleSelectProps {
  onServiceProviderRemove:(index?:number) => void;
  onServiceProviderAdd:(id:string, name:string) => void;
  variant: 'neighborhoodLaunchTooling' | 'providerCoverageAreaLaunchTooling';
}

export default function ServiceProviderMultiSelect({ onServiceProviderRemove, onServiceProviderAdd, variant }:IServiceProviderSingleSelectProps) {
  const store = useProviderCoverageAreaLaunchToolingStore();

  const formCtx = useFormContext();
  const { formState, setError, clearErrors } = formCtx;
  const { isError, getErrorMessage } = useErrorState(formCtx);
  const isDirty = get(formState.dirtyFields, 'serviceProviderId', false);
  const isTouched = get(formState.touchedFields, 'serviceProviderId', false);
  return (
    <ServiceProviderAutocomplete
      fieldName='serviceProviderId'
      label={'Service Providers'}
      formless={false}
      placeholder={'Search and check service providers'}
      required={false}
      postOnChange={(_formCtx, values, reason, details) => {
        if (reason === 'removeOption') {
          const launchDtos = _formCtx.getValues('serviceProviderLaunchDtos');
          for (let i = 0; i < launchDtos.length; i++) {
            let serviceProviderId = launchDtos[i].serviceProviderId;
            if ((details?.option?.optionValue ?? details.optionValue) === serviceProviderId) {
              onServiceProviderRemove(i);
            }
          }
        } else if (reason === 'selectOption') {
          onServiceProviderAdd(details.option.optionValue, details.option.optionText);

        } else if (reason === 'clear') {
          onServiceProviderRemove();
        }
      }}
    />

  );
}