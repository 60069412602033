import { Grid, Typography } from '@mui/material';
import { toggleNeighborhoodServiceOfferingsByServiceProvider } from 'api/neighborhoodServiceOfferingApi';
import { createApiKey } from 'api/serviceProviderApi';
import CopyButton from 'components/buttons/CopyButton';
import GenericModal from 'components/modal/GenericModal';
import useModal, { IModal } from 'components/modal/useModal';
import { createEmptyServiceOfferingStatusCheckDTO, IServiceOfferingStatusCheckDTO } from 'model/serviceOffering';
import { useEffect } from 'react';
import { formatMonthDayTime, formatMonthDayYear } from 'util/dateUtil';

export interface ICreateApiKeyModal {
  name: string;
  id:string;
  hasApiKey:boolean;
  createdApiKey: string;
}
export function createEmptyApiKeyModal() :ICreateApiKeyModal {
  return {
    name: '',
    id: '',
    hasApiKey: false,
    createdApiKey: '',
  };
}
export interface ICreateApiKeyModalProps {
  postOnConfirm: (serviceProviderId:string, apiKey:string) => void;
}

export default function CreateApiKeyModal({ postOnConfirm }:ICreateApiKeyModalProps) {
  const { getModalState, updateModalState, closeModal, open } = useModal('createServiceProviderApiKeyModal');
  const _state = getModalState() as ICreateApiKeyModal & IModal;
  const { id, name, hasApiKey } = _state;

  async function _onCreateApiKeyForServiceProvider(state:ICreateApiKeyModal & IModal) {
    var res = await createApiKey(state.id);
    if (res.data) {
      postOnConfirm(state.id, res.data.apiKey);
    }
    updateModalState({ ...state, createdApiKey: res.data.apiKey });
  }
  return (
    <GenericModal
      modalStoreKey={'createServiceProviderApiKeyModal'}
      title={`Create API key for ${name}?`}
      overrideSx={{
        '& .MuiPaper-root': {
          width: '600px',
        },
      }}
      modalContent={
        (
          <div >
            {hasApiKey && (
              <Typography variant='body1' sx={{ color: 'red' }}>This service provider already has an API key.</Typography>
            )}
            <Typography variant='body1' >

            </Typography>
            {_state.createdApiKey && (
              <Grid container sx={{ marginTop: '10px', marginBottom: '10px' }}>
                <Typography variant='body1' sx={{ color: 'green' }}>API key: {_state.createdApiKey}</Typography>
                <CopyButton message={_state.createdApiKey} />
              </Grid>
            )}
          </div>
        )
      }
      onConfirm={() => _onCreateApiKeyForServiceProvider(_state)}
      secondaryButtonText='Cancel'
    />
  );
}