export class LookUpEntityType {
  static PROVIDER_CRM_OPTION = 'PROVIDER_CRM_OPTION';
  static NEIGHBORHOOD_CAMPAIGN_TAG = 'NEIGHBORHOOD_CAMPAIGN_TAG';
  static NEIGHBORHOOD_EXPERIMENT_TAG = 'NEIGHBORHOOD_EXPERIMENT_TAG';
  static NEIGHBORHOOD_CAMPAIGN_TEMPLATE_KEY = 'NEIGHBORHOOD_CAMPAIGN_TEMPLATE_KEY';
}


export interface ILookUp {
  sk:string;
  pk:string;
  entityType:string;
  entityValue:string;
}

export interface ICreateLookUpOptionRequest {
  lookUpEntityType: string;
  value: string;
}