import get from 'lodash.get';
import { INeighborhood } from 'model/neighborhood';

/**
 *
 * @param neighborhoods neighborhoods
 * @param filter used to filter neighborhoods before iterating over them
 * @param keyedPropertyPath the property path on the neighborhood that will be used for the key in the result map. must be a string.
 * @returns
 */
export function createNeighborhoodMap(neighborhoods:INeighborhood[], filter: (x:INeighborhood) => boolean, keyedPropertyPath:string ):Map<string, INeighborhood[]> {
  let neighborhoodMap = new Map();
  let filteredNeighborhoods = neighborhoods.filter(filter);
  for (let i = 0; i < filteredNeighborhoods.length; i++) {
    let neighborhood = filteredNeighborhoods[i];
    const keyedPropertyValue = get(neighborhood, keyedPropertyPath, null);
    if (neighborhoodMap.has(keyedPropertyValue)) {
      let _neighborhoods = neighborhoodMap.get(keyedPropertyValue);
      _neighborhoods.push(neighborhood);
      neighborhoodMap.set(keyedPropertyValue, _neighborhoods);
    } else {
      neighborhoodMap.set(keyedPropertyValue, [neighborhood]);
    }
  }
  return neighborhoodMap;
}

export function extractNeighborhoodsFromMap(map:Map<string, INeighborhood[]>, keys:string[]):INeighborhood[] {
  const neighborhoods:INeighborhood[] = [];
  keys.forEach((key, index) => {
    if (map.has(key)) {
      let mappedNeighborhoods = map.get(key);
      if (mappedNeighborhoods) {
        neighborhoods.push(...mappedNeighborhoods);
      }
    }
  });
  return neighborhoods;
}

export function getNeighborhoodZipCodeStatusString(neighborhood: INeighborhood):string {
  const name = neighborhood.name;
  const zipCode = neighborhood.zipCodeTerritory || 'Territory N/A';
  const status = neighborhood.status;

  return `${name} - ${zipCode} (${status})`;
}
