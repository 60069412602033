import Loading from 'components/Layout/Loading';
import NeighborhoodSelect from './NeighborhoodSelect';
import NeighborhoodSeedingTable from './NeighborhoodSeedingTable';
import PageHeader from 'components/SectionHeaders/PageHeader';
import useNeighborhoodSeedingToolingStore from './useNeighborhoodSeedingToolingStore';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Grid, Typography } from '@mui/material';

export default function NeighborhoodSeedingTooling() {
  const formContext = useForm({
    mode: 'onTouched', defaultValues: {},
  });
  const { init, neighborhoodOptions, selectedNeighborhood, loadingKey } = useNeighborhoodSeedingToolingStore();
  useEffect(() => {
    void init();
  }, []);
  const houseHoldCount = selectedNeighborhood?.houseHoldCount || 'Unknown';

  return (
    <FormProvider {...formContext}>
      <Loading loadingKey={loadingKey}>
        <Grid container sx={{ flexDirection: 'column', paddingLeft: '10px', paddingRight: '10px' }}>
          <Grid item xs={12}>
            <PageHeader headerText={'Neighborhood Seeding Tooling'} />
          </Grid>
          <Grid container spacing={2} sx={{ flexDirection: 'row', alignItems: 'flex-end', padding: '20px' }}>
            <Grid item xs={4}>
              <NeighborhoodSelect neighborhoods={neighborhoodOptions} />
            </Grid>
            <Grid item>
              {selectedNeighborhood?.id && (
                <Typography variant='h6'>({houseHoldCount} HH Count)</Typography>
              )}
            </Grid>
          </Grid>
          <>
            <Grid item xs={12}>
              <NeighborhoodSeedingTable />
            </Grid>
          </>
        </Grid>
      </Loading>
    </FormProvider>
  );
};