import { Grid, Typography } from '@mui/material';
import HookConfirmationModal from 'components/modals/HookConfirmationModal';
import useTerritoryDetails from '../hookStore/useTerritoryDetails';

export const TerritoryPrepConfirmationModalKey = 'TerritoryPrepConfirmationModalKey';

export default function TerritoryPrepConfirmationModal() {
  const { formData, onConfirmTerritoryPrep } = useTerritoryDetails();
  return (
    <HookConfirmationModal
      title={() => 'Confirm Territory Prep'}
      body='Prep Territory?'
      modalKey={TerritoryPrepConfirmationModalKey}
      message={(confirmArgs, cancelModal) => (
        <Grid container spacing={1} alignItems='center' justifyContent='center' sx={{ maxWidth: '600px' }}>
          <Grid item xs={12} paddingTop={'30px'} paddingBottom={'25px'}>
            <Typography variant='body1'>
                Initiate preparation process for territory - {formData.name}?
            </Typography>
            <Typography variant='body1'>
                This will reconcile NSOs for all neighborhoods in the territory. In addition, any PropStream addresses uploaded will be used to set fields on each neighborhood.
            </Typography>
          </Grid>
        </Grid>
      )}
      onConfirm={async (data) => {
        await onConfirmTerritoryPrep();
      }}
    />
  );
}