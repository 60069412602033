import { Checkbox, FormControlLabel, Grid, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { FormProvider, useForm, useFormContext, useWatch } from 'react-hook-form';
import useOneTimeNewsletter from './oneTimeNewsletterHookStore';
import useMarkets from 'hooks/useMarkets';
import PageHeader from 'components/SectionHeaders/PageHeader';
import { HookCheckbox, HookSelect, HookTextField } from 'components/reactHookForm';
import HookDatePicker from 'components/reactHookForm/HookDatePicker';
import MarketSelect from 'pages/providerSetup/providerInformation/MarketSelect';
import { IOneTimeNewsletterForm, createEmptyOneTimeNewsletterFormDto } from 'model/marketing/oneTimeNewsletter';
import HookConfirmationModal from 'components/modals/HookConfirmationModal';
import useToast from 'components/toast/useToast';
import { letterBatchDetailModalKey } from 'pages/letterBatches/letterBatchDetail/LetterBatchDetailForm';
import { useNavigate } from 'react-router-dom';
import StickyFormButtons from './components/StickyFormButtons';
import TerritoryAutocomplete from 'components/autocomplete/TerritoryAutocomplete';
import { useLoading } from 'components/Layout/Loading';
import AvailableNeighborhoods from './components/AvailableNeighborhoods';
import FromEmailSelect from './components/FromEmailSelect';


export interface IOneTimeNewsletterFormInternalProps {
  initialFormData: IOneTimeNewsletterForm;

}

export const oneTimeNewsletterModalKey = 'oneTimeNewsletterModalKey';

function ModalWrapper() {
  const { getValues } = useFormContext();

  const { onSubmit } = useOneTimeNewsletter();
  const { createErrorToast } = useToast();
  const { selectedMarket } = useMarkets();
  const watchSendTest = useWatch({ name: 'sendTest' });
  return (
    <HookConfirmationModal
      title={() => 'Confirm One Time Newsletter'}
      body='Submit?'
      modalKey={oneTimeNewsletterModalKey}
      message={(confirmArgs, cancelModal) => (

        <Grid container spacing={1} alignItems='center' justifyContent='start' sx={{ paddingBottom: '20px', maxWidth: '600px' }}>
          <Grid item xs={12}>
            <Typography variant='body1'>Are you sure you want to submit?</Typography>
          </Grid>
          <Grid item xs={12} sm={6} sx={{ paddingLeft: '10px' }}>
            <HookCheckbox
              name='sendTest'
              label='Send Test'
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <HookTextField
              label='Test Email (optional)'
              name='testToEmail'
            />
          </Grid>
          <Grid item xs={12}>
            <FromEmailSelect/>
          </Grid>

        </Grid>

      )}
      confirmText={watchSendTest ? 'Send Test' : 'Submit'}
      onConfirm={async (data) => {
        try {
          await onSubmit(getValues() as IOneTimeNewsletterForm, selectedMarket?.id!);
        } catch (err:any) {
          createErrorToast('Unable to submit one time newsletter');
        }
      }}

    />
  );
}

function OneTimeNewsletterFormInternal({ initialFormData }:IOneTimeNewsletterFormInternalProps) {
  const { loadingKey, onTerritoryChange } = useOneTimeNewsletter();
  const { selectedMarket } = useMarkets();
  const { onLoading, doneLoading } = useLoading(loadingKey);

  const formContext = useForm<IOneTimeNewsletterForm>({
    mode: 'onChange',
    defaultValues: createEmptyOneTimeNewsletterFormDto(),
  });
  useEffect(() => {
    formContext.reset(initialFormData);
  }, [initialFormData]);

  if (!selectedMarket) {
    return null;
  }

  return (
    <FormProvider {...formContext}>
      <form >
        <Grid container sx={{
          '&&&': {
            position: 'relative',
          },
        }}>
          <StickyFormButtons initialFormData={initialFormData}/>
          <Grid container spacing={2} className='pageGridContainer' alignItems={'start'}>
            <Grid item xs={12} md={3} sx={{ marginTop: '20px' }}>
              <TerritoryAutocomplete
                marketId={selectedMarket.id!}
                onChangeOverride={async (option:any) => {
                  if (option && option.optionValue) {
                    onLoading();
                    await onTerritoryChange(selectedMarket!, option.ancillary);
                    formContext.setValue('territoryId', option.optionValue);
                    doneLoading();
                  }
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <AvailableNeighborhoods/>
            </Grid>
          </Grid>
          <ModalWrapper />


        </Grid>
      </form>
    </FormProvider>
  );
}

export default function OneTimeNewsletterForm() {
  const { formData } = useOneTimeNewsletter();
  return (
    <OneTimeNewsletterFormInternal initialFormData={formData} />
  );
}