import { Button, Grid, TextField, Typography } from '@mui/material';
import Loading from 'components/Layout/Loading';
import DemoNeighborhoodFromAddress from './DemoNeighborhoodFromAddress';
import useHome from './hookStore/useHome';

function HomePage() {
  const {
    loadingKey,
    latitude,
    longitude,
    neighborhood,
    onLatitudeChange,
    onLongitudeChange,
    onFindNeighborhoodByCoordinates,
  } = useHome();
  return (
    <Loading loadingKey={loadingKey} size={100}>
      <Grid container sx={{ padding: 1 }} alignItems='center' justifyContent='center'>
        <Grid item xs='auto'>
          <Typography variant='h5'>Welcome to the Admin Portal!  LFG!</Typography>
        </Grid>

        <Grid item xs={12}></Grid>
        <Grid container item spacing={1} xs={6} sx={{ margin: 1, border: '1px solid gainsboro' }} >
          <Grid item xs={12} >
            <Typography variant='h6'>Neighborhood lookup via Geo coordinates</Typography>
          </Grid>
          <Grid item>
            <TextField variant='standard' name='longitude' label='longitude' value={longitude} onChange={(e) => onLongitudeChange(e.target.value)}/>
          </Grid>
          <Grid item>
            <TextField variant='standard' name='latitude' label='latitude' value={latitude} onChange={(e) => onLatitudeChange(e.target.value)}/>
          </Grid>
          <Grid item xs='auto'>
            <Button
              variant='contained'
              type='button'
              onClick={() => onFindNeighborhoodByCoordinates(latitude, longitude)}
            >
              <>Submit</>
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='subtitle1'>Result: {neighborhood ? neighborhood.name : 'No Result'}</Typography>
          </Grid>
        </Grid>
        <DemoNeighborhoodFromAddress/>
      </Grid>
    </Loading>
  );
}

export default HomePage;