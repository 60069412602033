import { Box, Grid, Typography } from '@mui/material';
import Loading from 'components/Layout/Loading';
import ConfirmationModal, { ConfirmationModalContextProvider } from 'components/modals/ConfirmationModal';
import PageHeader from 'components/SectionHeaders/PageHeader';
import { useEffect } from 'react';
import styles from './addressContactsUpload.module.css';
import HomeUploadForm from './AddressContactsUploadForm';
import useAddressContactsUpload from './addressContactsUploadHookStore';
import TooltipDrawer from 'components/drawer/TooltipDrawer';
import TooltipDrawerButton from 'components/drawer/TooltipDrawerButton';


export default function HomeUpload() {
  const { onUpload, init, loadingKey } = useAddressContactsUpload();

  useEffect(() => {
    void init();
  }, []);

  return (
    <ConfirmationModalContextProvider onConfirm={(args) => onUpload(args)}>
      <Loading loadingKey={loadingKey} size={100}>
        <Grid container spacing={1} className='pageGridContainer' justifyContent='center' alignItems={'center'}>

          <Grid item xs={12}>
            <PageHeader
              headerText={'Address Contact Upload - PropStream'}
              rightChildren={
                <TooltipDrawerButton
                  storeKey='addressContactUploadDrawer'
                  iconButtonProps={{ sx: { marginTop: '20px' } }}
                />
              }
            />
          </Grid>
          <Grid item xs={12} sx={{ marginTop: 1 }}>
            <HomeUploadForm/>
          </Grid>
        </Grid>
      </Loading>
      <ConfirmationModal
        title={(onConfirmArgs) => (<div className={styles.confirmDeleteModalTitle}>Upload PropStream skip tracing file</div>)}
        message={(onConfirmArgs:any) => {
          if (onConfirmArgs.shouldUpload) {
            return (
              <Grid container spacing={1} alignItems='center' justifyContent='center' className={styles.confirmModal}>
                <Grid item xs>
                  You are about to upload {onConfirmArgs.fileName}. You will receive a datadog alert when it completes
                </Grid>
              </Grid>
            );
          }
        }}
      />
      <TooltipDrawer storeKey={'addressContactUploadDrawer'}>
        <Box sx={{ padding: '16px', maxWidth: '400px' }}>
          <Typography variant='h6'>Address Contacts Upload</Typography>
          <Typography variant='body1'>Used to upload a PropStream skip tracing file for processing and insertion into address_contacts table.</Typography>
        </Box>
      </TooltipDrawer>
    </ConfirmationModalContextProvider>
  );
}