import { findDiscountSchedulesByProvider } from 'api/discountScheduleApi';
import { findNeighborhoodServiceOfferingById } from 'api/neighborhoodServiceOfferingApi';
import { findServiceTypeInfo } from 'api/serviceCategoryApi';
import { findServiceOfferingsByProvider } from 'api/serviceOfferingApi';
import { useLoading } from 'components/Layout/Loading';
import useToast from 'components/toast/useToast';
import createStore from 'hooks/hookStore';
import { IDropdownOption } from 'model/dropdown';
import { createEmptyNeighborhoodServiceOfferingForm, INeighborhoodServiceOfferingForm } from 'model/neighborhoodServiceOffering';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { findFirstMatch } from 'util/listUtil';

function compare(a: any, b: any) {
  return a.name > b.name ? 1 : b.name > a.name ? -1 : 0;
}

const loadingKey = 'neighborhoodServiceOfferingDetail';

type NeighborhoodServiceOfferingDetail = {
  discountSchedules:any[];
  formValues: any;
  neighborhoodNameMap: any;
  neighborhoods:any[];
  serviceOfferings:any[];
  serviceProviders:any[];
  serviceTypeNameMap: any;
}

const { get, update, registerListener, unregisterListener } = createStore<NeighborhoodServiceOfferingDetail>('neighborhoodServiceOfferingDetail', {
  discountSchedules: [],
  formValues: {},
  neighborhoodNameMap: {},
  neighborhoods: [],
  serviceOfferings: [],
  serviceProviders: [],
  serviceTypeNameMap: {},
});

export default function useNeighborhoodServiceOfferingDetail() {
  const setState = useState(get())[1];
  const navigate = useNavigate();

  const { createErrorToast, createSuccessToast } = useToast();
  const { neighborhoodServiceOfferingId } = useParams();
  const { onLoading, doneLoading } = useLoading(loadingKey);

  useEffect(() => {
    registerListener(setState);
    return () => {
      unregisterListener(setState);
    };
  }, []);

  async function init() {
    onLoading();
    try {
      let serviceTypeNameMap;

      const resServiceCategoryTypes = await findServiceTypeInfo();
      serviceTypeNameMap = resServiceCategoryTypes.data.serviceTypes
        .reduce((map, obj) => {
          if (obj.serviceTypeId && !obj.deleteDate) {
            map[obj.serviceTypeId] = obj.displayName ?? obj.name;
          }
          return map;
        }, {});

      if (neighborhoodServiceOfferingId) {
        const resNso = await findNeighborhoodServiceOfferingById(neighborhoodServiceOfferingId);

        if (resNso.data) {
          const { name, altName, neighborhoodId, providerId, serviceOfferingId, discountScheduleId, price, disabledDate } = resNso.data;

          const [serviceOfferingsRes, discountSchedulesRes] = await Promise.all([
            findServiceOfferingsByProvider(providerId),
            findDiscountSchedulesByProvider(providerId),
          ]);
          serviceOfferingsRes.data.sort(compare);
          discountSchedulesRes.data.sort(compare);
          const serviceOfferings = serviceOfferingsRes.data;
          const discountSchedules = discountSchedulesRes.data;

          const _name = getTemplatedName(neighborhoodId, serviceOfferingId);

          const nextFormValues = {
            name: _name,
            altName,
            neighborhoodId,
            providerId,
            serviceOfferingId,
            discountScheduleId,
            price,
            isEnabled: !Boolean(disabledDate),
            disabledDate,
          };

          update({
            ...get(),
            serviceOfferings,
            discountSchedules,
            neighborhoodNameMap: {},
            serviceTypeNameMap,
            formValues: nextFormValues,
          });
        }
      } else {
        update({
          ...get(),
          neighborhoodNameMap: {},
          serviceTypeNameMap,
          formValues: createEmptyNeighborhoodServiceOfferingForm(),
        });
      }

      doneLoading(300);
    } catch (e:any) {
      console.error(e);
      createErrorToast('Could not retrieve nso');
      doneLoading(300);
    }
  }

  function getTemplatedName(selectedNeighborhoodId:string, selectedServiceOfferingId:string) {
    const { serviceOfferings, serviceTypeNameMap, neighborhoodNameMap } = get();
    if (!selectedNeighborhoodId || !selectedServiceOfferingId) {
      return '';
    }
    //check if string
    let _selectedNeighborhoodId = selectedNeighborhoodId;
    if (typeof selectedNeighborhoodId !== 'string') {
      //must be dropdown option.
      _selectedNeighborhoodId = (selectedNeighborhoodId as any).optionValue;
    }
    const serviceOffering = serviceOfferings.find(x => x.id === selectedServiceOfferingId);
    if (!serviceOffering || !serviceTypeNameMap || !serviceTypeNameMap[serviceOffering.serviceTypeId]) {
      return '';
    }
    const serviceType = serviceTypeNameMap[serviceOffering.serviceTypeId];
    const neighborhood = neighborhoodNameMap[_selectedNeighborhoodId];

    return `${serviceType} in ${neighborhood}`;
  };


  async function onServiceProviderChange (nextProviderId) {
    if (nextProviderId) {
      const [serviceOfferingsRes, discountSchedulesRes] = await Promise.all([
        findServiceOfferingsByProvider(nextProviderId),
        findDiscountSchedulesByProvider(nextProviderId),
      ]);
      serviceOfferingsRes.data.sort(compare);
      discountSchedulesRes.data.sort(compare);

      update({
        ...get(),
        serviceOfferings: serviceOfferingsRes.data,
        discountSchedules: discountSchedulesRes.data,
      });
    }
  }

  const goBack = () => {
    navigate('/neighborhood-service-offerings');
  };

  function getDisabledDateToSave(values:INeighborhoodServiceOfferingForm, now:Date) {
    let { disabledDate, isEnabled } = values;
    if (!isEnabled) {
      return disabledDate ?? now;
    }
    //otherwise it's enabled and we want to clear the disabledDate field
    return null;
  }

  function getDiscountSchedule(id:string):any {
    const { discountSchedules } = get();
    return findFirstMatch(discountSchedules, 'id', id);
  }

  function onNeighborhoodsSearched(neighborhoods:IDropdownOption[]) {
    const { neighborhoodNameMap } = get();

    const _selectedNameMap = neighborhoods
      .reduce((map, obj) => {
        if (obj.optionValue) {
          map[obj.optionValue] = obj.ancillary.name;
        }
        return map;
      }, {});

    update({
      ...get(),
      neighborhoodNameMap: {
        ...neighborhoodNameMap,
        ..._selectedNameMap,
      },
    });
  }

  return {
    loadingKey,
    ...get(),
    init,
    onServiceProviderChange,
    goBack,
    getTemplatedName,
    getDisabledDateToSave,
    getDiscountSchedule,
    onNeighborhoodsSearched,
  };
}