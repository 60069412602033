import { useLoading } from 'components/Layout/Loading';
import createStore from 'hooks/hookStore';
import { useEffect, useState } from 'react';
import { IMarket } from 'model/markets';
import { IDropdownOption } from 'model/dropdown';
import { findTerritoriesByMarket } from 'api/territoriesApi';
import { useNavigate, useParams } from 'react-router-dom';
import { ITerritory, createUnassignedTerritoryOption } from 'model/territories';
import useToast from 'components/toast/useToast';
import { cloneTerritoryTemplates, findAllTerritorySeedingTemplatesByZipcodeTerritory } from 'api/territorySeedingTemplatesApi';
import { ICloneTerritorySeedingTemplateRequest, ITerritorySeedingTemplate } from 'model/territorySeedingTemplates';
import { sortByString } from 'util/sortFunctions';

const loadingKey = 'TerritorySeedingTemplates';
type TerritorySeedingTemplates = {
  territories: IDropdownOption[];
  selectedTerritory:string;
  selectedCloneFromTerritory:string;
  isAddProviderDrawerOpen: boolean;
  tableRows: any[];
  templates: ITerritorySeedingTemplate[];
}

const { get, update, registerListener, unregisterListener } = createStore<TerritorySeedingTemplates>('TerritorySeedingTemplates', {
  territories: [],
  selectedTerritory: '',
  selectedCloneFromTerritory: '',
  isAddProviderDrawerOpen: false,
  templates: [],
  tableRows: [],
});

function convertTemplateDataForGrid(data:ITerritorySeedingTemplate[]) {
  return data.map((item) => ({
    ...item,
    // row items need to have a unique ID attribute
    id: item.providerId + item.serviceType + item.serviceCategory,
  }));
}
export default function useTerritorySeedingTemplates() {
  const setState = useState(get())[1];
  const { onLoading, doneLoading } = useLoading(loadingKey);
  const { createErrorToast, createSuccessToast } = useToast();

  useEffect(() => {
    registerListener(setState);
    return () => {
      unregisterListener(setState);
    };
  }, []);


  async function init(market:IMarket | null) {
    if (market == null) {
      console.log('waiting to load market');
      return;
    }
    onLoading();
    try {
      let territories: IDropdownOption[] = [];
      const territoriesRes = await findTerritoriesByMarket(market.id);
      territories = convertTerritoriesToDropdownOptions(territoriesRes.data);
      update({
        ...get(),
        territories,
      });
    } catch (e:any) {
      console.error(e);
    }
    doneLoading(300);
  }

  async function onTerritorySelect(territorySelection: string) {
    onLoading();
    try {
      const templates = await findAllTerritorySeedingTemplatesByZipcodeTerritory(territorySelection);
      update({
        ...get(),
        selectedTerritory: territorySelection,
        templates: templates.data,
        tableRows: convertTemplateDataForGrid(templates.data),
      });
    } catch (e) {
      console.error(e);
    }
    doneLoading();
  }

  async function onCloneFromTerritorySelect(territorySelection: string) {
    onLoading();
    try {
      update({
        ...get(),
        selectedCloneFromTerritory: territorySelection,
      });
    } catch (e) {
      console.error(e);
    }
    doneLoading();
  }

  function convertTerritoriesToDropdownOptions(territories: ITerritory[]): IDropdownOption[] {
    const sortByFunc = sortByString('optionText');
    let options = territories.map(m => {
      return {
        key: m.id,
        optionValue: m.zipCodeTerritory,
        optionText: `${m.name ?? ''} (${m.zipCodeTerritory})`,
        ancillary: m,
      } as IDropdownOption;
    }).sort(sortByFunc);
    options.unshift(createUnassignedTerritoryOption());
    return options;
  }

  async function refreshTemplatesForCurrentTerritory() {
    onLoading();
    try {
      const territorySelection = get().selectedTerritory;
      const templates = await findAllTerritorySeedingTemplatesByZipcodeTerritory(territorySelection);
      update({
        ...get(),
        templates: templates.data,
        tableRows: convertTemplateDataForGrid(templates.data),
      });
    } catch (e) {
      console.error(e);
    }
    doneLoading();
  }

  function toggleAddProviderDrawer() {
    update({
      ...get(),
      isAddProviderDrawerOpen: !get().isAddProviderDrawerOpen,
    });
  }

  async function onCloneFromConfirm() {
    onLoading();
    try {
      const request: ICloneTerritorySeedingTemplateRequest = {
        fromZipcodeTerritory: get().selectedCloneFromTerritory,
        toZipcodeTerritory: get().selectedTerritory,
      };
      await cloneTerritoryTemplates(request);
      createSuccessToast('Successfully cloned territory template!');
    } catch (e:any) {
      console.error(e);
      createErrorToast(`Error cloning territory seeding templates: ${e.response?.data?.message}`);
    }
    doneLoading(300);
  }

  return {
    ...get(),
    loadingKey,
    init,
    onTerritorySelect,
    onCloneFromTerritorySelect,
    onCloneFromConfirm,
    toggleAddProviderDrawer,
    refreshTemplatesForCurrentTerritory,
  };
}