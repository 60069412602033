import { Grid, Typography } from '@mui/material';
import HookConfirmationModal from 'components/modals/HookConfirmationModal';
import useSeedFromTemplate from '../_hookStore/useSeedFromTemplate';

export const SeedNeighborhoodconfirmationModalKey = 'SeedNeighborhoodconfirmationModalKey';

export default function SeedNeighborhoodConfirmationModal() {
  const { selectedNeighborhood, onConfirmNeighborhoodSeeding } = useSeedFromTemplate();
  return (
    <HookConfirmationModal
      title={() => 'Confirm Neighborhood Seeding'}
      body='Seed Neighborhood?'
      modalKey={SeedNeighborhoodconfirmationModalKey}
      message={(confirmArgs, cancelModal) => (
        <Grid container spacing={1} alignItems='center' justifyContent='center' >
          <Grid item xs={12} paddingTop={'30px'} paddingBottom={'25px'}>
            <Typography variant='body1'>Seed {selectedNeighborhood?.optionText} from territory template?</Typography>
          </Grid>
        </Grid>
      )}
      onConfirm={async (data) => {
        await onConfirmNeighborhoodSeeding();
      }}
    />
  );
}