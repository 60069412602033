import GenericModal from 'components/modal/GenericModal';
import useModal, { IModal } from 'components/modal/useModal';
import { Alert, Grid, Typography } from '@mui/material';

export const modalStoreKey = 'NumbersOnDoNotCallListAlertModal';

export interface INumbersOnDoNotCallListAlertModal extends IModal {
  flaggedNumbers: string[];
  providerName: string;
  onConfirmCallback: () => void;
}
export function createEmptyNumbersOnDoNotCallListAlertModal() :INumbersOnDoNotCallListAlertModal {
  return {
    open: false,
    flaggedNumbers: [],
    providerName: '',
    onConfirmCallback: () => {},
  };
}

export default function NumbersOnDoNotCallListAlertModal() {
  const { closeModal, getModalState } = useModal(modalStoreKey);
  var modalState = getModalState() as INumbersOnDoNotCallListAlertModal;

  var {
    flaggedNumbers = [],
    providerName = '',
  } = modalState;

  const onSubmit = async (data:any) => {
    try {
      modalState.onConfirmCallback();
    } catch (error) {
      console.error(error);
    }
    closeModal(createEmptyNumbersOnDoNotCallListAlertModal());
  };

  return (
    <GenericModal
      modalStoreKey={modalStoreKey}
      title={'WARNING: Phone number(s) on the Do-Not-Call list!'}
      modalContent={
        (
          <Grid container item justifyContent='center' rowSpacing={2}>
            <Alert severity='warning'>
                The following phone number(s) are on the Do-Not-Call list:
              {flaggedNumbers.map((x, i) => (
                <Typography key={i} variant='body1'>- {x}</Typography>
              ))}
            </Alert>
            <Grid item xs={11}>
              <Typography variant='body1'>Do you want to proceed with saving {providerName}?</Typography>
            </Grid>
          </Grid>
        )
      }
      onConfirm={onSubmit}
      onSecondaryAction={() => closeModal(createEmptyNumbersOnDoNotCallListAlertModal())}
      primaryButtonText='Yes, Proceed'
      secondaryButtonText='No, Cancel'
      overrideSx={{
        '& .MuiPaper-root': {
          width: '600px',
        },
      }}
    />
  );
}