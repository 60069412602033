import { Grid, Typography } from '@mui/material';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import Loading from 'components/Layout/Loading';
import { PrimaryButton } from 'components/buttons';
import useAddProviderToTemplates from './hookStore/useAddProviderToTemplates';
import ServiceProviderSelect from './form_components/ServiceProviderSelect';
import { AddProviderToTemplateModel } from 'model/seeding/addProviderToTemplates';
import SaveButton from './form_components/SaveButton';
import ServiceOfferingSelections from './form_components/ServiceOfferingSelections';
import { createUninitializedDropdownOption } from 'model/dropdown';
import TerritoryMultiAutocomplete from 'components/autocomplete/TerritoryMultiAutocomplete';
import TerritoryMultiSelect from './form_components/TerritoryMultiSelect';


export default function AddProviderToTemplatesForm() {
  const { onProviderSelect, onSave } = useAddProviderToTemplates();

  const formContext = useForm<AddProviderToTemplateModel.IAddProviderToSeedingTemplatesRequestForm>({
    mode: 'onChange',
    defaultValues: {
      serviceProvider: createUninitializedDropdownOption(),
      marketId: '',
      serviceOfferingsWithSeedFactor: [],
      zipCodeTerritories: [],
    },
  });

  const onSubmit : SubmitHandler<AddProviderToTemplateModel.IAddProviderToSeedingTemplatesRequestForm> = async data => {
    await onSave(data);

  };


  return (
    <FormProvider {...formContext}>
      <form onSubmit={formContext.handleSubmit(onSubmit)}>
        <Grid container item >
          <Grid item xs={12} sm={4} paddingTop="20px" paddingBottom="20px">
            <ServiceProviderSelect/>
          </Grid>
          <Grid container item>
            <Grid item xs={12}>
              <Typography variant='h6'>Which Service Offerings?</Typography>
            </Grid>
            <Grid item xs>
              <ServiceOfferingSelections/>
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ paddingTop: '40px' }}>
            <TerritoryMultiSelect/>
          </Grid>
          <Grid item xs={12}>
            <SaveButton/>
          </Grid>

        </Grid>
      </form>
    </FormProvider>
  );
}