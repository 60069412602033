import { Grid, Typography } from '@mui/material';
import HookLargeIntegerField from 'components/reactHookForm/HookLargeIntegerField';
import { CampaignType } from 'model/letter_batch/ancillary';
import { useFormContext, useWatch } from 'react-hook-form';

export default function AddressCount() {
  const formContext = useFormContext();
  const val = useWatch({ name: 'campaignType', control: formContext.control });

  if (val === CampaignType.FOLLOW_UP) {
    return (
      <Grid item xs={12} sm={6} md={4}>
        <HookLargeIntegerField
          name='targetAddressCount'
          label='Address Count'
        />
      </Grid>
    );
  } else if (val === CampaignType.LAUNCH_LEADER_ACQUISITION) {
    return (
      <Grid item xs={12} sm={6} md={4} alignContent={'center'} alignItems={'center'} sx={{ height: '56px' }}>
        <Typography variant='body1'>Address Count: {formContext.getValues('targetAddressCount') ?? 'Not set'}</Typography>
      </Grid>
    );
  }
  return null;
}