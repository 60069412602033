import { sortByString } from 'util/sortFunctions';
import { useLoading } from 'components/Layout/Loading';
import useToast from 'components/toast/useToast';
import createStore from 'hooks/hookStore';
import { createUninitializedDropdownOption, IDropdownOption, UNSELECTED_OPTION } from 'model/dropdown';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { convert, createEmptyRebookServicesForm, IRebookServicesDto, IRebookServicesForm, IRebookServicesNSOIFilter } from 'model/rebookServices';
import { IServiceOffering } from 'model/serviceOffering';
import { findServiceOfferingsByProvider } from 'api/serviceOfferingApi';
import { convertToDropdown, IDropdownPropertyMapper } from 'util/dropdownUtil';
import { INeighborhoodServiceOfferingInstance } from 'model/serviceInstance';
import { findHappeningSoonByServiceOfferingAndTerritory } from 'api/serviceInstanceApi';
import { formatMonthDayYear } from 'util/dateUtil';
import { sendRebookReminders } from 'api/communicationsApi';
import { IMarket } from 'model/markets';
import { findTerritoriesByMarket } from 'api/territoriesApi';
import { ITerritory } from 'model/territories';

const loadingKey = 'RebookServiceNotificationsStore';
type RebookServiceNotificationsStore = {
  sourceServiceOfferings: IServiceOffering[];
  formData:IRebookServicesForm;
  serviceOfferingOptions: IDropdownOption[];
  targetServiceOfferingOptions: IDropdownOption[];
  territoryOptions: IDropdownOption[];
  nsoiOptions:IDropdownOption[];
}

const { get, update, registerListener, unregisterListener } = createStore<RebookServiceNotificationsStore>('RebookServiceNotificationsStore', {
  sourceServiceOfferings: [],
  formData: createEmptyRebookServicesForm(),
  serviceOfferingOptions: [createUninitializedDropdownOption()],
  targetServiceOfferingOptions: [createUninitializedDropdownOption()],
  territoryOptions: [createUninitializedDropdownOption()],
  nsoiOptions: [createUninitializedDropdownOption()],

});

export default function useRebookServiceNotifications() {
  const setState = useState(get())[1];
  const navigate = useNavigate();
  const { onLoading, doneLoading } = useLoading(loadingKey);
  const { createErrorToast, createInfoToast } = useToast();


  useEffect(() => {
    registerListener(setState);
    return () => {
      unregisterListener(setState);
    };
  }, []);

  async function init(market:IMarket) {
    onLoading();
    try {
      const territoryRes = await findTerritoriesByMarket(market.id);
      let territoryOptions:IDropdownOption[] = [];
      if (territoryRes.data) {
        territoryOptions = convertTerritoriesForDropdown(territoryRes.data!);
      }

      update({
        ...get(),

        territoryOptions,
      });
    } catch (e:any) {
      console.error(e);
    }
    doneLoading(300);
  }

  async function save(marketId:string, data:IRebookServicesForm) {
    const dataToSave = { ...data };
    onLoading();
    try {
      var dto:IRebookServicesDto = await convert(marketId, dataToSave);
      const res = await sendRebookReminders(dto.serviceProviderId, dto.serviceOfferingId, dto);
      createInfoToast('Rebook Services Notification sent.');
      doneLoading(200);
    } catch (err:any) {
      createErrorToast('Error sending Rebook Services Notification.');
      console.error(err);
      doneLoading(200);
    }
  }

  async function findAndSetExistingServiceOfferings(formContext:any, serviceProviderId:string) {
    const serviceOfferingsRes = await findServiceOfferingsByProvider(serviceProviderId);
    if (serviceOfferingsRes.data) {
      update({
        ...get(),
        sourceServiceOfferings: serviceOfferingsRes.data,
        serviceOfferingOptions: convertServiceOfferingsForDropdown(serviceOfferingsRes.data),
      });
    } else {
      update({
        ...get(),
        sourceServiceOfferings: [],
        serviceOfferingOptions: [],
      });
    }
  }

  async function findAndSetTargetExistingServiceOfferings(formContext:any, serviceProviderId:string) {
    const { sourceServiceOfferings } = get();
    const serviceOfferingId = formContext.getValues('serviceOfferingId') as IDropdownOption;
    const serviceType = sourceServiceOfferings.find((s) => s.id === serviceOfferingId.optionValue)?.serviceType;
    const serviceOfferingsRes = await findServiceOfferingsByProvider(serviceProviderId);

    if (serviceOfferingsRes.data) {
      update({
        ...get(),
        targetServiceOfferingOptions: convertServiceOfferingsForDropdown(serviceOfferingsRes.data.filter( x=> x.serviceType === serviceType)),
      });
    } else {
      update({
        ...get(),
        targetServiceOfferingOptions: [],
      });
    }
  }

  async function findAndSetNSOIs(serviceProviderId:string, serviceOfferingId:string, zipCodeTerritory:string) {

    let hoursOffset = new Date().getTimezoneOffset() / 60;
    const data:IRebookServicesNSOIFilter = {
      serviceProviderId,
      serviceOfferingId,
      zipCodeTerritory,
      hoursOffset: hoursOffset,
    };
    const nsoiRes = await findHappeningSoonByServiceOfferingAndTerritory(serviceProviderId, serviceOfferingId, data);
    if (nsoiRes.data) {
      update({
        ...get(),
        nsoiOptions: convertNSOIsForDropdown(nsoiRes.data),
      });
    } else {
      update({
        ...get(),
        nsoiOptions: [],
      });
    }

  }

  function convertServiceOfferingsForDropdown(data:IServiceOffering[]): IDropdownOption[] {
    const propertyMapper:IDropdownPropertyMapper<IServiceOffering> = {
      getKey: x => x.id,
      getOptionText: x => x.name,
      getOptionValue: x => x.id,
      getAncillary: x => x.serviceType,
    };
    return convertToDropdown(data, x => x.id !== null, propertyMapper);
  }

  function convertNSOIsForDropdown(data:INeighborhoodServiceOfferingInstance[]):IDropdownOption[] {
    const propertyMapper:IDropdownPropertyMapper<INeighborhoodServiceOfferingInstance> = {
      getKey: x => x.id ?? UNSELECTED_OPTION,
      getOptionText: x => formatMonthDayYear(x.localServiceDate),
      getOptionValue: x => x.id ?? UNSELECTED_OPTION,
    };
    return convertToDropdown(data, x => x.id !== null, propertyMapper);
  }

  function convertTerritoriesForDropdown(territories: ITerritory[]): IDropdownOption[] {
    let options: IDropdownOption[] = [];
    territories.forEach((value, key, map) => {
      options.push({
        key: value.id ?? UNSELECTED_OPTION,
        optionText: value.zipCodeTerritory ?? UNSELECTED_OPTION,
        optionValue: value.zipCodeTerritory ?? UNSELECTED_OPTION,
        ancillary: {
          neighborhoods: value,
        },
      });
    });
    return [createUninitializedDropdownOption(), ...options.sort(sortByString('optionText', false))];
  }


  function hasValidValuesForFindingNSOIs(
    serviceProviderId:IDropdownOption | null,
    serviceOfferingId:IDropdownOption | null,
    territoryId:IDropdownOption | null,
  ):boolean {
    return serviceProviderId?.optionValue !== UNSELECTED_OPTION &&
      serviceOfferingId?.optionValue !== UNSELECTED_OPTION &&
      territoryId?.optionValue !== UNSELECTED_OPTION;
  }
  return {
    ...get(),
    loadingKey,
    init,
    save,
    findAndSetExistingServiceOfferings,
    findAndSetTargetExistingServiceOfferings,
    findAndSetNSOIs,
    hasValidValuesForFindingNSOIs,
  };
}