
import Axios, { AxiosResponse } from 'axios';
import { IAddNeighborhoodToBatchDTO } from 'model/letter_batch/addNeighborhoodsToBatch';
import { ISearchLetterBatchRequest, ILetterBatchPage, ISearchLetterBatchNeighborhoodRequest, ILetterBatchFormDto, ILetterBatchValidationResult, ILetterBatchNeighborhoodValidationResult } from 'model/letter_batch/letterBatch';
import { ILetterBatchNeighborhoodFormDto, IQRGenerationRequest } from 'model/letter_batch/letterBatchNeighborhood';

const apiUrl = window.REACT_APP_BASE_API_URI;

export function findLetterBatches(dto: ISearchLetterBatchRequest):Promise<AxiosResponse<ILetterBatchPage>> {
  return Axios.post(`${apiUrl}/letterBatches/v2/find`, dto);
}

export function findLetterBatchNeighborhoods(letterBatchId:string, dto:ISearchLetterBatchNeighborhoodRequest):Promise<AxiosResponse<ILetterBatchNeighborhoodFormDto[]>> {
  return Axios.post(`${apiUrl}/letterBatches/v2/${letterBatchId}/findLetterBatchNeighborhoods`, dto);
}

export function findLetterBatchFormById(letterBatchId:string):Promise<AxiosResponse<ILetterBatchFormDto>> {
  return Axios.get(`${apiUrl}/letterBatches/v2/${letterBatchId}/findLetterBatchFormById`);
}

export function findLetterBatchNeighborhoodFormById(letterBatchId:string, letterBatchNeighborhoodId: string):Promise<AxiosResponse<ILetterBatchNeighborhoodFormDto>> {
  return Axios.get(`${apiUrl}/letterBatches/v2/${letterBatchId}/findLetterBatchNeighborhoodFormById/${letterBatchNeighborhoodId}`);
}

export function saveLetterBatch(id:string | null, data: ILetterBatchFormDto):Promise<AxiosResponse<ILetterBatchValidationResult>> {
  if (id) {
    return Axios.put(`${apiUrl}/letterBatches/v2/${id}/updateLetterBatch`, data);
  } else {
    return Axios.post(`${apiUrl}/letterBatches/v2/createLetterBatch`, data);
  }
}

export function deleteLetterBatch(id:string):Promise<AxiosResponse<void>> {
  return Axios.delete(`${apiUrl}/letterBatches/v2/${id}/deleteLetterBatch`);
}

export function saveLetterBatchNeighborhood(letterBatchId:string, letterBatchNeighborhoodId:string | null, formData:FormData):Promise<AxiosResponse<ILetterBatchNeighborhoodValidationResult>> {
  if (letterBatchNeighborhoodId) {
    return Axios.put(`${apiUrl}/letterBatches/v2/${letterBatchId}/letterBatchNeighborhoods/${letterBatchNeighborhoodId}/updateLetterBatchNeighborhood`,
      formData,
      {
        headers: {
          'Content-Type': 'undefined',
        },
      },
    );
  } else {
    return Axios.post(
      `${apiUrl}/letterBatches/v2/${letterBatchId}/letterBatchNeighborhoods/createLetterBatchNeighborhood`,
      formData,
      {
        headers: {
          'Content-Type': 'undefined',
        },
      });
  }
}

export function deleteLetterBatchNeighborhood(letterBatchId:string, letterBatchNeighborhoodId:string):Promise<AxiosResponse<void>> {
  return Axios.delete(`${apiUrl}/letterBatches/v2/${letterBatchId}/letterBatchNeighborhoods/${letterBatchNeighborhoodId}/deleteLetterBatchNeighborhood`);
}

export function generateQRCode(data:IQRGenerationRequest) {
  return Axios.post(
    `${apiUrl}/letterBatches/v2/generateQRCode`,
    data,
    {
      responseType: 'blob',
    },
  );
}

export function getLetterTemplate(letterTemplateGuid:string): Promise<AxiosResponse<Blob>> {
  return Axios.get(`${apiUrl}/letterBatches/v2/getLetterTemplate/${letterTemplateGuid}`, { responseType: 'blob' });
}

export function exportLetterBatch(letterBatchId:string):Promise<AxiosResponse<void>> {
  return Axios.post(`${apiUrl}/letterBatches/v2/${letterBatchId}/exportLetterBatch`);
}

export function quickAddNeighborhoods(letterBatchId:string, dto:IAddNeighborhoodToBatchDTO ):Promise<AxiosResponse<void>> {
  return Axios.post(`${apiUrl}/letterBatches/v2/${letterBatchId}/quickAddNeighborhoods`);
}