
import { Button, Grid } from '@mui/material';
import useBatchEmailJobTesting from './useBatchEmailJobTesting';

export default function BatchEmailJobTesting() {

  const {

    onEmitTestPastCustomerRequestToReviewEvent,
    onManualReceivePastCustomerRequestToReview,
  } = useBatchEmailJobTesting();

  return (
    <>

      <Grid container spacing={1} >
        <Grid container item xs={12}>
          <Grid item xs='auto' sx={{ paddingRight: '8px' }}>
            <Button
              variant='outlined'
              onClick={onEmitTestPastCustomerRequestToReviewEvent}
            >
              <span style={{ fontSize: '12px' }}>Emit test past customer request to review event</span>
            </Button>
          </Grid>
          <Grid item xs='auto' sx={{ paddingRight: '8px' }}>
            <Button
              variant='outlined'
              onClick={onManualReceivePastCustomerRequestToReview}
            >
              <span style={{ fontSize: '12px' }}>Receive test past customer request to review event</span>
            </Button>
          </Grid>
        </Grid>

      </Grid>
    </>
  );
}