import { Button, Grid } from '@mui/material';
import Userfront from '@userfront/react';
import NavigationButton from 'components/Navigation/NavigationButton';
import NavigationDropdownMenu from 'components/Navigation/NavigationDropdownMenu';
import StreetFairLogo from 'images/streetfair-logo-white.png';
import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import MarketAutocomplete from './MarketAutocomplete';
import useSelf from 'hooks/useSelf';
import { ROLE_ADMIN_CONSUMER_GROWTH_OPS, ROLE_ADMIN_DEFAULT, ROLE_ADMIN_SUPPLY_MANAGER, ROLE_ADMIN_USER } from 'model/roles';
import { IRoleProps, createAdminMenu, createDemandMenu, createLocationsMenu, createSeedingMenu, createSupplyMenu, createSupplyToolsMenu } from './roleBasedNavFactory';

function LogoHeader () {
  const navigate = useNavigate();
  const { roles } = useSelf();

  const hasDefaultAdminRole = roles.indexOf(ROLE_ADMIN_DEFAULT) > -1;

  const hasUserAdminRole = roles.indexOf(ROLE_ADMIN_USER) > -1;
  const hasSupplyManagerRole = roles.indexOf(ROLE_ADMIN_SUPPLY_MANAGER) > -1;
  const hasConsumerGrowthOpsRole = roles.indexOf(ROLE_ADMIN_CONSUMER_GROWTH_OPS) > -1;

  const roleProps:IRoleProps = {
    hasDefaultAdminRole,
    hasUserAdminRole,
    hasSupplyManagerRole,
    hasConsumerGrowthOpsRole,
  };
  const handleButtonClick = (event: any) => {
    console.log(event.target.dataset.route);
    navigate(event.target.dataset.route);
  };

  useEffect(() => {
    if (Userfront.accessToken()) {

    } else {
      navigate('/sign-in');
    }
  }, []);

  const LogoutButton = Userfront.build({
    toolId: window.REACT_APP_ADMIN_USERFRONT_LOGOUT,
  });

  const logOutButtonClick = (event: any) => {
    event.preventDefault();
    Userfront.logout();
  };


  return (
    <Grid container style={{
      background: '#299B60 no-repeat left',
      paddingLeft: '6px',
    }}>
      <Grid container item sm={12} justifyContent={'space-between'} >
        <Grid item xs>
          <img alt="logo" style={{ marginTop: '5px' }} src={StreetFairLogo} height="50px" />
        </Grid>

        <Grid container item xs='auto'
          justifyContent={'center'}
          alignContent={'center'}
          sx={{
            '&': {
              background: 'white',
              minWidth: '300px',
              margin: '4px',
              padding: '6px',
              borderRadius: '4px',
            },
          }}>
          <MarketAutocomplete/>
        </Grid>
        <Grid container item xs justifyContent={'flex-end'}>
          <Button id="logout-button"
            onClick={logOutButtonClick}
            style={{
              backgroundColor: 'transparent',
              color: 'white',
              border: 'none',
              marginTop: '10px',
              marginRight: '10px',
            }}
          >
            Log out</Button>
        </Grid>
      </Grid>
      <Grid container item sm={12} spacing={1}>
        <Grid item xs='auto'>
          <NavigationButton text = {'Home'} route={'/'} />
        </Grid>
        {(hasDefaultAdminRole || hasConsumerGrowthOpsRole) ? (
          <Grid item xs='auto'>
            <NavigationButton text = {'Neighborhoods'} route={'/neighborhoods'} />
          </Grid>
        ) : null}
        {(hasDefaultAdminRole || hasConsumerGrowthOpsRole) ? (
          <Grid item xs='auto'>
            <NavigationDropdownMenu
              buttonText={'Locations'}
              menuItemsContent={createLocationsMenu(roleProps)} />
          </Grid>
        ) : null}
        {(hasDefaultAdminRole || hasSupplyManagerRole) ? (
          <Grid item xs='auto'>
            <NavigationDropdownMenu buttonText={'Supply'}
              menuItemsContent={createSupplyMenu(roleProps)} />
          </Grid>
        ) : null}
        {(hasDefaultAdminRole || hasSupplyManagerRole || hasConsumerGrowthOpsRole) ? (
          <Grid item xs='auto'>
            <NavigationDropdownMenu buttonText={'Supply Tools'} menuItemsContent={createSupplyToolsMenu(roleProps)} />
          </Grid>
        ) : null}
        {(hasDefaultAdminRole || hasConsumerGrowthOpsRole) ? (
          <Grid item xs='auto'>
            <NavigationDropdownMenu buttonText={'Seeding'} menuItemsContent={createSeedingMenu(roleProps)} />
          </Grid>
        ) : null}
        {(hasDefaultAdminRole || hasConsumerGrowthOpsRole) ? (
          <Grid item xs='auto'>
            <NavigationDropdownMenu buttonText={'Demand'} menuItemsContent={createDemandMenu(roleProps)} />
          </Grid>
        ) : null}
        {(hasDefaultAdminRole || hasUserAdminRole) ? (
          <Grid item xs='auto'>
            <NavigationDropdownMenu buttonText={'Admin'} menuItemsContent={createAdminMenu(roleProps)} />
          </Grid>
        ) : null}
      </Grid>
    </Grid>
  );
}

export default LogoHeader;
