import { HookTextField } from 'components/reactHookForm';

import { useFormContext, useWatch } from 'react-hook-form';
import { createValidateNumber } from 'util/rhfValidateFunctions';
import useNeighborhoodServiceOfferingDetail from '../hookStore/useNeighborhoodServiceOfferingDetail';
import { DiscountScheduleTypes } from 'model/discountSchedule';

const validateNumber = createValidateNumber('Can only have numbers');

export default function NeighborhoodPricing() {
  const formCtx = useFormContext();
  const { getDiscountSchedule } = useNeighborhoodServiceOfferingDetail();
  const watchedDiscountScheduleId = useWatch({ control: formCtx.control, name: 'discountScheduleId' });

  var discountScheduleAggregate = getDiscountSchedule(watchedDiscountScheduleId);

  return (
    <HookTextField
      name='price'
      label='Price'
      validationRules={{
        validate: function (value) {
          if (value === null || value === '' || value === undefined) {
            return true;
          }
          if (discountScheduleAggregate) {
            const { priceAmount, discountSchedule, pricingSchedule, flatRateSchedule, type } = discountScheduleAggregate;
            if (discountSchedule && type === DiscountScheduleTypes.DollarSavePerCustomer) {
              if (value - discountSchedule.maxDiscount <= 0) {
                return 'This price with the max discount would make the total price less than or = 0';
              }
            } else if (pricingSchedule && type === DiscountScheduleTypes.DollarPerCustomer) {
              if (value < pricingSchedule.priceFloor) {
                return 'This price is less than the price floor of the discount schedule and will break discount logic.';
              }
            } else if (flatRateSchedule && type === DiscountScheduleTypes.DollarFlatRate) {
              if (value - flatRateSchedule.dollarsOff <= 0) {
                return 'This price with the flat discount would make the total price less than or = 0.';
              }
            }
          }
          return validateNumber(value);
        },
      }}
    />
  );
}