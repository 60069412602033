import { Box } from '@mui/material';
import Spacer from 'components/Layout/Spacer';
import { PrimaryButton, SecondaryButton } from 'components/buttons';
import { ServiceProviderFormContext } from 'contexts/serviceProvider/useServiceProviderForm';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

export default function FormButtons() {
  const navigate = useNavigate();
  const { isImageUploading } = useContext(ServiceProviderFormContext);
  return (
    <>
      <PrimaryButton
        type='submit'
        disabled={isImageUploading}
      >
        Save
      </PrimaryButton>
      <Box sx={{ width: '10px', display: 'inline-block' }}/>
      <SecondaryButton
        onClick={() => navigate('/service-providers')}
      >
        Cancel
      </SecondaryButton>
    </>
  );
}