import useToast from 'components/toast/useToast';
import createStore from 'hooks/hookStore';
import { useLoading } from 'components/Layout/Loading';
import { useEffect, useState } from 'react';
import { IDropdownOption } from 'model/dropdown';
import { prepTerritory } from 'api/neighborhoodLaunchApi';

const loadingKey = 'TerritoryPrep';
type TerritoryPrep = {
  selectedTerritory: IDropdownOption | null;
}

const { get, update, registerListener, unregisterListener } = createStore<TerritoryPrep>('TerritoryPrep', {
  selectedTerritory: null,
});

export default function useTerritoryPrep() {
  const setState = useState(get())[1];
  const { onLoading, doneLoading } = useLoading(loadingKey);
  const { createErrorToast, createSuccessToast } = useToast();

  useEffect(() => {
    registerListener(setState);
    return () => {
      unregisterListener(setState);
    };
  }, []);

  function onTerritorySelect(territorySelection: IDropdownOption | null) {
    try {
      update({
        ...get(),
        selectedTerritory: territorySelection,
      });
    } catch (e) {
      console.error(e);
    }
  }

  async function onConfirmTerritoryPrep() {
    onLoading();
    try {
      const territory = get().selectedTerritory;
      if (!territory || !territory.optionValue) {
        createErrorToast('Please select a territory');
        doneLoading(300);
        return;
      }

      await prepTerritory(territory.optionValue);
      createSuccessToast('Successfully initiated territory prep process!');
    } catch (e : any) {
      console.error(e);
      createErrorToast(`Error prepping territory: ${e.response?.data?.message}`);
    }
    doneLoading(300);
  }

  return {
    ...get(),
    onConfirmTerritoryPrep,
    onTerritorySelect,
    loadingKey,
  };
}