import { Box, Button, Grid } from '@mui/material';
import Loading from 'components/Layout/Loading';
import useLetterBatchDetail from './hookStore/useLetterBatchDetail';
import LetterBatchDetailForm from './LetterBatchDetailForm';
import CrudTable from 'components/dataGrid/CrudTable';
import { columns } from './tableColumnConfig';
import { PrimaryButton } from 'components/buttons';
import { useParams } from 'react-router-dom';
import Spacer from 'components/Layout/Spacer';
import AddNeighborhoodToBatchAutocomplete from './addNeighborhoodToBatch/AddNeighborhoodToBatchAutocomplete';
import MarkForInheritance from './addNeighborhoodToBatch/MarkForInheritance';
import SelectedLetterBatchNeighborhood from './addNeighborhoodToBatch/SelectedLetterBatchNeighborhood';
import SubmitQuickAdd from './addNeighborhoodToBatch/SubmitQuickAdd';

export interface ILetterBatchDetailProps {
  isNew:boolean;
}

const quickSearchStorageKey = 'letter_batch_neighborhood_table';
const deleteModalStoreKey = 'deleteLetterBatchNeighborhoodModal';

export default function LetterBatchDetail({
  isNew,
}:ILetterBatchDetailProps) {
  const { letterBatchId } = useParams();
  const { loadingKey, letterBatchNeighborhoods, onAddNewLetterBatchNeighborhood, onDeleteLetterBatchNeighborhood } = useLetterBatchDetail();

  return (
    <Loading loadingKey={loadingKey} size={100}>
      <Grid container>
        <Grid item xs={12}>
          <LetterBatchDetailForm isNew={isNew}/>
        </Grid>
        <Grid container item xs={12} className='pageGridContainer'>
          <Grid container item xs={12} sx={{
            'marginBottom': '10px',
            '&': {
              maxWidth: '1300px',
            },
          }}
          >
            <Grid item xs='auto'>
              <PrimaryButton
                variant="contained"
                disabled={isNew}
                onClick={onAddNewLetterBatchNeighborhood}>
                <>Add Neighborhood Campaign</>
              </PrimaryButton>
            </Grid>
            <Grid item xs></Grid>

            {/*
            ****TODO: delete this after jan 1 2025 if not readded****

            <Grid container item xs sx={{ minWidth: '600px' }}>
              <Grid container item xs justifyContent={'flex-end'}>
                <Grid item xs sx={{ maxWidth: '400px' }}>
                  <AddNeighborhoodToBatchAutocomplete/>
                </Grid>
                <Grid item xs='auto'>
                  <SubmitQuickAdd/>
                </Grid>
              </Grid>
              <Grid container item xs={12} justifyContent={'flex-start'}>
                <SelectedLetterBatchNeighborhood/>
              </Grid>
            </Grid> */}
          </Grid>
          <Grid item xs={12}>
            <CrudTable
              quickSearchStorageKey={quickSearchStorageKey}
              allDataRows={letterBatchNeighborhoods}
              columns={columns}
              defaultSortModel={[{ field: 'neighborhoodName', sort: 'asc' }]}
              enableEdit
              enableDelete
              // customActions={[
              //   (letterBatchNeighborhoodId, letterBatchNeighborhood) => (
              //     <MarkForInheritance
              //       key={letterBatchNeighborhoodId}
              //       letterBatchNeighborhoodId={letterBatchNeighborhoodId}
              //       letterBatchNeighborhood={letterBatchNeighborhood}
              //     />
              //   ),
              // ]}
              deleteModalStoreKey={deleteModalStoreKey}
              createDetailRoute={(id) => `/letterBatches/${letterBatchId}/letterBatchNeighborhoods/${id}`}
              getId={(row) => row.id}
              getDesc={(row) => row.batchName}
              onDelete={onDeleteLetterBatchNeighborhood}
            />
          </Grid>
        </Grid>
      </Grid>

    </Loading>
  );
}