import CrudTable from 'components/dataGrid/CrudTable';
import useLetterBatchTable from './hookStore/useLetterBatchTable';
import { columns } from './tableColumnConfig';
import { sortByString } from 'util/sortFunctions';
import { Button, Grid } from '@mui/material';
import PageHeader from 'components/SectionHeaders/PageHeader';
import { useCallback, useEffect } from 'react';
import { PrimaryButton } from 'components/buttons';
import { ISearchLetterBatchRequest } from 'model/letter_batch/letterBatch';

const sortByName = sortByString('name');
const quickSearchStorageKey = 'letter_batch_table';
const deleteModalStoreKey = 'deleteLetterBatchModal';

export default function LetterBatchTable() {
  const { letterBatches, init, refresh, onAddNewBatch, onDeleteLetterBatch } = useLetterBatchTable();
  useEffect(() => {
    void init();
  }, []);

  return (
    <Grid container spacing={2} className='pageGridContainer'>
      <Grid item xs={12}>
        <PageHeader headerText={'Letter Batches'} />
      </Grid>

      <Grid item xs={12}>
        <PrimaryButton variant="contained" onClick={onAddNewBatch}>Add Batch</PrimaryButton>
      </Grid>
      <Grid item xs={12}>
        <CrudTable
          quickSearchStorageKey={quickSearchStorageKey}
          allDataRows={letterBatches}
          columns={columns}
          defaultSortModel={[{ field: 'sendDate', sort: 'desc' }]}
          enableEdit
          enableDelete
          deleteModalStoreKey={deleteModalStoreKey}
          createDetailRoute={(id) => `/letterBatches/${id}`}
          getId={(row) => row.id}
          getDesc={(row) => row.batchName}
          onDelete={onDeleteLetterBatch}
          onPaginationChange={async (paginationModel, setRowCountState, onComplete) => {
            var searchRequest: ISearchLetterBatchRequest = {
              marketId: null,
              batchName: '',
              externalBatchId: '',
              batchStatus: null,
              pageNumber: paginationModel.page + 1,
              pageSize: paginationModel.pageSize,
            };
            const result = await refresh(searchRequest);
            setRowCountState(result.availableCount);

            onComplete();
          }}
          pagination={true}
        />
      </Grid>
    </Grid>

  );
}