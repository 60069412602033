import { FormControl, InputLabel, FormHelperText } from '@mui/material';
import { HookSelect } from 'components/reactHookForm';
import useErrorState from 'hooks/reactHookForm/useErrorState';
import { useFormContext, useWatch } from 'react-hook-form';
import useNeighborhoodDetail from '../hookStore/useNeighborhoodDetail';


export default function TerritorySelect() {
  const { territories } = useNeighborhoodDetail();
  const formCtx = useFormContext();
  const zipCodeTerritory = useWatch({ control: formCtx.control, name: 'zipCodeTerritory' });
  const marketId = useWatch({ control: formCtx.control, name: 'marketId' });
  const { isError, getErrorMessage } = useErrorState(formCtx);
  return (
    <FormControl
      variant='standard'
      fullWidth
      error={isError('zipCodeTerritory')}
      sx={{ background: zipCodeTerritory === 'UNASSIGNED' ? '#fbfbac' : '' }}
    >
      <InputLabel htmlFor="territory-select">Territory</InputLabel>
      <HookSelect
        control={formCtx.control}
        name='zipCodeTerritory'
        id='territory-select'
        menuItems={territories}
        menuItemKeys={{
          key: 'id',
          value: 'optionValue',
          displayValue: 'optionText',
        }}
      />
      <FormHelperText>{getErrorMessage('zipCodeTerritory')}</FormHelperText>
    </FormControl>
  );
}