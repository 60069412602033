import Loading from 'components/Layout/Loading';
import { useEffect, useState } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import useTableSearch from 'components/grid/useTableSearch';
import QuickSearchToolbar from 'components/grid/QuickSearchToolbar';
import { Grid, IconButton } from '@mui/material';
import useTsoiManagementBatch from '../hookStore/useTsoiManagementBatch';
import { Edit } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';

const columns: GridColDef[] = [
  {
    field: 'territoryName',
    headerName: 'Territory',
    width: 250,
    renderCell: (params: any) => {
      return <div>{params.value}</div>;
    },
  },
  {
    field: 'serviceProviderName',
    headerName: 'Service Provider',
    width: 250,
    renderCell: (params: any) => {
      return <div>{params.value}</div>;
    },
  },
  {
    field: 'serviceType',
    headerName: 'Service Type',
    width: 250,
    renderCell: (params: any) => {
      return <div>{params.value}</div>;
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 100,
    renderCell: (params: any) => {
      return <div>{params.value}</div>;
    },
  },
  {
    field: 'actions',
    headerName: 'Actions',
    width: 100,
    renderCell: (params:any) => {
      const navigate = useNavigate();
      const { batchId } = useParams();

      return (
        <Grid container justifyContent='center' alignItems='center'>
          <Grid>
            <IconButton onClick={() => navigate(`/tsoiManagementBatches/${batchId}/territory/${params.row.territoryId}/serviceTypes`)}>
              <Edit />
            </IconButton>
          </Grid>
        </Grid>
      );
    },
  },
];

const columnTotalWidth = columns.map(x => x.width).reduce((a:any, b:any) => a+b, 0);
const quickSearchStorageKey = 'batchSummaryTable';

function enhanceServiceOfferings(batch) {
  if (!batch.territories) {
    return [];
  }
  let result = [] as any[];

  // Iterate over each territory
  Object.keys(batch.territories).forEach(territoryKey => {
    const territory = batch.territories[territoryKey];

    // Iterate over each service offering in the territory
    territory.serviceOfferings.forEach(serviceOffering => {
      // Create a new object that combines the service offering
      // with the territory name and zip code territory
      const enhancedServiceOffering = {
        ...serviceOffering,
        territoryName: territory.territoryName,
        zipcodeTerritory: territory.zipCodeTerritory,
        territoryId: territory.territoryId,
        id: `${territory.territoryId}|${serviceOffering.serviceProviderId}|${serviceOffering.serviceOfferingId}`,
      };

      // Add the enhanced service offering to the result array
      result.push(enhancedServiceOffering);
    });
  });

  return result;
}

export default function BatchSummaryDataTable() {
  const { batch, loadingKey } = useTsoiManagementBatch();
  const { rows, searchText, setRowsWithStoredFilter, requestSearch } = useTableSearch(quickSearchStorageKey);
  const [sortModel, setSortModel] = useState([{
    field: 'territoryName',
    sort: 'desc' as any,
  }]);

  useEffect(() => {
    const tableRows = enhanceServiceOfferings(batch);
    setRowsWithStoredFilter(tableRows);
  }, [batch]);

  return (
    <Loading loadingKey={loadingKey}>
      <Grid container>
        <DataGrid
          rows={rows}
          components={{ Toolbar: QuickSearchToolbar }}
          componentsProps={{
            toolbar: {
              quickSearchStorageKey,
              value: searchText,
              onChange: (event: React.ChangeEvent<HTMLInputElement>) => requestSearch(rows, event.target.value),
              clearSearch: () => requestSearch(rows, ''),
            },
          }}
          columns={columns}
          autoHeight={true}
          sortModel={sortModel}
          onSortModelChange={setSortModel}
          style={{
            minWidth: '500px',
            maxWidth: `${columnTotalWidth}px`,
          }}
        />
      </Grid>
    </Loading>
  );
}
