import { FormControl, InputLabel, FormHelperText } from '@mui/material';
import { HookSelect } from 'components/reactHookForm';
import HookMultiselect from 'components/reactHookForm/HookMultiselect';
import useErrorState from 'hooks/reactHookForm/useErrorState';
import { RequestManagementTypeOptions, RequestManagementTypes } from 'model/neighborhoodLaunch';
import { useFormContext } from 'react-hook-form';
import useRequestManagement from '../hookStore/useRequestManagement';


export default function RequestManagementTypeSelect() {
  const formCtx = useFormContext();

  const { requestManagementTypeOptions } = useRequestManagement();
  const { isError, getErrorMessage } = useErrorState(formCtx);
  const requestAction = formCtx.getValues().requestAction;
  return (
    <FormControl variant='standard' fullWidth error={isError('requestAction')}>
      <InputLabel htmlFor="requestAction">Request Action</InputLabel>
      <HookSelect
        rules={{
          required: { value: true, message: 'Required' },
        }}
        control={formCtx.control}
        name='requestAction'
        id='requestAction'
        menuItems={requestManagementTypeOptions}
        menuItemKeys={{
          key: 'id',
          value: 'optionValue',
          displayValue: 'optionText',
        }}
      />
      <AdditionalHelperText requestAction={requestAction}/>
      <FormHelperText>{getErrorMessage('requestAction')}</FormHelperText>
    </FormControl>
  );
}


function AdditionalHelperText({ requestAction }:any) {
  if (requestAction === RequestManagementTypes.DELETE_PROVIDER_LAUNCH) {
    return <FormHelperText>This will hard delete nsos</FormHelperText>;
  }
  if (requestAction === RequestManagementTypes.DELETE_CLONED_NSOS) {
    return <FormHelperText>This will hard delete nsos</FormHelperText>;
  }
  return null;
}