import { Typography } from '@mui/material';
import { useFormContext, useWatch } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import useNeighborhoodServiceOfferingDetail from '../hookStore/useNeighborhoodServiceOfferingDetail';

export function GeneratedNames() {
  const { neighborhoodServiceOfferingId } = useParams();
  const formCtx = useFormContext();
  const { getTemplatedName } = useNeighborhoodServiceOfferingDetail();

  if (neighborhoodServiceOfferingId) {
    const watchedNeighborhoodId = useWatch({ name: 'neighborhoodId' });
    return (
      <Typography variant='body1'>{getTemplatedName(watchedNeighborhoodId, formCtx.getValues().serviceOfferingId)}</Typography>
    );
  } else {
    const watchedNeighborhoodIds = useWatch({ name: 'neighborhoodIds' });
    return (
      <>
        {watchedNeighborhoodIds && watchedNeighborhoodIds.map(x => {
          return (
            <Typography key={x} variant='body1'>{getTemplatedName(x, formCtx.getValues().serviceOfferingId)}</Typography>
          );
        })}
      </>
    );
  }
}
