import { setFormData } from 'util/formHelpers';
import {
  IWorkflowStep,
  ICustomFieldOption,
  ISubscriptionOption,
  IServiceDetailSection,
  ICustomField,
  IFormFriendlyServiceOffering,
  IFormFriendlyServiceDetailSection,
  IFieldSubscriptionOption,
} from 'model/serviceOffering';
import { useState } from 'react';

//need to pass something to make hook execute
export default function useServiceOfferingForm(val:null) {
  const [tabValue, setTabValue] = useState(0);
  function createEmptyCustomFieldOption():ICustomFieldOption {
    return {
      optionText: '',
      optionValue: '',
    };
  }
  function createEmptyCustomField():ICustomField {
    return {
      customFieldOptions: [createEmptyCustomFieldOption()],
      fieldLabel: '',
      fieldName: '',
      fieldPlaceholderText: '',
      fieldTypeCode: '',
      required: false,
      customFieldGroupType: '',
    };
  }
  function createEmptySubscriptionOption():IFieldSubscriptionOption {
    return {
      optionName: '',
      optionValue: '',
      isDefaultOption: false,
      isOneTime: false,
      isNew: true,
    };
  }
  function createEmptyServiceDetailSection():IFormFriendlyServiceDetailSection {
    return {
      sectionHeaderName: '',
      sectionIcon: '',
      sectionItems: [{ val: '' }],
      sectionListItems: [{ val: '' }],
    };
  }
  function convertSectionDetailSectionToFormFriendly(serviceDetailSection:IServiceDetailSection):IFormFriendlyServiceDetailSection {
    return {
      sectionHeaderName: serviceDetailSection.sectionHeaderName,
      sectionIcon: serviceDetailSection.sectionIcon,
      sectionItems: serviceDetailSection.sectionItems ? serviceDetailSection.sectionItems.map(x => ({ val: x })) : [],
      sectionListItems: serviceDetailSection.sectionListItems ? serviceDetailSection.sectionListItems.map(x => ({ val: x })) : [],
    };
  }
  function convertSectionDetailSectionOriginal(serviceDetailSection:IFormFriendlyServiceDetailSection):IServiceDetailSection {
    return {
      sectionHeaderName: serviceDetailSection.sectionHeaderName,
      sectionIcon: serviceDetailSection.sectionIcon,
      sectionItems: serviceDetailSection.sectionItems ? serviceDetailSection.sectionItems.map(x => (x.val)) : [],
      sectionListItems: serviceDetailSection.sectionListItems ? serviceDetailSection.sectionListItems.map(x => (x.val)) : [],
    };
  }


  return {
    tabValue,
    setTabValue,
    setFormData,
    createEmptyCustomField,
    createEmptyCustomFieldOption,
    createEmptySubscriptionOption,
    createEmptyServiceDetailSection,
    convertSectionDetailSectionToFormFriendly,
    convertSectionDetailSectionOriginal,
  };
}