import { useLoading } from 'components/Layout/Loading';
import useToast from 'components/toast/useToast';
import createStore from 'hooks/hookStore';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { findBestMatchNeighborhoodByAddress, findNeighborhoodByCoordinates } from 'api/googleApi';
import { INeighborhood } from 'model/neighborhood';
import { INeighborhoodFromAddressResponse } from 'model/ancillary';

const loadingKey = 'HomeStore';
type HomeStore = {
  latitude:number;
  longitude:number;
  address:string;
  city:string;
  state:string;
  zipCode:string;
  neighborhood:INeighborhood | null;
  neighborhoodFromAddressResponse:INeighborhoodFromAddressResponse | null;
}

const { get, update, registerListener, unregisterListener } = createStore<HomeStore>('HomeStore', {
  latitude: 35.4290849980676,
  longitude: -80.81921217348902,
  address: '',
  city: '',
  state: '',
  zipCode: '',
  neighborhood: null,
  neighborhoodFromAddressResponse: null,
});

export default function useHome() {
  const setState = useState(get())[1];
  const navigate = useNavigate();
  const { onLoading, doneLoading } = useLoading(loadingKey);
  const { createErrorToast, createSuccessToast } = useToast();

  useEffect(() => {
    registerListener(setState);
    return () => {
      unregisterListener(setState);
    };
  }, []);

  async function onFindNeighborhoodByCoordinates(latitude:number, longitude:number) {
    onLoading();
    const res = await findNeighborhoodByCoordinates({ latitude, longitude });
    update({
      ...get(),
      neighborhood: res.data,
    });
    doneLoading(300);
  }

  async function onFindBestMatchNeighborhoodByAddress({ address, city, state, zipCode }:any) {
    onLoading();
    const res = await findBestMatchNeighborhoodByAddress({ address, city, state, zipCode });
    update({
      ...get(),
      neighborhoodFromAddressResponse: res.data,
    });
    doneLoading(300);
  }

  function onLatitudeChange(val) {
    update({
      ...get(),
      latitude: val,
    });
  }

  function onLongitudeChange(val) {
    update({
      ...get(),
      longitude: val,
    });
  }

  function onNeighborhoodFromAddressChange(name:string, val:string) {
    update({
      ...get(),
      [name]: val,
    });
  }

  return {
    loadingKey,
    ...get(),
    onLatitudeChange,
    onLongitudeChange,
    onNeighborhoodFromAddressChange,
    onFindNeighborhoodByCoordinates,
    onFindBestMatchNeighborhoodByAddress,
  };
}