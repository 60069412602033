
import Axios from 'axios';
import { IDiscountSchedule } from 'model/discountSchedule';

export function findDiscountSchedulesByProvider(providerId:string) {
  return Axios.get(`${window.REACT_APP_BASE_API_URI}/discountSchedule/v2/public/findByProvider/${providerId}`);
}
export function findDiscountScheduleById(id:string) {
  return Axios.get(`${window.REACT_APP_BASE_API_URI}/discountSchedule/v2/public/find/${id}`);
}

export function findDiscountScheduleTypes() {
  return Axios.get(`${window.REACT_APP_BASE_API_URI}/discountSchedule/v2/public/getDiscountScheduleTypes`);
}


export function saveDiscountSchedule(values: IDiscountSchedule) {
  if (values.id) {
    return Axios.put(`${window.REACT_APP_BASE_API_URI}/discountSchedule/v2/update/${values.id}`, values);
  }
  return Axios.post(`${window.REACT_APP_BASE_API_URI}/discountSchedule/v2/create`, values);
}

export function deleteDiscountSchedule(id:string, sk:string) {
  return Axios.delete(`${window.REACT_APP_BASE_API_URI}/discountSchedule/v2/delete/${id}/sk/${sk}`);
}