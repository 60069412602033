import LibraryAddOutlinedIcon from '@mui/icons-material/LibraryAddOutlined';
import { IconButton, Tooltip } from '@mui/material';

interface ICloneButtonProps {
  tooltipTitle:string;
  onClick:any;
  className?:string;
  disabled?:boolean;
  iconButtonProps?:any;
  tooltipProps?:any;
}
export default function CloneButton({ tooltipTitle, onClick, className, disabled, tooltipProps, iconButtonProps }:ICloneButtonProps) {
  return (
    <Tooltip title={tooltipTitle} className={className} {...tooltipProps}>
      <IconButton onClick={onClick} disabled={disabled} {...iconButtonProps}>
        <LibraryAddOutlinedIcon/>
      </IconButton>
    </Tooltip>
  );
}