import PageHeader from 'components/SectionHeaders/PageHeader';
import ServiceProviderMultiAutocomplete from 'components/serviceProviderAutocomplete/ServiceProviderMultiAutocomplete';
import useTsoiManagementBatchServiceProviders from '../hookStore/useTsoiManagementBatchServiceProviders';
import { AutocompleteChangeReason, Grid } from '@mui/material';
import { IDropdownOption } from 'model/dropdown';
import { ITsoiManagementBatchTerritoryServiceType } from 'model/tsoiManagementBatch';
import { createTsoiServiceProviderFromServiceProviderAndServiceType } from '../util';
import { useEffect, useState } from 'react';
import { StatusType } from 'model/serviceProvider';

interface ServiceProviderServiceTypeSectionProps {
  serviceType: ITsoiManagementBatchTerritoryServiceType;
}

export default function ServiceProviderServiceTypeSection({ serviceType }: ServiceProviderServiceTypeSectionProps) {
  const { selectedServiceProviders, setSelectedServiceProviders } = useTsoiManagementBatchServiceProviders();

  const startingIds = selectedServiceProviders
    .filter((serviceProvider) => serviceProvider.serviceTypeId === serviceType.id)
    .map((serviceProvider) => serviceProvider.serviceProviderId);

  const onChange = (formCtx: any, selectedOptions: string[], reason: AutocompleteChangeReason, details: any, rawValues: IDropdownOption[]) => {
    if (reason === 'removeOption') {
      const selectedOption = details.ancillary;
      const arrayWithOptionRemoved = [...selectedServiceProviders]
        .filter((serviceProvider) => !(serviceProvider.serviceProviderId === selectedOption.id && serviceProvider.serviceTypeId === serviceType.id));

      setSelectedServiceProviders(arrayWithOptionRemoved);
    }
    if (reason === 'selectOption') {
      const serviceProviderWithServiceTypeInfo = createTsoiServiceProviderFromServiceProviderAndServiceType(rawValues[0].ancillary, serviceType);
      setSelectedServiceProviders([...selectedServiceProviders, serviceProviderWithServiceTypeInfo]);
    }
    if (reason === 'clear') {
      const arrayWithCurrentServiceTypeProvidersRemoved = [...selectedServiceProviders]
        .filter((serviceProvider) => serviceProvider.serviceTypeId !== serviceType.id);
      setSelectedServiceProviders(arrayWithCurrentServiceTypeProvidersRemoved);
    }
  };
  return (
    <Grid container item xs={5}>
      <PageHeader headerText={`${serviceType.serviceType}`} />
      <Grid item xs={8} paddingTop={'25px'}>
        <ServiceProviderMultiAutocomplete
          key={serviceType.id}
          label="Service Providers"
          formless={true}
          placeholder=""
          required
          postOnChange={onChange}
          controlledValues={startingIds}
          statuses={[StatusType.ACTIVE, StatusType.LAUNCHED]}/>
      </Grid>
    </Grid>
  );
}