import { HookTextField } from 'components/reactHookForm';

export default function TextInput({ name, label, required, maxLength, validationRules, postOnChange }:any) {
  return (
    <HookTextField
      name={name}
      label={label}
      required={required}
      maxLength={maxLength}
      postOnChange={postOnChange}
      validationRules={validationRules}
    />
  );
}