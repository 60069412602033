import useNeighborhoodSeedingToolingStore from './useNeighborhoodSeedingToolingStore';
import { AutocompleteChangeReason, FormControl, InputLabel } from '@mui/material';
import NeighborhoodAutocomplete from 'components/neighborhoodAutocomplete/NeighborhoodAutocomplete';
import { HookSelect } from 'components/reactHookForm';
import { IDropdownOption } from 'model/dropdown';
import { useFormContext } from 'react-hook-form';
import { getNeighborhoodZipCodeStatusString } from 'util/neighborhoodUtil';

export default function NeighborhoodSelect({ neighborhoods }) {
  const formCtx = useFormContext();
  const { onNeighborhoodSelect } = useNeighborhoodSeedingToolingStore();

  return (
    <NeighborhoodAutocomplete
      label={'Neighborhoods'}
      fieldName='neighborhoodId'

      formless={false}
      placeholder={''}
      required={false}
      postOnChange={async (_formCtx: any, selectedOption: IDropdownOption | null, reason: AutocompleteChangeReason, details: any) => {
        if (selectedOption?.optionValue) {
          await onNeighborhoodSelect(selectedOption?.optionValue);
        }

      }}
      optionDescriptionFunc={getNeighborhoodZipCodeStatusString}
    />
  );
}
