import { FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import PageHeader from 'components/SectionHeaders/PageHeader';
import useTsoiManagementBatch from '../hookStore/useTsoiManagementBatch';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { sortByString } from 'util/sortFunctions';
import { ITerritory, TerritoryStatus } from 'model/territories';
import { IDropdownOption } from 'model/dropdown';
import { get } from 'react-hook-form';
import { PrimaryButton } from 'components/buttons';

function convertTerritoriesToDropdownOptions(territories: ITerritory[]): IDropdownOption[] {
  const sortByFunc = sortByString('optionText');
  let options = territories
    .filter((t) => t.status === TerritoryStatus.ACTIVE)
    .map(m => {
      return {
        key: m.id,
        optionValue: m.zipCodeTerritory,
        optionText: `${m.name ?? ''} (${m.zipCodeTerritory})`,
        ancillary: m,
      } as IDropdownOption;
    }).sort(sortByFunc);
  return options;
}

export default function TsoiManagementBatchTerritorySelectPage() {
  const { init, availableTerritories } = useTsoiManagementBatch();
  const [selectedTerritory, setSelectedTerritory] = useState<string>('');
  const [isContinueDisabled, setIsContinueDisabled] = useState<boolean>(true);
  const { batchId } = useParams();
  const navigate = useNavigate();

  const territoryOptions = convertTerritoriesToDropdownOptions(availableTerritories);

  useEffect(() => {
    if (batchId) {
      void init(batchId);
    }
  }, [batchId]);

  function onTerritorySelect(value: any) {
    setSelectedTerritory(value);
    setIsContinueDisabled(false);
  }

  function onContinueButtonClick() {
    const fullSelectedTerritory = territoryOptions.find(x => x.optionValue === selectedTerritory);
    if (fullSelectedTerritory?.optionValue) {
      navigate(`/tsoiManagementBatches/${batchId}/territory/${fullSelectedTerritory.ancillary.id}/serviceTypes`);
    }
  }

  return (
    <Grid container>
      <Grid container flexDirection={'row'} alignItems={'center'}>
        <Grid item xs={3}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="territory-select-label">Territory</InputLabel>
            <Select
              onChange={async (e) => {
                await onTerritorySelect(e.target.value);
              }}
              label='Territory'
              placeholder='Select Territory'
              value={selectedTerritory}
              id={'territory-select'}
              labelId='territory-select-label'
              fullWidth>
              {territoryOptions && territoryOptions.map((x: any, i: number) => {
                return (
                  <MenuItem
                    key={get(x, 'id', i)}
                    value={get(x, 'optionValue', x.id)}>
                    <span>{get(x, 'optionText', x.name)}</span>
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={3} paddingLeft={'25px'}>
          <PrimaryButton onClick={onContinueButtonClick} disabled={isContinueDisabled}>Go to Territory</PrimaryButton>
        </Grid>
      </Grid>
    </Grid>
  );
};
