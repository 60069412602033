import { Grid } from '@mui/material';
import { PrimaryButton } from 'components/buttons';
import { HookTextField } from 'components/reactHookForm';
import { IShortLinkCreateRequest } from 'model/short_link/shortlink';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import useShortLink from './shortLinkHookStore';

export default function ShortLinkForm() {

  const {
    onCreate,
  } = useShortLink();
  const formContext = useForm<IShortLinkCreateRequest>({
    mode: 'onTouched',
    defaultValues: {
      url: '',
    },
  });

  const onSubmit : SubmitHandler<IShortLinkCreateRequest> = async data => {
    await onCreate(data);
  };

  return (
    <FormProvider {...formContext}>
      <form onSubmit={formContext.handleSubmit(onSubmit)}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <HookTextField
              name='url'
              label='URL to convert'
              required
            />
          </Grid>
          <Grid item xs={11} >
            <PrimaryButton type='submit' style={{ marginTop: '10px' }}>Submit</PrimaryButton>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
}