/* eslint-disable @typescript-eslint/no-shadow */
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import DeleteIcon from '@mui/icons-material/Delete';
import { Autocomplete, AutocompleteChangeReason, AutocompleteInputChangeReason, AutocompleteProps, Button, Checkbox, TextField } from '@mui/material';
import { IDropdownOption, UNSELECTED_OPTION } from 'model/dropdown';
import { Controller, useFormContext } from 'react-hook-form';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export interface IHookSingleAsyncAutocompleteProps {
  name: string;
  dropdownOptions:IDropdownOption[];
  label:string;
  placeholder:string;
  rules?:any;
  onInputChange: Function;
  postOnChange?:(formContext:any, value:IDropdownOption | null, reason:AutocompleteChangeReason, details:any) => void;
  postOnBlur?:Function;
  extraProps?:any;
}

export default function HookSingleAsyncAutocomplete({
  name,
  label,
  placeholder,
  rules,
  dropdownOptions,
  onInputChange,
  postOnChange,
  postOnBlur,
  extraProps,
}:IHookSingleAsyncAutocompleteProps) {
  const formContext = useFormContext();
  const { control } = formContext;
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { invalid, isTouched, isDirty, error },
        formState,
      }) => {
        return (
          <Autocomplete
            id={`${name}-singleautocomplete`}
            onChange={(e, value:any, reason, details) => {
              if (reason === 'selectOption') {
                onChange(value);
                if (postOnChange) {
                  postOnChange(formContext, value, reason, details);
                }
              } else if (reason === 'createOption') {

              } else if (reason === 'removeOption') {
                onChange(value);
                if (postOnChange) {
                  postOnChange(formContext, value, reason, details);
                }
              } else if (reason === 'blur') {

              } else if (reason === 'clear') {
                onChange(null);
                if (postOnChange) {
                  postOnChange(formContext, null, reason, details);
                }
              }

            }}
            isOptionEqualToValue={(option, value: IDropdownOption) => {
              return option.optionValue === value.optionValue;
            }}
            getOptionDisabled={(option) => {
              return option.optionValue === UNSELECTED_OPTION;
            }}
            ref={ref}
            value={value}
            options={dropdownOptions}
            filterOptions={(x) => x}
            getOptionLabel={(option) => {
              return option.optionText ?? '';
            }}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                {option.optionText}
              </li>
            )}
            fullWidth
            onInputChange={(event, newInputValue, reason:AutocompleteInputChangeReason) => {
              if (reason !== 'reset') {
                onInputChange(newInputValue, reason);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={label}
                placeholder={placeholder}
                sx={{ cursor: 'pointer' }}
              />
            )}
            {...extraProps}
          />
        );
      }
      }
    />
  );
}