import { syncStreetName, uploadPropStreamFile } from 'api/launchToolingApi';
import { uploadHomeSales, uploadNeighborhoodWKT } from 'api/neighborhoodApi';
import { createCustomShortLink } from 'api/shortLinkApi';
import { useLoading } from 'components/Layout/Loading';
import useToast from 'components/toast/useToast';
import createStore from 'hooks/hookStore';
import { IShortLinkCreateRequest, IShortLinkCreateResponse } from 'model/short_link/shortlink';
import { useEffect, useState } from 'react';

type ShortLink = {
  shortLinkResponse:IShortLinkCreateResponse | null;
}
const loadingKey = 'ShortLink';

const { get, update, registerListener, unregisterListener } = createStore<ShortLink>('ShortLink', {
  shortLinkResponse: null,
});

export default function useShortLink() {
  const setState = useState(get())[1];
  const { onLoading, doneLoading } = useLoading(loadingKey);
  const { createInfoToast, createSuccessToast, createErrorToast } = useToast();

  useEffect(() => {
    registerListener(setState);
    return () => {
      update({
        ...get(),
        shortLinkResponse: null,
      });
      unregisterListener(setState);
    };
  }, []);

  async function init() {
    onLoading();
    update({
      ...get(),
    });
    doneLoading(300);
  }

  async function onCreate(data:IShortLinkCreateRequest) {
    try {
      let response = await createCustomShortLink(data);
      if (response.data.shortLink && response.data.converted) {
        navigator.clipboard.writeText(response.data.shortLink).catch(reason => console.log(reason));
        createInfoToast('Copied to clipboard');
        update({
          ...get(),
          shortLinkResponse: response.data,
        });
      } else if (response.data.shortLink && !response.data.converted) {
        createErrorToast('Could not create short link');
      }
    } catch (err:any) {
      createErrorToast(err.response.data.message);
    }
  }

  return {
    loadingKey,
    ...get(),
    init,
    onCreate,
  };
}