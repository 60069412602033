import { IPreviewFile } from 'model/imageUpload';
import Thumbnail from './Thumbnail';

const thumbsContainer:any = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16,
};


export interface IThumbnailsProps {
  files: IPreviewFile[];
}

export default function Thumbnails({ files }:IThumbnailsProps) {
  return (
    <aside style={thumbsContainer}>
      {
        files.map((file, i) => (
          <Thumbnail key={i} file={file}/>
        ))
      }
    </aside>
  );
}