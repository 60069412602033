import { Alert, Grid, IconButton, Typography } from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import Loading from 'components/Layout/Loading';
import ConfirmationModal, { ConfirmationModalContextProvider } from 'components/modals/ConfirmationModal';
import PageHeader from 'components/SectionHeaders/PageHeader';
import { useEffect } from 'react';
import styles from './customerUpload.module.css';
import CustomerUploadRollbackForm from './rollback/customerUploadRollbackForm';
import CustomerUploadForm from './customerUploadForm';
import useCustomerUpload from './hookStore/customerUploadHookStore';
import ProcessStagedCustomersForm from './ProcessStagedCustomersForm';
import HelpIcon from '@mui/icons-material/Help';
import ConfirmRollbackModal from './rollback/ConfirmRollbackModal';
import CustomerUploadReconcileForm from './reconcile/customerUploadReconcileForm';
import { BigTooltip } from 'components/tooltip/BigTooltip';
import StepOneOperations from './step_one_operations';
import ConfirmEditAllocationsModal from './edit_allocations/ConfirmEditAllocationsModal';


export default function CustomerUploadV2() {
  const { onUpload, init, loadingKey } = useCustomerUpload();

  useEffect(() => {
    void init();
  }, []);

  return (
    <ConfirmationModalContextProvider onConfirm={(args) => onUpload(args)}>
      <Loading loadingKey={loadingKey} size={100}>
        <Grid container spacing={2} className='pageGridContainer' justifyContent='center'>
          <Grid container item xs={11} >
            <Grid item xs={12}>
              <PageHeader headerText={'Step 1: Stage Customers'}
                suppressHeaderMargin
                sx={{ paddingTop: '20px' }}
                rightChildren={
                  <BigTooltip title={
                    'Will find a latitude and longitude for each customer address, but will not associate the customer to a neighborhood. '+
                    'These customers are saved to a separate table in redshift instead of the Customer or Subscriber table'
                  }>
                    <IconButton>
                      <HelpIcon />
                    </IconButton>
                  </BigTooltip>}
              />
            </Grid>
            <Grid item xs={12} sx={{ marginTop: 1 }}>
              <StepOneOperations/>
            </Grid>
            <Grid item xs={12}>
              <Alert severity='warning' sx={{ marginBottom: '20px' }}>
                Customer data is automatically processed at the end of customer staging. You no longer need to process staged customers.
              </Alert>
            </Grid>
            {/* <Grid item xs={12} sx={{ marginTop: 10 }}>
              <PageHeader
                headerText={'Step 2: Process Staged Customers'}
                suppressHeaderMargin
                sx={{ paddingTop: '20px' }}
                rightChildren={
                  <BigTooltip title={
                    'The staging_customer table is queried by the neighborhood or service provider selection. '+
                    'Customer or Subscriber records are then created and associated to the neighborhood that matches the latitude and longitude on the staged customer.'}>
                    <IconButton>
                      <HelpIcon />
                    </IconButton>
                  </BigTooltip>}
              />
            </Grid>
            <Grid item xs={12} sx={{ marginTop: 2 }}>
              <ProcessStagedCustomersForm/>
            </Grid> */}

            {/* <Grid item xs={12} sx={{ marginBottom: '300px' }}>
              <PageHeader
                headerText={'Roll back Customer Upload Data'}
                suppressHeaderMargin
                sx={{ paddingTop: '20px' }}
                rightChildren={
                  <BigTooltip
                    title={
                      `Rollback entirety of customer upload records based on selection.
                    Will hard delete dynamo db records for individual, customer, subscriber, and nsois.
                    Will reset all staged customers back to the unprocessed state and clear the processing id.`
                    } >
                    <IconButton>
                      <HelpIcon />
                    </IconButton>
                  </BigTooltip>}
              />

              <CustomerUploadRollbackForm/>
            </Grid> */}
            {/* <Grid item xs={12} sx={{ marginBottom: '500px' }}>
              <PageHeader
                headerText={'Reconcile Customer Upload'}
                suppressHeaderMargin
                sx={{ paddingTop: '20px' }}
                rightChildren={
                  <BigTooltip title={
                    `Will create or hard delete some records in dynamodb (i.e. step 2) to reflect the status of records in postgres (i.e. step 1).
                    Will delete dynamo db records for individual, customer, subscriber, and nsois if there is not a matching individual in the staging_customer table.
                    Will create dynamodb records if there are individuals/customers in the staging_customer table that do not have a matching dynamodb record.`
                  }>
                    <IconButton>
                      <HelpIcon />
                    </IconButton>
                  </BigTooltip>}
              />

              <CustomerUploadReconcileForm/>
            </Grid> */}
          </Grid>
        </Grid>
      </Loading>
      <ConfirmationModal
        title={(onConfirmArgs) => (<div className={styles.confirmDeleteModalTitle}>Upload customers</div>)}
        message={(onConfirmArgs:any) => {
          if (onConfirmArgs.shouldUpload) {
            return (
              <Grid container spacing={1} alignItems='center' justifyContent='center' className={styles.confirmModal}>
                <Grid item xs>
                  You are about to upload {onConfirmArgs.fileName}. You will receive an email with results when complete.
                </Grid>
              </Grid>
            );
          } else if (onConfirmArgs.shouldUpload === false) {
            return (
              <Grid container spacing={1} alignItems='center' justifyContent='center' className={styles.confirmModal}>
                <Grid item xs>
                  You are about to delete customer upload results for {onConfirmArgs.data.requestId}.
                </Grid>
              </Grid>
            );
          }
        }}
      />
      <ConfirmRollbackModal/>
      <ConfirmEditAllocationsModal/>
    </ConfirmationModalContextProvider>
  );
}