import { Grid } from '@mui/material';
import { INeighborhoodServiceOffering } from 'model/neighborhoodServiceOffering';
import { useFormContext, useWatch } from 'react-hook-form';
import StepHeader from './StepHeader';
import { HookPriceField } from 'components/reactHookForm';


export interface IDollarFlatRateFieldsProps {
  neighborhoodServiceOfferings:INeighborhoodServiceOffering[];
}

export default function DollarFlatRateFields() {
  return (
    <Grid container item xs={12} spacing={1}>
      <Grid item xs={12}>
        <StepHeader>Step 4: Flat Rate Schedule Details</StepHeader>
      </Grid>
      <Grid item xs={2}>
        <HookPriceField
          name='flatRateSchedule.dollarsOff'
          label='Dollars Off'
          required
          allowNegative={false}
          validationRules
        />
      </Grid>
    </Grid>
  );
}