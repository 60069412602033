import { Grid, Button } from '@mui/material';
import useConfirmationModal from 'components/modals/useConfirmationModal';
import { HookTextField } from 'components/reactHookForm';
import { IRequestManagementForm, createEmptyRequestManagementFrom, RequestManagementTypes } from 'model/neighborhoodLaunch';
import { useEffect } from 'react';
import { useForm, SubmitHandler, FormProvider, useFormContext } from 'react-hook-form';
import { ConfirmationModal } from './confirmationModal';
import RequestManagementTypeSelect from './formComponents/RequestManagementTypeSelect';
import useRequestManagement from './hookStore/useRequestManagement';

export interface IRequestManagementFormInternalProps {
  initialFormData: IRequestManagementForm;
}

const requestManagementModalKey = 'requestManagementModalKey';
function getModalBody(requestAction:string) {
  if (requestAction === RequestManagementTypes.DELETE_PROVIDER_LAUNCH) {
    return <div>Delete NSOs for request?</div>;
  } else if (requestAction === RequestManagementTypes.DELETE_CLONED_NSOS) {
    return <div>Delete NSOs for request?</div>;
  }
  return 'Unset';
}

function getModalTitle(requestAction:string) {
  if (requestAction === RequestManagementTypes.DELETE_PROVIDER_LAUNCH) {
    return 'Delete Provider Launch?';
  } else if (requestAction === RequestManagementTypes.DELETE_CLONED_NSOS) {
    return 'Delete Neighborhood Clone?';
  }
  return 'Unset';
}

function ModalWrapper() {
  const { getValues, control } = useFormContext();
  const { executeRequest } = useRequestManagement();
  const requestAction = getValues('requestAction');
  return (
    <ConfirmationModal
      body={getModalBody(requestAction)}
      modalKey={requestManagementModalKey}
      title={getModalTitle(requestAction)}
      onConfirm={async (data) => {
        await executeRequest(data);
      }}
      successText=' submitted.'
    />
  );
}

function RequestManagementFormInternal({ initialFormData }:IRequestManagementFormInternalProps) {
  const formContext = useForm<IRequestManagementForm>({ mode: 'onTouched', defaultValues: createEmptyRequestManagementFrom() });
  const { openModal } = useConfirmationModal(requestManagementModalKey);
  const { isSubmitting, isValid } = formContext.formState;

  useEffect(() => {
    formContext.reset(initialFormData);
  }, [initialFormData]);

  const onSubmit : SubmitHandler<IRequestManagementForm> = data => {
    openModal(data);
  };
  const requestAction = formContext.getValues('requestAction');
  return (
    <FormProvider {...formContext}>
      <form onSubmit={formContext.handleSubmit(onSubmit)} >
        <Grid container spacing={1}>
          <Grid container item xs={12} sm={12} md={4} >
            <HookTextField
              label='Request Id'
              name='requestId'
            />
          </Grid>
          <Grid container item xs={12} sm={12} md={4} >
            <RequestManagementTypeSelect/>
          </Grid>

          <Grid container item xs={12} justifyContent={{ sm: 'space-between' }} alignItems='flex-end'>
            <Grid item xs={12} sm={5} md={4} order={{ xs: 0, sm: 1 }}>
              <Button
                disabled={isSubmitting || !isValid || requestAction === RequestManagementTypes.UNSET}

                variant='contained'
                type='submit'
              >
                            Submit Request Action
              </Button>
            </Grid>
          </Grid>
          <ModalWrapper/>
        </Grid>
      </form>
    </FormProvider>
  );
}

export default function RequestManagementForm() {
  const { formData, init } = useRequestManagement();
  useEffect(() => {
    void init();
  }, []);
  return (
    <RequestManagementFormInternal initialFormData={formData}/>
  );
}