import AddIcon from '@mui/icons-material/Add';
import { IconButton, Tooltip } from '@mui/material';

interface IAddButtonProps {
  tooltipTitle:string;
  onClick:any;
  className?:string;
  disabled?:boolean;
}

export default function AddButton({ tooltipTitle, onClick, className, disabled }:IAddButtonProps) {
  return (
    <Tooltip title={tooltipTitle} className={className}>
      <IconButton onClick={onClick} disabled={disabled}>
        <AddIcon/>
      </IconButton>
    </Tooltip>
  );
}