import { Grid } from '@mui/material';
import TerritorySelect from './_components/TerritorySelect';
import AddProviderButton from './_components/AddProviderButton';
import CloneFromButton from './_components/CloneFromButton';
import useTerritorySeedingTemplates from './hookStore/useTerritorySeedingTemplates';
import { useEffect } from 'react';
import useMarkets from 'hooks/useMarkets';
import PageHeader from 'components/SectionHeaders/PageHeader';
import AddProviderDrawer from './addProviderDrawer/AddProviderDrawer';
import TerritorySeedingTemplatesTable from './_components/TemplatesTable';
import CloneFromModal from './_components/CloneFromModal';

function TerritorySeedingTemplates() {
  const { market } = useMarkets();
  const { init, isAddProviderDrawerOpen, toggleAddProviderDrawer, templates } = useTerritorySeedingTemplates();

  useEffect(() => {
    void init(market);
  }, [market]);
  return <Grid container sx={{ flexDirection: 'column', paddingLeft: '10px', paddingRight: '10px' }}>
    <Grid item xs={12}>
      <PageHeader headerText={'Territory Seeding Templates'} />
    </Grid>
    <Grid
      container item
      xs={10}
      md={6}
      flexDirection='row'
      justifyContent='space-between'
      alignItems={'center'}
      sx={{ paddingLeft: '10px', paddingRight: '10px', paddingTop: '50px' }}>
      <Grid item xs={4}>
        <TerritorySelect />
      </Grid>
      <Grid item xs={4}>
        <CloneFromButton />
      </Grid>
    </Grid>
    <Grid container item xs={12} md={6} direction={'row'} sx={{ paddingLeft: '10px', paddingBottom: '25px', paddingTop: '40px' }}>
      <Grid item xs={12}>
        <AddProviderButton />
      </Grid>
    </Grid>
    { templates.length > 0 && <TerritorySeedingTemplatesTable />}
    <AddProviderDrawer open={isAddProviderDrawerOpen} onClose={toggleAddProviderDrawer}/>
    <CloneFromModal />
  </Grid>;
}

export default TerritorySeedingTemplates;