import { Grid, Typography } from '@mui/material';
import { toggleIgnoreForReconciliationNeighborhoodServiceOfferingsById } from 'api/neighborhoodServiceOfferingApi';
import MarketAutocomplete from 'components/Header/MarketAutocomplete';
import GenericModal from 'components/modal/GenericModal';
import useModal, { IModal } from 'components/modal/useModal';
import useMarkets from 'hooks/useMarkets';
import useServiceCategoryDetail from '../../hookStore/useServiceCategoryDetail';

export const modalStoreKey = 'SelectMarketModal';
export interface ISelectMarketModal {
  marketId:string;
}
export function createEmptySelectMarketModal() :ISelectMarketModal {
  return {
    marketId: '',
  };
}
export interface ISelectMarketModalProps {
  postOnConfirm: (marketId:string) => void;
}

export default function SelectMarketModal({ postOnConfirm }:ISelectMarketModalProps) {
  const {
    revertSelectedTab,
  } = useServiceCategoryDetail();
  const { getModalState, closeModal, updateModalState } = useModal(modalStoreKey);
  const { selectedMarket } = useMarkets();
  const _state = getModalState() as ISelectMarketModal & IModal;
  const { marketId } = _state;

  async function _onToggle(state:ISelectMarketModal & IModal) {
    let usedMarketId = selectedMarket?.id;
    if (marketId) {
      usedMarketId = marketId;
    }
    if (usedMarketId) {
      postOnConfirm(usedMarketId!);
      closeModal(createEmptySelectMarketModal());
    }
  }

  return (
    <GenericModal
      modalStoreKey={modalStoreKey}
      title={'Select Market'}
      modalContent={
        (
          <div >
            <MarketAutocomplete
              onChangeOverride={(option:any) => {
                updateModalState({
                  ...getModalState(),
                  marketId: option.optionValue,
                });
              }}
            />
          </div>
        )
      }
      onConfirm={() => _onToggle(_state)}
      onSecondaryAction={() => {
        closeModal(createEmptySelectMarketModal());
        revertSelectedTab();
      }}
      secondaryButtonText='Cancel'
    />
  );
}