import { Button, Card, CardContent, Modal, Grid, useMediaQuery, useTheme } from '@mui/material';
import styles from './ConfirmationModal.module.css';
import useConfirmationModal from './useConfirmationModal';
import { PrimaryButton } from 'components/buttons';
import PrimaryTextButton from 'components/buttons/PrimaryTextButton';

export default function HookConfirmationModal({ modalKey, title, message, onConfirm, enabled=true, confirmText='Confirm' }:any) {
  const { open, onConfirmArgs, cancelModal, confirmModal } = useConfirmationModal(modalKey);
  const theme = useTheme();
  const xsBrk = useMediaQuery(theme.breakpoints.only('xs'));
  return (
    <Modal
      open={open}
      aria-labelledby="confirm-modal-title"
      aria-describedby="confirm-modal-description"
      className={styles.modalParent}
      sx={{
        margin: xsBrk ? '20px' : 0,
      }}
    >
      <Card>
        <CardContent>
          <div id="confirm-modal-title" className={styles.modalTitle}>
            {title(onConfirmArgs) || 'You are about to delete this item'}
          </div>
          <div id="confirm-modal-description" className={styles.modalContent}>
            {message(onConfirmArgs) || (<div>Are you sure you want to delete?</div>)}
          </div>
          <Grid container justifyContent='right' spacing={1}>
            <Grid item xs='auto'>
              <PrimaryTextButton type='button' onClick={cancelModal}>Cancel</PrimaryTextButton>
            </Grid>
            <Grid item xs='auto' >
              <PrimaryButton
                disabled={!enabled}
                type='button'
                color='primary'
                onClick={() => confirmModal(onConfirm)}
              >
                <>{confirmText}</>
              </PrimaryButton>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Modal>
  );
}