import { useLoading } from 'components/Layout/Loading';
import useMarkets from 'hooks/useMarkets';
import useAddProviderToTemplates, { loadingKey } from '../hookStore/useAddProviderToTemplates';
import TerritoryMultiAutocomplete from 'components/autocomplete/TerritoryMultiAutocomplete';


export default function TerritoryMultiSelect() {
  const { selectedMarket } = useMarkets();
  const { onLoading, doneLoading } = useLoading(loadingKey);
  const { selectedTerritories, onSelectTerritory } = useAddProviderToTemplates();
  if (!selectedMarket) {
    return null;
  }

  return (
    <TerritoryMultiAutocomplete
      marketId={selectedMarket.id!}
      selectedOptions={selectedTerritories}
      onChange={async (options:any[]) => {
        onLoading();
        onSelectTerritory(options);
        doneLoading();
      }}
    />
  );
}