import { Grid } from '@mui/material';
import { AddButton, RemoveButton } from 'components/buttons';
import Spacer from 'components/Layout/Spacer';
import { HookCheckbox, HookTextField } from 'components/reactHookForm';
import useServiceOfferingForm from 'hooks/serviceOffering/useServiceOfferingForm';
import { useEffect, useState } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { StepHeader } from './common';
import FieldTypeSelect from './customFieldsComponents/FieldTypeSelect';
import MoveCustomField from './customFieldsComponents/MoveCustomField';
import styles from '../providerOnboarding.module.css';
import CustomFieldOptions from './customFieldsComponents/CustomFieldOptions';
import CustomFieldGroupTypeSelect from './customFieldsComponents/CustomFieldGroupTypeSelect';


export default function CustomFields({ init }:any) {
  const { control, formState } = useFormContext();
  const { createEmptyCustomField } = useServiceOfferingForm(null);
  const [moved, setMoved] = useState<boolean>(false);
  const { fields: customFields, append, replace, move, remove } = useFieldArray({
    name: 'customFields',
  });
  const watchCustomFields = useWatch({ control, name: 'customFields' });
  useEffect(() => {
    if (customFields.length !== watchCustomFields.length) {
      replace(watchCustomFields);
    }
  }, [watchCustomFields]);

  return (
    <Grid container item spacing={1} alignItems='center'>
      {customFields.map((item, index) => {
        return (
          <Grid container item spacing={1} key={item.id} alignItems='top' className={styles.customField}>
            <Grid container flexDirection='row' justifyContent='space-between'>
              <Grid item xs={3}>
                <HookTextField
                  name={`customFields.${index}.fieldName`}
                  label='Field Name'
                  required
                />
              </Grid>
              <Grid item xs={3}>
                <FieldTypeSelect index={index}/>
              </Grid>
              <Grid item xs={3}>
                <HookTextField
                  name={`customFields.${index}.fieldLabel`}
                  label='Field Label'
                  required
                />
              </Grid>
              <Grid item xs={1}>
                {/* spacer */}
              </Grid>
            </Grid>
            <Grid container flexDirection='row' justifyContent='space-between'>
              <Grid item xs={3}>
                <HookTextField
                  name={`customFields.${index}.fieldPlaceholderText`}
                  label='Field Placeholder Text'
                  required
                />
              </Grid>
              <Grid item xs={3}>
                <CustomFieldGroupTypeSelect index={index}/>
              </Grid>
              <Grid item xs={3} alignSelf='center'>
                <HookCheckbox
                  name={`customFields.${index}.required`}
                  label='Is Required?'
                />
              </Grid>
              <Grid container item xs={1} alignSelf='center' justifyContent='end'>
                <RemoveButton
                  tooltipTitle="Delete this custom field"
                  onClick={() => remove(index)}
                />
              </Grid>
            </Grid>
            <CustomFieldOptions customField={`customFields.${index}`}/>
            <MoveCustomField
              arrayLength={customFields.length}
              index={index}
              move={move}
              moved={moved}
              setMoved={setMoved}
            />
          </Grid>
        );
      })}
      <Grid container alignItems='center' item xs={12}>
        <Grid item xs='auto'>
          <StepHeader>Add Custom Field</StepHeader>
        </Grid>
        <Grid item xs='auto'>
          <AddButton
            tooltipTitle="Add a custom field"
            onClick={() => append(createEmptyCustomField())}
          />
        </Grid>
        <Spacer/>
      </Grid>
    </Grid>
  );
}
