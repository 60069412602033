import { FormControl, InputLabel, FormHelperText } from '@mui/material';
import { HookSelect } from 'components/reactHookForm';
import useErrorState from 'hooks/reactHookForm/useErrorState';
import { ServiceTypeColor } from 'model/serviceCategory';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import useServiceCategoryDetailStore from '../hookStore/useServiceCategoryDetail';

export interface IColorSelectProps {
  disabled?: boolean;
}
export default function ColorSelect({
  disabled,
}:IColorSelectProps) {
  const formCtx = useFormContext();
  const { swapColorScheme } = useServiceCategoryDetailStore();
  const [colors] = useState<any[]>(ServiceTypeColor.list);
  const { isError, getErrorMessage } = useErrorState(formCtx);
  let watchedColorScheme = formCtx.watch('colorScheme');
  useEffect(() => {
    swapColorScheme(watchedColorScheme);
  }, [watchedColorScheme]);
  return (
    <FormControl variant='standard' fullWidth error={isError('colorScheme')}>
      <InputLabel htmlFor={'service-type-color'}>Service Type Color</InputLabel>
      <HookSelect
        control={formCtx.control}
        rules={{
          required: { value: true, message: 'Required' },
        }}
        name={'colorScheme'}
        id={'service-type-color'}
        menuItems={colors}
        disabled={disabled}
      />
      <FormHelperText>{getErrorMessage('colorScheme')}</FormHelperText>
    </FormControl>
  );
}