import { searchNextdoorPosts } from 'api/nextdoorApi';
import { findTerritoriesByMarket, uploadTerritoryWKT } from 'api/territoriesApi';
import { useLoading } from 'components/Layout/Loading';
import useToast from 'components/toast/useToast';
import createStore from 'hooks/hookStore';
import { IDropdownOption } from 'model/dropdown';
import { IMarket } from 'model/markets';
import { INextDoorSearchPostsResult, INextdoorSearchPostRequest, IProviderRecommendationsForm, createEmptyProviderRecommendationsForm } from 'model/providerRecommendations';
import { useEffect, useState } from 'react';
import { convertTerritoriesToDropdownOptions } from 'util/dropdownUtil';

type ProviderRecommendations = {
  formData: IProviderRecommendationsForm;
  territoryDropdownOptions: IDropdownOption[];
  data: INextDoorSearchPostsResult[];
}
const loadingKey = 'providerRecommendations';

const { get, update, registerListener, unregisterListener } = createStore<ProviderRecommendations>('providerRecommendations', {
  formData: createEmptyProviderRecommendationsForm(),
  territoryDropdownOptions: [],
  data: [],
});

export default function useProviderRecommendations() {
  const setState = useState(get())[1];
  const { onLoading, doneLoading } = useLoading(loadingKey);
  const { createErrorToast } = useToast();


  useEffect(() => {
    registerListener(setState);
    return () => {
      update({
        ...get(),
        formData: createEmptyProviderRecommendationsForm(),
        territoryDropdownOptions: [],
        data: [],
      });
      unregisterListener(setState);
    };
  }, []);

  async function init(market:IMarket) {
    onLoading();
    const territoryRes = await findTerritoriesByMarket(market.id);
    const territoryDropdownOptions = convertTerritoriesToDropdownOptions(territoryRes.data, { optionValueIsZipCodeTerritory: false, addUnassignedTerritory: false });
    update({
      ...get(),
      territoryDropdownOptions,
    });
    doneLoading(300);
  }

  async function submit(request:INextdoorSearchPostRequest) {
    onLoading();
    try {
      const res = await searchNextdoorPosts(request);
      if (res.data) {
        update({
          ...get(),
          data: res.data,
        });

      }
    } catch (e:any) {
      createErrorToast(e.response.data.message);
    }
    doneLoading(300);
  }

  return {
    loadingKey,
    ...get(),
    init,
    submit,
  };
}