import { useFormContext, useWatch } from 'react-hook-form';
import { Typography } from '@mui/material';

export default function ExportDate() {

  const formContext = useFormContext();
  const watchedExportDate = useWatch({ name: 'exportDate', control: formContext.control });
  return (
    <Typography variant="body1">
            Export Date: {watchedExportDate ? watchedExportDate : 'Not Exported'}
    </Typography>
  );
}