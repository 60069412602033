import { Grid } from '@mui/material';
import CacheButton from './CacheButton';

export default function FlushCache() {

  return (
    <Grid container sx={{ height: '600px', width: '100%' }} alignItems='center' justifyContent='center'>
      <Grid item>
        <CacheButton/>
      </Grid>
    </Grid>
  );
}