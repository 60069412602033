import { FormControl, InputLabel, FormHelperText } from '@mui/material';
import { HookSelect } from 'components/reactHookForm';
import useErrorState from 'hooks/reactHookForm/useErrorState';
import { FieldType } from 'model/serviceOffering';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

export default function FieldTypeSelect({ index }:any) {
  const formCtx = useFormContext();
  const [fieldTypes] = useState<any[]>(FieldType.list);
  const { isError, getErrorMessage } = useErrorState(formCtx);
  return (
    <FormControl variant='standard' fullWidth error={isError(`customFields.${index}.fieldTypeCode`)}>
      <InputLabel htmlFor="field-type">Field Type</InputLabel>
      <HookSelect
        control={formCtx.control}
        rules={{
          required: { value: true, message: 'Required' },
        }}
        name={`customFields.${index}.fieldTypeCode`}
        id='field-type'
        menuItems={fieldTypes}
      />
      <FormHelperText>{getErrorMessage(`customFields.${index}.fieldTypeCode`)}</FormHelperText>
    </FormControl>
  );
}