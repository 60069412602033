import ClearIcon from '@mui/icons-material/Clear';
import { IconButton, Tooltip } from '@mui/material';

interface IClearButtonProps {
  tooltipTitle?:string;
  onClick:any;
  className?:string;
  disabled?:boolean;
  iconButtonProps?:any;
  tooltipProps?:any;
}
export default function ClearButton({ tooltipTitle, onClick, className, disabled, tooltipProps, iconButtonProps }:IClearButtonProps) {
  return (
    <Tooltip title={tooltipTitle} className={className} {...tooltipProps}>
      <>
        {disabled && <ClearIcon opacity={0.3}/>}
        {!disabled && (
          <IconButton onClick={onClick} disabled={disabled} {...iconButtonProps}>
            <ClearIcon/>
          </IconButton>
        )}
      </>
    </Tooltip>
  );
}