import { sortByString } from 'util/sortFunctions';
import { InputLabel, Grid, FormControl, FormHelperText, Tooltip, Tabs, Tab, Button, Select, MenuItem, Typography } from '@mui/material';
import Spacer from 'components/Layout/Spacer';
import { HookCheckbox, HookIntegerField, HookSelect, HookTextField } from 'components/reactHookForm';
import PageHeader from 'components/SectionHeaders/PageHeader';
import useErrorState from 'hooks/reactHookForm/useErrorState';
import useServiceOfferingForm from 'hooks/serviceOffering/useServiceOfferingForm';
import get from 'lodash/get';
import {
  CadenceType,
  IServiceOffering,
} from 'model/serviceOffering';
import { useState, useEffect, memo } from 'react';
import { useForm, SubmitHandler, FormProvider, useFormContext, useWatch, useFieldArray } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import CustomFields from './details/CustomFields';
import DiscountScheduleSelect from './details/DiscountScheduleSelect';
import useServiceOfferingDetails from './details/hookStore/useServiceOfferingDetails';
import ServiceDetailSections from './details/ServiceDetailSections';
import { createDefaultValues } from './details/serviceOfferingDetailHelper';
import ServiceTypeSelect from './details/ServiceTypeSelect';
import SubscriptionOptions from './details/SubscriptionOptions';
import WorkflowSteps from './details/WorkflowSteps';
import styles from './serviceOfferingDetails.module.css';
import { ServiceOfferingImageDropzone } from './details/imageUpload/ServiceOfferingImageDropzone';
import Loading from 'components/Layout/Loading';
import ProviderCoverageAreaSelect from './details/ProviderCoverageAreaSelect';
import ServiceProviderAutocomplete from 'components/serviceProviderAutocomplete/ServiceProviderAutocomplete';
import InfoIcon from '@mui/icons-material/Info';

const sortByName = sortByString('name');
//Overview of component
//0. always collect priceAmount, priceLabel, priceSuffix, and name
//1.dropdown select discount schedule type
//2a. if $ per customer then show three fields for pricing schedule
//2b. if $ saved per customer then show discount schedule fields
//2c. if no discount then show no extra fields
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function getSubscriptionTabTooltip(selectedCadenceType:string) {
  return selectedCadenceType===CadenceType.ONETIME_ONLY ?
    'One time only does not have subscriptions' : '';
}

const TabPanel = memo(function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`service-offering-details-tabpanel-${index}`}
      aria-labelledby={`service-offering-details-tab-${index}`}
      className={styles.tabPanel}
      {...other}
    >
      {children}
    </div>
  );
});

function a11yProps(index: number) {
  return {
    'id': `service-offering-details-tab-${index}`,
    'aria-controls': `service-offering-details-tabpanel-${index}`,
  };
}

function CadenceTypeSelect() {
  const formCtx = useFormContext();
  const [cadenceTypes] = useState<any[]>(CadenceType.list);
  const { isError, getErrorMessage } = useErrorState(formCtx);
  return (
    <FormControl variant='standard' fullWidth error={isError('cadenceType')}>
      <InputLabel htmlFor="cadence-type">Cadence Type</InputLabel>
      <HookSelect
        control={formCtx.control}
        rules={{
          required: { value: true, message: 'Required' },
        }}
        name='cadenceType'
        id='cadence-type'
        menuItems={cadenceTypes}
      />
      <FormHelperText>{getErrorMessage('cadenceType')}</FormHelperText>
    </FormControl>
  );
}

function ServiceOfferingDetails({
  initialFormData,
}:any) {
  const {
    tabValue, setTabValue,
    setFormData,
  } = useServiceOfferingForm(null);
  const {
    loadingKey,
    isClone,
    omitImages,
    hasServiceProviderError,
    existingDefaultServiceOffering,

    selectedServiceProviderId,
    isDefaultForServiceType,
    originalServiceTypeId,
    onSaveServiceOffering,
    onServiceProviderChanged,
    onDefaultForServiceTypeChecked,
    setHasServiceProviderError,
  } = useServiceOfferingDetails();
  const formContext = useForm<IServiceOffering>({ mode: 'onChange', defaultValues: initialFormData || createDefaultValues() });
  const navigate = useNavigate();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };
  const selectedCadenceType = formContext.watch('cadenceType');

  useEffect(() => {
    if (selectedCadenceType === CadenceType.ONETIME_ONLY) {
      setTabValue(0);
    }
  }, [selectedCadenceType]);

  useEffect(() => {
    if (initialFormData) {
      formContext.reset(initialFormData);
    }
  }, [initialFormData]);

  const onSubmit : SubmitHandler<IServiceOffering> = data => {
    if (!selectedServiceProviderId) {
      setHasServiceProviderError();
      return;
    }
    void onSaveServiceOffering(data);
  };

  const watchedServiceTypeId = useWatch({ control: formContext.control, name: 'serviceTypeId' });
  const watchedDefaultForServiceType = useWatch({ control: formContext.control, name: 'defaultForServiceType' });
  const watchServiceProviderId = useWatch({ control: formContext.control, name: 'providerId' });
  return (
    <FormProvider {...formContext}>
      <form onSubmit={formContext.handleSubmit(onSubmit)}>
        <Grid container spacing={2} className='pageGridContainer'>
          <Grid container item xs={12}>
            {isClone && <PageHeader headerText={`Clone service offering${omitImages ? ' (EXCLUDING IMAGES)' : ' (INCLUDING IMAGES)'}`}/>}
            {!isClone && <PageHeader headerText={'Manage service offering'}/>}
          </Grid>
          <Grid item xs={9} md={2}>
            <FormControl variant="standard" sx={{ minWidth: 300 }}>
              <ServiceProviderAutocomplete
                label='Service Provider'
                fieldName='providerId'
                controlledValue={watchServiceProviderId}
                placeholder='Search and check service provider'
                required={true}
                formless={false}
                postOnChange={ async (formCtx, values, reason, details) => {
                  if (details && details.option && details.option.optionValue) {
                    await onServiceProviderChanged(details.option.optionValue, formCtx);
                  }
                }}
              />
              <FormHelperText sx={{ color: 'red' }}>{hasServiceProviderError ? 'Required' : ''}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={9} md={3}>
            <HookTextField
              name='name'
              label='Name'
              required
              maxLength={100}
            />
          </Grid>
          <Grid item xs={9} md={3}>
            <HookTextField
              name='frequency'
              label='Frequency'
              required
            />
          </Grid>
          <Grid item xs={9} md={2}>
            <CadenceTypeSelect/>
          </Grid>
          <Grid item xs={9} md={2}>
            <ServiceTypeSelect/>
          </Grid>
          <Grid item xs={9} md={6}>
            <HookTextField
              name='invitationDescription'
              label='Invitation description'
              required
              multiline
            />
          </Grid>
          <Grid item xs={9} md={3}>
            <HookTextField
              name='serviceTypeNameOverride'
              label='Service Type Name Override'
            />
          </Grid>
          <Grid item xs={9} md={3}>
            <ProviderCoverageAreaSelect />
          </Grid>
          <Grid item xs={12}></Grid>
          <Grid item xs={12} sm={6} md={2}>
            <HookCheckbox
              name='territoryServiceOfferingEnabled'
              label='TSOI Enabled?'
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <HookCheckbox
              name='hasDisabledMaxCustomerCount'
              label='Has Disabled Max Customer Count?'
            />
          </Grid>
          <Grid item xs={9} md={3}>
            <DiscountScheduleSelect/>
          </Grid>
          <Grid item xs={9} md={3}>
            <HookIntegerField
              name='cutoffDayCount'
              label='Default Cutoff Day Count'
            />
          </Grid>

          <Grid item xs={9} md={3}>
            <HookCheckbox
              postOnChange={(e) => onDefaultForServiceTypeChecked(e, formContext)}
              name='defaultForServiceType'
              label='Is Default for Service Type'
              extraProps={{
                disabled: (
                  !selectedServiceProviderId ||
                                !watchedServiceTypeId ||
                                (isDefaultForServiceType && originalServiceTypeId === watchedServiceTypeId)
                ),
              }}
            />
            {isDefaultForServiceType && originalServiceTypeId === watchedServiceTypeId &&(
              <Typography variant='body1'>This service offering is the default for the service type. You may override this default with another service offering, but cannot uncheck.</Typography>
            )}
            {existingDefaultServiceOffering && (
              <>
                <Typography variant='body1'>There is an existing service offering that is the default for this service type. Saving will set this service offering as the new default.</Typography>
                <Typography variant='body2'>Service Offering Id: {existingDefaultServiceOffering.id}</Typography>
                <Typography variant='body2'>Service Offering Name: {existingDefaultServiceOffering.name}</Typography>
              </>
            )}
          </Grid>
          <Grid container item xs={12}>
            <HookCheckbox
              name='isRebookEnabled'
              label='Rebook Enabled?'
            />
            <Grid item >
              <Tooltip title='Controls whether a user will be provided with the "Rebook for next year" modal after leaving a review in the Consumer app. The same flag will need to be enabled at the Service Type level in order for this setting to considered.'>
                <InfoIcon className={styles.infoIcon}/>
              </Tooltip>
            </Grid>
          </Grid>
          <Spacer/>
          <div className={styles.tabDivider}></div>
          <Grid item xs={12}>
            <Tabs
              value={tabValue}
              onChange={handleChange}
              aria-label="basic tabs example"
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab
                label={(
                  <span
                    className={get(formContext.formState.errors, 'serviceDetailSections', false) ? styles.errorTab : ''}
                  >
                                    Service detail sections
                  </span>)}
                {...a11yProps(0)} />
              <Tab
                style={{ pointerEvents: 'auto' }}
                label={
                  <Tooltip
                    title={getSubscriptionTabTooltip(selectedCadenceType)}>
                    <span
                      className={get(formContext.formState.errors, 'subscriptionOptions', false) ? styles.errorTab : ''}
                    >
                                    Subscription options
                    </span>
                  </Tooltip>
                }
                disabled={selectedCadenceType===CadenceType.ONETIME_ONLY}
                {...a11yProps(1)}
              />
              <Tab
                label={(
                  <span
                    className={get(formContext.formState.errors, 'customFields', false) ? styles.errorTab : ''}
                  >
                                Custom fields
                  </span>)}
                {...a11yProps(2)}
              />
              <Tab
                label={(
                  <span
                    className={get(formContext.formState.errors, 'workflowSteps', false) ? styles.errorTab : ''}
                  >
                                Workflow Steps
                  </span>)}
                {...a11yProps(3)}
              />
              <Tab
                label={(
                  <span>Images</span>
                )}
                {...a11yProps(3)}
              />
            </Tabs>
          </Grid>
          <Grid item xs={12} className={styles.tabBackground}>
            <TabPanel value={tabValue} index={0}>
              <ServiceDetailSections />
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <SubscriptionOptions/>
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
              <CustomFields />
            </TabPanel>
            <TabPanel value={tabValue} index={3}>
              <WorkflowSteps workflowSteps={initialFormData.workflowSteps}/>
            </TabPanel>
            <TabPanel value={tabValue} index={4}>
              <ServiceOfferingImageDropzone/>
            </TabPanel>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Button
                variant="contained"
                className={styles.saveButton}
                type="submit"
              >
                <span>Save</span>
              </Button>
              <Button
                variant="text"
                className={styles.cancelButton}
                onClick={()=>{navigate('/service-offerings');}}
              >
                <span>Cancel</span>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>

    </FormProvider>
  );
}

export interface IServiceOfferingDetailsWrapper {
  clone?:boolean;
}

export default function Wrapper({ clone }:IServiceOfferingDetailsWrapper) {
  const {
    initialFormData,
    init,
    loadingKey,
  } = useServiceOfferingDetails();

  useEffect(() => {
    void init(clone || false);
  }, []);

  if (!initialFormData) {
    return null;
  }
  return (
    <Loading loadingKey={loadingKey}>
      <ServiceOfferingDetails
        initialFormData={initialFormData}
      />
    </Loading>
  );
}