
import { DataGrid } from '@mui/x-data-grid';
import QuickSearchToolbar from 'components/grid/QuickSearchToolbar';
import { useEffect, useState } from 'react';
import useTableSearch from 'components/grid/useTableSearch';
import { GridColumns, GridSortModel } from '@mui/x-data-grid-pro';
import { Grid } from '@mui/material';
import EditCell from 'components/DataGridCell/EditCell';
import DeleteCell from 'components/DataGridCell/DeleteCell';
import GenericDeleteModal from './GenericDeleteModal';


export interface ICrudTableProps {
  quickSearchStorageKey: string;
  allDataRows: any[];
  columns: GridColumns<any>;
  defaultSortModel: GridSortModel;
  enableEdit?: boolean;
  enableDelete?: boolean;
  customActions?: ((id: any, row: any) => JSX.Element)[];
  deleteModalStoreKey?: string;
  getId: (row: any) => string;
  getDesc: (row: any) => string;
  createDetailRoute: (id: string) => string;
  onDelete?: (id: any, desc: any) => Promise<any>;
  onPaginationChange?: (paginationModel: any, setRowCountState: (number) => any, onComplete: () => any) => Promise<any>;
  pagination?:true;
  searchablePropertyKeys?:string[];
  defaultPageSize?:number;
  defaultPage?:number;
  rowsPerPageOptions?: number[];
  paginationMode?: 'client' | 'server';
}

export default function CrudTable({
  quickSearchStorageKey,
  allDataRows,
  columns,
  defaultSortModel,
  enableEdit = false,
  enableDelete= false,
  customActions = [],
  deleteModalStoreKey,
  createDetailRoute,
  getId,
  getDesc,
  onDelete,
  onPaginationChange,
  defaultPageSize = 100,
  defaultPage = 0,
  pagination,
  searchablePropertyKeys,
  rowsPerPageOptions = [10, 25, 50, 100],
  paginationMode,
}:ICrudTableProps) {
  if (enableDelete && !deleteModalStoreKey) {
    throw new Error('deleteModalStoreKey is required when enableDelete is true');
  }
  const {
    rows,
    searchText,
    paginationModel,
    rowCountState,
    setRowsWithStoredFilter,
    setSearchResultsLoading,
    requestSearch,
    clearStoredSearch,
    onPageChange,
    onPageSizeChange,
    setPaginationModel,
    setRowCountState,
  } = useTableSearch(quickSearchStorageKey, searchablePropertyKeys, defaultPageSize, defaultPage);
  const [sortModel, setSortModel] = useState(
    defaultSortModel,
  );
  const [columnTotalWidth, setColumnTotalWidth] = useState(0);
  const [_columns, setColumns] = useState(columns);

  useEffect(() => {
    let nextColumns = [...columns];
    if (enableEdit || enableDelete || customActions.length > 0) {
      nextColumns = [...columns, {
        field: 'actions',
        headerName: 'Actions',
        width: 200,
        renderCell: (params:any) => {
          return (
            <Grid container item xs={12} justifyContent='center' alignItems='center'>
              {enableEdit && (
                <EditCell
                  editName={getDesc(params.row)}
                  toRoute={createDetailRoute(params.id)}
                />
              )}
              {enableDelete && (
                <DeleteCell
                  modalStoreKey={deleteModalStoreKey!}
                  id={getId(params.row)}
                  desc={getDesc(params.row)}
                />
              )}
              {customActions?.length > 0 && customActions.map((el, index) => {
                var component = el(params.id, params.row);
                return component;
              })}
            </Grid>
          );
        },
      }];
    }
    setColumns(nextColumns);
    const calculatedTotalWidth = nextColumns.map(x => x.width).reduce((a:any, b:any) => a+b, 0);
    setColumnTotalWidth(calculatedTotalWidth);

  }, [columns]);

  useEffect(() => {
    setRowsWithStoredFilter(allDataRows);
  }, [allDataRows]);

  useEffect(() => {
    if (onPaginationChange) {
      setSearchResultsLoading(true);
      void onPaginationChange!(paginationModel,
        (val:number) => {
          setRowCountState(val);
        },
        () => {
          setSearchResultsLoading(false);
        });
    }

  }, [paginationModel]);

  return (
    <>
      <DataGrid
        rowCount={rowCountState != null ? rowCountState : rows.length}
        rows={rows}
        components={{ Toolbar: QuickSearchToolbar }}
        rowsPerPageOptions={rowsPerPageOptions}
        componentsProps={{
          toolbar: {
            quickSearchStorageKey,
            value: searchText,
            onChange: (event: React.ChangeEvent<HTMLInputElement>) => requestSearch(allDataRows, event.target.value),
            clearSearch: () => requestSearch(allDataRows, ''),
          },
        }}
        columns={_columns}
        autoHeight={true}
        sortModel={sortModel}
        onSortModelChange={setSortModel}
        pagination={pagination}
        paginationMode={paginationMode}
        page={paginationModel.page}
        pageSize={paginationModel.pageSize}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        style={{
          minWidth: '500px',
          maxWidth: `${columnTotalWidth}px`,
        }}
      />
      {deleteModalStoreKey && (
        <GenericDeleteModal
          modalKey={deleteModalStoreKey}
          onDelete={onDelete ?? (() => Promise.resolve())}
        />
      )}
    </>
  );
}