import Autocomplete, { AutocompleteChangeReason, createFilterOptions } from '@mui/material/Autocomplete';
import CreateCrmOptionConfirmationModal, { modalStoreKey } from './CreateCrmOptionConfirmationModal';
import useErrorState from 'hooks/reactHookForm/useErrorState';
import useServiceProviderCrmCreatableAutocomplete from './useServiceProviderCrmCreatableAutocomplete';
import { IDropDownOptionCreatable } from 'model/dropdown';
import { FormControl } from '@mui/material';
import { HookSingleAsyncAutocomplete } from 'components/reactHookForm';
import { useFormContext, useWatch } from 'react-hook-form';
import { useEffect, useState } from 'react';
import useModal from 'components/modal/useModal';

const filter = createFilterOptions<IDropDownOptionCreatable>();

export interface IServiceProviderCrmCreatableAutocompleteProps {}

export default function ServiceProviderCrmCreatableAutocomplete({ }:IServiceProviderCrmCreatableAutocompleteProps) {
  const { init, options } = useServiceProviderCrmCreatableAutocomplete();
  const { openModal } = useModal(modalStoreKey);
  const formCtx = useFormContext();
  const { isError } = useErrorState(formCtx);
  const fieldName = 'crm';
  const [newCrmOption, setNewCrmOption] = useState('');
  useEffect(() => {
    void init();
  }, []);

  const onAddNewOptionTap = () => {
    openModal({
      newValue: newCrmOption,
    });
  };

  const onInputChange = (newValue) => { };

  const postOnChange = (_formCtx, val, reason, details) => {
    if (reason === 'selectOption') {
      _formCtx.setValue('crm', val?.optionValue);
    }
    if (reason === 'selectOption' && val?.inputValue) {
      setNewCrmOption(val?.optionValue);
      onAddNewOptionTap();
    }
  };

  return (
    <>
      <FormControl variant='standard' fullWidth error={ isError(fieldName!)}>
        <HookSingleAsyncAutocomplete
          name={fieldName!}
          label="CRM"
          postOnChange={postOnChange}

          extraProps={{
            filterOptions: (opts, params) => {
              const filtered = filter(opts, params);

              const { inputValue } = params;
              // Suggest the creation of a new value
              const isExisting = options.some((option) => inputValue === option.optionValue);
              if (inputValue !== '' && !isExisting) {
                filtered.push({
                  key: inputValue,
                  optionValue: inputValue,
                  optionText: `Add "${inputValue}"`,
                  inputValue: inputValue,
                });
              }

              return filtered;
            },
            selectOnFocus: true,
            clearOnBlur: true,
            handleHomeEndKeys: true,
            getOptionLabel: (option) => {
            // Value selected with enter, right from the input
              if (typeof option === 'string') {
                return option;
              }
              // Add "xxx" option created dynamically
              if (option.inputValue) {
                return option.inputValue;
              }
              // Regular option
              return option.optionText;
            },
            freeSolo: true,
          }}
          dropdownOptions={options}
          placeholder={''}
          onInputChange={onInputChange}
        />
      </FormControl>
      <CreateCrmOptionConfirmationModal newValue={newCrmOption} />
    </>

  );
}
