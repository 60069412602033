import { Grid } from '@mui/material';
import PageHeader from 'components/SectionHeaders/PageHeader';
import RebookServiceNotificationsForm from './RebookServiceNotificationsForm';

export default function RebookServiceNotifications() {
  return (
    <Grid container spacing={2} className='pageGridContainer'>
      <Grid item xs={12}>
        <PageHeader headerText={'Rebook Service Notifications'} />
      </Grid>
      <Grid item xs={12}>
        <RebookServiceNotificationsForm/>
      </Grid>
    </Grid>
  );
}