import Axios, { AxiosResponse } from 'axios';
import { IEditServiceAllocationsRequest } from 'model/customerUpload';
import { IStreetFairSearchRequest, ISearchPage } from 'model/search';
import { IApiKeyDTO, IApproveForSapDTO, IClaimYourBusinessRequestDTO, IInternalServiceProvider, IServiceProvider, IServiceSummaryUpdateRequest } from 'model/serviceProvider';

const apiUrl = window.REACT_APP_BASE_API_URI;
export function findAllServiceProviders() {
  return Axios.get(`${apiUrl}/serviceProvider/v2/findAll`);
}
export function historicalSearchSync() {
  return Axios.get(`${apiUrl}/serviceProvider/v2/historicalSearchSync`);
}

export function manuallySendDailyReminders() {
  return Axios.get(`${apiUrl}/serviceProvider/v2/scheduling/manuallySendDailyReminders`);
}


export function searchServiceProviders(request:IStreetFairSearchRequest):Promise<AxiosResponse<ISearchPage<IServiceProvider>>> {
  return Axios.post(`${apiUrl}/serviceProvider/v2/searchServiceProviders`, request);
}

export function findServiceProviderById(providerId) {
  return Axios.get(`${apiUrl}/serviceProvider/v2/public/find/${providerId}`);
}

export function findInternalServiceProviderById(providerId:string):Promise<AxiosResponse<IInternalServiceProvider>> {
  return Axios.get(`${apiUrl}/serviceProvider/v2/find/internal/${providerId}`);
}

export function saveServiceProvider(data:IInternalServiceProvider) {
  if (data.id) {
    return Axios.put(`${apiUrl}/serviceProvider/v2/update/${data.id}`, data);
  }
  return Axios.post(`${apiUrl}/serviceProvider/v2/create`, data);
}

export function updateServiceOfferingAllocations(serviceProviderId:string, data:IEditServiceAllocationsRequest):Promise<AxiosResponse<void>> {
  return Axios.post(`${apiUrl}/serviceProvider/v2/${serviceProviderId}/updateServiceOfferingAllocations`, data);
}

export function saveServiceProviderImage(data:FormData) {
  return Axios.post(`${apiUrl}/serviceProvider/v2/saveServiceProviderImage`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function deleteServiceProvider(id:string) {
  return Axios.delete(`${apiUrl}/serviceProvider/v2/delete/${id}`);
}

export function sendUncontactedCustomerReminders() {
  return Axios.get(`${apiUrl}/serviceProvider/v2/public/scheduling/sendUncontactedCustomerReminders`);
}

export function sendContactedCustomerReminders() {
  return Axios.get(`${apiUrl}/serviceProvider/v2/public/scheduling/sendContactedCustomerReminders`);
}

export function sendNeedsCompletedCustomerReminders() {
  return Axios.get(`${apiUrl}/serviceProvider/v2/public/scheduling/sendConfirmedCustomerReminders`);
}

export function createApiKey(serviceProviderId:string):Promise<AxiosResponse<IApiKeyDTO>> {
  return Axios.post(`${apiUrl}/serviceProvider/v2/${serviceProviderId}/createApiKey`);
}

export function getServiceProviderKey(serviceProviderId:string):Promise<AxiosResponse<string>> {
  return Axios.get(`${apiUrl}/serviceProvider/v2/${serviceProviderId}/getServiceProviderKey`);
}

export function sendClaimYourBusinessMessage(serviceProviderId:string, data:IClaimYourBusinessRequestDTO) {
  return Axios.post(`${apiUrl}/serviceProvider/v2/${serviceProviderId}/sendClaimYourBusinessMessage`, data);
}

export function approveProviderForSap(serviceProviderId:string, data:IApproveForSapDTO) {
  return Axios.post(`${apiUrl}/serviceProvider/v2/${serviceProviderId}/approveForAcquisitionPlaybook`, data);
}

export function maybeSendReferringIndividualProviderLaunchedEmail(serviceProviderId:string) {
  return Axios.post(`${apiUrl}/serviceProvider/v2/maybeSendReferringIndividualProviderLaunchedEmail/${serviceProviderId}`);
}

export function updateServiceSummaries(serviceProviderId:string, data: IServiceSummaryUpdateRequest) {
  return Axios.post(`${apiUrl}/serviceProvider/v2/updateServiceSummaries/${serviceProviderId}`, data);
}