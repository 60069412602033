import CompanyImageDropzone from './CompanyImageDropzone';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Loading, { useLoading } from 'components/Layout/Loading';
import ProviderLogo from 'components/visual/ProviderLogo';
import useToast from 'components/toast/useToast';
import styles from '../providerOnboarding.module.css';
import {
  Accordion, AccordionDetails, AccordionSummary, Button, Card, FormControl, Grid, InputLabel, Tooltip, Typography,
} from '@mui/material';
import { createValidateEmail, createValidateIntegerRange, createValidateNumber, createValidatePhone } from 'util/rhfValidateFunctions';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { HookCheckbox, HookIntegerField, HookPriceField, HookSelect, HookTextField } from 'components/reactHookForm';
import { IInternalServiceProvider, StatusType, createEmptyInternalServiceProviderWithDefaults } from 'model/serviceProvider';
import { PrimaryButton } from 'components/buttons';
import { saveServiceProvider } from 'api/serviceProviderApi';
import { ProviderSelect } from './ProviderSelect';
import { useNavigate, useParams } from 'react-router-dom';
import useProviderSetupStore from '../useProviderSetupStore';
import { useEffect } from 'react';
import LeadSourceSelect from './LeadSourceSelect';
import NeighborhoodAutocomplete from 'components/neighborhoodAutocomplete/NeighborhoodAutocomplete';
import ContactInformation from './ContactInformation';
import AddressInformation from './AddressInformation';
import RatingInformation from './RatingInformation';
import { IDropdownOption } from 'model/dropdown';
import useMarkets from 'hooks/useMarkets';
import MarketSelect from './MarketSelect';
import ServiceProviderCrmCreatableAutocomplete from 'components/serviceProviderCrmCreatableAutocomplete/ServiceProviderCrmCreatableAutocomplete';
import NumbersOnDoNotCallListAlertModal, { modalStoreKey } from 'components/ServiceProviderModals/NumbersOnDoNotCallListAlertModal';
import useModal from 'components/modal/useModal';
import HookPercentField from 'components/reactHookForm/HookPercentField';
import { formatServiceProviderForForm } from 'util/serviceProviderUtil';
import { convertFormattedPercentage } from 'util/numberUtil';

const loadingKey = 'ProviderOnboardingProviderInformationForm';
const validateQualitativeScore = createValidateIntegerRange(1, 10);

export default function ProviderInformationForm() {
  const { provider, setProvider, init, maybeCheckNumbersAgainstDNCList } = useProviderSetupStore();
  const { market } = useMarkets();
  const { providerId } = useParams();
  const navigate = useNavigate();
  const { onLoading, doneLoading } = useLoading(loadingKey);
  const { createErrorToast, createWarnToast } = useToast();
  const defaultValues = provider.id ? provider : createEmptyInternalServiceProviderWithDefaults(market);
  const formattedDefaultValues = formatServiceProviderForForm(defaultValues);

  let formContext = useForm<IInternalServiceProvider>({
    mode: 'onTouched', defaultValues: formattedDefaultValues,
  });
  const { openModal } = useModal(modalStoreKey);


  useEffect(() => {
    void init(market);
  }, [providerId, market]);

  useEffect(() => {
    formContext.reset(formattedDefaultValues);
  }, [provider]);

  const watchImageUrlGuid = formContext.watch('imageUrlGuid');
  const watchReferringNeighborhoodId = formContext.watch('referringNeighborhoodId');

  async function _saveServiceProvider(data:any) {
    // add in default values that are not in the form but are wiped by formContext
    const dataToSave = { ...defaultValues, ...data };
    if (dataToSave.contactPersonPhone) {
      dataToSave.smsNotificationEnabled = true;
    }
    if (dataToSave.recurringPercent) {
      dataToSave.recurringPercent = convertFormattedPercentage(dataToSave.recurringPercent);
    }
    if (dataToSave.oneTimePercent) {
      dataToSave.oneTimePercent = convertFormattedPercentage(dataToSave.oneTimePercent);
    }
    return saveServiceProvider(dataToSave);
  }

  async function _completeSave(data:any) {
    const providerData = await _saveServiceProvider(data);
    await setProvider(providerData?.data);
    navigate(`/providerOnboarding/${providerData?.data.id}/service-offerings`);
  }

  const onSubmit : SubmitHandler<IInternalServiceProvider> = async data => {
    try {
      onLoading();
      var invalidNumbersResp = await maybeCheckNumbersAgainstDNCList(data)
        .catch((_e) => {
          createWarnToast('Failed to check numbers against DNC list. Saving without checking.');
          return [];
        });
      if (invalidNumbersResp && invalidNumbersResp.length > 0) {
        openModal({
          flaggedNumbers: invalidNumbersResp,
          providerName: data.name,
          onConfirmCallback: async () => {
            await _completeSave(data);
          },
        });
      } else {
        await _completeSave(data);
      }
    } catch (error:any) {
      createErrorToast('Error creating provider: ', error.message);
      console.log('error creating provider:', { error, data });
    }
    doneLoading();
  };

  return (
    <Loading loadingKey={loadingKey}>
      <FormProvider {...formContext}>
        <form onSubmit={formContext.handleSubmit(onSubmit)}>
          <Grid container item spacing={1} sx={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <Grid container item xs={11} sx={{ flexDirection: 'row', marginTop: '50px', justifyContent: 'space-evenly' }}>
              <Grid item xs={6}>
                <Typography variant='body1'>Select a provider from the dropdown to pick up where you left off, or start from scratch by using the form below.</Typography>
              </Grid>
              <Grid item xs={4}>
                <ProviderSelect />
              </Grid>
            </Grid>
            <Card sx={{ width: '80%', paddingLeft: '50px', paddingBottom: '50px', marginTop: '50px' }}>
              <Typography variant='h6' className={styles.cardTitle}>Provider Information</Typography>
              <Grid container item spacing={1} sx={{ flexDirection: 'row' }}>
                <Grid item xs={4}>
                  <HookTextField
                    label='Provider name'
                    name='name'
                    required
                  />
                </Grid>
                <Grid item xs={3}>
                  <HookTextField
                    label='Website URL'
                    name='websiteUrl'
                  />
                </Grid>
              </Grid>
              <Grid container item spacing={1} xs={12}>
                <Grid item xs={6}>
                  <HookTextField
                    label='About The Provider'
                    name='aboutUs'
                    multiline
                    minRows={1}
                    maxRows={10}
                  />
                </Grid>
              </Grid>
              <Grid container sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
                <Grid item xs={6}>
                  <InputLabel>Logo</InputLabel>
                  <CompanyImageDropzone inputBoxColumnSize={10}/>
                </Grid>
                <Grid container item xs={12}>
                  {watchImageUrlGuid && (
                    <>
                      <ProviderLogo imageUrlGuid={watchImageUrlGuid}
                        style={{
                          maxHeight: '65px',
                          maxWidth: '250px',
                        }}
                      />
                      <Button type='button' onClick={() => formContext.setValue('imageUrlGuid', '')}>Clear image</Button>
                    </>
                  )}
                </Grid>
              </Grid>
            </Card>
            <Accordion sx={{ width: '80%', paddingLeft: '50px', marginTop: '50px', borderRadius: '4px' }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant='h6' className={styles.accordionTitle}>Additional Details</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container marginBottom='25px' direction='row' alignItems='flex-end'>
                  <Grid item xs={2}>
                    <FormControl variant='standard' fullWidth>
                      <InputLabel htmlFor="status-type">Status</InputLabel>
                      <HookSelect
                        control={formContext.control}
                        rules={{
                          required: { value: true, message: 'Required' },
                        }}
                        name='status'
                        id='status-type'
                        menuItems={StatusType.getListIncludingStatus(defaultValues.status)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} md='auto' marginLeft={5}>
                    <HookCheckbox
                      name='hipFireProvider'
                      label='Hip Fire Provider?'
                    />
                  </Grid>
                  <Grid item xs={4} marginLeft={5}>
                    <LeadSourceSelect />
                  </Grid>
                </Grid>
                <Grid container item spacing={1}>
                  <Grid item xs={4}>
                    <HookTextField
                      label='Referring Person Name'
                      name='referringPersonName'
                      validationRules={{
                        required: false,
                      }} />
                  </Grid>
                  <Grid item xs={4}>
                    <NeighborhoodAutocomplete
                      label='Referring Neighborhood'
                      fieldName='referringNeighborhood'
                      placeholder='Referring Neighborhood'
                      formless={false}
                      required={false}
                      controlledValue={watchReferringNeighborhoodId ?? ''}
                      postOnChange={async (_formCtx: any, selectedOption: IDropdownOption | null) => {
                        if (selectedOption?.optionValue) {
                          _formCtx.setValue('referringNeighborhoodId', selectedOption.optionValue);
                        }
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={3} marginBottom={'25px'}>
                    <MarketSelect />
                  </Grid>
                  <Grid item xs={2} marginLeft={5}>
                    <HookTextField
                      name='companyHubspotId'
                      label='Hubspot ID'
                      required={false}
                    />
                  </Grid>
                  <Grid item xs={2} marginLeft={5}>
                    <HookTextField
                      name='supplyBatchNumber'
                      label='Supply Batch Number'
                      required={false}
                    />
                  </Grid>
                  <Grid container item xs={12} spacing={1}>
                    <Grid item xs={6} sm={3} md={2} lg={2}>
                      <HookIntegerField
                        name='supplyQualitativeScore'
                        label='Stoke Meter'
                        validationRules={{
                          min: { value: 1 || false, message: 'Minimum value of 1' },
                          max: { value: 10, message: 'Maximum value of 10' },
                          validate: validateQualitativeScore,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <ServiceProviderCrmCreatableAutocomplete />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant='body1' fontWeight={500}>Terms and Conditions</Typography>
                    </Grid>
                    <Grid container item spacing={1}>
                      <Grid item >
                        <HookPercentField
                          name='oneTimePercent'
                          label='One-Time Percent'
                          maxLength={5}
                        />
                      </Grid>
                      <Grid item >
                        <HookPercentField
                          name='recurringPercent'
                          label='Recurring Percent'
                        />
                      </Grid>
                      <Grid item>
                        <HookPriceField
                          name='takeRateCap'
                          label='Take Rate Cap'
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <ContactInformation />
                <AddressInformation />
                <RatingInformation />
              </AccordionDetails>
            </Accordion>
            <Grid item xs={12} md={3}>
              <PrimaryButton type='submit' sx={{ marginTop: '40px' }}>Save & Continue</PrimaryButton>
            </Grid>
          </Grid>
        </form>
        <NumbersOnDoNotCallListAlertModal />
        <Loading loadingKey={loadingKey}/>
      </FormProvider>
    </Loading>
  );
}