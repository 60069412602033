import get from 'lodash/get';

export function recursiveSetData(currentPropertyPath: string, values: any, setValue:Function, isInit?:boolean) {
  for (const key in values) {
    let val = get(values, key);
    if (val === undefined) {
      throw new Error(`Could not find property ${key} in ${values}`);
    }

    if (typeof(val) === 'object' && Object.keys(values).length > 0) {
      recursiveSetData(`${currentPropertyPath}.${key}`, val, setValue);
    }
    setValue(`${currentPropertyPath}.${key}`, val);
  }
}


export function setFormData<T>(values: T, setValue:Function, isInit?:boolean) {
  for (const p in values) {
    let propertyVal = get(values, p);
    if (typeof(propertyVal) === 'object' && Object.keys(p).length > 0) {
      recursiveSetData(p, propertyVal, setValue, isInit);
    } else {
      setValue(`${p}`, propertyVal);
    }
  }
}