
import NeighborhoodAutocomplete from 'components/neighborhoodAutocomplete/NeighborhoodAutocomplete';
import { useFormContext } from 'react-hook-form';

export interface INeighborhoodSelectProps {
  marketId: string | null;
}

export default function NeighborhoodSelect({
  marketId,
}: INeighborhoodSelectProps) {
  const formCtx = useFormContext();

  return (
    <NeighborhoodAutocomplete
      label='Neighborhood'
      fieldName='neighborhoodId'
      placeholder='Search and check a neighborhood'
      controlledValue={formCtx.getValues().neighborhoodId ?? ''}
      formless={false}
      required={true}
      optionDescriptionFunc= {(x) => {
        if (x.zipCodeTerritory) {
          return `${x.name} ${x.zipCodeTerritory}`;
        }
        if (!x.zipCodes || x.zipCodes.length === 0) {
          return `${x.name}`;
        }
        return `${x.name} [zip(s): ${x.zipCodes.join(',')}]`;
      }}
      marketOverride={!marketId ? 'skip' : `${marketId}`}
    />
  );
}
