import { InputLabel, FormControl, FormHelperText } from '@mui/material';
import { findAllIndividuals } from 'api/individualApi';
import HookMultiselect from 'components/reactHookForm/HookMultiselect';
import useErrorState from 'hooks/reactHookForm/useErrorState';
import { useState, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { sortByName } from '../userDetails';

export function IndividualsSelect() {
  const formCtx = useFormContext();
  const watched = useWatch({ name: 'individuals' });
  const { userId } = useParams();
  const [individuals, setIndividuals] = useState<any[]>([]);
  useEffect(() => {
    async function getIndividuals() {
      if (userId) {
        const _individuals = await findAllIndividuals();
        const sortedIndividuals = _individuals.data.sort(sortByName);
        setIndividuals(sortedIndividuals);
      }
    }
    // void getIndividuals();
  }, []);
  const { isError, getErrorMessage } = useErrorState(formCtx);
  return (
    <FormControl variant='standard' fullWidth error={isError('individuals')}>
      <InputLabel htmlFor="individuals">Individuals</InputLabel>
      <HookMultiselect
        control={formCtx.control}
        name='individuals'
        id='individuals'
        menuItems={individuals}
        menuItemKeys={{
          key: 'id',
          value: 'id',
          displayValue: 'fullName',
        }} />
      <FormHelperText>{getErrorMessage('individuals')}</FormHelperText>
    </FormControl>
  );
}
