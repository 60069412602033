import { Grid } from '@mui/material';
import { saveDiscountSchedule, findDiscountScheduleById, deleteDiscountSchedule } from 'api/discountScheduleApi';
import { findNSOsByDiscountSchedule } from 'api/neighborhoodServiceOfferingApi';
import PrimarySecondaryButtonGroup from 'components/processButton/primarySecondaryButtonGroup';
import PageHeader from 'components/SectionHeaders/PageHeader';
import useDiscountScheduleForm from 'hooks/discountSchedule/useDiscountScheduleForm';
import {
  DiscountScheduleTypes,
  IDiscountSchedule,
} from 'model/discountSchedule';
import { INeighborhoodServiceOffering } from 'model/neighborhoodServiceOffering';
import { useState, useEffect } from 'react';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import styles from './discountScheduleDetails.module.css';
import { StepOne, StepTwo, StepThree, StepFive } from './formComponents';
import StepFour from './formComponents/StepFour';
import StepSix from './formComponents/StepSix';
import useToast from 'components/toast/useToast';

//Overview of component
//0. always collect priceAmount, priceLabel, priceSuffix, and name
//1.dropdown select discount schedule type
//2a. if $ per customer then show three fields for pricing schedule
//2b. if $ saved per customer then show discount schedule fields
//2c. if no discount then show no extra fields
//2d. if $ flat rate then show dollarsOff from flatRateSchedule
//2e. if % flat rate then show percentOff from flatRateSchedule

export default function DiscountScheduleDetails() {
  //react hook form
  const formContext = useForm<IDiscountSchedule>({ mode: 'onChange' });
  const navigate = useNavigate();
  const discountScheduleForm = useDiscountScheduleForm(null);
  const { serviceProviderId, discountScheduleId } = useParams();
  const [discountScheduleTypes] = useState<any[]>(DiscountScheduleTypes.list);
  const [nsosWithDiscountSchedule, setNsosWithDiscountSchedule] = useState<INeighborhoodServiceOffering[]>([]);
  const [tabValue, setTabValue] = useState(0);
  const { createInfoToast } = useToast();

  async function _createDiscountSchedule(data:IDiscountSchedule) {
    if (data.type === DiscountScheduleTypes.NoDiscount) {
      delete data.discountSchedule;
      delete data.pricingSchedule;
      delete data.flatRateSchedule;
    } else if (data.type === DiscountScheduleTypes.DollarPerCustomer) {
      delete data.discountSchedule;
      delete data.flatRateSchedule;
    } else if (data.type === DiscountScheduleTypes.DollarSavePerCustomer || data.type === DiscountScheduleTypes.PercentSavePerCustomer) {
      delete data.pricingSchedule;
      delete data.flatRateSchedule;
    } else if (data.type === DiscountScheduleTypes.DollarFlatRate) {
      delete data.pricingSchedule;
      delete data.discountSchedule;
    } else if (data.type === DiscountScheduleTypes.PercentFlatRate) {
      delete data.pricingSchedule;
      delete data.discountSchedule;
      data.flatRateSchedule!.percentOff = data.flatRateSchedule!.percentOff / 100.0;
    }
    if (serviceProviderId) {
      data.providerId = serviceProviderId;
    }
    if (data.hasPrice) {
      delete data.noPriceTextField;
    } else {
      delete data.priceAmount;
      delete data.priceLabel;
      delete data.priceSuffix;
      delete data.priceSubText;
      delete data.unitBasedPricingFlag;
    }

    await saveDiscountSchedule(data);
    navigate(-1);
  }


  const handlePriceTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
    if (newValue === 0) {
      formContext.setValue('hasPrice', true);
      createInfoToast('Price selected. The "No Price Text Field" will be cleared on save.');
    } else {
      formContext.setValue('hasPrice', false);
      createInfoToast('No price selected. Price fields will be cleared on save.');
    }
  };

  useEffect(() => {
    async function getDiscountSchedule() {
      if (discountScheduleId) {
        const response = await findDiscountScheduleById(discountScheduleId);
        if (response.data) {
          var modifiedData: any = response.data;
          if (modifiedData.flatRateSchedule && modifiedData.flatRateSchedule.percentOff) {
            modifiedData.flatRateSchedule.percentOff = (modifiedData?.flatRateSchedule?.percentOff * 100).toFixed(0).toString();
          } else if (modifiedData.flatRateSchedule && modifiedData.flatRateSchedule.dollarsOff) {
            modifiedData.flatRateSchedule.dollarsOff = modifiedData?.flatRateSchedule?.dollarsOff.toFixed(2).toString();
          }

          discountScheduleForm.setData(response.data, formContext.setValue);
          if (response.data.hasPrice) {
            setTabValue(0);
          } else {
            setTabValue(1);
          }
          const nsosWithDiscountScheduleRes = await findNSOsByDiscountSchedule(discountScheduleId);
          setNsosWithDiscountSchedule(nsosWithDiscountScheduleRes.data);
        }
      } else {
        // set defaults
        formContext.setValue('hasPrice', true);
      }
    }
    void getDiscountSchedule();
  }, []);
  const onSubmit : SubmitHandler<IDiscountSchedule> = data => {
    void _createDiscountSchedule(data);
  };
  return (
    <FormProvider {...formContext}>
      <form onSubmit={formContext.handleSubmit(onSubmit)}>
        <Grid container spacing={2} className='pageGridContainer'>
          <Grid item xs={12}>
            <PageHeader headerText={'Manage Discount Schedule'} />
          </Grid>
          <StepOne/>
          <StepTwo tab={tabValue} handleChange={handlePriceTabChange}/>
          <StepThree discountScheduleTypes={discountScheduleTypes}/>
          <StepFour neighborhoodServiceOfferings={nsosWithDiscountSchedule}/>
          <StepFive/>
          <StepSix/>
          <Grid item xs={12}>
            <PrimarySecondaryButtonGroup
              primaryButtonText={'Save'}
              primaryButtonAction={async () => {
                const result = await formContext.trigger();
              }}
              secondaryButtonText={'Cancel'}
              secondaryButtonAction={() => {navigate('/discount-schedules');}}
            />
            {/* {discountScheduleId && (
                        <Button onClick={_deleteDiscountSchedule}>Delete</Button>
                    )} */}
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
}