import PageHeader from 'components/SectionHeaders/PageHeader';
import IndividualsSearch from './IndividualsSearch';
import IndividualTabs from './IndividualTabs';
import useIndividualsHookStore from './individualsHookStore';
import { Grid } from '@mui/material';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

export default function IndividualDetails() {
  const { init } = useIndividualsHookStore();
  const { individualId } = useParams();
  useEffect(() => {
    void init();
  }, [individualId]);

  return (
    <Grid container spacing={2} className='pageGridContainer'>
      <Grid item xs={12}>
        <PageHeader headerText={'Individuals'} />
      </Grid>
      <Grid item xs={12}>
        <IndividualsSearch />
      </Grid>
      <Grid item xs={12}>
        <IndividualTabs />
      </Grid>
    </Grid>

  );
}