import { useLoading } from 'components/Layout/Loading';
import createStore from 'hooks/hookStore';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IDropdownOption } from 'model/dropdown';
import { IServiceProviderSearchRequest, ISearchPage, IStreetFairSearchRequest } from 'model/search';
import debounce from 'lodash.debounce';
import { searchServiceProviders } from 'api/serviceProviderApi';
import { IServiceProvider } from 'model/serviceProvider';
import useMarkets from 'hooks/useMarkets';

const loadingKey = 'ServiceProviderMultiAutocompleteStore';
type ServiceProviderMultiAutocompleteStore = {
  // options: IDropdownOption[];

}

const { get, update, registerListener, unregisterListener } = createStore<ServiceProviderMultiAutocompleteStore>('ServiceProviderMultiAutocompleteStore', {
  // options: [],

});

const debouncedSearchServiceProviders = debounce(
  async (dto:IStreetFairSearchRequest, callback:any) => {
    try {
      const res = await searchServiceProviders(dto);
      callback(res.data);
    } catch (err) {
      console.error(err);
    }

  }, 400);

export default function useServiceProviderMultiAutocomplete() {
  const setState = useState(get())[1];
  const navigate = useNavigate();
  const { onLoading, doneLoading } = useLoading(loadingKey);
  const { selectedMarket } = useMarkets();

  useEffect(() => {
    registerListener(setState);
    return () => {
      update({
        ...get(),
        options: [],
      });
      unregisterListener(setState);
    };
  }, []);

  async function initDropdownOptions(formContext:any, dropdownOptions: IDropdownOption[], setOptions) {
    setOptions(dropdownOptions);
  }

  async function initWithValue(formContext: any, fieldName: string, values:string[], setOptions) {
    onLoading();
    const dto:IServiceProviderSearchRequest = {
      query: '',
      ids: values,
      pageNumber: 0,
      pageSize: 100,
      offset: 0,
      marketId: selectedMarket?.id,
    };

    const res = await searchServiceProviders(dto);
    const page = res.data as ISearchPage<IServiceProvider>;
    setOptions(page.items
      .filter(x => x.id && x.name)
      .map((item) => {
        return {
          key: item.id!,
          optionText: item.name!,
          optionValue: item.id!,
          ancillary: item,
        };
      }));

    let selectedOptions = page.items
      .filter((item) => values.indexOf(item.id!) > -1)
      .map((item) => item.id!);

    if (selectedOptions !== null) {
      formContext.setValue(fieldName, selectedOptions);
    }
    doneLoading();
  }

  async function onSearchServiceProviders(
    search:string,
    currentSelected: string[],
    setOptions,
    options,
    statuses?: string[],
  ) {

    onLoading();
    const dto:IServiceProviderSearchRequest = {
      query: search,
      pageNumber: 0,
      pageSize: 100,
      offset: 0,
      ids: [],
      marketId: selectedMarket?.id,
      statuses: statuses,
    };
    var selectedOptions = getSelectedOptions(currentSelected, options);


    debouncedSearchServiceProviders(dto, (data) => {
      const page = data as ISearchPage<IServiceProvider>;
      let nextOptions = page.items
        .filter(x => x.id && x.name && (currentSelected === null || currentSelected.indexOf(x.id) === -1))
        .map((item) => {
          return {
            key: item.id!,
            optionText: item.name!,
            optionValue: item.id!,
            ancillary: item,
          };
        });
      setOptions([...selectedOptions, ...nextOptions]);
      update({
        ...get(),
        options: [...selectedOptions, ...nextOptions],
      });
      doneLoading();
    });

  }

  function getSelectedOptions(currentSelected:string[], options): IDropdownOption[] {
    return options.filter((item) => currentSelected.indexOf(item.optionValue) > -1);
  }


  return {
    ...get(),
    onSearchServiceProviders,
    initWithValue,
    initDropdownOptions,
  };
}