import { Grid, Typography } from '@mui/material';

import GenericModal from 'components/modal/GenericModal';
import useModal, { IModal } from 'components/modal/useModal';
import { CustomerRollbackType, IAuditCustomerUploadForm, createEmptyAuditCustomerUploadForm, createEmptyCustomerUploadDeleteByIdForm } from 'model/customerUpload';

export const modalStoreKey = 'confirmAuditModalKey';
export interface IConfirmAuditModal {
  formData: IAuditCustomerUploadForm;
  callback: (formData: IAuditCustomerUploadForm) => Promise<void>;
}
export function createEmptyConfirmAuditModal() :IConfirmAuditModal {
  return {
    formData: createEmptyAuditCustomerUploadForm(),
    callback: async (formData: IAuditCustomerUploadForm) => {},
  };
}
export interface IConfirmAuditModalProps {

}

export default function ConfirmAuditModal({ }:IConfirmAuditModalProps) {
  const { getModalState, closeModal } = useModal(modalStoreKey);
  const _state = getModalState() as IConfirmAuditModal & IModal;
  const { formData, callback } = _state;
  if (!formData) {
    return null;
  }
  async function _onConfirm(state:IConfirmAuditModal & IModal) {
    await callback(state.formData);
    closeModal(createEmptyAuditCustomerUploadForm());
  }


  return (
    <GenericModal
      modalStoreKey={modalStoreKey}
      title={'Confirm Audit Customer Upload'}
      modalContent={
        (
          <div >
            <Typography variant='body1' sx={{ paddingBottom: '10px' }}>You will receive an email with the results</Typography>
            {Content(formData)}

          </div>
        )
      }
      primaryDisabled={!formData.auditAllServiceProviders && !formData.selectedServiceProvider}
      onConfirm={() => _onConfirm(_state)}
      secondaryButtonText='Cancel'
    />
  );
}
function Content(formData: IAuditCustomerUploadForm) {
  if (!formData.auditAllServiceProviders && !formData.selectedServiceProvider) {
    return (
      <Typography variant='body1' sx={{ color: 'red' }}>Select a service provider or check the "Run for all" checbox</Typography>
    );
  }
  if (formData.auditAllServiceProviders) {
    return (
      <Typography variant='body1' sx={{ color: 'darkorange' }}>Audit customer upload for all service providers. {formData.selectedServiceProvider && 'Uncheck to run for selected service provider.'}</Typography>
    );
  }
  return (
    <Typography variant='body1'>Audit customer upload for service provider: {formData.selectedServiceProvider?.optionText}</Typography>
  );
}