import createStore from 'hooks/hookStore';
import useToast from 'components/toast/useToast';
import { useLoading } from 'components/Layout/Loading';
import { useEffect, useState } from 'react';
import { IMarket } from 'model/markets';
import { sortByString } from 'util/sortFunctions';
import { DateRange } from '@mui/x-date-pickers-pro';
import { useNavigate } from 'react-router-dom';
import { ICreateTsoiManagementBatchRequest } from 'model/tsoiManagementBatch';
import { createTsoiManagementBatch } from 'api/tsoiManagementApi';
import { IDropdownOption } from 'model/dropdown';
import { ITerritory } from 'model/territories';
import { findTerritoriesByMarket } from 'api/territoriesApi';

const loadingKey = 'TsoiManagementNewBatchStore';
type TsoiManagementNewBatchStore = {
  dateRange: DateRange<Date|null>;
  market: IMarket | null;
  selectedTerritory: IDropdownOption | string;
  availableTerritories: ITerritory[];
}

const createEmpty = ():TsoiManagementNewBatchStore => ({
  dateRange: [null, null],
  market: null,
  selectedTerritory: '',
  availableTerritories: [],
});

const { get, update, registerListener, unregisterListener } = createStore<TsoiManagementNewBatchStore>('TsoiManagementNewBatchStore', createEmpty());

export default function useTsoiManagementNewBatch() {
  const setState = useState(get())[1];
  const { onLoading, doneLoading } = useLoading(loadingKey);
  const { createErrorToast } = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    registerListener(setState);
    return () => {
      unregisterListener(setState);
    };
  }, []);


  async function init(market:IMarket | null) {
    if (market == null) {
      console.log('waiting to load market');
      return;
    }
    onLoading();

    const availableTerritoriesResp = await findTerritoriesByMarket(market.id);

    try {
      update({
        ...createEmpty(),
        availableTerritories: availableTerritoriesResp.data,
        market: market,
      });
    } catch (e:any) {
      const errorMessage = e.response?.data?.message ?? 'Error loading territories';
      createErrorToast(`Error loading page: ${errorMessage}`);
      console.error(e);
    }
    doneLoading(300);
  }

  function setDateRange(dateRange:DateRange<Date|null>) {
    update({
      ...get(),
      dateRange: dateRange,
    });
  }

  function setSelectedTerritory(selectedTerritory:IDropdownOption | string) {
    update({
      ...get(),
      selectedTerritory: selectedTerritory,
    });
  }

  function clear() {
    update({
      ...get(),
      dateRange: [null, null],
      market: null,
    });
  }

  async function onSave() {
    onLoading();
    try {
      if (get().dateRange[0] == null || get().dateRange[1] == null) {
        createErrorToast('Please select a start and end date.');
        return;
      }

      if (get().market == null) {
        createErrorToast('Error determining market. Please refresh the page and try again.');
        return;
      }

      if (!get().selectedTerritory) {
        createErrorToast('Please select a territory.');
        return;
      }

      const request: ICreateTsoiManagementBatchRequest = {
        startDate: get().dateRange[0] as Date,
        endDate: get().dateRange[1] as Date,
        marketId: get().market?.id as string,
      };

      const resp = await createTsoiManagementBatch(request);
      if (!resp.data?.territories && Object.keys(resp.data?.territories).length === 0) {
        createErrorToast('Error saving batch, please try again. If the issue persists then please contact the dev team.');
        return;
      }

      // Start the user off with the first territory
      const territory = get().selectedTerritory as IDropdownOption;
      let selectedTerritoryId = territory.ancillary?.id;
      navigate(`/tsoiManagementBatches/${resp.data.id}/territory/${selectedTerritoryId}/serviceTypes`);
    } catch (e:any) {
      createErrorToast('Error saving batch');
      console.error(e);
    }
    doneLoading(300);
  }

  function onCancel() {
    clear();
    navigate('/tsoiManagementBatches');
  }

  return {
    ...get(),
    loadingKey,
    init,
    setDateRange,
    setSelectedTerritory,
    onSave,
    onCancel,
  };
}