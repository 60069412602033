import { IDropdownOption } from './dropdown';
import { IServiceOffering } from './serviceOffering';
import { IServiceOfferingAllocationDTO } from './serviceProvider';

export interface ICustomerUploadForm {
  fileName?:string;
  customersTemplate: any;
  shouldSetProviderUploaded: boolean;
}

export interface IEditServiceAllocationsForm {
  selectedServiceProvider:IDropdownOption | null;
  serviceOfferingAllocations: { [key: string]: IServiceOfferingAllocation[] };
}

export interface IEditServiceAllocationsRequest {
  requestIdToServiceOfferingAllocationMap?: { [key: string]: IServiceOfferingAllocationDTO[] };
}

export function createEmptyEditServiceAllocationsForm():IEditServiceAllocationsForm {
  return {
    selectedServiceProvider: null,
    serviceOfferingAllocations: {},
  };
}

export interface ICustomerUploadFormV2 {
  selectedServiceProvider:IDropdownOption | null;
  fileName?:string;
  customersTemplate: any;
  shouldSetProviderUploaded: boolean;
  serviceOfferingAllocations: IServiceOfferingAllocation[];
}

//UI friendly view of a service offering allocation
export interface IServiceOfferingAllocation {
  serviceOfferingId:string;
  name: string;
  serviceCategory:string;
  serviceType:string;
  serviceTypeId:string;
  percent:number;
}

export function createServiceOfferingAllocation(x: IServiceOffering):IServiceOfferingAllocation {
  return {
    serviceOfferingId: x.id!,
    name: x.name,
    serviceCategory: x.serviceCategory,
    serviceType: x.serviceType,
    serviceTypeId: x.serviceTypeId,
    percent: 0.0,
  };
}

export interface IProcessStagedCustomersForm {
  selectedNeighborhood:IDropdownOption | null;
  selectedServiceProvider:IDropdownOption | null;
}
export interface ICustomerUploadRequestDto {
  neighborhoodId:string;
  serviceProviderId?:string;
  zipCode?:string;
}
export interface ICustomerUploadDeleteForm {
  requestId:string;
}

export interface ICustomerUploadDeleteFormV2 {
  processingId?:string;
  requestId?:string;
}

export interface ICustomerUploadDeleteByIdForm {
  selectedNeighborhood:IDropdownOption | null;
  selectedServiceProvider:IDropdownOption | null;
  requestId:string;
  processingId:string;
  customerRollbackType: string;
  shouldRollbackStagingCustomers: boolean;
}

export interface IAuditCustomerUploadForm {
  selectedServiceProvider:IDropdownOption | null;
  auditAllServiceProviders: boolean;
}

export interface IAuditCustomerUploadRequestDto {
  serviceProviderId:string | null;
  auditAllServiceProviders: boolean;
}

export interface IReconcileCustomerUploadForm {
  selectedServiceProvider:IDropdownOption | null;
  allServiceProviders:boolean;
}

export interface IReconcileCustomerUploadRequestDto {
  serviceProviderId:string | null;
  allServiceProviders:boolean;
}

export function createEmptyCustomerUploadDeleteByIdForm():ICustomerUploadDeleteByIdForm {
  return {
    selectedNeighborhood: null,
    selectedServiceProvider: null,
    requestId: '',
    processingId: '',
    customerRollbackType: '',
    shouldRollbackStagingCustomers: true,
  };
}

export function createEmptyAuditCustomerUploadForm():IAuditCustomerUploadForm {
  return {
    selectedServiceProvider: null,
    auditAllServiceProviders: false,
  };
}

export function createEmptyReconcileCustomerUploadForm():IReconcileCustomerUploadForm {
  return {
    selectedServiceProvider: null,
    allServiceProviders: false,
  };
}

export interface IRollbackUploadedCustomersRequestDto {
  neighborhoodId:string | null;
  serviceProviderId:string | null;
  requestId:string | null;
  processingId:string | null;
  shouldRollbackStagingCustomers:boolean;
  customerRollbackType:string;
}

export class CustomerRollbackType {
  static get BY_NEIGHBORHOOD():string {
    return 'BY_NEIGHBORHOOD';
  }
  static get BY_SERVICE_PROVIDER():string {
    return 'BY_SERVICE_PROVIDER';
  }
  static get BY_REQUEST_ID():string {
    return 'BY_REQUEST_ID';
  }
  static get BY_PROCESSING_ID():string {
    return 'BY_PROCESSING_ID';
  }

  static list = [
    { id: CustomerRollbackType.BY_NEIGHBORHOOD, name: 'Rollback by neighborhood' },
    { id: CustomerRollbackType.BY_SERVICE_PROVIDER, name: 'Rollback by service provider' },
    { id: CustomerRollbackType.BY_REQUEST_ID, name: 'Rollback by request id' },
    { id: CustomerRollbackType.BY_PROCESSING_ID, name: 'Rollback by processing id' },
  ];


}