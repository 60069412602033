import React, { useState } from 'react';
import { Drawer, Checkbox, FormControlLabel, Button, Select, MenuItem, Grid, IconButton, Typography } from '@mui/material';
import ServiceProviderAutocomplete from 'components/serviceProviderAutocomplete/ServiceProviderAutocomplete';
import { FormProvider, useForm } from 'react-hook-form';
import useAddProviderTemplateDrawer from './useAddProviderDrawer';
import Loading from 'components/Layout/Loading';
import { PrimaryButton } from 'components/buttons';
import { Close } from '@mui/icons-material';
import useTerritorySeedingTemplates from '../hookStore/useTerritorySeedingTemplates';
import SeedFactorSelect from './SeedFactorselect';

interface ServiceDrawerProps {
  open: boolean;
  onClose: () => void;
}

interface IAddProviderForm {
  serviceProviderId: string;
  serviceOfferings: string[];
  seedFactor: number;
}

const AddProviderDrawer: React.FC<ServiceDrawerProps> = ({ open, onClose }) => {
  const {
    onProviderSelect,
    onSave,
    loadingKey,
    availableServiceOfferingsMap,
    toggleServiceOfferingCheckbox,
    clearData,
    onServiceOfferingSeedFactorChange,
  } = useAddProviderTemplateDrawer();
  const { selectedTerritory, refreshTemplatesForCurrentTerritory } = useTerritorySeedingTemplates();
  const availableServiceOffersingArray = Object.values(availableServiceOfferingsMap);
  const checkedServiceOfferings = availableServiceOffersingArray.filter((serviceOffering) => serviceOffering.checked);
  const serviceOfferingsWithSeedFactor = checkedServiceOfferings.filter((serviceOffering) => !!serviceOffering.seedFactor);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    toggleServiceOfferingCheckbox(event.target.value);
  };

  const formContext = useForm<IAddProviderForm>({
    mode: 'onChange',
    defaultValues: {
      serviceProviderId: '',
      serviceOfferings: [],
    },
  });

  const handleSave = async () => {
    await onSave(selectedTerritory, formContext.getValues());
    await refreshTemplatesForCurrentTerritory();
    formContext.reset();
    onClose();
  };

  const getServiceOfferingOptions = () => availableServiceOffersingArray.map(
    (serviceOffering) => {
      const postOnChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        return onServiceOfferingSeedFactorChange(serviceOffering.id, event.target.value as number);
      };
      return (
        <Grid container item flexDirection={'row'} alignItems={'flex-end'} >
          <Grid item xs={8}>
            <FormControlLabel
              key={serviceOffering.key}
              control={
                <Checkbox
                  value={serviceOffering.id}
                  checked={serviceOffering.checked}
                  onChange={handleCheckboxChange}
                  name={serviceOffering.label}
                />
              }
              label={serviceOffering.label}
            />
          </Grid>
          <Grid item xs={4}>
            <SeedFactorSelect serviceOfferingId={serviceOffering.id} postOnChange={postOnChange}/>
          </Grid>
        </Grid>
      );
    },
  );

  const handleClose = () => {
    clearData();
    formContext.reset();
    onClose();
  };

  return (
    <Drawer anchor="right" open={open} onClose={handleClose}>
      <FormProvider {...formContext}>
        <form onSubmit={formContext.handleSubmit(handleSave)}>
          <Grid container item >
            <Grid container item direction='row' justifyContent='flex-end'>
              <Grid item>
                <IconButton onClick={onClose}>
                  <Close />
                </IconButton>
              </Grid>
            </Grid>
            <div style={{ padding: '16px', width: '400px' }}>
              <Grid item xs={12} paddingTop="50px" paddingBottom="25px">
                <ServiceProviderAutocomplete
                  fieldName='serviceProviderId'
                  label={'Service Providers'}
                  formless={false}
                  placeholder={'Search and check service providers'}
                  required={false}
                  postOnChange={async (_formCtx, values, reason, details) => {
                    if (reason === 'selectOption') {
                      await onProviderSelect(details.option);
                    }
                  }}
                />
              </Grid>
              {availableServiceOffersingArray.length > 0 && (<>
                <Loading loadingKey={loadingKey}>
                  <Grid container item rowSpacing={2}>
                    <Typography variant='h6'>Which Service Offerings?</Typography>
                    { getServiceOfferingOptions()}
                    <Grid item xs={12}>
                      <PrimaryButton
                        disabled={!(checkedServiceOfferings.length > 0) || checkedServiceOfferings.length !== serviceOfferingsWithSeedFactor.length}
                        type="submit"
                        variant="contained"
                        color="primary"
                        style={{ marginTop: '100px' }}
                      >
                         Save
                      </PrimaryButton>
                    </Grid>
                  </Grid>
                </Loading>
              </>)}
            </div>
          </Grid>
        </form>
      </FormProvider>
    </Drawer>
  );
};

export default AddProviderDrawer;
