import { FormControl, FormHelperText } from '@mui/material';
import HookAutocomplete from 'components/reactHookForm/HookAutocomplete';
import useErrorState from 'hooks/reactHookForm/useErrorState';
import get from 'lodash.get';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import useProviderCoverageArea from '../hookStore/useProviderCoverageArea';
import NeighborhoodAutocomplete from 'components/neighborhoodAutocomplete/NeighborhoodAutocomplete';
import { NeighborhoodStatusType } from 'model/neighborhood';
import NeighborhoodMultiAutocomplete from 'components/neighborhoodAutocomplete/NeighborhoodMultiAutocomplete';

export interface INeighborhoodMultiSelectProps {
  index:number;
}

export default function NeighborhoodMultiSelect({ index }:INeighborhoodMultiSelectProps) {
  const { neighborhoodOptions } = useProviderCoverageArea();
  const formCtx = useFormContext();
  const { isError, getErrorMessage } = useErrorState(formCtx);
  const [inputValue, setInputValue] = useState<string>('');
  let excludedNeighborhoodOptions = formCtx.getValues(`providerCoverageAreas.${index}.excludedNeighborhoodOptions`);
  return (
    <NeighborhoodMultiAutocomplete
      required={false}
      formless={false}
      initialOptions={excludedNeighborhoodOptions}
      fieldName={`providerCoverageAreas.${index}.excludedNeighborhoodIds`}
      label='Excluded Neighborhoods'
      placeholder='Search and check neighborhoods'
      statuses={[NeighborhoodStatusType.BETA, NeighborhoodStatusType.ACTIVE, NeighborhoodStatusType.LAUNCHED]}
      postOnChange={(formContext, value, reason, details, rawOptions) => {
        var existingOptions = formCtx.getValues(`providerCoverageAreas.${index}.excludedNeighborhoodOptions`);
        if ( reason === 'selectOption') {
          formCtx.setValue(`providerCoverageAreas.${index}.excludedNeighborhoodOptions`, [...existingOptions, ...rawOptions]);
        } else if (reason === 'removeOption') {
          formCtx.setValue(`providerCoverageAreas.${index}.excludedNeighborhoodOptions`, existingOptions.filter((option:any) => option.optionValue !== details.optionValue));
        } else if (reason === 'clear') {
          formCtx.setValue(`providerCoverageAreas.${index}.excludedNeighborhoodOptions`, []);
        }
      }}
      postOnInputChange={(formContext, value, reason, details) => {
        if (reason === 'reset') {
        } else {
          setInputValue(value ?? '');
        }
      }}
      extraProps={{
        clearOnBlur: false,
      }}
    />
  );
}