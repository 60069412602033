import Axios, { AxiosResponse } from 'axios';
import { IGeoCoordinates, INeighborhoodFromAddressRequest, INeighborhoodFromAddressResponse } from 'model/ancillary';


const apiUrl = window.REACT_APP_BASE_API_URI;
export function findNeighborhoodByCoordinates(data:IGeoCoordinates) {
  return Axios.post(`${apiUrl}/sfgapi/v2/public/findNeighborhoodByCoordinates`, data);
}

export function findBestMatchNeighborhoodByAddress(data:INeighborhoodFromAddressRequest):Promise<AxiosResponse<INeighborhoodFromAddressResponse>> {
  return Axios.post(`${apiUrl}/sfgapi/v2/public/findBestMatchNeighborhoodByAddress`, data);
}
