import { Button, Grid } from '@mui/material';
import { useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

export interface INeighborhoodImageProps{
  mobileView?:boolean;
}
export default function NeighborhoodImage({ mobileView }:INeighborhoodImageProps) {
  const [shown, setShown] = useState(true);
  const formCtx = useFormContext();
  const watchedImage = useWatch({ control: formCtx.control, name: 'neighborhoodImageGuid' });
  if (!watchedImage) {
    return <Grid container alignItems='center'>No image set</Grid>;
  }
  return (
    <Grid
      container
      alignItems='center'
    >
      <Grid item xs={12}>
        <Button onClick={() => {setShown(!shown);}}>Toggle preview</Button>
      </Grid>
      <Grid item xs>
        {shown && <img src={`${window.REACT_APP_NEIGHBORHOOD_IMAGE_URI}${watchedImage}`} alt='No image found' /> }
      </Grid>
    </Grid>
  );
}