import HelpIcon from '@mui/icons-material/Help';
import { IconButton, Tooltip } from '@mui/material';

interface ITooltipButtonProps {
  tooltipTitle:string;
  className?:string;
  disabled?:boolean;
}

export default function TooltipButton({ tooltipTitle, className, disabled }:ITooltipButtonProps) {
  return (
    <Tooltip title={tooltipTitle} className={className} >
      <IconButton disabled={disabled}>
        <HelpIcon/>
      </IconButton>
    </Tooltip>
  );
}