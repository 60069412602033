import { Button } from '@mui/material';
import useModal from 'components/modal/useModal';
import { IInvoiceCustomerRowDTO } from 'model/invoicing';
import { IGenerateInvoiceModal, modalStoreKey } from './GenerateInvoiceModal';

export interface IGenerateInvoiceButtonProps {
  selectedCustomers:IInvoiceCustomerRowDTO[];
  selectedYear:number;
  selectedMonth: number;
  autoFinalizeIfPossible: boolean;
}
export default function GenerateInvoiceButton({ selectedCustomers, selectedYear, selectedMonth, autoFinalizeIfPossible }:IGenerateInvoiceButtonProps) {

  const { openModal } = useModal(modalStoreKey);
  const state:IGenerateInvoiceModal = {
    selectedRows: selectedCustomers,
    name: localStorage.getItem('invoices.selectedServiceProviderName') || '',
    memo: `${String(selectedMonth).padStart(2, '0')}/${selectedYear}`,
    autoFinalizeIfPossible,
  };
  return (
    <Button
      variant='contained'
      type='button'
      onClick={() => openModal({ ...state })}
      disabled={!selectedCustomers || selectedCustomers.length === 0}
    >
      <>Generate invoice for selected</>
    </Button>
  );
}