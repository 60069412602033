import { FormControl, FormHelperText } from '@mui/material';
import { HookSingleAutocomplete } from 'components/reactHookForm';
import HookAutocomplete from 'components/reactHookForm/HookAutocomplete';
import useErrorState from 'hooks/reactHookForm/useErrorState';
import get from 'lodash.get';
import { UNSELECTED_OPTION } from 'model/dropdown';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import useRebookServiceNotifications from '../hookStore/useRebookServiceNotifications';

export default function TerritorySelect() {
  const { territoryOptions } = useRebookServiceNotifications();
  const formCtx = useFormContext();
  const { isError, getErrorMessage } = useErrorState(formCtx);
  return (
    <FormControl variant='standard' fullWidth error={isError('territoryId')}>
      <HookSingleAutocomplete
        name='territoryId'
        label='Target Territory'
        dropdownOptions={territoryOptions}
        placeholder='Search and select territory'
        extraProps={{
          disabled: territoryOptions.filter(x => x.optionValue !== UNSELECTED_OPTION).length === 0,
        }}
      />
      <FormHelperText>{getErrorMessage('territoryId')}</FormHelperText>
    </FormControl>
  );
}