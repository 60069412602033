import { Button, Card, CardContent, Modal, Grid } from '@mui/material';
import { Box } from '@mui/system';
import { SecondaryButton } from 'components/buttons';
import PrimaryButton from 'components/buttons/PrimaryButton';
import { useEffect, useState, createContext, useContext } from 'react';
import styles from './ConfirmationModal.module.css';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

type onConfirmFunc = (args:any) => Promise<any>;

export interface IConfirmationModalContextProps {
  onConfirm:onConfirmFunc;
  children:any;
}
export const ConfirmationModalContext = createContext({
  openModal: (args:any) => {},
  cancelModal: () => {},
  confirmModal: () => {},
  isOpen: false,
  onConfirmArgs: {},
});

export const ConfirmationModalContextProvider = ({ onConfirm, children }: IConfirmationModalContextProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [onConfirmArgs, setOnConfirmArgs] = useState({});

  function openModal(_onConfirmArgs) {
    setIsOpen(true);
    setOnConfirmArgs(_onConfirmArgs);
  }

  async function confirmModal() {
    await onConfirm(onConfirmArgs);
    setIsOpen(false);
    setOnConfirmArgs({});
  }
  function cancelModal() {
    setIsOpen(false);
    setOnConfirmArgs({});
  }

  return (
    <ConfirmationModalContext.Provider value={{
      isOpen,
      openModal,
      confirmModal,
      cancelModal,
      onConfirmArgs,
    }}>
      {children}
    </ConfirmationModalContext.Provider>
  );
};

export default function ConfirmationModal({ title, message }:any) {
  const ctx = useContext(ConfirmationModalContext);
  return (
    <Modal
      open={ctx.isOpen}
      aria-labelledby="confirm-modal-title"
      aria-describedby="confirm-modal-description"
      className={styles.modalParent}
    >
      <Card>
        <CardContent>
          <div id="confirm-modal-title" className={styles.modalTitle}>
            {title(ctx.onConfirmArgs) || 'You are about to delete this item'}
          </div>
          <div id="confirm-modal-description">
            {message(ctx.onConfirmArgs) || (<div>Are you sure you want to delete?</div>)}

          </div>
          <Grid container justifyContent='right'>
            <SecondaryButton type='button' onClick={ctx.cancelModal} sx={{ marginRight: '6px' }}>Cancel</SecondaryButton>
            <PrimaryButton size='small' type='button' onClick={ctx.confirmModal} style={{ minWidth: '100px', maxWidth: '100px', marginRight: '10px' }}>Confirm</PrimaryButton>

          </Grid>
        </CardContent>
      </Card>
    </Modal>
  );
}
