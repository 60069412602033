import GenericModal from 'components/modal/GenericModal';
import useModal from 'components/modal/useModal';
import useToast from 'components/toast/useToast';
import useTsoiManagementBatch from '../hookStore/useTsoiManagementBatch';
import { Grid, Typography } from '@mui/material';
import { submitTsoiManagementBatch } from 'api/tsoiManagementApi';
import { useLoading } from 'components/Layout/Loading';

export const modalStoreKey = 'SendHappeningSoonInvitesModal';

export interface ISendHappeningSoonInvitesModal {
  batchId: string;
}
export function createEmptySendHappeningSoonInvitesModal() :ISendHappeningSoonInvitesModal {
  return {
    batchId: '',
  };
}

export default function SendHappeningSoonInvitesModal({ batchId }) {
  const { closeModal } = useModal(modalStoreKey);
  const { createSuccessToast, createErrorToast } = useToast();
  const { loadingKey } = useTsoiManagementBatch();
  const { onLoading, doneLoading } = useLoading(loadingKey);

  const onSubmit = async (data:any) => {
    onLoading();
    try {
      await submitTsoiManagementBatch(batchId);
      createSuccessToast('Messaging process started successfully!');
    } catch (error) {
      console.error(error);

      if ((error as any).response.data.message) {
        createErrorToast((error as any).response.data.message);
      } else {
        createErrorToast('Error starting messaging process :(');
      }

    }
    doneLoading(300);
    closeModal(createEmptySendHappeningSoonInvitesModal());
  };
  return (
    <GenericModal
      modalStoreKey={modalStoreKey}
      title={'Confirm'}
      modalContent={
        (
          <Grid container item justifyContent='center' rowSpacing={2}>
            <Grid item xs={10}>
              <Typography variant='body1'>Great job with this TSOI batch, you're awesome!</Typography>
            </Grid>
            <Grid item xs={10}>
              <Typography variant='body1'>Press the "Send" button to send text/email Happening Soon Invites to the service providers on this batch.</Typography>
            </Grid>
            <Grid item xs={10}>
              <Typography variant='body1'>This can not be undone!</Typography>
            </Grid>
          </Grid>
        )
      }
      onConfirm={onSubmit}
      onSecondaryAction={() => closeModal(createEmptySendHappeningSoonInvitesModal())}
      primaryButtonText='Send'
      secondaryButtonText='Cancel'
      overrideSx={{
        '& .MuiPaper-root': {
          width: '500px',
        },
      }}
    />
  );
}