import { Grid, Button, Typography, IconButton } from '@mui/material';
import { RemoveButton } from 'components/buttons';
import useConfirmationModal from 'components/modals/useConfirmationModal';
import { HookIntegerField, HookPriceField, HookTextField } from 'components/reactHookForm';
import HookLargeIntegerField from 'components/reactHookForm/HookLargeIntegerField';
import useToast from 'components/toast/useToast';
import { createEmptyRewardCodeBatch, ICreateRewardCodesForm } from 'model/rewards';
import { useEffect } from 'react';
import { useForm, SubmitHandler, FormProvider, useFormContext, useWatch, useFieldArray } from 'react-hook-form';
import ConfirmationModal from './confirmationModal/ConfirmationModal';
import useRewardCodeManagement from './hookStore/useRewardCodeManagement';
import styles from './rewards.module.css';

export interface ICreateRewardCodesFormInternalProps {
  initialFormData:ICreateRewardCodesForm;
}

const createRewardCodesModalKey = 'createRewardCodesModalKey';

function ModalWrapper() {
  const { getValues, control } = useFormContext();
  const { createRewardCodes } = useRewardCodeManagement();
  const { open } = useConfirmationModal(createRewardCodesModalKey);
  return (
    <ConfirmationModal
      modalKey={createRewardCodesModalKey}
      title={'Reward Code Creation'}
      body={'Are you sure you want to create these reward codes?'}
      onConfirm={async (data) => {
        await createRewardCodes(data);
      }}
      successText='Rewards created'
    />
  );
}

function RewardBatches() {
  const { control } = useFormContext();
  const { fields: batches, append, replace, remove } = useFieldArray({
    name: 'batches',
  });
  const watchBatches = useWatch({ control, name: 'batches' });
  useEffect(() => {
    if (batches.length !== watchBatches.length) {
      replace(watchBatches);
    }
  }, [watchBatches]);
  return (
    <Grid container item spacing={1} alignItems='center'>
      {batches.map((item, index) => {
        return (
          <Grid container item spacing={1} key={item.id} alignItems='top' className={styles.customField}>
            <Grid item xs={2}>
              <HookLargeIntegerField
                name={`batches.${index}.count`}
                label='Count'
                required
                validationRules={{
                  validate: function(value) {
                    if (value <= 0) {
                      return 'Must be greater than 0';
                    }
                  },
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <HookPriceField
                name={`batches.${index}.rewardAmount`}
                label='Reward Amount'
                required
              />
            </Grid>
            <Grid item xs={2}>
              <HookTextField
                name={`batches.${index}.notes`}
                label='Notes'
              />
            </Grid>
            <Grid item xs='auto'>
              <RemoveButton
                tooltipTitle="Delete"
                onClick={() => {
                  remove(index);
                }}
              />
            </Grid>
          </Grid>
        );
      })}
      <Grid container alignItems='center' item xs={12}>
        <Grid item xs='auto'>
          <Button
            variant='contained'
            type='button'
            onClick={() => append(createEmptyRewardCodeBatch())}
          >Add Reward Code Batch</Button>
        </Grid>

      </Grid>
    </Grid>
  );
}

function CreateRewardCodesFormInternal({ initialFormData }:ICreateRewardCodesFormInternalProps) {
  const formContext = useForm<ICreateRewardCodesForm>({ mode: 'onTouched', defaultValues: initialFormData });
  const { openModal } = useConfirmationModal(createRewardCodesModalKey);
  const { createErrorToast } = useToast();
  const { isSubmitting, isValid } = formContext.formState;

  useEffect(() => {
    formContext.reset(initialFormData);
  }, [initialFormData]);

  const onSubmit : SubmitHandler<ICreateRewardCodesForm> = data => {
    let hasBatches = data.batches.length > 0;
    if (!hasBatches) {
      createErrorToast('Must supply at least one batch');
      return;
    }
    openModal(data);
  };

  return (
    <FormProvider {...formContext}>
      <form onSubmit={formContext.handleSubmit(onSubmit)} >
        <Grid container spacing={1}>
          <Grid container item xs={12} justifyContent={{ sm: 'space-between' }} alignItems='flex-end'>
            <Grid item xs={12}>
              <RewardBatches/>
            </Grid>
            <Grid item xs={12} sm={5} md={4} order={{ xs: 0, sm: 1 }}>
              <Button
                disabled={isSubmitting || !isValid}
                variant='contained'
                type='submit'
                fullWidth
                sx={{ maxWidth: '300px', marginTop: '50px' }}
              >
                Save
              </Button>
            </Grid>
          </Grid>
          <ModalWrapper/>
        </Grid>
      </form>
    </FormProvider>
  );
}

export default function CreateRewardCodesForm() {
  const { formData, init } = useRewardCodeManagement();
  useEffect(() => {
    void init();
  }, []);
  return (
    <CreateRewardCodesFormInternal initialFormData={formData}/>
  );
}