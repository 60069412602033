;
import AddIcon from '@mui/icons-material/Add';
import { Button, Grid } from '@mui/material';
import { useLoading } from 'components/Layout/Loading';
import { createEmptyCoverageArea } from 'model/maps';
import { useEffect } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import useProviderCoverageArea from '../hookStore/useProviderCoverageArea';
import CoverageArea from './CoverageArea';

export default function CoverageAreaList() {
  const { control, formState } = useFormContext();
  const { fields: coverageAreas, append, replace, move, remove } = useFieldArray({
    name: 'providerCoverageAreas',
  });
  const { loadingKey, marketCoverageAreaMap, onAddCoverageArea, onRemoveCoverageArea } = useProviderCoverageArea();
  const { getLoadingState } = useLoading(loadingKey);
  const isLoading = getLoadingState().isLoading;
  const watchCoverageAreas = useWatch({ control, name: 'providerCoverageAreas' });
  const watchedServiceProviderId = useWatch({ control, name: 'serviceProviderId' });

  useEffect(() => {
    if (coverageAreas.length !== watchCoverageAreas.length) {
      replace(watchCoverageAreas);
    }
  }, [watchCoverageAreas]);
  return (
    <>

      <Grid item xs={12} md={3} sx={{ paddingLeft: '20px', paddingTop: '10px', paddingBottom: '20px' }}>
        <Button
          disabled={!watchedServiceProviderId || isLoading}
          onClick={async () => {

            await onAddCoverageArea();
            append(createEmptyCoverageArea(marketCoverageAreaMap));
          }}
        >
        Add Coverage Area
        </Button>
      </Grid>
      <Grid container item xs={12} sm={12} md={12} alignItems='center'>
        {coverageAreas.map((x, i) => {

          return (
            <CoverageArea key={x.id} index={i} remove={async ()=> {
              await onRemoveCoverageArea(i);
              remove(i);
            }}/>
          );
        })}
      </Grid>
    </>
  );
}