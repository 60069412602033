export interface ICreateRewardCodesForm {
  batches:RewardCodeBatch[];
}

export interface RewardCodeBatch {
  count:number;
  rewardAmount:number;

}
export function createEmptyRewardCodeBatch():RewardCodeBatch {
  return {
    count: 0,
    rewardAmount: 0.0,

  };
}
export function createEmptyRewardCodesForm():ICreateRewardCodesForm {
  return {
    batches: [],
  };
}

export interface CompensateRewardRequest {
  rewardCode:string | null;
  rewardsBalance:number | null;
  note:string | null;
}

export function createEmptyCompensateRewardRequest():CompensateRewardRequest {
  return {
    rewardCode: '',
    rewardsBalance: null,
    note: '',
  };
}

export interface IRewardCode {
  pk:string;
  code:string;
  amount:string;
  individualId:string;
  redeemedDate:string;
  appliedDate:string;
  appliedCustomerId:string;
  appliedSubscriberId:string;
  rewardCodeStatus:string;
  notes:string;
}