
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Tooltip, IconButton } from '@mui/material';
import styles from './CopyToClipboard.module.css';

export default function CopyToClipboardCell({ val, copyText }:any) {
  return (
    <div className={styles.flexWrapper}>
      <div>{val}</div>
      <div className={styles.spacer}></div>
      <div>
        <Tooltip title="Copy to clipboard">
          <IconButton onClick={() => navigator.clipboard.writeText(copyText ?? val)}>
            <ContentCopyIcon
              className='copyIcon'
            />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  );
}