import { Grid } from '@mui/material';
import Userfront from '@userfront/react';
import { PrimaryButton } from 'components/buttons';
import { useNavigate } from 'react-router-dom';

export default function DefaultErrorPage() {
  const navigate = useNavigate();
  return (
    <Grid container justifyContent={'center'} alignContent={'center'} sx={{ height: '100vh' }}>
      <Grid container item xs='auto' justifyContent={'center'}>
        <Grid item xs={12}>
          <h1 style={{ textAlign: 'center' }}>Something went wrong...</h1>
        </Grid>
        <PrimaryButton onClick={(event) => {
          event.preventDefault();
          Userfront.logout();
          navigate('/sign-in');
        }}>Click here to sign out</PrimaryButton>
      </Grid>
    </Grid>
  );
}