import { FormControl, InputLabel, FormHelperText } from '@mui/material';
import { HookSelect } from 'components/reactHookForm';
import useErrorState from 'hooks/reactHookForm/useErrorState';
import { SearchResultCardStyle } from 'model/serviceCategory';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

export interface ISearchResultCardStyleSelectProps {
  disabled?: boolean;
}

export default function SearchResultCardStyleSelect({ disabled }:ISearchResultCardStyleSelectProps) {
  const formCtx = useFormContext();
  const [list] = useState<any[]>(SearchResultCardStyle.list);
  const { isError, getErrorMessage } = useErrorState(formCtx);
  return (
    <FormControl variant='standard' fullWidth error={isError('searchResultCardStyle')}>
      <InputLabel htmlFor={'search-result-card-style'}>Search Result Card Style</InputLabel>
      <HookSelect
        control={formCtx.control}
        name={'searchResultCardStyle'}
        id={'search-result-card-style'}
        menuItems={list}
        disabled={disabled}
      />
      <FormHelperText>{getErrorMessage('searchResultCardStyle')}</FormHelperText>
    </FormControl>
  );
}