
import { Grid, Box, Tabs, Tab } from '@mui/material';
import { HookCheckbox } from 'components/reactHookForm';
import StepHeader from './StepHeader';

import TabPanel from './TabPanel';
import TextInput from './TextInput';
import validateNumber from './validators';

function a11yProps(index: number) {
  return {
    'id': `price-style-tab-${index}`,
    'aria-controls': `price-tabpanel-${index}`,
  };
}

export default function StepTwo({ tab, handleChange }:any) {

  return (
    <Grid container item xs={12} spacing={1}>
      <Grid item xs={12}>
        <StepHeader>Step 2: Enter a label, price, and price suffix</StepHeader>
      </Grid>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}>
        <Tabs value={tab} onChange={handleChange} aria-label="price or no price tabs">
          <Tab label="Price" {...a11yProps(0)} />
          <Tab label="No Price" {...a11yProps(1)} />
        </Tabs>
        <TabPanel value={tab} index={0}>
          <Grid container item xs={12}>
            <Grid item xs={3}>
              <TextInput
                required
                name='priceLabel'
                label='Price Label'
                maxLength='100'
              />
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={3}>
              <TextInput
                required
                name='priceAmount'
                label='Price Amount'
                maxLength='100'
                validationRules={{
                  validate: validateNumber,
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <HookCheckbox
                name={'unitBasedPricingFlag'}
                label='Unit based pricing?'
              />
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={3}>
              <TextInput
                required
                name='priceSuffix'
                label='Price Suffix'
                maxLength='100'
              />
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={3}>
              <TextInput
                name='priceSubText'
                label='Price Subtext'
                maxLength='100'
              />
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <Grid container item xs={12}>
            <Grid item xs={3}>
              <TextInput
                name='noPriceTextField'
                label='No Price Text Field'
                maxLength='100'
              />
            </Grid>
          </Grid>
        </TabPanel>
      </Box>

    </Grid>
  );
}