import { FormControl, InputLabel, FormHelperText } from '@mui/material';
import { HookSelect } from 'components/reactHookForm';
import useErrorState from 'hooks/reactHookForm/useErrorState';
import { useFormContext, useWatch } from 'react-hook-form';
import useServiceOfferingDetails from './hookStore/useServiceOfferingDetails';

export default function ProviderCoverageAreaSelect() {
  const { providerCoverageAreaOptions } = useServiceOfferingDetails();
  const formCtx = useFormContext();
  const { isError, getErrorMessage } = useErrorState(formCtx);
  useWatch({ control: formCtx.control, name: 'providerCoverageAreaId' });
  return (
    <FormControl variant='standard' fullWidth error={isError('providerCoverageAreaId')}>
      <InputLabel htmlFor="provider-coverage-area">Provider Coverage Area</InputLabel>
      <HookSelect
        rules={{
          required: { value: false, message: 'Required' },
        }}
        control={formCtx.control}
        name='providerCoverageAreaId'
        id='provider-coverage-area'
        menuItems={providerCoverageAreaOptions}
        menuItemKeys={{
          displayValue: 'optionText',
          key: 'key',
          value: 'optionValue',
        }}
      />
      <FormHelperText>{getErrorMessage('providerCoverageAreaId')}</FormHelperText>
    </FormControl>
  );
}