import { Rating, RatingProps, TextField, TextFieldProps } from '@mui/material';
import useErrorState from 'hooks/reactHookForm/useErrorState';
import { Controller, useFormContext } from 'react-hook-form';


export interface IHookRatingProps extends RatingProps {
  name:string;
  label:string;
  required?:boolean;
  validationRules?:any;
}
export default function HookRating({ name, label, required, validationRules, ...extraProps }:IHookRatingProps) {
  const methods = useFormContext();
  const { control } = methods;
  const { isError, getErrorMessage } = useErrorState(methods);
  const rules:any = {
    required: {
      value: required || false, message: 'Required',
    },
    ...validationRules,
  };
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={0}
      rules={rules}
      render={({ field: { onChange, value, ref } }) => {
        return (
          <Rating name={name} defaultValue={0} value={value || 0} {...extraProps} onChange={onChange}/>
        );
      }
      }
    />
  );
}