import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { IconButton, Tooltip } from '@mui/material';

interface IRemoveButtonProps {
  tooltipTitle:string;
  onClick:any;
  className?:string;
  disabled?:boolean;
  iconButtonProps?:any;
  tooltipProps?:any;
}
export default function RemoveButton({ tooltipTitle, onClick, className, disabled, tooltipProps, iconButtonProps }:IRemoveButtonProps) {
  return (
    <Tooltip title={tooltipTitle} className={className} {...tooltipProps}>
      <>
        {disabled && <DeleteOutlineIcon opacity={0.3}/>}
        {!disabled && (
          <IconButton onClick={onClick} disabled={disabled} {...iconButtonProps}>
            <DeleteOutlineIcon/>
          </IconButton>
        )}
      </>
    </Tooltip>
  );
}