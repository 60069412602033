import TabPanel from 'components/tabs/SimpleTabPanel';
import { Grid, Tab, Tabs } from '@mui/material';
import { useEffect, useState } from 'react';
import IndividualRewards from './individualRewards/IndividualRewards';
import useIndividualsHookStore from './individualsHookStore';
import LaunchLeaderManagement from './launchLeaderManagement/LaunchLeaderManagement';
import Loading from 'components/Layout/Loading';
import UpdateBalanceModal from './individualRewards/UpdateBalanceModal';

function a11yProps(index: number) {
  return {
    'id': `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function IndividualTabs() {
  const [value, setValue] = useState(0);
  const { individual, loadingKey } = useIndividualsHookStore();


  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <Loading loadingKey={loadingKey}>
      <Grid item xs={12} marginTop={'50px'}>
        { individual.id && (
          <>
            <Tabs value={value} onChange={handleChange} aria-label='tabs'>
              <Tab label="Rewards" {...a11yProps(0)} />
              <Tab disabled={!individual.launchLeaderApplicationOrigin} label="Launch Leader Management" {...a11yProps(1)} />
            </Tabs>
            <TabPanel value={value} index={0}>
              <IndividualRewards />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <LaunchLeaderManagement />
            </TabPanel>
          </>
        )
        }
      </Grid>
      <UpdateBalanceModal/>
    </Loading>
  );
}