import { Grid } from '@mui/material';
import HookConfirmationModal from 'components/modals/HookConfirmationModal';
import { HookCheckbox, HookTextField } from 'components/reactHookForm';
import { createEmptyRebookServicesForm, IRebookServicesForm } from 'model/rebookServices';
import { useEffect } from 'react';
import { useForm, FormProvider, useFormContext, useWatch } from 'react-hook-form';
import NSOISelect from './formComponents/NSOISelect';
import PriorCustomerEndDate from './formComponents/PriorCustomerEndDate';
import PriorCustomerStartDate from './formComponents/PriorCustomerStartDate';
import ServiceOfferingSelect from './formComponents/ServiceOfferingSelect';
import ServiceProviderSelect from './formComponents/ServiceProviderSelect';
import SubmitButton from './formComponents/SubmitButton';
import TargetServiceOfferingSelect from './formComponents/TargetServiceOfferingSelect';
import TargetServiceProviderSelect from './formComponents/TargetServiceProviderSelect';
import TerritorySelect from './formComponents/TerritorySelect';
import useRebookServiceNotifications from './hookStore/useRebookServiceNotifications';
import useMarkets from 'hooks/useMarkets';

export interface IRebookServiceNotificationsFormInternalProps {
  initialFormData:IRebookServicesForm;
}

export const rebookServiceNotificationsModalKey = 'rebookServiceNotificationsModalKey';

function ModalWrapper() {
  const { getValues, control } = useFormContext();
  const { save } = useRebookServiceNotifications();
  const { selectedMarket } = useMarkets();
  return (
    <HookConfirmationModal
      body='Confirm Rebook Notifications'
      modalKey={rebookServiceNotificationsModalKey}
      title={() => 'This will send emails to customers to rebook.'}
      message={(confirmArgs, cancelModal) => (
        <Grid container spacing={1} alignItems='center' justifyContent='center' >
          <Grid item xs={12}>

          </Grid>
        </Grid>
      )}
      onConfirm={async (data) => {
        if (!selectedMarket?.id) {
          return;
        }
        await save(selectedMarket?.id, data);
      }}

    />
  );
}


function RebookServiceNotificationsFormInternal({ initialFormData }:IRebookServiceNotificationsFormInternalProps) {
  const formContext = useForm<IRebookServicesForm>({ mode: 'onChange', defaultValues: createEmptyRebookServicesForm() });
  const { hasValidValuesForFindingNSOIs, findAndSetNSOIs } = useRebookServiceNotifications();
  useEffect(() => {
    formContext.reset(initialFormData);
  }, [initialFormData]);
  const watchedServiceProviderId = useWatch({ control: formContext.control, name: 'targetServiceProviderId' });
  const watchedServiceOfferingId = useWatch({ control: formContext.control, name: 'targetServiceOfferingId' });
  const watchedTerritoryId = useWatch({ control: formContext.control, name: 'territoryId' });
  useEffect(() => {
    if (hasValidValuesForFindingNSOIs(watchedServiceProviderId, watchedServiceOfferingId, watchedTerritoryId)) {
      if (watchedServiceProviderId !== null && watchedServiceOfferingId !== null && watchedTerritoryId !== null) {
        void findAndSetNSOIs(watchedServiceProviderId.optionValue, watchedServiceOfferingId.optionValue, watchedTerritoryId.optionValue);
      }
    }
  }, [watchedServiceProviderId, watchedServiceOfferingId, watchedTerritoryId]);

  return (
    <FormProvider {...formContext}>
      <form >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={3} >
            <ServiceProviderSelect />
          </Grid>
          <Grid item xs={12} sm={12} md={3} >
            <ServiceOfferingSelect/>
          </Grid>
          <Grid item xs={12} sm={12} md={2} >
            <PriorCustomerStartDate/>
          </Grid>
          <Grid item xs={12} sm={12} md={2} >
            <PriorCustomerEndDate/>
          </Grid>
          <Grid item xs={12} sm={12} md={2} >
          </Grid>
          <Grid item xs={12} sm={12} md={3} >
            <TargetServiceProviderSelect />
          </Grid>
          <Grid item xs={12} sm={12} md={3} >
            <TargetServiceOfferingSelect/>
          </Grid>
          <Grid item xs={12} sm={12} md={2} >
            <TerritorySelect/>
          </Grid>
          <Grid item xs={12} sm={12} md={2} >
            <NSOISelect/>
          </Grid>
          <Grid container item xs={12} sx={{ margin: '20px 0' }}>
            <Grid item xs={12} sm={12} md={2} >
              <HookTextField name="overrideEmails" label="Override Email"/>
            </Grid>
            <Grid item xs={12} sm={12} md={2} >
              <HookCheckbox name="sendToMeAsTest" label="Send to me as test"/>
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <SubmitButton/>
          </Grid>
          <ModalWrapper/>
        </Grid>
      </form>
    </FormProvider>
  );
}

export default function RebookServiceNotificationsForm() {
  const { formData, init } = useRebookServiceNotifications();
  const { selectedMarket } = useMarkets();
  useEffect(() => {
    if (!selectedMarket) {
      return;
    }
    void init(selectedMarket);
  }, [selectedMarket]);
  return (
    <RebookServiceNotificationsFormInternal initialFormData={formData}/>
  );
}