import Axios, { AxiosResponse } from 'axios';
import { AddProviderToTemplateModel } from 'model/seeding/addProviderToTemplates';
import { RemoveProviderFromTemplateModel } from 'model/seeding/removeProviderFromTemplates';
import { ICloneTerritorySeedingTemplateRequest, ICreateTerritorySeedingTemplateRequest, ITerritorySeedingTemplate } from 'model/territorySeedingTemplates';

const apiUrl = window.REACT_APP_BASE_API_URI;

export function saveTerritorySeedingTemplates(data: ICreateTerritorySeedingTemplateRequest):Promise<AxiosResponse<void>> {
  return Axios.post(`${apiUrl}/territorySeedingTemplates/v2/createTerritorySeedingTemplateForProvider`, data);
}

export function findAllTerritorySeedingTemplatesByZipcodeTerritory(zipcodeTerritory: string):Promise<AxiosResponse<Array<ITerritorySeedingTemplate>>> {
  return Axios.get(`${apiUrl}/territorySeedingTemplates/v2/findAllForTerritory/${encodeURIComponent(zipcodeTerritory)}`);
}

export function deleteProviderServiceTypeTemplateForTerritory(primaryKey: string, sortKey: string) {
  return Axios.delete(`${apiUrl}/territorySeedingTemplates/v2/deleteTemplateInTerritory/${encodeURIComponent(primaryKey)}/providerServiceType/${encodeURIComponent(sortKey)}`);
}

export function seedNeighborhoodFromTemplate(neighborhoodId: string):Promise<AxiosResponse<void>> {
  return Axios.post(`${apiUrl}/territorySeedingTemplates/v2/seedNeighborhoodFromTemplate/${neighborhoodId}`);
}

export function seedProviderFromTemplates(providerId: string):Promise<AxiosResponse<void>> {
  return Axios.post(`${apiUrl}/territorySeedingTemplates/v2/seedProviderFromTemplate/${providerId}`);
}

export function cloneTerritoryTemplates(data: ICloneTerritorySeedingTemplateRequest):Promise<AxiosResponse<void>> {
  return Axios.post(`${apiUrl}/territorySeedingTemplates/v2/cloneTerritoryTemplates`, data);
}

export function addProviderToSeedingTemplates(data: AddProviderToTemplateModel.IAddProviderToSeedingTemplatesRequest):Promise<AxiosResponse<void>> {
  return Axios.post(`${apiUrl}/territorySeedingTemplates/v2/addProviderToSeedingTemplates`, data);
}

export function removeProviderFromSeedingTemplates(data: RemoveProviderFromTemplateModel.IRemoveProviderFromSeedingTemplatesRequest):Promise<AxiosResponse<void>> {
  return Axios.post(`${apiUrl}/territorySeedingTemplates/v2/removeProviderFromSeedingTemplates`, data);
}