import { FormControl, FormHelperText } from '@mui/material';
import HookAutocomplete from 'components/reactHookForm/HookAutocomplete';
import useErrorState from 'hooks/reactHookForm/useErrorState';
import get from 'lodash.get';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import useNeighborhoodLaunchTooling from '../hookStore/useNeighborhoodLaunchTooling';
import NeighborhoodMultiAutocomplete from 'components/neighborhoodAutocomplete/NeighborhoodMultiAutocomplete';

export default function NeighborhoodMultiSelect() {
  const { neighborhoodOptions } = useNeighborhoodLaunchTooling();
  const formCtx = useFormContext();
  const { isError, getErrorMessage } = useErrorState(formCtx);
  const [inputValue, setInputValue] = useState<string>('');
  return (
    <NeighborhoodMultiAutocomplete
      label={'Neighborhoods'}
      fieldName='neighborhoodIds'
      formless={false}
      placeholder={'Search and check neighborhoods'}
      required={false}
      extraProps={{
        clearOnBlur: false,
        onInputChange: (e, newInputValue, reason) => {
          if (reason === 'reset') {

          } else {
            setInputValue(newInputValue);
          }
        },
        inputValue: inputValue,
      }}
    />
    // <FormControl variant='standard' fullWidth error={isError('neighborhoodIds')}>
    //   <HookAutocomplete
    //     name='neighborhoodIds'
    //     label='Neighborhoods'
    //     dropdownOptions={neighborhoodOptions}
    //     placeholder='Search and check neighborhoods'
    //     extraProps={{
    //       clearOnBlur: false,
    //       onInputChange: (e, newInputValue, reason) => {
    //         if (reason === 'reset') {

  //         } else {
  //           setInputValue(newInputValue);
  //         }
  //       },
  //       inputValue: inputValue,
  //     }}

  //   />
  //   <FormHelperText>{getErrorMessage('neighborhoodIds')}</FormHelperText>
  // </FormControl>
  );
}