import { FormControl, InputLabel, FormHelperText } from '@mui/material';
import { HookSelect } from 'components/reactHookForm';
import useToast from 'components/toast/useToast';
import useErrorState from 'hooks/reactHookForm/useErrorState';
import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import useNeighborhoodServiceOfferingDetail from '../hookStore/useNeighborhoodServiceOfferingDetail';

export function DiscountScheduleSelect() {
  const formCtx = useFormContext();
  const { createInfoToast } = useToast();
  const { discountSchedules, serviceOfferings } = useNeighborhoodServiceOfferingDetail();
  const { isError, getErrorMessage } = useErrorState(formCtx);

  const watchedServiceOfferingId = useWatch({ control: formCtx.control, name: 'serviceOfferingId' });
  const watchedDiscountScheduleId = useWatch({ control: formCtx.control, name: 'discountScheduleId' });
  useEffect(() => {
    if (serviceOfferings && watchedServiceOfferingId && !watchedDiscountScheduleId) {
      const matchingServiceOffering = serviceOfferings.filter(x => x.id === watchedServiceOfferingId);
      if (matchingServiceOffering.length > 0 && matchingServiceOffering[0].defaultDiscountScheduleId) {
        formCtx.setValue('discountScheduleId', matchingServiceOffering[0].defaultDiscountScheduleId);
        createInfoToast('selected default discount schedule.');
      }
    }
  }, [watchedServiceOfferingId, watchedDiscountScheduleId]);
  return (
    <FormControl variant='standard' fullWidth error={isError('discountScheduleId')}>
      <InputLabel htmlFor="discount-schedule">Discount Schedule</InputLabel>
      <HookSelect
        control={formCtx.control}
        rules={{
          required: { value: true, message: 'Required' },
        }}
        name={'discountScheduleId'}
        id='discount-schedule'
        menuItems={discountSchedules} />
      <FormHelperText>{getErrorMessage('discountScheduleId')}</FormHelperText>
    </FormControl>
  );
}
