import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import HomeIcon from '@mui/icons-material/Home';
import IosShareIcon from '@mui/icons-material/IosShare';
import { Button } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import StreetFairLogo from '../../images/streetfair-logo-white.png';

function SignInHeader (props: any) {
  const navigate = useNavigate();

  return (
    <div style={{
      background: '#299B60 no-repeat left',
      padding: '10px',
      textAlign: 'center',
      marginBottom: '20px',
    }}>
      <div >
        <img alt="logo" style={{ marginTop: '5px' }} src={StreetFairLogo} height="50px" />
      </div>
    </div>
  );
}

export default SignInHeader;
