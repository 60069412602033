import createStore from 'hooks/hookStore';
import useToast from 'components/toast/useToast';
import { useLoading } from 'components/Layout/Loading';
import { useState, useEffect } from 'react';
import { findIndividualById } from 'api/individualApi';
import { IIndividual, createEmptyIndividual } from 'model/individual';
import { useNavigate, useParams } from 'react-router-dom';
import { CompensateRewardRequest, createEmptyCompensateRewardRequest } from 'model/rewards';
import { compensateReward } from 'api/rewardsApi';

const loadingKey = 'IndividualsHookStore';
type IndividualsHookStore = {
  searchValue: string;
  individual: IIndividual;
  launchLeaderReferralLink: string;
  neighborReferralLink: string;
  email:string;
  formData:any;
}

const { get, update, registerListener, unregisterListener } = createStore<IndividualsHookStore>('IndividualsHookStore', {
  searchValue: '',
  individual: {} as IIndividual,
  launchLeaderReferralLink: '',
  neighborReferralLink: '',
  email: '',
  formData: createEmptyCompensateRewardRequest(),
});

export default function useIndividualsHookStore() {
  const setState = useState(get())[1];
  const { onLoading, doneLoading } = useLoading(loadingKey);
  const { createErrorToast, createInfoToast } = useToast();
  const navigate = useNavigate();
  const { individualId: individualIdParam } = useParams();

  useEffect(() => {
    registerListener(setState);
    return () => {
      unregisterListener(setState);
    };
  }, []);

  async function init() {
    onLoading();
    try {
      let individual = createEmptyIndividual();
      let searchValue = '';
      if (individualIdParam) {
        const resp = await findIndividualById(individualIdParam);
        individual = resp?.data;
        searchValue = individual.id.toString();
      }
      update({
        ...get(),
        individual: individual,
        launchLeaderReferralLink: '',
        searchValue: searchValue,
      });
    } catch (error) {
      doneLoading(300);
      createErrorToast('Error retrieving individual');
      console.error('Error retrieveing individual: ', { error, searchValue: get().searchValue });
    }
    doneLoading(300);
  }

  function clearSearch() {
    update({
      ...get(),
      searchValue: '',
    });
  };

  function onSearchInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    update({
      ...get(),
      searchValue: event.target.value,
    });
  }

  function setReferralLink(link: string) {
    update({
      ...get(),
      launchLeaderReferralLink: link,
    });
  }

  function setNeighorReferralLink(link: string) {
    update({
      ...get(),
      neighborReferralLink: link,
    });
  }

  async function onSearchButtonClick() {
    onLoading();
    try {
      navigate(`/individuals/${encodeURIComponent(get().searchValue.toLowerCase())}`);
      await update({
        ...get(),
        individual: {} as IIndividual,
      });
    } catch (error) {
      doneLoading(300);
      createErrorToast('Error retrieving individual');
      console.error('Error retrieveing individual: ', { error, searchValue: get().searchValue });
    }
    doneLoading(300);
  }

  function updateIndividual(individual: IIndividual) {
    update({
      ...get(),
      individual,
    });
  }

  async function onCompensateReward(data:CompensateRewardRequest) {
    onLoading();
    const { individual } = get();
    try {
      let individualId = individual.id.toString();
      await compensateReward(individualId, data);
      const resp = await findIndividualById(individualId);
      update({
        ...get(),
        individual: resp?.data,
        formData: createEmptyCompensateRewardRequest(),
      });
      createInfoToast('Reward balance updated');
    } catch (err:any) {
      createErrorToast('Error updating reward balance');
      console.error(err);
    }
    doneLoading();
  }

  return {
    ...get(),
    loadingKey,
    init,
    clearSearch,
    onCompensateReward,
    onSearchButtonClick,
    onSearchInputChange,
    setNeighorReferralLink,
    setReferralLink,
    updateIndividual,
  };
}
