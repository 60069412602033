import { Grid, Typography } from '@mui/material';
import Loading from 'components/Layout/Loading';
import PageHeader from 'components/SectionHeaders/PageHeader';
import useMarketDetails from './hookStore/useMarketDetails';
import MarketDetailsForm from './marketDetailsForm';

export interface IMarketDetailsProps {
  isNew:boolean;
}

export default function MarketDetails({
  isNew,
}:IMarketDetailsProps) {
  const { loadingKey } = useMarketDetails();
  return (
    <Loading loadingKey={loadingKey} size={100}>
      <Grid container spacing={2} className='pageGridContainer'>
        <Grid item xs={12}>
          <PageHeader headerText={'Market Details'} />
        </Grid>
        <Grid item xs={12}>
          <MarketDetailsForm isNew={isNew}/>
        </Grid>
      </Grid>
    </Loading>
  );
}