import Axios, { AxiosResponse } from 'axios';
import { IMarket, IMarketForm } from 'model/markets';


const apiUrl = window.REACT_APP_BASE_API_URI;

export function findMarkets():Promise<AxiosResponse<IMarket[]>> {
  return Axios.get(`${apiUrl}/markets/v2/findMarkets`);
}

export function findMarketById(marketId:string):Promise<AxiosResponse<IMarket>> {
  return Axios.get(`${apiUrl}/markets/v2/markets/${marketId}`);
}


export function saveMarket(id:string | null, data: IMarketForm):Promise<AxiosResponse<IMarket>> {
  if (id) {
    return Axios.put(`${apiUrl}/markets/v2/markets/${id}/updateMarket`, data);
  } else {
    return Axios.post(`${apiUrl}/markets/v2/markets/createMarket`, data);
  }
}