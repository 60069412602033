import { Alert, Grid } from '@mui/material';
import { PrimaryButton } from 'components/buttons';
import { ConfirmationModalContext } from 'components/modals/ConfirmationModal';
import { IEditServiceAllocationsForm, createEmptyEditServiceAllocationsForm } from 'model/customerUpload';
import { useContext } from 'react';
import { FormProvider, SubmitHandler, useForm, useWatch } from 'react-hook-form';
import ServiceProviderSelect from '../ServiceProviderSelect';
import EditServiceOfferingAllocations from './EditServiceOfferingAllocations';
import useCustomerUpload from '../hookStore/customerUploadHookStore';

export default function EditAllocations() {

  const { onSubmitEditAllocations } = useCustomerUpload();
  const formContext = useForm<IEditServiceAllocationsForm>({
    mode: 'onTouched',
    defaultValues: createEmptyEditServiceAllocationsForm(),
  });

  const selectedServiceProvider = useWatch({
    control: formContext.control,
    name: 'selectedServiceProvider',
  });

  const onSubmit : SubmitHandler<IEditServiceAllocationsForm> = data => {
    void onSubmitEditAllocations(data);
  };


  return (
    <FormProvider {...formContext}>
      <form onSubmit={formContext.handleSubmit(onSubmit)}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Alert
              severity='info'
              sx={{ marginBottom: '20px' }}
            >
              Use this tab to edit the service offering allocations for an existing staged customer upload identified by their request id. Steps for reprocessing the customer upload with new allocations is as follows:
              <ol>
                <li>Edit the service offering allocations for the particular request id(s)</li>
                <li>Rollback the customer upload by the request id</li>
              </ol>
            </Alert>
          </Grid>
          <Grid item xs={12} lg={4}>
            <ServiceProviderSelect/>
          </Grid>
          <Grid item xs={12}></Grid>
          <Grid item xs={12}>
            <EditServiceOfferingAllocations/>
          </Grid>
          {selectedServiceProvider && (
            <Grid item xs={11} >
              <PrimaryButton type='submit' style={{ marginTop: '10px' }}>Submit</PrimaryButton>
            </Grid>
          )}

        </Grid>
      </form>
    </FormProvider>
  );
}