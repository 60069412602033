import { Grid } from '@mui/material';
import PageHeader from 'components/SectionHeaders/PageHeader';
import InviteNeighborsTable from './InviteNeighborsTable';
import InviteNeighborsFilterBar from './InviteNeighborsFilterBar';
import InviteNeighobrsModals from './_components/Modals';

export default function InviteNeighbors() {
  return (
    <Grid container spacing={2} className='pageGridContainer'>
      <Grid item xs={12}>
        <PageHeader headerText={'Invite Neighbors'} />
      </Grid>
      <Grid item xs={12}>
        <InviteNeighborsFilterBar />
      </Grid>
      <Grid item xs={12}>
        <InviteNeighborsTable />
      </Grid>
      <InviteNeighobrsModals />
    </Grid>

  );
}