import { deleteClonedNsos, deleteProviderLaunch } from 'api/neighborhoodLaunchApi';
import { useLoading } from 'components/Layout/Loading';
import createStore from 'hooks/hookStore';
import { IDropdownOption } from 'model/dropdown';
import { createEmptyRequestManagementFrom, IRequestManagementForm, RequestManagementTypeOptions, RequestManagementTypes } from 'model/neighborhoodLaunch';
import { useEffect, useState } from 'react';

const loadingKey = 'RequestManagementStore';
type RequestManagementStore = {
  formData:IRequestManagementForm;
  requestManagementTypeOptions: IDropdownOption[];
}

const { get, update, registerListener, unregisterListener } = createStore<RequestManagementStore>('RequestManagementStore', {
  formData: createEmptyRequestManagementFrom(),
  requestManagementTypeOptions: [...RequestManagementTypeOptions],
});

export default function useRequestManagement() {
  const setState = useState(get())[1];
  const { onLoading, doneLoading } = useLoading(loadingKey);

  useEffect(() => {
    registerListener(setState);
    return () => {
      unregisterListener(setState);
    };
  }, []);

  async function init() {
    onLoading();
    try {

      //todo: maybe show all prior requests that were not deleted?
      update({
        ...get(),
      });
    } catch (e:any) {
      console.error(e);
    }
    doneLoading(300);
  }

  async function executeRequest(data:IRequestManagementForm) {
    try {
      onLoading();
      if (data.requestAction === RequestManagementTypes.DELETE_PROVIDER_LAUNCH) {
        await deleteProviderLaunch(data);
      } else if (data.requestAction === RequestManagementTypes.DELETE_CLONED_NSOS) {
        await deleteClonedNsos(data);
      }
      doneLoading(200);
    } catch (err:any) {
      console.error(err);
    }
  }

  return {
    ...get(),
    loadingKey,
    init,
    executeRequest,
  };
}