
import { FormControl, FormHelperText } from '@mui/material';
import { HookSingleAutocomplete } from 'components/reactHookForm';
import useErrorState from 'hooks/reactHookForm/useErrorState';
import { UNSELECTED_OPTION } from 'model/dropdown';
import { useFormContext, useWatch } from 'react-hook-form';
import useRebookServiceNotifications from '../hookStore/useRebookServiceNotifications';


export interface IServiceOfferingSelectProps {

}

export default function TargetServiceOfferingSelect({ }:IServiceOfferingSelectProps) {
  const { targetServiceOfferingOptions } = useRebookServiceNotifications();
  const formCtx = useFormContext();
  const { isError, getErrorMessage } = useErrorState(formCtx);
  let rules:any = {
    required: {
      value: true, message: 'Required',
    },
  };
  const watchedServiceOfferingId = useWatch({ control: formCtx.control, name: 'serviceOfferingId' });
  return (
    <FormControl variant='standard' fullWidth error={isError('targetServiceOfferingId')}>
      <HookSingleAutocomplete
        rules={rules}
        name='targetServiceOfferingId'
        label='Target Service Offering'
        dropdownOptions={targetServiceOfferingOptions}
        placeholder='Search and check service offering'
        postOnChange={(formContext, value, reason, details) => {

        }}
        extraProps={{
          disabled: targetServiceOfferingOptions.filter(x => x.optionValue !== UNSELECTED_OPTION).length === 0 || watchedServiceOfferingId == null,
        }}
      />
      <FormHelperText>{getErrorMessage('targetServiceOfferingId') ?? 'Only shows service offerings that match source offering service type'}</FormHelperText>
    </FormControl>
  );
}