
import { AutocompleteChangeReason, AutocompleteInputChangeReason, FormControl, FormHelperText } from '@mui/material';
import useErrorState from 'hooks/reactHookForm/useErrorState';
import { FormProvider, useForm, useFormContext, useWatch } from 'react-hook-form';
import { IDropdownOption } from 'model/dropdown';
import { useEffect, useState } from 'react';
import HookAsyncAutocomplete from 'components/reactHookForm/HookAsyncAutocomplete';
import useServiceProviderMultiAutocomplete from './useServiceProviderMultiAutocomplete';
import useMarkets from 'hooks/useMarkets';


export interface IServiceProviderMultiAutocompleteProps {
  label:string;
  fieldName?: string;
  initialOptions?: IDropdownOption[];
  formless: boolean;
  placeholder:string;
  suppressEmptyStringSearch?:boolean;
  postOnChange?: (formCtx: any, selectedOptions: string[], reason: AutocompleteChangeReason, details: any, rawValues: IDropdownOption[]) => void;
  postOnInputChange?: (formCtx: any, nextVal: string | null, reason: AutocompleteInputChangeReason, details: any) => void;
  controlledValues?: string[];
  statuses?: string[];
  extraProps?:any;
  required: boolean;
  rules?: any;
}

interface IFormlessForm {
  selectedServiceProviders:string[];
}

export default function ServiceProviderMultiAutocomplete({
  label,
  fieldName,
  initialOptions,
  formless,
  placeholder,
  suppressEmptyStringSearch,
  statuses,
  controlledValues,
  rules,
  postOnChange,
  postOnInputChange,
  extraProps,
  required,
}:IServiceProviderMultiAutocompleteProps) {
  if (!formless && !fieldName) {
    throw new Error('fieldName is required when formless is false');
  }
  // managing options state here within the component
  const [options, setOptions] = useState([] as IDropdownOption[]);
  const { onSearchServiceProviders, initWithValue, initDropdownOptions } = useServiceProviderMultiAutocomplete();
  const { selectedMarket } = useMarkets();

  let _rules: any;
  if (rules) {
    _rules = rules;
  } else if (required) {
    rules = {
      required: {
        value: true, message: 'Required',
      },
    };
  }

  if (!formless) {
    const formCtx = useFormContext();
    const { isError, getErrorMessage } = useErrorState(formCtx);

    useEffect(()=> {
      if (!selectedMarket) {
        return;
      }
      if (initialOptions && initialOptions.length > 0 && options.length === 0) {
        void initDropdownOptions(formCtx, initialOptions, setOptions);
      }
    }, [initialOptions, selectedMarket]);

    useEffect(() => {
      if (!selectedMarket) {
        return;
      }

      if (controlledValues && controlledValues.length > 0 && options.length === 0) {
        void initWithValue(formCtx, fieldName!, controlledValues, setOptions);
      }
    }, [controlledValues, selectedMarket]);


    return (
      <FormControl variant='standard' fullWidth error={formless ? false : isError(fieldName!)}>
        <HookAsyncAutocomplete
          rules={_rules}
          name={fieldName!}
          label={label}
          dropdownOptions={options}
          placeholder={placeholder}
          onInputChange={(value: string, reason: AutocompleteInputChangeReason) => {
            if (!suppressEmptyStringSearch || value) {
              var currentSelected = formCtx.getValues(fieldName!);
              if (reason !== 'reset') {
                void onSearchServiceProviders(value, currentSelected, setOptions, options, statuses);
              }
              if (postOnInputChange) {
                postOnInputChange(formCtx, value, reason, null);
              }
            }
          }}
          postOnChange={(formContext, values, reason, details, rawValues) => {
            if (postOnChange) {
              postOnChange(formContext, values, reason, details, rawValues);
            }

          }}
        />
        <FormHelperText>{getErrorMessage(fieldName!) || ' '}</FormHelperText>
      </FormControl>
    );
  } else {
    const formContext = useForm<IFormlessForm>({
      mode: 'onTouched',
      defaultValues: {
        selectedServiceProviders: [],
      },
    });
    const { isError, getErrorMessage } = useErrorState(formContext);

    useEffect(()=> {
      if (!selectedMarket) {
        return;
      }
      if (initialOptions && initialOptions.length > 0 && options.length === 0) {
        void initDropdownOptions(formContext, initialOptions, setOptions);
      }
    }, [initialOptions, selectedMarket]);

    useEffect(() => {
      if (!selectedMarket) {
        return;
      }
      if (controlledValues && controlledValues.length > 0 && options.length === 0) {
        void initWithValue(formContext, 'selectedServiceProviders', controlledValues, setOptions);
      }
    }, [controlledValues, selectedMarket]);

    return (
      <FormProvider {...formContext}>
        <FormControl variant='standard' fullWidth error={formless ? false : isError('selectedServiceProviders')}>
          <HookAsyncAutocomplete
            rules={_rules}
            name={'selectedServiceProviders'}
            label={label}
            dropdownOptions={options}
            placeholder={placeholder}
            onInputChange={(value: string, reason: AutocompleteInputChangeReason) => {
              if (!suppressEmptyStringSearch || value) {
                var currentSelected = formContext.getValues('selectedServiceProviders');
                if (reason !== 'reset') {
                  void onSearchServiceProviders(value, currentSelected, setOptions, options, statuses);
                }
                if (postOnInputChange) {
                  postOnInputChange(formContext, value, reason, null);
                }
              }
            }}
            postOnChange={(formCtx, values, reason, details, rawValues) => {
              if (postOnChange) {
                postOnChange(formCtx, values, reason, details, rawValues);
              }
            }}
            extraProps={extraProps}
          />
          <FormHelperText>{getErrorMessage('selectedServiceProviders') || ' '}</FormHelperText>
        </FormControl>
      </FormProvider>
    );
  }

}

