import { useWatch } from 'react-hook-form';
import { HookCheckbox } from 'components/reactHookForm';
import { CampaignType } from 'model/letter_batch/ancillary';

export default function ExcludeExistingUsers() {


  return (
    <HookCheckbox
      name="excludeExistingUsers"
      label="Exclude existing users" />
  );
}