import { Grid, Typography } from '@mui/material';
import { HookTextField } from 'components/reactHookForm';
import { VariablePrintTemplatePropertyNames } from 'model/letter_batch/ancillary';


export default function VariablePrintSubform() {

  return (
    <Grid container item spacing={1}>
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
                    Variable Print Overrides
        </Typography>
      </Grid>
      <Grid item xs={12} sm={2}>
        <HookTextField
          name={`variablePrintOverrides.${VariablePrintTemplatePropertyNames.HoodNumber}`}
          label="Hood Number" />
      </Grid>
      {/* <Grid item xs={12} sm={2}>
        <HookTextField
          name={`variablePrintOverrides.${VariablePrintTemplatePropertyNames.NeighborhoodLeadName}`}
          label="Neighborhood Lead Name" />
      </Grid>
      <Grid item xs={12} sm={2}>
        <HookTextField
          name={`variablePrintOverrides.${VariablePrintTemplatePropertyNames.NeighborhoodLeadStreetName}`}
          label="Neighborhood Lead Street Name" />
      </Grid> */}
      <Grid item xs={12} sm={2}>
        <HookTextField
          name={`variablePrintOverrides.${VariablePrintTemplatePropertyNames.OfferValue}`}
          label="Offer Value" />
      </Grid>
      {/* <Grid item xs={12} sm={2}>
        <HookTextField
          name={`variablePrintOverrides.${VariablePrintTemplatePropertyNames.PSMonth}`}
          label="PS Month" />
      </Grid> */}
      <Grid item xs={12} sm={2}>
        <HookTextField
          name={`variablePrintOverrides.${VariablePrintTemplatePropertyNames.RegionName}`}
          label="Region Name" />
      </Grid>

    </Grid>
  );
}