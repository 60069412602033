import Axios from 'axios';
import { IOneTimeNewsletterRequest } from 'model/marketing/oneTimeNewsletter';
import { IRebookServicesDto } from 'model/rebookServices';

const apiUrl = window.REACT_APP_BASE_API_URI;


export function sendAutomatedNewsletters() {
  return Axios.get(`${apiUrl}/communications/v2/manual/sendAutomatedNewsletters`);
}


export function sendRebookReminders(serviceProviderId: string, serviceOfferingId:string, data:IRebookServicesDto) {
  return Axios.post(`${apiUrl}/communications/v2/serviceProviders/${serviceProviderId}/serviceOfferings/${serviceOfferingId}/sendRebookReminders`, data);
}

export function manuallySendNeighborhoodNotifications(timezone:string) {
  return Axios.get(`${apiUrl}/communications/v2/manual/sendNeighborhoodNotifications/${timezone}`);
}

export function sendOneTimeNewsletter(data:IOneTimeNewsletterRequest) {
  return Axios.post(`${apiUrl}/communications/v2/manual/sendOneTimeNewsletter`, data);
}

export function manuallyTriggerSendGridBounces() {
  return Axios.get(`${apiUrl}/communications/v2/manual/retrieveAndProcessSendGridBounces`);
}