import { createValidateNumberNotRequired } from 'util/rhfValidateFunctions';
import { Grid, Typography } from '@mui/material';
import { HookTextField } from 'components/reactHookForm';

const validateNumber = createValidateNumberNotRequired();

export default function AdditionalRelevantPlatformRatings() {

  return (
    <Grid container item spacing={1} alignItems='center' xs={12}>
      <Grid item alignItems={'flex-end'}>
        <Typography variant='body1'>Additional Relevant Platform Ratings:</Typography>
      </Grid>
      <Grid item xs={6} md={3} lg={2}>
        <HookTextField
          label='Number of ratings'
          name='relevantPlatformReviewsCount'
          validationRules={{
            min: { value: 0, message: 'Number must be ' },
            validate: validateNumber,
            require: false,
          }}
          inputProps={{ inputMode: 'numeric' }}
        />
      </Grid>
    </Grid>
  );
}