import TabPanel from 'components/tabs/SimpleTabPanel';
import { Grid, Tab, Tabs } from '@mui/material';
import { useEffect, useState } from 'react';
import ServiceCategories from './serviceCategories';
import ServiceTypes from './serviceTypes';
import PageHeader from 'components/SectionHeaders/PageHeader';
import { useLocation } from 'react-router-dom';
import useServiceTypeInfo from './hookStore/useServiceTypeInfo';

function a11yProps(index: number) {
  return {
    'id': `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function ServiceTypeInfoTabs() {

  const {
    selectedTab,
    onParentDispose,
    selectTab,
    initTable,
  } = useServiceTypeInfo();

  useEffect(() => {
    void initTable();

    return () => {
      void onParentDispose();
    };
  }, []);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    selectTab(newValue);
  };

  return (
    <Grid container className='pageGridContainer'>
      <Grid item xs={12}>
        <PageHeader headerText={'Service Type Info'} />
      </Grid>
      <Grid item xs={12} sx={{ marginTop: '20px' }}>
        <Tabs value={selectedTab} onChange={handleChange} aria-label='tabs'>
          <Tab label="Service Categories" {...a11yProps(0)} />
          <Tab label="Service Types" {...a11yProps(1)} />
        </Tabs>
        <TabPanel value={selectedTab} index={0} sx={{ paddingLeft: '10px' }}>
          <ServiceCategories />
        </TabPanel>
        <TabPanel value={selectedTab} index={1} sx={{ paddingLeft: '10px' }}>
          <ServiceTypes />
        </TabPanel>
      </Grid>
    </Grid>
  );
}