import {
  auditCustomerUpload,
} from 'api/customerApi';
import { useLoading } from 'components/Layout/Loading';
import useToast from 'components/toast/useToast';
import createStore from 'hooks/hookStore';
import { IAuditCustomerUploadForm, IAuditCustomerUploadRequestDto } from 'model/customerUpload';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

type AuditCustomerUpload = {

}
const loadingKey = 'auditCustomerUpload';

const { get, update, registerListener, unregisterListener } = createStore<AuditCustomerUpload>('auditCustomerUpload', {

});

export default function useAuditCustomerUpload() {
  const setState = useState(get())[1];
  const { onLoading, doneLoading } = useLoading(loadingKey);
  const { neighborhoodId } = useParams();
  const { createInfoToast, createSuccessToast, createErrorToast } = useToast();

  useEffect(() => {
    registerListener(setState);
    return () => {
      unregisterListener(setState);
    };
  }, []);

  async function init() {
    onLoading();


    update({
      ...get(),
    });
    doneLoading(300);
  }


  async function onAuditCustomerUpload(form: IAuditCustomerUploadForm) {
    onLoading();
    try {
      const data: IAuditCustomerUploadRequestDto = {
        serviceProviderId: form.selectedServiceProvider?.optionValue ?? null,
        auditAllServiceProviders: form.auditAllServiceProviders ?? false,
      };
      const res = await auditCustomerUpload(data);
      createSuccessToast('Audit request received.');
    } catch (err:any) {
      createErrorToast(err.response.data.message);
    }
    doneLoading(300);
  }


  return {
    loadingKey,
    ...get(),
    init,
    onAuditCustomerUpload,
  };
}