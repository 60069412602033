import useErrorState from 'hooks/reactHookForm/useErrorState';
import { useFormContext } from 'react-hook-form';
import NeighborhoodAutocomplete from 'components/neighborhoodAutocomplete/NeighborhoodAutocomplete';
import { NeighborhoodStatusType } from 'model/neighborhood';
import useReconcileNeighborhood from '../hookStore/useReconcileNeighborhood';
import { getNeighborhoodZipCodeStatusString } from 'util/neighborhoodUtil';


export default function NSOReconcileNeighborhoodSelect() {
  const formCtx = useFormContext();
  const { onSelectNeighborhood, clearLastReconciled } = useReconcileNeighborhood();
  const { isError, getErrorMessage } = useErrorState(formCtx);
  return (
    <NeighborhoodAutocomplete
      label={'Neighborhood'}
      fieldName='neighborhoodId'
      statuses={[NeighborhoodStatusType.BETA, NeighborhoodStatusType.LAUNCHED, NeighborhoodStatusType.ACTIVE]}
      formless={false}
      placeholder={'Search for a neighborhood'}
      required={true}
      optionDescriptionFunc={getNeighborhoodZipCodeStatusString}
      postOnChange={(formContext, values, reason, details) => {
        if (reason === 'selectOption') {
          var neighborhoodName = details.option.optionText;
          formCtx.setValue('neighborhoodName', neighborhoodName);
          void onSelectNeighborhood(details.option.optionValue);

        } else {
          void clearLastReconciled();
          formCtx.setValue('neighborhoodName', '');
        }
      }}
    />
  );
}