import createStore from 'hooks/hookStore';
import { useLoading } from 'components/Layout/Loading';
import { useEffect, useState } from 'react';
import { IDropdownOption } from 'model/dropdown';
import { findAllProviderCrmOptions } from 'api/providerCrmOptionLookUpApi';
import { IProviderCrmOptionLookUp } from 'model/providerCrmOptionLookUp';


const loadingKey = 'ServiceProviderCrmCreatableAutocompleteStore';
type ServiceProviderCrmCreatableAutocompleteStore = {
  options: IDropdownOption[];
}

const { get, update, registerListener, unregisterListener } = createStore<ServiceProviderCrmCreatableAutocompleteStore>('ServiceProviderCrmCreatableAutocompleteStore', {
  options: [],
});

function convertRespToOptions(data: IProviderCrmOptionLookUp[]): IDropdownOption[] {
  return data.map((x: IProviderCrmOptionLookUp) => ({
    key: x.entityValue,
    optionValue: x.entityValue,
    optionText: x.entityValue,
  }));
};

export default function useServiceProviderCrmCreatableAutocomplete() {
  const setState = useState(get())[1];
  const { onLoading, doneLoading } = useLoading(loadingKey);
  useEffect(() => {
    registerListener(setState);
    return () => {
      update({
        ...get(),
        options: [],
      });
      unregisterListener(setState);
    };
  }, []);

  async function init() {
    onLoading();
    try {
      const resp = await findAllProviderCrmOptions();
      if (resp.data) {
        var dropdownOptions = convertRespToOptions(resp.data);
        update({
          ...get(),
          options: dropdownOptions,
        });
      }
    } catch (err) {
      console.error('error: ', err); // TODO: remove
    }
    doneLoading(300);
  }


  return {
    ...get(),
    init,
    loadingKey,
  };
}