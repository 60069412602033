
import { getNeighborhoodById } from 'api/neighborhoodApi';
import { reconcileNSOsByNeighborhood } from 'api/neighborhoodLaunchApi';
import { useLoading } from 'components/Layout/Loading';
import useToast from 'components/toast/useToast';
import createStore from 'hooks/hookStore';
import { IDropdownOption } from 'model/dropdown';
import { useEffect, useState } from 'react';
import { createEmptyNeighborhoodReconciliationForm, INeighborhoodReconciliationForm } from '../reconcileNsosByNeighborhoodForm';

const loadingKey = 'ReconcileNeighborhoodStore';
type ReconcileNeighborhoodStore = {
  formData:INeighborhoodReconciliationForm;
  lastReconciled: Date | null;
  neighborhoodOptions:IDropdownOption[];
}


const { get, update, registerListener, unregisterListener } = createStore<ReconcileNeighborhoodStore>('ReconcileNeighborhoodStore', {
  formData: createEmptyNeighborhoodReconciliationForm(),
  lastReconciled: null,
  neighborhoodOptions: [],
});


export default function useReconcileNeighborhood() {
  const setState = useState(get())[1];
  const { onLoading, doneLoading } = useLoading(loadingKey);
  const { createErrorToast, createInfoToast } = useToast();

  useEffect(() => {
    registerListener(setState);
    return () => {
      unregisterListener(setState);
    };
  }, []);

  async function init() {
    onLoading();
    try {
      update({
        ...get(),

      });
    } catch (e:any) {
      console.error(e);
    }
    doneLoading(300);
  }


  async function onReconcileNSOsByNeighborhood(data: INeighborhoodReconciliationForm) {

    try {
      onLoading();

      var response = await reconcileNSOsByNeighborhood({ neighborhoodId: data.neighborhoodId?.optionValue, dryRun: data.dryRun });
      doneLoading();
      createInfoToast(`${data.dryRun ? 'Dry Run: ' : ''}Reconciling NSOs. You will receive an email with the results.`);
    } catch (e: any) {
      console.error(e);
      createErrorToast(`Request for NSO reconciliation failed with message: ${e.response.data.message}`);
    }

  }

  async function onSelectNeighborhood(id:string) {
    try {
      const res = await getNeighborhoodById(id);
      const neighborhood = res.data;
      update({
        ...get(),
        lastReconciled: neighborhood.lastReconciled,
      });
    } catch (e:any) {
      console.error(e);
    }
  }

  function clearLastReconciled() {
    update({
      ...get(),
      lastReconciled: null,
    });
  }


  return {
    ...get(),
    loadingKey,
    init,
    onReconcileNSOsByNeighborhood,
    onSelectNeighborhood,
    clearLastReconciled,
  };
}