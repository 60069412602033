import { Grid, Typography } from '@mui/material';
import { updateServiceSummaries } from 'api/serviceProviderApi';
import HookConfirmationModal from 'components/modals/HookConfirmationModal';
import useConfirmationModal from 'components/modals/useConfirmationModal';
import useToast from 'components/toast/useToast';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

export const serviceSummaryConfirmationModalKey = 'serviceSummaryConfirmationModalKey';

export interface IServiceSummaryConfirmationModalProps {
  onConfirm: () => Promise<any>;
}

export default function ServiceSummaryConfirmationModal({ onConfirm }:IServiceSummaryConfirmationModalProps) {
  const { createSuccessToast } = useToast();
  const { onConfirmArgs } = useConfirmationModal(serviceSummaryConfirmationModalKey);
  return (
    <HookConfirmationModal
      modalKey={serviceSummaryConfirmationModalKey}
      title={() => 'Confirm Action'}
      message={(confirmArgs, cancelModal) => (
        <Grid container spacing={1} alignItems='center' justifyContent='center' >
          <Grid item xs={12}>
            <Typography variant='body1'>Are you sure you want to sync service summaries via {confirmArgs.isLive ? 'live data?' : 'stale data?'}</Typography>
          </Grid>
        </Grid>
      )}
      onConfirm={async (data) => {
        if (onConfirmArgs.isLive) {
          void updateServiceSummaries(onConfirmArgs.serviceProviderId, { useLiveData: true });
        } else {
          void updateServiceSummaries(onConfirmArgs.serviceProviderId, { useLiveData: false });
        }
        createSuccessToast('Sync in progress');
        await onConfirm();
      }}

    />
  );
}