import { PrimaryButton } from 'components/buttons';

export default function SaveButton() {
  return (
    <PrimaryButton
      type="submit"
      variant="contained"
      color="primary"
      style={{ marginTop: '32px', marginBottom: '32px' }}
    >
        Submit
    </PrimaryButton>
  );
}