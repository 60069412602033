
import useConfirmationModal from 'components/modals/useConfirmationModal';
import { TerritoryPrepConfirmationModalKey } from './TerritoryPrepConfirmationModal';
import useTerritoryDetails from '../hookStore/useTerritoryDetails';
import { PrimaryButton } from 'components/buttons';

export default function TerritoryPrepButton() {
  const { openModal } = useConfirmationModal(TerritoryPrepConfirmationModalKey);
  const { isPrepTerritoryEnabled } = useTerritoryDetails();
  return (
    <PrimaryButton
      disabled={!isPrepTerritoryEnabled()}
      onClick={() => {
        openModal({});
      }}
    >
    Prep
    </PrimaryButton>
  );
}