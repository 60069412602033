import { FormControl, InputLabel, FormHelperText } from '@mui/material';
import { HookSelect } from 'components/reactHookForm';
import useErrorState from 'hooks/reactHookForm/useErrorState';
import { CustomerRollbackType } from 'model/customerUpload';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';


export class FromEmailType {

  static list = [
    // 'neighbor@streetfairneighbor.com',
    // 'neighbors@streetfairmarket.com',
    // 'neighborhood@streetfairmarket.com',
    // 'partnership@streetfairmarket.com',
    //TODO: need to copy template into primary account before enabling this
    'welcome@streetfair.com',
    'welcome@streetfair.app',
    // 'ops@streetfair.app',
  ];


}

export default function FromEmailSelect() {
  const formCtx = useFormContext();
  const [options] = useState<any[]>(FromEmailType.list);
  const { isError, getErrorMessage } = useErrorState(formCtx);
  return (
    <FormControl variant='standard' fullWidth error={isError('fromEmail')}>
      <InputLabel htmlFor="fromEmail-type">From Email (optional)</InputLabel>
      <HookSelect
        control={formCtx.control}
        name='fromEmail'
        id='fromEmail-type'
        menuItemKeys={{
          key: 'id',
          value: 'id',
          displayValue: 'name',
        }}
        menuItems={
          options.map((x, i) => ({ id: x, name: x }))
        }
      />
      <FormHelperText>{getErrorMessage('fromEmail')}</FormHelperText>
    </FormControl>
  );
}