import useErrorState from 'hooks/reactHookForm/useErrorState';
import { getDefaultCadenceForServiceType, getDefaultHasDisabledMaxCustomerCountForServiceType, getDefaultWorkFlowStepsForServiceType } from 'util/serviceTypeHelper';
import { FormControl, InputLabel, FormHelperText } from '@mui/material';
import { HookSelect } from 'components/reactHookForm';
import { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { findServiceTypeInfo } from 'api/serviceCategoryApi';
import { sortByString } from 'util/sortFunctions';
import useProviderSetupStore from '../useProviderSetupStore';

async function getAndFormatServiceTypesForDropdown(setServiceTypesFunction) {

  const serviceTypeInfo = await findServiceTypeInfo();
  const serviceTypes = serviceTypeInfo.data.serviceTypes
    .filter(x => !x.deleteDate)
    .map((x, i) => ({
      key: `${x.serviceTypeId}`,
      optionText: `${x.displayName ?? x.name}`,
      optionValue: `${x.serviceTypeId}`,
      ancillary: x,
    }))
    .sort(sortByString('optionText'));
  setServiceTypesFunction(serviceTypes);


}

export default function ServiceTypeSelect() {
  const formCtx = useFormContext();
  const { setServiceType } = useProviderSetupStore();

  const [serviceTypes, setServiceTypes] = useState<any[]>([]);
  useEffect(() => {
    void getAndFormatServiceTypesForDropdown(setServiceTypes);
  }, []);
  const { isError, getErrorMessage } = useErrorState(formCtx);

  return (
    <FormControl variant='standard' fullWidth error={isError('serviceTypeId')}>
      <InputLabel htmlFor="service-type">Service Type</InputLabel>
      <HookSelect
        control={formCtx.control}
        rules={{
          required: { value: true, message: 'Required' },
        }}
        postOnChange={async (event, x) => {
          // This feels gross, but based on how HookSelect is Implemented, I don't see a better way to do this
          let serviceTypeName;

          var serviceTypeData = serviceTypes.find((serviceType) => serviceType.optionValue === event.target.value)?.ancillary;
          serviceTypeName = serviceTypeData.displayName ?? serviceTypeData.name;

          // Set the default SO name based on service type
          await formCtx.setValue('name', serviceTypeName);
          // Set the service type in the context
          await setServiceType(serviceTypeName);
          // Set Service type specific defaults
          await formCtx.setValue('cadenceType', getDefaultCadenceForServiceType(serviceTypeName));
          await formCtx.setValue('hasDisabledMaxCustomerCount', getDefaultHasDisabledMaxCustomerCountForServiceType(serviceTypeName));
          await formCtx.setValue('workflowSteps', getDefaultWorkFlowStepsForServiceType(serviceTypeName));
        }}
        name='serviceTypeId'
        id='service-type'
        menuItems={serviceTypes}
        menuItemKeys={{
          key: 'id',
          value: 'optionValue',
          displayValue: 'optionText',
        }}
      />
      <FormHelperText>{getErrorMessage('serviceTypeId')}</FormHelperText>
    </FormControl>
  );
}