import { Grid } from '@mui/material';
import { useFormContext, useWatch } from 'react-hook-form';

export default function ServiceCategoryImage() {
  const formCtx = useFormContext();
  const watchedImage = useWatch({ control: formCtx.control, name: 'imageGuid' });
  return (
    <Grid
      container
      alignItems='center'
    >
      <Grid item xs>
        <img src={`${window.REACT_APP_SERVICE_CATEGORY_TYPE_IMAGE_URI}${watchedImage}`} alt='No image found' />
      </Grid>
    </Grid>
  );
}