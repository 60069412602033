import { Grid } from '@mui/material';
import MarketAutocomplete from 'components/Header/MarketAutocomplete';
import TerritoryAutocomplete from 'components/autocomplete/TerritoryAutocomplete';
import GenericModal from 'components/modal/GenericModal';
import useModal, { IModal } from 'components/modal/useModal';
import useMarkets from 'hooks/useMarkets';
import useServiceTypeDetail from '../../hookStore/useServiceTypeDetail';


export const modalStoreKey = 'SelectTerritoryModal';
export interface ISelectTerritoryModal {
  marketId:string;
  territoryId:string;
}
export function createEmptySelectTerritoryModal() :ISelectTerritoryModal {
  return {
    marketId: '',
    territoryId: '',
  };
}
export interface ISelectTerritoryModalProps {
  postOnConfirm: (marketId:string, territoryId:string) => void;
}

export default function SelectTerritoryModal({ postOnConfirm }:ISelectTerritoryModalProps) {
  const {
    revertSelectedTab,
  } = useServiceTypeDetail();
  const { getModalState, closeModal, updateModalState } = useModal(modalStoreKey);
  const { selectedMarket, market } = useMarkets();

  const _state = getModalState() as ISelectTerritoryModal & IModal;
  const { marketId, territoryId } = _state;

  async function _onToggle(state:ISelectTerritoryModal & IModal) {
    let usedMarketId = selectedMarket?.id;
    if (marketId) {
      usedMarketId = marketId;
    }
    if (usedMarketId && territoryId) {
      postOnConfirm(usedMarketId, territoryId);
      closeModal(createEmptySelectTerritoryModal());
    }
  }

  return (
    <GenericModal
      modalStoreKey={modalStoreKey}
      title={'Select Territory'}
      modalContent={
        (
          <Grid container spacing={1} sx={{ maxWidth: '300px' }} justifyContent={'center'}>
            <Grid item xs='auto'>
              <MarketAutocomplete
                onChangeOverride={(option:any) => {
                  updateModalState({
                    ...getModalState(),
                    marketId: option.optionValue,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12}></Grid>
            <Grid item xs='auto'>
              <TerritoryAutocomplete
                marketId={marketId}
                onChangeOverride={(option:any) => {
                  updateModalState({
                    ...getModalState(),
                    territoryId: option.optionValue,
                  });
                }}
              />
            </Grid>
          </Grid>
        )
      }
      onConfirm={() => _onToggle(_state)}
      primaryDisabled={!marketId || !territoryId}
      onSecondaryAction={(e) => {
        closeModal(createEmptySelectTerritoryModal());
        revertSelectedTab();
      }}
      secondaryButtonText='Cancel'
    />
  );
}