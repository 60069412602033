import { useEffect, useState, createContext, useContext } from 'react';

export const ServiceProviderFormContext = createContext({
  isImageUploading: false,
  onImageUploading: () => () => {},
});

export const ServiceProviderFormContextProvider = ({ children }: any) => {
  const [isImageUploading, setIsImageUploading] = useState(false);

  function onImageUploading() {
    setIsImageUploading(true);
    return () => {
      setIsImageUploading(false);
    };
  }

  return (
    <ServiceProviderFormContext.Provider value={{
      isImageUploading, onImageUploading,
    }}>
      {children}
    </ServiceProviderFormContext.Provider>
  );
};
