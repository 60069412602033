
import { Button, Grid } from '@mui/material';
import useConfirmationModal from 'components/modals/useConfirmationModal';
import { createEmptyNeighborhoodLaunchForm, createEmptyServiceProviderLaunchDto, INeighborhoodLaunchForm } from 'model/neighborhoodLaunch';
import { useEffect } from 'react';
import { FieldValues, FormProvider, SubmitErrorHandler, SubmitHandler, useFieldArray, useForm, useFormContext } from 'react-hook-form';
import { ConfirmationModal } from './confirmationModal';
import NeighborhoodMultiSelect from './formComponents/NeighborhoodMultiSelect';
import ServiceProviderLaunchCards from './formComponents/ServiceProviderLaunchCards';
import ServiceProviderMultiSelect from './formComponents/ServiceProviderMultiSelect';
import useNeighborhoodLaunchTooling from './hookStore/useNeighborhoodLaunchTooling';
import ProviderCoverageZoneMultiSelect from './formComponents/ProviderCoverageZoneMultiSelect';


const launchProviderModalKey = 'launchProviderModalKey';

export interface ILaunchProviderValidationResult {
  isValid:boolean;
}
export interface INeighborhoodLaunchToolingFormInternalProps {
  initialFormData:INeighborhoodLaunchForm;
}

function ModalWrapper() {
  const { getValues, control } = useFormContext();
  const { save } = useNeighborhoodLaunchTooling();
  return (
    <ConfirmationModal
      body='Confirm launch.'
      modalKey={launchProviderModalKey}
      title={'Launch Providers?'}
      onConfirm={async (data) => {
        await save(data);
      }}
      successText='Launch providers submitted.'
    />
  );
}

function HelperForm() {
  const { control } = useFormContext();
  const { fields, append, replace, remove } = useFieldArray({
    control,
    name: 'serviceProviderLaunchDtos',
  });

  return (
    <>
      <Grid container item xs={12} sm={12} md={4} >
        <ServiceProviderMultiSelect
          onServiceProviderAdd={(id, name) => append(createEmptyServiceProviderLaunchDto(id, name))}
          onServiceProviderRemove={(index) => remove(index)}
          variant='neighborhoodLaunchTooling'
        />
      </Grid>
      <Grid container item xs={12} sm={12} md={4} >
        <NeighborhoodMultiSelect/>
      </Grid>
      {/* <Grid container item xs={12} sm={12} md={4}>
        <ProviderCoverageZoneMultiSelect/>
      </Grid> */}
      <Grid container item xs={12}>
        <ServiceProviderLaunchCards
          fields={fields}
          onReplace={replace}
          variant='neighborhoodLaunchTooling'
        />
      </Grid>
    </>
  );
}

function SubmitButton() {
  const { handleSubmit, setError, clearErrors, formState: { isSubmitting, isValid }, getValues } = useFormContext();
  const { openModal } = useConfirmationModal(launchProviderModalKey);

  function validate(data:INeighborhoodLaunchForm):ILaunchProviderValidationResult {
    const result = { isValid: true };
    const { serviceProviderLaunchDtos, neighborhoodIds, serviceProviderIds, providerCoverageZones } = data;
    for (let i = 0; i < serviceProviderLaunchDtos.length;i++) {
      let dto = serviceProviderLaunchDtos[i];
      if (dto.serviceOfferingOptions.filter(x => x.checked).length === 0) {
        setError(`serviceProviderLaunchDtos.${i}`, {
          type: 'manual',
          message: 'at least one service offering must be checked',
        });
        result.isValid = false;
      } else {
        clearErrors(`serviceProviderLaunchDtos.${i}`);
      }
    }


    if (neighborhoodIds.length === 0 && providerCoverageZones.length === 0) {
      setError('neighborhoodIds', {
        type: 'manual',
        message: 'must select at least one neighborhood or a supply zone',
      });
      setError('providerCoverageZones', {
        type: 'manual',
        message: 'must select at least one neighborhood or a supply zone',
      });
      result.isValid = false;
    } else {
      clearErrors('neighborhoodIds');
      clearErrors('providerCoverageZones');
    }
    if (serviceProviderIds.length === 0) {
      setError('serviceProviderIds', {
        type: 'manual',
        message: 'must select at least one service provider',
      });
      result.isValid = false;
    } else {
      clearErrors('serviceProviderIds');
    }
    return result;
  }

  const onSubmit : SubmitHandler<INeighborhoodLaunchForm> = data => {
    const { isValid: _isValid } = validate(data);
    if (_isValid) {
      openModal(data);
    }
  };
  const onSubmitError: SubmitErrorHandler<FieldValues> = data => {
    const { isValid: _isValid } = validate(getValues() as INeighborhoodLaunchForm);
    if (_isValid) {
      openModal(getValues());
    }
  };

  return (
    <Button
      disabled={isSubmitting || !isValid}
      variant='contained'
      type='button'
      onClick={(e) => {
        // TODO: figure out why this onSubmit gives me inconsistent ts errors
        // @ts-expect-error
        void handleSubmit(onSubmit, onSubmitError)(e);
      }}
    >
            Save
    </Button>
  );
}


function NeighborhoodLaunchToolingFormInternal({ initialFormData }:INeighborhoodLaunchToolingFormInternalProps) {
  const formContext = useForm<INeighborhoodLaunchForm>({ mode: 'onTouched', defaultValues: createEmptyNeighborhoodLaunchForm() });
  useEffect(() => {
    formContext.reset(initialFormData);
  }, [initialFormData]);
  return (
    <FormProvider {...formContext}>
      <form >
        <Grid container spacing={1}>
          <HelperForm/>
          <Grid container item xs={12} sm={12} md={8} >
            <Grid container item xs={12} justifyContent={{ sm: 'space-between' }} alignItems='flex-end'>
              <Grid item xs={12} sm={5} md={4} order={{ xs: 0, sm: 1 }}>
                <SubmitButton/>
              </Grid>
            </Grid>
          </Grid>
          <ModalWrapper/>
        </Grid>
      </form>
    </FormProvider>
  );
}

export default function NeighborhoodLaunchToolingForm() {
  const { formData, init } = useNeighborhoodLaunchTooling();
  useEffect(() => {
    void init();
  }, []);
  return (
    <NeighborhoodLaunchToolingFormInternal initialFormData={formData}/>
  );
}