import createStore from 'hooks/hookStore';
import useToast from 'components/toast/useToast';
import { useLoading } from 'components/Layout/Loading';
import { useState, useEffect } from 'react';
import { IDiscountSchedule } from 'model/discountSchedule';
import { IPreviewFile } from 'model/imageUpload';
import { IServiceOffering, IServiceOfferingImageRef } from 'model/serviceOffering';
import { IServiceProvider } from 'model/serviceProvider';
import { useParams } from 'react-router-dom';
import { findInternalServiceProviderById } from 'api/serviceProviderApi';
import { findServiceOfferingById } from 'api/serviceOfferingApi';
import { findDiscountScheduleById } from 'api/discountScheduleApi';
import { sortByString } from 'util/sortFunctions';
import { IMarket, MarketUtils } from 'model/markets';
import { findMarkets } from 'api/marketsApi';
import { IDropdownOption } from 'model/dropdown';
import { getAllServiceProviderPhoneNumbersList } from 'util/serviceProviderUtil';
import { ICheckNumbersAgainstDNCListRequest } from 'model/phoneNumberValidation';
import { checkNumbersAgainstDNCList } from 'api/phoneNumberValidatorApi';

const loadingKey = 'ProviderOnboardingStore';
type ServiceOfferingCloneOption = {
  key: string;
  optionText: string;
  optionValue: string;
  ancillary: IServiceOffering;
}

type ProviderOnboardingStore = {
  cadenceType: string;
  currentServiceOffering: IServiceOffering;
  currentDiscountSchedule: IDiscountSchedule;
  discountSchedules: IDiscountSchedule[];
  files: IPreviewFile[];
  isImageUploading: boolean;
  markets: IDropdownOption[];
  onImageUploading: Function;
  provider: IServiceProvider;
  removeFile: Function;
  serviceType: string;
  serviceOfferingCloneProvider: IServiceProvider;
  serviceOfferingCloneOptions: ServiceOfferingCloneOption[];
  setCadenceType: Function;
  setCurrentDiscountSchedule: Function;
  setCurrentServiceOffering: Function;
  setDiscountSchedules: Function;
  setProvider: Function;
  setProviderId: Function;
  setServiceType: Function;
  updateFiles: Function;
  pendingDeleteImages: IServiceOfferingImageRef[];

}

const { get, update, registerListener, unregisterListener } = createStore<ProviderOnboardingStore>('ProviderOnboardingStore', {
  cadenceType: '',
  currentServiceOffering: {} as IServiceOffering,
  currentDiscountSchedule: {} as IDiscountSchedule,
  discountSchedules: [] as IDiscountSchedule[],
  files: [] as IPreviewFile[],
  isImageUploading: false,
  markets: [] as IDropdownOption[],
  onImageUploading: () => () => {},
  provider: {} as IServiceProvider,
  removeFile: files => files,
  serviceType: '',
  serviceOfferingCloneProvider: {} as IServiceProvider,
  serviceOfferingCloneOptions: [] as ServiceOfferingCloneOption[],
  setCadenceType: (cadenceType) => cadenceType,
  setCurrentDiscountSchedule: (discountSchedule) => discountSchedule,
  setCurrentServiceOffering: (serviceOffering) => serviceOffering,
  setDiscountSchedules: (dss) => dss,
  setProvider: (p) => p,
  setProviderId: (id) => id,
  setServiceType: (serviceType) => serviceType,
  updateFiles: files => files,
  pendingDeleteImages: [],
});

export default function useProviderSetupStore() {
  const setState = useState(get())[1];
  const { onLoading, doneLoading } = useLoading(loadingKey);
  const { createErrorToast, createInfoToast } = useToast();
  const { providerId, serviceOfferingId, discountScheduleId } = useParams();
  useEffect(() => {
    registerListener(setState);
    return () => {
      unregisterListener(setState);
    };
  }, []);

  async function init(market:IMarket | null) {
    onLoading();
    try {
      if (market == null) {
        console.log('waiting to load market');
        //we should wait for the market to load before making any requests
        return;
      }

      if (providerId) {
        const providerResp = await findInternalServiceProviderById(providerId);
        if (!providerResp.data.marketId) {
          providerResp.data.marketId = market.id;
        }
        const marketsResp = await findMarkets();
        const markets = MarketUtils.convertToDropdownOptions(marketsResp.data);

        update({
          ...get(),
          markets: markets,
          provider: providerResp?.data,
        });
      }

      if (!providerId) {
        const marketsResp = await findMarkets();
        const markets = MarketUtils.convertToDropdownOptions(marketsResp.data);

        update({
          ...get(),
          currentServiceOffering: {} as IServiceOffering,
          currentDiscountSchedule: {} as IDiscountSchedule,
          markets: markets,
          provider: {} as IServiceProvider,
        });
      }

      if (serviceOfferingId) {
        const serviceOfferingResp = await findServiceOfferingById(serviceOfferingId);
        update({
          ...get(),
          currentServiceOffering: serviceOfferingResp?.data,
        });
      }

      if (!serviceOfferingId) {
        update({
          ...get(),
          currentServiceOffering: {} as IServiceOffering,
        });
      }

      if (discountScheduleId) {
        const discountScheduleResp = await findDiscountScheduleById(discountScheduleId);
        update({
          ...get(),
          currentDiscountSchedule: discountScheduleResp?.data,
        });
      }

      if (!discountScheduleId) {
        update({
          ...get(),
          currentDiscountSchedule: {} as IDiscountSchedule,
        });
      }
    } catch (e:any) {
      console.error('error initializing providerSetupStore', e);
    }
    doneLoading(300);
  }

  function onImageUploading() {
    update({
      ...get(),
      isImageUploading: true,
    });
    return () => update({
      ...get(),
      isImageUploading: false,
    });
  }

  function updateFiles(nextFiles:IPreviewFile[]) {
    const { files } = get();
    const newFiles = [...files, ...nextFiles];
    update({
      ...get(),
      files: newFiles,
    });
  }

  function removeFile(file:IPreviewFile) {
    const { files } = get();
    const newFiles = files.filter(x => x !== file);
    update({
      ...get(),
      files: newFiles,
    });
  }

  function setProvider(prov:IServiceProvider) {
    update({
      ...get(),
      provider: prov,
    });
  }

  function setServiceType(serviceType:string) {
    update({
      ...get(),
      serviceType,
    });
  }

  function setDiscountSchedules(discSchedules:IDiscountSchedule[]) {
    update({
      ...get(),
      discountSchedules: discSchedules,
    });
  }

  function setCadenceType(cadenceType:string) {
    update({
      ...get(),
      cadenceType,
    });
  }

  function setCurrentServiceOffering(currentServiceOffering:IServiceOffering) {
    update({
      ...get(),
      currentServiceOffering,
    });
  }

  function setCurrentDiscountSchedule(currentDiscountSchedule:IDiscountSchedule) {
    update({
      ...get(),
      currentDiscountSchedule,
    });
  }

  function setServiceOfferingCloneProvider(provider:IServiceProvider) {
    update({
      ...get(),
      serviceOfferingCloneProvider: provider,
    });
  }

  function setServiceOfferingCloneOptions(serviceOfferingCloneOptions:IServiceOffering[]) {
    const formatted = serviceOfferingCloneOptions
      .map((x, i) => ({
        key: `${x.id}`,
        optionText: `${x.name}`,
        optionValue: `${x.id}`,
        ancillary: x,
      }))
      .sort(sortByString('optionText'));

    return update({
      ...get(),
      serviceOfferingCloneOptions: formatted,
    });
  }


  async function maybeCheckNumbersAgainstDNCList(data:any) {
    var phoneNumbersArray = getAllServiceProviderPhoneNumbersList(data);

    if (phoneNumbersArray.length > 0) {
      var request:ICheckNumbersAgainstDNCListRequest = {
        phoneNumbers: phoneNumbersArray,
      };
      var resp = await checkNumbersAgainstDNCList(request);

      return resp.data.doNotCallNumbers;
    }
  }


  /**
   * Mark an image ref for delete.
   * @param data
   * @param formCtx
   */
  function markForDelete(data:IServiceOfferingImageRef, formCtx: any) {
    const { pendingDeleteImages } = get();
    const imageRefs = formCtx.getValues('imageRefs');
    const after = imageRefs.filter(x => x !== data);
    formCtx.setValue('imageRefs', after);
    pendingDeleteImages.push(data);
    update({ ...get(), pendingDeleteImages });
  }

  /**
   * This will only work before submission. The image ref will be removed from the list.
   * @param data
   * @param formCtx
   */
  function unmarkForDelete(data:IServiceOfferingImageRef, formCtx: any) {
    const { pendingDeleteImages } = get();
    const imageRefs = formCtx.getValues('imageRefs');
    const after = pendingDeleteImages.filter(x => x !== data);
    formCtx.setValue('imageRefs', [...imageRefs, data]);
    update({
      ...get(),
      pendingDeleteImages: after,
    });
  }

  function resetPendingDeleteImages() {
    update({
      ...get(),
      pendingDeleteImages: [],
    });
  }

  return {
    ...get(),
    loadingKey,
    init,
    onImageUploading,
    setProvider,
    setServiceOfferingCloneOptions,
    setServiceOfferingCloneProvider,
    setServiceType,
    removeFile,
    updateFiles,
    setCadenceType,
    setCurrentDiscountSchedule,
    setCurrentServiceOffering,
    setDiscountSchedules,
    maybeCheckNumbersAgainstDNCList,
    markForDelete,
    unmarkForDelete,
    resetPendingDeleteImages,
  };
}