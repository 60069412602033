import Axios from 'axios';
import { CreateProviderCrmOptionRequest } from 'model/providerCrmOptionLookUp';

const apiUrl = window.REACT_APP_BASE_API_URI;
const baseUrl = `${apiUrl}/providerCrmOptions/v2`;

export function findAllProviderCrmOptions() {
  return Axios.get(`${baseUrl}/findAll`);
}

export function createProviderCrmOption(data: CreateProviderCrmOptionRequest) {
  return Axios.post(`${baseUrl}/create`, data);
}