import { Grid, Typography } from '@mui/material';
import HookConfirmationModal from 'components/modals/HookConfirmationModal';

export interface IGenericDeleteModalProps {
  modalKey:string;
  onDelete: (id:any, desc:any) => Promise<any>;
}

export default function GenericDeleteModal({
  modalKey,
  onDelete,
}:IGenericDeleteModalProps) {

  return (
    <HookConfirmationModal
      title={() => 'Confirm Delete'}
      body=''
      modalKey={modalKey}
      message={(confirmArgs, cancelModal) => (
        <Grid container spacing={1} alignItems='center' justifyContent='center' >
          <Grid item xs={12} paddingTop={'30px'} paddingBottom={'25px'}>
            <Typography variant='body1'>
                Are you sure you want to delete this record?
            </Typography>
          </Grid>
        </Grid>
      )}
      onConfirm={async (data) => {
        void onDelete(data.id, data.desc);
      }}
    />
  );
}