import { FormControl, InputLabel, FormHelperText } from '@mui/material';
import { HookSelect } from 'components/reactHookForm';
import useErrorState from 'hooks/reactHookForm/useErrorState';
import { useFormContext } from 'react-hook-form';
import useNeighborhoodServiceOfferingDetail from '../hookStore/useNeighborhoodServiceOfferingDetail';
import ServiceProviderAutocomplete from 'components/serviceProviderAutocomplete/ServiceProviderAutocomplete';

export function ProviderSelect() {
  const formContext = useFormContext();
  const { onServiceProviderChange } = useNeighborhoodServiceOfferingDetail();
  const { isError, getErrorMessage } = useErrorState(formContext);
  const watchServiceProviderId = formContext.watch('providerId');
  return (
    <ServiceProviderAutocomplete
      label='Service Provider'
      fieldName='providerId'
      controlledValue={watchServiceProviderId}
      placeholder='Search and check service provider'
      required={true}
      formless={false}
      postOnChange={ async (formCtx, selectedOption, reason, details) => {
        if (selectedOption?.optionValue) {
          await onServiceProviderChange(selectedOption.optionValue);
        }
      }}
    />
  );
}
