import { ICheckable } from './ancillary';
import { IDropdownOption } from './dropdown';
import { INeighborhoodServiceOffering } from './neighborhoodServiceOffering';

export const RequestManagementTypes = {
  DELETE_PROVIDER_LAUNCH: 'DELETE_PROVIDER_LAUNCH',
  DELETE_CLONED_NSOS: 'DELETE_CLONED_NSOS',
  UNSET: 'UNSET',
};

export const RequestManagementTypeOptions = [
  {
    key: RequestManagementTypes.DELETE_PROVIDER_LAUNCH,
    optionValue: RequestManagementTypes.DELETE_PROVIDER_LAUNCH,
    optionText: 'Launch Provider',
  },
  {
    key: RequestManagementTypes.DELETE_CLONED_NSOS,
    optionValue: RequestManagementTypes.DELETE_CLONED_NSOS,
    optionText: 'Clone Neighborhood',
  },
  {
    key: RequestManagementTypes.UNSET,
    optionValue: RequestManagementTypes.UNSET,
    optionText: 'Not Set',
  },
];

export interface INeighborhoodLaunchForm {
  neighborhoodIds:string[];
  serviceProviderIds:string[];
  providerCoverageZones:string[];
  serviceProviderLaunchDtos:IServiceProviderLaunchDTO[];
}

export function createEmptyNeighborhoodLaunchForm():INeighborhoodLaunchForm {
  return {
    neighborhoodIds: [],
    serviceProviderIds: [],
    providerCoverageZones: [],
    serviceProviderLaunchDtos: [],
  };
}

export interface IProviderCoverageAreaLaunchToolingDto {
  serviceProviderLaunchDtos:IServiceProviderLaunchDTO[];
}

export interface IProviderCoverageAreaLaunchToolingForm {
  dryRun:boolean;
  serviceProviderId:IDropdownOption | null;
  serviceProviderLaunchDtos:IServiceProviderLaunchDTO[];
}

export function createEmptyProviderCoverageAreaLaunchToolingForm():IProviderCoverageAreaLaunchToolingForm {
  return {
    serviceProviderId: null,
    serviceProviderLaunchDtos: [],
    dryRun: true,
  };
}

export interface IServiceProviderLaunchDTO {
  serviceProviderId:string;
  serviceProviderName:string;
  neighborhoodIds:string[];
  serviceOfferingIds:string[];
  serviceOfferingOptions: ICheckable[];
}

export interface ILaunchProvidersResult {
  results:IServiceProviderLaunchResult[];
  valid:boolean;
  message:string;
}

export interface IServiceProviderLaunchResult {
  serviceProviderId:string;
  neighborhoodId:string;
  serviceOfferingId:string;
  discountScheduleId:string;
  nso:INeighborhoodServiceOffering;
  valid:boolean;
  message:string;
}

export function createEmptyServiceProviderLaunchDto(serviceProviderId:string, serviceProviderName:string):IServiceProviderLaunchDTO {
  return {
    serviceProviderId,
    serviceProviderName,
    neighborhoodIds: [],
    serviceOfferingIds: [],
    serviceOfferingOptions: [],
  };
}

export function createEmptyLaunchProvidersResult():ILaunchProvidersResult {
  return {
    valid: false,
    message: '',
    results: [],
  };
}


export interface ICloneNeighborhoodForm {
  fromNeighborhood:any;
  toNeighborhoodIds:string[];
}

export function createEmptyCloneNeighborhoodForm():ICloneNeighborhoodForm {
  return {
    fromNeighborhood: null,
    toNeighborhoodIds: [],
  };
}

export interface IRequestManagementForm {
  requestId:string;
  requestAction: 'DELETE_PROVIDER_LAUNCH' | 'DELETE_CLONED_NSOS' | 'UNSET';
}

export function createEmptyRequestManagementFrom():IRequestManagementForm {
  return {
    requestId: '',
    requestAction: 'UNSET',
  };
}

export interface IReconcileNSOsDTO {
  dryRun:boolean;
  neighborhoodId?:string;
  serviceProviderId?:string;
}