import { Grid, Typography } from '@mui/material';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';

import { useEffect } from 'react';
import { IServiceOfferingAllocation } from 'model/customerUpload';
import HookPercentField from 'components/reactHookForm/HookPercentField';
import useCustomerUpload from '../hookStore/customerUploadHookStore';

export default function EditServiceOfferingAllocations() {
  const formContext = useFormContext();
  const {
    onEditServiceAllocationsSelectedProviderChanged,
    serviceTypeNameMap,

  } = useCustomerUpload();

  const serviceOfferingAllocations = useWatch({
    control: formContext.control,
    name: 'serviceOfferingAllocations',
  });

  const selectedServiceProvider = useWatch({
    control: formContext.control,
    name: 'selectedServiceProvider',
  });

  useEffect(() => {
    void onEditServiceAllocationsSelectedProviderChanged(formContext, selectedServiceProvider?.optionValue);
  }, [selectedServiceProvider]);

  if (!selectedServiceProvider) {
    return null;
  }

  return (
    <Grid container item spacing={1} sx={{ padding: '10px' }}>
      <Typography variant='h6'>Service Offering Allocations</Typography>
      <Grid item xs={12}></Grid>
      {
        Object.keys(serviceOfferingAllocations).map((requestId:any, index) => {
          let data = formContext.getValues(`serviceOfferingAllocations.${requestId}`);
          const serviceOfferingAllocation = data as IServiceOfferingAllocation[];
          return (
            <Grid container item xs={12} lg={6} spacing={1} key={requestId}>
              <Grid item xs={12}>
                <Typography variant='h6'>Request Id: {requestId}</Typography>
              </Grid>
              {
                serviceOfferingAllocation.map((item:any, inner_index) => {
                  return (

                    <Grid item xs={6} lg={3} key={`${requestId}_${item.serviceOfferingId}`}>
                      <Typography variant='caption' color='text.secondary'>{`${serviceTypeNameMap[item.serviceTypeId]}`}</Typography>
                      <HookPercentField
                        required
                        name={`serviceOfferingAllocations.${requestId}.${inner_index}.percent`}
                        label={`${item.name}`}
                      />

                    </Grid>
                  );
                })
              }
            </Grid>
          );
        })
      }
    </Grid>
  );
}