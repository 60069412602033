import { useNavigate } from 'react-router-dom';
import ServiceProviderAutocomplete from 'components/serviceProviderAutocomplete/ServiceProviderAutocomplete';

export function ProviderSelect() {
  const navigate = useNavigate();

  async function onServiceProviderChange(providerId) {
    // jump the user to the summary step so they can see what they have completed
    // for this provider so far
    navigate(`/providerOnboarding/${providerId}/summary`);
  };

  return (
    <ServiceProviderAutocomplete
      label={'Service Provider'}
      fieldName='providerId'
      postOnChange={(_formCtx, selectedOption, reason, details) => {
        void onServiceProviderChange(selectedOption?.optionValue);
      }}
      formless={false}
      placeholder={''}
      required={false}
    />
  );
}
