
import { getSelfRoles } from 'api/individualApi';
import { useLoading } from 'components/Layout/Loading';
import { createModuleStore } from 'hooks/hookStore';
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

type AdminSelf = {
  roles: string[];

}

type ModuleSelf = {
  rolesLoaded:boolean;
}


const { get, getModuleScoped, update, registerListener, unregisterListener } = createModuleStore<AdminSelf, ModuleSelf>('adminSelf', {
  roles: [],
},
{
  rolesLoaded: false,
});

const loadingKey = 'adminSelf';

export default function useSelf() {
  const setState = useState(get())[1];
  const { onLoading, doneLoading } = useLoading(loadingKey);
  const { signUpLinkToken } = useParams();
  const location = useLocation();

  useEffect(() => {
    registerListener(setState);
    return () => {
      unregisterListener(setState);
    };
  }, []);

  useEffect(() => {
    if (location.pathname !== '/reset' && location.pathname !== '/sign-in' && !signUpLinkToken) {
      const moduleScope = getModuleScoped();
      if (!moduleScope.rolesLoaded) {
        moduleScope.rolesLoaded = true;
        void populateRoles();
      }
    }

  }, []);

  async function populateRoles() {
    const rolesRes = await getSelfRoles();
    update({
      ...get(),
      roles: rolesRes.data.map(x => x.authority),
    });
  }


  return {
    loadingKey: loadingKey,
    ...get(),
  };
}