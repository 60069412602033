import { FormControl, InputLabel, FormHelperText } from '@mui/material';
import { HookSelect } from 'components/reactHookForm';
import useErrorState from 'hooks/reactHookForm/useErrorState';
import { useFormContext } from 'react-hook-form';
import useNeighborhoodServiceOfferingDetail from '../hookStore/useNeighborhoodServiceOfferingDetail';

export function ServiceOfferingSelect() {
  const formCtx = useFormContext();
  const { serviceOfferings } = useNeighborhoodServiceOfferingDetail();
  const { isError, getErrorMessage } = useErrorState(formCtx);

  return (
    <FormControl variant='standard' fullWidth error={isError('serviceOfferingId')}>
      <InputLabel htmlFor="service-offerings">Service Offering</InputLabel>
      <HookSelect
        control={formCtx.control}
        rules={{
          required: { value: true, message: 'Required' },
        }}
        name={'serviceOfferingId'}
        id='service-offerings'
        menuItems={serviceOfferings}
        postOnChange={() => {
          formCtx.setValue('discountScheduleId', '');
        }} />
      <FormHelperText>{getErrorMessage('serviceOfferingId')}</FormHelperText>
    </FormControl>
  );
}
