
export class NeighborhoodLaunchType {
  static RESIDENT = 'RESIDENT';
  static MINING = 'MINING';
  static GENERIC = 'GENERIC';
  static HOME_OWNERS_ASSOCIATION = 'HOME_OWNERS_ASSOCIATION';

  static list = [
    {
      id: NeighborhoodLaunchType.RESIDENT,
      name: 'Resident',
    },
    {
      id: NeighborhoodLaunchType.MINING,
      name: 'Mining',
    },
    {
      id: NeighborhoodLaunchType.GENERIC,
      name: 'Generic',
    },
    {
      id: NeighborhoodLaunchType.HOME_OWNERS_ASSOCIATION,
      name: 'Home Owners Association',
    },
  ];
}

export class CampaignType {
  //also known as "mining"
  static LAUNCH_LEADER_ACQUISITION = 'LAUNCH_LEADER_ACQUISITION';
  static LAUNCH = 'LAUNCH';
  static FOLLOW_UP = 'FOLLOW_UP';


  static list = [
    {
      id: CampaignType.LAUNCH_LEADER_ACQUISITION,
      name: 'Mining',
    },
    {
      id: CampaignType.LAUNCH,
      name: 'Launch',
    },
    {
      id: CampaignType.FOLLOW_UP,
      name: 'Follow Up',
    },
  ];
}

export class ToAddressOption {
  static TO_MY_STREET = 'TO_MY_STREET';
  static TO_MY_NEIGHBORHOOD = 'TO_MY_NEIGHBORHOOD';
  static REAL_NAME = 'REAL_NAME';

  static list = [
    {
      id: ToAddressOption.TO_MY_STREET,
      name: 'To My Street',
    },
    {
      id: ToAddressOption.TO_MY_NEIGHBORHOOD,
      name: 'To My Neighborhood',
    },
    {
      id: ToAddressOption.REAL_NAME,
      name: 'Real Name',
    },
  ];
}

export class ReturnAddressOption {

  static LAUNCH_LEADER = 'LAUNCH_LEADER';
  static STREETFAIR_MARKET_HQ = 'STREETFAIR_MARKET_HQ';
  static NAME_ONLY = 'NAME_ONLY';
  static NO_RETURN_ADDRESS = 'NO_RETURN_ADDRESS';
  static OTHER = 'OTHER';


  static list = [
    {
      id: ReturnAddressOption.LAUNCH_LEADER,
      name: 'Launch Leader Address',
    },
    {
      id: ReturnAddressOption.STREETFAIR_MARKET_HQ,
      name: 'StreetFair HQ Address',
    },
    {
      id: ReturnAddressOption.NAME_ONLY,
      name: 'Only name',
    },
    {
      id: ReturnAddressOption.NO_RETURN_ADDRESS,
      name: 'None',
    },
    {
      id: ReturnAddressOption.OTHER,
      name: 'Other',
    },
  ];
}

export class EnvelopeType {
  static ROBOT_WRITTEN = 'ROBOT_WRITTEN';
  static HANDWRITTEN = 'HANDWRITTEN';
  static PRINTED = 'PRINTED';

  static list = [
    {
      id: EnvelopeType.ROBOT_WRITTEN,
      name: 'Robot Written',
    },
    {
      id: EnvelopeType.HANDWRITTEN,
      name: 'Handwritten',
    },
    {
      id: EnvelopeType.PRINTED,
      name: 'Printed',
    },
  ];
}

export class NeighborhoodLetterStatus {
  static DRAFT = 'DRAFT';
  static READY = 'READY';
  static SENT = 'SENT';

  static list = [
    {
      id: NeighborhoodLetterStatus.DRAFT,
      name: 'Draft',
    },
    {
      id: NeighborhoodLetterStatus.READY,
      name: 'Ready',
    },
    {
      id: NeighborhoodLetterStatus.SENT,
      name: 'Sent',
    },
  ];

  static getDisplayName(status:string):string {
    let found = NeighborhoodLetterStatus.list.find(x => x.id === status);
    return found ? found.name : status;
  }
}

export class VariablePrintTemplate {
  //These are defined on the backend in VariablePrintTemplate. As of Oct 28, we are not storing this in the db, but statically in code.
  static FOLLOWUP_LETTER_TEMPLATE = '0EFF9C66-0D37-4D08-99D2-A8DDC3010C38';
  static MK2A_VARIABLE = '0CCB3FEB-88DE-48F7-A489-66A18DF7A735';
  static MK2AD_VARIABLE = '97A64EF0-AD71-4C1B-9B46-23B2B7041975';
  static MINING_TEMPLATE_MINUA = '61FFC4F8-C90E-4998-BE04-5DFC01A1B487';
  static TEMPLATE_GENNP_LAUNCH = '7D196742-B9C7-48C3-91D6-AC6A4C3B833E';
  static TEMPLATE_LAUNCH_LETTER_SB1A = '54464E1F-AB9C-4436-8BC5-52F8CA3C8D43';
  static TEMPLATE_OBLETTER1D_VARIABLE= '050604B3-7D39-497D-85C0-723ACB708EB4';

  static list = [
    {
      id: VariablePrintTemplate.FOLLOWUP_LETTER_TEMPLATE,
      name: 'Followup_Letter_Template_OBLetter1_Variable_genericServices',
    },
    {
      id: VariablePrintTemplate.MK2A_VARIABLE,
      name: 'Letter_Template_mk2a_Variable',
    },
    {
      id: VariablePrintTemplate.MK2AD_VARIABLE,
      name: 'Letter_Template_mk2ad_Variable',
    },
    {
      id: VariablePrintTemplate.MINING_TEMPLATE_MINUA,
      name: 'Mining_Template_LetterMinUA_Variable',
    },
    {
      id: VariablePrintTemplate.TEMPLATE_GENNP_LAUNCH,
      name: 'Template_GenNP_launch',
    },
    {
      id: VariablePrintTemplate.TEMPLATE_LAUNCH_LETTER_SB1A,
      name: 'Template_Launch_Letter_SB1A',
    },
    {
      id: VariablePrintTemplate.TEMPLATE_OBLETTER1D_VARIABLE,
      name: 'TEMPLATE_OBLetter1D_Variable',
    },

  ];

  static getDisplayName(status:string):string {
    let found = VariablePrintTemplate.list.find(x => x.id === status);
    return found ? found.name : status;
  }
}

export class VariablePrintTemplatePropertyNames {

  static NeighborhoodName = 'Neighborhood_Name';
  static NeighborhoodLeadName = 'NL_Name';
  static NeighborhoodLeadStreetName = 'NL_Street_Name';
  static PSMonth = 'PS_Month';
  static RegionName = 'Region_Name';
  static OfferValue = 'Offer_Value';
  static HoodNumber = 'Hood_Number';
  static Season = 'Season';

}
