import { Grid } from '@mui/material';
import { saveNeighborhoodServiceOffering, updateNeighborhoodServiceOffering } from 'api/neighborhoodServiceOfferingApi';
import PrimarySecondaryButtonGroup from 'components/processButton/primarySecondaryButtonGroup';
import { HookTextField } from 'components/reactHookForm';
import PageHeader from 'components/SectionHeaders/PageHeader';
import { createEmptyNeighborhoodServiceOfferingForm, INeighborhoodServiceOffering, INeighborhoodServiceOfferingForm } from 'model/neighborhoodServiceOffering';
import { useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import useNeighborhoodServiceOfferingDetail from './hookStore/useNeighborhoodServiceOfferingDetail';
import { DiscountScheduleSelect } from './nsoDetailComponents/DiscountScheduleSelect';
import { ServiceOfferingSelect } from './nsoDetailComponents/ServiceOfferingSelect';
import { NeighborhoodSelect } from './nsoDetailComponents/NeighborhoodSelect';
import { NsoToggle } from './nsoDetailComponents/NsoToggle';
import { ProviderSelect } from './nsoDetailComponents/ProviderSelect';
import { GeneratedNames } from './nsoDetailComponents/GeneratedNames';
import NeighborhoodPricing from './nsoDetailComponents/NeighborhoodPricing';
import PrimaryButton from 'components/buttons/PrimaryButton';
import useModal from 'components/modal/useModal';
import SendClaimYourBusinessModal, { createEmptySendClaimYourBusinessModal, modalStoreKey } from './SendClaimYourBusinessModal';


export default function NeighborhoodServiceOfferingDetail() {
  const navigate = useNavigate();

  const { neighborhoodServiceOfferingId } = useParams();
  const { init, getTemplatedName, goBack, getDisabledDateToSave, formValues } = useNeighborhoodServiceOfferingDetail();
  const formContext = useForm<INeighborhoodServiceOfferingForm>({ mode: 'onChange', defaultValues: createEmptyNeighborhoodServiceOfferingForm() });
  const { openModal } = useModal(modalStoreKey);

  const onCreateClaimYourBusinessMessageClick = () => {
    return openModal(createEmptySendClaimYourBusinessModal());
  };

  useEffect(() => {
    void init();
  }, []);

  useEffect(() => {
    formContext.reset(formValues);
  }, [formValues]);


  const handleSubmit : SubmitHandler<INeighborhoodServiceOfferingForm> = (values) => {
    let now = new Date();
    if (neighborhoodServiceOfferingId) {
      values.id = neighborhoodServiceOfferingId;
      values.name = getTemplatedName(values.neighborhoodId, values.serviceOfferingId);
      values.disabledDate = getDisabledDateToSave(values, now);

      if (typeof values.providerId !== 'string') {
        //TODO: figure out a better way to handle this in the react dropdowns later.
        values.providerId = (values.providerId as any)?.optionValue;
      }
      if (typeof values.neighborhoodId !== 'string') {
        //TODO: figure out a better way to handle this in the react dropdowns later.
        values.neighborhoodId = (values.neighborhoodId as any)?.optionValue;
      }

      updateNeighborhoodServiceOffering(values)
        .then(function (response) {
          navigate('/neighborhood-service-offerings?code=success');
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      let usedProviderId = values.providerId;
      if (typeof values.providerId !== 'string') {
        //TODO: figure out a better way to handle this in the react dropdowns later.
        usedProviderId = (values.providerId as any)?.optionValue;
      }

      const neighborhoodServiceOfferings = values.neighborhoodIds.map(x => {
        let nso:INeighborhoodServiceOffering = {
          name: getTemplatedName(x, values.serviceOfferingId),
          altName: values.altName,
          serviceOfferingId: values.serviceOfferingId,
          discountScheduleId: values.discountScheduleId,
          neighborhoodId: x,
          providerId: usedProviderId,
          price: values.price,
          disabledDate: getDisabledDateToSave(values, now),
        };
        return nso;
      });
      const dataToSave = { neighborhoodServiceOfferings };
      saveNeighborhoodServiceOffering(dataToSave)
        .then(function (response) {
          navigate('/neighborhood-service-offerings?code=success');
        })
        .catch(function (error) {
          console.log(error);
        });
    };
  };
  const shouldShowSendClaimYourBusinessMessageButton = neighborhoodServiceOfferingId
    && formContext.getValues().neighborhoodId === window.REACT_APP_NEIGHBORVILLE_UUID;

  return (
    <FormProvider {...formContext}>
      <form onSubmit={formContext.handleSubmit(handleSubmit)}>
        <Grid container className='pageGridContainer'>
          <PageHeader headerText={'Manage Neighborhood Service Offering'} />
          <Grid container item xs={12} direction={'row'} marginTop={'15px'}>
            <Grid item xs={3}>
              <NsoToggle/>
            </Grid>
            { shouldShowSendClaimYourBusinessMessageButton &&
              <Grid item xs={6}>
                <PrimaryButton
                  onClick={onCreateClaimYourBusinessMessageClick}
                >
                Send "Claim Your Business" Message
                </PrimaryButton>
              </Grid>
            }
          </Grid>
          <Grid item xs={12}>
            <h4>Generated Names</h4>
          </Grid>
          <Grid item xs={6}>
            <GeneratedNames/>
          </Grid>
          <Grid item xs={12}>
            <h4>Alternative name</h4>
          </Grid>
          <Grid item xs={6}>
            <HookTextField
              name='altName'
              label='Alternative Name'
              validationRules={{
                validate: function (value) {
                  if (value === null || value === '' || value === undefined) {
                    return true;
                  }
                  if (value.length > 100) {
                    return 'Name must be less than 100 characters';
                  }
                  return true;
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <h4>Step 2: Select a Service Provider</h4>
          </Grid>
          <Grid item xs={6}>
            <ProviderSelect/>
          </Grid>
          <Grid item xs={12}>
            <h4>Step 3: Select a Neighborhood</h4>
          </Grid>
          <Grid item xs={6}>
            <NeighborhoodSelect/>
          </Grid>
          <Grid item xs={12}>
            <h4>Step 4: Select a Service Offering</h4>
          </Grid>
          <Grid item xs={6}>
            <ServiceOfferingSelect/>
          </Grid>
          <Grid item xs={12}>
            <h4>Step 5: Apply a Discount Schedule</h4>
          </Grid>
          <Grid item xs={6}>
            <DiscountScheduleSelect/>
          </Grid>
          <Grid item xs={12}>
            <h4>Step 6: Set neighborhood level pricing (Optional)</h4>
          </Grid>
          <Grid item xs={6}>
            <NeighborhoodPricing/>
          </Grid>
          <PrimarySecondaryButtonGroup
            primaryButtonText = {'Submit'}
            primaryButtonAction = {() => console.log('submitting form')}
            secondaryButtonText = {'Cancel'}
            secondaryButtonAction = {goBack}
          />
        </Grid>
        <SendClaimYourBusinessModal serviceProviderId={formContext.getValues().providerId} nsoId={neighborhoodServiceOfferingId}/>
      </form>
    </FormProvider>
  );
}