import { Grid, Typography } from '@mui/material';
import useIndividualsHookStore from '../individualsHookStore';
import get from 'lodash.get';
import { IndividualRewardTypes } from 'model/individual';
import DetailItem from '../_components/DetailItem';

export default function LaunchLeaderDetails() {
  const { individual } = useIndividualsHookStore();
  const rewardKey = IndividualRewardTypes.LAUNCH_LEADER_APPLICATION;
  const offer = get(individual.rewards, rewardKey, {});
  return (
    <Grid container flexDirection="column">
      <Grid item>
        <DetailItem label="Status" value={individual.launchLeaderStatus} />
      </Grid>
      <Grid item>
        <DetailItem label="Origin" value={individual.launchLeaderApplicationOrigin} />
      </Grid>
      <Grid item>
        <DetailItem label="Reward Value" value={`$${offer.amount}`} />
      </Grid>
      <Grid item>
        <DetailItem label="Reward Value Type" value={offer.rewardOfferType ?? 'Credits'} />
      </Grid>
      <Grid item>
        <DetailItem label="Reward Status" value={`${offer.status}`} />
      </Grid>
      <Grid item>
        <DetailItem label="Is a Referral" value={individual.referredBy ? 'True' : 'False'} />
      </Grid>
    </Grid>
  );
}