import { ICategorizedServiceType, ISampleServiceCategoryAndServiceTypeName, IServiceCategory, IServiceTypeInfo, IServiceTypeInfoMetadatum, IServiceTypeTree } from 'model/serviceCategory';
import { IServiceType } from 'model/serviceType';
import { nullableIntSort } from 'util/sortFunctions';


export class ServiceTypeInfoUtil {

  /// Created to support legacy method of finding NSOs by service type and service category name
  /// This method is used to get the service type name and service category name for a given service type id.
  /// Long term this will not be used in favor of always retrieving service type data from the service type id.
  static findMatchingServiceCategoryAndServiceTypeNames(serviceTypeId:string, serviceTypeInfo:IServiceTypeInfo):ISampleServiceCategoryAndServiceTypeName | null {
    var categorizedServiceTypes = ServiceTypeInfoUtil.getSortedCategorizedServiceTypes(serviceTypeInfo);
    for (let categorizedServiceType of categorizedServiceTypes) {
      let serviceCategory = categorizedServiceType.serviceCategory;
      for (let _serviceType of categorizedServiceType.serviceTypes) {
        if (_serviceType.serviceTypeId === serviceTypeId && !_serviceType.deleteDate) {
          return {
            serviceTypeName: _serviceType.name,
            serviceCategoryName: serviceCategory.name,
          };

        }
      }
    }
    return null;
  }

  static getSortedCategorizedServiceTypes(serviceTypeInfo:IServiceTypeInfo):ICategorizedServiceType[] {
    var list:ICategorizedServiceType[] = [];
    var serviceCategories = ServiceTypeInfoUtil.getSortedCategories(serviceTypeInfo);
    var serviceTypeMap = ServiceTypeInfoUtil.getServiceTypeMap(serviceTypeInfo);

    for (var serviceCategory of serviceCategories) {
      var serviceTypes = ServiceTypeInfoUtil._getRawSortedServiceTypes(
        serviceCategory.serviceCategoryId,
        serviceTypeInfo.serviceCategoryToServiceTypes[serviceCategory.serviceCategoryId] ?? [],
        serviceTypeMap,
      );

      list.push(
        {
          serviceCategory: serviceCategory,
          serviceTypes: serviceTypes,
        },
      );
    }
    return list;
  }

  static getServiceTypeMap(serviceTypeInfo:IServiceTypeInfo):Map<String, IServiceType> {
    var map = new Map<String, IServiceType>();
    for (var serviceType of serviceTypeInfo.serviceTypes) {
      if (!serviceType.deleteDate) {
        map[serviceType.serviceTypeId] = serviceType;
      }
    }
    return map;
  }

  static getSortedCategories(serviceTypeInfo:IServiceTypeInfo):IServiceCategory[] {
    var list = serviceTypeInfo.serviceCategories;
    list.sort((a, b) => nullableIntSort(a.sortOrder, b.sortOrder));
    return list;
  }

  static _getRawSortedServiceTypes(
    categoryId:string,
    serviceTypeMetadata:IServiceTypeInfoMetadatum[],
    serviceTypeMap: Map<String, IServiceType>,
  ):IServiceType[] {

    if (serviceTypeMetadata.length == 0) {
      return [];
    }
    serviceTypeMetadata.sort((a, b) => nullableIntSort(a.sortOrder, b.sortOrder));
    return serviceTypeMetadata
      .map((e) => serviceTypeMap[e.serviceTypeId]);
  }

  static createHierarchicalServiceTypes(serviceTypeInfo: IServiceTypeInfo): IServiceTypeTree[] {
    let serviceTypeTrees:IServiceTypeTree[] = [];
    const serviceCategoryMap = {};
    const serviceTypeMap:Record<string, IServiceType> = {};
    const serviceTypes = serviceTypeInfo.serviceTypes;
    const serviceCategories = serviceTypeInfo.serviceCategories;
    const serviceCategoryToServiceTypes = serviceTypeInfo.serviceCategoryToServiceTypes;

    serviceCategories.forEach((serviceCategory) => {
      serviceCategoryMap[serviceCategory.serviceCategoryId] = serviceCategory;
    });

    serviceTypes.forEach((serviceType) => {
      serviceTypeMap[serviceType.serviceTypeId] = serviceType;
    });

    for ( const key in serviceCategoryToServiceTypes) {
      if (!serviceCategoryToServiceTypes.hasOwnProperty(key)) {
        continue;
      }
      let _serviceCategory = serviceCategoryMap[key];
      let _serviceTypes:IServiceType[] = [];
      let _sortOrder = _serviceCategory.sortOrder;
      let list = serviceCategoryToServiceTypes[key];

      list.map((val) => {
        let serviceTypeId = val.serviceTypeId;
        let _serviceType = serviceTypeMap[serviceTypeId];
        if (_serviceType && _serviceType.enabled) {
          _serviceTypes.push(_serviceType);
        } else {
          console.log('serviceType not found or not enabled.');
        }
      });

      serviceTypeTrees.push({
        sortOrder: _sortOrder,
        serviceCategory: _serviceCategory,
        serviceTypes: _serviceTypes,

      });
    }
    return serviceTypeTrees;
  }

}