import { MenuItem, Select } from '@mui/material';
import get from 'lodash/get';
import { Control, Controller, RegisterOptions } from 'react-hook-form';

interface IMenuItemKeys {
  key: string;
  value: string;
  displayValue:string;
}

interface IHookSelectProps {
  control:Control<any>;
  rules?: any;
  id:string;
  name:string;
  menuItems:any[];
  menuItemKeys?:IMenuItemKeys | null;
  postOnChange?:Function;
  onClick?:Function;
  disabled?:boolean;
}

export default function RHFSelect({ control, id, name, menuItems, menuItemKeys, rules, postOnChange, disabled }:IHookSelectProps) {

  const menuItemKey = menuItemKeys?.key || '';
  const menuItemValue = menuItemKeys?.value || '';
  const menuItemDisplayValue = menuItemKeys?.displayValue || '';
  return (
    <Controller
      control={control}
      name={name}
      defaultValue=''
      rules={rules}
      render={({ field: { onBlur, onChange, ref, value } })=>(
        <Select
          disabled={disabled}
          inputProps={{
            inputRef: ref,
            onBlur,
          }}
          onChange={(e) => {
            onChange(e);
            if (postOnChange) {
              postOnChange(e);
            }
          }}
          value={value ?? ''} // prevent console warning if value is null
          id={id}
          fullWidth>
          {
            menuItems && menuItems.map((x: any, i:number) => {
              return (
                <MenuItem
                  key={get(x, menuItemKey, i)}
                  value={get(x, menuItemValue, x.id)}>
                  <span>{get(x, menuItemDisplayValue, x.name)}</span>
                </MenuItem>
              );
            })
          }
        </Select>
      )}/>
  );
}