import { Button, MenuItem } from '@mui/material';
import { Link } from 'react-router-dom';

export default function NavigationDropdownMenuItem({ buttonText, route, onClick }) {
  return (
    <MenuItem onClick={onClick} sx={{ padding: '0' }}>
      <Link to={route} rel="noopener noreferrer" style={{
        textDecoration: 'none',
        color: 'black',
        width: '100%',
        height: '100%',
        padding: '6px 16px',
      }}>
        {buttonText}
      </Link>
    </MenuItem>
  );
}