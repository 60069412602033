import { syncStreetName, uploadPropStreamFile } from 'api/launchToolingApi';
import { uploadHomeSales, uploadNeighborhoodWKT } from 'api/neighborhoodApi';
import { useLoading } from 'components/Layout/Loading';
import useToast from 'components/toast/useToast';
import createStore from 'hooks/hookStore';
import { useEffect, useState } from 'react';

type HomeUpload = {
  onDropFile:Function;
  acceptedFiles:any;
  selectedNeighborhoodId:string | null;
}
const loadingKey = 'HomeUpload';

const { get, update, registerListener, unregisterListener } = createStore<HomeUpload>('HomeUpload', {
  onDropFile: () => {},
  acceptedFiles: [],
  selectedNeighborhoodId: null,
});

export default function useHomeUpload() {
  const setState = useState(get())[1];
  const { onLoading, doneLoading } = useLoading(loadingKey);
  const { createInfoToast, createSuccessToast, createErrorToast } = useToast();

  useEffect(() => {
    registerListener(setState);
    return () => {
      update({
        ...get(),
        acceptedFiles: [],
        selectedNeighborhoodId: null,
      });
      unregisterListener(setState);
    };
  }, []);

  async function init() {
    onLoading();
    update({
      ...get(),
    });
    doneLoading(300);
  }

  async function onUpload(onConfirmArgs) {
    await uploadPropStreamFile(onConfirmArgs.data);
    createSuccessToast('Request received.');
  }

  async function onDropFile(acceptedFiles, ctx) {
    try {
      ctx.setValue('propStreamTemplate', acceptedFiles[0]);
      ctx.setValue('fileName', acceptedFiles[0].name);
      update({
        ...get(),
        acceptedFiles,
      });
    } catch (e:any) {

      // setErrorMessage(e.response.data);
    }

  }

  function onChangeNeighborhood(selectedNeighborhoodId) {
    update({
      ...get(),
      selectedNeighborhoodId,
    });
  }

  async function onSyncStreetNames() {
    const { selectedNeighborhoodId } = get();
    if (selectedNeighborhoodId) {
      await syncStreetName(selectedNeighborhoodId);
    }
  }


  return {
    loadingKey,
    ...get(),
    init,
    onDropFile,
    onUpload,
    onChangeNeighborhood,
    onSyncStreetNames,
  };
}