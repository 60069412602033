import { Grid } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import AddSeed from './AddSeed';
import SubtractSeed from './SubtractSeed';

export const columns: GridColDef[] = [
  {
    field: 'serviceType',
    headerName: 'Service Type',
    width: 250,
    renderCell: (params: any) => {
      return <div>{params.value}</div>;
    },
  },
  {
    field: 'providerName',
    headerName: 'Provider Name',
    width: 250,
    renderCell: (params: any) => {
      return <div>{params.value}</div>;
    },
  },
  {
    field: 'totalCustomers',
    headerName: 'Total Customers',
    width: 150,
    renderCell: (params: any) => {
      return <div>{params.value}</div>;
    },
  },
  {
    field: 'seededCustomers',
    headerName: 'Seeded Customers',
    width: 150,
    renderCell: (params: any) => {
      return <div>{params.value}</div>;
    },
  },
  {
    field: 'actions',
    headerName: 'Actions',
    width: 150,
    renderCell: (params:any) => {
      return (
        <Grid container justifyContent='center' alignItems='center'>
          <Grid>
            <AddSeed params={params.row} />
          </Grid>
          <Grid>
            <SubtractSeed params={params.row} />
          </Grid>
        </Grid>
      );
    },
  },
];