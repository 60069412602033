import { Button, Grid, Typography } from '@mui/material';
import { IServiceOfferingImageRef } from 'model/serviceOffering';
import { Fragment } from 'react';
import { useFormContext } from 'react-hook-form';
import useServiceOfferingDetails from '../hookStore/useServiceOfferingDetails';
import ExistingImage from './ExistingImage';

export default function ExistingImages() {
  const formCtx = useFormContext();
  const { markForDelete, toggleForHardDelete, unmarkForDelete, pendingDeleteImages } = useServiceOfferingDetails();
  const imageRefs:IServiceOfferingImageRef[] = formCtx.getValues('imageRefs') ?? [];
  const allImages = [...imageRefs, ...pendingDeleteImages];
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant='h6'>Existing images</Typography>
      </Grid>
      {allImages.map((x, i) => {
        return (
          <ExistingImage
            key={x.imageFileName}
            imageWrapper={x}
            markForDelete={markForDelete}
            toggleForHardDelete={toggleForHardDelete}
            unmarkForDelete={unmarkForDelete}
          />
        );
      })}
    </Grid>
  );
}