import { Grid } from '@mui/material';
import { useEffect } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import styles from './formComponents.module.css';
import ServiceProviderLaunchCard from './ServiceProviderLaunchCard';

export interface IServiceProviderLaunchCardsProps {
  fields:any[];
  onReplace:(data:any) => void;
  disableServiceOfferingsWithoutProviderCoverageArea?:boolean;
  variant: 'neighborhoodLaunchTooling' | 'providerCoverageAreaLaunchTooling';
}

export default function ServiceProviderLaunchCards({ fields, onReplace, disableServiceOfferingsWithoutProviderCoverageArea, variant }:IServiceProviderLaunchCardsProps) {
  const formContext = useFormContext();
  const { control } = formContext;

  const watchFields = useWatch({ control, name: 'serviceProviderLaunchDtos' });
  useEffect(() => {
    if (fields.length !== watchFields.length) {
      onReplace(watchFields);
    }
  }, [watchFields]);
  return (
    <Grid container spacing={1} className={styles.serviceProviderLaunchCards}>
      {fields.map((x, i) => (
        <Grid item xs={3} lg={2} key={x.id}>
          <ServiceProviderLaunchCard
            disableServiceOfferingsWithoutProviderCoverageArea={disableServiceOfferingsWithoutProviderCoverageArea}
            serviceProviderId={x.serviceProviderId}
            index={i}
            variant={variant}
          />
        </Grid>
      ))}
    </Grid>
  );
}