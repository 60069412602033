import { findTerritoriesByMarket } from 'api/territoriesApi';
import { createModuleStore } from 'hooks/hookStore';
import { IDropdownOption } from 'model/dropdown';
import { ITerritory } from 'model/territories';
import { useEffect, useState } from 'react';

type TerritoryStore = {
  territory: ITerritory | null;
}

type ModuleSelf = {
  loaded:boolean;
  currentMarketId:string | null;
  territories: ITerritory[];
  selectedTerritory: ITerritory | null;
  territoryDropdownOptions: IDropdownOption[];
}

const { get, getModuleScoped, update, registerListener, unregisterListener } = createModuleStore<TerritoryStore, ModuleSelf>('TerritoryStore', {
  territory: null,
},
{
  loaded: false,
  currentMarketId: null,
  territories: [],
  selectedTerritory: null,
  territoryDropdownOptions: [],
});

const loadingKey = 'TerritoryStore';

export default function useTerritories(marketId?:string) {
  const setState = useState(get())[1];

  useEffect(() => {
    registerListener(setState);
    return () => {
      unregisterListener(setState);
      const moduleScope = getModuleScoped();
      moduleScope.loaded = false;
      moduleScope.currentMarketId = null;
      moduleScope.territories = [];
      moduleScope.selectedTerritory = null;
      moduleScope.territoryDropdownOptions = [];
    };
  }, []);

  useEffect(() => {
    if (marketId) {
      void onInit(marketId);
    }

  }, [marketId]);

  async function onInit(nextMarketId?:string) {
    try {
      const moduleScope = getModuleScoped();

      if (nextMarketId && (!moduleScope.loaded || nextMarketId !== moduleScope.currentMarketId)) {
        moduleScope.loaded = true;
        moduleScope.currentMarketId = nextMarketId;
        const res = await findTerritoriesByMarket(nextMarketId);
        if (res.data) {
          moduleScope.territories = res.data;
          moduleScope.territoryDropdownOptions = res.data.map(m => {
            return {
              key: m.id,
              optionValue: m.id,
              optionText: m.name ?? m.zipCodeTerritory,
              ancillary: m,
            };
          });
          update({
            ...get(),
          });
        }
      }
    } catch (err:any) {
      console.log(err);
    }
  }

  function onChange(territoryOption:IDropdownOption | null) {
    const moduleScope = getModuleScoped();
    if (!territoryOption) {
      moduleScope.selectedTerritory = null;
      return;
    }

    update({
      ...get(),
      territory: territoryOption.ancillary,
    });

  }

  function preOnChangeOverride(territoryOption: IDropdownOption | null) {
    if (territoryOption === null) {
      update({
        ...get(),
        territory: null,
      });
      return;
    }
    update({
      ...get(),
      territory: territoryOption?.ancillary as ITerritory,
    });
  }

  return {
    loadingKey: loadingKey,
    ...get(),
    ...getModuleScoped(),
    onChange,
    preOnChangeOverride,
  };
}