import { Grid, Alert } from '@mui/material';
import { saveServiceCategoryTypeImage } from 'api/serviceCategoryApi';
import { useLoading } from 'components/Layout/Loading';
import { IServiceCategoryForm, ManageServiceTypeMode } from 'model/serviceCategory';
import { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useFormContext } from 'react-hook-form';
import useServiceCategoryDetail from '../hookStore/useServiceCategoryDetail';
import styles from './serviceCategoryTypeDetails.module.css';
import { useParams } from 'react-router-dom';

export function ServiceCategoryImageDropzone() {
  const [errorMessage, setErrorMessage] = useState('');
  const ctx = useFormContext<IServiceCategoryForm>();
  const { loadingKey } = useServiceCategoryDetail();
  const { onLoading, doneLoading } = useLoading(loadingKey);
  const { mode } = useParams();
  if (mode === ManageServiceTypeMode.EDIT_MARKET || mode === ManageServiceTypeMode.EDIT_TERRITORY) {
    return null;
  }
  const onDrop = useCallback(async acceptedFiles => {
    var formData = new FormData();
    if (acceptedFiles.filter(x => x.type === 'image/webp' || x.type.indexOf('svg') > -1).length > 0) {
      setErrorMessage('WebP and SVG format are not supported. Please upload a PNG or JPEG file.');
      return;
    } else {
      setErrorMessage('');
    }
    formData.append('image', acceptedFiles[0]);
    let existingImageUrlGuid = '';
    try {
      existingImageUrlGuid = ctx.getValues('imageGuid');
    } catch (e:any) {
      console.error(e);
    }

    try {
      try {
        onLoading();
        const response = await saveServiceCategoryTypeImage(formData);
        const imageUrlGuid = response.data;
        ctx.setValue('imageGuid', imageUrlGuid);
      } catch (e:any) {
        console.error(e);
      } finally {
        doneLoading();
      }
    } catch (e:any) {
      setErrorMessage(e.response.data);
    }

  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <Grid container justifyContent='center'>
      <Grid item xs>
        <div {...getRootProps()} className={styles.dropzoneWrapper}>
          <input {...getInputProps()} accept='.png,.jpeg'/>
          {
            isDragActive ?
              <p>Drop the files here ...</p> :
              <p>Drag and drop a file or click here to select a file</p>
          }

        </div>
      </Grid>
      <Grid item xs={12}>
        {errorMessage && (
          <Alert
            severity='error'
            className={styles.uploadErrorMessage}
            variant='outlined'
          >
            {errorMessage}
          </Alert>
        )}
      </Grid>
    </Grid>
  );
}