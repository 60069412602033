import ServiceProviderAutocomplete from 'components/serviceProviderAutocomplete/ServiceProviderAutocomplete';
import useRemoveProviderFromTemplates from '../hookStore/useRemoveProviderFromTemplates';


export default function ServiceProviderSelect() {

  const { onProviderSelect } = useRemoveProviderFromTemplates();

  return (
    <ServiceProviderAutocomplete
      fieldName='serviceProvider'
      label={'Service Providers'}
      formless={false}
      placeholder={'Search and check service providers'}
      required={true}
      postOnChange={async (_formCtx, values, reason, details) => {
        if (reason === 'selectOption') {
          await onProviderSelect(details.option);
        }
      }}
    />
  );
}