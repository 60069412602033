import { Button, Grid, Tab, Tabs, Typography } from '@mui/material';
import { PrimaryButton, SecondaryButton } from 'components/buttons';
import Loading from 'components/Layout/Loading';
import { ManageServiceTypeMode } from 'model/serviceCategory';
import { useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import styles from './components/serviceCategoryTypeDetails.module.css';
import ServiceTypeFields from './components/ServiceTypeFields';
import useServiceTypeDetail from './hookStore/useServiceTypeDetail';
import SelectMarketModal from './components/pageConfigModal/SelectMarketModal';
import SelectTerritoryModal from './components/pageConfigModal/SelectTerritoryModal';
import { IServiceTypeDetailForm } from 'model/serviceType';
import BackToTableButton from '../common/BackToTableButton';

function a11yProps(index: number) {
  return {
    'id': `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export interface IServiceTypeDetailsInternalProps {
  initialFormData: IServiceTypeDetailForm;
}

function ServiceTypeDetailsInternal({ initialFormData }:IServiceTypeDetailsInternalProps) {
  const formContext = useForm<IServiceTypeDetailForm>({ mode: 'onTouched', defaultValues: initialFormData });
  const navigate = useNavigate();
  const { serviceTypeId, marketId, territoryId, mode } = useParams();

  const {
    loadingKey,
    selectedTab,
    selectedMarket,
    selectedTerritory,
    save,
    cancel,
    onSelectTab,
  } = useServiceTypeDetail();

  useEffect(() => {
    formContext.reset(initialFormData);
  }, [initialFormData]);

  const onSubmit : SubmitHandler<IServiceTypeDetailForm> = data => {
    void save(data);
  };
  return (
    <Loading loadingKey={loadingKey}>
      <FormProvider {...formContext}>
        <BackToTableButton/>
        <form onSubmit={formContext.handleSubmit(onSubmit)}>
          <Grid
            container
            className={styles.formGridContainer}
            sx={{
              '&&&': {
                paddingTop: '10px',
              },
            }}
          >
            <Grid container item xs={12}>
              {(ManageServiceTypeMode.CREATE === mode) && <Typography variant='h6'>Create Service Type</Typography>}
              {(ManageServiceTypeMode.EDIT_DEFAULT === mode) && <Typography variant='h6'>Edit Default Service Type</Typography>}
              {(ManageServiceTypeMode.EDIT_MARKET === mode) && <Typography variant='h6'>Edit Market Service Type</Typography>}
              {(ManageServiceTypeMode.EDIT_TERRITORY === mode) && <Typography variant='h6'>Edit Territory Service Type</Typography>}
            </Grid>
            <Tabs value={selectedTab} onChange={onSelectTab} aria-label='tabs'>
              <Tab
                label="Default"
                value="1"
                onClick={(e) => {
                  if (selectedTab === '1') {
                    onSelectTab(e, selectedTab);
                  }
                }}
                {...a11yProps(0)}
              />

              {ManageServiceTypeMode.CREATE !== mode ? (
                <Tab
                  label="Market"
                  value="2"
                  onClick={(e) => {
                    if (selectedTab === '2') {
                      onSelectTab(e, selectedTab);
                    }
                  }}
                  {...a11yProps(1)}
                />
              ): null}
              {ManageServiceTypeMode.CREATE !== mode ? (
                <Tab
                  label="Territory"
                  value="3"
                  onClick={(e) => {
                    if (selectedTab === '3') {
                      onSelectTab(e, selectedTab);
                    }
                  }}
                  {...a11yProps(2)}
                />
              ) : null}
            </Tabs>
            <Grid item xs={12}></Grid>
            <Grid container item xs={8} justifyContent={'space-between'} sx={{ marginBottom: '10px' }}>
              <Grid container item xs='auto' direction={'column'}>
                {(selectedMarket !== null) && <Typography variant='body2'>Editing Market: {selectedMarket?.name}</Typography>}
                {(selectedTerritory !== null) && <Typography variant='body2'>Editing Territory: {selectedTerritory?.name}</Typography>}
              </Grid>
              <Grid container item xs='auto'>
                <PrimaryButton type='submit' >Save</PrimaryButton>
                <SecondaryButton onClick={() => cancel()} sx={{ marginLeft: '20px' }}>Cancel</SecondaryButton>
              </Grid>
            </Grid>
            <Grid item xs={12}></Grid>
            <Grid container item xs={8}>
              <ServiceTypeFields/>
            </Grid>
          </Grid>
        </form>

      </FormProvider>
      <SelectMarketModal postOnConfirm={function (selectedMarketId: string): void {
        if (serviceTypeId && serviceTypeId !== 'unknown' && selectedMarketId) {
          navigate(`/serviceTypes/${serviceTypeId}/service-type-details/${ManageServiceTypeMode.EDIT_MARKET}/markets/${selectedMarketId}`);
          window.location.reload();
        }
      }}/>
      <SelectTerritoryModal postOnConfirm={function (selectedMarketId: string, selectedTerritoryId:string): void {
        if (serviceTypeId && serviceTypeId !== 'unknown' && selectedMarketId) {
          navigate(`/serviceTypes/${serviceTypeId}/service-type-details/${ManageServiceTypeMode.EDIT_TERRITORY}/markets/${selectedMarketId}/territories/${selectedTerritoryId}`);
          window.location.reload();
        }
      }}/>
    </Loading>
  );
}

export default function ServiceTypeDetails() {
  const { init, formData } = useServiceTypeDetail();
  useEffect(() => {
    void init();
  }, []);
  return (
    <ServiceTypeDetailsInternal initialFormData={formData}/>
  );
}