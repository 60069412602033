
import { ToAddressOption } from 'model/letter_batch/ancillary';
import EnumSelect from './EnumSelect';
import { Grid } from '@mui/material';

export default function ToAddressSelect() {

  return (
    <Grid item xs={12} >
      <EnumSelect
        label='To Address'
        fieldName='toAddressOption'
        list={ToAddressOption.list}
      />
    </Grid>
  );
}