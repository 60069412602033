import { Grid } from '@mui/material';
import { HookTextField } from 'components/reactHookForm';

export default function ProviderIds() {
  return (
    <Grid container item spacing={1} xs={12}>
      <Grid item xs={3}>
        <HookTextField
          label='Google Place Id'
          name='googlePlaceId'
        />
      </Grid>
      <Grid item xs={3}>
        <HookTextField
          label='Facebook Id'
          name='facebookPlaceId'
        />
      </Grid>
      <Grid item xs={3}>
        <HookTextField
          label='Stripe Customer Id'
          name='stripeCustomerId'
        />
      </Grid>
    </Grid>
  );
}