import { Grid, Typography } from '@mui/material';
import { markNeighborConnestionsAsFollowing } from 'api/neighborConnectionsApi';
import Loading, { useLoading } from 'components/Layout/Loading';
import GenericModal from 'components/modal/GenericModal';
import useModal, { IModal } from 'components/modal/useModal';
import useToast from 'components/toast/useToast';
import { INeighborConnectionBatchJobRequest } from 'model/neighborConnections';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

export const modalStoreKey = 'MarkAsFollowingModal';
const loadingKey = 'MarkAsFollowingModalLoader';

export interface IMarkAsFollowingModal extends IModal {
  connectionIds: string[];
}

export function createInitialMarkAsFollowingModal(connectionIds) :IMarkAsFollowingModal {
  return {
    open: false,
    connectionIds: connectionIds,
  };
}

export function createEmptyMarkAsFollowingModal() :IMarkAsFollowingModal {
  return {
    open: false,
    connectionIds: [],
  };
}

export default function MarkAsFollowingModal() {
  const { closeModal, getModalState } = useModal(modalStoreKey);
  const state = getModalState() as IMarkAsFollowingModal;
  const { connectionIds = [] } = state;
  const { createSuccessToast, createErrorToast } = useToast();
  const { onLoading, doneLoading } = useLoading(loadingKey);
  const [requesting, setRequesting] = useState(false);

  const formContext = useForm({
    mode: 'onTouched',
    defaultValues: {
      dryRun: false,
    },
  });

  const onSubmit = async (data:any) => {
    setRequesting(true);
    onLoading();
    try {
      const formData = formContext.getValues();
      const request = {
        connectionIds: connectionIds,
        dryRun: formData.dryRun,
      } as INeighborConnectionBatchJobRequest;

      var resp = await markNeighborConnestionsAsFollowing(request);
      var count = resp.data.processedCount;

      createSuccessToast('Successfully marked ' + count + ' connections as FOLLOWING');
    } catch (error:any) {
      console.error(error);
      createErrorToast('Error updating connections: ' + error.response?.data?.message);
    }

    doneLoading();
    setRequesting(false);
    closeModal(createEmptyMarkAsFollowingModal());
  };

  return (
    <GenericModal
      modalStoreKey={modalStoreKey}
      title={"Set Connections' status to 'FOLLOWING'"}
      modalContent={
        (
          <>
            <Loading loadingKey={loadingKey}>
              <Grid container justifyContent={'flex-start'}>
                <FormProvider {...formContext}>
                  <form onSubmit={formContext.handleSubmit(onSubmit)}>
                    <Grid container item justifyContent={'flex-start'} flexDirection={'column'}>
                      <Grid item xs={12}>
                        <Typography variant='body1'>{`Would you like to change ${connectionIds.length} connections' status to 'FOLLOWING'?`}</Typography>
                      </Grid>
                    </Grid>
                  </form>
                </FormProvider>
              </Grid>
            </Loading>
          </>
        )
      }
      onConfirm={onSubmit}
      onSecondaryAction={() => closeModal(createEmptyMarkAsFollowingModal())}
      primaryButtonText='Update'
      primaryDisabled={requesting}
      secondaryButtonText='Cancel'
    />
  );
}