import PageHeader from 'components/SectionHeaders/PageHeader';
// import SeedFromTemplateTabs from './_components/SeedFromTemplateTabs';
import Loading from 'components/Layout/Loading';
import useConfirmationModal from 'components/modals/useConfirmationModal';
import TerritoryPrepConfirmationModal, { TerritoryPrepConfirmationModalKey } from './_components/TerritoryPrepConfirmationModal';
import TerritoryAutocomplete from 'components/autocomplete/TerritoryAutocomplete';
import useMarkets from 'hooks/useMarkets';
import useTerritoryPrep from './_hookStore/useTerritoryPrep';
import { Alert, Grid } from '@mui/material';
import { PrimaryButton } from 'components/buttons';

export default function TerritoryPrep() {
  const { openModal } = useConfirmationModal(TerritoryPrepConfirmationModalKey);
  const { market } = useMarkets();
  const { selectedTerritory, onTerritorySelect, loadingKey } = useTerritoryPrep();

  return (
    <Loading loadingKey={loadingKey}>
      <Grid padding={'25px'}>
        <PageHeader headerText={'Territory Prep'} />
        <Alert severity='info'>
          Prep territory to reconcile NSOs and process staged customers for all neighborhoods in the territory.
        </Alert>
        <Grid container flexDirection={'row'} alignItems={'center'} columnSpacing={3} paddingTop={'25px'}>
          <Grid item xs={3}>
            <TerritoryAutocomplete
              marketId={market?.id || ''}
              onChangeOverride={(option:any) => {
                onTerritorySelect(option);
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <PrimaryButton
              sx={{ height: '55px' }}
              disabled={selectedTerritory == null}
              onClick={() => {
                openModal({});
              }}
            >
            Prep
            </PrimaryButton>
          </Grid>
        </Grid>
        <TerritoryPrepConfirmationModal />
      </Grid>
    </Loading>
  );
}