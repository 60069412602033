import { getServiceTypesForDropdown } from 'util/serviceTypeHelper';
import { Grid, FormControl, InputLabel, FormHelperText } from '@mui/material';

import { HookSelect } from 'components/reactHookForm';
import useDiscountScheduleErrorState from 'hooks/reactHookForm/useErrorState';
import { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import CustomCancelIcon from './CustomCancelIcon';
import StepHeader from './StepHeader';

export default function StepFive() {
  const formCtx = useFormContext();

  const { isError, getErrorMessage } = useDiscountScheduleErrorState(formCtx);
  const [serviceTypes, setServiceTypes] = useState<any[]>([]);
  useEffect(() => {
    void getServiceTypesForDropdown(setServiceTypes);
  }, []);
  return (
    <Grid container item xs={12} spacing={1}>
      <Grid item xs={12}>
        <StepHeader>Step 5: Select a Service Type (Optional)</StepHeader>
        <p>Only select a service type if you'd like to make this the default discount schedule for this service type.</p>
        <p>Note:  If you do select one, it will become the default and override an previously marked discount schedules.</p>
      </Grid>
      <Grid item xs={4}>
        <FormControl variant='standard' fullWidth error={isError('type')}>

          <Grid container alignItems='center'>
            <Grid item xs={11}>
              <InputLabel htmlFor="discount-schedule-type">Service Type</InputLabel>
              <HookSelect
                control={formCtx.control}
                rules={{
                  required: { value: false, message: 'Required' },
                }}
                name='serviceTypeId'
                id='service-type'
                menuItems={serviceTypes}
              />
            </Grid>
            <Grid item xs={1}>
              <CustomCancelIcon
                onClick={() => {
                  formCtx.setValue('serviceTypeId', '');
                }}/>
            </Grid>
          </Grid>
          <FormHelperText>{getErrorMessage('serviceTypeId')}</FormHelperText>
        </FormControl>
      </Grid>
    </Grid>
  );
}