import { FormControl, InputLabel, FormHelperText } from '@mui/material';
import { HookSelect } from 'components/reactHookForm';
import useErrorState from 'hooks/reactHookForm/useErrorState';
import { NeighborhoodStatusType } from 'model/neighborhood';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';


export default function NeighborhoodStatusSelect() {
  const formCtx = useFormContext();
  const [statuses] = useState<any[]>(NeighborhoodStatusType.list);
  const { isError, getErrorMessage } = useErrorState(formCtx);
  return (
    <FormControl variant='standard' fullWidth error={isError('status')}>
      <InputLabel htmlFor="status-type">Status</InputLabel>
      <HookSelect
        control={formCtx.control}
        rules={{
          required: { value: true, message: 'Required' },
        }}
        name='status'
        id='status-type'
        menuItems={statuses}
      />
      <FormHelperText>{getErrorMessage('status')}</FormHelperText>
    </FormControl>
  );
}