import { FormControl, InputLabel, FormHelperText } from '@mui/material';
import { HookSelect } from 'components/reactHookForm';
import useErrorState from 'hooks/reactHookForm/useErrorState';
import { useFormContext, useWatch } from 'react-hook-form';import useProviderRecommendations from '../hookStore/useProviderRecommendations';
;


export default function TerritorySelect() {
  const { territoryDropdownOptions } = useProviderRecommendations();
  const formCtx = useFormContext();
  const territoryId = useWatch({ control: formCtx.control, name: 'territoryId' });
  const { isError, getErrorMessage } = useErrorState(formCtx);
  return (
    <FormControl
      variant='standard'
      fullWidth
      error={isError('territoryId')}
      sx={{ background: territoryId === 'UNASSIGNED' ? '#fbfbac' : '' }}
    >
      <InputLabel htmlFor="territory-select">Territory</InputLabel>
      <HookSelect
        control={formCtx.control}
        name='territoryId'
        id='territory-select'
        menuItems={territoryDropdownOptions}
        menuItemKeys={{
          key: 'id',
          value: 'optionValue',
          displayValue: 'optionText',
        }}
      />
      <FormHelperText>{getErrorMessage('territoryId')}</FormHelperText>
    </FormControl>
  );
}