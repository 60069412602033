import { Grid, FormControl, InputLabel, FormHelperText } from '@mui/material';
import { HookSelect } from 'components/reactHookForm';
import useDiscountScheduleErrorState from 'hooks/reactHookForm/useErrorState';
import { useFormContext } from 'react-hook-form';
import StepHeader from './StepHeader';


export default function StepThree({ discountScheduleTypes }:any) {
  const formCtx = useFormContext();
  const { isError, getErrorMessage } = useDiscountScheduleErrorState(formCtx);
  return (
    <Grid container item xs={12} spacing={1}>
      <Grid item xs={12}>
        <StepHeader>Step 3: Select a Discount Schedule Type</StepHeader>
      </Grid>
      <Grid item xs={4}>
        <FormControl variant='standard' fullWidth error={isError('type')}>
          <InputLabel htmlFor="discount-schedule-type">Discount Schedule Type</InputLabel>
          <HookSelect
            control={formCtx.control}
            rules={{
              required: { value: true, message: 'Required' },
            }}
            name='type'
            id='discount-schedule-type'
            menuItems={discountScheduleTypes}
          />
          <FormHelperText>{getErrorMessage('type')}</FormHelperText>
        </FormControl>
      </Grid>
    </Grid>
  );
}

