import { Grid } from '@mui/material';
import PageHeader from 'components/SectionHeaders/PageHeader';
import TerritoryAutocomplete from 'components/autocomplete/TerritoryAutocomplete';
import useMarkets from 'hooks/useMarkets';
import ProviderRecommendationsForm from './ProviderRecommendationsForm';
import Loading from 'components/Layout/Loading';
import useProviderRecommendations from './hookStore/useProviderRecommendations';
import ProviderRecommendationsTable from './ProviderRecommendationsTable';

export default function ProviderRecommendations() {
  const { market } = useMarkets();
  const { loadingKey } = useProviderRecommendations();
  return (
    <Loading loadingKey={loadingKey} size={100}>
      <Grid container spacing={2} className='pageGridContainer'>
        <Grid item xs={12}>
          <PageHeader headerText={'Provider Recommendations'} />
        </Grid>
        <Grid item xs={12}>
          <ProviderRecommendationsForm />
        </Grid>
        <Grid item xs={12}>
          <ProviderRecommendationsTable/>
        </Grid>
      </Grid>
    </Loading>

  );
}