import { useLoading } from 'components/Layout/Loading';
import createStore from 'hooks/hookStore';
import { useEffect, useState } from 'react';
import { findMarkets } from 'api/marketsApi';
import { IMarket, MarketUtils } from 'model/markets';
import { IDropdownOption } from 'model/dropdown';
import { findTerritoriesByMarket, findTerritoryById, findTerritoryByLegacyId } from 'api/territoriesApi';
import { useNavigate, useParams } from 'react-router-dom';
import { getNeighborhoodById, saveNeighborhood, sendNeighborhoodCommunication } from 'api/neighborhoodApi';
import { IInternalNeighborhood, IInternalNeighborhoodForm, INeighborhood, INeighborhoodCommunicationForm, LaunchLeaderType, NeighborhoodStatusType, NeighborhoodUtils } from 'model/neighborhood';
import { createDateIgnoreTimezone, formatLocalServiceDate } from 'util/dateUtil';
import { ITerritory, createUnassignedTerritoryOption } from 'model/territories';
import { convert } from 'model/rebookServices';
import useToast from 'components/toast/useToast';
import { reconcileNSOsByNeighborhood } from 'api/neighborhoodLaunchApi';

const loadingKey = 'NeighborhoodDetailStore';
type NeighborhoodDetailStore = {
  formData: IInternalNeighborhoodForm;
  neighborhood: INeighborhood | null;
  markets: IDropdownOption[];
  territories: IDropdownOption[];
}

const { get, update, registerListener, unregisterListener } = createStore<NeighborhoodDetailStore>('NeighborhoodDetailStore', {
  neighborhood: null,
  markets: [],
  territories: [],
  formData: NeighborhoodUtils.createEmptyInternalNeighborhoodForm(),
});


export default function useNeighborhoodDetail() {
  const setState = useState(get())[1];
  const { onLoading, doneLoading } = useLoading(loadingKey);
  const { neighborhoodId } = useParams();
  const navigate = useNavigate();
  const { createErrorToast, createSuccessToast, createInfoToast } = useToast();

  useEffect(() => {
    registerListener(setState);
    return () => {
      unregisterListener(setState);
    };
  }, []);


  async function init(market:IMarket | null) {

    if (market == null) {
      console.log('waiting to load market');
      return;
    }
    onLoading();
    try {
      let neighborhood: INeighborhood | null = null;
      let territories: IDropdownOption[] = [];
      let formData: IInternalNeighborhoodForm = NeighborhoodUtils.createEmptyInternalNeighborhoodForm();
      if (neighborhoodId) {
        const response = await getNeighborhoodById(neighborhoodId);
        if (response.data) {
          const internalNeighborhood = response.data;
          internalNeighborhood.zipCodes = internalNeighborhood.zipCodes.map( x=> ({ value: x }));
          if (internalNeighborhood.launchDate) {
            internalNeighborhood.launchDate = createDateIgnoreTimezone(internalNeighborhood.launchDate).toISOString();
          }


          if (internalNeighborhood?.zipCodeTerritory) {
            const territoriesRes = await findTerritoriesByMarket(internalNeighborhood?.marketId);
            territories = convertTerritoriesToDropdownOptions(territoriesRes.data);
          } else {
            const territoryRes = await findTerritoriesByMarket(market.id);
            territories = convertTerritoriesToDropdownOptions(territoryRes.data);
          }
          formData = internalNeighborhood as IInternalNeighborhoodForm;
          if (!response.data.marketId) {
            formData.marketId = market.id;
          }
          if (internalNeighborhood.launchLeaderAcquisitionCampaignStartDate && internalNeighborhood.launchLeaderAcquisitionCampaignEndDate) {
            formData.miningDateRange = [
              createDateIgnoreTimezone(internalNeighborhood.launchLeaderAcquisitionCampaignStartDate),
              createDateIgnoreTimezone(internalNeighborhood.launchLeaderAcquisitionCampaignEndDate),
            ];
          } else {
            formData.miningDateRange = [null, null];
          }
        }
      } else {
        formData.marketId = market.id;
        const territoriesRes = await findTerritoriesByMarket(market.id);
        territories = convertTerritoriesToDropdownOptions(territoriesRes.data);
        formData.zipCodeTerritory = 'UNASSIGNED';
      }
      const res = await findMarkets();
      const markets = MarketUtils.convertToDropdownOptions(res.data);
      update({
        ...get(),
        formData,
        neighborhood,
        markets,
        territories,
      });

    } catch (e:any) {
      console.error(e);
    }
    doneLoading(300);
  }

  async function onSelectMarket(marketId:string) {
    try {
      const res = await findTerritoriesByMarket(marketId);
      const territories = convertTerritoriesToDropdownOptions(res.data);
      if (res.data) {
        update({
          ...get(),
          territories,
        });
      }
    } catch (err:any) {
      console.error(err);
    }
  }

  function convertTerritoriesToDropdownOptions(territories: ITerritory[]): IDropdownOption[] {
    let options = territories.map(m => {
      return {
        key: m.id,
        optionValue: m.zipCodeTerritory,
        optionText: `${m.name} (${m.zipCodeTerritory})`,
        ancillary: m,
      } as IDropdownOption;
    });
    options.unshift(createUnassignedTerritoryOption());
    return options;
  }


  async function onSaveNeighborhood(data:IInternalNeighborhoodForm) {
    onLoading();
    let dataToSave:IInternalNeighborhood;
    if (data.miningDateRange && data.miningDateRange.length === 2 && data.miningDateRange[0] && data.miningDateRange[1]) {
      dataToSave = {
        ...data,
        launchLeaderAcquisitionCampaignStartDate: data.miningDateRange[0]!,
        launchLeaderAcquisitionCampaignEndDate: data.miningDateRange[1]!,
      };
    } else {
      dataToSave = { ...data };
    }

    dataToSave.name = data.name.trim();
    delete dataToSave.legacyId;
    if (data.zipCodes && data.zipCodes.length > 0) {
      dataToSave.zipCodes = (data.zipCodes as any[]).filter(x => x && x !== '').map(x => x.value);
    }
    if (dataToSave.status !== NeighborhoodStatusType.LAUNCHED) {
      delete dataToSave.launchDate;
    } else {
      dataToSave.launchDate = formatLocalServiceDate(dataToSave.launchDate ?? '');
    }

    if (!dataToSave.neighborhoodGeometryWKT) {
      dataToSave.neighborhoodGeometryWKT = null;
    }
    if (!dataToSave.houseHoldCount) {
      dataToSave.houseHoldCount = null;
    }
    if (!dataToSave.launchLeaderType) {
      dataToSave.launchLeaderType = LaunchLeaderType.NOT_SET;
    }
    if (dataToSave.zipCodeTerritory === 'UNASSIGNED') {
      delete dataToSave.zipCodeTerritory;
    }
    if (dataToSave.neighborhoodCommunications) {
      var happeningSoonDayThreshold = dataToSave.neighborhoodCommunications.happeningSoonDayThreshold;
      if (typeof happeningSoonDayThreshold === 'string') {
        if (happeningSoonDayThreshold.trim() === '') {
          delete dataToSave.neighborhoodCommunications.happeningSoonDayThreshold;;
        } else {
          dataToSave.neighborhoodCommunications.happeningSoonDayThreshold = parseInt(happeningSoonDayThreshold);
        }
      }
    }
    for (let key in dataToSave) {
      if (dataToSave[key] === '') {
        delete dataToSave[key];
      }
    }

    try {
      await saveNeighborhood(dataToSave);
      createSuccessToast('Neighborhood saved successfully');
      navigate('/neighborhoods');

    } catch (err) {
      createErrorToast('Failed to save neighborhood');
    } finally {
      doneLoading(100);
    }
  }

  async function onSendNeighborhoodCommunication(data: INeighborhoodCommunicationForm) {
    if (neighborhoodId) {
      await sendNeighborhoodCommunication(neighborhoodId, data.communicationTemplate, data.shouldSendToLoggedInUser, data.overrideEmail, data.neighborhoodServiceOfferingInstanceId);
      navigate('/neighborhoods');
    }
  }

  async function onReconcileNSOsByNeighborhood(args:any) {
    if (neighborhoodId) {
      try {
        var response = await reconcileNSOsByNeighborhood({ neighborhoodId, dryRun: args.dryRun });
        createInfoToast(`${args.dryRun ? 'Dry Run: ' : ''}Reconciling NSOs. You will receive an email with the results.`);
      } catch (e: any) {
        console.error(e);
        createErrorToast(`Request for NSO reconciliation failed with message: ${e.response.data.message}`);
      }
    }
  }

  return {
    ...get(),
    loadingKey,
    init,
    onSelectMarket,
    onSaveNeighborhood,
    onSendNeighborhoodCommunication,
    onReconcileNSOsByNeighborhood,
  };
}