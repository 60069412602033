import Axios, { AxiosResponse } from 'axios';
import { IServiceCategoryAdminDTO, IServiceCategoryAdminRequest, IServiceTypeInfo } from 'model/serviceCategory';

const apiUrl = window.REACT_APP_BASE_API_URI;

export function findServiceCategoryAdminDTO(data: IServiceCategoryAdminRequest):Promise<AxiosResponse<IServiceCategoryAdminDTO>> {
  return Axios.post(`${apiUrl}/serviceCategory/v2/findServiceCategoryDTO`, data);
}

export function saveServiceCategoryAdminDTO(data:IServiceCategoryAdminDTO) {
  return Axios.post(`${apiUrl}/serviceCategory/v2/saveServiceCategoryDTO`, data);
}

export function findServiceTypeInfo():Promise<AxiosResponse<IServiceTypeInfo>> {
  return Axios.get(`${apiUrl}/serviceCategory/v2/findAllIncludeDeleted`);
}

export function saveServiceCategoryTypeImage(data:FormData) {
  return Axios.post(`${apiUrl}/serviceCategory/v2/saveServiceCategoryTypeImage`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}
