import { Button, Card, CardContent, Grid, Modal, TextField } from '@mui/material';
import { SecondaryButton } from 'components/buttons';
import styles from './serviceProviderInvoicingDetails.module.css';
import useInvoicing from './hookStore/useInvoicing';

export default function MarkInvoiceConfirmationModal() {
  const {
    isModalOpen,
    invoiceNumber,
    shouldShowInvoicedCustomers,
    onModalClose,
    onModalSubmit,
    onSetInvoiceNumber,
  } = useInvoicing();
  return (
    <Modal
      open={isModalOpen}
      aria-labelledby="confirm-modal-title"
      aria-describedby="confirm-modal-description"
      className={styles.modalParent}
    >
      <Card>
        <CardContent>
          <div id="confirm-modal-title" className={styles.modalTitle}>
            {`You are about to mark these customers as ${shouldShowInvoicedCustomers ? 'NOT' : ''} INVOICED`}
          </div>
          <div id="confirm-modal-description" className={styles.modalContent}>
            <div>Are you sure you want to update these customers?</div>
          </div>
          <Grid container justifyContent='right' >
            {!shouldShowInvoicedCustomers &&
              <TextField
                fullWidth
                id={'submit-input'}
                variant='standard'
                label={'Invoice Number'}
                value={invoiceNumber}
                onChange={(e) => {
                  onSetInvoiceNumber(e.target.value);
                }}
                sx={{ marginBottom: '20px' }}
              />
            }
          </Grid>
          <Grid container item justifyContent={'end'}>
            <Grid item xs='auto' sx={{ paddingRight: '10px' }}>
              <SecondaryButton type='button' onClick={onModalClose}>Cancel</SecondaryButton>
            </Grid>
            <Grid item xs='auto' sx={{ maxWidth: '50px' }}>
              <Button type='button' variant='contained' onClick={onModalSubmit}>Confirm</Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Modal>
  );
}