import { IServiceTypeInfo } from './serviceCategory';


export class ServiceTypePriority {
  static PRIMARY = 'PRIMARY';
  static SECONDARY = 'SECONDARY';
  static TERTIARY = 'TERTIARY';
  static list = [
    { id: 'PRIMARY', name: 'Primary (Core)' },
    { id: 'SECONDARY', name: 'Secondary' },
    { id: 'TERTIARY', name: 'Tertiary' },
  ];
}

export interface IServiceType {
  pk:string;
  sk:string;
  serviceTypeId:string;
  marketId:string | null;
  name:string;
  displayName:string | null;
  colorScheme:string;
  imageGuid:string;
  searchResultCardStyle:string | null;
  tags:string[];
  serviceTypeCategoryMetadata:IServiceTypeCategoryMetadata[];
  serviceTypePriority:string;
  enabled:boolean;
  zipCodeTerritory:string | null;
  deleteDate: string | null;
  aliases:string[];
}

export interface IServiceTypeCategoryMetadata {
  serviceCategoryId:string;
  sortOrder:number | null;
}

export interface IServiceTypeDetailForm {
  serviceTypeId:string;
  marketId:string | null;
  name:string;
  displayName:string | null;
  colorScheme:string;
  imageGuid:string;
  searchResultCardStyle:string | null;
  tags:ITagValue[];
  serviceTypeCategoryMetadata:IServiceTypeCategoryMetadata[];
  serviceTypePriority:string;
  enabled:boolean;
  zipCodeTerritory:string | null;
  oneTimePercent: string | null;
  recurringPercent: string | null;
  takeRateCap: string | null;
  introductoryOffer: string | null;
  aliases:IAliasValue[];
  isRebookEnabled: boolean;
}

export interface ITagValue {
  value:string;
}

export interface IAliasValue {
  value:string;
}

export function createEmptyServiceTypeDetailForm():IServiceTypeDetailForm {
  return {
    serviceTypeId: '',
    marketId: '',
    name: '',
    displayName: '',
    colorScheme: '',
    imageGuid: '',
    zipCodeTerritory: '',
    searchResultCardStyle: null,
    tags: [],
    serviceTypeCategoryMetadata: [],
    serviceTypePriority: ServiceTypePriority.PRIMARY,
    enabled: true,
    oneTimePercent: null,
    recurringPercent: null,
    takeRateCap: null,
    introductoryOffer: null,
    aliases: [],
    isRebookEnabled: false,
  };
}


export interface IServiceTypeAdminRequest {
  serviceTypeId:string | null;
  marketId:string | null;
  zipCodeTerritory:string | null;
}


export interface IServiceTypeAdminDTO {
  serviceTypeId:string;
  marketId:string | null;
  name:string;
  displayName:string | null;
  colorScheme:string;
  imageGuid:string;
  searchResultCardStyle:string | null;
  tags:string[];
  serviceTypeCategoryMetadata:IServiceTypeCategoryMetadata[];
  serviceTypePriority:string;
  enabled:boolean;
  zipCodeTerritory:string | null;
  serviceTypeInfo: IServiceTypeInfo | null;
  oneTimePercent: number | null;
  recurringPercent: number | null;
  takeRateCap: number | null;
  introductoryOffer: number | null;
  aliases:string[];
  isRebookEnabled: boolean;
}

export function createServiceTypeAdminDTOFromForm(form:IServiceTypeDetailForm):IServiceTypeAdminDTO {
  return {
    serviceTypeId: form.serviceTypeId,
    marketId: form.marketId,
    name: form.name,
    displayName: form.displayName,
    colorScheme: form.colorScheme,
    imageGuid: form.imageGuid,
    searchResultCardStyle: form.searchResultCardStyle,
    tags: form.tags.map(x => x.value),
    serviceTypeCategoryMetadata: form.serviceTypeCategoryMetadata,
    serviceTypePriority: form.serviceTypePriority,
    enabled: form.enabled,
    zipCodeTerritory: form.zipCodeTerritory,
    serviceTypeInfo: null,
    oneTimePercent: !form.oneTimePercent ? null : parseFloat(form.oneTimePercent),
    recurringPercent: !form.recurringPercent ? null : parseFloat(form.recurringPercent),
    takeRateCap: !form.takeRateCap ? null : parseFloat(form.takeRateCap),
    introductoryOffer: !form.introductoryOffer ? null : parseFloat(form.introductoryOffer),
    aliases: form.aliases.map(x => x.value),
    isRebookEnabled: form.isRebookEnabled,
  };
}