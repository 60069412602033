import { Grid } from '@mui/material';
import { PrimaryButton, SecondaryButton } from 'components/buttons';
import { ConfirmationModalContext } from 'components/modals/ConfirmationModal';
import { HookCheckbox } from 'components/reactHookForm';
import { IUserReconciliationForm } from 'model/userReconciliation';
import { useContext } from 'react';
import { FormProvider, SubmitHandler, useForm, useFormContext } from 'react-hook-form';
import UserUpload from './userTemplateUpload';

export default function UserUploadForm() {
  const { openModal } = useContext(ConfirmationModalContext);
  const formContext = useForm<IUserReconciliationForm>({
    mode: 'onTouched',
    defaultValues: {
      users: undefined,
      fileName: undefined,
    },
  });

  const onSubmit : SubmitHandler<IUserReconciliationForm> = data => {
    openModal({ data: data, fileName: data.users.name, formContext });
  };
  return (
    <FormProvider {...formContext}>
      <form onSubmit={formContext.handleSubmit(onSubmit)}>
        <Grid container spacing={1} justifyContent='center'>
          <Grid item xs={11}>
            <UserUpload/>
          </Grid>
          <Grid item xs={11}>
            <PrimaryButton type='submit'>Submit</PrimaryButton>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
}