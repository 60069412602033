import { Button, FormControl, FormHelperText, Grid, InputLabel } from '@mui/material';
import Spacer from 'components/Layout/Spacer';
import ConfirmationModal, { ConfirmationModalContext, ConfirmationModalContextProvider } from 'components/modals/ConfirmationModal';
import { HookCheckbox, HookSelect, HookTextField } from 'components/reactHookForm';
import PageHeader from 'components/SectionHeaders/PageHeader';
import useErrorState from 'hooks/reactHookForm/useErrorState';
import { INeighborhoodCommunicationForm, NeighborhoodCommunicationType } from 'model/neighborhood';
import { useContext, useState } from 'react';
import { FormProvider, SubmitHandler, useForm, useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import styles from './neighborhoodDetail.module.css';

function NeighborhoodCommunicationSelect(props: any) {
  const formCtx = useFormContext();

  const { isError, getErrorMessage } = useErrorState(formCtx);
  return (
    <FormControl variant='standard' fullWidth error={isError('status')}>
      <InputLabel htmlFor="status-type">Communication Type</InputLabel>
      <HookSelect
        control={formCtx.control}
        rules={{
          required: { value: true, message: 'Required' },
        }}
        name='communicationTemplate'
        id='communication-type'
        menuItems={props.menuItems}
      />
      <FormHelperText>{getErrorMessage('status')}</FormHelperText>
    </FormControl>
  );
}

export default function NeighborhoodCommunicationForm(props: any) {

  const commFormContext = useForm<INeighborhoodCommunicationForm>({ mode: 'onChange' });
  const confirmationModalCtx = useContext(ConfirmationModalContext);

  const sendCommunication : SubmitHandler<INeighborhoodCommunicationForm> = data => {

    if (props.neighborhoodId) {
      const nid = props.neighborhoodId;
      confirmationModalCtx.openModal({ nid, data });
    } else if (props.neighborhoodServiceOfferingInstanceId) {
      const nid = props.neighborhoodServiceOfferingInstanceId;
      confirmationModalCtx.openModal({ nid, data });
    }
  };

  return (
    <FormProvider {...commFormContext}>
      <form onSubmit={commFormContext.handleSubmit(sendCommunication)}>
        <Grid container item xs={12} spacing={1} className='pageGridContainer'>
          <Grid container item xs={12}>
            <PageHeader headerText={'Neighborhood Communications'}/>
            <h4>Please select the type of communication that you would like to send</h4>
          </Grid>
          <Grid item xs={12} md={3}>
            <NeighborhoodCommunicationSelect menuItems={props.menuItems}/>
          </Grid>
          <Grid item xs={12} md={3}>
            <HookTextField
              name='neighborhoodServiceOfferingInstanceId'
              label='Deal of the Month'
              maxLength={100}
            />
          </Grid>
          <Grid item xs={12}></Grid>
          <Grid item xs={12} md='auto' alignSelf={'center'}>
            <HookCheckbox name='shouldSendToLoggedInUser' label='Send to me as test'/>
          </Grid>
          <Grid item xs={12} md={3}>
            <HookTextField
              name='overrideEmail'
              label='Additional Override Email'
            />
          </Grid>
          <Spacer/>
          <Grid item xs={12}>
            <Grid container>
              <Button
                variant="contained"
                className={styles.saveButton}
                type="submit"
              >
                <span>Send Communication</span>
              </Button>
            </Grid>
          </Grid>
          <Spacer/>
        </Grid>
      </form>
    </FormProvider>
  );
}