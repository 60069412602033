import QuickSearchToolbar from 'components/grid/QuickSearchToolbar';
import useNeighborhoodSeedingToolingStore from './useNeighborhoodSeedingToolingStore';
import useTableSearch from 'components/grid/useTableSearch';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import Loading from 'components/Layout/Loading';
import { columns } from './tableColumnConfig';

export const loadingKey = 'NeighborhoodSeedingTable';

const columnTotalWidth = columns.map(x => x.width).reduce((a:any, b:any) => a+b, 0);
const quickSearchStorageKey = 'neighborhoodSeedingPopularity';

export default function NeighborhoodSeedingTable () {
  const { tableRows } = useNeighborhoodSeedingToolingStore();
  const { rows, searchText, setRowsWithStoredFilter, requestSearch } = useTableSearch(quickSearchStorageKey);
  const [sortModel, setSortModel] = useState([
    {
      field: 'name',
      sort: 'asc' as any,
    },
  ]);

  useEffect(() => {
    setRowsWithStoredFilter(tableRows);
  }, [tableRows]);

  return (
    <Loading loadingKey={loadingKey}>
      <Grid container spacing={2} className='pageGridContainer'>
        <Grid item xs={12}>
          <DataGrid
            rows={rows}
            components={{ Toolbar: QuickSearchToolbar }}
            componentsProps={{
              toolbar: {
                quickSearchStorageKey,
                value: searchText,
                onChange: (event: React.ChangeEvent<HTMLInputElement>) => requestSearch(tableRows, event.target.value),
                clearSearch: () => requestSearch(tableRows, ''),
              },
            }}
            columns={columns}
            autoHeight={true}
            sortModel={sortModel}
            onSortModelChange={setSortModel}
            style={{
              minWidth: '500px',
              maxWidth: `${columnTotalWidth}px`,
            }}
          />
        </Grid>
      </Grid>
    </Loading>
  );
};
