import { Button, Typography } from '@mui/material';
import GenericModal from 'components/modal/GenericModal';
import useModal, { IModal } from 'components/modal/useModal';
import { CompensateRewardRequest, createEmptyCompensateRewardRequest } from 'model/rewards';
import UpdateIndividualRewardBalanceForm from './UpdateIndividualRewardBalanceForm';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import useIndividualsHookStore from '../individualsHookStore';
import { useEffect } from 'react';

export const modalStoreKey = 'updateRewardBalanceModal';

export interface IUpdateBalanceModal {
  formData: CompensateRewardRequest;
  callback: (formData: CompensateRewardRequest) => Promise<void>;
}
export function createEmptyUpdateBalanceModal() :IUpdateBalanceModal {
  return {
    formData: createEmptyCompensateRewardRequest(),
    callback: async (formData: CompensateRewardRequest) => {},
  };
}
export interface IUpdateBalanceProps {
  initialFormData: CompensateRewardRequest;
}

export function UpdateBalanceModalInternal({ initialFormData }:IUpdateBalanceProps) {
  const { getModalState, closeModal } = useModal(modalStoreKey);
  const _state = getModalState() as IUpdateBalanceModal & IModal;
  const { formData, callback } = _state;

  const { individual, onCompensateReward } = useIndividualsHookStore();
  async function _onConfirm(data) {
    void onCompensateReward(data);
    closeModal(createEmptyUpdateBalanceModal());
  }


  return (
    <GenericModal
      modalStoreKey={modalStoreKey}
      title={'Update Individual Reward Balance'}
      modalContent={(
        <UpdateIndividualRewardBalanceForm
          onConfirm={_onConfirm}
          onCancel={() => closeModal(createEmptyUpdateBalanceModal())}
        />
      )}
      customConfirmationHandling
      secondaryButtonText='Cancel'
    />
  );
}


export default function UpdateBalanceModal() {
  const { formData, init } = useIndividualsHookStore();
  useEffect(() => {
    void init();
  }, []);
  return (
    <UpdateBalanceModalInternal initialFormData={formData} />
  );
}