import { Grid, Typography } from '@mui/material';
import { AddButton, RemoveButton } from 'components/buttons';
import Spacer from 'components/Layout/Spacer';
import { HookTextField } from 'components/reactHookForm';
import HookPhoneField from 'components/reactHookForm/HookPhoneField';
import { useEffect } from 'react';
import { useFormContext, useFieldArray, useWatch } from 'react-hook-form';
import { createValidatePhone, createValidatePhoneNotRequired } from 'util/rhfValidateFunctions';
import styles from './serviceProviderDetails.module.css';
import { activeOrGreater } from 'util/serviceProviderUtil';

const validatePhone = createValidatePhone('Should be of form xxx-xxx-xxxx or xxxyyyzzzz');
const validatePhoneNotRequired = createValidatePhoneNotRequired('Should be of form xxx-xxx-xxxx or xxxyyyzzzz');

export default function AdditionalPhoneNumbers() {
  const { control, getValues } = useFormContext();

  const { fields: additionalContactPhones, append, replace, remove } = useFieldArray({
    name: 'transientAdditionalContactPhones',
  });
  const watchStatus = useWatch({ control, name: 'status' });
  var isActiveOrGreater = activeOrGreater(watchStatus);
  const watchAdditionalPhones = useWatch({ control, name: 'transientAdditionalContactPhones' });
  useEffect(() => {
    if (additionalContactPhones.length !== watchAdditionalPhones.length) {
      replace(watchAdditionalPhones);
    }
  }, [watchAdditionalPhones]);

  return (
    <Grid container item alignItems='center'>
      <Grid item xs={6}>
        <HookPhoneField
          label='Contact Phone'
          name='contactPersonPhone'
          required={isActiveOrGreater}
          validationRules={{
            validate: isActiveOrGreater ? validatePhone : validatePhoneNotRequired,
          }}
        />
      </Grid>
      <Grid container alignItems='center' item xs='auto' >
        <Typography>Add Contact Phone</Typography>
        <Grid item xs='auto'>
          <AddButton
            tooltipTitle="Add a phone number"
            onClick={() => append({ value: '' })}
          />
        </Grid>
        <Spacer/>
      </Grid>
      {additionalContactPhones.map((item, index) => {

        return (
          <Grid container item spacing={1} alignItems='center' key={index}>
            <Grid container item xs={6} alignItems='center'>
              <HookPhoneField
                name={`transientAdditionalContactPhones.${index}.value`}
                label={`Additional Phone ${index+1}`}
              />
            </Grid>

            <Grid container item xs='auto' alignSelf='center' >
              <RemoveButton
                tooltipTitle="Delete this phone number"
                onClick={() => {
                  remove(index);
                }}
              />
            </Grid>
          </Grid>
        );
      })}

    </Grid>
  );
}