import { Grid, Typography } from '@mui/material';
import { IMarketForm, createEmptyMarketForm } from 'model/markets';
import { useEffect } from 'react';
import { useForm, FormProvider, useFormContext, useWatch } from 'react-hook-form';
import SubmitButton from './formComponents/SubmitButton';
import HookConfirmationModal from 'components/modals/HookConfirmationModal';
import useMarkets from 'hooks/useMarkets';
import { HookTextField } from 'components/reactHookForm';
import { useNavigate } from 'react-router-dom';
import { SecondaryButton } from 'components/buttons';
import useMarketDetails from './hookStore/useMarketDetails';
import StateAbbrSelect from './formComponents/StateAbbrSelect';
import useToast from 'components/toast/useToast';
import { MetadataConstants } from 'constants/MetadataConstants';

export interface IMarketFormProps {
  isNew:boolean;
}

export interface IMarketFormInternalProps {
  initialFormData: IMarketForm;
}

export const marketDetailsModalKey = 'marketDetailsModalKey';

function ModalWrapper() {
  const { getValues, control } = useFormContext();
  const { save } = useMarketDetails();
  const navigate = useNavigate();
  return (
    <HookConfirmationModal
      title={() => 'Confirm Market'}
      body='Save changes?'
      modalKey={marketDetailsModalKey}
      message={(confirmArgs, cancelModal) => (
        <Grid container spacing={1} alignItems='center' justifyContent='center' >
          <Grid item xs={12}>
            <Typography variant='body1'>This market will become available for assignment to service providers, territories, and neighborhoods</Typography>
          </Grid>
        </Grid>
      )}
      onConfirm={async (data) => {
        await save(data);
        navigate('/markets');

      }}

    />
  );
}

function MarketFormInternal({ initialFormData }:IMarketFormInternalProps) {
  const navigate = useNavigate();
  const formContext = useForm<IMarketForm>({
    mode: 'onChange',
    defaultValues: createEmptyMarketForm(),
  });
  useEffect(() => {
    formContext.reset(initialFormData);
  }, [initialFormData]);

  const selectedMarketId = useWatch({ control: formContext.control, name: 'id' });
  return (
    <FormProvider {...formContext}>
      <form >
        <Grid container spacing={1} >
          <Grid item xs={12} sm={6} md={3}>
            <HookTextField name='name' label='Name' disabled={MetadataConstants.UNASSIGNED_MARKET_ID === selectedMarketId} required/>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <HookTextField name='parentMarket' label='Parent Market' required disabled/>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <StateAbbrSelect/>
          </Grid>

          <Grid container item xs={12} alignItems='center' sx={{ marginTop: '10px' }}>
            <Grid item xs='auto' sx={{ marginRight: '10px' }}>
              <SubmitButton/>
            </Grid>
            <Grid item xs='auto'>
              <SecondaryButton
                onClick={() => {
                  formContext.reset(initialFormData);
                  navigate('/markets');
                }}>
                <>Cancel</>
              </SecondaryButton>
            </Grid>
          </Grid>
          <ModalWrapper/>
        </Grid>
      </form>
    </FormProvider>
  );
}

export default function MarketDetailsForm({ isNew }: IMarketFormProps) {
  const { formData, init } = useMarketDetails();
  useEffect(() => {
    void init(isNew);
  }, [isNew]);
  return (
    <MarketFormInternal initialFormData={formData}/>
  );
}