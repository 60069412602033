import { FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, SelectChangeEvent, Box, Button, Typography } from '@mui/material';
import { IDropdownOption } from 'model/dropdown';
import useTsoiManagementBatchServiceTypes from '../hookStore/useTsoiManagementBatchServiceTypes';
import useToast from 'components/toast/useToast';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 13 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function ServiceTypeMultiSelect() {
  const { selectedServiceTypes, setSelectedServiceTypes, options } = useTsoiManagementBatchServiceTypes();
  const { createErrorToast } = useToast();


  const handleChange = (event: SelectChangeEvent<typeof selectedServiceTypes>) => {
    const {
      target: { value },
    } = event;

    // remove element from list
    const lastElement = value[value.length - 1];
    const indexOfLatestElementInSelectedServiceTypes = selectedServiceTypes.findIndex((x) => x.optionValue === lastElement);
    if (indexOfLatestElementInSelectedServiceTypes > -1) {
      const newSelectedServiceTypes = [...selectedServiceTypes];
      newSelectedServiceTypes.splice(indexOfLatestElementInSelectedServiceTypes, 1);

      setSelectedServiceTypes(newSelectedServiceTypes);
      return;
    }

    // limit selection to 4 service types maximum
    if (selectedServiceTypes.length === 4) {
      createErrorToast('You can select a max of 4 service types');
      return;
    }
    var selectedOptionsFromString = typeof value === 'string' ? value.split(',') : value;
    // newly selected options will be the ID. We need to hydrate the option object
    // based on the ID
    var selectedOptionsHydrated = selectedOptionsFromString
      .map((x) => {
        if (typeof x === 'string') {
          return options.find((option) => option.optionValue === x);
        }
        return x;
      }).filter(x => x !== undefined);

    setSelectedServiceTypes(selectedOptionsHydrated as IDropdownOption[]);
  };

  const renderChips = (selected) => (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
      {selected.map((value) => (
        <Button
          key={value.key}
          variant='outlined'
          type='button'
          size='small'
          onClick={(e) => {
            let nextValues = selected.filter(y => y !== value.optionValue).map(z => z.optionValue ?? z);
            setSelectedServiceTypes(nextValues);
          }}
        >
          <Typography variant='body1' fontSize={12}>{value.optionText}</Typography>
        </Button>
      ))}
    </Box>
  );

  return (
    <div>
      <FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel id="serviceTypesLabel">Service types</InputLabel>
        <Select
          labelId="serviceTypesLabel"
          id="serviceTypes"
          multiple
          value={selectedServiceTypes}
          onChange={handleChange}
          input={<OutlinedInput label="Service Types" />}
          renderValue={renderChips}
          MenuProps={MenuProps}
        >
          {options.map((option) => (
            <MenuItem key={option.key} value={option.optionValue}>
              <ListItemText primary={option.optionText} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}