import { Grid, Typography } from '@mui/material';
import GenericModal from 'components/modal/GenericModal';
import useModal, { IModal } from 'components/modal/useModal';
import useTerritorySeedingTemplates from 'pages/territorySeedingTemplates/hookStore/useTerritorySeedingTemplates';
import CloneFromTerritorySelect from './CloneFromTerritorySelect';
import Loading from 'components/Layout/Loading';

export const modalStoreKey = 'CloneFromModal';

export default function CloneFromModal() {
  const { closeModal } = useModal(modalStoreKey);
  const {
    onCloneFromConfirm,
    refreshTemplatesForCurrentTerritory,
    selectedCloneFromTerritory,
    selectedTerritory,
    territories,
    loadingKey,
  } = useTerritorySeedingTemplates();

  async function onCloneFromConfirmClick() {
    await onCloneFromConfirm();
    await refreshTemplatesForCurrentTerritory();
    closeModal({});
  }

  const toTerritoryFullName = territories.find((x:any) => x.optionValue === selectedTerritory)?.optionText;
  const fromTerritoryFullName = territories.find((x:any) => x.optionValue === selectedCloneFromTerritory)?.optionText;

  return (
    <GenericModal
      modalStoreKey={modalStoreKey}
      title={'Clone Existing Territory Seeding Template'}
      modalContent={
        (
          <Loading loadingKey={loadingKey}>
            <Grid container flexDirection={'column'}>
              <Grid item xs={12}>
                <p>This will create a new territory template for {toTerritoryFullName} from {fromTerritoryFullName} </p>
              </Grid>
              <Grid item xs={6} paddingTop={'25px'} paddingBottom={'15px'}>
                <CloneFromTerritorySelect />
              </Grid>
            </Grid>
          </Loading>
        )
      }
      onConfirm={() => onCloneFromConfirmClick()}
      secondaryButtonText='Cancel'
      overrideSx={{
        '& .MuiPaper-root': {
          xs: {
            width: '40%',
            height: '100%',
            maxWidth: '700px',
          },
          sm: {
            height: 'auto',
          },
        },

      }}
    />
  );
}