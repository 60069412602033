import { Grid, Typography } from '@mui/material';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import useRemoveProviderFromTemplates from './hookStore/useRemoveProviderFromTemplates';
import ServiceProviderSelect from './form_components/ServiceProviderSelect';
import { RemoveProviderFromTemplateModel } from 'model/seeding/removeProviderFromTemplates';
import SaveButton from './form_components/SaveButton';
import ServiceOfferingSelections from './form_components/ServiceOfferingSelections';
import { createUninitializedDropdownOption } from 'model/dropdown';


export default function RemoveProviderFromTemplatesForm() {
  const { onProviderSelect, onSave } = useRemoveProviderFromTemplates();

  const formContext = useForm<RemoveProviderFromTemplateModel.IRemoveRequestForm>({
    mode: 'onChange',
    defaultValues: {
      serviceProvider: createUninitializedDropdownOption(),
      serviceOfferingDtos: [],
    },
  });

  const onSubmit : SubmitHandler<RemoveProviderFromTemplateModel.IRemoveRequestForm> = async data => {
    await onSave(data);

  };

  return (
    <FormProvider {...formContext}>
      <form onSubmit={formContext.handleSubmit(onSubmit)}>
        <Grid container item >
          <Grid item xs={12} sm={4} paddingTop="20px" paddingBottom="20px">
            <ServiceProviderSelect/>
          </Grid>
          <Grid container item>
            <Grid item xs={12}>
              <Typography variant='h6'>Which Service Offerings?</Typography>
            </Grid>
            <Grid item xs>
              <ServiceOfferingSelections/>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <SaveButton/>
          </Grid>

        </Grid>
      </form>
    </FormProvider>
  );
}