import RemoveIcon from '@mui/icons-material/Remove';
import { IconButton, Tooltip } from '@mui/material';

interface ISubtractButtonProps {
  tooltipTitle:string;
  onClick:any;
  className?:string;
}

export default function SubtractButton({ tooltipTitle, onClick, className }:ISubtractButtonProps) {
  return (
    <Tooltip title={tooltipTitle} className={className}>
      <IconButton onClick={onClick}>
        <RemoveIcon/>
      </IconButton>
    </Tooltip>
  );
}