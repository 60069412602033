import { Grid, Typography } from '@mui/material';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import useCustomerUpload from './hookStore/customerUploadHookStore';
import { useEffect } from 'react';
import { IServiceOfferingAllocation } from 'model/customerUpload';
import HookPercentField from 'components/reactHookForm/HookPercentField';

export default function ServiceOfferingAllocations() {

  const { onStep1SelectedProviderChanged, serviceTypeNameMap } = useCustomerUpload();
  const { fields: serviceOfferingAllocations, append, replace, move, remove } = useFieldArray({
    name: 'serviceOfferingAllocations',
  });
  const formContext = useFormContext();
  const selectedServiceProvider = useWatch({ control: formContext.control, name: 'selectedServiceProvider' });
  useEffect(() => {
    void onStep1SelectedProviderChanged(formContext, replace, selectedServiceProvider?.optionValue);
  }, [selectedServiceProvider]);


  return (
    <Grid container item spacing={1} sx={{ padding: '10px' }}>
      <Typography variant='h6'>Service Offering Allocations</Typography>
      <Grid item xs={12}></Grid>
      {
        serviceOfferingAllocations.map((item:any, index) => {
          const serviceOfferingAllocation = item as IServiceOfferingAllocation;
          return (

            <Grid item xs={6} lg={4} key={item.id}>
              <Typography variant='caption' color='text.secondary'>{`${serviceTypeNameMap[serviceOfferingAllocation.serviceTypeId]}`}</Typography>
              <HookPercentField
                required
                name={`serviceOfferingAllocations.${index}.percent`}
                label={`${serviceOfferingAllocation.name}`}
              />

            </Grid>
          );
        })
      }
    </Grid>
  );
}