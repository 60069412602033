import PageHeader from 'components/SectionHeaders/PageHeader';
import ServiceTypeMultiSelect from './_components/ServiceTypeMultiSelect';
import Loading from 'components/Layout/Loading';
import DetailItem from './_components/DetailItem';
import DraggableList from './_components/DraggableList';
import useTsoiManagementBatchServiceTypes from './hookStore/useTsoiManagementBatchServiceTypes';
import styles from './tsoiManagement.module.css';
import { Button, Grid } from '@mui/material';
import { PrimaryButton } from 'components/buttons';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';

export default function TsoiManagementBatchServiceTypes() {
  const {
    init,
    selectedServiceTypes,
    onSave,
    onCancel,
    setSelectedServiceTypes,
    loadingKey,
    territory,
  } = useTsoiManagementBatchServiceTypes();
  const { batchId, territoryId } = useParams();

  useEffect(() => {
    void init(batchId, territoryId);
  }, [batchId, territoryId]);

  return (
    <Loading loadingKey={loadingKey}>
      <Grid sx={{ paddingLeft: '10px', paddingRight: '10px' }}>
        <PageHeader headerText="Step 3. Select Service Types" />
        <Grid container item xs={12} flexDirection={'row'} paddingTop={'25px'}>
          <Grid item xs={12}>
            <DetailItem label={'Territory'} value={territory.name} />
          </Grid>
        </Grid>
        <Grid container flexDirection={'row'} paddingTop={'25px'}>
          <Grid item xs={5}>
            <ServiceTypeMultiSelect />
          </Grid>
          <Grid item xs={4}>
            <DraggableList
              title={'Selected Service Types'}
              items={selectedServiceTypes}
              stateSetter={setSelectedServiceTypes}
            />
          </Grid>
        </Grid>
        <Grid container item justifyContent={'center'} paddingTop={'50px'} xs={6}>
          <Grid item paddingRight={'25px'}>
            <PrimaryButton onClick={onSave}>Save & Continue</PrimaryButton>
          </Grid>
          <Grid item paddingLeft={'25px'}>
            <Button variant={'outlined'} color={'error'} className={styles.cancelButton} onClick={onCancel}>Cancel</Button>
          </Grid>
        </Grid>
      </Grid>
    </Loading>
  );
}