
import { FormControl, FormHelperText } from '@mui/material';
import HookAutocomplete from 'components/reactHookForm/HookAutocomplete';
import useErrorState from 'hooks/reactHookForm/useErrorState';
import get from 'lodash.get';
import { IDropdownOption } from 'model/dropdown';
import { useFormContext } from 'react-hook-form';
import useNeighborhoodLaunchTooling from '../hookStore/useNeighborhoodLaunchTooling';
import useProviderCoverageAreaLaunchToolingStore from '../hookStore/useProviderCoverageAreaLaunchTooling';
import ServiceProviderMultiAutocomplete from 'components/serviceProviderAutocomplete/ServiceProviderMultiAutocomplete';


export interface IServiceProviderMultiSelectProps {
  onServiceProviderRemove:(index?:number) => void;
  onServiceProviderAdd:(id:string, name:string) => void;
  variant: 'neighborhoodLaunchTooling' | 'providerCoverageAreaLaunchTooling';
}

export default function ServiceProviderMultiSelect({ onServiceProviderRemove, onServiceProviderAdd, variant }:IServiceProviderMultiSelectProps) {
  const formCtx = useFormContext();
  const { formState, setError, clearErrors } = formCtx;
  const { isError, getErrorMessage } = useErrorState(formCtx);
  const isDirty = get(formState.dirtyFields, 'serviceProviderIds', false);
  const isTouched = get(formState.touchedFields, 'serviceProviderIds', false);
  return (

    <ServiceProviderMultiAutocomplete
      label={'Service Providers'}
      fieldName='serviceProviderIds'
      postOnChange={(_formCtx, values, reason, details) => {
        if (reason === 'removeOption') {
          const launchDtos = _formCtx.getValues('serviceProviderLaunchDtos');
          for (let i = 0; i < launchDtos.length; i++) {
            let serviceProviderId = launchDtos[i].serviceProviderId;
            if ((details?.option?.optionValue ?? details.optionValue) === serviceProviderId) {
              onServiceProviderRemove(i);
            }
          }
        } else if (reason === 'selectOption') {
          onServiceProviderAdd(details.option.optionValue, details.option.optionText);
        } else if (reason === 'clear') {
          onServiceProviderRemove();
        }
      }}
      formless={false}
      placeholder={'Search and check service providers'}
      required={false}
    />
  );
}