import { Grid, Typography } from '@mui/material';
import Loading from 'components/Layout/Loading';
import PageHeader from 'components/SectionHeaders/PageHeader';
import useProviderCoverageArea from './hookStore/useProviderCoverageArea';
import ProviderCoverageAreaForm from './ProviderCoverageAreaForm';

export default function ProviderCoverageArea() {
  const { loadingKey } = useProviderCoverageArea();
  return (
    <Loading loadingKey={loadingKey} size={100}>
      <Grid container spacing={2} className='pageGridContainer'>
        <Grid item xs={12}>
          <PageHeader headerText={'Provider Coverage Area'} />
        </Grid>
        <Grid item xs={12}>
          <ProviderCoverageAreaForm/>
        </Grid>
      </Grid>
    </Loading>
  );
}