import { Grid } from '@mui/material';
import get from 'lodash.get';
import { DiscountScheduleTypes } from 'model/discountSchedule';
import { INeighborhoodServiceOffering } from 'model/neighborhoodServiceOffering';
import { useFormContext, useWatch } from 'react-hook-form';
import StepHeader from './StepHeader';
import TextInput from './TextInput';
import validateNumber from './validators';

function isTruthyNumber(value) {
  return value !== undefined && value !== null && value !== '';
}
export interface IDollarSavedPerCustomerFieldsProps {
  neighborhoodServiceOfferings:INeighborhoodServiceOffering[];
}
export default function DollarSavedPerCustomerFields({ neighborhoodServiceOfferings }:IDollarSavedPerCustomerFieldsProps) {
  const { control, trigger, getValues } = useFormContext();
  const watchedDiscountScheduleType = useWatch({ control, name: 'type' });
  const nsosWithNeighborhoodLevelPrices = neighborhoodServiceOfferings.filter( x=> x.price);
  return (
    <Grid container item xs={12} spacing={1}>
      <Grid item xs={12}>
        <StepHeader>Step 4: Discount Schedule Details</StepHeader>
      </Grid>
      <Grid item xs={2}>
        <TextInput
          required
          name='discountSchedule.startingAt'
          label='Starting At'
          maxLength='100'
          validationRules={{
            validate: function(value) {
              let _validatedNumber = validateNumber(value);
              if (_validatedNumber !== true) {
                return _validatedNumber;
              }
              if (isTruthyNumber(value)) {
                let parsedValue = parseFloat(value);
                if (watchedDiscountScheduleType === DiscountScheduleTypes.PercentSavePerCustomer && parsedValue > 100) {
                  return 'Percent value must be less than 100%.';
                }
                let priceIncrement = get(getValues(), 'discountSchedule.discountIncrement');
                let maxDiscount = get(getValues(), 'discountSchedule.maxDiscount');
                if (isTruthyNumber(maxDiscount) && parsedValue >= parseFloat(maxDiscount)) {
                  return 'The Starting At value must be less than the max discount.';
                }
                if ( isTruthyNumber(priceIncrement) &&
                                    isTruthyNumber(maxDiscount) &&
                                    parseFloat(priceIncrement) + parsedValue > parseFloat(maxDiscount)
                ) {
                  return 'The sum of the starting at price and the increment must be less than or equal to the max discount.';
                }
              }

            },
          }}
        />
      </Grid>
      <Grid item xs={2}>
        <TextInput
          required
          name='discountSchedule.discountIncrement'
          label='Discount Increment'
          maxLength='100'
          postOnChange={() => {
            void trigger('discountSchedule.startingAt');
          }}
          validationRules={{
            validate: function(value) {
              let _validatedNumber = validateNumber(value);
              if (_validatedNumber !== true) {
                return _validatedNumber;
              }
              if (isTruthyNumber(value)) {
                let parsedValue = parseFloat(value);
                if (watchedDiscountScheduleType === DiscountScheduleTypes.PercentSavePerCustomer && parsedValue > 100) {
                  return 'Percent value must be less than 100%.';
                }
                let maxDiscount = get(getValues(), 'discountSchedule.maxDiscount');
                if (isTruthyNumber(maxDiscount) && parsedValue > parseFloat(maxDiscount)) {
                  return 'The Discount Increment value must be less than the max discount.';
                }

              }
            },
          }}
        />
      </Grid>
      <Grid item xs={2}>
        <TextInput
          required
          name='discountSchedule.maxDiscount'
          label='Max Discount'
          maxLength='100'
          postOnChange={() => {
            void trigger('discountSchedule.startingAt');
          }}
          validationRules={{
            validate: function(value) {
              let _validatedNumber = validateNumber(value);
              if (_validatedNumber !== true) {
                return _validatedNumber;
              }
              if (isTruthyNumber(value)) {
                let parsedValue = parseFloat(value);
                if (watchedDiscountScheduleType === DiscountScheduleTypes.PercentSavePerCustomer && parsedValue > 100) {
                  return 'Percent value must be less than 100%.';
                }

                if (watchedDiscountScheduleType === DiscountScheduleTypes.DollarSavePerCustomer) {
                  for (let i = 0; i < nsosWithNeighborhoodLevelPrices.length;i++) {
                    let nsoWithPrice = nsosWithNeighborhoodLevelPrices[i];
                    if (nsoWithPrice && nsoWithPrice.price && nsoWithPrice.price - parsedValue <= 0) {
                      return `${nsoWithPrice.name} has a price: ${nsoWithPrice.price}, which combined with a max discount of ${parsedValue}, would result in a less than 0 price for customers.`;
                    }
                  }
                }
              }
            },
          }}
        />
      </Grid>
      <Grid item xs={2}>
        <TextInput
          name='discountSchedule.startingCountOffset'
          label='Starting Count Offset'
          maxLength='100'
          validationRules={{
            validate: validateNumber,
          }}
        />
      </Grid>
    </Grid>
  );
}