import { Button } from '@mui/material';
import styles from './SecondaryButton.module.css';

export default function SecondaryButton({ onClick, children, ...buttonProps }) {
  return (
    <Button
      variant="outlined"
      onClick={onClick}

      {...buttonProps}
      className={styles.cancelButton}
    >{children}
    </Button>
  );
}