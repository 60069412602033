import { IPreviewFile } from 'model/imageUpload';

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  padding: 4,
};

const img = {
  display: 'block',
  width: '200px',

};

export interface IThumbnailProps {
  file:IPreviewFile;
}

export default function Thumbnail({ file }:IThumbnailProps) {
  return (
    <div key={file.name} style={thumb}>
      <div >
        <img
          src={file.preview}
          style={img}
          width='100px'
          // Revoke data uri after image is loaded
          onLoad={() => { URL.revokeObjectURL(file.preview); }}
        />
      </div>
    </div>
  );
}