
import { Grid } from '@mui/material';
import { PrimaryButton } from 'components/buttons';
import { useEffect } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import WidgetConfigDTO from './WidgetConfigDTO';

export default function CompoundTitle() {
  const { control } = useFormContext();


  const { fields: compoundTitle, append, replace, remove } = useFieldArray({
    name: 'compoundTitle',
  });
  const watchCompoundTitle = useWatch({ control, name: 'compoundTitle' });
  useEffect(() => {
    if (compoundTitle.length !== watchCompoundTitle.length) {
      replace(watchCompoundTitle);
    }
  }, [watchCompoundTitle]);

  return (
    <Grid container item>
      {watchCompoundTitle.map((item, index) => {
        return (
          <Grid item xs={12} sm={4} key={index}>
            <WidgetConfigDTO fieldName={`compoundTitle[${index}]`} />
          </Grid>
        );
      })}
      <PrimaryButton
        onClick={() => append({})}>
        <>Add Compound Title</>
      </PrimaryButton>
    </Grid>
  );
}