import { createFilterOptions, FormControl } from '@mui/material';
import { createNeighborhoodExperimentTagOption, findAllNeighborhoodExperimentTags } from 'api/neighborhoodExperimentTagApi';
import { HookSingleAsyncAutocomplete } from 'components/reactHookForm';
import useToast from 'components/toast/useToast';
import { IDropDownOptionCreatable, IDropdownOption } from 'model/dropdown';
import { LookUpEntityType } from 'model/lookup/lookup';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { convertLookupToDropdownOptions } from 'util/dropdownUtil';

const filter = createFilterOptions<IDropDownOptionCreatable>();

export default function ExperimentTag() {
  const { createInfoToast, createSuccessToast, createErrorToast } = useToast();
  const formCtx = useFormContext();
  const [options, setOptions] = useState<IDropdownOption[]>([]);

  const postOnChange = async (_formCtx, val, reason, details) => {
    if (reason === 'selectOption' && val?.inputValue) {
      createInfoToast('Saving new option...');
      try {
        let res = await createNeighborhoodExperimentTagOption({
          value: val?.optionValue,
          lookUpEntityType: LookUpEntityType.NEIGHBORHOOD_EXPERIMENT_TAG,
        });
        const experimentTagsRes = await findAllNeighborhoodExperimentTags();
        if (experimentTagsRes.data) {
          setOptions(convertLookupToDropdownOptions(experimentTagsRes.data!));
        }

        createSuccessToast('New option saved');
      } catch (e:any) {
        createErrorToast(`Error saving new option ${e.response.data.message}` );
      }
    }


    if (reason === 'selectOption') {
      _formCtx.setValue('experimentTag', val?.optionValue);
    }
  };

  return (
    <FormControl variant='standard' fullWidth>
      <HookSingleAsyncAutocomplete
        name='experimentTag'
        label="Experiment Tag (type to add)"
        postOnChange={postOnChange}
        extraProps={{
          onOpen: async () => {
            const experimentTagsRes = await findAllNeighborhoodExperimentTags();
            if (experimentTagsRes.data) {
              setOptions(convertLookupToDropdownOptions(experimentTagsRes.data!));
            }
          },
          filterOptions: (opts, params) => {
            const filtered = filter(opts, params);

            const { inputValue } = params;
            // Suggest the creation of a new value
            const isExisting = options.some((option) => inputValue === option.optionValue);
            if (inputValue !== '' && !isExisting) {
              filtered.push({
                key: inputValue,
                optionValue: inputValue,
                optionText: `Add "${inputValue}"`,
                inputValue: inputValue,
              });
            }

            return filtered;
          },
          selectOnFocus: true,
          clearOnBlur: true,
          handleHomeEndKeys: true,
          getOptionLabel: (option) => {
          // Value selected with enter, right from the input
            if (typeof option === 'string') {
              return option;
            }
            // Add "xxx" option created dynamically
            if (option.inputValue) {
              return option.inputValue;
            }
            // Regular option
            return option.optionText;
          },
          freeSolo: true,
        }}
        dropdownOptions={options}
        placeholder={''}
        onInputChange={(event, newInputValue, reason) => {

        } }
      />
    </FormControl>
  );
}