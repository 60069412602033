import { useLoading } from 'components/Layout/Loading';
import createStore from 'hooks/hookStore';
import { useEffect, useState } from 'react';
import { findMarkets } from 'api/marketsApi';
import { IMarket } from 'model/markets';

const loadingKey = 'MarketsTableStore';
type MarketsTableStore = {
  markets: IMarket[];
}

const { get, update, registerListener, unregisterListener } = createStore<MarketsTableStore>('MarketsTableStore', {
  markets: [],
});


export default function useMarketsTable() {
  const setState = useState(get())[1];
  const { onLoading, doneLoading } = useLoading(loadingKey);


  useEffect(() => {
    registerListener(setState);
    return () => {
      unregisterListener(setState);
    };
  }, []);

  async function init(setRowsWithStoredFilter: any) {

    onLoading();
    try {
      try {
        const res = await findMarkets();
        const markets = res.data;
        setRowsWithStoredFilter(markets);
        update({
          ...get(),
          markets,
        });
      } catch (err:any) {
        console.error(err);
      }

    } catch (e:any) {
      console.error(e);
    }
    doneLoading(300);
  }

  async function onConfirmDelete(territoryId: string) {
    //TODO: implement delete if necessary
  }


  return {
    ...get(),
    loadingKey,
    init,
    onConfirmDelete,
  };
}