import ToggleOffOutlinedIcon from '@mui/icons-material/ToggleOffOutlined';
import ToggleOnOutlinedIcon from '@mui/icons-material/ToggleOnOutlined';
import { IconButton, Tooltip } from '@mui/material';

interface IToggleButtonProps {
  toggled:boolean;
  tooltipTitle:string;
  onClick:any;
  className?:string;
  disabled?:boolean;
  iconButtonProps?:any;
  tooltipProps?:any;
}
export default function ToggleButton({
  toggled,
  tooltipTitle,
  onClick,
  className,
  disabled,
  tooltipProps,
  iconButtonProps,
}:IToggleButtonProps) {
  return (
    <Tooltip title={tooltipTitle} className={className} {...tooltipProps}>
      <IconButton onClick={onClick} disabled={disabled} {...iconButtonProps}>
        {toggled ? <ToggleOnOutlinedIcon sx={{ color: 'green' }}/> : <ToggleOffOutlinedIcon sx={{ color: 'red' }}/>}
      </IconButton>
    </Tooltip>
  );
}