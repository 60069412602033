
import { FormControl, FormHelperText } from '@mui/material';
import { HookSingleAutocomplete } from 'components/reactHookForm';
import useErrorState from 'hooks/reactHookForm/useErrorState';
import { UNSELECTED_OPTION } from 'model/dropdown';
import { useFormContext } from 'react-hook-form';
import useRebookServiceNotifications from '../hookStore/useRebookServiceNotifications';


export interface INSOISelectProps {

}

export default function NSOISelect({ }:INSOISelectProps) {
  const { nsoiOptions } = useRebookServiceNotifications();
  const formCtx = useFormContext();
  const { isError, getErrorMessage } = useErrorState(formCtx);
  let rules:any = {
    required: {
      value: true, message: 'Required',
    },
  };
  return (
    <FormControl variant='standard' fullWidth error={isError('neighborhoodServiceOfferingInstanceId')}>
      <HookSingleAutocomplete
        rules={rules}
        name='neighborhoodServiceOfferingInstanceId'
        label='Target Group Deal'
        dropdownOptions={nsoiOptions}
        placeholder='Search and select NSOI'
        postOnChange={(formContext, value, reason, details) => {

        }}
        extraProps={{
          disabled: nsoiOptions.filter(x => x.optionValue !== UNSELECTED_OPTION).length === 0,
        }}
      />
      <FormHelperText>{getErrorMessage('neighborhoodServiceOfferingInstanceId')}</FormHelperText>
    </FormControl>
  );
}