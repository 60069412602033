import { FormControl, FormHelperText, InputLabel } from '@mui/material';
import { HookSelect } from 'components/reactHookForm';
import useErrorState from 'hooks/reactHookForm/useErrorState';
import { SupplyAcquisitionTargetLevel, TerritoryStatus } from 'model/territories';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

export default function SupplyAcquisitionTargetSelect() {
  const formCtx = useFormContext();
  const [levels] = useState<any[]>(SupplyAcquisitionTargetLevel.list);
  const { isError, getErrorMessage } = useErrorState(formCtx);
  return (
    <FormControl variant='standard' fullWidth error={isError('supplyAcquisitionTargetlevel')}>
      <InputLabel htmlFor="SupplyAcquisitionTargetSelect">Supply Acquisition Target Level</InputLabel>
      <HookSelect
        rules={{
          required: { value: false, message: 'Required' },
        }}
        control={formCtx.control}
        name='supplyAcquisitionTargetlevel'
        id='SupplyAcquisitionTargetSelect'
        menuItems={levels}
      />
      <FormHelperText>{getErrorMessage('supplyAcquisitionTargetlevel')}</FormHelperText>
    </FormControl>
  );
}