import { HookCheckbox } from 'components/reactHookForm';
import { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import useProviderSetupStore from '../useProviderSetupStore';
import { Checkbox, FormControlLabel } from '@mui/material';

/**
 * There is no dedicated field for this checkbox, instead it listens to existing state and existing form fields
 * to manipulate the image references on the service offering to be saved using the selected service offering to clone.
 * @returns
 */
export default function CloneWithImagesCheckbox() {
  const formCtx = useFormContext();
  const { serviceOfferingCloneOptions, resetPendingDeleteImages } = useProviderSetupStore();
  const [checked, setChecked] = useState(true);
  const [clonedImageRefs, setClonedImageRefs] = useState<any>([]);
  const watchedServiceOfferingIdToClone = useWatch({ control: formCtx.control, name: 'serviceOfferingIdToClone' });

  //When the service offering clone select option changes then we will reset the cloned image refs here that the checkbox uses.
  //by default this checkbox will always be true and will be reset to true when the service offering to clone changes so that the image refs are updated.
  useEffect(() => {
    const serviceOffering = serviceOfferingCloneOptions.find((option) => option.optionValue === watchedServiceOfferingIdToClone)?.ancillary;
    if (serviceOffering) {
      setClonedImageRefs([...serviceOffering.imageRefs]);
    } else {
      setClonedImageRefs([]);
    }
    setChecked(true);
  }, [watchedServiceOfferingIdToClone]);

  useEffect(() => {
    if (checked) {
      formCtx.setValue('imageRefs', [...clonedImageRefs]);
    } else {
      formCtx.setValue('imageRefs', []);
    }
    resetPendingDeleteImages();
  }, [checked]);


  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={checked}
          onChange={(e) => {
            setChecked(e.target.checked);
          }}
        />
      }
      label={'Clone with images?'}
    />
  );
}