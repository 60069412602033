import { uploadNeighborhoodWKT } from 'api/neighborhoodApi';
import { useLoading } from 'components/Layout/Loading';
import useToast from 'components/toast/useToast';
import createStore from 'hooks/hookStore';
import { useEffect, useState } from 'react';

type NeighborhoodUpload = {
  onDropFile:Function;
  acceptedFiles:any;
}
const loadingKey = 'neighborhoodUpload';

const { get, update, registerListener, unregisterListener } = createStore<NeighborhoodUpload>('neighborhoodUpload', {
  onDropFile: () => {},
  acceptedFiles: [],
});

export default function useNeighborhoodUpload() {
  const setState = useState(get())[1];
  const { onLoading, doneLoading } = useLoading(loadingKey);
  const { createInfoToast, createSuccessToast, createErrorToast } = useToast();

  useEffect(() => {
    registerListener(setState);
    return () => {
      unregisterListener(setState);
    };
  }, []);

  async function init() {
    onLoading();
    update({
      ...get(),
    });
    doneLoading(300);
  }

  async function onUpload(onConfirmArgs) {
    await uploadNeighborhoodWKT(onConfirmArgs.data);
    createSuccessToast('Request received.');
  }

  async function onDropFile(acceptedFiles, ctx) {
    try {
      ctx.setValue('neighborhoodTemplate', acceptedFiles[0]);
      ctx.setValue('fileName', acceptedFiles[0].name);
      update({
        ...get(),
        acceptedFiles,
      });
    } catch (e:any) {

      // setErrorMessage(e.response.data);
    }

  }


  return {
    loadingKey,
    ...get(),
    init,
    onDropFile,
    onUpload,
  };
}