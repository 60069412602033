import { getServiceTypesForDropdown } from 'util/serviceTypeHelper';
import { FormControl, InputLabel, FormHelperText } from '@mui/material';
import { HookSelect } from 'components/reactHookForm';
import useErrorState from 'hooks/reactHookForm/useErrorState';
import { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import useServiceOfferingDetails from './hookStore/useServiceOfferingDetails';

export default function ServiceTypeSelect() {


  const formCtx = useFormContext();
  const {
    onServiceTypeChanged,
  } = useServiceOfferingDetails();
  const [serviceTypes, setServiceTypes] = useState<any[]>([]);
  useEffect(() => {
    void getServiceTypesForDropdown(setServiceTypes);
  }, []);
  const { isError, getErrorMessage } = useErrorState(formCtx);
  return (
    <FormControl variant='standard' fullWidth error={isError('serviceTypeId')}>
      <InputLabel htmlFor="service-type">Service Type</InputLabel>
      <HookSelect
        control={formCtx.control}
        rules={{
          required: { value: true, message: 'Required' },
        }}
        postOnChange={(event) => onServiceTypeChanged(event, formCtx)}
        name='serviceTypeId'
        id='service-type'
        menuItems={serviceTypes}
      />
      <FormHelperText>{getErrorMessage('serviceTypeId')}</FormHelperText>
    </FormControl>
  );
}