import { Card, CardContent, FormControl, FormHelperText, Grid, IconButton, InputLabel, TextField } from '@mui/material';
import { RemoveButton } from 'components/buttons';
import AddButton from 'components/buttons/AddButton';
import Spacer from 'components/Layout/Spacer';
import { HookSelect, HookTextField } from 'components/reactHookForm';
import useErrorState from 'hooks/reactHookForm/useErrorState';
import useServiceOfferingForm from 'hooks/serviceOffering/useServiceOfferingForm';
import { useEffect, useState } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import SwapHorizOutlinedIcon from '@mui/icons-material/SwapHorizOutlined';
import { AddListItemButton, ServiceOfferingCardContainer, StepHeader } from './common';
import styles from './common.module.css';

export interface ISortChangeProps {
  items:any[];
  index:number;
  move:any;
  onMove:any;
  setMoved:any;
  moved:boolean;
}

function SortChange({ items, index, move, onMove, setMoved, moved }:ISortChangeProps) {
  const [sortOrder, setSortOrder] = useState(index);

  useEffect(() => {
    if (moved) {
      setSortOrder(index);
      setMoved(false);
    }
  }, [moved]);

  function onSortOrderChange(e:any) {
    setSortOrder(e.target.value);
  }
  return (
    <Grid item xs='auto'>
      <TextField
        variant='standard'
        size='small'
        label='sort order'
        value={sortOrder}
        helperText=' '
        onChange={onSortOrderChange}
        sx={{
          'width': '80px',
          '& .MuiInputBase-root': {
            height: '32px',
          },
        }}
      />
      <IconButton
        type='button'
        onClick={() => {
          if (sortOrder >= 0 && sortOrder < items.length) {
            move(index, sortOrder);
            onMove();
          }
        }}>
        <SwapHorizOutlinedIcon/>
      </IconButton>
    </Grid>
  );
}


function ServiceDetailCommonItems({ items, labelSuffix, namePrefix, onClickAdd, onClickDelete, move }:any) {
  const [moved, setMoved] = useState(false);

  function onMove() {
    setMoved(true);
  }
  return (
    <Grid container item xs={12} spacing={1} alignItems='center' className={styles.serviceDetailsSectionElement}>
      <Grid container item xs={12}>
        <AddListItemButton
          label={`Add ${labelSuffix}`}
          onClick={onClickAdd}
        />
      </Grid>
      {
        items.map((field:any, index:number)=> {
          return (
            <Grid container item xs={12} key={field.id} spacing={1}>
              <Grid item xs>
                <HookTextField
                  name={`${namePrefix}.${index}.val`}
                  label={labelSuffix}
                  required
                  multiline
                  onKeyDown={(event)=>{
                    if (event.key== 'Enter') {
                      event.preventDefault();
                      event.stopPropagation();
                    }
                  }}
                  onKeyUp={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    if (event.key== 'Enter') {onClickAdd(field, index);}
                  }}
                />
              </Grid>
              <SortChange
                items={items}
                onMove={onMove}
                setMoved={setMoved}
                moved={moved}
                move={move}
                index={index}
              />
              <Grid item xs='auto'>
                <RemoveButton
                  tooltipTitle={`Delete this ${labelSuffix}`}
                  onClick={()=> {
                    onClickDelete(index);
                    onMove();
                  }}
                />
              </Grid>
            </Grid>
          );
        })
      }
    </Grid>
  );
}

function ServiceDetailItems({ serviceDetailSection }:any) {
  const { control } = useFormContext();
  const { fields: sectionItems, append, move, remove } = useFieldArray({
    control,
    name: `${serviceDetailSection}.sectionItems`,
  });

  return (
    <ServiceDetailCommonItems
      items={sectionItems}
      labelSuffix='item'
      namePrefix={`${serviceDetailSection}.sectionItems`}
      onClickAdd={(field:any, index:number) => {
        if (index >= 0) {
          append({ val: '' }, { shouldFocus: true, focusIndex: (index || 0)+1 });
        } else {
          append({ val: '' }, { shouldFocus: true });
        }
      }}
      onClickDelete={(index:number) => remove(index)}
      move={move}
    />
  );
}

function ServiceDetailListItems({ serviceDetailSection }:any) {
  const { control, watch } = useFormContext();

  const { fields: sectionListItems, append, move, remove } = useFieldArray({
    control,
    name: `${serviceDetailSection}.sectionListItems`,
  });

  return (
    <ServiceDetailCommonItems
      items={sectionListItems}
      labelSuffix='list item'
      namePrefix={`${serviceDetailSection}.sectionListItems`}
      onClickAdd={(field:any, index:number) => {
        if (index >= 0) {
          append({ val: '' }, { shouldFocus: true, focusIndex: (index || 0)+1 });
        } else {
          append({ val: '' }, { shouldFocus: true });
        }
      }}
      onClickDelete={(index:number) => remove(index)}
      move={move}
    />
  );
}

export default function ServiceDetailSections() {
  const formCtx = useFormContext();
  const { control } = formCtx;
  const { createEmptyServiceDetailSection } = useServiceOfferingForm(null);
  const { fields: serviceDetailSections, replace, remove, move, append } = useFieldArray({
    control,
    name: 'serviceDetailSections' as const,
  });
  const watchServiceDetailSections = useWatch({ control, name: 'serviceDetailSections' });
  useEffect(() => {
    if (serviceDetailSections.length !== watchServiceDetailSections.length) {
      replace(watchServiceDetailSections);
    }
  }, [watchServiceDetailSections]);

  const { isError, getErrorMessage } = useErrorState(formCtx);
  const sectionIconOptions = [
    { id: 'listIcon', name: 'List' },
    { id: 'calendarIcon', name: 'Calendar' },
    { id: 'receiptIcon', name: 'Receipt' },
  ];

  return (
    <Grid container item spacing={1} alignItems='center'>
      <Grid item xs='auto'>
        <StepHeader>Add Service Detail Section</StepHeader>
      </Grid>
      <Grid item xs='auto'>
        <AddButton
          tooltipTitle="Add a service detail section"
          onClick={() => append(createEmptyServiceDetailSection())}
        />
      </Grid>
      <Spacer/>
      <ServiceOfferingCardContainer
        items={serviceDetailSections}
        render={(index:number, onDelete:any, sortOrder:number, onSortOrderChange:any, onMove:any) => (
          <Grid container spacing={1} sx={{}}>
            <Grid container item xs={12} alignItems='center'>
              <Grid item xs>
                <HookTextField
                  name={`serviceDetailSections.${index}.sectionHeaderName`}
                  label='Header name'
                  required
                />
              </Grid>
              <Grid item xs='auto'>
                <RemoveButton
                  tooltipTitle="Delete this section"
                  onClick={() => onDelete(() => remove(index))}
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} alignItems='center'>
              <Grid item xs>
                <FormControl variant='standard' fullWidth error={isError(`serviceDetailSections.${index}.sectionIcon`)}>
                  <InputLabel htmlFor={`serviceDetailSections.${index}.sectionIcon`}>Section Icon</InputLabel>
                  <HookSelect
                    control={formCtx.control}
                    name={`serviceDetailSections.${index}.sectionIcon`}
                    id={`serviceDetailSections.${index}.sectionIcon`}
                    menuItems={sectionIconOptions}
                  />
                  <FormHelperText>{getErrorMessage(`serviceDetailSections.${index}.sectionIcon`)}</FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <ServiceDetailItems
                serviceDetailSection={`serviceDetailSections.${index}`}
              />
            </Grid>
            <Grid item xs={12}>
              <ServiceDetailListItems
                serviceDetailSection={`serviceDetailSections.${index}`}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant='standard'
                size='small'
                label='sort order'
                value={sortOrder}
                onChange={onSortOrderChange}
                sx={{
                  width: '80px',
                }}
              />
              <IconButton
                type='button'
                onClick={() => {
                  if (sortOrder >= 0 && sortOrder < serviceDetailSections.length) {
                    move(index, sortOrder);
                    onMove();
                  }
                }}>
                <SwapHorizOutlinedIcon/>
              </IconButton>
            </Grid>
          </Grid>
        )}/>
    </Grid>
  );
}