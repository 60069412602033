
import { FormControl, FormHelperText } from '@mui/material';
import ServiceProviderAutocomplete from 'components/serviceProviderAutocomplete/ServiceProviderAutocomplete';
import useErrorState from 'hooks/reactHookForm/useErrorState';
import { useFormContext } from 'react-hook-form';


export interface IServiceProviderAutocompleteProps {
  validate?:Function;
}

export default function AuditCustomerUploadServiceProviderAutocomplete({ validate }:IServiceProviderAutocompleteProps) {
  const formCtx = useFormContext();
  const { formState, setError, clearErrors } = formCtx;
  const { isError, getErrorMessage } = useErrorState(formCtx);
  let rules:any = {
    required: {
      value: false, message: 'Required',
    },
  };
  if (validate) {

    rules = {
      validate,
    };
  }
  return (
    <FormControl variant='standard' fullWidth error={isError('selectedServiceProvider')}>
      <ServiceProviderAutocomplete
        label='Service Provider'
        fieldName='selectedServiceProvider'
        placeholder='Search and check a neighborhood'
        formless={false}
        rules={rules}
        required={false}
      />
    </FormControl>
  );
}