import Axios, { AxiosResponse } from 'axios';
import { ITerritoryUploadForm } from 'model/neighborhoodUpload';
import { ITerritory, ITerritoryForm } from 'model/territories';

const apiUrl = window.REACT_APP_BASE_API_URI;

export function findTerritoriesByMarket(marketId:string):Promise<AxiosResponse<ITerritory[]>> {
  return Axios.get(`${apiUrl}/territories/v2/markets/${marketId}/territories`);
}

export function findTerritoryById(marketId:string, id:string):Promise<AxiosResponse<ITerritory>> {
  return Axios.get(`${apiUrl}/territories/v2/markets/${marketId}/territories/${id}`);
}

export function findTerritoryByLegacyId(marketId:string, zipCodeTerritory:string) {
  return Axios.post(`${apiUrl}/territories/v2/markets/${marketId}/findTerritoryByLegacyId`, {
    zipCodeTerritory,
  });
}

export function saveTerritory(marketId:string, id:string | null, data: ITerritoryForm):Promise<AxiosResponse<ITerritory>> {
  if (id) {
    return Axios.put(`${apiUrl}/territories/v2/markets/${marketId}/territories/${id}/updateTerritory`, data);
  } else {
    return Axios.post(`${apiUrl}/territories/v2/markets/${marketId}/createTerritory`, data);
  }
}


export function uploadTerritoryWKT(data:ITerritoryUploadForm) {
  var formData = new FormData();
  formData.append('territoryTemplate', data.territoryTemplate);
  return Axios.post(`${apiUrl}/territories/v2/uploadTerritoryWKT`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function initiateSkipTraceForTerritory(territoryId:string) {
  return Axios.get(`${apiUrl}/territories/v2/skipTraceAddressesInTerritory/${territoryId}`);
}