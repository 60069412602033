
export interface IToggleNsoDTO {
  shouldEnable:boolean;
}

export interface IToggleIgnoreNsoForReconciliationDTO {
  shouldIgnore:boolean;
}

export interface INeighborhoodServiceOfferingForm {
  id?:string;
  name: string;
  altName?:string;
  neighborhoodIds: string[];
  neighborhoodId:string; //need additional to support edit mode where multiselect is disabled
  providerId: string;
  serviceOfferingId: string;
  discountScheduleId: string;
  price?:number;
  isEnabled:boolean;
  disabledDate:Date | null;
}

export interface INeighborhoodServiceOffering {
  name: string;
  altName?:string;
  neighborhoodId: string;
  providerId: string;
  serviceOfferingId: string;
  discountScheduleId: string;
  price?:number;
  disabledDate:Date | null;
}

export function createEmptyNeighborhoodServiceOfferingForm():INeighborhoodServiceOfferingForm {
  return {
    id: '',
    altName: '',
    name: '',
    neighborhoodIds: [],
    neighborhoodId: '',
    providerId: '',
    serviceOfferingId: '',
    discountScheduleId: '',
    price: undefined,
    isEnabled: true,
    disabledDate: null,
  };
}

export function createEmptyNeighborhoodServiceOffering() {
  return {
    altName: '',
    name: '',
    neighborhoodId: '',
    providerId: '',
    serviceOfferingId: '',
    discountScheduleId: '',
    price: null,
  };
}
