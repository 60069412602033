import { Box, Grid, Tabs, Tab } from '@mui/material';
import Loading from 'components/Layout/Loading';
import PageHeader from 'components/SectionHeaders/PageHeader';
import { useState } from 'react';
import CreateRewardCodesForm from './CreateRewardCodesForm';
import useRewardCodeExport from './hookStore/useRewardCodeExport';
import useRewardCodeManagement from './hookStore/useRewardCodeManagement';
import RewardCodeExport from './RewardCodeExport';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    'id': `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function NeighborhoodLaunchTooling() {
  const { loadingKey: rewardCodeExportKey } = useRewardCodeExport();
  const { loadingKey: rewardCodeKey } = useRewardCodeManagement();

  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <Grid container spacing={2} className='pageGridContainer'>
      <Grid item xs={12}>
        <PageHeader headerText={'Rewards'} />
      </Grid>
      <Grid item xs={12}>
        <Tabs value={value} onChange={handleChange} aria-label='tabs'>
          <Tab label="View/Export Reward Codes" {...a11yProps(0)}/>
          <Tab label="Create Reward Codes" {...a11yProps(1)}/>
        </Tabs>
        <TabPanel value={value} index={0}>
          <Loading loadingKey={rewardCodeExportKey} size={100}>
            <RewardCodeExport/>
          </Loading>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Loading loadingKey={rewardCodeKey} size={100}>
            <CreateRewardCodesForm/>
          </Loading>
        </TabPanel>
      </Grid>
    </Grid>
  );
}
