import { INeighborhoodServiceOffering } from 'model/neighborhoodServiceOffering';
import { useFormContext } from 'react-hook-form';
import DollarPerCustomerFields from './DollarPerCustomerFields';
import DollarSavedPerCustomerFields from './DollarSavedPerCustomerFields';
import { DiscountScheduleTypes } from 'model/discountSchedule';
import DollarFlatRateFields from './DollarFlatRateFields';
import PercentFlatRateFields from './PercentFlatRateFields';


function determineExtraFieldVisibility(selectedDiscountScheduleType?:string) {
  const extraFieldVisibility = {
    shouldShowDollarPerCustomerFields: false,
    shouldShowDollarSavedPerCustomerFields: false,
    shouldShowDollarFlatRateFields: false,
    shouldShowPercentFlatRateFields: false,
  };

  switch (selectedDiscountScheduleType) {
    case DiscountScheduleTypes.DollarPerCustomer:
      extraFieldVisibility.shouldShowDollarPerCustomerFields = true;
      break;
    case DiscountScheduleTypes.DollarSavePerCustomer:
    case DiscountScheduleTypes.PercentSavePerCustomer:
      extraFieldVisibility.shouldShowDollarSavedPerCustomerFields = true;
      break;
    case DiscountScheduleTypes.DollarFlatRate:
      extraFieldVisibility.shouldShowDollarFlatRateFields = true;
      break;
    case DiscountScheduleTypes.PercentFlatRate:
      extraFieldVisibility.shouldShowPercentFlatRateFields = true;
      break;
    default:
      break;
  }
  return extraFieldVisibility;
}

export interface IStepFourProps {
  neighborhoodServiceOfferings:INeighborhoodServiceOffering[];
}

export default function StepFour({ neighborhoodServiceOfferings }:IStepFourProps) {
  const methods = useFormContext();
  const watchType = methods.watch('type');
  const {
    shouldShowDollarPerCustomerFields,
    shouldShowDollarSavedPerCustomerFields,
    shouldShowDollarFlatRateFields,
    shouldShowPercentFlatRateFields,
  } = determineExtraFieldVisibility(watchType);

  if (shouldShowDollarSavedPerCustomerFields) {
    return (
      <DollarSavedPerCustomerFields neighborhoodServiceOfferings={neighborhoodServiceOfferings}/>
    );
  }
  if (shouldShowDollarPerCustomerFields) {
    return (
      <DollarPerCustomerFields neighborhoodServiceOfferings={neighborhoodServiceOfferings}/>
    );
  }
  if (shouldShowDollarFlatRateFields) {
    return (
      <DollarFlatRateFields/>
    );
  }
  if (shouldShowPercentFlatRateFields) {
    return (
      <PercentFlatRateFields/>
    );
  }
  return null;
}