import Axios, { AxiosResponse } from 'axios';
import { IRebookServicesNSOIFilter } from 'model/rebookServices';
import { INeighborhoodServiceOfferingInstance, IServiceInstanceForm } from 'model/serviceInstance';

const apiUrl = window.REACT_APP_BASE_API_URI;
export function findByNeighborHoodServiceOffering(neighborhoodServiceOfferingId:string) {
  return Axios.get(`${apiUrl}/serviceInstance/v2/findByNeighborhoodServiceOffering/${neighborhoodServiceOfferingId}`);
}

export function findNeighborhoodServiceOfferingInstanceById(neighborhoodServiceOfferingId:string) {
  return Axios.get(`${apiUrl}/serviceInstance/v2/findByNeighborhoodServiceOffering/${neighborhoodServiceOfferingId}`);
}

export function findHappeningSoonByServiceOfferingAndTerritory(serviceProviderId:string, serviceOfferingId:string, data:IRebookServicesNSOIFilter)
  :Promise<AxiosResponse<INeighborhoodServiceOfferingInstance[]>> {
  return Axios.post(`${apiUrl}/serviceInstance/v3/serviceProviders/${serviceProviderId}/serviceOfferings/${serviceOfferingId}/findHappeningSoonByServiceOfferingAndTerritory`, data);
}

export function saveServiceInstance(data:IServiceInstanceForm) {
  if (data.id) {
    return Axios.put(`${apiUrl}/serviceInstance/v2/update/${data.id}`, data);
  }
  return Axios.post(`${apiUrl}/serviceInstance/v2/create`, data);
}

export function deleteServiceInstance(id:string) {
  return Axios.delete(`${apiUrl}/serviceInstance/v2/delete/${id}`);
}

export function findServiceInstanceById(id:string) {
  return Axios.get(`${apiUrl}/serviceInstance/v2/find/${id}`);
}

export function migrateCutoffDayCount() {
  return Axios.get(`${apiUrl}/serviceInstance/v2/migrateCutoffDayCount`);
}

export function migrateNeighborhoodNameAndServiceTypeAndServiceProviderId() {
  return Axios.get(`${apiUrl}/serviceInstance/v2/migrateNeighborhoodNameAndServiceTypeAndServiceProviderId`);
}

export function migrateServiceOfferingToNSOI() {
  return Axios.get(`${apiUrl}/serviceInstance/v2/migrateServiceOfferingToNSOI`);
}