import { generateGuid } from 'util/guidUtil';
import { IDropdownOption } from './dropdown';


export interface IMapBounds {
  sw: ILatLng;
  ne: ILatLng;
}

export interface ILatLng {
  lat:number;
  lng:number;
}

export interface IMarketCoverageAreaMap {
  zoom: number;
  center:ILatLng;
  bounds: IMapBounds;
}

export interface IGeoCoordinate {
  latitude:number;
  longitude:number;
}

export function createFromLatLng(data:ILatLng):IGeoCoordinate {
  return {
    latitude: data.lat,
    longitude: data.lng,
  };
}

export interface IGeoJSONCoordinates {
  type:string;
  coordinates:IGeoCoordinate[];
}

export interface IGeoJSONNeighborhood{
  neighborhoodId:string;
  name:string;
  geoJson:IGeoJSONCoordinates;
}

export interface IProviderCoverageAreaForm {
  serviceProviderId:IDropdownOption | null;
  providerCoverageAreas: ICoverageArea[];
}

export interface ICoverageArea {
  id:string;
  bounds: IMapBounds;
  type: string;
  coordinates: IGeoCoordinate[];
  center:{lat:number; lng:number};
  name:string;
  excludedNeighborhoodIds:string[];
  excludedNeighborhoodOptions:IDropdownOption[];
  new:boolean;
}

const bounds = (marketCoverageAreaMap:IMarketCoverageAreaMap) => {
  return marketCoverageAreaMap.bounds ?? {
    sw: { lat: 35.0508960017053, lng: -81.01728906087853 },
    ne: { lat: 35.37758731279673, lng: -80.67902298086572 },
  };
};

//centered on charlotte by default
const center = (marketCoverageAreaMap:IMarketCoverageAreaMap):ILatLng => {
  return marketCoverageAreaMap.center ?? {
    lat: 35.226548732911816,
    lng: -80.84466557461568,
  };
};

export function createEmptyCoverageArea(marketCoverageAreaMap:IMarketCoverageAreaMap):ICoverageArea {
  return {
    id: generateGuid(),
    type: '',
    coordinates: [],
    center: center(marketCoverageAreaMap),
    bounds: bounds(marketCoverageAreaMap),
    name: '',
    excludedNeighborhoodIds: [],
    excludedNeighborhoodOptions: [],
    new: true,
  };
}

export const charlotteMarketCoverageArea:IMarketCoverageAreaMap = {
  center: {
    lat: 35.226548732911816,
    lng: -80.84466557461568,
  },
  bounds: {
    sw: { lat: 35.0508960017053, lng: -81.01728906087853 },
    ne: { lat: 35.37758731279673, lng: -80.67902298086572 },
  },
  zoom: 2,
};

export const dallasMarketCoverageArea:IMarketCoverageAreaMap = {
  center: {
    lat: 32.7766642,
    lng: -96.79698789999998,
  },
  bounds: {
    sw: { lat: 32.5566642, lng: -97.01698789999998 },
    ne: { lat: 32.9966642, lng: -96.57698789999998 },
  },
  zoom: 2,
};

export function createEmptyProviderCoverageAreaForm():IProviderCoverageAreaForm {
  return {
    serviceProviderId: null,
    providerCoverageAreas: [],
  };
}