import { createUninitializedDropdownOption, IDropdownOption } from 'model/dropdown';
import { IMarket } from 'model/markets';

export namespace RemoveProviderFromTemplateModel {

  export interface IRemoveRequestForm {
    serviceProvider:IDropdownOption | null;
    serviceOfferingDtos:any[];
  }

  export interface IRemoveProviderFromSeedingTemplatesRequest {
    marketId:string;
    serviceProviderId:string;
    serviceOfferingIds:string[];
  }

  export function createEmptyForm():IRemoveRequestForm {
    return {
      serviceProvider: createUninitializedDropdownOption(),
      serviceOfferingDtos: [],
    };
  }

  export function convertFormToDto(data:IRemoveRequestForm, market:IMarket):IRemoveProviderFromSeedingTemplatesRequest {
    return {
      marketId: market.id,
      serviceProviderId: data?.serviceProvider?.optionValue || '',
      serviceOfferingIds: data.serviceOfferingDtos
        .filter((serviceOffering) => serviceOffering.checked)
        .map((serviceOffering) => serviceOffering.id),
    };
  }
}