import { FormControl, InputLabel, FormHelperText } from '@mui/material';
import { HookSelect } from 'components/reactHookForm';
import useErrorState from 'hooks/reactHookForm/useErrorState';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import useNeighborhoodDetail from '../hookStore/useNeighborhoodDetail';


export default function MarketSelect() {
  const { markets, onSelectMarket } = useNeighborhoodDetail();
  const formCtx = useFormContext();

  const { isError, getErrorMessage } = useErrorState(formCtx);
  return (
    <FormControl variant='standard' fullWidth error={isError('marketId')}>
      <InputLabel htmlFor="market-select">Market</InputLabel>
      <HookSelect
        control={formCtx.control}
        rules={{
          required: { value: true, message: 'Required' },
        }}
        name='marketId'
        id='market-select'
        menuItems={markets}
        menuItemKeys={{
          key: 'id',
          value: 'optionValue',
          displayValue: 'optionText',
        }}
        postOnChange={ async (value: any) => {

          await onSelectMarket(formCtx.getValues('marketId'));
          formCtx.setValue('zipCodeTerritory', 'UNASSIGNED');
        }}
      />
      <FormHelperText>{getErrorMessage('marketId')}</FormHelperText>
    </FormControl>
  );
}