import EditIcon from '@mui/icons-material/Edit';
import { IconButton, IconButtonProps, Tooltip, TooltipProps } from '@mui/material';

interface IEditButtonProps {
  tooltipTitle:string;
  onClick:any;
  className?:string;
  disabled?:boolean;
  iconButtonProps?:any;
  tooltipProps?:any;
}
export default function EditButton({ tooltipTitle, onClick, className, disabled, tooltipProps, iconButtonProps }:IEditButtonProps) {
  return (
    <Tooltip title={tooltipTitle} className={className} {...tooltipProps}>
      <IconButton onClick={onClick} disabled={disabled} {...iconButtonProps}>
        <EditIcon/>
      </IconButton>
    </Tooltip>
  );
}