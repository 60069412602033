import { useLoading } from 'components/Layout/Loading';
import createStore from 'hooks/hookStore';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ITerritory } from 'model/territories';
import { findTerritoriesByMarket } from 'api/territoriesApi';
import useMarkets from 'hooks/useMarkets';
import { IMarket } from 'model/markets';


const loadingKey = 'TerritoriesStore';
type TerritoriesStore = {
  territories: ITerritory[];
}

const { get, update, registerListener, unregisterListener } = createStore<TerritoriesStore>('TerritoriesStore', {
  territories: [],
});


export default function useTerritories() {
  const setState = useState(get())[1];
  const { onLoading, doneLoading } = useLoading(loadingKey);


  useEffect(() => {
    registerListener(setState);
    return () => {
      unregisterListener(setState);
    };
  }, []);

  async function init(market:IMarket | null, setRowsWithStoredFilter: any) {

    onLoading();
    try {
      if (market) {
        try {
          const res = await findTerritoriesByMarket(market.id);
          const territories = res.data;
          setRowsWithStoredFilter(territories);
          update({
            ...get(),
            territories,
          });
        } catch (err:any) {
          console.error(err);
        }
      }
    } catch (e:any) {
      console.error(e);
    }
    doneLoading(300);
  }

  async function onConfirmDelete(territoryId: string) {
    //TODO: implement delete if necessary
  }


  return {
    ...get(),
    loadingKey,
    init,
    onConfirmDelete,
  };
}