import createStore from 'hooks/hookStore';
import useToast from 'components/toast/useToast';
import { useLoading } from 'components/Layout/Loading';
import { useEffect, useState } from 'react';
import { sortByString } from 'util/sortFunctions';
import { ITsoiManagementBatch } from 'model/tsoiManagementBatch';
import { ITerritory } from 'model/territories';
import { getTsoiManagementBatchById } from 'api/tsoiManagementApi';
import { findTerritoriesByMarket } from 'api/territoriesApi';

const loadingKey = 'TsoiManagementBatchStore';
type TsoiManagementBatchStore = {
  batch: ITsoiManagementBatch;
  availableTerritories: ITerritory[];
}

const { get, update, registerListener, unregisterListener } = createStore<TsoiManagementBatchStore>('TsoiManagementBatchStore', {
  batch: {} as ITsoiManagementBatch,
  availableTerritories: [],
});

export default function useTsoiManagementBatch() {
  const setState = useState(get())[1];
  const { onLoading, doneLoading } = useLoading(loadingKey);
  const { createErrorToast } = useToast();

  useEffect(() => {
    registerListener(setState);
    return () => {
      unregisterListener(setState);
    };
  }, []);


  async function init(batchId:string) {
    if (batchId == null) {
      console.log('No batch id provided');
      return;
    }
    onLoading();
    try {
      const batchResp = await getTsoiManagementBatchById(batchId);

      if (!batchResp.data.marketId) {
        createErrorToast('Error with batch: no market ID present. This is likely a defect, please consult the dev team.');
        return;
      }

      const availableTerritoriesResp = await findTerritoriesByMarket(batchResp.data.marketId);

      update({
        ...get(),
        batch: batchResp.data,
        availableTerritories: availableTerritoriesResp.data,
      });
    } catch (e:any) {
      createErrorToast('Error loading batch');
      console.error(e);
    }
    doneLoading(300);
  }

  return {
    ...get(),
    loadingKey,
    init,
  };
}