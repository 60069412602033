import Userfront from '@userfront/react';
import { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import LogoHeader from '../components/Header/logoHeader';
function Layout () {
  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    if (!Userfront.tokens.accessToken) {
      navigate('/sign-in');
      return;
    }
  }, []);
  return (
    <div>
      <LogoHeader />
      <main>
        <Outlet />
      </main>
    </div>
  );
}

export default Layout;