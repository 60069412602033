import { Button, Grid } from '@mui/material';
import Spacer from 'components/Layout/Spacer';
import { ConfirmationModalContext } from 'components/modals/ConfirmationModal';
import PageHeader from 'components/SectionHeaders/PageHeader';
import { IDropdownOption } from 'model/dropdown';
import { useContext, useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import NSOReconcileNeighborhoodSelect from './formComponents/NSOReconcileNeighborhoodSelect';
import useReconcileNeighborhood from './hookStore/useReconcileNeighborhood';
import styles from './neighborhoodLaunchTooling.module.css';
import { formatMonthDayTime } from 'util/dateUtil';

export interface INeighborhoodReconciliationForm {
  neighborhoodId:IDropdownOption | null;
  neighborhoodName:string;
  dryRun: boolean;
}

export function createEmptyNeighborhoodReconciliationForm() {
  return {
    neighborhoodId: null,
    neighborhoodName: '',
    dryRun: true,
  };
}

export interface IReconcileNsosByNeighborhoodFormProps {
  initialData: INeighborhoodReconciliationForm;
}
function ReconcileNsosByNeighborhoodFormInternal({ initialData }:IReconcileNsosByNeighborhoodFormProps) {
  const formCtx = useForm<INeighborhoodReconciliationForm>({ mode: 'onChange', defaultValues: createEmptyNeighborhoodReconciliationForm() });
  const { lastReconciled } = useReconcileNeighborhood();
  useEffect(() => {
    formCtx.reset(initialData);
  }, [initialData]);

  const confirmationModalCtx = useContext(ConfirmationModalContext);

  const onSubmit : SubmitHandler<INeighborhoodReconciliationForm> = data => {

    if (data.neighborhoodId) {

      confirmationModalCtx.openModal({ data });
    }
  };
  var neighborhoodId = formCtx.getValues('neighborhoodId');
  var formattedLastReconciled = formatMonthDayTime(lastReconciled);
  return (
    <FormProvider {...formCtx}>
      <form onSubmit={formCtx.handleSubmit(onSubmit)}>
        <Grid container item xs={12} spacing={1} className='pageGridContainer'>
          <Grid container item xs={12}>
            <PageHeader headerText={'NSO Reconciliation'}/>
            <h4>This will use current provider coverage areas and the current geometry (from the DB) to create/soft delete NSOs.</h4>
          </Grid>
          <Spacer/>
          <Grid item xs={4}>
            <NSOReconcileNeighborhoodSelect/>
          </Grid>
          <Grid item xs={12}></Grid>
          <Grid item xs={12}>
            Last Reconciled: { formattedLastReconciled ? formattedLastReconciled : 'N/A'}
          </Grid>
          <Grid item xs={'auto'}>
            <Button
              variant="contained"
              className={styles.saveButton}
              type="button"
              disabled={neighborhoodId == null}
              onClick={() => {
                const _neighborhoodId = formCtx.getValues('neighborhoodId');
                const neighborhoodName = formCtx.getValues('neighborhoodName');
                confirmationModalCtx.openModal({ neighborhoodId: _neighborhoodId, neighborhoodName, dryRun: true });
              }}
            >
              <span>Reconcile NSOs (Dry Run)</span>
            </Button>

          </Grid>
          <Grid item xs='auto'>
            <Button
              variant="contained"
              className={styles.saveButton}
              type="button"
              disabled={neighborhoodId == null}
              onClick={() => {
                var _neighborhoodId = formCtx.getValues('neighborhoodId');
                var neighborhoodName = formCtx.getValues('neighborhoodName');
                confirmationModalCtx.openModal({ neighborhoodId: _neighborhoodId, neighborhoodName, dryRun: false });
              }}
            >
              <span>Reconcile NSOs</span>
            </Button>
          </Grid>
          <Spacer/>
        </Grid>
      </form>
    </FormProvider>
  );
}

export default function ReconcileNsosByNeighborhoodForm() {
  const { formData, init } = useReconcileNeighborhood();
  useEffect(() => {
    void init();
  }, []);
  return (
    <ReconcileNsosByNeighborhoodFormInternal initialData={formData}/>
  );
}