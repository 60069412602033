import { Grid, Alert } from '@mui/material';
import { saveServiceProviderImage } from 'api/serviceProviderApi';
import { ServiceProviderFormContext } from 'contexts/serviceProvider/useServiceProviderForm';
import { IServiceProvider } from 'model/serviceProvider';
import { useContext, useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useFormContext } from 'react-hook-form';
import styles from '../providerOnboarding.module.css';
import Loading, { useLoading } from 'components/Layout/Loading';
import { useParams } from 'react-router-dom';

const loadingKey = 'ProviderOnboardingCompanyImageDropzone';

export default function CompanyImageDropzone({ inputBoxColumnSize = 3 }) {
  const [errorMessage, setErrorMessage] = useState('');
  const { onLoading, doneLoading } = useLoading(loadingKey);
  const formContext = useFormContext();
  const { serviceProviderId } = useParams();

  const onDrop = useCallback(async acceptedFiles => {
    var formData = new FormData();

    if (acceptedFiles.length === 0) return;
    if (acceptedFiles.filter(x => x.type === 'image/webp').length > 0) {
      setErrorMessage('WebP format is not supported. Please upload a PNG, JPEG, or SVG file.');
      return;
    } else {
      setErrorMessage('');
    }

    formData.append('image', acceptedFiles[0]);
    if (formContext.getValues().imageKey) {
      formData.set('imageKey', formContext.getValues().imageKey);
    }
    if (serviceProviderId) {
      formData.set('serviceProviderId', serviceProviderId);
    }

    try {
      onLoading();
      const response = await saveServiceProviderImage(formData);
      const imageUrlGuid = response.data;
      formContext.setValue('imageUrlGuid', imageUrlGuid);
      doneLoading();
    } catch (e:any) {
      doneLoading();
      setErrorMessage(e.message);
      console.log('error uploading image', { error: e });
    }

  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <Grid container item xs={inputBoxColumnSize} justifyContent='center'>
      <Grid item xs>
        <div {...getRootProps()} className={styles.dropzoneWrapper}>
          <input {...getInputProps()} accept='.png,.jpeg,.svg'/>
          {
            isDragActive ?
              <p>Drop the files here ...</p> :
              <p>Drag and drop a file or click here to select a file</p>
          }
        </div>
      </Grid>
      <Grid item xs={12}>
        {errorMessage && (
          <Alert
            severity='error'
            className={styles.uploadErrorMessage}
            variant='outlined'
          >
            {errorMessage}
          </Alert>
        )}
      </Grid>
      <Loading loadingKey={loadingKey} />
    </Grid>
  );
}