import { Grid, Chip, TextField, IconButton } from '@mui/material';
import { RemoveButton } from 'components/buttons';
import { HookTextField } from 'components/reactHookForm';
import useServiceOfferingForm from 'hooks/serviceOffering/useServiceOfferingForm';
import { useFormContext, useFieldArray } from 'react-hook-form';
import SwapHorizOutlinedIcon from '@mui/icons-material/SwapHorizOutlined';
import { AddListItemButton, ServiceOfferingCardContainer } from '../common';

export default function CustomFieldOptions({ customField }:any) {
  const { control } = useFormContext();
  const { createEmptyCustomFieldOption } = useServiceOfferingForm(null);
  const { fields: customFieldOptions, append, move, remove } = useFieldArray({
    control,
    name: `${customField}.customFieldOptions`,
  });

  return (
    <Grid container item xs={12} spacing={1} alignItems='center'>
      <Grid container item xs={12} alignItems='center'>
        <AddListItemButton
          label='Add  option'
          onClick={() => append(createEmptyCustomFieldOption())}
        />

      </Grid>
      <ServiceOfferingCardContainer
        items={customFieldOptions}
        render={(index:number, onDelete:any, sortOrder:number, onSortOrderChange:any, onMove:any) =>(
          <Grid container item spacing={1} alignItems='center'>
            <Grid item xs='auto' >
              <Chip label={`${index+1}`}/>
            </Grid>
            <Grid item xs={5} md>
              <HookTextField
                name={`${customField}.customFieldOptions.${index}.optionText`}
                label='Option text'
                required
                validationRules={{
                  validate: function(value) {
                    if (!value || !value.trim()) {
                      return 'Must not be blank';
                    }
                  },
                }}
              />
            </Grid>
            <Grid item xs='auto'>
              <RemoveButton
                tooltipTitle="Delete this custom field option"
                onClick={() => {
                  onDelete(() => remove(index));
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant='standard'
                size='small'
                label='sort order'
                value={sortOrder}
                onChange={onSortOrderChange}
                sx={{
                  width: '80px',
                }}
              />
              <IconButton
                type='button'
                onClick={() => {
                  if (sortOrder >= 0 && sortOrder < customFieldOptions.length) {
                    move(index, sortOrder);
                    onMove();
                  }
                }}>
                <SwapHorizOutlinedIcon/>
              </IconButton>
            </Grid>
          </Grid>
        )}
      />
    </Grid>
  );
}