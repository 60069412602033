import CopyButton from 'components/buttons/CopyButton';
import useShortLink from './shortLinkHookStore';

export default function ShortLinkResult() {

  const { shortLinkResponse } = useShortLink();
  return (
    <div>
      <h1>Short Link Result</h1>
      {shortLinkResponse?.shortLink && shortLinkResponse?.converted ? (
        <div>
          <a href={shortLinkResponse.shortLink} target='_blank' rel='noreferrer'>{shortLinkResponse.shortLink}</a>
          <CopyButton
            message={shortLinkResponse.shortLink}
          />
        </div>
      )
        : null}
    </div>
  );
}