import { Autocomplete, TextField } from '@mui/material';
import useMarkets from 'hooks/useMarkets';
import { IDropdownOption } from 'model/dropdown';
import { IMarket } from 'model/markets';


export interface IMarketAutocompleteProps {
  value?:any;
  onChangeOverride?:any;
}

function convertToDropdownOption(selectedMarket:IMarket):IDropdownOption {
  return {
    key: selectedMarket.id,
    optionText: selectedMarket.name,
    optionValue: selectedMarket.id,
    ancillary: selectedMarket,
  };
}

export default function MarketAutocomplete({
  onChangeOverride,
}:IMarketAutocompleteProps) {
  const {
    marketDropdownOptions,
    selectedMarket,
    market,
    onChange,
    preOnChangeOverride,
  } = useMarkets();
  let usedOption:IDropdownOption | null = null;
  if (onChangeOverride) {
    usedOption = market != null ? convertToDropdownOption(market) : null;
  } else {
    usedOption = selectedMarket != null ? convertToDropdownOption(selectedMarket) : null;
  }
  return (
    <Autocomplete
      size='small'

      sx={{
        '&': {
          minWidth: '300px',
          maxWidth: '300px',
          padding: '1px',
        },
      }}
      id={'market-autocomplete'}
      onChange={(e, value:any, reason, details) => {
        if (reason === 'selectOption') {
          if (onChangeOverride) {
            preOnChangeOverride(value);
            onChangeOverride(value);
            return;
          }
          onChange(value);

        } else if (reason === 'createOption') {

        } else if (reason === 'removeOption') {
          if (onChangeOverride) {
            preOnChangeOverride(value);
            onChangeOverride(value);
            return;
          }
          onChange(value);

        } else if (reason === 'blur') {

        } else if (reason === 'clear') {
          if (onChangeOverride) {
            preOnChangeOverride(null);
            onChangeOverride(value);
            return;
          }
          onChange(null);
        }
      }}
      isOptionEqualToValue={(option, value: IDropdownOption) => {
        return option.optionValue === value.optionValue;
      }}
      value={usedOption}
      options={marketDropdownOptions}
      getOptionLabel={(option) => {
        return option?.optionText ?? '';
      }}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          {option.optionText}
        </li>
      )}

      fullWidth
      renderInput={(params) => (
        <TextField
          {...params}
          label={'Market'}
          placeholder={'Select a market'}
          sx={{
            backgroundColor: 'white',
          }}
        />
      )}
    />
  );
}