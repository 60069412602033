import Axios, { AxiosResponse } from 'axios';
import { IServiceTypeAdminDTO, IServiceTypeAdminRequest } from 'model/serviceType';

const apiUrl = window.REACT_APP_BASE_API_URI;

export function findServiceTypeAdminDTO(data: IServiceTypeAdminRequest):Promise<AxiosResponse<IServiceTypeAdminDTO>> {
  return Axios.post(`${apiUrl}/serviceType/v2/findServiceTypeDTO`, data);
}

export function saveServiceTypeAdminDTO(data:IServiceTypeAdminDTO) {
  return Axios.post(`${apiUrl}/serviceType/v2/saveServiceTypeDTO`, data);
}
