import { sortByString } from 'util/sortFunctions';
import { Button, Grid, AutocompleteChangeReason } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { findServiceOfferingsByProvider, deleteServiceOffering } from 'api/serviceOfferingApi';
import { RemoveButton, EditButton, ToggleButton, CloneButton } from 'components/buttons';
import CopyToClipboardCell from 'components/DataGridCell/CopyToClipboard';
import QuickSearchToolbar from 'components/grid/QuickSearchToolbar';
import useTableSearch from 'components/grid/useTableSearch';
import ConfirmationModal, { ConfirmationModalContextProvider, ConfirmationModalContext } from 'components/modals/ConfirmationModal';
import PageHeader from 'components/SectionHeaders/PageHeader';
import useToast from 'components/toast/useToast';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './serviceOfferings.module.css';
import DisableNsoByServiceOfferingModal, { IDisableNsoByServiceOfferingModal, modalStoreKey } from './DisableNsoByServiceOfferingModal';
import useModal from 'components/modal/useModal';
import CloneServiceOfferingModal, { ICloneServiceOfferingModal, modalStoreKey as cloneServiceOfferingKey } from './CloneServiceOfferingModal';
import ServiceProviderAutocomplete from 'components/serviceProviderAutocomplete/ServiceProviderAutocomplete';
import { IDropdownOption } from 'model/dropdown';
//overview
//1. select service provider from dropdown
//2. show grid of service offerings related to service provider
//3. click grid item to edit. click add new to add new discount schedule.

const sortByName = sortByString('name');
const quickSearchStorageKey = 'serviceOfferings';
function DeleteCell({ id: serviceOfferingId, name: serviceOfferingName }) {
  const confirmationModalCtx = useContext(ConfirmationModalContext);
  return (
    <RemoveButton
      tooltipTitle='Remove service offering'
      onClick={() => confirmationModalCtx.openModal({ serviceOfferingId, serviceOfferingName })}
      tooltipProps={{
        placement: 'top',
      }}
      iconButtonProps={{
        className: styles.actionButton,
      }}
    />
  );
}

function CloneCell({ id, name, serviceProviderId }) {
  const { openModal } = useModal(cloneServiceOfferingKey);
  const state:ICloneServiceOfferingModal = {
    serviceProviderId,
    name: name,
    serviceOfferingId: id,
  };
  return (
    <CloneButton
      tooltipTitle={`Clone ${name}`}
      onClick={() => {
        openModal(state);
      }}
      tooltipProps={{
        placement: 'top',
      }}
      iconButtonProps={{
        className: styles.actionButton,
      }}
    />
  );
}

function EditCell({ id, name, serviceProviderId }) {
  const navigate = useNavigate();
  function handleClick() {
    navigate(`/service-providers/${serviceProviderId}/service-offering-details/${id}`);
  }
  return (
    <EditButton
      tooltipTitle={`Edit ${name}`}
      onClick={handleClick}
      tooltipProps={{
        placement: 'top',
      }}
      iconButtonProps={{
        className: styles.actionButton,
      }}
    />
  );
}

function ToggleCell({ id, name, wasEnabled }:any) {
  const { openModal } = useModal(modalStoreKey);
  const state:IDisableNsoByServiceOfferingModal = {
    id,
    name,
    wasEnabled,
  };
  return (
    <ToggleButton
      toggled={wasEnabled}
      tooltipTitle={`Toggle ${name}`}
      onClick={() => {
        openModal(state);
      }}
      tooltipProps={{
        placement: 'top',
      }}
      iconButtonProps={{
        className: styles.actionButton,
      }}
    />
  );
}

export default function ServiceOfferings() {
  const { createErrorToast, createSuccessToast } = useToast();
  const [serviceProviders, setServiceProviders] = useState<any[]>([]);
  const [serviceOfferings, setServiceOfferings] = useState<any[]>([]);
  const { rows, searchText, setRowsWithStoredFilter, requestSearch, clearStoredSearch } = useTableSearch(quickSearchStorageKey);
  const [selectedServiceProvider, setSelectedServiceProvider] = useState<string>('');
  const [sortModel, setSortModel] = useState([
    {
      field: 'name',
      sort: 'asc' as any,
    },
  ]);
  const navigate = useNavigate();

  async function getServiceOfferings(providerId:string) {
    const serviceOfferingsRes = await findServiceOfferingsByProvider(providerId);
    setServiceOfferings(serviceOfferingsRes.data);
    setRowsWithStoredFilter(serviceOfferingsRes.data);
  }

  async function onServiceProviderChanged(formCtx: any, option: IDropdownOption | null, reason: AutocompleteChangeReason, details:any) {
    if (reason === 'clear') {
      setSelectedServiceProvider('');
      localStorage.removeItem('serviceOfferings.selectedServiceProvider');
      setServiceOfferings([]);
      setRowsWithStoredFilter([]);
    } else if (option !== null) {
      setSelectedServiceProvider(option.optionValue);
      localStorage.setItem('serviceOfferings.selectedServiceProvider', option.optionValue);
      await getServiceOfferings(option.optionValue);
    }
  }

  function addNewButtonClick(e: any) {
    navigate(`/service-providers/${selectedServiceProvider}/service-offering-details`);
  }

  async function init() {


    const storedSelectedServiceProvider = localStorage.getItem('serviceOfferings.selectedServiceProvider') || '';
    if (storedSelectedServiceProvider) {
      setSelectedServiceProvider(storedSelectedServiceProvider);
      await getServiceOfferings(storedSelectedServiceProvider);
    } else if (selectedServiceProvider) {
      await getServiceOfferings(selectedServiceProvider);
    }
  }

  useEffect(() => {
    void init();
  }, []);

  async function _deleteServiceOffering({ serviceOfferingId }) {
    try {
      if (serviceOfferingId) {
        await deleteServiceOffering(serviceOfferingId);
        clearStoredSearch();
        await getServiceOfferings(selectedServiceProvider);
      }
    } catch (e:any) {
      console.error(`Unable to delete service offering. Message=${e.response?.data?.message}`);
      createErrorToast(`Unable to delete service offering. Message=${e.response?.data?.message}`);
    }
  }

  function postToggle(serviceOfferingId:string, wasEnableRequest:boolean) {
    const pseudoUpdatedServiceOfferings = serviceOfferings.map(x => {
      if (x.id === serviceOfferingId && wasEnableRequest) {
        x.disabledNeighborhoodServiceOfferingsDate = null;
      } else if (x.id === serviceOfferingId && !wasEnableRequest) {
        x.disabledNeighborhoodServiceOfferingsDate = new Date();
      }
      return x;
    });
    setServiceOfferings(pseudoUpdatedServiceOfferings);
    setRowsWithStoredFilter(pseudoUpdatedServiceOfferings);
    createSuccessToast( 'Success!' );
  }

  function onCloneWithImages(serviceProviderId:string, serviceOfferingId:string) {
    navigate(`/service-providers/${serviceProviderId}/service-offering-details/${serviceOfferingId}/clone`);
  }

  function onCloneWithoutImages(serviceProviderId:string, serviceOfferingId:string) {
    navigate(`/service-providers/${serviceProviderId}/service-offering-details/${serviceOfferingId}/clone?omit=images`);
  }

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      width: 500,
      renderCell: (params: any) => {
        return <div>{params.row.name}</div>;
      },
    },
    {
      field: 'id',
      headerName: 'GUID',
      width: 500,
      renderCell: (params: any) => {
        return (<CopyToClipboardCell val={params.id}/>);
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 200,
      renderCell: (params:any) => {
        return (
          <Grid container item xs={12} justifyContent='center' alignItems='center'>
            <CloneCell id={params.id} name={params.row.name} serviceProviderId={selectedServiceProvider}/>
            <ToggleCell id={params.id} name={params.row.name} wasEnabled={!params.row.disabledNeighborhoodServiceOfferingsDate}/>
            <EditCell id={params.id} name={params.row.name} serviceProviderId={selectedServiceProvider}/>
            <DeleteCell id={params.id} name={params.row.name}/>
          </Grid>
        );
      },
    },
  ];
  const columnTotalWidth = columns.map(x => x.width).reduce((a:any, b:any) => a+b, 0);

  return (
    <ConfirmationModalContextProvider onConfirm={_deleteServiceOffering}>
      <Grid container spacing={2} className='pageGridContainer'>
        <Grid item xs={12}>
          <PageHeader headerText={'Service Offerings'} />
        </Grid>
        <Grid item xs={8} md={2}>
          <h4>Step 1: Select a Service Provider</h4>
          <ServiceProviderAutocomplete
            required
            label='Service Provider'
            placeholder='Search a service provider'
            formless
            postOnChange={onServiceProviderChanged}
            controlledValue={selectedServiceProvider}
          />
        </Grid>
        {selectedServiceProvider && (
          <>
            <Grid item xs={12}>
              <Button variant="contained" onClick={addNewButtonClick}>Add New</Button>
            </Grid>
            <Grid item xs={12}>
              <DataGrid
                rows={rows}
                components={{ Toolbar: QuickSearchToolbar }}
                componentsProps={{
                  toolbar: {
                    quickSearchStorageKey,
                    value: searchText,
                    onChange: (event: React.ChangeEvent<HTMLInputElement>) => requestSearch(serviceOfferings, event.target.value),
                    clearSearch: () => requestSearch(serviceOfferings, ''),
                  },
                }}
                columns={columns}
                autoHeight={true}
                sortModel={sortModel}
                onSortModelChange={setSortModel}
                style={{
                  minWidth: '500px',
                  maxWidth: `${columnTotalWidth}px`,
                }}
              />
            </Grid>
          </>
        )}
      </Grid>
      <ConfirmationModal
        title={(onConfirmArgs) => (<div className={styles.confirmDeleteModalTitle}>Delete service offering</div>)}
        message={(onConfirmArgs:any) => (
          <Grid container spacing={1} alignItems='center' justifyContent='center' className={styles.confirmDeleteModal}>
            <Grid item xs>
                        You are about to delete {onConfirmArgs.serviceOfferingName}.
            </Grid>
          </Grid>
        )}
      />
      <DisableNsoByServiceOfferingModal
        postOnConfirm={postToggle}
      />
      <CloneServiceOfferingModal
        onCloneWithImages={onCloneWithImages}
        onCloneWithoutImages={onCloneWithoutImages}
      />
    </ConfirmationModalContextProvider>
  );
}