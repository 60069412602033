import { Grid, Typography } from '@mui/material';
import { HookCheckbox, HookTextField } from 'components/reactHookForm';
import { useFormContext, useWatch } from 'react-hook-form';
import { ServiceCategoryImageDropzone } from './ServiceCategoryImageDropzone';
import ServiceCategoryImage from './ServiceCategoryImage';
import { ManageServiceTypeMode } from 'model/serviceCategory';
import { useParams } from 'react-router-dom';
import ColorSelect from './ColorSelect';

export default function ServiceCategoryFields() {
  const { control } = useFormContext();
  const { mode } = useParams();
  const isNew = mode === ManageServiceTypeMode.CREATE;
  const shouldDisableCoreFields = mode === ManageServiceTypeMode.EDIT_MARKET || mode === ManageServiceTypeMode.EDIT_TERRITORY;
  return (
    <Grid container item spacing={1}>
      <Grid item xs={12}>
        <Typography variant='h5'></Typography>
      </Grid>
      <Grid item xs={4}>
        <HookTextField name='name' label='Name' disabled={shouldDisableCoreFields || !isNew} required/>
      </Grid>
      <Grid item xs={4}>
        <HookTextField name='displayName' label='Display Name' disabled={shouldDisableCoreFields}/>
      </Grid>
      <Grid item xs={4}>
        <HookTextField name='sortOrder' label='Sort Order' disabled={shouldDisableCoreFields} required/>
      </Grid>
      <Grid item xs={4}>
        <ColorSelect
          disabled={shouldDisableCoreFields}
        />
      </Grid>
      <Grid item xs={4}>
        <HookCheckbox
          name={'enabled'}
          label='Enabled'
        />
      </Grid>
      <Grid container item xs={12} justifyContent='space-between' alignItems='center'>
        <Grid item xs>
          <ServiceCategoryImageDropzone/>
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs='auto'>
          <ServiceCategoryImage/>
        </Grid>
        <Grid item xs={1}></Grid>
      </Grid>
    </Grid>
  );
}