import { Button, Chip, Grid, IconButton, List, ListItem, TextField, Typography } from '@mui/material';
import { RemoveButton } from 'components/buttons';
import { HookCheckbox, HookTextField } from 'components/reactHookForm';
import useServiceOfferingForm from 'hooks/serviceOffering/useServiceOfferingForm';
import { CadenceType } from 'model/serviceOffering';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { AddListItemButton, ServiceOfferingCardContainer, StepHeader } from './common';
import SwapHorizOutlinedIcon from '@mui/icons-material/SwapHorizOutlined';
import { findSubscribersWithSubscriptionSelection } from 'api/subscriberApi';
import { useParams } from 'react-router-dom';
import useToast from 'components/toast/useToast';
import { IMinimalCustomerDTO } from 'model/participant';
import { useEffect, useState } from 'react';
import { watch } from 'fs';

export interface ISubscriberIdsWithSelectionProps {
  subscribers:IMinimalCustomerDTO[];
}
function SubscriberIdsWithSelection({ subscribers }) {

  return (
    <List sx={{ background: '#fff', border: '1px solid gainsboro', marginTop: '10px' }}>
      <Typography variant='body2' sx={{ padding: '15px' }}>Subscriber IDs copied to clipboard</Typography>
      {subscribers.map(x => {
        return (
          <ListItem key={x.id}>{x.id}</ListItem>
        );
      })}
    </List>
  );
}

function SubscriptionOptionChoices({ subscriptionOptions }:any) {
  const { serviceProviderId, serviceOfferingId } = useParams();
  const { control, getValues } = useFormContext();
  const { createErrorToast } = useToast();
  const { createEmptySubscriptionOption } = useServiceOfferingForm(null);
  const { fields: choices, append, move, remove, replace } = useFieldArray({
    control,
    name: `${subscriptionOptions}.options`,
  });
  const watchSubOptions = useWatch({ control, name: 'subscriptionOptions.options' });
  useEffect(() => {
    if (choices.length !== watchSubOptions.length) {
      replace(watchSubOptions);
    }
  }, [watchSubOptions]);
  const watchedCadenceType = useWatch({ control, name: 'cadenceType' });
  return (
    <Grid container item xs={12} spacing={1} alignItems='center'>
      <Grid container item xs={12} alignItems='center'>
        <AddListItemButton
          label='Add list item'
          onClick={() => append(createEmptySubscriptionOption())}
        />
      </Grid>
      <ServiceOfferingCardContainer
        items={choices}
        render={(index:number, onDelete:any, sortOrder:number, onSortOrderChange:any, onMove:any) => {
          return (
            <Grid container spacing={1} alignItems='center'>
              <Grid item xs={1}>
                <Chip label={`${index+1}`}/>
              </Grid>
              <Grid item xs={10}>
              </Grid>
              <Grid item xs={1}>
                <RemoveButton
                  tooltipTitle="Delete this subscription option"
                  onClick={async () => {
                    const data = {
                      subscriptionSelection: getValues(`${subscriptionOptions}.options.${index}.optionValue`),
                    };
                    if (serviceProviderId && serviceOfferingId) {
                      const res = await findSubscribersWithSubscriptionSelection(serviceProviderId, serviceOfferingId, data);
                      if (res.data?.length === 1) {
                        const subscriberIdCsv = res.data.map(x => x.id).join('');
                        navigator.clipboard.writeText(subscriberIdCsv).catch(reason => createErrorToast(reason));
                        createErrorToast(`There is ${res.data.length} subscriber with this option. Cannot delete.`, <SubscriberIdsWithSelection subscribers={res.data}/>);
                      } else if (res.data?.length > 0) {
                        const subscriberIdCsv = res.data.map(x => x.id).join('\n');
                        navigator.clipboard.writeText(subscriberIdCsv).catch(reason => createErrorToast(reason));
                        createErrorToast(`There are ${res.data.length} subscribers with this option. Cannot delete.`, <SubscriberIdsWithSelection subscribers={res.data}/>);
                      } else {
                        onDelete(() => remove(index));
                      }
                    }
                  }}
                />
              </Grid>
              <Grid container spacing={1} item xs={12}>
                <Grid item xs>
                  <HookTextField
                    name={`${subscriptionOptions}.options.${index}.optionName`}
                    label='Name'
                    required={watchedCadenceType !== CadenceType.ONETIME_ONLY}
                  />
                </Grid>
                <Grid item xs>
                  <HookTextField
                    name={`${subscriptionOptions}.options.${index}.optionValue`}
                    label='Value'
                    disabled={!getValues(`${subscriptionOptions}.options.${index}.isNew`)}
                    required={watchedCadenceType !== CadenceType.ONETIME_ONLY}
                  />
                </Grid>
              </Grid>
              <Grid container item xs={12}>
                <Grid item xs>
                  <HookCheckbox
                    name={`${subscriptionOptions}.options.${index}.isDefaultOption`}
                    label='Is default option?'
                  />
                </Grid>
                <Grid item xs>
                  <HookCheckbox
                    name={`${subscriptionOptions}.options.${index}.isOneTime`}
                    label='Is one time?'
                  />
                </Grid>
                <Grid item xs>
                  <HookTextField
                    name={`${subscriptionOptions}.options.${index}.annualBillingOccurrences`}
                    label='Annual Billing Cycles'
                    required={watchedCadenceType !== CadenceType.ONETIME_ONLY}
                  />
                </Grid>
                <Grid item xs={12}></Grid>
                <Grid item xs={6} >
                  <HookTextField
                    name={`${subscriptionOptions}.options.${index}.priceSuffix`}
                    label='Price Suffix Override'
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant='standard'
                  size='small'
                  label='sort order'
                  value={sortOrder}
                  onChange={onSortOrderChange}
                  sx={{
                    width: '80px',
                  }}
                />
                <IconButton
                  type='button'
                  onClick={() => {
                    if (sortOrder >= 0 && sortOrder < choices.length) {
                      move(index, sortOrder);
                      onMove();
                    }
                  }}>
                  <SwapHorizOutlinedIcon/>
                </IconButton>
              </Grid>
            </Grid>
          );
        }}
      />
    </Grid>
  );
}

export default function SubscriptionOptions() {
  const { createEmptySubscriptionOption } = useServiceOfferingForm(null);
  const formContext = useFormContext();
  const selectedCadenceType = formContext.watch('cadenceType');


  return (
    <Grid container item spacing={1} alignItems='top'>
      <Grid item xs={6} md={3}>
        <HookTextField
          name={'subscriptionOptions.label'}
          label='Subscription option label'
          required={selectedCadenceType !== CadenceType.ONETIME_ONLY}
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <HookTextField
          name={'subscriptionOptions.makeRecurringLabel'}
          label='Make Recurring Label'
          placeholder='default is: make recurring?'
        />
      </Grid>
      <Grid item xs={6} md={3} alignSelf='top'>
        <HookTextField
          name={'subscriptionOptions.subscriptionTermInMonths'}
          label='Subscription term in months'
          validationRules={{
            min: { value: 0 || false, message: 'Minimum value of 0' },
            validate: function(value) {
              if (selectedCadenceType === CadenceType.ONETIME_ONLY) {
                return true; //if one time only then this field will be set to null
              }
              if (value === null || value === '') {
                return true;
              }
              const matched = !!value.toString().match(/^[0-9]{1,10}$/g);
              if (!matched) {
                return 'Subscription term must be a positive integer';
              }
              return true;
            },
          }}
        />
      </Grid>
      <Grid item md={12}></Grid>
      <Grid item xs={12} md={12}>
        <SubscriptionOptionChoices
          subscriptionOptions={'subscriptionOptions'}
        />
      </Grid>
    </Grid>
  );
}