
import { Grid, IconButton, Tooltip } from '@mui/material';
import { PrimaryButton } from 'components/buttons';
import useToast from 'components/toast/useToast';
import { createEmptyAuditCustomerUploadForm, createEmptyCustomerUploadDeleteByIdForm, IAuditCustomerUploadForm } from 'model/customerUpload';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import ConfirmAuditModal, { modalStoreKey } from './ConfirmAuditModal';
import HelpIcon from '@mui/icons-material/Help';
import useModal from 'components/modal/useModal';
import useAuditCustomerUpload from '../hookStore/useAuditCustomerUpload';
import AuditCustomerUploadServiceProviderAutocomplete from './AuditCustomerUploadServiceProviderAutocomplete';
import { HookCheckbox } from 'components/reactHookForm';
import { BigTooltip } from 'components/tooltip/BigTooltip';

export default function AuditCustomerUploadForm() {
  const { getModalState, openModal } = useModal(modalStoreKey);
  const { onAuditCustomerUpload } = useAuditCustomerUpload();
  const { createErrorToast } = useToast();
  const formContext = useForm<IAuditCustomerUploadForm>({
    mode: 'onTouched',
    defaultValues: createEmptyAuditCustomerUploadForm(),
  });
  const onSubmit : SubmitHandler<IAuditCustomerUploadForm> = data => {

    openModal({
      formData: data,
      callback: onAuditCustomerUpload,
    });
  };


  return (
    <>

      <FormProvider {...formContext}>
        <form onSubmit={formContext.handleSubmit(onSubmit)}>
          <Grid container spacing={1} >
            <Grid container item xs={11} spacing={1}>
              <Grid item xs={12} md={3} >
                <AuditCustomerUploadServiceProviderAutocomplete/>
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={12} md={3}>
                <HookCheckbox
                  name='auditAllServiceProviders'
                  label='Run for all service providers'
                />
                <BigTooltip title={
                  'Run the audit process for all service providers'
                }>
                  <IconButton>
                    <HelpIcon />
                  </IconButton>
                </BigTooltip>
              </Grid>
            </Grid>
            <Grid item xs={11} sx={{ marginTop: '20px' }}>
              <PrimaryButton type='submit'>Submit</PrimaryButton>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
      <ConfirmAuditModal/>
    </>
  );
}