import { setFormData } from 'util/formHelpers';
import { createValidateEmail } from 'util/rhfValidateFunctions';
import { sortByString } from 'util/sortFunctions';
import { Grid } from '@mui/material';
import { findIndividualAccounts } from 'api/accountApi';
import { findAllServiceProviders } from 'api/serviceProviderApi';
import { findUserById, saveUser } from 'api/userApi';
import { HookTextField } from 'components/reactHookForm';
import PageHeader from 'components/SectionHeaders/PageHeader';
import { IEditableUser } from 'model/user';
import { useEffect } from 'react';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { VerificationMessage } from './userDetails/VerificationMessage';
import { FormButtons } from './userDetails/FormButtons';
import { ServiceProviderSelect } from './userDetails/ServiceProviderSelect';
import { RolesSelect } from './userDetails/RolesSelect';
import { IndividualsSelect } from './userDetails/IndividualsSelect';
import { AccountsSelect } from './userDetails/AccountsSelect';
import useToast from 'components/toast/useToast';

const validateEmail = createValidateEmail();
export const sortByName = sortByString('name');


export function UserDetailsFormInternal() {

}

export default function UserDetails() {
  const { createErrorToast, createSuccessToast } = useToast();
  const formContext = useForm<IEditableUser>({
    mode: 'onTouched',
    defaultValues: {
      id: '',
      name: '',
      organizations: [],
      roles: [],
      accounts: [],
      serviceProviders: [],
      individuals: [],
    },
  });
  const navigate = useNavigate();

  const { userId } = useParams();

  async function _saveUser(data:IEditableUser) {
    const dataToSave = { ...data };
    try {
      await saveUser(dataToSave);
      createSuccessToast('User saved successfully');
      navigate('/users');
    } catch (e:any) {
      console.error(e.response.data.message);
      createErrorToast(`Failed to save user: ${e.response.data.message}`);
    }

  }

  useEffect(() => {
    async function _populateUser() {
      if (userId) {
        const response = await findUserById(userId);
        if (response.data) {

          setFormData(response.data, formContext.setValue);
        }
      }
    }
    void _populateUser();
  }, []);

  const onSubmit : SubmitHandler<IEditableUser> = data => {
    void _saveUser(data);
  };

  return (
    <FormProvider {...formContext}>
      <form onSubmit={formContext.handleSubmit(onSubmit)}>
        <Grid container spacing={2} className='pageGridContainer'>
          <Grid container item xs={12}>
            <PageHeader headerText={'Manage user'}/>
            <VerificationMessage/>
          </Grid>
          <Grid item xs={4}>
            <HookTextField
              label='Email'
              name='email'
              required
              validationRules={{
                validate: validateEmail,
              }}
            />
            <HookTextField
              label='Name'
              name='name'
            />
          </Grid>
          <Grid item xs={4}>
            <ServiceProviderSelect/>
          </Grid>
          <Grid item xs={4}>
            <RolesSelect/>
          </Grid>
          <Grid item xs={4}>
            <IndividualsSelect/>
          </Grid>
          <Grid item xs={4}>
            <AccountsSelect/>
          </Grid>
          <Grid container item xs={12}>
            <FormButtons/>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
}