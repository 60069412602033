import { Box, Grid, IconButton, List, Typography } from '@mui/material';
import Loading from 'components/Layout/Loading';
import ConfirmationModal, { ConfirmationModalContextProvider } from 'components/modals/ConfirmationModal';
import PageHeader from 'components/SectionHeaders/PageHeader';
import { useEffect } from 'react';

import styles from './homeUpload.module.css';
import HomeUploadForm from './HomeUploadForm';
import useHomeUpload from './homeUploadHookStore';
import TooltipDrawer from 'components/drawer/TooltipDrawer';
import useTooltipDrawer from 'components/drawer/useTooltipDrawer';
import TooltipDrawerButton from 'components/drawer/TooltipDrawerButton';
import useSelf from 'hooks/useSelf';
import { ROLE_ADMIN_DEFAULT } from 'model/roles';
import NeighborhoodAutocomplete from 'components/neighborhoodAutocomplete/NeighborhoodAutocomplete';
import { getNeighborhoodZipCodeStatusString } from 'util/neighborhoodUtil';
import { PrimaryButton } from 'components/buttons';


export default function HomeUpload() {
  const { onUpload, init, loadingKey, onSyncStreetNames, onChangeNeighborhood } = useHomeUpload();
  const { roles } = useSelf();
  const hasDefaultAdminRole = roles.indexOf(ROLE_ADMIN_DEFAULT) > -1;

  useEffect(() => {
    void init();
  }, []);

  return (
    <ConfirmationModalContextProvider onConfirm={(args) => onUpload(args)}>
      <Loading loadingKey={loadingKey} size={100}>
        <Grid container spacing={1} className='pageGridContainer' justifyContent='start' alignItems={'center'}>

          <Grid item xs={12}>
            <PageHeader
              headerText={'Home Upload - PropStream'}
              rightChildren={
                <TooltipDrawerButton
                  storeKey='homeUploadDrawer'
                  iconButtonProps={{ sx: { marginTop: '20px' } }}
                />
              }
            />
          </Grid>
          <Grid item xs={12} sx={{ marginTop: 1 }}>
            <HomeUploadForm/>
          </Grid>
          {hasDefaultAdminRole && (
            <>
              <Grid item xs={4} sx={{ marginTop: 1 }}>
                <NeighborhoodAutocomplete
                  label={'Neighborhood to sync street names'}
                  formless={true}
                  postOnChange={(_formCtx, value) => {
                    onChangeNeighborhood(value?.optionValue);
                  }}
                  fieldName='neighborhood'
                  placeholder={''}
                  required={true}
                  optionDescriptionFunc={getNeighborhoodZipCodeStatusString}
                />
              </Grid>
              <Grid item xs='auto' >
                <PrimaryButton
                  type='button'
                  color='primary'
                  onClick={() => {
                    void onSyncStreetNames();
                  }}
                >
                  <>sync</>
                </PrimaryButton>
              </Grid>
            </>
          )}
        </Grid>
      </Loading>
      <ConfirmationModal
        title={(onConfirmArgs) => (<div className={styles.confirmDeleteModalTitle}>Upload PropStream file</div>)}
        message={(onConfirmArgs:any) => {
          if (onConfirmArgs.shouldUpload) {
            return (
              <Grid container spacing={1} alignItems='center' justifyContent='center' className={styles.confirmModal}>
                <Grid item xs>
                  You are about to upload {onConfirmArgs.fileName}. You will receive an email with results when complete.
                </Grid>
              </Grid>
            );
          }
        }}
      />
      <TooltipDrawer storeKey={'homeUploadDrawer'}>
        <Box sx={{ padding: '16px', maxWidth: '400px' }}>
          <Typography variant='h6'>Home Upload</Typography>
          <Typography variant='body1'>Used to upload a PropStream file for processing.</Typography>
          <Box sx={{ height: '16px' }}></Box>
          <Typography variant='h6'>Order of operations</Typography>
          <Typography variant='body1'>Follow these steps when preparing neighborhoods for a territory</Typography>
          <ol>
            <li>Go to PropStream and draw a general polygon/circle around the territory. It does not have to be perfect but shoud encompass the territory.</li>
            <li>Export the file from PropStream using normal filters (e.g. owner occupied, residential family home, etc.)</li>
            <li>Upload the file using the drag &amp; drop element on this page (or click to select file)</li>
            <li>Ensure that all neighborhoods in the territory have a polygon assigned (i.e. WKT) and territory assigned.
              If you wish for household count, starting neighbor count, and max happening soon card count to be set automatically then <i>do not </i>
              set these fields on the neighborhood before running territory prep </li>
            <li>Run territory prep tool. The household count will be calculated using the uploaded propstream data for each neighborhood by using geospatial queries to determine how many homes exist within a neighborhood's polygon.
              If the calculated household count is 0 then the setting of neighborhood fields will be skipped. If the result is non-zero then household count, starting neighborhood count,
              max happening soon card count, and neighborhood status will be set. The neighborhood status will be set to active if the neighborhood is not already set to active or launched.
            </li>
            <li>Spot check a few neighborhoods to make sure that the proper fields were set. </li>
          </ol>
        </Box>
      </TooltipDrawer>
    </ConfirmationModalContextProvider>
  );
}