import QuickSearchToolbar from 'components/grid/QuickSearchToolbar';
import ProviderRecommendations from './ProviderRecommendations';
import useProviderRecommendations from './hookStore/useProviderRecommendations';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import serviceOfferings from 'pages/serviceOffering/serviceOfferings';
import { useState } from 'react';
import useTableSearch from 'components/grid/useTableSearch';
import { DataGridPro, DataGridProProps } from '@mui/x-data-grid-pro';
import { Grid, Typography } from '@mui/material';


const columns: GridColDef[] = [
  {
    field: 'title',
    headerName: 'Title',
    width: 500,
    renderCell: (params: any) => {
      return <div style={{ fontWeight: 'bold' }}>{params.row.title}</div>;
    },
  },
  {
    field: 'name',
    headerName: 'Author Name',
    width: 300,
    renderCell: (params: any) => {
      return (<div style={{ fontWeight: 'bold' }}>{params.row.author.name}</div>);
    },
  },
  {
    field: 'neighborhood',
    headerName: 'Author Neighborhood',
    width: 300,
    renderCell: (params: any) => {
      return (<div style={{ fontWeight: 'bold' }}>{params.row.author?.neighborhood_name ?? 'Unknown'}</div>);
    },
  },
];
const columnTotalWidth = columns.map(x => x.width).reduce((a:any, b:any) => a+b, 0);
const quickSearchStorageKey = 'providerRecommendations';

const getDetailPanelHeight: DataGridProProps['getDetailPanelHeight'] = () => 'auto';

export default function ProviderRecommendationsTable() {
  const { data } = useProviderRecommendations();
  const { rows, searchText, setRowsWithStoredFilter, requestSearch, clearStoredSearch } = useTableSearch(quickSearchStorageKey);
  const [sortModel, setSortModel] = useState([
    {
      field: 'name',
      sort: 'asc' as any,
    },
  ]);
  return (
    <Grid container>
      <Grid item xs={12} sx={{ margin: '10px' }}>
        <DataGridPro
          rows={data}
          components={{ Toolbar: QuickSearchToolbar }}
          componentsProps={{
            toolbar: {
              quickSearchStorageKey,
              value: searchText,
              onChange: (event: React.ChangeEvent<HTMLInputElement>) => requestSearch(serviceOfferings, event.target.value),
              clearSearch: () => requestSearch(serviceOfferings, ''),
            },
          }}
          getDetailPanelContent={(params: any) => {
            return (
              <Grid container
                spacing={1}
                sx={{
                  maxWidth: '1100px',
                  padding: '10px',
                }}
              >
                <Grid item xs={12} md={6}>
                  <Typography variant='h6'>Message Body</Typography>
                  <Typography variant='body2'> {params.row.body}</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant='h6'>Comments</Typography>
                  {params.row.comments.map((x, i) => (
                    <Typography variant='body2' sx={{ marginBottom: '10px' }}>Comment #{i+1}: {x.body}</Typography>
                  ))}
                </Grid>
              </Grid>
            );
          }}
          getDetailPanelHeight={getDetailPanelHeight}
          columns={columns}
          autoHeight={true}
          sortModel={sortModel}
          onSortModelChange={setSortModel}
          style={{
            minWidth: '800px',
            maxWidth: `${columnTotalWidth}px`,
          }}
        />
      </Grid>
    </Grid>
  );
}