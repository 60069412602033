import Axios, { AxiosResponse } from 'axios';
import { IReferrerRewardTestingForm } from 'model/referrer_reward_testing/referrer_reward';
import { IEditableUser, IExistingProviderUserResponse, IProviderSignUpLinkGenerationResult, IServiceProviderUserDto } from 'model/user';
import { IUserReconciliationForm } from 'model/userReconciliation';

const apiUrl = window.REACT_APP_BASE_API_URI;

export function findAllUsers() {
  return Axios.get(`${apiUrl}/users/v2/findAll`);
}

export function findUserByEmail(email:string) {
  return Axios.post(`${apiUrl}/users/v2/findByEmail`, { email });
}

export function findUserById(id:string) {
  return Axios.get(`${apiUrl}/users/v2/find/${id}`);
}

export function deleteUser(id:string) {
  return Axios.delete(`${apiUrl}/users/v2/delete/${id}`);
}

export function saveUser(data:IEditableUser) {
  if (data.id) {
    return Axios.put(`${apiUrl}/users/v2/update/${data.id}`, data);
  }
  return Axios.post(`${apiUrl}/users/v2/create`, data);
}

export function generateProviderSignUpLink(serviceProviderId:string) :Promise<AxiosResponse<IProviderSignUpLinkGenerationResult>> {
  return Axios.post(`${apiUrl}/users/v2/generateProviderSignUpLink/${serviceProviderId}`);
}

export function saveProviderUser(data:IServiceProviderUserDto) {
  if (data.id) {
    console.error('No update method created yet.');
  }
  return Axios.post(`${apiUrl}/users/v2/createServiceProviderUser`, data);
}

export function findAvailableRoles(id:string) {
  return Axios.get(`${apiUrl}/users/v2/findAvailableRoles/${id}`);
}

export function reconcileUsers(data:IUserReconciliationForm) {
  var formData = new FormData();
  formData.append('users', data.users);
  return Axios.post(`${apiUrl}/users/v2/reconcileUsers`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}


export function testReferralFunding(data:IReferrerRewardTestingForm) {
  return Axios.post(`${apiUrl}/users/v2/testReferralFunding`, data);
}

export function findExistingProviderUsers(serviceProviderId:string) :Promise<AxiosResponse<IExistingProviderUserResponse>> {
  return Axios.get(`${apiUrl}/users/v2/serviceProviders/${serviceProviderId}/findExistingProviderUsers`);
}