
import { AutocompleteChangeReason, AutocompleteInputChangeReason, FormControl, FormHelperText } from '@mui/material';
import { HookSingleAsyncAutocomplete } from 'components/reactHookForm';
import useErrorState from 'hooks/reactHookForm/useErrorState';
import { FormProvider, useForm, useFormContext, useWatch } from 'react-hook-form';
import useNeighborhoodAutocomplete from './useNeighborhoodAutocomplete';
import { IDropdownOption } from 'model/dropdown';
import { useEffect } from 'react';
import { INeighborhood } from 'model/neighborhood';


export interface INeighborhoodAutocompleteProps {
  label:string;
  fieldName?: string;
  formless: boolean;
  placeholder:string;
  postOnChange?: (formCtx: any, selectedOption: IDropdownOption | null, reason: AutocompleteChangeReason, details: any) => void;
  postOnInputChange?: (formCtx: any, nextVal: string | null, reason: AutocompleteInputChangeReason, details: any) => void;
  controlledValue?: string;
  statuses?: string[];
  extraProps?:any;
  required: boolean;
  rules?:any;
  onNeighborhoodsRetrieved?: (options: INeighborhood[]) => Promise<void>;
  onOptionsCreated?: (options: IDropdownOption[]) => Promise<void>;
  optionDescriptionFunc?: (data:INeighborhood) => string;
  marketOverride?: string | 'skip'; // if 'skip' then the market will not be used in the request
}

interface IFormlessForm {
  selectedNeighborhood:IDropdownOption | null;
}

export default function NeighborhoodAutocomplete({
  label,
  fieldName,
  formless,
  placeholder,
  statuses,
  controlledValue,
  extraProps,
  required,
  rules,
  postOnChange,
  postOnInputChange,
  onNeighborhoodsRetrieved,
  onOptionsCreated,
  optionDescriptionFunc,
  marketOverride,
}:INeighborhoodAutocompleteProps) {
  if (!formless && !fieldName) {
    throw new Error('fieldName is required when formless is false');
  }
  let _rules: any;
  if (rules) {
    _rules = rules;
  } else if (required) {
    _rules = {
      required: {
        value: true, message: 'Required',
      },
    };
  }
  const { options, onSearchNeighborhoods, initWithValue } = useNeighborhoodAutocomplete();
  if (!formless) {
    const formCtx = useFormContext();
    const { isError, getErrorMessage } = useErrorState(formCtx);
    useEffect(() => {
      if (controlledValue && options.length === 0) {
        void initWithValue(formCtx, fieldName!, controlledValue, statuses ?? [], onNeighborhoodsRetrieved, onOptionsCreated, optionDescriptionFunc);
      }
      return () => {

      };
    }, [controlledValue]);
    return (
      <FormControl variant='standard' fullWidth error={formless ? false : isError(fieldName!)}>
        <HookSingleAsyncAutocomplete
          rules={_rules}
          name={fieldName!}
          label={label}
          dropdownOptions={options}
          placeholder={placeholder}
          onInputChange={(value: string, reason: AutocompleteInputChangeReason) => {
            void onSearchNeighborhoods(
              value,
              statuses ?? [],
              onNeighborhoodsRetrieved,
              onOptionsCreated,
              optionDescriptionFunc,
              marketOverride,
            );
            if (postOnInputChange) {
              postOnInputChange(formCtx, value, reason, null);
            }
          }}
          postOnChange={(formContext, value, reason, details) => {
            if (postOnChange) {
              postOnChange(formContext, value as IDropdownOption, reason, details);
            }

          }}
        />
        <FormHelperText>{getErrorMessage(fieldName!) || ' '}</FormHelperText>
      </FormControl>
    );
  } else {
    const formContext = useForm<IFormlessForm>({
      mode: 'onTouched',
      defaultValues: {
        selectedNeighborhood: null,
      },
    });
    const { isError, getErrorMessage } = useErrorState(formContext);
    useEffect(() => {
      if (controlledValue && options.length === 0) {
        void initWithValue(
          formContext,
          'selectedNeighborhood',
          controlledValue,
          statuses ?? [],
          onNeighborhoodsRetrieved,
          onOptionsCreated,
          optionDescriptionFunc,
          marketOverride,
        );
      }
    }, [controlledValue]);

    return (
      <FormProvider {...formContext}>
        <FormControl variant='standard' fullWidth error={formless ? false : isError('selectedNeighborhood')}>
          <HookSingleAsyncAutocomplete
            rules={_rules}
            name={'selectedNeighborhood'}
            label={label}
            dropdownOptions={options}
            placeholder={placeholder}
            onInputChange={(value: string, reason: AutocompleteInputChangeReason) => {
              void onSearchNeighborhoods(
                value,
                statuses ?? [],
                onNeighborhoodsRetrieved,
                onOptionsCreated,
                optionDescriptionFunc,
                marketOverride,
              );
              if (postOnInputChange) {
                postOnInputChange(formContext, value, reason, null);
              }
            }}
            postOnChange={(formCtx, value, reason, details) => {
              if (postOnChange) {
                postOnChange(formCtx, value as IDropdownOption, reason, details);
              }
            }}
            extraProps={extraProps}
          />
          <FormHelperText>{getErrorMessage('selectedNeighborhood') || ' '}</FormHelperText>
        </FormControl>
      </FormProvider>
    );
  }

}