import { FormControl, InputLabel, FormHelperText } from '@mui/material';
import { HookSelect } from 'components/reactHookForm';
import useErrorState from 'hooks/reactHookForm/useErrorState';
import { useFormContext } from 'react-hook-form';

export interface ISeedFactorSelectProps {
  serviceOfferingId: string;
  index: number;
}

const seedFactorOptions = [1, 2, 3, 4, 5]
  .map((x, i) => ({
    key: `${x}`,
    optionText: `${x}`,
    optionValue: x,
  }));

export default function SeedFactorSelect( { serviceOfferingId, index } : ISeedFactorSelectProps) {
  const formCtx = useFormContext();
  const { isError, getErrorMessage } = useErrorState(formCtx);

  return (
    <FormControl variant='standard' fullWidth error={isError('seedFactor')}>
      <InputLabel htmlFor={`seed-factor-${serviceOfferingId}`}>Seed Factor</InputLabel>
      <HookSelect
        control={formCtx.control}
        name={`serviceOfferingsWithSeedFactor.${index}.seedFactor`}
        id={`seed-factor-${serviceOfferingId}`}
        menuItems={seedFactorOptions}
        rules={{ required: 'Required' }}
        menuItemKeys={{
          key: 'id',
          value: 'optionValue',
          displayValue: 'optionText',
        }}
      />
      <FormHelperText>{getErrorMessage(`seedFactor-${serviceOfferingId}`)}</FormHelperText>
    </FormControl>
  );
}