
import {
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { IServiceOfferingWithProviderCoverageArea } from 'model/serviceOffering';
import { useState } from 'react';


export interface IServiceOfferingsWithProviderCoverageProps {
  serviceOfferings?:IServiceOfferingWithProviderCoverageArea[];
}
export default function ServiceOfferingsWithProviderCoverage({ serviceOfferings }:IServiceOfferingsWithProviderCoverageProps) {

  const [open, setOpen] = useState(false);

  const handleClickOpen = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setOpen(true);
  };

  const handleClose = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setOpen(false);
  };

  if (!serviceOfferings || serviceOfferings.length === 0) {
    return null;
  }

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen} >
        Service Offerings: {serviceOfferings.length}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="service-offerings-with-provider-coverage-area"
        aria-describedby="service-offerings-with-provider-coverage-area-description"
      >
        <DialogTitle id="service-offerings-with-provider-coverage-area">
          Service Offerings with Provider Coverage Area
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="service-offerings-with-provider-coverage-area-description">
            <Typography variant='body1' sx={{ marginBottom: '10px' }}>Clear the Provider Coverage Area from the following service offerings:</Typography>
            {serviceOfferings.map(x => {
              return (
                <div key={x.id}>{x.name} id: {x.id}</div>
              );
            })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Okay
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}