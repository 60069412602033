import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Grid, IconButton, Tab, Tabs, Tooltip, Typography } from '@mui/material';
import { PrimaryButton, SecondaryButton, TooltipButton } from 'components/buttons';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Loading from 'components/Layout/Loading';
import { IServiceCategoryForm, ManageServiceTypeMode } from 'model/serviceCategory';
import { useEffect, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import useServiceCategoryDetail from './hookStore/useServiceCategoryDetail';
import ServiceCategoryFields from './components/ServiceCategoryFields';
import styles from './serviceCategoryDetails.module.css';
import SortableServiceTypes from './components/SortableServiceTypes';
import AvailableServiceTypes from './components/AvailableServicetypes';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HelpIcon from '@mui/icons-material/Help';
import SelectMarketModal, { createEmptySelectMarketModal, modalStoreKey as selectMarketModalKey } from './components/pageConfigModal/SelectMarketModal';
import useModal from 'components/modal/useModal';
import useToast from 'components/toast/useToast';
import SelectTerritoryModal from './components/pageConfigModal/SelectTerritoryModal';
import BackToTableButton from '../common/BackToTableButton';


export interface IServiceCategoryDetailsInternalProps {
  initialFormData: IServiceCategoryForm;
}

function a11yProps(index: number) {
  return {
    'id': `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


function ServiceCategoryDetailsInternal({ initialFormData }:IServiceCategoryDetailsInternalProps) {
  const formContext = useForm<IServiceCategoryForm>({
    mode: 'onTouched',
    defaultValues: initialFormData,
  });
  const { createErrorToast } = useToast();

  const {
    loadingKey,
    availableServiceTypesExpanded,
    selectedTab,
    selectedMarket,
    selectedTerritory,
    save,
    cancel,
    onDragEnd,
    onToggleAccordion,
    onSelectTab,
  } = useServiceCategoryDetail();
  const { serviceCategoryId, marketId, territoryId, mode } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    formContext.reset(initialFormData);
  }, [initialFormData]);


  const onSubmit : SubmitHandler<IServiceCategoryForm> = data => {
    if (!serviceCategoryId) {
      createErrorToast('Could not save since serviceCategoryId is not defined.');
      return;
    }
    if (mode == ManageServiceTypeMode.EDIT_MARKET) {
      if (marketId == null) {
        createErrorToast('Could not save since marketId is not defined.');
        return;
      }
      data.marketId = marketId;
      void save(data);
    } else if (mode == ManageServiceTypeMode.EDIT_TERRITORY) {
      if (marketId == null || territoryId == null || !selectedTerritory) {
        createErrorToast('Could not save since marketId is not defined and territoryId is not defined.');
        return;
      }
      data.marketId = marketId;
      data.zipCodeTerritory = selectedTerritory.zipCodeTerritory;
      void save(data);
    } else {
      data.marketId = null;
      data.zipCodeTerritory = null;
      void save(data);
    }
  };

  return (
    <Loading loadingKey={loadingKey}>
      <BackToTableButton/>
      <FormProvider {...formContext}>
        <form onSubmit={formContext.handleSubmit(onSubmit)}>
          <DragDropContext onDragEnd={onDragEnd}>
            <Grid
              container
              className={styles.formGridContainer}
              spacing={1}
              alignItems={'flex-start'}
              sx={{
                '&&&': {
                  paddingTop: '10px',
                },
              }}
            >

              <Grid container item xs={12} alignItems={'center'}>
                <Grid item xs='auto'>
                  {(ManageServiceTypeMode.CREATE === mode) && <Typography variant='h6' >Create Service Category</Typography>}
                  {(ManageServiceTypeMode.EDIT_DEFAULT === mode) && <Typography variant='h6'>Edit Default Service Category</Typography>}
                  {(ManageServiceTypeMode.EDIT_MARKET === mode) && <Typography variant='h6'>Edit Market Service Category</Typography>}
                  {(ManageServiceTypeMode.EDIT_TERRITORY === mode) && <Typography variant='h6'>Edit Territory Service Category</Typography>}
                </Grid>
                <Grid item xs='auto'>
                  <TooltipButton
                    tooltipTitle={`To edit at the market or territory level you must select one of 
                    the Market or Territory tabs and select the appropriate values within. 
                    This page will not respect the selected Market at the top of the screen`}
                  />
                </Grid>
              </Grid>
              <Tabs value={selectedTab} onChange={onSelectTab} aria-label='tabs'>

                <Tab
                  label="Default"
                  value="1"
                  onClick={(e) => {
                    if (selectedTab === '1') {
                      onSelectTab(e, selectedTab);
                    }
                  }}
                  {...a11yProps(0)}
                />

                {ManageServiceTypeMode.CREATE !== mode ? (
                  <Tab
                    label="Market"
                    value="2"
                    onClick={(e) => {
                      if (selectedTab === '2') {
                        onSelectTab(e, selectedTab);
                      }
                    }}
                    {...a11yProps(1)}
                  />
                ): null}
                {ManageServiceTypeMode.CREATE !== mode ? (
                  <Tab
                    label="Territory"
                    value="3"
                    onClick={(e) => {
                      if (selectedTab === '3') {
                        onSelectTab(e, selectedTab);
                      }
                    }}
                    {...a11yProps(2)}
                  />
                ) : null}
              </Tabs>
              <Grid container item xs={12} justifyContent={'space-between'}>
                <Grid container item xs='auto' direction={'column'}>

                  {(selectedMarket !== null) && <Typography variant='body2'>Editing Market: {selectedMarket?.name}</Typography>}
                  {(selectedTerritory !== null) && <Typography variant='body2'>Editing Territory: {selectedTerritory?.name}</Typography>}
                </Grid>
                <Grid container item xs='auto'>
                  <PrimaryButton type='submit' >Save</PrimaryButton>
                  <SecondaryButton onClick={() => cancel()} sx={{ marginLeft: '20px' }}>Cancel</SecondaryButton>
                </Grid>
              </Grid>
              <Grid container item xs={12} md={6}>
                <ServiceCategoryFields/>
                <SortableServiceTypes/>
              </Grid>
              <Grid container item xs={12} md={6}>

                <Accordion
                  expanded={availableServiceTypesExpanded}
                  onChange={onToggleAccordion}
                  sx={{
                    maxHeight: !availableServiceTypesExpanded ? '50px': null,
                    width: '100%',
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    sx={{
                      '&& .MuiAccordionSummary-contentGutters': {
                        margin: '0',
                      },
                    }}
                  >
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <Typography variant='body1' component='div'>Available Service Types</Typography>
                      <Tooltip title={
                        'These service types are not currently associated with this service category. Drag and drop them to the left side so that they will appear in that service category. '+
                        'A service type can be associated with multiple service categories.'
                      }
                      >
                        <IconButton>
                          <HelpIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <AvailableServiceTypes/>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Grid>
          </DragDropContext>
        </form>
      </FormProvider>
      <SelectMarketModal postOnConfirm={function (selectedMarketId: string): void {
        if (serviceCategoryId && serviceCategoryId !== 'unknown' && selectedMarketId) {
          navigate(`/serviceCategoriesV2/${serviceCategoryId}/service-category-details/${ManageServiceTypeMode.EDIT_MARKET}/markets/${selectedMarketId}`);
          window.location.reload();
        }
      }}/>
      <SelectTerritoryModal postOnConfirm={function (selectedMarketId: string, selectedTerritoryId:string): void {
        if (serviceCategoryId && serviceCategoryId !== 'unknown' && selectedMarketId) {
          navigate(`/serviceCategoriesV2/${serviceCategoryId}/service-category-details/${ManageServiceTypeMode.EDIT_TERRITORY}/markets/${selectedMarketId}/territories/${selectedTerritoryId}`);
          window.location.reload();
        }
      }}/>
    </Loading>
  );
}

export default function ServiceCategoryDetails() {
  const { init, formData } = useServiceCategoryDetail();
  useEffect(() => {
    void init();
  }, []);
  return (
    <ServiceCategoryDetailsInternal initialFormData={formData}/>
  );
}