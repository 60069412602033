import { FormControl, InputLabel, FormHelperText, Tooltip } from '@mui/material';
import { HookSelect } from 'components/reactHookForm';
import useErrorState from 'hooks/reactHookForm/useErrorState';
import { CustomFieldGroupType } from 'model/serviceOffering';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

export default function CustomFieldGroupTypeSelect({ index }:any) {
  const formCtx = useFormContext();
  const [customFieldGroupTypes] = useState<any[]>(CustomFieldGroupType.list);
  const { isError, getErrorMessage } = useErrorState(formCtx);
  return (
    <FormControl variant='standard' fullWidth error={isError(`customFields.${index}.customFieldGroupType`)}>
      <InputLabel htmlFor="field-type">Custom Field Group Type</InputLabel>
      <HookSelect

        control={formCtx.control}
        name={`customFields.${index}.customFieldGroupType`}
        id='custom-field-group-type'
        menuItems={customFieldGroupTypes}
      />
      <FormHelperText>{getErrorMessage(`customFields.${index}.customFieldGroupType`)}</FormHelperText>
    </FormControl>
  );
}