import Spacer from 'components/Layout/Spacer';
import PageHeader from 'components/SectionHeaders/PageHeader';
import useNeighborhoodDetail from './hookStore/useNeighborhoodDetail';
import { Button, Grid } from '@mui/material';
import { ConfirmationModalContext } from 'components/modals/ConfirmationModal';
import { useContext } from 'react';

export default function NeighborhoodDataSection(props: any) {
  const {
    formData,
  } = useNeighborhoodDetail();
  const confirmationModalCtx = useContext(ConfirmationModalContext);
  const onSkiptraceButtonClick = () => {
    if (props.neighborhoodId) {
      const nid = props.neighborhoodId;
      confirmationModalCtx.openModal({ nid });
    }
  };

  var dateDisplay = !formData.lastSkipTracedTimestamp ? 'Never' : new Date(formData.lastSkipTracedTimestamp).toLocaleString();
  var buttonDisabled = false;
  if (!!formData.lastSkipTracedTimestamp) {
    buttonDisabled = new Date().getTime() - new Date(formData.lastSkipTracedTimestamp).getTime() < 60 * 60 * 1000;
  }
  var buttonText = buttonDisabled ? 'Must wait 1hr between skiptracing attempts' : 'Skiptrace Neighborhood';
  return (
    <Grid container item xs={12} spacing={1} className='pageGridContainer' paddingBottom={'25px'}>
      <Grid container item xs={12}>
        <PageHeader headerText={'Neighborhood Data'}/>
      </Grid>
      <Grid item xs={12}>
        <Grid container item spacing={1}>
          <Grid item>
            <Button
              variant="contained"
              onClick={() => onSkiptraceButtonClick() }
              disabled={buttonDisabled}
            >
              <span>{buttonText}</span>
            </Button>
          </Grid>
          <Grid item>
            <p>Last Skiptraced on: {dateDisplay}</p>
          </Grid>
        </Grid>
      </Grid>
      <Spacer/>
    </Grid>
  );
}