import { Typography, Grid } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { RemoveButton, EditButton, EventButton, ToggleButton } from 'components/buttons';
import CopyToClipboardCell from 'components/DataGridCell/CopyToClipboard';
import useModal from 'components/modal/useModal';
import { ConfirmationModalContext } from 'components/modals/ConfirmationModal';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { formatMonthDayYear } from 'util/dateUtil';
import styles from './neighborhoodServiceOfferings.module.css';
import { IDisableNsoByIdModal, modalStoreKey } from './DisableNsoByIdModal';
import { ILockNsoByIdModal, modalStoreKey as lockModalStoreKey } from './LockNsoByIdModal';
import LockButton from 'components/buttons/LockButton';

export interface IToggleCellProps {
  id:string;
  name:string;
  wasEnabled:boolean;
}

export interface ILockCellProps {
  id:string;
  name:string;
  wasEnabled:boolean;
}

function ToggleCell({ id, name, wasEnabled }:IToggleCellProps) {
  const { openModal } = useModal(modalStoreKey);
  const state:IDisableNsoByIdModal = {
    id,
    name,
    wasEnabled,
  };
  return (
    <ToggleButton
      toggled={wasEnabled}
      tooltipTitle={`Toggle ${name}`}
      onClick={() => {
        openModal(state);
      }}
      tooltipProps={{
        placement: 'top',
      }}
      iconButtonProps={{
        className: styles.actionButton,
      }}
    />
  );
}
function LockCell({ id, name, wasEnabled }:ILockCellProps) {
  const { openModal } = useModal(lockModalStoreKey);
  const state:ILockNsoByIdModal = {
    id,
    name,
    wasEnabled,
  };
  return (
    <LockButton
      toggled={wasEnabled}
      tooltipTitle={`Ignore ${name} for NSO reconciliation`}
      onClick={() => {
        openModal(state);
      }}
      tooltipProps={{
        placement: 'top',
      }}
      iconButtonProps={{
        className: styles.actionButton,
      }}
    />
  );
}

function DeleteCell({ id, name }) {
  const confirmationModalCtx = useContext(ConfirmationModalContext);
  return (
    <RemoveButton
      tooltipTitle='Remove service offering'
      onClick={() => confirmationModalCtx.openModal({ id, name })}
      tooltipProps={{
        placement: 'top',
      }}
      iconButtonProps={{
        className: styles.actionButton,
      }}
    />
  );
}

function EditCell({ id, name }) {
  const navigate = useNavigate();
  function handleClick() {
    navigate(`/neighborhood-service-offering-detail/${id}`);
  }
  return (
    <EditButton
      tooltipTitle={`Edit ${name}`}
      onClick={handleClick}
      tooltipProps={{
        placement: 'top',
      }}
      iconButtonProps={{
        className: styles.actionButton,
      }}
    />
  );
}

function ViewServiceInstancesCell({ id, name }) {
  const navigate = useNavigate();
  function handleClick() {
    navigate(`/neighborhood-service-offering-detail/${id}/service-instances`);
  }
  return (
    <EventButton
      tooltipTitle={`Service Instances for ${name}`}
      onClick={handleClick}
      tooltipProps={{
        placement: 'top',
      }}
      iconButtonProps={{
        className: styles.actionButton,
      }}
    />
  );
}

export const columns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Name',
    minWidth: 200,
    flex: 1,
    renderCell: (params: any) => {
      const nsoLink = `${window.REACT_APP_CONSUMER_BASE_URI}/neighborhood-service-offering/${params.id}`;
      return (
        <CopyToClipboardCell
          val={(<a target="_blank" rel='noreferrer' href={nsoLink} >{params.value}</a>)}
          copyText={nsoLink}
        />);
      ;
    },
  },
  {
    field: 'id',
    headerName: 'GUID',
    minWidth: 350,
    flex: 1,
    renderCell: (params:any) => {
      return (<CopyToClipboardCell val={params.id}/>);
    },
  },
  {
    field: 'altName',
    headerName: 'Alternative Name',
    minWidth: 300,
    flex: 1,
    renderCell: (params: any) => {
      return (
        <CopyToClipboardCell val={params.row.altName}/>
      );
    },
  },
  {
    field: 'disabledDate',
    headerName: 'NSO Status',
    minWidth: 140,
    flex: 1,
    renderCell: (params: any) => {
      if (!params.row.disabledDate) {
        return <Typography variant='body2'>Enabled</Typography>;
      }
      return (
        <div>
          <Typography variant='body2'>Disabled on {formatMonthDayYear(params.row.disabledDate)}</Typography>
        </div>
      );
    },
  },
  {
    field: 'zips',
    headerName: 'ZIP codes',
    minWidth: 100,
    flex: 1,
    renderCell: (params:any) => {
      return (
        <div>
          <Typography variant='body2'>{params.row.zips}</Typography>
        </div>
      );
    },
  },
  {
    field: 'actions',
    headerName: 'Actions',
    minWidth: 150,
    flex: 1,
    renderCell: (params:any) => {
      return (
        <Grid container item xs={12} justifyContent='center' alignItems='center'>
          <EditCell id={params.id} name={params.row.name}/>
          <LockCell id={params.id} name={params.row.name} wasEnabled={params.row.ignoredFromLaunchTooling}/>
          <ToggleCell id={params.id} name={params.row.name} wasEnabled={params.row.disabledDate === null}/>
          <ViewServiceInstancesCell id={params.id} name={params.row.name}/>
          <DeleteCell id={params.id} name={params.row.name}/>
        </Grid>
      );
    },
  },
];

export const columnTotalWidth = columns.map(x => x.width).reduce((a:any, b:any) => a+b, 0);