import Axios from 'axios';

const apiUrl = window.REACT_APP_BASE_API_URI;

// TODO: temporary, probably not needed long term
export function manuallySendSubscriberReviews() {
  return Axios.get(`${apiUrl}/reviews/v2/scheduling/manuallySendSubscriberReviewRequests`);
}

export function manuallySendCustomerFollowUpReviews() {
  return Axios.get(`${apiUrl}/reviews/v2/scheduling/manuallySendCustomerFollowUpReviewRequests`);
}