import { useContext, useState } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import ProviderInformationForm from './providerInformation/ProviderInformationForm';
import ServiceOfferingForm from './serviceOffering/ServiceOfferingForm';
import DiscountScheduleForm from './discountSchedule/DiscountScheduleForm';
import { Grid, StepButton } from '@mui/material';
import OnboardingSummary from './summary/OnboardingSummary';
import { useParams, useLocation } from 'react-router-dom';

const steps = ['Provider Information', 'Service Offering', 'Discount Schedule', 'Summary'];
const getComponentForStep = (step: number) => {
  switch (step) {
    case 0:
      return <ProviderInformationForm />;
    case 1:
      return <ServiceOfferingForm />;
    case 2:
      return <DiscountScheduleForm />;
    case 3:
      return <OnboardingSummary/>;
    default:
      return <div>Invalid step</div>;
  }
};

export default function ProviderOnboardingStepper({ step }) {
  const handleStep = (s) => {
    // setActiveStep(step);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Grid container sx={{ alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
        <Grid item xs={6}>
          <Stepper activeStep={step} sx={{ marginTop: '25px' }}>
            {steps.map((label, index) => {
              const stepProps: { completed?: boolean } = {};
              return (
                <Step key={label} {...stepProps}>
                  <StepButton onClick={() => handleStep(index)}>
                    {label}
                  </StepButton>
                </Step>
              );
            })}
          </Stepper>
        </Grid>
        <>
          {getComponentForStep(step)}
        </>
      </Grid>
    </Box>
  );
}