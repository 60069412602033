import { Grid } from '@mui/material';
import StepHeader from './StepHeader';
import TextInput from './TextInput';
import validateNumber from './validators';

export default function StepSix() {
  return (
    <Grid container item xs={6} spacing={1}>
      <Grid item xs={12}>
        <StepHeader>Step 6: Billing Frequency</StepHeader>
      </Grid>
      <Grid item xs={6}>
        <TextInput
          name='annualBillingCycleCount'
          label='Annual Billing Cycle Count'
          maxLength='3'
          validationRules={{
            validate: validateNumber,
          }}
        />
      </Grid>
    </Grid>
  );
}