import { Button } from '@mui/material';
import { searchNextdoorPosts } from 'api/nextdoorApi';
import useConfirmationModal from 'components/modals/useConfirmationModal';
import useToast from 'components/toast/useToast';
import { INextDoorSearchPostsResult, INextdoorSearchPostRequest, IProviderRecommendationsForm } from 'model/providerRecommendations';

import { FieldValues, SubmitErrorHandler, SubmitHandler, useFormContext } from 'react-hook-form';
import useProviderRecommendations from '../hookStore/useProviderRecommendations';

export default function SubmitButton() {
  const { handleSubmit, setError, clearErrors, formState: { isSubmitting, isValid, errors }, getValues } = useFormContext();
  const { createErrorToast } = useToast();
  const { submit } = useProviderRecommendations();
  const validate = (data:IProviderRecommendationsForm) => {
    if (!data.searchTerm) {
      return {
        isValid: false,
        error: 'Search Term is required',
      };
    }
    if (!data.territoryId) {
      return {
        isValid: false,
        error: 'Territory is not set',
      };
    }
    return {
      isValid: true,
    };
  };

  const onSubmit : SubmitHandler<IProviderRecommendationsForm> = async data => {
    const { isValid: _isValid, error } = validate(data);
    if (_isValid) {
      const request: INextdoorSearchPostRequest = {
        searchTerm: data.searchTerm!,
        territoryId: data.territoryId!,
      };
      await submit(request);
    } else {
      createErrorToast(error || 'Invalid form data');
    }
  };
  const onSubmitError: SubmitErrorHandler<FieldValues> = data => {
    const { isValid: _isValid } = validate(getValues() as IProviderRecommendationsForm);
    if (_isValid) {

    }
  };
  return (
    <Button
      disabled={isSubmitting || !isValid}
      variant='contained'
      type='button'
      onClick={(e) => {
        // TODO: figure out why this onSubmit gives me inconsistent ts errors
        // @ts-expect-error
        void handleSubmit(onSubmit, onSubmitError)(e);
      }}
    >
      Search
    </Button>
  );
}