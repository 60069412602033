import { Typography } from '@mui/material';
import { useEffect } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';

export default function AliasCount() {
  const { control } = useFormContext();
  const { fields: aliases, append, replace, remove } = useFieldArray({
    name: 'aliases',
  });
  const watchAliases = useWatch({ control, name: 'aliases' });
  useEffect(() => {
    if (aliases.length !== watchAliases.length) {
      replace(watchAliases);
    }
  }, [watchAliases]);
  return (
    <Typography> Aliases ({aliases.length})</Typography>
  );
}