import { Grid, Typography } from '@mui/material';
import { AddButton, RemoveButton } from 'components/buttons';
import Spacer from 'components/Layout/Spacer';
import { HookTextField } from 'components/reactHookForm';
import useToast from 'components/toast/useToast';
import { ManageServiceTypeMode } from 'model/serviceCategory';
import { useEffect } from 'react';
import { useFormContext, useFieldArray, useWatch } from 'react-hook-form';
import { useParams } from 'react-router-dom';

function createAlias() {
  return {
    value: '',
  };
}

export default function Aliases() {
  const { control } = useFormContext();
  const { mode } = useParams();

  const shouldDisableDefaultFields = mode === ManageServiceTypeMode.EDIT_MARKET || mode === ManageServiceTypeMode.EDIT_TERRITORY;
  const { fields: aliases, append, replace, remove } = useFieldArray({
    name: 'aliases',
  });
  const watchAliases = useWatch({ control, name: 'aliases' });
  const { createErrorToast } = useToast();
  useEffect(() => {
    if (aliases.length !== watchAliases.length) {
      replace(watchAliases);
    }
  }, [watchAliases]);

  return (
    <Grid container item spacing={1} alignItems='center'>
      <Grid container alignItems='center' item xs={12}>
        <Typography>Add an alias</Typography>
        <Grid item xs='auto'>
          <AddButton
            tooltipTitle="Add an alias"
            disabled={shouldDisableDefaultFields}
            onClick={ () => append(createAlias())}
          />
        </Grid>
        <Spacer/>
      </Grid>
      {aliases.map((item, index) => {

        return (
          <Grid container item xs={4} lg={3} spacing={1} alignItems='center' key={item.id}>
            <Grid container item xs={9} alignItems='center'>
              <HookTextField
                name={`aliases.${index}.value`}
                label={`Alias ${index+1}`}
                disabled={shouldDisableDefaultFields}
                required
              />
            </Grid>

            <Grid container item xs='auto' alignSelf='center' >
              <RemoveButton
                tooltipTitle="Delete this alias"
                disabled={shouldDisableDefaultFields}
                onClick={() => remove(index)}
              />
            </Grid>
          </Grid>
        );
      })}

    </Grid>
  );
}