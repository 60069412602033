import { Button, Grid, Typography } from '@mui/material';
import PageHeader from 'components/SectionHeaders/PageHeader';
import useMarkets from 'hooks/useMarkets';
import { useEffect } from 'react';
import BatchDateRangePicker from './_components/BatchDateRangePicker';
import PrimaryButton from 'components/buttons/PrimaryButton';
import useTsoiManagementNewBatch from './hookStore/useTsoiManagementNewBatch';
import styles from './tsoiManagement.module.css';
import FirstTerritorySelect from './_components/FirstTerritorySelect';

export default function TsoiManagementNewBatch() {
  const { market } = useMarkets();
  const { init, onSave, onCancel } = useTsoiManagementNewBatch();

  useEffect(() => {
    void init(market);
  }, [market]);

  return (
    <Grid sx={{ paddingLeft: '10px', paddingRight: '10px' }}>
      <PageHeader headerText={`New Batch in ${market?.name}`} />
      <Grid container>
        <BatchDateRangePicker />
      </Grid>
      <Grid item paddingTop={'25px'}>
        <Typography variant="h6" fontWeight={'bold'}>
                Select First Territory:
        </Typography>
        <FirstTerritorySelect />
      </Grid>
      <Grid container item justifyContent={'center'} paddingTop={'50px'} xs={6}>
        <Grid item paddingRight={'25px'}>
          <PrimaryButton onClick={onSave}>Save & Continue</PrimaryButton>
        </Grid>
        <Grid item paddingLeft={'25px'}>
          <Button variant={'outlined'} color={'error'} className={styles.cancelButton} onClick={onCancel}>Cancel</Button>
        </Grid>
      </Grid>
    </Grid>
  );
}