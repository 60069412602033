import { sortByString } from 'util/sortFunctions';
import { Button, Grid, AutocompleteChangeReason } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { findDiscountSchedulesByProvider } from 'api/discountScheduleApi';
import { EditButton } from 'components/buttons';
import CopyToClipboardCell from 'components/DataGridCell/CopyToClipboard';
import QuickSearchToolbar from 'components/grid/QuickSearchToolbar';
import useTableSearch from 'components/grid/useTableSearch';
import PageHeader from 'components/SectionHeaders/PageHeader';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './discountSchedules.module.css';
import ServiceProviderAutocomplete from 'components/serviceProviderAutocomplete/ServiceProviderAutocomplete';
import { IDropdownOption } from 'model/dropdown';
import { displayValueForDiscountScheduleType } from 'model/discountSchedule';

//overview
//1. select service provider from dropdown
//2. show grid of discount schedules related to service provider
//3. click grid item to edit. click add new to add new discount schedule.

const sortByName = sortByString('name');

function EditCell({ id, name, serviceProviderId }) {
  const navigate = useNavigate();
  function handleClick() {
    navigate(`/service-providers/${serviceProviderId}/discount-schedules/${id}`);
  }
  return (
    <EditButton
      tooltipTitle={`Edit ${name}`}
      onClick={handleClick}
      tooltipProps={{
        placement: 'top',
      }}
      iconButtonProps={{
        className: styles.actionButton,
      }}
    />
  );
}
const quickSearchStorageKey = 'discountSchedules';
export default function DiscountSchedules() {

  const [discountSchedules, setDiscountSchedules] = useState<any[]>([]);
  const storedSelectedServiceProvider = localStorage.getItem('discountSchedules.selectedServiceProvider') || '';
  const [selectedServiceProvider, setSelectedServiceProvider] = useState<string>('');
  const { rows, searchText, setRowsWithStoredFilter, requestSearch } = useTableSearch(quickSearchStorageKey);
  const [sortModel, setSortModel] = useState([
    {
      field: 'name',
      sort: 'asc' as any,
    },
  ]);
  const navigate = useNavigate();

  async function getDiscountSchedules(providerId:string) {
    const _discountSchedules = await findDiscountSchedulesByProvider(providerId);
    setDiscountSchedules(_discountSchedules.data);
    setRowsWithStoredFilter(_discountSchedules.data);
  }

  async function onServiceProviderChanged(formCtx: any, option: IDropdownOption | null, reason: AutocompleteChangeReason, details:any) {
    if (reason === 'clear') {
      setSelectedServiceProvider('');
      localStorage.removeItem('discountSchedules.selectedServiceProvider');
      setDiscountSchedules([]);
      setRowsWithStoredFilter([]);
    } else if (option !== null) {
      setSelectedServiceProvider(option.optionValue);
      localStorage.setItem('discountSchedules.selectedServiceProvider', option.optionValue);
      await getDiscountSchedules(option.optionValue);
    }
  }

  function addNewButtonClick(e: any) {
    navigate(`/service-providers/${selectedServiceProvider}/discount-schedules`);
  }

  useEffect(() => {

    if (storedSelectedServiceProvider) {
      setSelectedServiceProvider(storedSelectedServiceProvider);
      void getDiscountSchedules(storedSelectedServiceProvider);
    }

  }, []);

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      width: 500,
      renderCell: (params: any) => {
        return <div>{params.row.name}</div>;
      },
    },
    {
      field: 'type',
      headerName: 'Discount Type',
      width: 500,
      renderCell: (params: any) => {
        return <div>{displayValueForDiscountScheduleType(params.row.type)}</div>;
      },
    },
    {
      field: 'id',
      headerName: 'GUID',
      width: 500,
      renderCell: (params: any) => {
        return (<CopyToClipboardCell val={params.id}/>);
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      renderCell: (params: any) => {
        return (
          <Grid container item xs={12} justifyContent='center' alignItems='center'>
            <EditCell id={params.id} name={params.row.name} serviceProviderId={selectedServiceProvider}/>
          </Grid>
        );
      },
    },
  ];
  const columnTotalWidth = columns.map(x => x.width).reduce((a:any, b:any) => a+b, 0);

  return (
    <Grid container spacing={2} className='pageGridContainer'>
      <Grid item xs={12}>
        <PageHeader headerText={'Discount Schedules'} />
      </Grid>
      <Grid item>
        <h4>Step 1: Select a Service Provider</h4>
        <ServiceProviderAutocomplete
          required
          label='Service Provider'
          placeholder='Search a service provider'
          formless
          postOnChange={onServiceProviderChanged}
          controlledValue={selectedServiceProvider}
        />

      </Grid>
      {selectedServiceProvider && (
        <>
          <Grid item xs={12}>
            <Button variant="contained" onClick={addNewButtonClick}>Add New</Button>
          </Grid>
          <Grid item xs={12}>
            <DataGrid
              rows={rows}
              components={{ Toolbar: QuickSearchToolbar }}
              componentsProps={{
                toolbar: {
                  quickSearchStorageKey,
                  value: searchText,
                  onChange: (event: React.ChangeEvent<HTMLInputElement>) => requestSearch(discountSchedules, event.target.value),
                  clearSearch: () => requestSearch(discountSchedules, ''),
                },
              }}
              columns={columns}
              autoHeight={true}
              sortModel={sortModel}
              onSortModelChange={setSortModel}
              style={{
                minWidth: '500px',
                maxWidth: `${columnTotalWidth}px`,
              }}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
}