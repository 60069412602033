import StateAbbrSelect from 'components/reactHookForm/HookStateAbbrSelect';
import styles from '../providerOnboarding.module.css';
import { HookTextField } from 'components/reactHookForm';
import { Typography, Grid } from '@mui/material';
import { createValidateNumber } from 'util/rhfValidateFunctions';

function validateZip(arg) {
  if (!arg) {
    return true;
  }
  if (arg.length !== 5) {
    return 'Zip code should be 5 digits';
  }
  return createValidateNumber('Should be a number')(arg);
}

export default function AddressInformation() {
  return <><Typography variant='h6' className={styles.sectionTitle}>Address Information</Typography><Grid container item xs={12} sx={{ flexDirection: 'column' }}>
    <Grid item xs={6}>
      <HookTextField
        label='Address Line 1'
        name='address.addressLineOne' />
    </Grid>
    <Grid item xs={6}>
      <HookTextField
        label='Address Line 2'
        name='address.addressLineTwo' />
    </Grid>
    <Grid container item xs={12} spacing={1} sx={{ flexDirection: 'row' }}>
      <Grid item xs={3}>
        <HookTextField
          label='City'
          name='address.city' />
      </Grid>
      <Grid item xs={2}>
        <StateAbbrSelect hookSelectName='address.state' hookSelectId={'addressState'} label='State' />
      </Grid>
      <Grid item xs={2}>
        <HookTextField
          label='Zip Code'
          name='address.zipCode'
          validationRules={{
            required: false,
            validate: validateZip,
          }} />
      </Grid>
    </Grid>
  </Grid></>;
}