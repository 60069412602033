import { useLoading } from 'components/Layout/Loading';
import createStore from 'hooks/hookStore';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IDropdownOption } from 'model/dropdown';
import { searchServiceProviders } from 'api/serviceProviderApi';
import { INeighborhoodSearchRequest, ISearchPage, IStreetFairSearchRequest } from 'model/search';
import debounce from 'lodash.debounce';
import { INeighborhood } from 'model/neighborhood';
import { searchNeighborhoods } from 'api/neighborhoodApi';
import useMarkets from 'hooks/useMarkets';

const loadingKey = 'NeighborhoodAutocompleteStore';
type NeighborhoodAutocompleteStore = {
  options: IDropdownOption[];

}

const { get, update, registerListener, unregisterListener } = createStore<NeighborhoodAutocompleteStore>('NeighborhoodAutocompleteStore', {
  options: [],

});

const debouncedSearchNeighborhoods = debounce(
  async (dto:IStreetFairSearchRequest, callback:any) => {
    try {
      const res = await searchNeighborhoods(dto);
      callback(res.data);
    } catch (err) {
      console.error(err);
    }

  }, 400);

export default function useNeighborhoodAutocomplete() {
  const setState = useState(get())[1];
  const navigate = useNavigate();
  const { onLoading, doneLoading } = useLoading(loadingKey);
  const { selectedMarket } = useMarkets();

  useEffect(() => {
    registerListener(setState);
    return () => {
      update({
        ...get(),
        options: [],
      });
      unregisterListener(setState);
    };
  }, []);

  async function initWithValue(
    formContext: any,
    fieldName: string,
    id:string,
    statuses: string[],
    onRetrieval?: (options: INeighborhood[]) => Promise<void>,
    onOptionsCreated?: (options: IDropdownOption[]) => Promise<void>,
    optionDescriptionFunc?: (data:INeighborhood) => string,
    marketId?: string | 'skip',
  ) {
    onLoading();
    let dto:INeighborhoodSearchRequest = {
      query: '',
      pageNumber: 0,
      pageSize: 100,
      offset: 0,
      marketId: selectedMarket?.id,
      statuses: statuses,
      ids: [id],
    };
    if (marketId && marketId !== 'skip') {
      dto.marketId = marketId;
    } else if (marketId === 'skip') {
      delete dto.marketId;
    }
    debouncedSearchNeighborhoods(dto, async (data) => {
      const page = data as ISearchPage<INeighborhood>;
      if (onRetrieval) {
        await onRetrieval(page.items);
      }
      const options = page.items.filter(x => x.id && x.name).map((item) => {
        return {
          key: item.id!,
          optionText: optionDescriptionFunc ? optionDescriptionFunc(item) : item.name!,
          optionValue: item.id!,
          ancillary: item,
        };
      });
      update({
        ...get(),
        options: options,
      });

      if (onOptionsCreated) {
        await onOptionsCreated(options);
      }

      let selectedOption = page.items.filter((item) => item.id === id).map((item) => {
        return {
          key: item.id,
          optionText: optionDescriptionFunc ? optionDescriptionFunc(item) : item.name,
          optionValue: item.id,
          ancillary: item,
        };
      })[0] ?? null;

      if (selectedOption !== null) {
        formContext.setValue(fieldName, selectedOption);
      }
      doneLoading();
    });
  }

  async function onSearchNeighborhoods(
    search:string,
    statuses:string[],
    onRetrieval?: (options: INeighborhood[]) => Promise<void>,
    onOptionsCreated?: (options: IDropdownOption[]) => Promise<void>,
    optionDescriptionFunc?: (data:INeighborhood) => string,
    marketId?: string | 'skip',
  ) {
    onLoading();
    let dto:INeighborhoodSearchRequest = {
      query: search,
      pageNumber: 0,
      pageSize: 100,
      offset: 0,
      marketId: selectedMarket?.id,
      statuses: statuses,
      ids: [],
    };
    if (marketId && marketId !== 'skip') {
      dto.marketId = marketId;
    } else if (marketId === 'skip') {
      delete dto.marketId;
    }

    debouncedSearchNeighborhoods(dto, async (data) => {
      const page = data as ISearchPage<INeighborhood>;
      if (onRetrieval) {
        await onRetrieval(page.items);
      }
      let options = page.items.filter(x => x.id && x.name).map((item) => {
        return {
          key: item.id!,
          optionText: optionDescriptionFunc ? optionDescriptionFunc(item) : item.name!,
          optionValue: item.id!,
          ancillary: item,
        };
      });
      update({
        ...get(),
        options: options,
      });
      if (onOptionsCreated) {
        await onOptionsCreated(options);
      }
      doneLoading();
    });

  }


  return {
    ...get(),
    onSearchNeighborhoods,
    initWithValue,
  };
}