
import { testReferralFunding } from 'api/userApi';
import { useLoading } from 'components/Layout/Loading';
import useToast from 'components/toast/useToast';
import createStore from 'hooks/hookStore';
import { IReferrerRewardTestingForm } from 'model/referrer_reward_testing/referrer_reward';
import { useEffect, useState } from 'react';

type ReferrerRewardTesting = {

}
const loadingKey = 'ReferrerRewardTesting';

const { get, update, registerListener, unregisterListener } = createStore<ReferrerRewardTesting>('ReferrerRewardTesting', {

});

export default function useReferrerRewardTesting() {
  const setState = useState(get())[1];
  const { onLoading, doneLoading } = useLoading(loadingKey);

  const { createInfoToast, createSuccessToast, createErrorToast } = useToast();

  useEffect(() => {
    registerListener(setState);
    return () => {
      unregisterListener(setState);
    };
  }, []);

  async function init() {
    onLoading();
    update({
      ...get(),
    });
    doneLoading(300);
  }

  async function onReferrerRewardTesting(form: IReferrerRewardTestingForm) {
    onLoading();
    try {
      const res = await testReferralFunding(form);
      createSuccessToast('request received.');
    } catch (err:any) {
      createErrorToast(err.response.data.message);
    }
    doneLoading(300);
  }


  return {
    loadingKey,
    ...get(),
    init,
    onReferrerRewardTesting,
  };
}