import { FormControl, FormHelperText, Grid, InputLabel } from '@mui/material';
import { sendNeighborhoodServiceOfferingInstanceCommunication } from 'api/neighborhoodApi';
import { saveServiceInstance } from 'api/serviceInstanceApi';
import { PrimaryButton, SecondaryButton } from 'components/buttons';
import ConfirmationModal, { ConfirmationModalContextProvider } from 'components/modals/ConfirmationModal';
import { HookSelect, HookTextField } from 'components/reactHookForm';
import HookDatePicker from 'components/reactHookForm/HookDatePicker';
import PageHeader from 'components/SectionHeaders/PageHeader';
import useErrorState from 'hooks/reactHookForm/useErrorState';
import { INeighborhoodCommunicationForm } from 'model/neighborhood';
import { createEmptyServiceInstanceForm, IServiceInstanceForm, NeighborhoodServiceOfferingInstanceCommunicationType, StatusType } from 'model/serviceInstance';
import NeighborhoodCommunicationForm from 'pages/neighborhoods/details/neighborhoodCommunicationForm';
import { useEffect, useState } from 'react';
import { useForm, SubmitHandler, FormProvider, useFormContext } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { formatLocalServiceDate } from 'util/dateUtil';
import useNeighborhoodServiceOfferingInstanceDetail from '../hookStore/useNeighborhoodServiceOfferingInstanceDetail';
import styles from './serviceInstances.module.css';

function FormButtons() {
  const navigate = useNavigate();
  const { neighborhoodServiceOfferingId } = useParams();
  return (
    <>
      <PrimaryButton
        type='submit'
      >
            Save
      </PrimaryButton>
      <SecondaryButton
        onClick={() => {
          navigate(`/neighborhood-service-offering-detail/${neighborhoodServiceOfferingId}/service-instances`);
        }}
      >
            Cancel
      </SecondaryButton>
    </>
  );
}

function ServiceDate() {
  const formCtx = useFormContext();
  const { isError, getErrorMessage } = useErrorState(formCtx);
  return (
    <FormControl variant='standard' fullWidth error={isError('serviceDate')}>
      <HookDatePicker
        label='Service Date'
        name='serviceDate'
        textFieldProps={{ fullWidth: true, variant: 'standard' }}
        required
      />
      <FormHelperText>{getErrorMessage('serviceDate')}</FormHelperText>
    </FormControl>
  );
}
function ServiceEndDateRange() {
  const formCtx = useFormContext();
  const { isError, getErrorMessage } = useErrorState(formCtx);
  return (
    <FormControl variant='standard' fullWidth error={isError('endServiceDate')}>
      <HookDatePicker
        label='End Service Date'
        name='endServiceDate'
        textFieldProps={{ fullWidth: true, variant: 'standard' }}
      />
      <FormHelperText>{getErrorMessage('endServiceDate')}</FormHelperText>
    </FormControl>
  );
}

function StatusTypeSelect() {
  const formCtx = useFormContext();
  const [statusTypes] = useState<any[]>(StatusType.list);
  const { isError, getErrorMessage } = useErrorState(formCtx);
  return (
    <FormControl variant='standard' fullWidth error={isError('status')}>
      <InputLabel htmlFor="status-type">Status</InputLabel>
      <HookSelect
        control={formCtx.control}
        rules={{
          required: { value: true, message: 'Required' },
        }}
        name='status'
        id='status-type'
        menuItems={statusTypes}
      />
      <FormHelperText>{getErrorMessage('status')}</FormHelperText>
    </FormControl>
  );
}

function ServiceInstanceDetailInternal({ initialFormData }:any) {
  const formContext = useForm<IServiceInstanceForm>({ mode: 'onTouched', defaultValues: createEmptyServiceInstanceForm() });
  const { neighborhoodId } = useNeighborhoodServiceOfferingInstanceDetail();
  const navigate = useNavigate();

  const { serviceInstanceId, neighborhoodServiceOfferingId } = useParams();
  const [communicationTemplates] = useState<any[]>(NeighborhoodServiceOfferingInstanceCommunicationType.list);

  useEffect(() => {
    formContext.reset(initialFormData);
  }, [initialFormData]);

  async function _sendNeighborhoodServiceOfferingInstanceCommunication(data: INeighborhoodCommunicationForm) {
    if (serviceInstanceId) {
      await sendNeighborhoodServiceOfferingInstanceCommunication(serviceInstanceId, data.communicationTemplate, data.shouldSendToLoggedInUser, data.overrideEmail);
      navigate(`/neighborhood-service-offering-detail/${neighborhoodServiceOfferingId}/service-instances`);
    }
  }

  async function _save(data:IServiceInstanceForm) {
    const dataToSave = { ...data };
    dataToSave.neighborhoodId = neighborhoodId;
    if (!neighborhoodServiceOfferingId) {
      console.error('neighborhoodServiceOfferingId does not exist.');
      return;
    }
    dataToSave.neighborhoodServiceOfferingId = neighborhoodServiceOfferingId;
    dataToSave.localServiceDate = formatLocalServiceDate(dataToSave.serviceDate);
    try {
      const res = await saveServiceInstance(dataToSave);
      navigate(`/neighborhood-service-offering-detail/${neighborhoodServiceOfferingId}/service-instances`);
    } catch (e:any) {
      console.error(e);
    }
  }

  const onSubmit : SubmitHandler<IServiceInstanceForm> = data => {
    void _save(data);
  };

  return (
    <>
      <FormProvider {...formContext}>
        <form onSubmit={formContext.handleSubmit(onSubmit)}>
          <Grid container spacing={2} className='pageGridContainer'>
            <Grid container item xs={12}>
              <PageHeader headerText={'Manage Service Instance'}/>
            </Grid>
            <Grid item xs={4}>
              <ServiceDate/>
            </Grid>
            <Grid item xs={4}>
              <ServiceEndDateRange/>
            </Grid>
            <Grid item xs={4}>
              <StatusTypeSelect/>
            </Grid>
            <Grid item xs={4}>
              <HookTextField
                name='maxCustomerCount'
                label='Max Customer Count'
                validationRules={{
                  min: { value: 0 || false, message: 'Minimum value of 0' },
                  validate: function(value) {
                    if (value === null || value === '') {
                      return true;
                    }
                    const matched = !!value.toString().match(/^[0-9]{1,10}$/g);
                    if (!matched) {
                      return 'Max customer count must be a positive integer';
                    }
                    return true;
                  },
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <HookTextField
                name='zipCodeTerritory'
                label='Zip Code Territory (TSOI enablement)'
                validationRules={{
                  validate: function(value) {
                    if (value === null || value === '') {
                      return true;
                    }
                    return true;
                  },
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <HookTextField
                name='cutoffDayCount'
                label='Cutoff Day Count'
                validationRules={{
                  min: { value: 0 || false, message: 'Minimum value of 0' },
                  validate: function(value) {
                    if (value === null || value === '') {
                      return true;
                    }
                    const matched = !!value.toString().match(/^[0-9]{1,10}$/g);
                    if (!matched) {
                      return 'Cutoff day count must be a positive integer';
                    }
                    return true;
                  },
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <HookTextField
                name='serviceTypeNameOverride'
                label='Service Type Name Override'
              />
            </Grid>
            <Grid container item xs={12}>
              <FormButtons/>
            </Grid>
          </Grid>
        </form>
      </FormProvider>

      <ConfirmationModalContextProvider onConfirm={(args) => _sendNeighborhoodServiceOfferingInstanceCommunication(args.data) }>
        <NeighborhoodCommunicationForm menuItems={communicationTemplates} neighborhoodServiceOfferingInstanceId={serviceInstanceId}/>
        <ConfirmationModal
          title={(onConfirmArgs) => (<div className={styles.confirmDeleteModalTitle}>Send neighborhood communication?</div>)}
          message={(onConfirmArgs:any) => (
            <Grid container spacing={1} alignItems='center' justifyContent='center' className={styles.confirmDeleteModal}>
              <Grid item xs>
                            Click confirm to send communication.
              </Grid>
            </Grid>
          )}
        />
      </ConfirmationModalContextProvider>

    </>
  );
}

export default function ServiceInstanceDetail() {
  const { init, formData } = useNeighborhoodServiceOfferingInstanceDetail();
  useEffect(() => {
    void init();
  }, []);

  return (
    <ServiceInstanceDetailInternal initialFormData={formData}/>
  );
}