import { Button } from '@mui/material';
import { flushAllCaches } from 'api/cacheApi';
import { useState } from 'react';
import styles from './caching.module.css';

export default function CacheButton() {

  const [animate, setAnimate] = useState<boolean>(false);

  async function flush() {
    await flushAllCaches();
    setTimeout(()=> {
      setAnimate(false);

    }, 3000);
  }
  function onFlushCacheClick() {
    setAnimate(true);
    void flush();
  }

  return (
    <Button
      type='button'
      variant='contained'
      sx={{ width: '300px', height: '200px' }}
      onClick={onFlushCacheClick}
      className={animate ? styles.flush : styles.noflush}
    >
      <>Flush Cache</>
    </Button>
  );
}