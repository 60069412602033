
import { Button, Checkbox, FormControl, FormLabel, Grid, Typography } from '@mui/material';
import { IServiceOfferingImageRef } from 'model/serviceOffering';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

export interface IExistingImage {
  unmarkForDelete: (x:IServiceOfferingImageRef, formCtx:any) => void;
  markForDelete: (x:IServiceOfferingImageRef, formCtx:any) => void;
  toggleForHardDelete: (x:IServiceOfferingImageRef, hardDelete:boolean) => void;
  imageWrapper: IServiceOfferingImageRef;
}

export default function ExistingImage({ unmarkForDelete, toggleForHardDelete, markForDelete, imageWrapper }:IExistingImage) {
  const [markedForDelete, setMarkedForDelete] = useState<boolean>(false);
  const [hardDelete, setHardDelete] = useState<boolean>(false);
  const formCtx = useFormContext();
  function onClick() {
    if (markedForDelete) {
      setMarkedForDelete(false);
      unmarkForDelete(imageWrapper, formCtx);
    } else {
      setMarkedForDelete(true);
      markForDelete(imageWrapper, formCtx);
    }
  }
  return (
    <Grid container item xs='auto' alignItems='center' sx={{ border: '1px solid gainsboro', padding: '4px', margin: '2px' }}>
      <img
        src={`${window.REACT_APP_SERVICE_OFFERING_IMAGE_URI}${imageWrapper.thumbnailImageFileName}`}
        width={200}
      />
      <Grid container xs='auto' justifyContent={'center'} sx={{ paddingLeft: '4px' }}>
        <Grid item xs={10} sx={{ paddingLeft: '10px' }}>
          <Button type='button' variant='outlined' onClick={onClick}>{markedForDelete ? 'Undelete' : 'Delete'}</Button>
        </Grid>
        <Grid container item xs={10} alignItems={'center'}>
          <Grid item xs='auto'>
            <Checkbox
              checked={hardDelete}
              onChange={(e) => {
                setHardDelete(e.target.checked);
                toggleForHardDelete(imageWrapper, e.target.checked);
              }}
            />
          </Grid>
          <Grid item xs='auto' >
            <Typography component='span'>Hard Delete</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}