import { Grid, Typography } from '@mui/material';
import { IInvoiceCustomerRowDTO } from 'model/invoicing';
import { formatUSDTwoDecimal } from 'util/currencyUtil';

export interface ISelectedRowsTotalsProps {
  selectedRows: IInvoiceCustomerRowDTO[];
}
export default function SelectedRowsTotals({ selectedRows }:ISelectedRowsTotalsProps) {
  const priceTotal = selectedRows.reduce((acc, cur) => acc + (cur.totalPrice ?? 0), 0);
  const unitPriceTotal = selectedRows.reduce((acc, cur) => acc + (cur.unitPrice ?? 0), 0);
  return (
    <Grid container justifyContent={'center'} sx={{ margin: '0 0px 20px 0px', padding: '8px', border: '1px solid gainsboro' }}>
      <Grid justifyContent={'center'} item xs='auto'>
        <Typography variant='caption'>Select rows to see totals</Typography>
      </Grid>
      <Grid item xs={12}></Grid>
      <Grid container item xs={3} justifyContent={'center'}>
        <Typography variant='subtitle1'>Price Total: {formatUSDTwoDecimal(priceTotal, 'N/A')}</Typography>
      </Grid>
      <Grid container item xs={3} justifyContent={'center'}>
        <Typography variant='subtitle1'>Unit Price Total: {formatUSDTwoDecimal(unitPriceTotal, 'N/A')}</Typography>
      </Grid>
    </Grid>
  );
}