import Axios, { AxiosResponse } from 'axios';
import { ICreateLookUpOptionRequest } from 'model/lookup/lookup';
import { INeighborhoodCampaignTemplateKey } from 'model/lookup/neighborhoodCampaignTemplateKey';

const apiUrl = window.REACT_APP_BASE_API_URI;
const baseUrl = `${apiUrl}/neighborhoodCampaignTemplateKeys/v2`;

export function findAllNeighborhoodCampaignTemplateKeys():Promise<AxiosResponse<INeighborhoodCampaignTemplateKey[]>> {
  return Axios.get(`${baseUrl}/findAll`);
}

export function createNeighborhoodCampaignTemplateKeyOption(data: ICreateLookUpOptionRequest) {
  return Axios.post(`${baseUrl}/createNeighborhoodCampaignTemplateKey`, data);
}