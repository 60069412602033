import LinkIcon from '@mui/icons-material/Link';
import { IconButton, Tooltip } from '@mui/material';

interface IGenerateLinkButtonProps {
  tooltipTitle:string;
  onClick:any;
  className?:string;
  disabled?:boolean;
  iconButtonProps?:any;
  tooltipProps?:any;
}
export default function GenerateLinkButton({ tooltipTitle, onClick, className, disabled, tooltipProps, iconButtonProps }:IGenerateLinkButtonProps) {
  return (

    <>
      {disabled && <LinkIcon opacity={0.3}/>}
      {!disabled && (
        <Tooltip title={tooltipTitle} className={className} {...tooltipProps}>
          <IconButton onClick={onClick} disabled={disabled} {...iconButtonProps}>
            <LinkIcon/>
          </IconButton>
        </Tooltip>
      )}
    </>
  );
}