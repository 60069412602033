import { FormControl, FormHelperText } from '@mui/material';
import HookDatePicker from 'components/reactHookForm/HookDatePicker';
import useErrorState from 'hooks/reactHookForm/useErrorState';
import { useFormContext } from 'react-hook-form';


export default function PriorCustomerStartDate() {
  const formCtx = useFormContext();
  const { isError, getErrorMessage } = useErrorState(formCtx);
  return (
    <FormControl variant='standard' fullWidth error={isError('priorCustomerStartDate')}>
      <HookDatePicker
        label='Start Date'
        name='priorCustomerStartDate'
        textFieldProps={{ fullWidth: true, variant: 'standard' }}
        required
        datePickerProps={{
          clearable: true,
          onAccept: () => {},
        }}
      />
      <FormHelperText>{getErrorMessage('priorCustomerStartDate') ?? 'Start date of range to find customers who booked service with source service provider'}</FormHelperText>
    </FormControl>
  );
}