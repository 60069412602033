
import { FormControl, FormHelperText } from '@mui/material';
import { HookSingleAutocomplete } from 'components/reactHookForm';
import useErrorState from 'hooks/reactHookForm/useErrorState';
import { useFormContext } from 'react-hook-form';
import useProviderCoverageArea from '../hookStore/useProviderCoverageArea';
import ServiceProviderAutocomplete from 'components/serviceProviderAutocomplete/ServiceProviderAutocomplete';


export interface IServiceProviderSelectProps {

}

export default function ServiceProviderSelect({ }:IServiceProviderSelectProps) {
  const { getServiceProviderCoverageAreas, clearForm } = useProviderCoverageArea();
  const formCtx = useFormContext();
  const { isError, getErrorMessage } = useErrorState(formCtx);
  let rules:any = {
    required: {
      value: true, message: 'Required',
    },
  };

  return (
    <FormControl variant='standard' fullWidth error={isError('serviceProviderId')}>
      <ServiceProviderAutocomplete
        required
        fieldName='serviceProviderId'
        label={'Service Providers'}
        formless={false}
        placeholder='Search and check service provider'
        postOnChange={(formContext, value, reason, details) => {
          if (value?.optionValue) {
            void getServiceProviderCoverageAreas(formContext, value, value.ancillary.marketId);
          } else {
            clearForm(formContext);
          }
        }}
      />
      <FormHelperText>{getErrorMessage('serviceProviderId') || ' '}</FormHelperText>
    </FormControl>
  );
}