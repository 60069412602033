import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { IconButton, Tooltip } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';

interface IRefreshButtonProps {
  tooltipTitle:string;
  onClick:any;
  className?:string;
  disabled?:boolean;
  iconButtonProps?:any;
  tooltipProps?:any;
}
export default function RefreshButton({ tooltipTitle, onClick, className, disabled, tooltipProps, iconButtonProps }:IRefreshButtonProps) {
  return (
    <Tooltip title={tooltipTitle} className={className} {...tooltipProps}>
      <IconButton onClick={onClick} disabled={disabled} {...iconButtonProps}>
        <RefreshIcon/>
      </IconButton>
    </Tooltip>
  );
}