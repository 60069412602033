import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button, Grid, IconButton } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { getNeighborhoodById } from 'api/neighborhoodApi';
import { findNeighborhoodServiceOfferingById } from 'api/neighborhoodServiceOfferingApi';
import { deleteServiceInstance, findByNeighborHoodServiceOffering } from 'api/serviceInstanceApi';
import { EditButton, RemoveButton } from 'components/buttons';
import CopyToClipboardCell from 'components/DataGridCell/CopyToClipboard';
import ConfirmationModal, { ConfirmationModalContext, ConfirmationModalContextProvider } from 'components/modals/ConfirmationModal';
import PageHeader from 'components/SectionHeaders/PageHeader';
import useToast from 'components/toast/useToast';
import formatDate from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { createDateIgnoreTimezone } from 'util/dateUtil';
import styles from './serviceInstances.module.css';

function compare(a: any, b: any) {
  return a.name > b.name ? 1 : b.name > a.name ? -1 : 0;
}

function DeleteCell({ id, name }) {
  const confirmationModalCtx = useContext(ConfirmationModalContext);
  return (
    <RemoveButton
      tooltipTitle='Remove service offering'
      onClick={() => confirmationModalCtx.openModal({ id, name })}
      tooltipProps={{
        placement: 'top',
      }}
      iconButtonProps={{
        className: styles.actionButton,
      }}
    />
  );
}

function EditCell({ id, name }) {
  const { neighborhoodServiceOfferingId } = useParams();
  const navigate = useNavigate();
  function handleClick() {
    navigate(`/neighborhood-service-offering-detail/${neighborhoodServiceOfferingId}/service-instance-details/${id}`);
  }
  return (
    <EditButton
      tooltipTitle={`Edit ${name}`}
      onClick={handleClick}
      tooltipProps={{
        placement: 'top',
      }}
      iconButtonProps={{
        className: styles.actionButton,
      }}
    />
  );
}


export default function ServiceInstances() {
  const navigate = useNavigate();
  const { createErrorToast } = useToast();
  const { neighborhoodServiceOfferingId } = useParams();
  const [nsoInstances, setNsoInstances] = useState([]);
  const [nsoName, setNsoName] = useState('');
  const [neighborhood, setNeighborhood] = useState('');

  const [sortModel, setSortModel] = useState([
    {
      field: 'name',
      sort: 'asc' as any,
    },
  ]);

  const columns: GridColDef[] = [
    {
      field: 'serviceDate',
      headerName: 'Service Date (MM/dd/yyyy)',
      width: 500,
      renderCell: (params: any) => {
        let formattedDate = 'No Date Set';
        if (params.row.serviceDate) {
          formattedDate = formatDate(createDateIgnoreTimezone(params.value), 'MM/dd/yyyy');
        }
        return <div>{formattedDate}</div>;
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 500,
      renderCell: (params:any) => {
        return (<div>{params.value}</div>);
      },
    },
    {
      field: 'id',
      headerName: 'GUID',
      width: 500,
      renderCell: (params:any) => {
        return (<CopyToClipboardCell val={params.id}/>);
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      renderCell: (params:any) => {
        let formattedDate = '';
        if (params.row.serviceDate) {
          formattedDate = formatDate(parseISO(params.row.serviceDate), 'MM/dd/yyyy');
        }
        return (
          <Grid container item xs={12} justifyContent='center' alignItems='center'>
            <EditCell id={params.id} name={formattedDate}/>
            <DeleteCell id={params.id} name={formattedDate}/>
          </Grid>
        );
      },
    },
  ];
  const columnTotalWidth = columns.map(x => x.width).reduce((a:any, b:any) => a+b, 0);

  async function init() {
    if (neighborhoodServiceOfferingId) {
      const nsoRes = await findNeighborhoodServiceOfferingById(neighborhoodServiceOfferingId);
      setNsoName(nsoRes.data.name);
      const neighborhoodRes = await getNeighborhoodById(nsoRes.data.neighborhoodId);
      setNeighborhood(neighborhoodRes.data.name);
      const res = await findByNeighborHoodServiceOffering(neighborhoodServiceOfferingId);
      setNsoInstances(res.data);
    }
  }

  useEffect(() => {
    void init();
  }, []);

  const _deleteServiceInstance = useCallback(async ({ id }) => {
    try {
      if (id) {
        const res = await deleteServiceInstance(id);
        await init();
      }
    } catch (e:any) {
      console.error(`Unable to delete neighborhood service offering instance. Message=${e.response?.data?.message}`);
      createErrorToast(`Unable to delete neighborhood service offering instance. Message=${e.response?.data?.message}`);
    }
  }, []);

  const addNewButtonClick = () => {
    navigate(`/neighborhood-service-offering-detail/${neighborhoodServiceOfferingId}/service-instance-details`);
  };

  return (
    <>
      <ConfirmationModalContextProvider onConfirm={_deleteServiceInstance}>
        <Grid container justifyContent='center' className='pageGridContainer'>
          <Grid item xs={12}>
            <PageHeader headerText={`Service Instances for ${nsoName}`} >
            </PageHeader>
          </Grid>

          <Grid container item xs={12} className={styles.serviceInstancesGrid}>
            <Grid item xs={12}>
              <IconButton onClick={() => navigate('/neighborhood-service-offerings')}>
                <ArrowBackIcon/>
              </IconButton>
              <Button variant="contained" onClick={addNewButtonClick}>Add New</Button>
            </Grid>
            {nsoInstances.length > 0 && (
              <DataGrid
                rows={nsoInstances}
                columns={columns}
                sortModel={sortModel}
                onSortModelChange={setSortModel}
                autoHeight={true}
                style={{
                  minWidth: '500px',
                  maxWidth: `${columnTotalWidth}px`,
                }}
              />
            )}
            {nsoInstances.length === 0 && <div>No service instances yet</div>}
          </Grid>
        </Grid>
        <ConfirmationModal
          title={(onConfirmArgs) => (<div className={styles.confirmDeleteModalTitle}>Delete service instance</div>)}
          message={(onConfirmArgs:any) => (
            <Grid container spacing={1} alignItems='center' justifyContent='center' className={styles.confirmDeleteModal}>
              <Grid item xs>
                        You are about to delete {onConfirmArgs.name} for {nsoName}.
              </Grid>
            </Grid>
          )}
        />
      </ConfirmationModalContextProvider>
    </>
  );
}