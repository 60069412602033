import useNeighborhoodSeedingToolingStore from './useNeighborhoodSeedingToolingStore';
import { AddButton } from 'components/buttons';
import { seedCustomer } from 'api/popularityApi';
import useToast from 'components/toast/useToast';
import { useLoading } from 'components/Layout/Loading';
import { loadingKey } from './NeighborhoodSeedingTable';

export default function AddSeed({ params }) {
  const { selectedNeighborhood, tableRows, refreshTable } = useNeighborhoodSeedingToolingStore();
  const { createErrorToast } = useToast();
  const { onLoading, doneLoading } = useLoading(loadingKey);

  async function handleClick() {
    try {
      onLoading();
      await seedCustomer(params.providerId, selectedNeighborhood.id!, params.serviceTypeId, 1);
      // The response won't include the updated count due to performance reasons.
      // We are still going to increment the count locally to inform the user that the action was successful.
      const indexOfRow = tableRows.findIndex((row) => row.providerId === params.providerId && row.serviceType === params.serviceTypeId);

      tableRows[indexOfRow].totalCustomers += 1;
      await refreshTable();
      doneLoading();
    } catch (error) {
      doneLoading();
      console.error('error seeding customer', { error, params });
      createErrorToast(`Error seeding customer: ${error}`);
    }
  }

  return (
    <AddButton
      tooltipTitle={'Add seed data'}
      onClick={handleClick} />
  );
}
