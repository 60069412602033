import { launchProvidersByCoverageAreas, reconcileNSOsByServiceProvider } from 'api/neighborhoodLaunchApi';
import { findServiceOfferingsByProvider } from 'api/serviceOfferingApi';
import { findInternalServiceProviderById } from 'api/serviceProviderApi';
import { useLoading } from 'components/Layout/Loading';
import useToast from 'components/toast/useToast';
import createStore from 'hooks/hookStore';
import { ICheckable } from 'model/ancillary';
import { createEmptyLaunchProvidersResult, createEmptyProviderCoverageAreaLaunchToolingForm, ILaunchProvidersResult, IProviderCoverageAreaLaunchToolingForm, IServiceProviderLaunchDTO } from 'model/neighborhoodLaunch';
import { IServiceOffering } from 'model/serviceOffering';
import { IInternalServiceProvider } from 'model/serviceProvider';
import { useEffect, useState } from 'react';

const loadingKey = 'ProviderCoverageAreaLaunchToolingStore';
type ProviderCoverageAreaLaunchToolingStore = {
  formData:IProviderCoverageAreaLaunchToolingForm;
  lastReconciled: Date | null;
  serviceProviderToServiceOfferingsMap:Map<string, IServiceOffering[]>;
  launchProvidersResult:ILaunchProvidersResult;
}

const { get, update, registerListener, unregisterListener } = createStore<ProviderCoverageAreaLaunchToolingStore>('ProviderCoverageAreaLaunchToolingStore', {
  formData: createEmptyProviderCoverageAreaLaunchToolingForm(),
  lastReconciled: null,
  serviceProviderToServiceOfferingsMap: new Map<string, IServiceOffering[]>(),
  launchProvidersResult: createEmptyLaunchProvidersResult(),
});

export default function useProviderCoverageAreaLaunchToolingStore() {
  const setState = useState(get())[1];
  const { onLoading, doneLoading } = useLoading(loadingKey);
  const { createInfoToast, createErrorToast } = useToast();

  useEffect(() => {
    registerListener(setState);
    return () => {
      unregisterListener(setState);
    };
  }, []);

  async function init() {
    onLoading();
    try {
      update({
        ...get(),

      });
    } catch (e:any) {
      console.error(e);
    }
    doneLoading(300);
  }

  async function save(data:IProviderCoverageAreaLaunchToolingForm) {
    const dataToSave = { ...data };
    onLoading();
    dataToSave.serviceProviderLaunchDtos = dataToSave.serviceProviderLaunchDtos.map(x => {
      let toReturn = {
        ...x,
        neighborhoodIds: [],
        serviceOfferingIds: x.serviceOfferingOptions.filter(y => y && y.checked && y.value).map(z => z.value as string),
      };
      toReturn.serviceOfferingOptions = [];
      return toReturn;
    });
    try {
      const res = await launchProvidersByCoverageAreas(dataToSave);
      createInfoToast('Provider Launch received and processing. You will be emailed results.', null, { persistent: true });
      doneLoading(200);
    } catch (err:any) {
      console.error(err);
      doneLoading(200);
    }
  }

  async function onSelectProvider(id:string) {
    try {
      const res = await findInternalServiceProviderById(id);
      const serviceProvider = res.data;
      update({
        ...get(),
        lastReconciled: serviceProvider.lastReconciled,
      });
    } catch (e:any) {
      console.error(e);
    }
  }

  function clearLastReconciled() {
    update({
      ...get(),
      lastReconciled: null,
    });
  }


  // takes getValues and setValue from formContext as parameters which is used to retrieve and set data on the card using the index
  async function populateLaunchCard(getValues, setValue, index) {
    const { serviceProviderToServiceOfferingsMap } = get();
    const launchDto:IServiceProviderLaunchDTO = getValues(`serviceProviderLaunchDtos.${index}`);
    const serviceOfferingsRes = await findServiceOfferingsByProvider(launchDto.serviceProviderId);
    if (serviceOfferingsRes.data) {
      serviceProviderToServiceOfferingsMap.set(launchDto.serviceProviderId, serviceOfferingsRes.data);
      let checkableServiceOfferings = convertServiceOfferingsForChecklist(serviceOfferingsRes.data);
      setValue(`serviceProviderLaunchDtos.${index}.serviceOfferingOptions`, checkableServiceOfferings);
    }
    update({
      ...get(),
      serviceProviderToServiceOfferingsMap,
    });
  }

  function convertServiceOfferingsForChecklist(data:IServiceOffering[]):ICheckable[] {
    return data.map(x => {
      const hasCoverageArea = x.providerCoverageAreaId !== '' && x.providerCoverageAreaId !== null;
      return {
        id: x.id ?? '',
        checked: hasCoverageArea && (x.defaultForServiceType ?? false),
        displayValue: x.name ?? '',
        value: x.id ?? '',
        hasCoverageArea,
      };
    });
  }

  async function onReconcileNSOsByServiceProvider(data: IProviderCoverageAreaLaunchToolingForm) {
    try {
      onLoading();
      if (data.serviceProviderId !== null) {
        var response = await reconcileNSOsByServiceProvider({ serviceProviderId: data.serviceProviderId.optionValue, dryRun: data.dryRun });
      }
      doneLoading();
      createInfoToast(`${data.dryRun ? 'Dry Run: ' : ''}Reconciling NSOs. You will receive an email with the results.`, null, { persistent: true });
    } catch (e: any) {
      console.error(e);
      createErrorToast(`Request for NSO reconciliation failed with message: ${e.response.data.message}`, null, { persistent: true });
    }
  }

  return {
    ...get(),
    loadingKey,
    init,
    save,
    populateLaunchCard,
    onReconcileNSOsByServiceProvider,
    onSelectProvider,
    clearLastReconciled,
  };
}