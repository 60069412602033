import { Grid, TextField } from '@mui/material';
import HookConfirmationModal from 'components/modals/HookConfirmationModal';
import useToast from 'components/toast/useToast';
import { ReactNode, useState } from 'react';
import styles from './confirmationModal.module.css';

export interface IConfirmationModalProps {
  modalKey:string;
  title:string;
  body:string | ReactNode;
  successText?:string;
  onConfirm: (onConfirmArgs:any) => Promise<any>;
}

export default function ConfirmationModal({ modalKey, title, body, successText, onConfirm }:IConfirmationModalProps) {
  const { createSuccessToast } = useToast();
  return (
    <HookConfirmationModal
      modalKey={modalKey}
      title={() => (<div className={styles.confirmModalTitle}>{title}</div>)}
      message={(confirmArgs, cancelModal) => (
        <Grid container spacing={1} alignItems='center' justifyContent='center' className={styles.confirmationModalBody}>
          <Grid item xs={12}>
            {body}
          </Grid>
        </Grid>
      )}
      onConfirm={async (data) => {
        await onConfirm(data);
        createSuccessToast(successText ?? 'Save successful');
      }}
    />
  );
}