
import EnumSelect from './EnumSelect';
import { Grid } from '@mui/material';
import { CampaignType, NeighborhoodLaunchType } from 'model/letter_batch/ancillary';
import { useFormContext, useWatch } from 'react-hook-form';

export default function LaunchTypeSelect() {
  const formContext = useFormContext();
  const val = useWatch({ name: 'campaignType', control: formContext.control });

  if (val === CampaignType.LAUNCH) {
    return (
      <Grid item xs={12} sm={6} md={4}>
        <EnumSelect
          label='Launch Type'
          fieldName='launchType'
          list={NeighborhoodLaunchType.list}
        />
      </Grid>
    );
  }
  return null;
}