import { StaticDateRangePicker } from '@mui/x-date-pickers-pro/StaticDateRangePicker';
import { DateRange } from '@mui/x-date-pickers-pro/DateRangePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { addWeeks, startOfWeek, endOfWeek, startOfToday } from 'date-fns';
import { useState } from 'react';
import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import useTsoiManagementNewBatch from '../hookStore/useTsoiManagementNewBatch';
import styles from '../tsoiManagement.module.css';

// interface exposed in v6 of @mui/x-date-pickers-pro
// that version isn't included in our license though
// so we're going to go ahead and just define it here
interface PickersShortcutsItem {
  label: string;
  getValue: () => [Date|null, Date|null];
};

const shortcutsItems: PickersShortcutsItem[] = [
  {
    label: 'Next Week',
    getValue: () => {
      const today = startOfToday();
      const startOfThisWeek = startOfWeek(today);
      const startOfNextWeek = addWeeks(startOfThisWeek, 1);
      const endOfNextWeek = endOfWeek(startOfNextWeek);

      return [startOfNextWeek, endOfNextWeek];
    },
  },
  {
    label: 'Two Weeks Out',
    getValue: () => {
      const today = startOfToday();
      const startOfThisWeek = startOfWeek(today);
      const startOfTwoWeeksOut = addWeeks(startOfThisWeek, 2);
      const endOfTwoWeeksOut = endOfWeek(startOfTwoWeeksOut);

      return [startOfTwoWeeksOut, endOfTwoWeeksOut];
    },
  },
  {
    label: 'Three Weeks Out',
    getValue: () => {
      const today = startOfToday();
      const startOfThisWeek = startOfWeek(today);
      const startOfThreeWeeksOut = addWeeks(startOfThisWeek, 3);
      const endOfThreeWeeksOut = endOfWeek(startOfThreeWeeksOut);

      return [startOfThreeWeeksOut, endOfThreeWeeksOut];
    },
  },
  {
    label: 'Four Weeks Out',
    getValue: () => {
      const today = startOfToday();
      const startOfThisWeek = startOfWeek(today);
      const startOfFourWeeksOut = addWeeks(startOfThisWeek, 4);
      const endOfFourWeeksOut = endOfWeek(startOfFourWeeksOut);

      return [startOfFourWeeksOut, endOfFourWeeksOut];
    },
  },
  { label: 'Reset', getValue: () => [null, null] },
];

export default function BatchDateRangePicker() {
  const { dateRange, setDateRange } = useTsoiManagementNewBatch();

  return (
    <Grid container flexDirection={'row'} justifyContent={'space-between'}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Grid container item xs={2} flexDirection={'column'} justifyContent={'space-evenly'}>
          <Typography variant="h6" fontWeight={'bold'}>
                Quick Select:
          </Typography>
          { shortcutsItems.map((item) => <Button
            variant={'contained'}

            sx={{ borderRadius: '15%' }}
            onClick={() => setDateRange(item.getValue())}
            key={item.label}
            className={styles.quickSelectButton}
          >
            {item.label}
          </Button>) }
        </Grid>
        <Grid container item xs={8} paddingTop={'50px'}>
          <StaticDateRangePicker
            disablePast
            value={dateRange}
            displayStaticWrapperAs='desktop'
            onChange={(newValue) => {
              setDateRange(newValue);
            }}
            renderInput={(startProps, endProps) => (
              <>
                <TextField {...startProps} />
                <Box sx={{ mx: 2 }}> to </Box>
                <TextField {...endProps} />
              </>
            )}
          />
        </Grid>
      </LocalizationProvider>;
    </Grid>
  );
};