import { Grid, Paper, Typography } from '@mui/material';

import { useEffect } from 'react';
import { useForm, FormProvider, useFormContext } from 'react-hook-form';
import HookConfirmationModal from 'components/modals/HookConfirmationModal';
import useMarkets from 'hooks/useMarkets';
import { HookIntegerField, HookTextField } from 'components/reactHookForm';
import { useNavigate } from 'react-router-dom';
import { SecondaryButton } from 'components/buttons';
import useToast from 'components/toast/useToast';
import useLetterBatchDetail from './hookStore/useLetterBatchDetail';
import { ILetterBatchFormDto, createEmptyLetterBatchFormDto } from 'model/letter_batch/letterBatch';
import SubmitButton from './components/SubmitButton';
import HookDatePicker from 'components/reactHookForm/HookDatePicker';
import MarketSelect from './components/MarketSelect';
import PageHeader from 'components/SectionHeaders/PageHeader';
import StickyFormButtons from './components/StickyFormButtons';
import ExportDate from './components/ExportDate';
import MarkSent from './components/MarkSent';

export interface ILetterBatchFormProps {
  isNew:boolean;
}

export interface ILetterBatchFormInternalProps {
  initialFormData: ILetterBatchFormDto;
  isNew:boolean;
}

export const letterBatchDetailModalKey = 'letterBatchDetailModalKey';

function ModalWrapper() {
  const { getValues, control } = useFormContext();
  const { onSave } = useLetterBatchDetail();
  const { createErrorToast } = useToast();

  const navigate = useNavigate();
  return (
    <HookConfirmationModal
      title={() => 'Confirm Letter Batch'}
      body='Save changes?'
      modalKey={letterBatchDetailModalKey}
      message={(confirmArgs, cancelModal) => (
        <Grid container spacing={1} alignItems='center' justifyContent='center' sx={{ paddingBottom: '20px' }}>
          <Grid item xs={12}>
            <Typography variant='body1'>Are you sure you want to save?</Typography>
          </Grid>
        </Grid>
      )}
      onConfirm={async (data) => {
        try {
          await onSave(data);
        } catch (err:any) {
          createErrorToast('Unable to save letter batch');
        }
      }}

    />
  );
}

function LetterBatchFormInternal({ initialFormData, isNew }:ILetterBatchFormInternalProps) {
  const { marketName } = useLetterBatchDetail();
  const formContext = useForm<ILetterBatchFormDto>({
    mode: 'onChange',
    defaultValues: createEmptyLetterBatchFormDto(),
  });
  useEffect(() => {
    formContext.reset(initialFormData);
  }, [initialFormData]);


  return (
    <FormProvider {...formContext}>
      <form >
        <Grid container sx={{
          '&&&': {
            position: 'relative',
          },
        }}>
          <StickyFormButtons initialFormData={initialFormData}/>
          <Grid container spacing={2} className='pageGridContainer' alignItems={'start'}>
            <Grid item xs={12}>
              <PageHeader headerText={'Letter Batch Details'} />
            </Grid>
            {!isNew && (
              <Grid container item spacing={1}>
                <Grid item xs={12} sm={'auto'}>
                  <Typography variant='body1'>Market: {marketName}</Typography>
                </Grid>
                <Grid item xs={12} sm={'auto'} >
                  <Typography variant='body1' sx={{ paddingLeft: '40px' }}>Home Count in Batch: {initialFormData.homeCount}</Typography>
                </Grid>
              </Grid>
            )}
            {!isNew && (
              <Grid item xs={12}>
                <ExportDate/>
              </Grid>
            )}
            {isNew && (
              <Grid item xs={12} sm={6} md={2}>
                <MarketSelect/>
              </Grid>
            )}
            <Grid item xs={12} sm={6} md={2}>
              <HookTextField name='batchName' label='Batch Name' required/>
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <HookTextField name='externalBatchId' label='Batch Number'/>
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <HookDatePicker name='sendDate' label='Send Date' required
                dateFormats={{
                  fullDate: 'yyyy-MM-dd',
                }}/>
            </Grid>
          </Grid>

          <ModalWrapper />
        </Grid>
      </form>
    </FormProvider>
  );
}

export default function LetterBatchDetailForm({ isNew }: ILetterBatchFormProps) {
  const { formData, init } = useLetterBatchDetail();

  const { markets } = useMarkets();
  useEffect(() => {
    if (markets.length === 0) {
      return;
    }
    void init(isNew, markets);
  }, [isNew, markets]);
  return (
    <LetterBatchFormInternal initialFormData={formData} isNew={isNew}/>
  );
}