import { Grid, Typography } from '@mui/material';
import Loading from 'components/Layout/Loading';
import ConfirmationModal, { ConfirmationModalContext, ConfirmationModalContextProvider } from 'components/modals/ConfirmationModal';
import PageHeader from 'components/SectionHeaders/PageHeader';
import { useContext } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import styles from './userUpload.module.css';
import UserUploadForm from './userUploadForm';
import useUserReconciliation from './userUploadHookStore';

export default function UserReconciliation() {
  const { onUpload, loadingKey } = useUserReconciliation();

  return (
    <ConfirmationModalContextProvider onConfirm={(args) => onUpload(args)}>
      <Loading loadingKey={loadingKey} size={100}>
        <Grid container spacing={2} className='pageGridContainer'>
          <Grid item xs={12}>
            <PageHeader headerText={'User Reconciliation'} />
          </Grid>
          <Grid item xs={12}>
            <UserUploadForm/>
          </Grid>
        </Grid>
      </Loading>
      <ConfirmationModal
        title={(onConfirmArgs) => (<div className={styles.confirmDeleteModalTitle}>Upload users</div>)}
        message={(onConfirmArgs:any) => {
          return (
            <Grid container spacing={1} alignItems='center' justifyContent='center' className={styles.confirmModal}>
              <Grid item xs>
                                You are about to upload {onConfirmArgs.fileName}. You will receive an email with results when complete.
              </Grid>
            </Grid>
          );
        }}
      />
    </ConfirmationModalContextProvider>
  );
}