import { Grid, Button, Paper } from '@mui/material';
import { HookCheckbox } from 'components/reactHookForm';
import PageHeader from 'components/SectionHeaders/PageHeader';
import ProviderLogo from 'components/visual/ProviderLogo';
import { ServiceProviderFormContextProvider } from 'contexts/serviceProvider/useServiceProviderForm';
import {
  IInternalServiceProvider,
  createEmptyInternalServiceProvider,
} from 'model/serviceProvider';
import { useEffect } from 'react';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import AboutProviderInfo from './formComponents/AboutProviderInfo';
import AcceptedPaymentTypes from './formComponents/AcceptedPaymentTypes';
import CompanyImageDropzone from './formComponents/CompanyImageDropzone';
import ContactInfo from './formComponents/ContactInfo';
import MainInfo from './formComponents/MainInfo';
import ProviderIds from './formComponents/ProviderIds';
import RatingInfo from './formComponents/RatingInfo';
import FacebookRatingInfo from './formComponents/FacebookRatingInfo';
import useMarkets from 'hooks/useMarkets';
import FormButtons from './formComponents/FormButtons';
import useServiceProviderDetail from './hookStore/useServiceProviderDetails';
import { useParams } from 'react-router-dom';
import ServiceSummaryActions from './serviceSummaryActions/ServiceSummaryActions';
import NumbersOnDoNotCallListAlertModal, { modalStoreKey } from '../../../components/ServiceProviderModals/NumbersOnDoNotCallListAlertModal';
import useModal from 'components/modal/useModal';
import Loading, { useLoading } from 'components/Layout/Loading';
import useToast from 'components/toast/useToast';
import CheckProviderUsers from './CheckProviderUsers';
import AdditionalRelevantPlatformRatings from './formComponents/AdditionalRelevantPlatformRatings';


export interface IServiceProviderDetailsFormInternalProps {
  initialFormData: IInternalServiceProvider;
}

function ServiceProviderDetailsFormInternal({
  initialFormData,
}) {

  const { serviceProviderId } = useParams();
  const { openModal } = useModal(modalStoreKey);

  const formContext = useForm<IInternalServiceProvider>({ mode: 'onTouched', defaultValues: createEmptyInternalServiceProvider() });
  const { onSaveServiceProvider, maybeCheckNumbersAgainstDNCList, loadingKey } = useServiceProviderDetail();
  const { onLoading, doneLoading } = useLoading(loadingKey);
  const { createWarnToast } = useToast();

  useEffect(() => {
    formContext.reset(initialFormData);
  }, [initialFormData]);

  const watchImageUrlGuid = formContext.watch('imageUrlGuid');

  const onSubmit : SubmitHandler<IInternalServiceProvider> = async data => {
    onLoading();
    var invalidNumbersResp = await maybeCheckNumbersAgainstDNCList(data)
      .catch((_e) => {
        createWarnToast('Failed to check numbers against DNC list. Saving without checking.');
        return [];
      });
    if (invalidNumbersResp && invalidNumbersResp.length > 0) {
      openModal({
        flaggedNumbers: invalidNumbersResp,
        providerName: data.name,
        onConfirmCallback: () => {
          void onSaveServiceProvider(data);
        },
      });
    } else {
      void onSaveServiceProvider(data);
    }
    doneLoading();
  };

  return (
    <Loading loadingKey={loadingKey}>
      <ServiceProviderFormContextProvider>
        <FormProvider {...formContext}>
          <form onSubmit={formContext.handleSubmit(onSubmit)}>
            <Grid container sx={{
              '&&&': {
                position: 'relative',
              },
            }}>
              <Grid container >

                <Paper
                  style={{
                    position: 'sticky',
                    top: 0,
                    padding: '20px 10px 20px 10px',
                    zIndex: 1,
                    width: '100%',
                    boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2)',
                  }}
                  elevation={0}
                >
                  <Grid container item xs={12} justifyContent={'flex-end'}>
                    <Grid item xs='auto' >
                      <FormButtons/>
                    </Grid>
                  </Grid>
                </Paper>
                <Grid container item xs={12} sx={{ padding: '0 16px 0 16px' }}>
                  <Grid container item xs={12}>
                    <PageHeader headerText={'Manage service provider'}/>
                  </Grid>
                  <Grid item xs={12} sx={{ paddingTop: '20px' }}></Grid>
                  <MainInfo/>
                  {serviceProviderId && (
                    <Grid container item xs={12} justifyContent={'flex-end'}>
                      <Grid item xs='auto'>
                        <CheckProviderUsers/>
                      </Grid>
                    </Grid>
                  )}
                  <ContactInfo/>
                  <ProviderIds/>
                  <AboutProviderInfo />
                  <RatingInfo/>
                  <FacebookRatingInfo/>
                  <AdditionalRelevantPlatformRatings />
                  <AcceptedPaymentTypes/>
                  <CompanyImageDropzone/>
                  <Grid item xs={3}>
                    <HookCheckbox
                      label='Is an image required?'
                      name='isImageRequired'
                      defaultValue={true}
                    />
                  </Grid>
                  <Grid container item xs={12}>
                    {watchImageUrlGuid && (
                      <>
                        <ProviderLogo imageUrlGuid={watchImageUrlGuid}
                          style={{
                            maxHeight: '65px',
                            maxWidth: '250px',
                          }}
                        />
                        <Button type='button' onClick={() => formContext.setValue('imageUrlGuid', '')}>Clear image</Button>
                      </>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
        {serviceProviderId && (
          <>
            <ServiceSummaryActions serviceProviderId={serviceProviderId}/>
          </>
        )}
        <NumbersOnDoNotCallListAlertModal />
      </ServiceProviderFormContextProvider>
    </Loading>
  );
}

export default function ServiceProviderDetails() {
  const { formData, init } = useServiceProviderDetail();
  const { market } = useMarkets();
  useEffect(() => {
    void init(market);
  }, [market]);
  return (
    <ServiceProviderDetailsFormInternal initialFormData={formData}/>
  );
}